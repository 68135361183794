export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хабарландыру"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сипаттама"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Деректемелер"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Минимум"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Максимум"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сома шектеуі"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Күту уақыты (мин)"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хабарландыру белсенді"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тек сілтеме бойынша қолжетімді"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тек расталған пайдаланушылар үшін қолжетімді"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бас тарту"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сақтау"])}
}