export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["транзакція"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загальні параметри"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зміна залишків на рахунках"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На кінець операції:"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На початок операції:"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додаткові параметри"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операції в блокчейні"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Callback"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наступна спроба:"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Час"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відповідь"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Протекція угоди"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Термін протекції:"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["До"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код протекції:"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Підтвердити"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Параметри шаблону"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва шаблону:"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберегти"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скасувати операцію"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повторити операцію"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберегти шаблон"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрити"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер транзакції"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операція"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кореспондент"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата платежу"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комісія"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коментар"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Результат"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер платіжної форми"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прихід/Витрата"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зміна залишків адрес"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Підтвердити зарахування"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повернути гроші відправнику"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Підозріла операція"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Варіанти дій:"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комісія одержувача"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комісія платника"])}
}