export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टेम्प्लेट्स"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खोजें"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समूह में"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भुगतान समूह"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भुगतान"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भुगतान राशि"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ट्रांसफर खाता"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["राशि"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टिप्पणी"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टेम्प्लेट"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टेम्प्लेट नाम:"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सहेजें"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टेम्प्लेट सहेजें"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ट्रांसफर"])}
}