export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz plik"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ten format pliku nie jest obsługiwany. \nAkceptowane formaty:"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieznany format"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Za duży rozmiar pliku, maksymalny rozmiar: "])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz lub przeciągnij pliki tutaj do załadowania"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Załaduj"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anuluj"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pliki załadowane"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["plików"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuwać"])}
}