export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Répéter l'opération"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historique des opérations"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des comptes"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succès"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur inconnue"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modèles"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes actifs"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations sur le nouvel actif"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Problème supplémentaire"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lien vers la blockchain"])}
}