export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自动柜员机设备"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加设备"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["设备类型"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["姓名"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评论"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["配置"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加盒式磁带"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["盒式"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["姓名"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评论"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["配置"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["移除设备"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取出盒式磁带"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除设备？"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除磁带？"])}
}