export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择文件"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不支持此文件格式。\n接受的格式："])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未知格式"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文件太大，最大大小："])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择或拖放文件到此处上传"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上传"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文件已上传"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文件"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除"])}
}