export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Embedded Widget"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installation"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect the script to the page in the head section via CDN or by downloading as a file"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Call"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Call the function"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["by passing a JS object"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["as an argument:"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data type"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Example"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["id for iframe"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["name for iframe"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["object with payment parameters"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["success operation handler function"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["failure operation handler function"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment service address"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Below"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Even below"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return to the store site after the operation is completed"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After the operation is completed or rejected, the widget will be closed. The object with the parameters below will be passed to the onFail or onSuccess callback:"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions on success"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions on failure"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment service address"])}
}