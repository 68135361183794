export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tytuł:"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa jest pełną nazwą kryptowaluty, tak jak będzie to widoczne dla każdego: samego emitenta, kupujących, sprzedających, przetwarzającego itp."])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TIKER"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skrót zawierający od 3 do 10 znaków. \nDopuszczalne są wielkie litery łacińskie, łączniki (myślniki) i cyfry."])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategoria:"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz kategorię, do której najlepiej pasuje Twój zasób kryptograficzny"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obsługiwane formaty: png, jpg, jpeg, svg. \nRozmiar pliku nie większy niż 1 MB, 500*500 pikseli"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opisz, dlaczego ten krypto-aktywo jest emitowane przez emitenta, w jakiej dziedzinie działa lub będzie działać, jaka jest jego misja."])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo:"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cel / Misja / Przeznaczenie:"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precyzja:"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emisja:"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wartość jednostki:"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liczba miejsc po przecinku dla kwoty. Na przykład dokładność większości walut fiducjarnych, takich jak dolar amerykański lub euro, wynosi 2"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liczba jednostek emitowanego aktywa kryptograficznego. Kwota emisji będzie natychmiast dostępna na koncie użytkownika po zakończeniu transakcji tworzenia zasobu kryptograficznego."])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadeklarowana wartość jednostki:"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zabezpieczenie:"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto:"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osoba zobowiązana:"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decyzja o emisji kryptoaktywów:"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eksportuj operacje do blockchain:"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ blockchain:"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Włącz transakcje kryptoaktywów:"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ograniczenia/Sankcje:"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Określ, którzy obywatele lub rezydenci podatkowi których krajów z powyższej listy nie mogą używać kryptoaktywów w swoich obrotach: "])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inne ograniczenia i warunki:"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres hostingu:"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres white paper:"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail:"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telegram:"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twitter:"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook:"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inne kontakty:"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do poprzedniego"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dalej"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz aktywa"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa i przeznaczenie"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charakterystyka finansowa"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emitent"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eksport do blockchain"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limity i ograniczenia"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakty"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieokreślona"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stałe powiązanie z walutami fiducjarnymi i innymi aktywami"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emitent (osoba wydająca aktywa kryptograficzne)"])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oferujący (osoba dystrybuująca aktywa kryptograficzne)"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nadawca"])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odbiorca"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja (włącz sponsoring)"])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umieszczenie aktywów kryptograficznych"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktywa kryptograficzne oznaczają cyfrową reprezentację wartości lub prawa, które mogą być przenoszone i przechowywane elektronicznie przy użyciu technologii blockchain. Aktywa kryptograficzne NIE obejmują walut fiducjarnych, papierów wartościowych i innych instrumentów finansowych oraz aktywów podlegających odrębnym regulacjom rządowym."])},
  "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edytuj zasób kryptograficzny"])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administracja"])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przyjęcie"])},
  "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powód odmowy przyjęcia"])},
  "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto oferenta"])},
  "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rachunek oferenta w obowiązującej walucie"])},
  "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oferujący jest przedstawicielem emitenta aktywa w transakcjach kupna/sprzedaży na rynku pierwotnym. \nKonto jest pasywne. \nMoże być kontem emisji aktywów"])},
  "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto jest pasywne, powiązane walutowo. \nMusi należeć do tego samego użytkownika co poprzednie konto"])},
  "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zezwalaj użytkownikom na zakup aktywów w bankomatach"])},
  "62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zezwalaj na sprzedaż aktywów przez użytkowników w bankomatach"])},
  "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formularz zakupu na rynku wtórnym"])},
  "64": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transakcje na rynku wtórnym przeprowadzane są za pośrednictwem bramek"])},
  "65": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formularz sprzedaży na rynku wtórnym"])},
  "66": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transakcje na rynku wtórnym przeprowadzane są za pośrednictwem bramek"])},
  "67": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zezwalaj na transakcje z aktywami na giełdzie"])},
  "68": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zezwalaj na transakcje dotyczące aktywów za pośrednictwem protokołu P2P"])},
  "69": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeśli jesteś indywidualnym przedsiębiorcą lub przedstawicielem osoby prawnej, musisz pobrać plik decyzji o wydaniu zasobu cyfrowego w formacie PDF. \nRozwiązanie musi zawierać wszystkie główne cechy wirtualnego zasobu i być podpisane elektronicznym podpisem indywidualnego przedsiębiorcy lub tej osoby prawnej. \nPrzykład decyzji o emisji zasobu cyfrowego można znaleźć na stronach naszego serwisu w sekcji „Dokumenty”"])},
  "70": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umieszczenie wirtualnego zasobu"])},
  "71": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zastępcze konto wydania"])},
  "72": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz z kont wystawców"])},
  "73": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kwota do odpisu:"])},
  "74": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak konta"])},
  "75": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz konto"])},
  "76": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maks. \nemisja:"])},
  "77": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wielkość emisji przekracza maks. \nemisje"])},
  "78": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maks. \nemisja jest mniejsza niż wielkość emisji lub równa zeru"])},
  "79": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formy są nieobecne"])}
}