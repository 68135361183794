export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumentation"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Händlerprotokoll"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiterleitung zum Händler"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rückkehr zum Shop"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shop-Benachrichtigung"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signaturberechnung"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eingebettetes Widget"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installation"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufruf"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antwort vom Shop"])}
}