export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Внутренний актив"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Счёт"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Внешний актив"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Группа"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название EN"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Синонимы"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Страны"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если заполнено, объявление будет доступно только пользователям из указанных стран"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Привязать к активу"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Единицы измерения"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сокращенно"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Точность"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Описание"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Реквизиты"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Направление перевода"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Минимум"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Максимум"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лимит"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Время ожидания (мин)"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Объявление активно"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступно только по ссылке"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступно только верифицированным пользователям"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["К предыдущему"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разместить"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Далее"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Направление"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ограничения"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создание объявления"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Свой внешний актив"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не привязывать"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Да"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сократить курс"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вернуть обратно"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет счета в таком активе"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать счёт"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Укажите курс"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курс корректный"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сократить курс"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вернуть обратно"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Минимум больше максимума"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Куда и как хотите получить перевод. Например: Переведите в приложении банка по номеру телефону +79990001111 (получатель Александр Н.)"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["отдаю"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["получаю"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активы"])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запрашиваемые реквизиты"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Какие реквизиты должен будет прислать тейкер, чтобы вы смогли сделать ему перевод"])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать актив"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать актив"])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Что хотите получить: средства во внутреннем активе, например, BTC или во внешнем, например TOKEN GOLD?"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Напишите комментарий об особенностях проведения этой сделки, например: \"Внешний актив передается только при личной встрече\""])},
  "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вид ценностей, к которому относится внешний актив, например \"Наличные\""])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название актива для отображения на платформе"])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Актив также может называться словами из списка"])},
  "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сопоставление с существующим активом"])},
  "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Например: литры, штуки, часы, коробки"])},
  "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Например: л, ш, ч, кор"])},
  "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сколько может быть цифр после запятой в значениях актива"])},
  "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы выбрали создание ещё не существующего актива, пожалуйста, заполните его характеристики"])},
  "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Время, которое даётся сторонам для выполнения условий"])},
  "62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Внутренним активом является актив, который существует в системе \"New Reality\". Внешний актив - это актив, не существующий в системе \"New Reality\"."])},
  "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не привязан"])},
  "64": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ожидаемое значение: от 1 до 2147483"])}
}