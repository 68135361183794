export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecione o status"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O pedido de cobrança foi apresentado"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicitação de cobrança cancelada"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arrecadação aprovada para execução"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A cobrança é feita pelo caixa (o dinheiro é colocado em cassetes)"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cassetes entregues a colecionadores (dinheiro a caminho)"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coleta de dinheiro instalada no caixa eletrônico"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Como resultado de uma falha, sobrecarregado com outra coleção"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A coleta foi substituída por outra coleta em caixa eletrônico (permanece a caminho)"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Os saldos de cobrança são lançados (a cobrança é enviada para o arquivo)"])}
}