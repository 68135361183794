export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benachrichtigungseinstellungen"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivieren"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Über Operationen benachrichtigen"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorisierung (Anmeldung im persönlichen Konto, mobile App usw.)"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benachrichtigen bei Ereignis „Ausführung von Finanztransaktionen mit der Karte“"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausgaben (jede Zahlungen, Überweisungen, Dienstleistungszahlungen usw.)"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geldeingänge"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eingang einer Nachricht über die interne Post"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eingang einer Rechnung zur Zahlung"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Änderung der Sicherheitseinstellungen"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operationen mit Code aus der Nachricht bestätigen"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorisierung mit Code aus der Nachricht bestätigen"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigung bei „Ausführung von Finanztransaktionen mit der Karte“"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Änderung der Sicherheitseinstellungen mit Code aus der Nachricht bestätigen"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausgaben mit Code aus der Nachricht bestätigen"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Änderungen nicht gespeichert, speichern?"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht speichern"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])}
}