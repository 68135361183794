export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anuncio"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descripción"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalles"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mínimo"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Máximo"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Límite de cantidad"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiempo de espera (min)"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anuncio activo"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponible solo por enlace"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponible solo para usuarios verificados"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar"])}
}