export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le chiavi monouso aumentano significativamente la sicurezza del sistema."])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al momento dell'accesso al programma, ti verrà chiesto non solo di inserire il login e la password, ma anche la chiave monouso successiva. L'attivazione avviene in sequenza. L'ultima chiave è valida fino a quando non crei una nuova serie di chiavi."])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usa chiavi monouso"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista delle chiavi"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genera"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esporta"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiave"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiavi monouso"])}
}