export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricevo"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commento"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motivo"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla transazione"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma partecipazione alla transazione"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rifiuta la transazione"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estendi tempo di attesa"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annullamento transazione"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato utente nella transazione non definito. Qualcosa è andato storto"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richiedi arbitrato"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accetta arbitrato"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifica esecuzione trasferimento"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma ricezione trasferimento"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valuta la transazione"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invia valutazione"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transazione № "])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corrispondente"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timeout"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oggi"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["domani"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annullare l'arbitrato"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annullare l'arbitrato"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hai richiesto l'arbitrato"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["È stato richiesto l'arbitrato contro di te"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'arbitrato è in corso (tu sei il querelante)"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'arbitrato è in corso (tu sei l'imputato)"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allo scadere del tempo, il consenso all'arbitrato avverrà automaticamente e non potrai più aggiungere le tue risposte alle domande dell'arbitrato"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbitrato richiesto"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["C'è un arbitrato"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["C'è un arbitrato"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prendere una decisione sull'arbitrato: pagamento interno"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condotta"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancellare"])}
}