export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सुरक्षा"])},
  "Antiphishing": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एंटीफिशिंग सेटिंग एक अतिरिक्त सुरक्षा तंत्र है।"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आपके द्वारा सेट किया गया स्वागत संदेश किसी भी पेज के शीर्ष पर प्रदर्शित होगा।"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सही संदेश आपको दिखाएगा कि आप सही साइट पर हैं, गलत संदेश एक संकेत होगा: ऑपरेशन न करें, हमारी सुरक्षा सेवा से तुरंत संपर्क करें।"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एंटीफिशिंग सक्षम करें:"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एंटीफिशिंग स्ट्रिंग:"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सहेजें"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विरोधी"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["परिवर्तन अगले प्रमाणीकरण के बाद प्रभावी होंगे"])}
  },
  "IP": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आईपी-एड्रेस से एक्सेस सेटिंग आपको (या हैकर को) किसी सूचीबद्ध पते से काम करने से रोक देगी।"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आईपी-एड्रेस के माध्यम से एक्सेस प्रतिबंध"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पते जोड़ें"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पता:"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जोड़ें"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पते का ब्लॉक:"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["से:"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आपका आईपी:"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पते की सूची"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्रमांक"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पते से"])},
    "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पते तक"])},
    "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एक्सेस"])},
    "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्रियाएँ"])},
    "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हटाएं"])},
    "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई पते नहीं"])},
    "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सहेजें"])},
    "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रतिबंधित करें"])},
    "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अनुमति दें"])},
    "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आईपी पता दर्ज करें"])},
    "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आईपी पता गलत है"])},
    "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई आईपी पता या पते का ब्लॉक निर्दिष्ट नहीं है।"])},
    "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई आईपी पता प्रमाणित नहीं है। आप अपने खाते तक पहुंच प्राप्त नहीं कर सकेंगे।"])},
    "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आईपी के माध्यम से एक्सेस"])},
    "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["से:"])}
  },
  "Magic": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जादुई शब्द एक अतिरिक्त सुरक्षा सेटिंग है।"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यदि जादुई शब्द सक्षम है, तो हर बार प्रमाणीकरण के समय सिस्टम आपसे जादुई शब्द के कुछ वर्ण पूछेगा।"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जादुई शब्द:"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जादुई शब्द पूछें:"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कैबिनेट में प्रवेश करते समय:"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["व्यापारी से भुगतान करते समय:"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कार्ड का उपयोग करते समय:"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सहेजें"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जादुई शब्द"])}
  },
  "Onekey": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वन-टाइम कुंजी सिस्टम की सुरक्षा को काफी बढ़ाती है।"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रवेश के समय, प्रोग्राम आपसे न केवल लॉगिन और पासवर्ड, बल्कि अगली वन-टाइम कुंजी भी पूछेगा। सक्रियण क्रमशः होता है। अंतिम कुंजी तब तक प्रभावी रहती है जब तक कि आप नई कुंजी श्रृंखला नहीं बनाते।"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वन-टाइम कुंजी का उपयोग करें"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कुंजी सूची"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उत्पन्न करें"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["निर्यात"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्रमांक"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कुंजी"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वन-टाइम कुंजी"])}
  },
  "Password": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पुराना पासवर्ड:"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नया पासवर्ड:"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पासवर्ड की पुष्टि:"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सहेजें"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"नया पासवर्ड\" और \"पासवर्ड की पुष्टि\" मेल नहीं खाते।"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पासवर्ड बदलें"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4 से 63 वर्णों की स्ट्रिंग। स्वीकार्य वर्ण: लैटिन वर्णमाला, अंक और प्रतीक"])}
  },
  "PayPassword": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पुराना पासवर्ड:"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नया पासवर्ड:"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पासवर्ड की पुष्टि:"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नया पासवर्ड:"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पासवर्ड की पुष्टि:"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सहेजें"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सहेजें"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"नया पासवर्ड\" और \"पासवर्ड की पुष्टि\" मेल नहीं खाते।"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पासवर्ड में सिरिलिक वर्ण नहीं होने चाहिए"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पुष्टिकरण पासवर्ड बनाएं"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पुष्टिकरण पासवर्ड बदलें"])},
    "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मुख्य पृष्ठ पर जाएं"])},
    "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4 से 65 वर्णों की स्ट्रिंग। स्वीकार्य वर्ण: लैटिन वर्णमाला, अंक और प्रतीक"])}
  },
  "SecretQuestion": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यदि आप अपने कैबिनेट के पासवर्ड को भूल जाते हैं, तो आप इसे गुप्त प्रश्न का उत्तर देकर पुनः प्राप्त कर सकते हैं।"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इस अनुभाग में आप गुप्त प्रश्न/उत्तर बदल सकते हैं।"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["गुप्त प्रश्न"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आपका प्रश्न:"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उत्तर:"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सहेजें"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["गुप्त प्रश्न"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपना प्रश्न"])}
  }
}