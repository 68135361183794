export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actifs externes"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lié à l'actif"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unités de mesure"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupe"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de création"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auteur"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Période du"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Période au"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtres"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun actif"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aujourd'hui"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semaine"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mois"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Année"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lié à l'actif"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupe"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auteur"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activité"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actif"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactif"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier l'actif externe"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer l'actif externe"])}
}