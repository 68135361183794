export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер касети"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID касети"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер інкасації касети"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номінал касети"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завантажено"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сервер"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кількість"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Банкомат"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фактично"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кількість"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коментар до касети"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Немає даних для відображення"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Інкасація пристрою"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["діє"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["c"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["по"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["залишки рознесені"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сервер"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кількість купюр"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Банкомат"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кількість купюр"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коментар"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Диспенсер"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reject/Retract"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Купюроприймач"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reject картки"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карти"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["поточні"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["вивантажені"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Інкасаційні залишки з банкомату"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Інкасація пристрою"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["діє"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["касета встановлена ​​в банкомат"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["вилучено"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["залишки рознесені"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["залишки рознесені"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["касета порожня:"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["злипання:"])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додаткова інформація:"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["по теперішній час"])}
}