export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cheques electrónicos"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear cheque"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actividad"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creado"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta de débito"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serie"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentario"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acciones"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activo"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactivo"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay cheques"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activar"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificar"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerrar"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontraron recibos electrónicos"])}
}