export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Methoden der Auffüllung"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kryptowährung"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachschub über P2P"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elektronische Überprüfung"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachschubvorlagen"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausgangsmethoden"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Kryptowährung"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungskatalog"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Massenzahlungen"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schlussfolgerung über P2P"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schlussfolgerung Vorlagen"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übersetzungsmethoden"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Austausch für den Austausch"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übersetzung zum Benutzer"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übertragen Sie nach Ihren Konten"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übersetzungsvorlagen"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Füllen Sie die Punktzahl mit Bitcoin, Tether USDT, Ethereum, Tron oder anderen Kryptowährungen auf."])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Austausch ohne Vermittler: Sie übersetzen Kryptowährung, Bankübertragung oder Überweisung von Bargeld in einen anderen Benutzer, und es handelt sich um eine interne Übertragung an Sie."])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haben Sie irgendwo einen elektronischen Scheck des Dienstes erworben? \nAktivieren Sie es bald und erhalten Sie den gesamten Nennwert auf eigene Kosten. \nÜbrigens können Sie die Prüfung ohne Aktivierung überprüfen."])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Füllen Sie die Punktzahl mit zuvor erstellten Vorlagen auf. \nÜbrigens können Vorlagen nach einer Transaktion oder aus der Geschichte der Operationen erstellt werden."])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entfernen Sie die Mittel zu Bitcoin, Tether USDT, Ethereum, Tron oder einer anderen Kryptowährung."])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezahlen Sie die Mobilfunkkommunikation, die kommunale Wohnung oder andere Dienstleistungen."])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Machen Sie Dutzende, Hunderte oder sogar Tausende von Operationen in einem Klick. \nLaden Sie einfach die Datei mit der Excel -Formatliste herunter."])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Austausch ohne Vermittler: Sie übersetzen Kryptowährung, Bankübertragung oder Überweisung von Bargeld in einen anderen Benutzer, und es handelt sich um eine interne Übertragung an Sie."])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weisen Sie die Mittel mit zuvor erstellten Vorlagen zu. \nÜbrigens können Vorlagen nach einer Transaktion oder aus der Geschichte der Operationen erstellt werden."])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können Ihren Kurs einstellen und darauf warten, dass der Kurs genau das wird. \nOder schnell zum aktuellen Marktpreis austauschen."])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übertragen Sie Gelder auf einen anderen Servicebenutzer."])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbreiten Sie einfach Mittel zwischen Ihren Konten."])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übertragen Sie Fonds schnell mit zuvor erstellten Vorlagen. \nÜbrigens können Vorlagen nach einer Transaktion oder aus der Geschichte der Operationen erstellt werden."])}
}