export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passaporte digital"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mais detalhes"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID do Usuário:"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de registro:"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Última autorização:"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meus ativos"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contas"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver todos"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recarregar"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retirar"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma conta"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senha de confirmação"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para confirmar operações, você precisa criar uma senha de confirmação."])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modelos"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantia"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagamentos populares"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notícias"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recolher"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Próximo..."])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum ativo"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equilíbrio"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retirar"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recarregar"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intercâmbio"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para o usuário"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balanço geral"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar em"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo não fiduciário"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balança fiduciária"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Novas informações"])}
}