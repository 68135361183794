export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загальні відомості"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відомості про оплату"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контрольний підпис"])},
  "List": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мої магазини"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Магазинів не знайдено"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створити код віджета"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створити платіжне посилання"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редагувати"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити магазин"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви дійсно хочете видалити магазин"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зареєструвати магазин"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва"])},
    "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Режим платежів"])},
    "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активність"])},
    "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Акцепт"])},
    "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
    "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дії"])}
  },
  "New": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зареєструвати магазин"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зареєстрований в системі електронний магазин може приймати платежі від клієнтів через мерчант нашої системи."])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Як магазин реєструються електронні магазини, обмінні пункти та будь-які інші інтернет-ресурси, які бажають приймати платежі на свою адресу."])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва або торгова марка"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL-адреса магазину"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Короткий опис магазину"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип сервісу"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Магазин активний"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Секретний ключ"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дозволити автовиплати"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відправляти результати на скрипт"])},
    "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адреса скрипта"])},
    "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повернення після успішного платежу"])},
    "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Після НЕуспішного платежу"])},
    "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберегти"])},
    "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Згенерувати"])},
    "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Щонайменше 30 знаків."])},
    "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Використовується для перевірки цілісності та достовірності переданих даних платіжною системою (при відправці магазином користувача на мерчант) та магазином (при отриманні результатів платежу)"])},
    "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Далі"])},
    "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назад"])},
    "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["До списку магазинів"])},
    "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неправильна URL -адреса"])}
  },
  "Invoices": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список інвойсів"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Інвойсів не знайдено"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коментар"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Магазин"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер магазину"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скасувати рахунок -фактуру"])},
    "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберігайте код платежу"])}
  }
}