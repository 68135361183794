export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ATM devices"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add device"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Device type"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add cassette"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cassette type"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove device"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove cassette"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete device?"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete the cassette?"])}
}