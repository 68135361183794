export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar archivo"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este formato de archivo no es compatible. \nFormatos aceptados:"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formato desconocido"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamaño de archivo demasiado grande, tamaño máximo:"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecciona o arrastra aquí los archivos para cargar"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargar"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivos cargados"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["archivos"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar"])}
}