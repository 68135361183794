export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operation wiederholen"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operationsgeschichte"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontenliste"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolg"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unbekannter Fehler"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorlagen"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Vermögenswerte"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informationen zum neuen Asset"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zusätzliches Problem"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link zur Blockchain"])}
}