export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daję"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["saldo "])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otrzymuję"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para kapitałowa"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konta"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ wymiany"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przelew rynkowy - zostanie zastosowany najbardziej korzystny kurs w momencie realizacji zlecenia. Uwaga, kwota do otrzymania może się zmienić i różnić od obliczonej w zależności od zleceń w momencie wymiany."])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przelew po stałej cenie odbywa się zgodnie z podaną kwotą i kursem. Zostanie złożone zlecenie. Jeśli podany kurs znacznie różni się od rynkowego, zamknięcie zlecenia może zająć dużo czasu."])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurs (prognoza):"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurs"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurs nie jest określony,"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zaleca się samodzielne ustawienie kursu"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suma"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Użyj wszystkiego"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prowizja"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prowizja"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Razem:"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Według rynku"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(szybka wymiana po najlepszej kontrofercie)"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stały kurs"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(złóż zlecenie po określonym kursie)"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak odpowiednich kont"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurs"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmiana w ciągu 24 godzin"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wahania w ciągu 24 godzin"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wolumen transakcji w ciągu 24 godzin"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kwota nie może być równa zero!"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wymień"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aby zobaczyć dane podsumowania, wybierz parę handlową"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie można wymieniać identycznych aktywów"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodatkowo"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wniosek nie jest uwzględniony w wyborze do"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["do"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ograniczenia"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pole 'Kurs' musi mieć wartość inną niż 0"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niewystarczające środki na koncie"])}
}