export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ripeti operazione"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Storia delle operazioni"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elenco conti"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successo"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore sconosciuto"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modelli"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I miei asset"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni sul nuovo asset"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emissione aggiuntiva"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elenco degli asset virtuali"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collegamento alla blockchain"])}
}