export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी आदेश"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रीसेट"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फ़िल्टर"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपडेट"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दिनांक"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मैं देता हूं"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मैं प्राप्त करता हूं"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्थिति"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दर"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संचालन"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कमीशन"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रद्द करें"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दोहराएं"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई आवेदन नहीं"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भेजने की न्यूनतम राशि"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भेजने की अधिकतम राशि"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्राप्त करने की न्यूनतम राशि"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्राप्त करने की अधिकतम राशि"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लेनदेन पूरा करने के प्रयासों की संख्या"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सक्रिय"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भेजने की मुद्रा"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्राप्त करने की मुद्रा"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भेजने का खाता"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्राप्त करने का खाता"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उप-डीलरों की सूची"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खाता चयनित नहीं"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अवधि से"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अवधि तक"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आज"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कल"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सप्ताह"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["महीना"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वर्ष"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दिनांक प्रकार चयनित नहीं"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बनाएं"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समाप्त"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समाप्त"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रारंभ"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कौन सा भाग निश्चित है और कौन सा परिवर्तनीय"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दी गई राशि निश्चित है"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्राप्त होने वाली राशि निश्चित है"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आदेश प्रकार"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपयोगकर्ता सर्वोत्तम प्रति-प्रस्तावों के लिए तुरंत एक्सचेंज करना चाहता है"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सख्ती से निर्दिष्ट दर पर एक्सचेंज"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्थिति"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रसंस्करण शुरू नहीं हुआ"])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रसंस्करण शुरू हुआ"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आंशिक रूप से पूर्ण"])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पूरी तरह से पूर्ण"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पूरी तरह से रद्द"])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आंशिक रूप से पूर्ण और रद्द"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जाने वाली संपत्ति"])},
  "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आने वाली संपत्ति"])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दिनांक प्रकार"])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["न्यूनतम राशि अधिकतम राशि से अधिक है"])}
}