export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Белсенділік"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активтің сипаттамасы"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Күні"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хабарландырулар жоқ"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Менің хабарландыруларым"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Менің хабарландыруларым"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жаңа хабарландыру"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Белсенділік"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Белсенді"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Белсенді емес"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Барлығы"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бағыты"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сыртқы актив"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ішкі актив"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сипаттама"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Биржада айырбастаймын"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Биржада қабылдаймын"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лимиттер"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жарияланған күні"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Есепшоттағы қалдық"])}
}