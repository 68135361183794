export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Externes Asset bearbeiten"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppe"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synonyme"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Länder"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit Asset verknüpfen"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einheiten"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abkürzung"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genauigkeit"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für Handel akzeptiert"])}
}