export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de facturas"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontraron facturas"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentario"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tienda"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de tienda"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar la factura"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de pago de almacenamiento"])}
}