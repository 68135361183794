export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stare hasło:"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nowe hasło:"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potwierdzenie hasła:"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nowe hasło:"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potwierdzenie hasła:"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"Nowe hasło\" i \"Potwierdzenie hasła\" nie pasują do siebie."])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hasło nie powinno zawierać cyrylicy"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tworzenie hasła potwierdzającego"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmiana hasła potwierdzenia"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do strony głównej"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Od 4 do 65 znaków. Dozwolone są litery alfabetu łacińskiego, cyfry i symbole"])}
}