export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чарівне слово - це додаткове налаштування безпеки."])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Якщо чарівне слово ввімкнено, то щоразу при авторизації система проситиме вас ввести якісь символи (за їх порядковим номером) із чарівного слова."])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чарівне слово:"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запитувати чарівне слово:"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["При вході в кабінет:"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["При оплаті через мерчант:"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["При використанні картки:"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберегти"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чарівне слово"])}
}