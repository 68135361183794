export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actif intérieur"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actif externe"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupe"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom RU"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synonymes"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si rempli, l'annonce ne sera visible que pour les utilisateurs des pays spécifiés"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le rattacher à un actif"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unités"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abréviation"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Précision"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direction de transfert"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limite"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps d'attente (min)"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'annonce est active"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponible uniquement par lien"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponible uniquement pour les utilisateurs vérifiés"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Précédent"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Placer"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivant"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direction exchange"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restrictions"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une annonce"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propre actif externe"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne pas attacher"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réduire le taux"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas de compte dans un tel actif"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un compte"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spécifier le taux"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le taux est correct"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réduire le taux"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum supérieur au maximum"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Où et comment souhaitez-vous recevoir le transfert. Par exemple : Effectuez le transfert dans l'application bancaire au numéro de téléphone +499990001111 (bénéficiaire Alexandre N.)"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je donne"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je reçois"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actifs"])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coordonnées demandées"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quelles coordonnées le preneur doit envoyer pour que vous puissiez effectuer le transfert"])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez l'actif"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez l'actif"])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Que voulez-vous obtenir : des fonds en actif interne, par exemple, BTC, ou en actif externe, par exemple, TOKEN GOLD ?"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Écrivez un commentaire sur les particularités de cette transaction, par exemple : \\\"L'actif externe est transféré uniquement lors d'une rencontre en personne\\\""])},
  "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de valeurs auxquelles appartient un actif externe, par exemple, \"espèces\""])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de l'actif à afficher sur la plateforme"])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'actif peut également être appelé par des mots de la liste"])},
  "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correspondance avec un actif existant"])},
  "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Par exemple : litres, pièces, heures, boîtes"])},
  "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Par exemple : l, p, h, b"])},
  "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Combien de chiffres après la virgule dans les valeurs de l'actif"])},
  "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez choisi de créer un actif qui n'existe pas encore, veuillez remplir ses caractéristiques"])},
  "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps accordé aux parties pour remplir les conditions"])},
  "62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un actif interne est un actif qui existe dans le système \\\"New Reality\\\". Un actif externe est un actif qui n'existe pas dans le système \\\"New Reality\\\"."])},
  "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non comparé"])},
  "64": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeur attendue: de 1 à 2147483"])}
}