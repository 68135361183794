export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TICKER"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mise"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akce"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seznam kryptoaktiv"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádná kryptoaktiva"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emise tokenu"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přijmout"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trh"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přesnost"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primární"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sekundární"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spárováno s jiným aktivem"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podrobné informace o kryptoaktivu"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upravit data kryptoaktiva"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodatečná emise"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smazat kryptoaktivum"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seznam kryptoaktiv"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přijmout"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivita"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ano"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tokeny lze koupit v bankomatu"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tokeny lze prodat v bankomatu"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tokeny sekundárního trhu"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobrazení tokenů"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Všechny přijaté"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Všechny moje"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UID uživatele"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spalování aktiv"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pro konkrétního uživatele"])}
}