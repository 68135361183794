export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва:"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва – це повна назва крипто-активу, якою його бачитимуть усі: сам емітент, покупці, продавці, процесинг та ін."])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TICKER"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скорочена абревіатура довжиною від 3 до 10 символів. \nДопустимі латинські літери у верхньому регістрі, дефіс (тире) та цифри."])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категорія:"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виберіть категорію, яка найбільше підходить для вашого крипто-активу"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Формати, що підтримуються: png, jpg, jpeg, svg. \nРозмір файлу не більше 1 Мб, 500*500 пікселів"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опишіть, чому цей крипто-актив випускається емітентом, в якій сфері він працює або працюватиме, яка його місія"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Логотип:"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мета/місія/призначення:"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Точність:"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Емісія:"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вартість за одиницю:"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кількість десяткових знаків для суми. Наприклад, точність більшості фіатних валют, таких як долар США або євро, становить 2."])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кількість одиниць випущеного крипто-активу. Вартість емісії буде доступна негайно на вашому рахунку після завершення операції створення крипто-активу."])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заявлена вартість за одиницю:"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гарантія:"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рахунок:"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відповідальна за зобов'язання особа:"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рішення про емісію крипто-активу:"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Експорт операцій у блокчейн:"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип блокчейну:"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Увімкнути операції з крипто-активом:"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обмеження/Санкції:"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вкажіть, громадянам або податковим резидентам яких країн зі списку заборонено використання крипто-активу:"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Інші обмеження та умови:"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адреса хостингу:"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адреса whitepaper:"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email:"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telegram:"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twitter:"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook:"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Інші контакти:"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На попереднє"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберегти"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Далі"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створити крипто-актив"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва та мета"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фінансові характеристики"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Емітент"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Експорт у блокчейн"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ліміти та обмеження"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контакти"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не визначено"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фіксована прив'язка до фіатних валют та інших активів"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Емітент (Особа, що випускає крипто-актив)"])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оферент (Особа, що розподіляє крипто-актив)"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відправник"])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Одержувач"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я (включити спонсорство)"])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Розподіл крипто-активу"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Крипто-актив означає цифрове представлення вартості або права, яке може передаватися та зберігатися електронно з використанням технології блокчейн. Крипто-активи НЕ включають фіатні валюти, цінні папери та інші фінансові інструменти та активи, що підлягають окремому державному регулюванню."])},
  "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редагувати криптоактив"])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адміністрація"])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Акцепт"])},
  "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Причина відмови в акцепті"])},
  "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рахунок оферента"])},
  "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рахунок оферента у валюті прив'язки"])},
  "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оферент - представник емітента активу в угодах купівлі/продажу на первинному ринку. \nРахунок пасивний. \nМоже бути рахунком емісії активу"])},
  "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рахунок пасивний, у валюті прив'язаний. \nПовинен належати тому ж користувачу, що й попередній рахунок"])},
  "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дозволити покупку активу користувачами у банкоматах"])},
  "62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дозволити продаж активів користувачами в банкоматах"])},
  "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Форма купівлі для вторинного ринку"])},
  "64": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Угоди на вторинному ринку виконуються через шлюзи"])},
  "65": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Форма продажу для вторинного ринку"])},
  "66": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Угоди на вторинному ринку виконуються через шлюзи"])},
  "67": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дозволити угоди з активом на біржі"])},
  "68": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дозволити угоди з активом через P2P"])},
  "69": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Якщо ви є індивідуальним підприємцем або представником юридичної особи, вам необхідно завантажити файл рішення про випуск цифрового активу у форматі PDF. \nРішення повинне містити всі основні характеристики віртуального активу та бути підписане електронним підписом ІП або даної юридичної особи. \nПриклад оформлення рішення про випуск цифрового активу Ви можете знайти на сторінках нашого сайту у розділі \"Документи\""])},
  "70": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Розміщення віртуального активу"])},
  "71": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Підставити рахунок емісії"])},
  "72": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вибрати із рахунків емітента"])},
  "73": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума до списання:"])},
  "74": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Немає рахунку"])},
  "75": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створити рахунок"])},
  "76": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Макс. \nемісія:"])},
  "77": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Розмір емісії перевищує макс. \nемісію"])},
  "78": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Макс. \nемісія менша за розмір емісії або дорівнює нулю"])},
  "79": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Форми відсутні"])}
}