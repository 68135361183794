export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створити валютну пару"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Валютна пара"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Налаштування оновлення"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дано валюту"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отримувана валюта"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додавати до курсу прибуток, %"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип оновлення курсу"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Термін життя курсу, секунд"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Інтервал оновлення курсу, секунд"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курс"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На яку дату курс актуальний"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аліас валюти, що віддається"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Псевдонім отриманої валюти"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шлюз"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Потрібно обов'язково вибрати валюту і до того ж лише одну: віддається або отримувана"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума відправки * Курс = Сума отримання"])}
}