export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de faturas"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faturas não encontradas"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentário"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantia"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loja"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número da loja"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancele a fatura"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de pagamento da loja"])}
}