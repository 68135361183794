export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paires de devises"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une paire de devises"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier la paire de devises"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer la paire de devises"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devise donnée"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devise reçue"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passerelle"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de mise à jour"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune paire de devises trouvée"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous vraiment supprimer la paire de devises"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lié à une autre devise"])}
}