export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["补充方法"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加密货币"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通过P2P补充"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子检查"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["补充模板"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输出方法"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在加密货币中"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["付款目录"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大规模付款"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["结论P2P"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["结论模板"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["翻译方法"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交换"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["翻译给用户"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["根据您的帐户转移"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["翻译模板"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用比特币，系绳USDT，以太坊，TRON或其他加密货币补充分数。"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有中介的交换：您将加密货币，银行转移或转移现金转换给另一个用户，这是对您的内部转移。"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您是否获得了该服务的电子检查的某个地方？\n尽快激活它，并自费获得整个面值。\n顺便说一句，您可以在不激活的情况下检查支票。"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用先前创建的模板补充分数。\n顺便说一句，可以在交易后或操作历史记录后创建模板。"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["将资金删除给比特币，束缚USDT，以太坊，TRON或其他加密货币。"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付蜂窝通信，公共公寓或其他服务。"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一键进行数十个，数百甚至数千个操作。\n只需使用Excel格式列表下载文件即可。"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有中介的交换：您将加密货币，银行转移或转移现金转换给另一个用户，这是对您的内部转移。"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用先前创建的模板分配资金。\n顺便说一句，可以在交易后或操作历史记录后创建模板。"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您可以设置课程，并等待课程成为该课程。\n或以当前的市场速度快速交换。"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["将资金转移到另一个服务用户。"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["轻松在您的帐户之间分配资金。"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用先前创建的模板快速转移资金。\n顺便说一句，可以在交易后或操作历史记录后创建模板。"])}
}