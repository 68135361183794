export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易代码"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我给"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我收到"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["方向"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["汇率"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["状态"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["超时"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有活动交易"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的交易"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活动"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["内部资产"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["非活动"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对应方"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["代码"])}
}