export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlung bestätigen"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rückzahlungskommission berechnen"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlung zurücksenden"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaktion"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hash in der Blockchain"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AML-Risiko"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betrag"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommission"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gutschrift"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie mit den geänderten Bedingungen einverstanden sind, bestätigen Sie die Einschreibung:"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Ihnen diese Bedingungen nicht zusagen, können Sie die Mittel abzüglich der Rückzahlungskosten an das Absenderkonto zurücksenden:"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaktion"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zum Betrag"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hat ein AML-Risiko"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["daher wurde die Kommission für ihre Gutschrift neu berechnet"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["somit beträgt der Gutschriftsbetrag"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine neuen Eingänge gefunden"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsdatum"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rückzahlung"])}
}