export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información del anuncio"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información básica"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Básico"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicional"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de creación"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activo"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceptar"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de inicio del trato"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de finalización del trato"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasa"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creador"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["da"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["recibe"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponible solo por enlace"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponible solo para usuarios verificados"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["límites"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propietario del anuncio"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descripción"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiempo de espera"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["días"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["horas"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minutos"])}
}