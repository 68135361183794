export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тіркеу"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жеке тұлға"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заңды тұлға"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тегі:"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аты:"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Әкесінің аты:"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Атауы"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қазақ әріптері, сонымен қатар дефис және апостроф рұқсат етіледі."])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Иванов"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қазақ әріптері, сонымен қатар дефис, бос орын және апостроф рұқсат етіледі."])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Иван"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Иванович"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қазақ және латын әріптері, сонымен қатар дефис, тырнақшалар, бос орындар, цифрлар және апостроф рұқсат етіледі."])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ЖШС \"Рога и копыта\""])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Телефон"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Құпиясөз"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Латын әріптері (үлкен және кіші), цифрлар, символдар _ - . ! $ % ^ * = рұқсат етіледі"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Құпиясөзді қайталаңыз"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Құпиясөздер сәйкес емес"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Артқа"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тіркеу"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Келесі"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пайдаланушы деректері"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Құпиясөз"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дұрыс электрондық пошта немесе телефон нөмірін көрсетіңіз. Телефон нөмірі халықаралық форматта көрсетіледі, мысалы: +79001234567."])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сіз сәтті тіркелдіңіз"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Енді сіз "])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["жеке кабинетке кіре аласыз"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сіздің аккаунтыңызды біздің қызметкер тексеретін болады, оны активизациялау нәтижелері туралы кейінірек хабарлаймыз"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сізге тіркеуді растау сілтемесі бар хат жіберілді"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сіздің электрондық поштаңызға"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бірінші кіруден кейін телефон нөміріңізді растау ұсынылады"])}
}