export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicitar negociação"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oferta"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendedor"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timeout"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tempo de espera para ações das partes"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxa"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dar"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receber"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicitar negociação"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar conta"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conta não selecionada"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantia"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantia fora do intervalo: "])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["min"])}
}