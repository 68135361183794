export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["рядок до 255 символів"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ціле число"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["20 цифр"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0 або 1"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["дробове число, точність два знаки, роздільник точка"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ціле число"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["рядок до 255 символів, необов'язковий"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ціле число, необов'язковий"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["рядок 32 символи"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["код платежу магазину; має бути унікальним для кожного платежу; при передачі рядка"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["унікальний номер буде згенеровано автоматично"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["номер магазину, присвоєний після реєстрації"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["номер рахунку магазину; цей рахунок також визначає, в якій валюті має бути здійснено платіж (у більшості випадків користувач може здійснити платіж у будь-якій валюті, а кошти будуть автоматично конвертовані у валюту одержувача)"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["заборонити клієнту змінювати вказану суму (1 - заборонити, 0 - дозволити)"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["сума одержувача у валюті рахунку одержувача"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["номер форми, через яку здійснюється прийом оплати"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["коментар, що відображається платнику як призначення платежу"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["додатковий необов'язковий параметр, який буде передано до скрипту результату оплати, а також на сторінки повернення"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL, на який повернеться клієнт після успішної оплати; якщо не вказано, береться з налаштувань магазину"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL, на який повернеться клієнт після помилки; якщо не вказано, береться з налаштувань магазину"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL, на який буде надіслано повідомлення про результати оплати; якщо не вказано, береться з налаштувань магазину"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["термін дії рахунку на оплату (у хвилинах), за замовчуванням 60"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["підпис повідомлення з використанням секретного ключа магазину"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0 або 1"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 - у разі успіху, 0 - в іншому випадку"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ціле число"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["код помилки (0 якщо немає помилки)"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["рядок"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["текст помилки (або пояснення до статусу оплати)"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0 або 1"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ознака того, що остаточний статус оплати ще не відомий"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["код платежу магазину"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["номер транзакції"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Протокол мерчанта"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прийом платежів на сайті можливий тільки для зареєстрованих у системі інтернет-магазинів. Для реєстрації магазину перейдіть на сторінку"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Реєстрація магазину"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перенаправлення користувача на мерчант для оплати"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для проведення оплати необхідно перенаправити користувача на URL: https://merchant.[ваш домен] і передати наступні параметри методом GET або POST:"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опис"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип даних"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Підпис"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["обчислюється шляхом конкатенації параметрів, що передаються, та хешування методом hmac з секретним ключем. Кількість і порядок параметрів, що конкатенуються, залежить від параметра"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["identified"])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["фіксована сума"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["порядок наступний:"])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ext_transact + num_shop + keyt_shop + identified + sum + payform + comment + free_param + url_success + url_decline + url_callback"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для посилання, де клієнт"])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["оплата, порядок наступний (сума не включається в підпис):"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ext_transact + num_shop + keyt_shop + identified + payform + comment + free_param + url_success + url_decline + url_callback"])},
  "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Платіжне посилання можна згенерувати на сторінці"])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'Мої магазини'"])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приклад форми:"])},
  "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повернення на сайт магазину після завершення операції"])},
  "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Якщо платник відмовляється від оплати або операція завершується з помилкою, платіжна система пропонує перейти на відповідну сторінку, вказану в налаштуваннях магазину або передану з формою"])},
  "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["url_decline"])},
  "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Якщо операція завершується успішно, перехід на сторінку"])},
  "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["url_success"])},
  "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ці скрипти також отримають результати оплати (методом, вказаним у налаштуваннях магазину. За замовчуванням - GET):"])},
  "62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва"])},
  "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опис"])},
  "64": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип даних"])},
  "65": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Підпис"])},
  "66": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["обчислюється шляхом конкатенації параметрів, що передаються, та хешування методом hmac з секретним ключем."])},
  "67": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Порядок параметрів, що конкатенуються:"])},
  "68": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ext_transact + amount + success + result + result_text + free_param"])},
  "69": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повідомлення магазину про статус оплати (callback)"])},
  "70": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Після проведення оплати результати оплати відправляються на скрипт, вказаний при реєстрації магазину"])},
  "71": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["result_url"])},
  "72": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цей скрипт отримує дані як про успішні оплати, так і у випадку помилки."])},
  "73": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ці скрипти також отримають результати оплати (методом, вказаним у налаштуваннях магазину. За замовчуванням - GET)."])},
  "74": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У деяких випадках повідомлення з результатами не приходить (наприклад, якщо клієнт пішов оплачувати і закрив сторінку браузера)."])},
  "75": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва"])},
  "76": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опис"])},
  "77": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип даних"])},
  "78": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Підпис"])},
  "79": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["обчислюється шляхом конкатенації параметрів, що передаються, та хешування методом hmac з секретним ключем."])},
  "80": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Порядок параметрів, що конкатенуються:"])},
  "81": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["transact + ext_transact + amount + success + result + result_text + free_param"])},
  "82": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обчислення підпису"])},
  "83": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Підпис формується шляхом конкатенації необхідних параметрів у вказаному порядку та хешування методом hmac."])},
  "84": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Порядок і кількість параметрів, що конкатенуються, описані вище."])},
  "85": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У наступних лістингах показані приклади функцій, що обчислюють підпис різними мовами, де конкатенований рядок, що підписується, передається параметром"])},
  "86": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лістинг 1. Обчислення підпису на PHP."])},
  "87": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лістинг 2. Обчислення підпису на Python."])},
  "88": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лістинг 3. Обчислення підпису на Perl."])},
  "89": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для оплати з"])},
  "90": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["може змінити суму"])},
  "91": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["оплата товару"])},
  "92": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оплатити"])},
  "93": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Згенерувати підпис з ключем"])},
  "94": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["signed_str"])},
  "95": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["і секретною фразою в параметрі"])},
  "96": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["key"])}
}