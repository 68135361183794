export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Données sur les actifs cryptographiques"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse du problème :"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom:"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléscripteur:"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorisations"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objectif/Mission/Objectif"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caractéristiques financières"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégorie"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Précision:"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacts"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Émission:"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personne responsable :"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Émetteur :"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coût unitaire :"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sécurité:"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achats aux distributeurs automatiques :"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ventes aux distributeurs automatiques :"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transactions avec des actifs en bourse :"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transactions d'actifs via P2P :"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse d'hébergement"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse du livre blanc"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offrant"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres contacts"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aller"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Émetteur"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Émission maximale:"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre"])}
}