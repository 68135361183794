export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kassettennummer"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kassetten-ID"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kassettensammlungsnummer"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kassettenbewertung"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hochgeladen"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summe"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menge"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summe"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geldautomat"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summe"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eigentlich"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menge"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summe"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar zur Kassette"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Daten zum Anzeigen"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerätesammlung"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gültig"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["C"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Von"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Überreste werden weggetragen"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der Rechnungen"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summe"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geldautomat"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der Rechnungen"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summe"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spender"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ablehnen/Zurückziehen"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geldscheinakzeptant"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karten ablehnen"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karten"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aktuell"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["entladen"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guthaben bei der Abholung von Geldautomaten"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerätesammlung"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gültig"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kassette im Geldautomaten installiert"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["beschlagnahmt"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Überreste werden weggetragen"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Überreste werden weggetragen"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["leere Kassette:"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haftung:"])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Informationen:"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["miteinander ausgehen"])}
}