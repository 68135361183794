export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sicherheit"])},
  "Antiphishing": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Antiphishing-Einstellung ist ein zusätzlicher Schutzmechanismus bei der Arbeit mit dem Schrank."])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die von Ihnen festgelegte Begrüßungsphrase wird im oberen Bereich jeder Schrankseite angezeigt."])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die richtige Phrase zeigt Ihnen an, dass Sie sich auf der richtigen Website befinden. Eine falsche Phrase ist ein Signal: ohne Operationen durchzuführen, müssen Sie sich dringend mit der Sicherheitsabteilung unseres Systems in Verbindung setzen."])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antiphishing aktivieren:"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anti-phishing string:"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anti-Phischen"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Änderungen werden nach der nächsten Autorisierung wirksam"])}
  },
  "IP": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die IP-Zugangseinstellung verhindert, dass Sie (oder ein Angreifer) mit dem System von einer Adresse arbeiten, die nicht auf der Liste der erlaubten Adressen steht."])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP-Adressen-beschränkter Zugang zum Schrank"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adressen hinzufügen"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse: "])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinzufügen"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adressblock: "])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bis:"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre IP: "])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adressliste"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nr."])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Von Adresse"])},
    "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bis Adresse"])},
    "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zugang"])},
    "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktionen"])},
    "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
    "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Adressen"])},
    "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
    "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verweigern"])},
    "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erlauben"])},
    "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP-Adresse eingeben"])},
    "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falsche IP-Adresse eingegeben"])},
    "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine IP-Adresse oder Adressblock angegeben."])},
    "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine IP-Adresse zur Autorisierung erlaubt. Sie können nicht auf Ihr Konto zugreifen."])},
    "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP-Zugang"])},
    "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["von:"])}
  },
  "Magic": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das magische Wort ist eine zusätzliche Sicherheitseinstellung."])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn das magische Wort aktiviert ist, wird das System bei jeder Autorisierung Sie auffordern, bestimmte Symbole (nach ihrer Reihenfolge) aus dem magischen Wort einzugeben."])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magisches Wort:"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magisches Wort abfragen:"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beim Betreten des Schranks:"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beim Bezahlen über den Händler:"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bei Verwendung der Karte:"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magisches Wort"])}
  },
  "Onekey": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einmalige Schlüssel erhöhen die Sicherheit des Systems erheblich."])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beim Betreten des Programms werden Sie nicht nur aufgefordert, den Login und das Passwort einzugeben, sondern auch den nächsten einmaligen Schlüssel. Die Aktivierung erfolgt nacheinander. Der letzte Schlüssel ist gültig, bis Sie eine neue Schlüsselserie erstellen."])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einmalige Schlüssel verwenden"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schlüsselliste"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generieren"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nr."])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schlüssel"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einmalige Schlüssel"])}
  },
  "Password": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altes Passwort:"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Passwort:"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwortbestätigung:"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"Neues Passwort\" und \"Passwortbestätigung\" stimmen nicht überein."])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort ändern"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeichenfolge von 4 bis 63 Zeichen. Erlaubte Zeichen sind lateinische Buchstaben, Zahlen und Symbole"])}
  },
  "PayPassword": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altes Passwort:"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Passwort:"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwortbestätigung:"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Passwort:"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwortbestätigung:"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"Neues Passwort\" und \"Passwortbestätigung\" stimmen nicht überein."])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort darf keine kyrillischen Buchstaben enthalten"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Bestätigungspasswort erstellen"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ändern Sie das Bestätigungspasswort"])},
    "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zur Hauptseite"])},
    "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeichenfolge von 4 bis 65 Zeichen. Erlaubte Zeichen sind lateinische Buchstaben, Zahlen und Symbole"])}
  },
  "SecretQuestion": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie das Passwort für den Zugang zum Schrank vergessen, können Sie es wiederherstellen, indem Sie die geheime Frage beantworten."])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In diesem Abschnitt können Sie die geheime Frage/Antwort ändern."])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geheime Frage"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Frage:"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antwort:"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geheime Frage"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eigene Frage"])}
  }
}