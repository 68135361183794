export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавление инкассации"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Основная информация"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Основное"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Диспенсер"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер точки"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество карт по заявке"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Купюроприёмник"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карты"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Инкассаторы"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Примечание для инкассаторов"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комментарий к инкассации"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузка вне очереди"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заменить кассету купюроприёмника"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комментарий к кассете"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комментарий к картам"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изъять все карты из кассеты Reject диспенсера карт"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изъять все карты из тубы"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер точки - обязательное поле, которое должно быть заполнено"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID кассеты 1"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID кассеты 2"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID кассеты 3"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID кассеты 4"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номинал кассеты 1"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество купюр в кассете 1"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма в кассете 1"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номинал кассеты 2"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество купюр в кассете 2"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма в кассете 2"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комментарий к кассете 1"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID кассеты"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номинал кассеты"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество купюр"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комментарий к кассете"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактирование инкассации"])}
}