export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activo interno"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activo externo"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupo"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre EN"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sinónimos"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Países"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si se completa, el anuncio estará disponible solo para usuarios de los países indicados"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vincular al activo"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unidades de medida"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abreviado"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precisión"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descripción"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalles"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección de transferencia"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mínimo"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Máximo"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Límite"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiempo de espera (min)"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El anuncio está activo."])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponible solo por enlace"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponible solo para usuarios verificados"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A la anterior"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publicar"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siguiente"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restricciones"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creación de anuncio"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu activo externo"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No vincular"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sí"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reducir tipo de cambio"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devolver"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay cuenta en tal activo"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear cuenta"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Especificar tipo de cambio"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de cambio correcto"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reducir tipo de cambio"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devolver"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mínimo mayor que el máximo"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dónde y cómo deseas recibir la transferencia. Por ejemplo: Transfiere en la aplicación bancaria al número de teléfono +349990001111 (destinatario Alexander N.)"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["doy"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["recibo"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activos"])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalles solicitados"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qué detalles debe proporcionar el tomador para que puedas hacer la transferencia"])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar un activo"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar un activo"])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Qué desea recibir: fondos en un activo interno, por ejemplo, BTC, o en un activo externo, por ejemplo, TOKEN GOLD?"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escriba un comentario sobre las características de esta transacción, por ejemplo: \\\"El activo externo solo se transfiere en un encuentro personal\\\""])},
  "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de valores a los que pertenece un activo externo, por ejemplo, \"efectivo\""])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre del activo para mostrar en la plataforma"])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El activo también puede ser llamado con palabras de la lista"])},
  "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comparación con un activo existente"])},
  "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por ejemplo: litros, piezas, horas, cajas"])},
  "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por ejemplo: L, pcs, h, caja"])},
  "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuántos dígitos pueden haber después de la coma en los valores del activo"])},
  "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha seleccionado la creación de un activo aún no existente, por favor complete sus características"])},
  "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiempo que se les da a las partes para cumplir con las condiciones"])},
  "62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un activo interno es un activo que existe dentro del sistema \\\"New Reality\\\". Un activo externo es un activo que no existe dentro del sistema \\\"New Reality\\\"."])},
  "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No vinculado"])},
  "64": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor esperado: de 1 a 2147483"])}
}