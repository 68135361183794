export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz czek elektroniczny"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto debetowe"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wartość nominalna każdego czeku"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentarz"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liczba czeków"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wstecz"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seria:"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numer:"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kod aktywacyjny:"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utworzono czeki"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pobierz plik z czekami"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz te informacje - kody aktywacyjne zostaną ukryte po zamknięciu tej strony"])}
}