export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вибрати файл"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цей формат файлу не підтримується. \nДопустимі формати:"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Невідомий формат"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Занадто великий розмір файлу, максимальний розмір:"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виберіть або перетягніть сюди файли для завантаження"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вибрати"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завантажити"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скасувати"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Файли завантажено"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["файлів"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити"])}
}