export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrare negozio"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il negozio elettronico registrato nel sistema può ricevere pagamenti dai clienti tramite il nostro sistema di merchant."])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vengono registrati come negozi i negozi elettronici, i punti di cambio e qualsiasi altra risorsa internet che desideri accettare pagamenti."])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome o marchio"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL del negozio"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Breve descrizione del negozio"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo di servizio"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negozio attivo"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiave segreta"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consenti pagamenti automatici"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invia risultati allo script"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo script"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ritorna dopo pagamento riuscito"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dopo pagamento NON riuscito"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genera"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Almeno 30 caratteri."])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizzato per verificare l'integrità e l'autenticità dei dati trasmessi dal sistema di pagamento (quando il negozio invia l'utente al merchant) e dal negozio (quando riceve i risultati del pagamento)"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successivo"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indietro"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All'elenco dei negozi"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL errato"])}
}