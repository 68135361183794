export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["模板"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查找"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在组中"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付组"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付金额"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["转账账户"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金额"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评论"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["模板"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["模板名称："])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存模板"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["转账"])}
}