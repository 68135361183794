export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations générales"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations de paiement"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signature de contrôle"])},
  "List": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes magasins"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun magasin trouvé"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un code de widget"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un lien de paiement"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le magasin"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous vraiment supprimer le magasin?"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer le magasin"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
    "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mode de paiement"])},
    "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activité"])},
    "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepter"])},
    "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
    "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])}
  },
  "New": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer le magasin"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un magasin électronique enregistré dans le système permet d'accepter des paiements des clients via le système de marchand."])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les magasins électroniques, les bureaux de change et toute autre ressource internet souhaitant accepter des paiements en leur faveur sont enregistrés en tant que magasins."])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom ou marque"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL du magasin"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description courte du magasin"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de service"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le magasin est actif"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clé secrète"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoriser les paiements automatiques"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer les résultats au script"])},
    "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse du script"])},
    "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour après un paiement réussi"])},
    "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Après un paiement échoué"])},
    "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
    "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Générer"])},
    "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Au moins 30 caractères."])},
    "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisé pour vérifier l'intégrité et l'authenticité des données transmises par le système de paiement (lorsque le magasin envoie l'utilisateur au marchand) et par le magasin (lors de la réception des résultats du paiement)"])},
    "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivant"])},
    "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour"])},
    "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À la liste des magasins"])},
    "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL incorrecte"])}
  },
  "Invoices": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des factures"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune facture trouvée"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magasin"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de magasin"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler la facture"])},
    "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code de paiement des magasins"])}
  }
}