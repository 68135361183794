export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Төлем коды/сілтемесін жасау"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дүкен операциясының нөмірі"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сома"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Түсініктеме"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қосымша параметр"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сәтті URL"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қате URL"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хабарландыру URL"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Клиент сәтті төлемнен кейін оралатын URL"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Клиент қателік болған кезде оралатын URL"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Төлем нәтижелері туралы хабарландыру жіберілетін URL"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Соманы бекіту"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Көшіру"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тексеру"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Төлем сілтемесі"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Форма"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Құжаттама"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Төлем шоттың жарамдылық мерзімі, әдепкі бойынша 60 минут"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сілтеменің әрекет ету уақыты"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сілтеме"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Форма"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виджет"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Егер сіз соманы бекітпесеңіз, төлеуші ​​оны дербес енгізе алады (мысалы, қайырымдылық төлемдері үшін жарамды)"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жасыру"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Төлем нысаны"])}
}