export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional emission"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crypto asset"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account - coverage source"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debit account"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional emission"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current emission"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TOKEN"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coverage calculation"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commission"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commission"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coverage calculation"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Binding"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic data"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coverage percentage"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Future coverage"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coverage write-off"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount that will be in reserve after issuing additional emission."])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To be written off"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current coverage"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount that will be written off from your account after issuing additional emission."])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Future coverage amount"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E — Current emission amount"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I — Additional emission"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["K — Binding rate"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["P — Coverage percentage"])}
}