export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر الملف"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تنسيق الملف هذا غير مدعوم. \nالتنسيقات المقبولة:"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صيغة غير معروفة"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حجم الملف كبير جدًا، الحجم الأقصى: "])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر أو اسحب الملفات هنا للتحميل"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحميل"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلغاء"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم تحميل الملفات"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الملفات"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمسح"])}
}