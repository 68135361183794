export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحساب المراد تعبئته"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طريقة الدفع"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم القالب:"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ القالب"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متابعة"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القوالب"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعبئة الحساب"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفاصيل التحويل"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التحقق من التحويل"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات عن التحويلات"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التعليمات"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعبئة العنوان"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العنوان المراد تعبئته"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تصدير"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلى قائمة المهام"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان التحويل"])}
}