export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Métodos de reabastecimento"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criptomoeda"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reabastecimento via P2P"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificação eletrônica"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modelos de reabastecimento"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Métodos de saída"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Em criptomoeda"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catálogo de pagamentos"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagamentos em massa"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conclusão via P2P"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modelos de conclusão"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Métodos de tradução"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Troca pela troca"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tradução para o usuário"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfira de acordo com suas contas"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modelos de tradução"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reabasteça a pontuação usando Bitcoin, Tether USDT, Ethereum, Tron ou outras criptomoedas."])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Troca sem intermediários: você traduz criptomoeda, transferência bancária ou transferência em dinheiro para outro usuário e é uma transferência interna para você."])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você adquiriu em algum lugar uma verificação eletrônica do serviço? \nAtive -o em breve e obtenha todo o valor de face às suas próprias custas. \nA propósito, você pode verificar a verificação sem ativação."])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reabasteça a pontuação usando modelos criados anteriormente. \nA propósito, os modelos podem ser criados após uma transação ou a partir da história das operações."])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remova os fundos para Bitcoin, Tether USDT, Ethereum, Tron ou outra criptomoeda."])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pague as comunicações celulares, apartamento comunal ou outros serviços."])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faça dezenas, centenas ou até milhares de operações em um clique. \nBasta baixar o arquivo com a lista de formatos do Excel."])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Troca sem intermediários: você traduz criptomoeda, transferência bancária ou transferência em dinheiro para outro usuário e é uma transferência interna para você."])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atribua os fundos usando modelos criados anteriormente. \nA propósito, os modelos podem ser criados após uma transação ou a partir da história das operações."])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você pode definir seu curso e esperar que o curso se torne exatamente isso. \nOu troca rapidamente na taxa de mercado atual."])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferir fundos para outro usuário de serviço."])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distribua facilmente fundos entre suas contas."])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfira fundos rapidamente usando modelos criados anteriormente. \nA propósito, os modelos podem ser criados após uma transação ou a partir da história das operações."])}
}