export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome:"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O nome é o nome completo do criptoativo, pois será visto por todos: o próprio emissor, compradores, vendedores, processamento, etc."])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TICKER"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uma abreviatura de 3 a 10 caracteres. \nLetras latinas maiúsculas, hífens (traços) e números são aceitáveis."])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria:"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecione a categoria que mais se adequa ao seu cripto-ativo"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formatos suportados: png, jpg, jpeg, svg. \nTamanho do arquivo não superior a 1 MB, 500*500 pixels"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descreva por que este cripto-ativo está sendo emitido pelo emissor, em qual área ele opera ou operará, qual é a sua missão"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logotipo:"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objetivo / missão / propósito:"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precisão:"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emissão:"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor por unidade:"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de casas decimais para a quantia. Por exemplo, a precisão da maioria das moedas fiduciárias, como o dólar americano ou o euro, é 2."])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de unidades do cripto-ativo emitido. O valor da emissão estará disponível imediatamente na sua conta após a conclusão da operação de criação do cripto-ativo."])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor declarado por unidade:"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garantia:"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conta:"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pessoa responsável pelas obrigações:"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decisão sobre a emissão do cripto-ativo:"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportar operações para o blockchain:"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de blockchain:"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Habilitar operações com o cripto-ativo:"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restrições/Sanções:"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indique a quais cidadãos ou residentes fiscais de quais países da lista fornecida é proibido o uso do cripto-ativo:"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outras restrições e condições:"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereço de hospedagem:"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereço do whitepaper:"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email:"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telegram:"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twitter:"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook:"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outros contatos:"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para o anterior"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvar"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Próximo"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar cripto-ativo"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome e propósito"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Características financeiras"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emissor"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportar para o blockchain"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limites e restrições"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contatos"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não definido"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vinculação fixa a moedas fiduciárias e outros ativos"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emissor (Pessoa emitindo o cripto-ativo)"])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ofertante (Pessoa distribuindo o cripto-ativo)"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remetente"])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destinatário"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eu (incluir patrocínio)"])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distribuição do cripto-ativo"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cripto-ativo significa uma representação digital de valor ou direito que pode ser transferida e armazenada eletronicamente usando tecnologia blockchain. Cripto-ativos NÃO incluem moedas fiduciárias, títulos e outros instrumentos financeiros e ativos sujeitos a regulamentação estatal separada."])},
  "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar ativo criptográfico"])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administração"])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceitação"])},
  "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motivo da recusa em aceitar"])},
  "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conta do ofertante"])},
  "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conta do ofertante na moeda vinculante"])},
  "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ofertante é o representante do emissor de um ativo nas operações de compra/venda no mercado primário. \nA conta é passiva. \nPode ser uma conta de emissão de ativos"])},
  "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A conta é passiva, vinculada em moeda. \nDeve pertencer ao mesmo usuário da conta anterior"])},
  "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permitir que os usuários comprem um ativo em caixas eletrônicos"])},
  "62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permitir a venda de ativos pelos usuários nos caixas eletrônicos"])},
  "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulário de compra pós-venda"])},
  "64": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As transações do mercado secundário são realizadas através de gateways"])},
  "65": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulário de vendas pós-venda"])},
  "66": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As transações do mercado secundário são realizadas através de gateways"])},
  "67": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permitir transações com um ativo na bolsa"])},
  "68": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permitir transações de ativos via P2P"])},
  "69": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se você é empresário individual ou representante de pessoa jurídica, é necessário baixar o arquivo de decisão de liberação de ativo digital em formato PDF. \nA solução deve conter todas as principais características do ativo virtual e ser assinada com assinatura eletrônica do empresário pessoa física ou desta pessoa jurídica. \nUm exemplo de decisão de emissão de ativo digital pode ser encontrado nas páginas do nosso site na seção \\\"Documentos\\\""])},
  "70": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colocando um ativo virtual"])},
  "71": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Substituir conta de emissão"])},
  "72": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecione nas contas do emissor"])},
  "73": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor a ser baixado:"])},
  "74": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma conta"])},
  "75": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crie uma conta"])},
  "76": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Máx. \nemissão:"])},
  "77": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O tamanho da emissão excede o máximo. \nemissões"])},
  "78": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Máx. \na emissão é menor que o tamanho da emissão ou igual a zero"])},
  "79": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulários estão ausentes"])}
}