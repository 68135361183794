export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sicurezza"])},
  "Antiphishing": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La configurazione anti-phishing è un meccanismo di protezione aggiuntivo durante l'uso del portafoglio."])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La frase di benvenuto che imposti verrà mostrata nella parte superiore di ogni pagina del portafoglio."])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La frase corretta indicherà che ti trovi sul sito corretto, mentre una frase errata sarà un segnale: non eseguire operazioni, contatta immediatamente il servizio di sicurezza del nostro sistema."])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attiva anti-phishing:"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frase anti-phishing:"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fishing anti"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le modifiche avranno effetto al prossimo accesso"])}
  },
  "IP": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La configurazione dell'accesso tramite indirizzo IP impedirà a te (o a un malintenzionato) di accedere al sistema da un indirizzo non incluso nella lista degli indirizzi autorizzati."])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restrizione dell'accesso al portafoglio tramite indirizzi IP"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi indirizzi"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo: "])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blocco di indirizzi: "])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a:"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il tuo IP: "])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista degli indirizzi"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Da indirizzo"])},
    "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A indirizzo"])},
    "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accesso"])},
    "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azioni"])},
    "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina"])},
    "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun indirizzo"])},
    "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva"])},
    "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proibisci"])},
    "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permetti"])},
    "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci l'indirizzo IP"])},
    "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo IP inserito non valido"])},
    "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun indirizzo IP o blocco di indirizzi specificato."])},
    "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun indirizzo IP autorizzato per l'accesso. Non potrai accedere al tuo conto."])},
    "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accesso tramite IP"])},
    "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["da:"])}
  },
  "Magic": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La parola magica è una configurazione di sicurezza aggiuntiva."])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se la parola magica è attivata, ogni volta che accedi al sistema, ti verrà chiesto di inserire alcuni caratteri (in base alla loro posizione) della parola magica."])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parola magica:"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richiedi la parola magica:"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durante l'accesso al portafoglio:"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durante il pagamento tramite merchant:"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durante l'uso della carta:"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parola magica"])}
  },
  "Onekey": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le chiavi monouso aumentano significativamente la sicurezza del sistema."])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al momento dell'accesso al programma, ti verrà chiesto non solo di inserire il login e la password, ma anche la chiave monouso successiva. L'attivazione avviene in sequenza. L'ultima chiave è valida fino a quando non crei una nuova serie di chiavi."])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usa chiavi monouso"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista delle chiavi"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genera"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esporta"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiave"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiavi monouso"])}
  },
  "Password": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vecchia password:"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuova password:"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma password:"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"Nuova password\" e \"Conferma password\" non coincidono."])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambio password"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stringa da 4 a 63 caratteri. Sono ammessi caratteri dell'alfabeto latino, numeri e simboli"])}
  },
  "PayPassword": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vecchia password:"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuova password:"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma password:"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuova password:"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma password:"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"Nuova password\" e \"Conferma password\" non coincidono."])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La password non deve contenere caratteri cirillici"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creazione della password di conferma"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambio della password di conferma"])},
    "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Torna alla home"])},
    "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stringa da 4 a 65 caratteri. Sono ammessi caratteri dell'alfabeto latino, numeri e simboli"])}
  },
  "SecretQuestion": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nel caso in cui dimentichi la password per accedere al portafoglio, potrai recuperarla rispondendo alla domanda segreta."])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In questa sezione puoi cambiare la domanda/risposta segreta."])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domanda segreta"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La tua domanda:"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risposta:"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domanda segreta"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La tua domanda"])}
  }
}