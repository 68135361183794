export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senha antiga:"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nova senha:"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmação de senha:"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nova senha:"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmação de senha:"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvar"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvar"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"Nova senha\" e \"Confirmação de senha\" não coincidem."])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A senha não deve conter caracteres cirílicos"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar senha de confirmação"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alterar senha de confirmação"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para a página inicial"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De 4 a 65 caracteres. Caracteres permitidos: letras latinas, números e símbolos"])}
}