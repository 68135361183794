export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запрос сделки"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предложение"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продавец"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Таймаут"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Время ожидания действий сторон"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курс"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отдаете"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получаете"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запросить сделку"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать счет"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Счет не выбран"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма не попадает в диапазон: "])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["мин"])}
}