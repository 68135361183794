export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Устройства банкомата"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить устройство"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип устройства"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комментарий"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Конфигурация"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кассеты"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить кассету"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип кассеты"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комментарий"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Конфигурация"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить устройство"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить кассету"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить устройство?"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить кассету?"])}
}