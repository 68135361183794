export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Queima de ativo"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantidade para queima"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conta para retorno"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Queimar"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantidade de queima"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A quantidade para queima não pode ser maior que a quantidade de emissão"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retorno de cobertura"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A retornar"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantidade que permanecerá na reserva após a queima do ativo"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantidade que retornará à sua conta após a queima do ativo"])}
}