export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जादुई शब्द एक अतिरिक्त सुरक्षा सेटिंग है।"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यदि जादुई शब्द सक्षम है, तो हर बार प्रमाणीकरण के समय सिस्टम आपसे जादुई शब्द के कुछ वर्ण पूछेगा।"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जादुई शब्द:"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जादुई शब्द पूछें:"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कैबिनेट में प्रवेश करते समय:"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["व्यापारी से भुगतान करते समय:"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कार्ड का उपयोग करते समय:"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सहेजें"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जादुई शब्द"])}
}