export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Börse"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marktübersicht"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ähnliche Angebote"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gegenangebote"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurs"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erhalten"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Aufträge"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letzte Geschäfte"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurs"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gegeben"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum und Uhrzeit"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich gebe"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich erhalte"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurs"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operationen"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommission"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stornieren"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wiederholen"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Angebote"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Geschäfte"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Aufträge"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vollständig ausgeführt"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verarbeitung nicht gestartet"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verarbeitung gestartet"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teilweise ausgeführt"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vollständig storniert"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teilweise ausgeführt und storniert"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erhalten"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Aufträge"])}
}