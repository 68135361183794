export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Встраиваемый виджет"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Установка"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подключить скрипт на страницу в раздел head через CDN или скачав как файл"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вызов"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вызвать функцию"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["передав в качестве аргумента JS объект"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Описание"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип данных"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пример"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["id для iframe"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["name для iframe"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["объект с параметрами для платежа"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["функция обработчик успеха операции"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["функция обработчик неуспеха операции"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес платежного сервиса"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ниже"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Еще ниже"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Возврат на сайт магазина после завершения операции"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["После завершения операции или отказа, виджет будет закрыт. Объект с параметрами ниже будет передан в callback onFail или onSuccess:"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оплатить"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Действия при успехе"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Действия при неуспехе"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес платежного сервиса"])}
}