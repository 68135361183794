export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar dirección"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Localidad"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["País"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Región"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distrito"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Municipio"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ciudad"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Territorio intraurbano"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asentamiento"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calle"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elemento adicional de dirección"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalle"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Casa"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código postal"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección"])}
}