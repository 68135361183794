export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výběr z bankomatu"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidat sbírku"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akce"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtry"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resetovat"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualizovat"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nezobrazovat archivní sbírky"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nezobrazovat zrušené sbírky"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Číslo bodu"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postavení"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádné sbírky"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádost o inkaso podána"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upravit sbírku"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Změnit stav sbírky"])}
}