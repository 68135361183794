export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मेरे स्टोर"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई स्टोर नहीं मिला"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विजेट कोड बनाएं"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भुगतान लिंक बनाएं"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संपादित करें"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्टोर हटाएं"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्या आप वाकई स्टोर को हटाना चाहते हैं?"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्टोर पंजीकरण करें"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोड"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नाम"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भुगतान मोड"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सक्रियता"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्वीकृति"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्रियाएँ"])}
}