export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodatečná emise"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kryptoaktivum"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Účet - zdroj krytí"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debetní účet"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodatečná emise"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentář"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokračovat"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuální emise"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TOKEN"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výpočet krytí"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provize"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provize"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výpočet krytí"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vazba"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Základní údaje"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procento krytí"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Budoucí krytí"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odpis krytí"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Částka, která bude v rezervě po vydání dodatečné emise."])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["K odepsání"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuální krytí"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Částka, která bude odepsána z vašeho účtu po vydání dodatečné emise."])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Budoucí částka krytí"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E — Aktuální výše emise"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I — Dodatečná emise"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["K — Vazební kurz"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["P — Procento krytí"])}
}