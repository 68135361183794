export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collecte aux guichets automatiques"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une collection"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actes"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtres"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mise à jour"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne pas afficher les collections d'archives"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne pas afficher les collections annulées"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de point"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune collecte"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demande de collecte soumise"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier la collection"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer le statut de collecte"])}
}