export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Electronic checks"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a check"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write-off account"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sum"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Series"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A comment"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actively"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not active"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No checks"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No e-receipts found"])}
}