export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طرق التجديد"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عملة مشفرة"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التجديد عبر P2P"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فحص إلكتروني"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قوالب التجديد"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طرق الإخراج"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["في العملة المشفرة"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كتالوج المدفوعات"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مدفوعات جماعية"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الخلاصة عبر P2P"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قوالب الخاتمة"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طرق الترجمة"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تبادل للتبادل"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ترجمة للمستخدم"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التحويل وفقًا لحساباتك"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قوالب الترجمة"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قم بتجديد النتيجة باستخدام Bitcoin أو Tether USDT أو Ethereum أو Tron أو غيرها من العملات المشفرة."])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التبادل بدون الوسطاء: تقوم بترجمة العملة المشفرة أو التحويل المصرفي أو تحويل النقود إلى مستخدم آخر ، وهو تحويل داخلي لك."])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل اكتسبت في مكان ما فحص إلكتروني للخدمة؟ \nقم بتفعيله قريبًا واحصل على القيمة الاسمية بالكامل على نفقتك الخاصة. \nبالمناسبة ، يمكنك التحقق من الشيك دون تنشيط."])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تجديد النتيجة باستخدام القوالب التي تم إنشاؤها مسبقًا. \nبالمناسبة ، يمكن إنشاء قوالب بعد معاملة أو من تاريخ العمليات."])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قم بإزالة الأموال إلى Bitcoin أو Tether USDT أو Ethereum أو Tron أو غيرها من العملة المشفرة."])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دفع الاتصالات الخلوية ، الشقة الجماعية أو الخدمات الأخرى."])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اصنع العشرات أو المئات أو حتى الآلاف من العمليات بنقرة واحدة. \nفقط قم بتنزيل الملف مع قائمة تنسيق Excel."])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التبادل بدون الوسطاء: تقوم بترجمة العملة المشفرة أو التحويل المصرفي أو تحويل النقود إلى مستخدم آخر ، وهو تحويل داخلي لك."])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعيين الأموال باستخدام القوالب التي تم إنشاؤها مسبقًا. \nبالمناسبة ، يمكن إنشاء قوالب بعد معاملة أو من تاريخ العمليات."])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكنك ضبط الدورة التدريبية والانتظار حتى تصبح الدورة التدريبية. \nأو تبادل بسرعة في سعر السوق الحالي."])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحويل الأموال إلى مستخدم خدمة آخر."])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قم بتوزيع الأموال بسهولة بين حساباتك."])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحويل الأموال بسرعة باستخدام القوالب التي تم إنشاؤها مسبقًا. \nبالمناسبة ، يمكن إنشاء قوالب بعد معاملة أو من تاريخ العمليات."])}
}