export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse auswählen"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ort"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stornieren"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Region"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezirk"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kreis"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stadt"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stadtteil"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ort"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Straße"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zusätzliche Adresskomponente"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erklärung"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haus"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postleitzahl"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])}
}