export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आईपी-एड्रेस से एक्सेस सेटिंग आपको (या हैकर को) किसी सूचीबद्ध पते से काम करने से रोक देगी।"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आईपी-एड्रेस के माध्यम से एक्सेस प्रतिबंध"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पते जोड़ें"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पता:"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जोड़ें"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पते का ब्लॉक:"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["से:"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आपका आईपी:"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पते की सूची"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्रमांक"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पते से"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पते तक"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एक्सेस"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्रियाएँ"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हटाएं"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई पते नहीं"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सहेजें"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रतिबंधित करें"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अनुमति दें"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आईपी पता दर्ज करें"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आईपी पता गलत है"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई आईपी पता या पते का ब्लॉक निर्दिष्ट नहीं है।"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई आईपी पता प्रमाणित नहीं है। आप अपने खाते तक पहुंच प्राप्त नहीं कर सकेंगे।"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आईपी के माध्यम से एक्सेस"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["से:"])}
}