export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Massenüberweisungsdienst ermöglicht es Ihnen, schnell Geld an andere Benutzer zu überweisen und Zahlungsoperationen an Dienstleister durchzuführen."])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Im Abschnitt"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsdatei hochladen"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["können Sie eine Datei im Excel-Format (XLS, XLSX, CSV) oder XML hochladen."])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufgabenliste"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ermöglicht es Ihnen, die Status früherer Anfragen für Massenüberweisungen anzuzeigen."])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können eine Datei in einem Tabelleneditor wie Excel erstellen."])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beispieldatei herunterladen"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wichtige Hinweise:"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dateiformat - CSV, XLS oder XLSX."])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Datei sollte keine Überschriften haben."])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Damit lange Zahlen nicht im Exponentialformat dargestellt werden (z. B. \"5,46546E+15\") und führende Nullen nicht abgeschnitten werden, können Sie vor der Zahl ein einfaches Anführungszeichen (') setzen oder der Zelle vor der Eingabe der Daten das Textformat zuweisen."])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spaltenformat für Zahlungen an Dienstleister"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste der Spalten in der Datei:"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betreiber-Code"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Wird aus der Liste der Betreiber ausgewählt"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hier"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". (Für alle Mobilfunkbetreiber können Sie den Betreiber-Code 7000 angeben - in diesem Fall wird der Betreiber automatisch anhand der Telefonnummer bestimmt.)"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betrag"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Сумма указывается в тикере актива, с которого осуществляется перевод. Если тикер счета, на который переводятся активы, отличается от тикера счета, выбранного для перевода, к операции автоматически применяется системный коэффициент конвертации."])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungskommentar"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Optional, kann leer gelassen werden; Länge nicht mehr als 255 Zeichen."])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsparameter 1"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". \nDer Wert des vom Bediener erforderlichen Parameters, beispielsweise die Telefonnummer oder die Adresse des kryptoaktuellen, wird vorgeschrieben. \nViele Betreiber sind auf einen Zahlungsparameter beschränkt. Wenn jedoch die Anzahl der Parameter mehr als einen beträgt, müssen Sie sie alle in den benachbarten Spalten angeben."])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsparameter 2"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsparameter N"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können die Zahlungsparameter anzeigen, indem Sie zur Zahlung eines bestimmten Betreibers gehen:"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spaltenformat für Massenüberweisungen an Benutzer"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betreiber-Code"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Geben Sie 0 ein oder lassen Sie es leer."])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betrag"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Сумма указывается в тикере счета, с которого осуществляется перевод актива. Если такого тикера на счетах получателя нет, к операции автоматически применяется системный коэффициент конвертации."])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungskommentar"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Optional, kann leer gelassen werden; Länge nicht mehr als 255 Zeichen."])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empfängerkonto"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Hier geben Sie die Kontonummer des Benutzers ein, dem die Überweisung erfolgen soll (20 Ziffern)."])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spaltenformat für Massenauszahlungen durch einen Kreditagenten"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Diese Zeile zeigt an, dass die Zahlungen Kredite sind."])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betrag"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Der Betrag wird in der Währung des Kreditprodukts angegeben."])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kreditkommentar"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Optional, kann leer gelassen werden; Länge nicht mehr als 255 Zeichen."])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Darlehensnehmer-ID"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Bitte beachten Sie, dass der Darlehensnehmer einen digitalen Pass Mfo-BorrowPerson haben muss."])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kreditprodukt-Code"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Sie können ihn in der Liste der Kreditprodukte bei manueller Darlehensvergabe sehen."])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeitraum"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Nur die Anzahl der Perioden (welche Perioden verwendet werden, ist im Kreditprodukt festgelegt, z. B. Tage, Wochen, Monate usw.)"])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betreiber-Code (Formularnummer)"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Das Ausfüllen dieses und der folgenden Felder ist optional. Wenn sie nicht ausgefüllt werden, kann der Darlehensnehmer das Darlehen später selbstständig über sein persönliches Konto abheben."])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Im Fehlerfall stornieren"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Stornieren (1) des gewährten Darlehens, wenn die Auszahlungs-Transaktion fehlschlägt, oder für eine spätere selbstständige Auszahlung belassen (0)."])},
  "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsparameter 1"])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Geben Sie den vom Betreiber geforderten Parameterwert ein, z. B. Telefonnummer. Viele Betreiber beschränken sich auf einen Zahlungsparameter, aber wenn die Anzahl der Parameter mehr als eins beträgt, müssen Sie alle in den benachbarten Spalten zum ersten Parameter angeben."])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsparameter 2"])},
  "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsparameter N"])},
  "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Datei muss die Konten oder Adressen der Krypto-Assets von Empfängern in einer Währung angeben, da die Abschreibung von Geldern von einem Konto und nicht von mehreren ausgeführt wird."])}
}