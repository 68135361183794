export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto przelewu:"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kwota:"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentarz:"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ochrona transakcji"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kod:"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generuj"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czas ochrony:"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dni"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa szablonu:"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz jako szablon"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przelej"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szablony"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przelew do innego użytkownika"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kod ochrony — to kod, który musi wprowadzić odbiorca, aby zakończyć transakcję. Chroniony przelew odbiorca zobaczy od razu w sekcji \"Historia operacji\", ale będzie mógł skorzystać z pieniędzy dopiero po wprowadzeniu kodu ochrony w karcie transakcji. Jeśli ważność płatności wygasła, środki wracają do nadawcy."])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres przelewu"])}
}