export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мәміле коды"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Беремін"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аламын"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бағыты"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курс"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Таймаут"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Әрекеттер"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Белсенді мәмілелер жоқ"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Менің мәмілелерім"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Белсенді"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ішкі актив"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Белсенді емес"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Корреспондент"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пути"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кезеңі"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сүзгілер"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жаңарту"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мен беремін"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["= тең"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≠ тең емес"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["> Қосымша"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≥ көп немесе тең"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["< Аз"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≤ аз немесе тең"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мен түсінемін"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сома"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активті таңдаңыз"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Менің рөлім"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бірі"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жасаушы"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Алушы"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ішкі актив"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Барлық опциялар"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мен түсінемін"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мен беремін"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жасаушы идентификатор"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мәртебе"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бірі"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хабарландыру нөмірі"])}
}