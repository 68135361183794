export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des factures"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune facture trouvée"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magasin"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de magasin"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler la facture"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code de paiement des magasins"])}
}