export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["घोषणा"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विवरण"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विवरण"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["न्यूनतम"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अधिकतम"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["राशि सीमा"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रतीक्षा समय (मिनट)"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["घोषणा सक्रिय है"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["केवल लिंक के माध्यम से उपलब्ध"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["केवल सत्यापित उपयोगकर्ताओं के लिए उपलब्ध"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रद्द करें"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सहेजें"])}
}