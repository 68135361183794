export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的商店"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未找到商店"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建小部件代码"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建支付链接"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编辑"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除商店"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您确定要删除商店"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注册商店"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["代码"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名称"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付模式"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活动"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["接受"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作"])}
}