export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sammlung hinzufügen"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundlegende Informationen"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundlagen"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spender"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punktnummer"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der Karten pro Anwendung"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geldscheinakzeptant"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karten"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geldsammler"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinweis für Sammler"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar zur Sammlung"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laden außerhalb der Reihenfolge"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tauschen Sie die Geldscheinkassette aus"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar zur Kassette"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar zu den Karten"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entfernen Sie alle Karten aus der Ausgabekassette für abgelehnte Karten"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entfernen Sie alle Karten aus der Röhre"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Punktnummer ist ein Pflichtfeld, das ausgefüllt werden muss"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kassetten-ID 1"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kassetten-ID 2"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kassetten-ID 3"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kassetten-ID 4"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kassettenbewertung 1"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der Scheine in Kassette 1"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menge in Kassette 1"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kassettenbewertung 2"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der Scheine in Kassette 2"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar zu Band 1"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kassetten-ID"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kassettenbewertung"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der Rechnungen"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summe"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar zur Kassette"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sammlung bearbeiten"])}
}