export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Методи поповнення"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Криптовалюта"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поповнення через p2p"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Електронна перевірка"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаблони поповнення"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Методи виходу"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У криптовалюті"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Каталог платежів"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Масові платежі"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Висновок через p2p"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаблони висновку"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Методи перекладу"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обмін на обмін"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переклад користувачеві"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перекладіть за вашими рахунками"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаблони перекладу"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поповніть партитуру за допомогою біткойна, прив’язки USDT, Ethereum, TRON або інших криптовалют."])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обмін без посередників: Ви перекладаєте криптовалюту, банківський переказ або передачу готівки іншому користувачеві, і це внутрішній переказ вам."])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви придбали десь електронну перевірку послуги? \nАктивуйте його найближчим часом і отримайте всю номінальну вартість за власний рахунок. \nДо речі, ви можете перевірити перевірку без активації."])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поповнити рахунок за допомогою раніше створених шаблонів. \nДо речі, шаблони можуть бути створені після транзакції або з історії операцій."])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видаліть кошти на біткойн, прив’язати USDT, Ethereum, Tron або іншу криптовалюту."])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Платіть стільниковий комунікацію, комунальну квартиру чи інші послуги."])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зробіть десятки, сотні чи навіть тисячі операцій одним клацанням. \nПросто завантажте файл зі списком формату Excel."])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обмін без посередників: Ви перекладаєте криптовалюту, банківський переказ або передачу готівки іншому користувачеві, і це внутрішній переказ вам."])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Призначте кошти за допомогою раніше створених шаблонів. \nДо речі, шаблони можуть бути створені після транзакції або з історії операцій."])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви можете встановити свій курс і чекати, коли курс стане саме цим. \nАбо швидкість обміну за поточною ринковою ставкою."])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Передача коштів іншому користувачеві послуги."])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Легко розподіляти кошти між своїми обліковими записами."])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Трансферні кошти швидко за допомогою раніше створених шаблонів. \nДо речі, шаблони можуть бути створені після транзакції або з історії операцій."])}
}