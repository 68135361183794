export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stav"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téma"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poslední zpráva"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akce"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádné žádosti"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priorita"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téma"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text zprávy"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soubory"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Můžete nahrát jeden nebo více souborů. Maximální velikost souboru je 10 MB."])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odeslat"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nízká"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normální"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vysoká"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technická podpora"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivní žádosti"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archiv"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nová žádost"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zpracovává se operátorem"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádost vytvořena"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Probíhá práce, čekejte na odpověď od operátora"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádost uzavřena"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádost uzavřena"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádné nové zprávy"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nová zpráva od operátora"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádné nové zprávy"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odeslat do archivu"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Všechny žádosti"])}
}