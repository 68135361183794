export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TICKER"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Missione"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azioni"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista dei crypto-asset"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun crypto-asset"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emissione token"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accetta"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mercato"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precisione"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primario"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secondario"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbinato con altro asset"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni dettagliate sul crypto-asset"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica dati del crypto-asset"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emissione aggiuntiva"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina crypto-asset"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista dei crypto-asset"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accetta"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attività"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sì"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I token possono essere acquistati al distributore"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I token possono essere venduti al distributore"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token del mercato secondario"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizzazione token"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti accettati"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti i miei"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UID utente"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bruciatura asset"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per utente specifico"])}
}