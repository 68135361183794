export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хабарландыруларды баптау"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қосу"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операциялар туралы хабарландыру"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Авторизация (жеке кабинетке кіру, мобильді қосымша және т.б.)"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"Картаны пайдалана отырып қаржылық операцияларды орындау\" оқиғасы кезінде хабарландыру"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шығындар (кез келген төлемдер, аударымдар, қызметтерді төлеу және т.б.)"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ақша қаражаттарының түсуі"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ішкі пошта арқылы хат алу"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Төлеуге шот түсуі"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қауіпсіздік баптауларын өзгерту"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операцияларды хабарламадан алынған кодпен растау"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Авторизацияны хабарламадан алынған кодпен растау"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"Картаны пайдалана отырып қаржылық операцияларды орындау\" кезіндегі растау"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қауіпсіздік баптауларын хабарламадан алынған кодпен өзгерту"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шығындарды хабарламадан алынған кодпен растау"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Өзгерістер сақталмады, сақтау керек пе?"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сақтау"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сақтамау"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сақтау"])}
}