export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das magische Wort ist eine zusätzliche Sicherheitseinstellung."])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn das magische Wort aktiviert ist, wird das System bei jeder Autorisierung Sie auffordern, bestimmte Symbole (nach ihrer Reihenfolge) aus dem magischen Wort einzugeben."])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magisches Wort:"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magisches Wort abfragen:"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beim Betreten des Schranks:"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beim Bezahlen über den Händler:"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bei Verwendung der Karte:"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magisches Wort"])}
}