export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Способы пополнения"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Криптовалютой"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Описание"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пополнение через P2P"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Электронный чек"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаблоны пополнения"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Способы вывода"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В криптовалюту"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Каталог платежей"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Массовые платежи"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вывод через P2P"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаблоны вывода"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Способы перевода"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обмен на бирже"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перевод пользователю"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перевод по своим счетам"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаблоны перевода"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пополняйте счет с помощью Bitcoin, Tether USDT, Ethereum, Tron или другой криптовалюты."])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обмен без посредников: вы переводите другому пользователю криптовалюту, банковский перевод или передаете наличные, а он вам - внутренний перевод."])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приобрели где-то электронный чек сервиса? Активируйте его скорее и получите весь номинал на свой счет. Кстати, проверить чек можно и без активации."])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пополняйте счет, используя созданные ранее шаблоны. Кстати, шаблоны можно создавать после сделки или из Истории операций."])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выводите средства на Bitcoin, Tether USDT, Ethereum, Tron или другую криптовалюту."])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оплачивайте сотовую связь, коммуналку или другие услуги."])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Делайте десятки, сотни или даже тысячи операций в один клик. Просто загрузите файл со списком платежей в формате Excel."])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обмен без посредников: вы переводите другому пользователю криптовалюту, банковский перевод или передаете наличные, а он вам - внутренний перевод."])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выводите средства, используя созданные ранее шаблоны. Кстати, шаблоны можно создавать после сделки или из Истории операций."])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Можно задать свой курс и ждать, когда курс станет именно таким. Или обменяйте быстро по текущему рыночному курсу."])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переводите средства другому пользователю сервиса."])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Легко распределяйте средства между своими счетами."])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переводите средства быстро, используя созданные ранее шаблоны. Кстати, шаблоны можно создавать после сделки или из Истории операций."])}
}