export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conto di trasferimento:"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo:"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commento:"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protezione della transazione"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice:"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genera"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durata della protezione:"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["giorni"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome del modello:"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva come modello"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trasferisci"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modelli"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trasferimento a un altro utente"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il codice di protezione è il codice che il destinatario deve inserire per completare la transazione. Il trasferimento protetto sarà subito visibile nella sezione 'Storia delle operazioni', ma il denaro potrà essere utilizzato solo dopo l'inserimento del codice di protezione nella scheda della transazione. Se il pagamento scade, i fondi vengono restituiti al mittente."])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo del trasferimento"])}
}