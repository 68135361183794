export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Aktiva"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Externes Aktiv"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppe"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name RU"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synonyme"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Länder"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn ausgefüllt, wird die Anzeige nur für Benutzer aus den angegebenen Ländern verfügbar sein"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Binden Sie es an einen Vermögenswert"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maßeinheiten"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abkürzung"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genauigkeit"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überweisungsrichtung"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limit"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wartezeit (Minuten)"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzeige ist aktiv"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur über den Link verfügbar"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur für verifizierte Benutzer verfügbar"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zum vorherigen"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richtung"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschränkungen"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzeige erstellen"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eigenes externes Aktiv"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht anbinden"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurs senken"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurücksetzen"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kein Konto in solchem Aktiv"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto erstellen"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurs angeben"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurs ist korrekt"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurs senken"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurücksetzen"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum größer als Maximum"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohin und wie möchten Sie die Überweisung erhalten. Zum Beispiel: Überweisen Sie in der Bankanwendung an die Telefonnummer +499990001111 (Empfänger Alexander N.)"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich übergebe die"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erhalte"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiva"])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angeforderte Angaben"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welche Angaben der Taker senden muss, damit Sie ihm die Überweisung machen können"])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset auswählen"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset auswählen"])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Was möchten Sie erhalten: Mittel in einem Innenaktiv, zum Beispiel BTC, oder in einem Außenaktiv, zum Beispiel TOKEN GOLD?"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentieren Sie die Besonderheiten dieser Transaktion, zum Beispiel: \\\"Das Außenaktiv wird nur bei einem persönlichen Treffen übertragen\\\""])},
  "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Art der Werte, zu der ein externes Vermögenswert gehört, zum Beispiel \"Bargeld\""])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name des Aktivs zur Anzeige auf der Plattform"])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Aktiv kann auch mit Wörtern aus der Liste bezeichnet werden"])},
  "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abgleich mit einem bestehenden Aktiv"])},
  "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beispiel: Liter, Stück, Stunden, Boxen"])},
  "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beispiel: L, St, Std, Box"])},
  "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wie viele Dezimalstellen kann ein Vermögenswert habt"])},
  "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben die Erstellung eines noch nicht existierenden Aktivs gewählt, bitte füllen Sie seine Merkmale aus"])},
  "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeit, die den Parteien zur Erfüllung der Bedingungen gegeben wird"])},
  "62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Innenaktiv ist ein Aktiv, das im System \\\"New Reality\\\" existiert. Ein Außenaktiv ist ein Aktiv, das nicht im System \\\"New Reality\\\" existiert."])},
  "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht mit einem anderen Vermögenswert abgeglichen"])},
  "64": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erwarteter Wert: von 1 bis 2147483"])}
}