export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerando código/link de pagamento"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número da operação da loja"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantia"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentário"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parâmetro adicional"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL de sucesso"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL de falha"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL de notificação"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL para onde o cliente retornará após pagamento bem-sucedido"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL para onde o cliente retornará após falha"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL para onde será enviada a notificação dos resultados do pagamento"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixar quantia"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiar"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificar"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link de pagamento"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forma"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documentação"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validade da conta de pagamento em minutos, padrão é 60"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validade do link"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forma"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Widget"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se você não fixar o valor, o pagador poderá inseri-lo de forma independente (adequado, por exemplo, para pagamentos de caridade)"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esconder"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulário de pagamento"])}
}