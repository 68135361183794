export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número da cassete"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID da cassete"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de coleção de cassetes"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classificação de cassete"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviado"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soma"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servidor"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantidade"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soma"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caixa eletrônico"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soma"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na verdade"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantidade"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soma"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentário sobre a cassete"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não há dados para exibir"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coleta de dispositivos"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["válido"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["c"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["os restos mortais são levados embora"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servidor"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de contas"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soma"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caixa eletrônico"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de contas"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soma"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentário"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distribuidor"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejeitar/Retrair"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceitante de contas"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejeitar cartões"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cartões"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["atual"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["descarregado"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldos de cobrança em caixas eletrônicos"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coleta de dispositivos"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["válido"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cassete instalada em ATM"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["apreendido"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["os restos são levados"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["os restos são levados"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cassete vazia:"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["adesão:"])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informações adicionais:"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a data"])}
}