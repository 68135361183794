export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metody doplňování"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kryptoměra"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doplnění přes P2P"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elektronická kontrola"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Šablony doplňování"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metody výstupu"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V kryptoměně"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Katalog plateb"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hromadné platby"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Závěr přes P2P"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Závěr šablony"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metody překladu"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výměna za burzu"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Překlad na uživatele"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Převod podle vašich účtů"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Translační šablony"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doplňte skóre pomocí bitcoinů, postroje USDT, Ethereum, Tron nebo jiných kryptoměn."])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výměna bez zprostředkovatelů: Překládáte kryptoměnu, bankovní převod nebo převod hotovosti jinému uživateli a je to interní převod na vás."])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Získali jste někde elektronickou kontrolu služby? \nAktivujte jej brzy a získejte celou nominální hodnotu na vlastní náklady. \nMimochodem, můžete zkontrolovat kontrolu bez aktivace."])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doplňte skóre pomocí dříve vytvořených šablon. \nMimochodem, šablony mohou být vytvořeny po transakci nebo z historie operací."])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odstraňte finanční prostředky na bitcoiny, postroj USDT, Ethereum, Tron nebo jiné kryptoměny."])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plaťte buněčnou komunikaci, komunální byt nebo jiné služby."])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proveďte desítky, stovky nebo dokonce tisíce operací jedním kliknutím. \nStačí si stáhnout soubor se seznamem Formátu Excel."])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výměna bez zprostředkovatelů: Překládáte kryptoměnu, bankovní převod nebo převod hotovosti jinému uživateli a je to interní převod na vás."])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přiřaďte prostředky pomocí dříve vytvořených šablon. \nMimochodem, šablony mohou být vytvořeny po transakci nebo z historie operací."])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Můžete nastavit kurz a počkat, až se kurz stane právě tím. \nNebo si rychle vyměňte za současnou tržní sazbu."])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Převést prostředky na jiného uživatele služby."])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snadno distribuovat prostředky mezi vaše účty."])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Převod fondů rychle pomocí dříve vytvořených šablon. \nMimochodem, šablony mohou být vytvořeny po transakci nebo z historie operací."])}
}