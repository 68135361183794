export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfiguracja dostępu na podstawie adresu IP uniemożliwi Tobie (lub napastnikowi) pracę z systemem z adresu, który nie jest wymieniony na liście dozwolonych."])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ograniczenie dostępu do konta na podstawie adresów IP"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj adresy"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres: "])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blok adresów: "])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["do:"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twój IP: "])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista adresów"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nr"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Od adresu"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do adresu"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostęp"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Działania"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak adresów"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zabroń"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zezwól"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wprowadź adres IP"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieprawidłowy adres IP"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie określono żadnego adresu IP ani bloku adresów."])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Żaden adres IP nie jest dozwolony do autoryzacji. Nie będziesz mieć dostępu do swojego konta."])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostęp na podstawie IP"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["od:"])}
}