export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виберіть адресу"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Населений пункт"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адреса"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скасувати"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберегти"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Країна"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регіон"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Округ"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Район"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Місто"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Внутрішньоміська територія"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Населений пункт"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вулиця"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додатковий елемент адреси"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уточнення"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Будинок"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поштовий індекс"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адреса"])}
}