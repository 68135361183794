export default {
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dát částku"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Částka"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostat částku"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtry"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obnovit"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["obchodů"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obchod"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádné nabídky"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["P2P nabídky"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID prodávajícího"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kód nabídky"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivum"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popis"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prodávající je ověřen"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pouze pro ověřené uživatele"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nelze provést obchod sám se sebou"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vybrat aktivum"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informace o oznámení"])}
}