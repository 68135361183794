export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni sull'annuncio"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni di base"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Base"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiuntivo"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di creazione"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attivo"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accetta"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di inizio dell'affare"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di fine dell'affare"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasso"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creatore"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dà"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["riceve"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponibile solo tramite link"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponibile solo per utenti verificati"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["limiti"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proprietario dell'annuncio"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrizione"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timeout"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["giorni"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ore"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minuti"])}
}