export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documentation"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protocole du Marchand"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redirection vers le marchand"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour à la boutique"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notification de la boutique"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calcul de signature"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Widget intégré"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installation"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appel"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réponse de la boutique"])}
}