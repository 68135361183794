<template>
    <div>
        <div class='d-flex justify-content-between neue'>
            <h4>{{ $t('components.service.modal.BurnEmission.1') }}</h4>
            <i class='big item-i x_red pointer' v-on:click='this.close'></i>
        </div>
        <div class='container-fluid mt-1 p-0 g-3'>
            <h4 class='p-0 mt-4 color-menu'>{{ this.$t('components.service.modal.Emission.15') }}</h4>
            <div class="d-flex align-items-md-center col-12 mt-3 neue-roman flex-wrap">
                <div class="col-md-3 col-12">{{ this.$t('components.service.modal.Emission.8') }}</div>
                <div class="col-md-9 col-12 d-flex justify-content-between align-items-md-center">
                    <div>
                        <span class="neue-bold">{{ this.sub_data.current_emission }}</span> 
                        {{ this.sub_data.ticker }}
                    </div>
                    <div v-if="this.sub_data.bind_fiat_curr && this.sub_data.cover_percent">
                        <span class="mr-2">{{ this.$t('components.service.modal.Emission.21') }}</span>
                        <span class="neue-bold">{{ this.calculatingCurrencyCoverage() }}</span> 
                        {{ this.sub_data.bind_fiat_curr_text }}
                    </div>
                </div>
            </div>
            <div class="position-relative curr-wrapper-element">
                <Input
                    type='text'
                    :dataValue='this.data.burn_amount'
                    :invalid='this.summWarn'
                    :invalidHint='this.summWarn'
                    :label="$t('components.service.modal.BurnEmission.5')"
                    id='burn_amount'
                    @updateParentState="this.updateState"
                />
                <div class="position-absolute curr-element">
                    {{ this.sub_data.ticker }}
                </div>
            </div>
            <h4 class='p-0 mt-4 color-menu'>{{ this.$t('components.service.modal.Emission.11') }}</h4>
            <Comiss :sum="this.data.burn_amount" 
                ref="comiss"
                @updateParentState='this.setSummWarn' 
                @onSuccess="this.readyCheck"
                :params = "this.setParams()"
                :cmd_name="'burn_custom_curr'"
                :precision="'2'"
                :curr="this.sub_data.bind_fiat_curr_text"
            />
            <Select
                :label="$t('components.service.modal.Emission.4')"
                id='commission_from_keyt'
                :keyt_thin="true"
                :dataValue='this.data.commission_from_keyt'
                :keywords.sync='this.keywords'
                @updateParentState="this.updateState"
            />
            <div v-if="this.sub_data.bind_fiat_curr && this.sub_data.cover_percent">
                <h4 class='p-0 mt-4 color-menu'>{{ this.$t('components.service.modal.Emission.10') }}</h4>
                <div class="d-flex col-12 mt-3 flex-wrap">
                    <div class="col-md-3 col-12 neue-roman">{{ this.$t('components.service.modal.Emission.14') }}</div>
                    <div class="col-md-9 col-12 flex-column flex-md-row d-flex justify-content-between align-items-md-center">
                        <div>
                            {{ this.sub_data.bind_custom_count }}
                            {{ this.sub_data.ticker }}
                            &nbsp;=&nbsp;
                            {{ this.sub_data.bind_fiat_count }}
                            {{ this.sub_data.bind_fiat_curr_text }}
                        </div>
                        <div>
                            <span class="neue-roman">{{ this.$t('components.service.modal.Emission.16') }}</span>
                            {{ this.sub_data.cover_percent }} %
                        </div>
                    </div>
                </div>
                <div v-if="this.data.burn_amount" class="d-flex col-12 mt-3 align-items-center flex-wrap">
                    <div class="col-md-3 col-12 neue-roman">{{ this.$t('components.service.modal.Emission.17') }}</div>
                    <div class="col-md-9 col-12 d-flex justify-content-between align-items-md-center">
                        <div>
                            ( <span class="color-active">{{ this.sub_data.current_emission }}</span> {{ this.sub_data.ticker }} -
                            <span class="color-active">{{ this.data.burn_amount }}</span> {{ this.sub_data.ticker }} ) × 
                            <span class="color-active">{{ this.sub_data.bind_fiat_count }}</span> {{ this.sub_data.bind_fiat_curr_text }} ×
                            <span class="color-active">{{ this.sub_data.cover_percent }}</span> % = 
                            <span class="color-active">{{ this.calculateCoverage() }}</span>
                            {{ this.sub_data.bind_fiat_curr_text }}
                        </div>
                        <div>
                            <Tooltip :content="this.$t('components.service.modal.BurnEmission.9')" :smart_icon="true" :placement="'right'"/>
                        </div>
                    </div>
                </div>
                <div class="mt-3 p-3 grey-block">
                    <div class="text-center mb-3">
                        ( <span class="color-active">E</span> - <span class="color-active">B</span> ) × 
                        <span class="color-active">K</span> × <span class="color-active">P</span> = 
                        <span class="color-active">{{ this.$t('components.service.modal.Emission.23') }}</span>
                    </div>
                    <div class="neue-roman">
                        <div>{{ this.$t('components.service.modal.Emission.24') }}</div>
                        <div>{{ this.$t('components.service.modal.BurnEmission.6') }}</div>
                        <div>{{ this.$t('components.service.modal.Emission.26') }}</div>
                        <div>{{ this.$t('components.service.modal.Emission.27') }}</div>
                    </div>
                </div>
                <h4 class='p-0 mt-4 color-menu'>{{ this.$t('components.service.modal.BurnEmission.7') }}</h4>
                <div v-if="this.data.burn_amount" class="d-flex col-12 mt-3 align-items-center flex-wrap">
                    <div class="col-md-3 col-12 neue-roman">{{ this.$t('components.service.modal.BurnEmission.8') }}</div>
                    <div class="col-md-9 col-12 d-flex justify-content-between align-items-md-center">
                        <div>
                            <span class="color-active">{{ this.calculatingCurrencyCoverage() }}</span> {{ this.sub_data.bind_fiat_curr_text }} -
                            <span class="color-active">{{ this.calculateCoverage() }}</span> {{ this.sub_data.bind_fiat_curr_text }} = 
                            <span class="color-active">{{ this.calculateReverse() }}</span>
                            {{ this.sub_data.bind_fiat_curr_text }}
                        </div>
                        <div>
                            <Tooltip :content="this.$t('components.service.modal.BurnEmission.10')" :smart_icon="true" :placement="'right'"/>
                        </div>
                    </div>
                </div>
                <Select
                    :label="$t('components.service.modal.BurnEmission.3')"
                    id='cover_return_keyt'
                    :keyt_thin="true"
                    :dataValue='this.data.cover_return_keyt'
                    :keywords.sync='this.keywords'
                    @updateParentState="this.updateState"
                />
            </div>
            <div class="row container-fluid g-3 align-items-center justify-content-center input mt-2">
                <div class="col-sm-2">
                    <Button 
                        :name="$t('components.service.modal.Emission.7')" 
                        v-on:click="this.burnDo" 
                        type='btn-warning btn' 
                        :disabled='!this.ready'
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Button from '@/components/service/Button';
import Input from '@/components/service/Input';
import Comiss from '@/components/service/Comiss';
import Select from '@/components/service/Select';
import Tooltip from '@/components/service/Tooltip';

export default {
    components: {
        Button,
        Input,
        Comiss,
        Select,
        Tooltip
    },
    props: {
        close: [Function],
    }, 
    computed: {
        t () {
            let k = this.$store.getters.get_keyts_keywords;
            if (k.length > 0) {
                this.getKeyts(k);
            }
            return k;
        }
    },
    data () {
        return {
            curr: this.$store.state.modal.content.curr,
            currs: this.$store.state.modal.content.currs,
            keywords: [],
            data: {
                comment: '',
                commission_from_keyt: '',
                cover_return_keyt: '',
                custom_curr: this.$store.state.modal.content.curr.curr_code,
                burn_amount: '',
                auto_rate: '1'
            },
            sub_data: {
                current_emission: this.$store.state.modal.content.curr.current_emission,
                ticker: this.$store.state.modal.content.curr.ticker,
                bind_custom_count: this.$store.state.modal.content.curr.bind_custom_count,
                bind_fiat_count: this.$store.state.modal.content.curr.bind_count,
                bind_fiat_curr: this.$store.state.modal.content.curr.bind_curr,
                bind_fiat_curr_text: this.$store.state.modal.content.curr.bind_curr_text,
                cover_percent: this.$store.state.modal.content.curr.cover_percent,
            },
            summWarn: false,
            ready: false,
        }
    },
    watch: {
        t: {
            handler: function(newData, oldData) {
                // console.log(newData)
                this.getKeyts(newData);
            },
            deep: true,
        }
    },
    methods: {
        calculateCoverage() {
            return (Number(this.sub_data.current_emission) - Number(this.data.burn_amount)) * Number(this.sub_data.bind_fiat_count) * Number(this.sub_data.cover_percent) / 100;
        },
        calculatingCurrencyCoverage() {
            return Number(this.sub_data.current_emission) * Number(this.sub_data.bind_fiat_count)* Number(this.sub_data.cover_percent) / 100;
        },
        calculateReverse() {
            return this.calculatingCurrencyCoverage() - this.calculateCoverage();
        },
        getKeyts(k) {
            const k_copy = structuredClone(k);
            k_copy.sort((a, b) => {
                if(a.curr === this.sub_data.bind_fiat_curr_text) {
                    return -1;
                }
                if(b.curr === this.sub_data.bind_fiat_curr_text) {
                    return 1;
                }
                return 0;
            })
            this.keywords = k_copy;
        },
        setParams() {
            let params = Object.assign({}, this.data);

            for(let k in params) {
                if( params[k] == '' ) {
                    delete params[k];
                }
            }

            return params;
        },
        readyCheck(arg) {
            this.ready = arg;
        },
        setSummWarn(arg) {
            this.summWarn = arg
        },
        updateState(state) {
            this.data[state.id] = state.value;
        },
        burnDo() {
            this.$store.commit('MODAL', {
                action: true,
                type: 'pay_pass',
                content: {callback: () => {
                    console.log(this.setParams());
                    this.axios.post('BurnCustomCurr', {data: this.setParams()});
                }},
            });
        }
    },
    mounted() {
        console.log('burn mounted this.$store.state.modal.content.curr', this.$store.state.modal.content.curr);
    }
}
</script>

<style lang="scss" scoped>
    .answer_content :deep(a) {
            color: $color-href !important;
    }

    .curr-wrapper-element .curr-element {
        top: 6px;
        right: 10px;
        color: #A6A6A6;
        max-width: 120px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        @media (max-width: 768px) {
            max-width: 40px;
        }

        @media (max-width: 540px) {
            top: 27px;
        }
    }

    .color-active {
        color: $dark-orange;
    }

    .grey-block {
        background: $menu-base;
        border: 1px solid $color-lightgrey;
        border-radius: 6px;
    }
</style>