export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorlagen"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finden"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In der Gruppe"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsgruppen"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungen"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsbetrag"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überweisungskonto"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betrag"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorlage"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorlagenname:"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorlage speichern"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überweisen"])}
}