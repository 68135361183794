export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Підтвердити платіж"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Розрахувати комісію за повернення"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повернути платіж"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакція"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хеш в блокчейні"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AML-ризик"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комісія"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["До зарахування"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Якщо ви згодні зі зміненими умовами, підтвердіть зарахування:"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Якщо ці умови вам не підходять, ви можете повернути кошти на рахунок відправника за вирахуванням витрат на повернення:"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакція"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["на суму"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["має AML-ризик"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["тому за її зарахування перерахована комісія"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["таким чином, сума до зарахування складає"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нових надходжень не виявлено"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата платежу"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повернення"])}
}