export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionando coleção"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informações básicas"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noções básicas"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distribuidor"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número do ponto"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantidade de cartões por aplicação"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceitante de contas"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cartões"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colecionadores de dinheiro"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nota para colecionadores"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentário sobre a coleção"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carregando fora de ordem"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Substitua o cassete do aceitador de notas"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentário sobre a cassete"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentário sobre as cartas"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remova todos os cartões do cassete dispensador de cartões rejeitados"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remova todas as cartas do tubo"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O número do ponto é um campo obrigatório que deve ser preenchido"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID da cassete 1"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID da cassete 2"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID da cassete 3"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID da cassete 4"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classificação 1 da cassete"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de notas no cassete 1"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantidade no cassete 1"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classificação 2 da cassete"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de notas no cassete 2"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentário sobre a fita 1"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID da cassete"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classificação de cassete"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de contas"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soma"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentário sobre a cassete"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvar"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editando coleção"])}
}