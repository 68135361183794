export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de cassette"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID de cassette"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de collection de cassettes"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classement des cassettes"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléchargé"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Somme"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serveur"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantité"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Somme"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ATM"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Somme"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En fait"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantité"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Somme"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire sur la cassette"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune donnée à afficher"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collecte d'appareils"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["valide"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["c"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Par"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["les restes sont emportés"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serveur"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de factures"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Somme"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ATM"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de factures"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Somme"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distributeur"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejeter/Rétracter"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepteur de billets"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejeter les cartes"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cartes"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["actuel"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["déchargé"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soldes de collecte aux guichets automatiques"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collecte d'appareils"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["valide"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cassette installée dans un guichet automatique"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["saisi"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["les restes sont emportés"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["les restes sont emportés"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cassette vide :"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["adhésion:"])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations Complémentaires:"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["à ce jour"])}
}