export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trade request"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salesman"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time-out"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting time for parties to act"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Well"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Give it away"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You receive"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request a deal"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create an account"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No account selected"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sum"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The amount does not fall within the range:"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["min"])}
}