export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Счет перевода:"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма:"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комментарий:"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Протекция сделки"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код:"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сгенерировать"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Срок протекции:"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["дней"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название шаблона:"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить как шаблон"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перевести"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаблоны"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перевод другому пользователю"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код протекции — это код, который должен ввести получатель для завершения сделки. Защищенный перевод получатель увидит сразу же в разделе \"История операций\", но воспользоваться деньгами сможет только после того, как введет код протекции в карточке транзакции. Если срок действия платежа истек, то средства возвращаются отправителю."])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес перевода"])}
}