export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القوالب"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التعبئة"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التحويل"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السحب"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم القالب"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المشغل"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحقول"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإجراءات"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد قوالب"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المستلم"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل تريد حقًا حذف القالب رقم"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعادة تسمية القالب"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعادة تسمية"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حذف"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استخدام"])}
}