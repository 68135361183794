export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datos de criptoactivos"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección del problema:"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre:"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corazón:"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permisos"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propósito/Misión/Propósito"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Características financieras"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoría"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exactitud:"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactos"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emisión:"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persona responsable:"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editor:"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costo unitario:"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seguridad:"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compras en cajeros automáticos:"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ventas en cajeros automáticos:"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transacciones con activos en bolsa:"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transacciones de activos vía P2P:"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección de alojamiento"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección del Libro Blanco"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oferente"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otros contactos"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ir"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editor"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emisión máxima:"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otro"])}
}