export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advertisement Information"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic Information"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creation Date"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deal Start Date"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deal End Date"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rate"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maker"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gives"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["receives"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available only by link"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available only to verified users"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["limits"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advertisement Owner"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timeout"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["days"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hours"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minutes"])}
}