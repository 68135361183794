export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich gebe"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich erhalte"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grund"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaktion stornieren"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teilnahme an der Transaktion bestätigen"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaktion ablehnen"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wartezeit verlängern"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaktionsstornierung"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzerstatus in der Transaktion nicht definiert. Etwas ist schief gelaufen"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schiedsgericht anfordern"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schiedsgericht zustimmen"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überweisung benachrichtigen"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überweisung bestätigen"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaktion bewerten"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewertung senden"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaktion Nr."])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korrespondent"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timeout"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["heute"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["morgen"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schlichtung abbrechen"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schiedsverfahren abbrechen"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben ein Schlichtungsverfahren beantragt"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gegen Sie wurde ein Schiedsverfahren beantragt"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Schiedsverfahren ist im Gange (Sie sind der Kläger)"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Schiedsverfahren ist im Gange (Sie sind der Beklagte)"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nach Ablauf des Timers erfolgt die Zustimmung zum Schlichtungsverfahren automatisch und Sie können Ihre Antworten nicht mehr auf Schlichtungsfragen hinzufügen"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erforderliches Schiedsverfahren"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es gibt ein Schiedsverfahren"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es gibt ein Schiedsverfahren"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entscheidung über Schiedsverfahren: interne Zahlung"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benehmen"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stornieren"])}
}