export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Methods of replenishment"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cryptocurrency"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Replenishment via P2P"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Electronic check"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Replenishment templates"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Methods of output"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In cryptocurrency"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catalog of payments"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mass payments"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conclusion via P2P"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conclusion templates"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Methods of translation"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exchange for the exchange"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Translation to the user"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer according to your accounts"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Translation templates"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Replenish the score using Bitcoin, Tether Usdt, Ethereum, Tron or other cryptocurrencies."])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exchange without intermediaries: you translate cryptocurrency, bank transfer or transfer cash to another user, and it is an internal transfer to you."])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Have you acquired somewhere an electronic check of the service? \nActivate it soon and get the entire face value at your own expense. \nBy the way, you can check the check without activation."])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Replenish the score using previously created templates. \nBy the way, templates can be created after a transaction or from the history of operations."])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove the funds to Bitcoin, Tether USDT, Ethereum, Tron or other cryptocurrency."])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay the cellular communications, communal apartment or other services."])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make dozens, hundreds or even thousands of operations in one click. \nJust download the file with the Excel format list."])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exchange without intermediaries: you translate cryptocurrency, bank transfer or transfer cash to another user, and it is an internal transfer to you."])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assign the funds using previously created templates. \nBy the way, templates can be created after a transaction or from the history of operations."])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can set your course and wait for the course to become just that. \nOr exchange quickly at the current market rate."])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer funds to another service user."])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Easily distribute funds between your accounts."])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer funds quickly using previously created templates. \nBy the way, templates can be created after a transaction or from the history of operations."])}
}