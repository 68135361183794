export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Внутрішній актив"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рахунок"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зовнішній актив"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Група"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва EN"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Синоніми"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Країни"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Якщо заповнено, оголошення буде доступно тільки користувачам з зазначених країн"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прив'язати до активу"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Одиниці вимірювання"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скорочено"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Точність"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опис"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Реквізити"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Напрямок переказу"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мінімум"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Максимум"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ліміт"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Час очікування (хв)"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оголошення активно"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступно тільки за посиланням"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступно тільки верифікованим користувачам"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["До попереднього"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Розмістити"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Далі"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Напрямок"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обмеження"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створення оголошення"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Свій зовнішній актив"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не прив'язувати"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Так"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скоротити курс"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повернути назад"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Немає рахунку в такому активі"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створити рахунок"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вкажіть курс"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курс коректний"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скоротити курс"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повернути назад"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мінімум більше максимуму"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Куди і як хочете отримати переказ. Наприклад: Переведіть в додатку банку за номером телефону +3809900011111 (одержувач Олександр Н.)"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["віддаю"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["отримую"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активи"])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запитувані реквізити"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Які реквізити повинен буде надіслати тейкер, щоб ви могли зробити йому переказ"])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вибрати актив"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вибрати актив"])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Що хочете отримати: кошти у внутрішньому активі, наприклад, BTC або у зовнішньому, наприклад TOKEN GOLD?"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Напишіть коментар про особливості проведення цієї угоди, наприклад: \\\"Зовнішній актив передається тільки при особистій зустрічі\\\""])},
  "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип значень, до яких належить зовнішній актив, наприклад, \"готівка\""])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва активу для відображення на платформі"])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Актив також може називатися словами зі списку"])},
  "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Співставлення з існуючим активом"])},
  "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наприклад: літри, штуки, години, коробки"])},
  "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наприклад: л, шт, год, кор"])},
  "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скільки може бути цифр після коми в значеннях активу"])},
  "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви обрали створення ще не існуючого активу, будь ласка, заповніть його характеристики"])},
  "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Час, який надається сторонам для виконання умов"])},
  "62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Внутрішнім активом є актив, що існує в системі \\\"New Reality\\\". Зовнішній актив - це актив, що не існує в системі \\\"New Reality\\\"."])},
  "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не прив'язаний"])},
  "64": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Очікуване значення: від 1 до 2147483"])}
}