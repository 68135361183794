export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ativos externos"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vinculado ao ativo"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unidades de medida"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupo"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de criação"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autor"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ações"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Período de"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Período até"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtros"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redefinir"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atualizar"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sem ativos"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoje"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ontem"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semana"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mês"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ano"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vinculado ao ativo"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupo"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autor"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atividade"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Países"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ativo"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inativo"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar ativo externo"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excluir ativo externo"])}
}