export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eingebettetes Widget"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installation"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Binden Sie das Skript in den Head-Bereich der Seite über CDN ein oder laden Sie es als Datei herunter"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufruf"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rufen Sie die Funktion auf"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["indem Sie ein JS-Objekt als Argument übergeben"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datentyp"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beispiel"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["id für iframe"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["name für iframe"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objekt mit Zahlungsparametern"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgsbehandlungsfunktion der Operation"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehlerbehandlungsfunktion der Operation"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse des Zahlungsdienstes"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unten"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noch weiter unten"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rückkehr zur Shop-Website nach Abschluss des Vorgangs"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nach Abschluss oder Ablehnung des Vorgangs wird das Widget geschlossen. Das Objekt mit den folgenden Parametern wird an den onFail- oder onSuccess-Callback übergeben:"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezahlen"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktionen bei Erfolg"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktionen bei Misserfolg"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse des Zahlungsdienstes"])}
}