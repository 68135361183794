export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krypto-Asset-Daten"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Problemadresse:"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name:"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticker:"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berechtigungen"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zweck/Mission/Zweck"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finanzielle Merkmale"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genauigkeit:"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakte"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emission:"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verantwortlicher:"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emittent:"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stückkosten:"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sicherheit:"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einkäufe am Geldautomaten:"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkauf an Geldautomaten:"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaktionen mit Vermögenswerten an der Börse:"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vermögenstransaktionen über P2P:"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hosting-Adresse"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["White-Book-Adresse"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anbieter"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere Kontakte"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gehen"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emittent"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximale Emission:"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere"])}
}