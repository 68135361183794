export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Генерація коду / посилання для оплати"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№ операції магазину"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коментар"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додатковий параметр"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успішний URL"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Помилковий URL"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL повідомлення"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL, куди повернеться клієнт після успішної оплати"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL, куди клієнт повернеться після помилки"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL, куди буде відправлено повідомлення про результати оплати"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зафіксувати суму"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Копіювати"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перевірити"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Посилання на оплату"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Форма"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Документація"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Термін дії рахунку на оплату в хвилинах, за замовчуванням 60"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Час дії посилання"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Посилання"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Форма"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Віджет"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Якщо не фіксувати суму, то платник зможе запровадити її самостійно (підходить, наприклад, для благодійних платежів)"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приховати"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Платіжна форма"])}
}