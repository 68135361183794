export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكنك إجراء عدة دفعات في نفس الوقت. \nللقيام بذلك، تحتاج أولاً إلى إنشاء ملف يحتوي على وصف للمدفوعات بتنسيق \"XLS\" و\"XLSX\" و\"CSV\"."])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحليل الملف"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المراسل"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المبلغ"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التعليق"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المعلمة"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات عن الملف"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إجمالي المدفوعات: "])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بإجمالي مبلغ: "])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملف المدفوعات"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حساب الخصم"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إجراء المدفوعات"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متابعة"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحميل ملف المدفوعات"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التحقق من محتويات الملف"])},
  "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المدفوعات والتحويلات الجماعية"])}
}