export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बाज़ार"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बाज़ार. सारांश"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समान प्रस्ताव"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विपरीत प्रस्ताव"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दर"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["देता है"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्राप्त करता है"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मेरे अनुरोध"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पिछले लेन-देन"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दर"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दी गई राशि"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["तारीख और समय"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["तारीख"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["देता हूँ"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्राप्त करता हूँ"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्थिति"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दर"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्रियाएँ"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शुल्क"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रद्द करें"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दोहराएं"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई प्रस्ताव नहीं"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई लेन-देन नहीं"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई अनुरोध नहीं"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पूर्ण रूप से पूरा हुआ"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रक्रिया प्रारंभ नहीं हुई"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रक्रिया प्रारंभ हुई"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आंशिक रूप से पूरा हुआ"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पूरी तरह से रद्द"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आंशिक रूप से पूरा और रद्द"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्राप्त किया"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी अनुरोध"])}
}