export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["授权"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录名："])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码："])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["忘记密码？"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["首次访问？"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建账户"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码恢复"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电话或电子邮件："])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["恢复"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码恢复"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["姓："])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名："])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["父名："])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出生日期："])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返回"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["继续"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查找"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找到用户"])}
}