export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["توليد رمز/رابط للدفع"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم عملية المتجر"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المبلغ"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعليق"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلمة إضافية"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رابط النجاح"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رابط الفشل"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رابط الإشعار"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرابط الذي سيعود إليه العميل بعد الدفع الناجح"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرابط الذي سيعود إليه العميل بعد الفشل"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرابط الذي سيتم إرسال الإشعار إليه بنتائج الدفع"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تثبيت المبلغ"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نسخ"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحقق"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رابط الدفع"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النموذج"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التوثيق"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مدة الحساب في الدقائق، افتراضيًا 60"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مدة الرابط"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرابط"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النموذج"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الودجت"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إذا لم تقم بإصلاح المبلغ، فسيتمكن الدافع من إدخاله بشكل مستقل (مناسب، على سبيل المثال، للمدفوعات الخيرية)"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يخفي"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نموذج الدفع"])}
}