export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register a store"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An electronic store registered in the system allows you to accept payments from customers through the system merchant."])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As a store, electronic stores, exchange offices and any other Internet resources that wish to accept payments to their address are registered."])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name or trade mark"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Store URL"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brief description of the store"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service Type"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shop active"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The secret key"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allow auto payout"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send results to script"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Script Address"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refund after successful payment"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After an unsuccessful payment"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At least 30 characters."])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Used to check the integrity and validity of the transmitted data by the payment system (when the user's store sends it to the merchant) and the store (when receiving payment results)"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Further"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To the list of stores"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incorrect URL"])}
}