export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code de transaction"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je transmets"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je reçois"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direction"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Délai"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune transaction active"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes transactions"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actif"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actif interne"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactif"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correspondant"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])}
}