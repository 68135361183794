export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction code"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I give it away"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I receive"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direction"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Well"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time-out"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No active trades"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My deals"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actively"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internal asset"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not active"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correspondent"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period p"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The period by"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filters"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I give"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["= equal"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≠ is not equal"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["> more"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≥ more or equal"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["< Less"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≤ less or equal"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I get it"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sum"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose an asset"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My role"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maker"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taker"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internal asset"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All options"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I get it"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I give"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maker ID"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Announcement number"])}
}