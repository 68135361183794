export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата отримання"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата обробки"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кількість платежів"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["в тому числі успішних"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["в тому числі з помилками"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Масові платежі"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакція"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Результат"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Провайдер послуг"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комісія"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коментар"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1-й параметр"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жодного платежу не знайдено"])}
}