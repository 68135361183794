export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Криптоактив деректері"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мәселе мекенжайы:"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аты:"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тикер:"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рұқсаттар"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мақсат/Миссия/Мақсат"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қаржылық сипаттамалар"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Санат"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дәлдігі:"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контактілер"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эмиссия:"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жауапты тұлға:"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эмитент:"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бірліктің құны:"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қауіпсіздік:"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Банкоматтан сатып алулар:"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Банкоматтардағы сату:"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Биржадағы активтермен операциялар:"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["P2P арқылы активтер транзакциялары:"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хостинг мекенжайы"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ақ кітап мекен-жайы"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Электрондық пошта"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ұсынушы"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Басқа контактілер"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бар"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эмитент"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Максималды шығарындылар:"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Өзге"])}
}