export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Генерация кода / ссылки для оплаты"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№ операции магазина"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комментарий"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дополнительный параметр"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успешный URL"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибочный URL"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL уведомления"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL, куда вернется клиент после успешной оплаты"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL, куда клиент вернется после ошибки"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL, куда будет отправлено уведомление о результатах платежа"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зафиксировать сумму"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Копировать"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проверить"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ссылка на оплату"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Форма"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Документация"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Срок жизни счета на оплату в минутах, по умолчанию 60"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Время действия ссылки"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ссылка"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Форма"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виджет"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если не фиксировать сумму, то плательщик сможет ввести ее самостоятельно (подходит, например, для благотворительных платежей)"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скрыть"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Платежная форма"])}
}