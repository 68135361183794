export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एटीएम संग्रह"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संग्रह जोड़ें"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कार्रवाई"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फिल्टर"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रीसेट करें"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अद्यतन"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पुरालेख संग्रह न दिखाएं"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रद्द किए गए संग्रह न दिखाएं"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बिंदु संख्या"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्थिति"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई संग्रह नहीं"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संग्रहण आवेदन प्रस्तुत किया गया"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संग्रह संपादित करें"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संग्रहण स्थिति बदलें"])}
}