export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La palabra mágica es una configuración de seguridad adicional."])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si la palabra mágica está activada, cada vez que inicies sesión el sistema te pedirá que ingreses algunos caracteres (por su número de orden) de la palabra mágica."])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palabra mágica:"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preguntar la palabra mágica:"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al ingresar a la oficina:"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al pagar a través del comerciante:"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al usar la tarjeta:"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palabra mágica"])}
}