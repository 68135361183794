export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Біржа"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ринок. Підсумок"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подібні пропозиції"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зустрічні пропозиції"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курс"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дати"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отримання"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мої заявки"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Останні угоди"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курс"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дано"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата та час"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дано"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отримано"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курс"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операції"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["курс"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скасувати"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повторити"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пропозицій немає"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Угод немає"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заявок немає"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повністю виконано"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обробка не розпочата"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обробка розпочата"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Частково виконано"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повністю скасовано"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Частково виконано та скасовано"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отримано"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всі заявки"])}
}