export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Urządzenia bankomatowe"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj urządzenie"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ urządzenia"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentarz"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfiguracja"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj kasetę"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ kasety"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentarz"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfiguracja"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń urządzenie"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyjmij kasetę"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usunąć urządzenie?"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuwać"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usunąć kasetę?"])}
}