export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Валютные пары"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать валютную пару"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактировать валютную пару"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить валютную пару"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Действия"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отдаваемая валюта"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получаемая валюта"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шлюз"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип обновления"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Валютные пары не найдены"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы действительно хотите удалить валютную пару"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["привязан к другой валюте"])}
}