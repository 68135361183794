export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar cheque eletrônico"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conta de débito"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor nominal de cada cheque"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentário"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de cheques"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voltar"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Série:"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número:"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de ativação:"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cheques criados"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baixar arquivo com cheques"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salve estas informações - os códigos de ativação serão ocultados após fechar esta página"])}
}