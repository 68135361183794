export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La configurazione dell'accesso tramite indirizzo IP impedirà a te (o a un malintenzionato) di accedere al sistema da un indirizzo non incluso nella lista degli indirizzi autorizzati."])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restrizione dell'accesso al portafoglio tramite indirizzi IP"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi indirizzi"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo: "])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blocco di indirizzi: "])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a:"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il tuo IP: "])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista degli indirizzi"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Da indirizzo"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A indirizzo"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accesso"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azioni"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun indirizzo"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proibisci"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permetti"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci l'indirizzo IP"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo IP inserito non valido"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun indirizzo IP o blocco di indirizzi specificato."])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun indirizzo IP autorizzato per l'accesso. Non potrai accedere al tuo conto."])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accesso tramite IP"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["da:"])}
}