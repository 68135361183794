export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बाहरी संपत्तियाँ"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोड"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नाम"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संपत्ति से बंधा हुआ"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["माप की इकाइयाँ"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समूह"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बनाने की तारीख"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लेखक"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्रियाएँ"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अवधि से"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अवधि तक"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फ़िल्टर"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रीसेट"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपडेट"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई संपत्ति नहीं मिली"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आज"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कल"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सप्ताह"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["महीना"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वर्ष"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संपत्ति से बंधा हुआ"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समूह"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लेखक"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सक्रियता"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["देश"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सक्रिय"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["निष्क्रिय"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बाहरी संपत्ति संपादित करें"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बाहरी संपत्ति हटाएं"])}
}