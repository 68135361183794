export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La configuración de acceso por dirección IP no permitirá que tú (o un atacante) trabajes con el sistema desde una dirección que no esté en la lista de permitidas."])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restricción de acceso a la oficina por direcciones IP"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar direcciones"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección: "])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bloque de direcciones: "])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a:"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu IP: "])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de direcciones"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nº"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desde la dirección"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A la dirección"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceso"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acciones"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay direcciones"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prohibir"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permitir"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingresa la dirección IP"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección IP ingresada incorrectamente"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se ha especificado ninguna dirección IP o bloque de direcciones."])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se permite la autorización desde ninguna dirección IP. No podrás acceder a tu cuenta."])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceso por IP"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de:"])}
}