export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشيكات الإلكترونية"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء شيك"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النشاط"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم الإنشاء"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حساب الخصم"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المبلغ"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرقم"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السلسلة"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعليق"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإجراءات"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نشط"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غير نشط"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد شيكات"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تنشيط"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحقق"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إغلاق"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم يتم العثور على إيصالات إلكترونية"])}
}