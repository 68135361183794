export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एसेट बर्निंग"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बर्निंग राशि"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वापसी के लिए खाता"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बर्न करें"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जलाने की राशि"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बर्निंग राशि इमिशन राशि से अधिक नहीं हो सकती"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कवरेज वापसी"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वापसी के लिए"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एसेट बर्निंग के बाद रिजर्व में बची रहने वाली राशि"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एसेट बर्निंग के बाद आपके खाते में वापस आने वाली राशि"])}
}