export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrdit platbu"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vypočítat poplatek za vrácení"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vrátit platbu"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transakce"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hash v blockchainu"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AML riziko"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Částka"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komise"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["K připsání"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stav"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokud souhlasíte se změněnými podmínkami, potvrďte připsání:"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokud vám tyto podmínky nevyhovují, můžete vrátit prostředky na účet odesílatele po odečtení nákladů na vrácení:"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transakce"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["na částku"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["má AML riziko"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["proto byla přepočítána komise za její připsání"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tudíž částka k připsání činí"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nové příjmy nebyly zjištěny"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum platby"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vrácení"])}
}