export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भुगतान की पुष्टि करें"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वापसी शुल्क की गणना करें"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भुगतान वापस करें"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लेन-देन"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ब्लॉकचेन में हैश"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एएमएल जोखिम"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["राशि"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शुल्क"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जमा करने के लिए"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्थिति"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यदि आप संशोधित शर्तों से सहमत हैं, तो जमा की पुष्टि करें:"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यदि ये शर्तें आपके लिए उपयुक्त नहीं हैं, तो आप भुगतान को वापस प्रेषक खाते में भेज सकते हैं:"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लेन-देन"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["राशि"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एएमएल जोखिम है"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इसलिए जमा के लिए पुनर्गणना की गई शुल्क"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इस प्रकार जमा राशि है"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई नई प्राप्तियां नहीं मिलीं"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भुगतान की तारीख"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वापसी"])}
}