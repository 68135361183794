export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子支票"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建支票"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活动"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已创建"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["冲销账户"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["和"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["数字"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["系列"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一条评论"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["行动"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["积极地"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不活跃"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有支票"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["启用"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关闭"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未找到电子收据"])}
}