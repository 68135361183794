export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit external asset"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synonyms"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Countries"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link to asset"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Units"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbreviation"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precision"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepted for trading"])}
}