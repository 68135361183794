export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receipt date"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of processing"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of payments"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["including successful"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["including erroneous"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mass payments"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Result"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service provider"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commission"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1st parameter"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No payments found"])}
}