export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status "])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temat"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ostatnia wiadomość"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategoria"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Działania"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak zgłoszeń"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategoria"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priorytet"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temat"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Treść wiadomości"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pliki"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Możesz przesłać jeden lub więcej plików. Maksymalny rozmiar pliku to 10 MB."])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyślij"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niski"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normalny"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wysoki"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wsparcie techniczne"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktywne zgłoszenia"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archiwum"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nowe zgłoszenie"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przetwarzane przez operatora"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zgłoszenie utworzone"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trwają prace, oczekuj odpowiedzi operatora"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zgłoszenie zamknięte"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zgłoszenie zamknięte"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak nowych wiadomości"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nowa wiadomość od operatora"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak nowych wiadomości"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyślij do archiwum"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszystkie prośby"])}
}