export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["транзакция "])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жалпы параметрлер"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шоттардағы қалдықтарды өзгерту"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операцияның соңында:"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операцияның басында:"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қосымша параметрлер"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Блокчейн операциялары"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Callback"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Келесі әрекет:"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уақыт"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жауап"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мәміле қорғауы"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қорғау мерзімі:"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["дейін"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қорғау коды:"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Растау"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Үлгі параметрлері"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Үлгі атауы :"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сақтау"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операциядан бас тарту"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операцияны қайталау"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Үлгіні сақтау"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жабу"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакция нөмірі"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операция"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Корреспондент"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Төлем күні"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сома"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комиссия"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Түсініктеме"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нәтиже"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Төлем формасының нөмірі"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кіріс/Шығыс"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес қалдықтарын өзгерту"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қабылдауды растау"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қаражатты жіберушіге қайтару"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Күдікті операция"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Әрекеттер нұсқалары:"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Алушы комиссиясы"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Төлеуші ​​комиссиясы"])}
}