export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["transação"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parâmetros gerais"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alteração de saldos nas contas"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No final da operação:"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No início da operação:"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parâmetros adicionais"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operações no blockchain"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Callback"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Próxima tentativa:"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tempo"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resposta"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proteção da transação"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Período de proteção:"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Até"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de proteção:"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parâmetros do modelo"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome do modelo:"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvar"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar operação"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repetir operação"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvar modelo"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fechar"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número da transação"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operação"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correspondente"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de pagamento"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantia"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comissão"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentário"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultado"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número do formulário de pagamento"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receita/Despesa"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alteração de saldos dos endereços"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar crédito"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devolver dinheiro ao remetente"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operação suspeita"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opções de ação:"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comissão do destinatário"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comissão do Pagador"])}
}