export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumentacja"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protokół Sprzedawcy"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przekierowanie do sprzedawcy"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powrót do sklepu"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powiadomienie sklepu"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obliczanie podpisu"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wbudowany widget"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instalacja"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wywołanie"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odpowiedź ze sklepu"])}
}