export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datei auswählen"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieses Dateiformat wird nicht unterstützt. \nAkzeptierte Formate:"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unbekanntes Format"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datei zu groß, maximale Größe: "])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dateien auswählen oder hierher ziehen, um sie hochzuladen"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auswählen"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hochladen"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stornieren"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dateien hochgeladen"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dateien"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])}
}