export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periodo da"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periodo a"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esporta"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtri"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reimposta"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiorna"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transazione"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametro"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transazione"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operazione"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corrispondente"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametri/Commento"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna operazione trovata"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo delle operazioni"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrate:"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" pezzi per un totale di"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spese:"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipi di operazioni"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagamenti, pagamento dei servizi"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trasferimenti interni"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagamento della fattura"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trasferimenti tra i propri conti"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creazione di assegni cripto"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attivazione di assegni cripto"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altro"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrata/Uscita"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrata"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uscita"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["= uguale"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≠ diverso"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["> maggiore"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≥ maggiore o uguale"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["< minore"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≤ minore o uguale"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completato con successo"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In elaborazione"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oggi"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ieri"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settimana"])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mese"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anno"])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Storia delle operazioni"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricarica del conto"])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conto non selezionato"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scambio in borsa"])},
  "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricarica indirizzo"])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trasferimenti all'interno del proprio portafoglio"])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corrispondente"])},
  "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operazione"])}
}