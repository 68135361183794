export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adding collection"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic information"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basics"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dispenser"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Point number"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of cards per application"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bill acceptor"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cards"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cash collectors"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note for collectors"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentary on collection"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading out of order"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Replace the bill acceptor cassette"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentary on the cassette"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentary on the cards"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove all cards from the Reject card dispenser cassette"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove all cards from the tube"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Point number is a required field that must be filled in"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cassette ID 1"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cassette ID 2"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cassette ID 3"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cassette ID 4"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cassette rating 1"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of bills in cassette 1"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount in cassette 1"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cassette rating 2"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of bills in cassette 2"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentary on tape 1"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cassette ID"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cassette rating"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of bills"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sum"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentary on the cassette"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editing collection"])}
}