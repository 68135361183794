export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["alinhar até 255 caracteres"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inteiro"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["20 dígitos"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0 ou 1"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["número fracionário, precisão de dois dígitos, separador de ponto"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inteiro"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["string de até 255 caracteres, opcional"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inteiro, opcional"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sequência de 32 caracteres"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de pagamento da loja; deve ser único para cada pagamento; Ao transmitir uma linha"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["um número exclusivo será gerado automaticamente"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["número da loja atribuído após o registro"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["número da conta da loja; esta conta também determina em qual moeda o pagamento deve ser feito (na maioria dos casos, o usuário pode efetuar o pagamento em qualquer moeda e os fundos serão automaticamente convertidos na moeda do destinatário)"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["proibir o cliente de alterar o valor especificado (1 - proibir, 0 - permitir)"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["valor do beneficiário na moeda da conta do beneficiário"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["número do formulário através do qual o pagamento é aceito"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["comentário exibido ao pagador como a finalidade do pagamento"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["parâmetro opcional adicional que será passado para o script de resultado do pagamento, bem como para as páginas de retorno"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL para onde o cliente retornará após pagamento bem sucedido; se não for especificado, será obtido nas configurações da loja"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL para onde o cliente retornará após algum erro; se não for especificado, será obtido nas configurações da loja"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL para onde será enviada a notificação do resultado do pagamento; se não for especificado, será obtido nas configurações da loja"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vida útil da fatura (em minutos), padrão 60"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["assinando uma mensagem usando a chave secreta da loja"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0 ou 1"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 - se bem-sucedido, 0 - caso contrário"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inteiro"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["código de erro (0 se não houver erro)"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["linha"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["texto de erro (ou explicação do status do pagamento)"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0 ou 1"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["um sinal de que o status do pagamento final ainda não é conhecido"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de pagamento da loja"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número da transação"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protocolo do Comerciante"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A aceitação de pagamentos no site só é possível para lojas online registradas no sistema. Para registrar uma loja, vá para a página"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrar loja"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redirecionamento do usuário para o comerciante para pagamento"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para fazer um pagamento, é necessário redirecionar o usuário para o URL: https://merchant.[seu domínio] e passar os seguintes parâmetros via GET ou POST:"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrição"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de dados"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assinatura"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["é calculada concatenando os parâmetros transmitidos e fazendo hash pelo método hmac com uma chave secreta. O número e ordem dos parâmetros concatenados dependem do parâmetro"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["identified"])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["valor fixo"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a ordem é a seguinte:"])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ext_transact + num_shop + keyt_shop + identified + sum + payform + comment + free_param + url_success + url_decline + url_callback"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para um link onde o cliente"])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pagamento, a ordem é a seguinte (o valor não é incluído na assinatura):"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ext_transact + num_shop + keyt_shop + identified + payform + comment + free_param + url_success + url_decline + url_callback"])},
  "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O link de pagamento pode ser gerado na página"])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'Minhas lojas'"])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exemplo de formulário:"])},
  "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retorno ao site da loja após a conclusão da operação"])},
  "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se o pagador recusa o pagamento ou a operação é concluída com erro, o sistema de pagamento sugere ir para a página apropriada especificada nas configurações da loja ou passada com o formulário"])},
  "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["url_decline"])},
  "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se a operação for concluída com sucesso, vá para a página"])},
  "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["url_success"])},
  "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estes scripts também receberão os resultados do pagamento (pelo método especificado nas configurações da loja. Padrão - GET):"])},
  "62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
  "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrição"])},
  "64": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de dados"])},
  "65": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assinatura"])},
  "66": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["é calculada concatenando os parâmetros transmitidos e fazendo hash pelo método hmac com uma chave secreta."])},
  "67": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordem dos parâmetros concatenados:"])},
  "68": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ext_transact + amount + success + result + result_text + free_param"])},
  "69": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notificação do status do pagamento da loja (callback)"])},
  "70": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Após a execução de um pagamento, os resultados do pagamento são enviados para o script especificado durante o registro da loja"])},
  "71": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["result_url"])},
  "72": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este script recebe dados tanto para pagamentos bem-sucedidos quanto para casos de erro."])},
  "73": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estes scripts também receberão os resultados do pagamento (pelo método especificado nas configurações da loja. Padrão - GET)."])},
  "74": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Em alguns casos, a notificação com os resultados não chega (por exemplo, se o cliente foi pagar e fechou a página do navegador)."])},
  "75": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
  "76": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrição"])},
  "77": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de dados"])},
  "78": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assinatura"])},
  "79": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["é calculada concatenando os parâmetros transmitidos e fazendo hash pelo método hmac com uma chave secreta."])},
  "80": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordem dos parâmetros concatenados:"])},
  "81": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["transact + ext_transact + amount + success + result + result_text + free_param"])},
  "82": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cálculo da assinatura"])},
  "83": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A assinatura é formada concatenando os parâmetros necessários na ordem especificada e fazendo hash pelo método hmac."])},
  "84": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A ordem e número de parâmetros concatenados estão descritos acima."])},
  "85": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Os seguintes listagens mostram exemplos de funções que calculam a assinatura em diferentes linguagens, onde a string assinada concatenada é passada como parâmetro"])},
  "86": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listagem 1. Cálculo da assinatura em PHP."])},
  "87": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listagem 2. Cálculo da assinatura em Python."])},
  "88": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listagem 3. Cálculo da assinatura em Perl."])},
  "89": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para pagamentos com"])},
  "90": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pode alterar o valor"])},
  "91": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pagamento do produto"])},
  "92": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagar"])},
  "93": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerar assinatura com chave"])},
  "94": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["signed_str"])},
  "95": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e a frase secreta no parâmetro"])},
  "96": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["key"])}
}