export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Валюта жұбын жасаңыз"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Валюта жұбы"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жаңартуды орнату"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Берілген валюта"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Валюта алынды"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курсқа пайда қосу, %"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курсты жаңарту түрі"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курстың ұзақтығы, секунд"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курсты жаңарту аралығы, секунд"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жақсы"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курс қай күнге жарамды?"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Берілген валютаның бүркеншік аты"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Алынған валютаның бүркеншік аты"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шлюз"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Валютаны таңдау керек, сонымен қатар біреуі ғана: берілген немесе алынған"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жіберу сомасы * Тариф = Қабылдау сомасы"])}
}