export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активність"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опис активу"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оголошень немає"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мої оголошення"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мої оголошення"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нове оголошення"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активність"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активно"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неактивно"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всі"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Напрямок"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зовнішній актив"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Внутрішній актив"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опис"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Віддаю"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отримую"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ліміти"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата публікації"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Залишок на рахунку"])}
}