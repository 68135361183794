export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تزيد المفاتيح ذات الاستخدام الواحد من أمان النظام بشكل كبير."])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عند تسجيل الدخول، سيطلب منك البرنامج إدخال اسم المستخدم وكلمة المرور، وأيضًا المفتاح ذو الاستخدام الواحد. يتم تفعيلها بالتتابع. يبقى المفتاح الأخير ساريًا حتى تقوم بإنشاء سلسلة جديدة من المفاتيح."])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استخدام المفاتيح ذات الاستخدام الواحد"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قائمة المفاتيح"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["توليد"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تصدير"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المفتاح"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المفاتيح ذات الاستخدام الواحد"])}
}