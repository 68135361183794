export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注册商店"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在系统中注册的电子商店允许通过系统的商家接受客户支付。"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子商店、兑换点和任何其他希望接受支付的互联网资源都注册为商店。"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名称或品牌"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商店的URL地址"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商店的简短描述"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["服务类型"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商店活动"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密钥"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["允许自动支付"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发送结果到脚本"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["脚本地址"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成功支付后返回"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["失败支付后返回"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["生成"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["至少 30 个字符。"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用于检查支付系统发送和接收的数据的完整性和真实性"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下一步"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返回"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返回商店列表"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["错误的URL"])}
}