export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد الدفع"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حساب العمولة للاسترداد"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إرجاع الدفع"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المعاملة"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تجزئة في البلوكشين"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطر AML"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المجموع"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العمولة"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["للايداع"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحالة"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إذا كنت توافق على الشروط المعدلة، أكد الائتمان:"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إذا لم تكن هذه الشروط مناسبة لك، يمكنك إعادة الأموال إلى حساب المرسل مع خصم تكاليف الاسترداد:"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المعاملة"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بمبلغ"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لديها خطر AML"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لذلك تم إعادة حساب العمولة للإيداع"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وبالتالي، المجموع للايداع هو"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم يتم العثور على إيرادات جديدة"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الدفع"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استرداد"])}
}