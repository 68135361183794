export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Банкоматы"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нажмите для разворота таблицы на полный экран"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сбросить"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обновить"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активность"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Субдилеры"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активно"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неактивно"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать банкомат"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактировать криптомат"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Основная информация"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Основное"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Расписание работы"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Логин"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Описание места установки"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нормированный"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ненормированный"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Режим работы"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Понедельник"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вторник"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Среда"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Четверг"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пятница"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Суббота"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Воскресенье"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обеденный перерыв"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повтор пароля"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Использовать существующие счета"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать новые счета"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Основные сведения"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Счёт"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Время работы (справочная информация)"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активность"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Действия"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Банкоматы не найдены"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер банкомата"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["широта"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["долгота"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Телефон поддержки"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID субдилера"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Счета"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Счёт"])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Валюта"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзитный счёт"])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создание банкомата"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактирование банкомата"])},
  "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Географические координаты"])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль"])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Да"])},
  "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запретить"])},
  "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разрешить"])},
  "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список инкассаторов"])},
  "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить инкассатора"])},
  "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Невозможно перейти к инкассационным остаткам - нет данных"])},
  "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список инкассаторов"])},
  "62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить инкассатора"])},
  "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разрешен"])},
  "64": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запрещен"])},
  "65": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Акцепт"])},
  "66": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Страна"])},
  "67": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес"])},
  "68": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактировать банкомат"])},
  "69": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Посмотреть текущие инкассационные остатки"])},
  "70": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Посмотреть выгруженные инкассационные остатки"])},
  "71": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пин-код"])},
  "72": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить банкомат"])},
  "73": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если не указывать субдилера, то банкомат будет привязан к вам"])},
  "74": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Широта и долгота — это географические координаты, которые можно скопировать, например, из Google Maps, введя адрес банкомата"])},
  "75": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Место установки"])},
  "76": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для отображения таблицы должен быть выбран хотя бы один столбец"])},
  "77": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
  "78": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать"])},
  "79": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перейти к устройствам банкомата"])}
}