export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeitraum von"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeitraum bis"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurücksetzen"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualisieren"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betrag"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaktion"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameter"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaktion"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operation"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korrespondent"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betrag"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameter/Kommentar"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Operationen gefunden"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betrag der Operationen"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einnahmen:"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Stk. auf den Betrag"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausgaben:"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operationstypen"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auszahlungen, Bezahlung von Dienstleistungen"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interne Überweisungen"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnungszahlung"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überweisungen zwischen eigenen Konten"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellung von Krypto-Checks"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivierung von Krypto-Schecks"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonstiges"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einnahmen/Ausgaben"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einnahmen"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausgaben"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["= gleich"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≠ ungleich"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["> größer"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≥ größer oder gleich"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["< kleiner"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≤ kleiner oder gleich"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgreich abgeschlossen"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Bearbeitung"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heute"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestern"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Woche"])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monat"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jahr"])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operationsgeschichte"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontoauffüllung"])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto nicht ausgewählt"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Börsenwechsel"])},
  "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adressauffüllung"])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überweisungen innerhalb der eigenen Brieftasche"])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korrespondent"])},
  "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betrieb"])}
}