export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte de transfert :"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant :"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire :"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protection de la transaction"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code :"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Générer"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée de protection :"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jours"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du modèle :"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer comme modèle"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transférer"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modèles"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfert à un autre utilisateur"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le code de protection est le code que le destinataire doit entrer pour terminer la transaction. Le destinataire verra immédiatement le transfert protégé dans la section \"Historique des opérations\", mais ne pourra utiliser les fonds qu'après avoir saisi le code de protection dans la carte de transaction. Si la période de validité du paiement expire, les fonds sont retournés à l'expéditeur."])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse de transfert"])}
}