export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संग्रह जोड़ा जा रहा है"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मूल जानकारी"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मूल बातें"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दवासाज़"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बिंदु संख्या"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रति आवेदन कार्डों की संख्या"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बिल स्वीकर्ता"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कार्ड"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नकद संग्राहक"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संग्राहकों के लिए नोट"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संग्रह पर टिप्पणी"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ऑर्डर से बाहर लोड हो रहा है"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बिल स्वीकर्ता कैसेट बदलें"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कैसेट पर टिप्पणी"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कार्डों पर टिप्पणी"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रिजेक्ट कार्ड डिस्पेंसर कैसेट से सभी कार्ड हटा दें"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ट्यूब से सभी कार्ड निकालें"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्वाइंट नंबर एक आवश्यक फ़ील्ड है जिसे भरना होगा"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कैसेट आईडी 1"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कैसेट आईडी 2"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कैसेट आईडी 3"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कैसेट आईडी 4"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कैसेट रेटिंग 1"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कैसेट में बिलों की संख्या 1"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कैसेट में राशि 1"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कैसेट रेटिंग 2"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कैसेट में बिलों की संख्या 2"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टेप 1 पर टिप्पणी"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कैसेट आईडी"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कैसेट रेटिंग"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बिलों की संख्या"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जोड़"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कैसेट पर टिप्पणी"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बचाना"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संपादन संग्रह"])}
}