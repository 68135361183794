<template>
    <div>
        <div class='d-flex justify-content-between neue mb-2'>
            <h4>{{ this.content.currency.name ?  this.content.currency.name : `${this.$t('views.P2P.ExternalCurrencies.1')} ${this.content.currency.code}`}}</h4>
            <i class='big item-i x_red pointer' v-on:click='this.close'></i>
        </div>
        <div class="mb-3 neue-roman">
            <div class="col-12">
                <Input
                    :dataValue='this.data.name'
                    require
                    :label="$t('views.P2P.ExternalCurrencies.2')"
                    id='name'
                    @updateParentState="this.setValue"
                />
            </div>
            <div class="col-12">
                <Select
                    :label="$t('views.P2P.ExternalCurrencies.5')"
                    require
                    :dataValue='this.data.grp'
                    id='grp'
                    :keywords.sync='this.groups_list'
                    @updateParentState="this.setValue"
                    :hotUpdate='true'
                />
            </div>
            <div class="col-12">
                <Input
                    :dataValue='this.data.synonyms'
                    :label="$t('views.P2P.ExternalCurrencies.6')"
                    id='synonyms'
                    @updateParentState="this.setValue"
                />
            </div>
            <div class="col-12">
                <SelectMulti
                    :label="$t('views.P2P.ExternalCurrencies.7')"
                    :dataValue='this.data.country'
                    id='country'
                    :keywords.sync='this.countries_list'
                    separator=","
                    @updateParentState="this.setValue"
                    :hotUpdate='true'
                    :isSearch="true"
                />
            </div>
            <div class="col-12">
                <Select
                    :label="$t('views.P2P.ExternalCurrencies.8')"
                    :dataValue='this.data.curr'
                    id='curr'
                    :keywords.sync='this.currency_internal'
                    @updateParentState="this.setValue"
                    :hotUpdate='true'
                    :isSearch="true"
                />
            </div>
            <div v-if="this.data.curr == ''">
                <div class="col-12">
                    <Input
                        :dataValue='this.data.unit'
                        :label="$t('views.P2P.ExternalCurrencies.9')"
                        id='unit'
                        @updateParentState="this.setValue"
                    />
                </div>
                <div class="col-12">
                    <Input
                        :dataValue='this.data.unit_abbreviation'
                        :label="$t('views.P2P.ExternalCurrencies.10')"
                        id='unit_abbreviation'
                        @updateParentState="this.setValue"
                    />
                </div>
                <div class="col-12">
                    <Number
                        :dataValue='this.data.unit_precision' 
                        :label="$t('views.P2P.ExternalCurrencies.11')" 
                        id='unit_precision' 
                        @updateParentState="this.setValue"
                        :hint="$t('views.P2P.New.59')"
                    />
                </div>
            </div>
            <div class="col-12 mt-3 d-flex">
                <SwitchSimple
                    class='pt-0 fs_09 mr-2'
                    :label="$t('views.P2P.ExternalCurrencies.12')"
                    :dataValue="this.data.active"
                    type='form-switch-md'
                    id='active'
                    @updateParentState="this.setValue"
                />
                <SwitchSimple
                    class='pt-0 fs_09'
                    :label="$t('views.P2P.ExternalCurrencies.13')"
                    :dataValue="this.data.accept"
                    type='form-switch-md'
                    id='accept'
                    @updateParentState="this.setValue"
                />
            </div>
        </div>
        <div class="col-12 col-md-3 mt-4 m-auto d-flex flex-column flex-md-row align-items-center justify-content-between">
            <Button
                :name="$t('views.P2P.ExternalCurrencies.3')"
                class="col-12 col-sm-6"
                :class="this.$store.state.resize.w > 768 ? 'mr-1' : 'mb-2'"
                type='btn-outline-warning'
                v-on:click="this.close"
            />
            <Button :name="$t('views.P2P.ExternalCurrencies.4')" class="col-12 col-sm-6" type='btn-warning' @click="this.saveExternalType()"/>
        </div>
    </div>
</template>

<script>
import Button from '@/components/service/Button';
import Select from '@/components/service/Select';
import Input from '@/components/service/Input';
import SelectMulti from '@/components/service/SelectMulti';
import SwitchSimple from '@/components/service/SwitchSimple';
import Number from '@/components/service/Number';

export default {
    components: {
        Button,
        Select,
        Input,
        SelectMulti,
        SwitchSimple,
        Number
    },
    props: {
        close: [Function],
    }, 
    data () {
        return { 
            content: this.$store.state.modal.content,
            data: {
                name: this.$store.state.modal.content.currency.name,
                grp: this.$store.state.modal.content.currency.grp,
                synonyms: this.$store.state.modal.content.currency.synonyms,
                country: this.$store.state.modal.content.currency.country_code,
                curr: this.$store.state.modal.content.currency.curr,
                unit: this.$store.state.modal.content.currency.unit,
                unit_abbreviation: this.$store.state.modal.content.currency.unit_abbreviation,
                unit_precision: this.$store.state.modal.content.currency.unit_precision,
                active: this.$store.state.modal.content.currency.active,
                accept: this.$store.state.modal.content.currency.accept,
            },
            groups_list: this.$store.state.modal.content.groupsList,
            countries_list: this.$store.state.modal.content.countriesList,
            currency_internal: this.$store.state.modal.content.currencyInternal,
        }
    },
    mounted() {
        console.log('mounteed', this.currency_internal);
        console.log('mounted this.data.', this.data);
    },
    methods: {
        setValue(arg) {
            this.data[arg.id] = arg.value;
        },
        booleanToString(arg) {
            if (typeof arg == 'boolean') {
                return arg ? '1' : '0';
            } 

            return arg;
        },
        saveExternalType() {
            this.data.active = this.booleanToString(this.data.active);
            this.data.accept = this.booleanToString(this.data.accept);

            this.axios.post('SetparamsP2PExternalType', {data: this.data}).then(res => {
                this.content.updateData();
            });
        }
    }
}
</script>