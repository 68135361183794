export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atividade"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrição do ativo"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sem anúncios"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meus anúncios"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meus anúncios"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Novo anúncio"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atividade"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ativo"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inativo"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direção"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ativo externo"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ativo interno"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrição"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrego"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recebo"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limites"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de publicação"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo em conta"])}
}