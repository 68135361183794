export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrovat obchod"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-shop registrovaný v systému může přijímat platby od zákazníků prostřednictvím obchodníka našeho systému."])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elektronické obchody, směnárny a jakékoli jiné online zdroje, které jsou ochotny přijímat platby na svou adresu, jsou registrovány jako obchod."])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název nebo obchodní značka"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL adresa obchodu"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krátký popis obchodu"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ služby"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obchod je aktivní"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tajný klíč"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Povolit automatické výplaty"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posílat výsledky na skript"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresa skriptu"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Návrat po úspěšné platbě"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Po NEúspěšné platbě"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložit"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vygenerovat"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimálně 30 znaků."])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Používá se k ověření integrity a pravosti dat přenášených platebním systémem (při odesílání uživatele obchodem na obchodníka) a obchodem (při přijímání výsledků platby)"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Další"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zpět"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do seznamu obchodů"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nesprávná adresa URL"])}
}