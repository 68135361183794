export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passaporto digitale"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maggiori informazioni"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User ID:"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di registrazione:"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultima autorizzazione:"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I miei asset"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conti"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizza tutto"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricarica"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preleva"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun conto"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password di conferma"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per confermare le operazioni è necessario creare una password di conferma."])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modelli"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutto"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagamenti popolari"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notizie"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprimi"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continua..."])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun asset"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creare"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilancia"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ritirare"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricaricare"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scambio"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All'utente"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equilibrio complessivo"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presentarsi"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilancio non fiat"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equilibrio fiat"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuove informazioni"])}
}