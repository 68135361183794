export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["资产销毁"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["销毁金额"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["退还账户"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["销毁"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["销毁金额"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["销毁金额不能大于发行金额"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["覆盖返还"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["待退还"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["资产销毁后将保留在储备中的金额"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["资产销毁后将退还到您账户的金额"])}
}