export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giełda"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rynek. Podsumowanie"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podobne oferty"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oferty przeciwdziałające"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurs"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dają"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otrzymują"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moje zlecenia"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ostatnie transakcje"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurs"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dałem"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data i czas"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daję"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otrzymuję"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurs"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operacje"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["prowizja"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anuluj"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powtórz"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak ofert"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak transakcji"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak zleceń"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Całkowicie zrealizowane"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie rozpoczęto przetwarzania"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rozpoczęto przetwarzanie"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Częściowo zrealizowane"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Całkowicie anulowane"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Częściowo zrealizowane i anulowane"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otrzymano"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszystkie zlecenia"])}
}