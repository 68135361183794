export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodawanie kolekcji"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podstawowe informacje"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podstawy"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szafarka"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numer punktu"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liczba kart na aplikację"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akceptant rachunku"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karty"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kolekcjonerzy gotówki"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uwaga dla kolekcjonerów"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentarz do kolekcji"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ładowanie nie jest w porządku"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wymień kasetę akceptora banknotów"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentarz do kasety"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentarz do kart"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyjmij wszystkie karty z kasety dozownika kart odrzucanych"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyjmij wszystkie karty z tuby"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numer punktu jest polem wymaganym, które należy wypełnić"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identyfikator kasety 1"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identyfikator kasety 2"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identyfikator kasety 3"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identyfikator kasety 4"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ocena kasety 1"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liczba banknotów w kasecie 1"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilość w kasecie 1"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ocena kasety 2"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liczba banknotów w kasecie 2"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentarz do taśmy 1"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identyfikator kasety"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ocena kasety"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liczba rachunków"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suma"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentarz do kasety"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ratować"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edycja kolekcji"])}
}