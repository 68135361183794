export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dane o zasobach kryptograficznych"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres wydania:"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa:"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serce:"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uprawnienia"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cel/Misja/Cel"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charakterystyka finansowa"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategoria"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokładność:"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Łączność"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emisja:"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osoba odpowiedzialna:"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emitent:"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koszt jednostkowy:"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezpieczeństwo:"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zakupy w bankomatach:"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprzedaż w bankomatach:"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transakcje aktywami na giełdzie:"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transakcje na aktywach za pośrednictwem P2P:"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres hostingu"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres Białej Księgi"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oferent"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inne kontakty"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iść"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emitent"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksymalna emisja:"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inny"])}
}