export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repetir operação"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Histórico de operações"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de contas"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sucesso"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erro"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erro desconhecido"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modelos"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meus ativos"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informações sobre novo ativo"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emissão adicional"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de ativos virtuais"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link para blockchain"])}
}