export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richiesta transazione"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offerta"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Venditore"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timeout"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tempo di attesa delle azioni delle parti"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasso"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dai"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricevi"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richiedi transazione"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea conto"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conto non selezionato"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo fuori intervallo: "])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["min"])}
}