export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você pode fazer vários pagamentos ao mesmo tempo. \nPara fazer isso, primeiro você precisa criar um arquivo com a descrição dos pagamentos nos formatos \"XLS\", \"XLSX\", \"CSV\"."])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análise de arquivo"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correspondente"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantia"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentário"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parâmetro"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informações do arquivo"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total de pagamentos: "])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no valor total de: "])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arquivo de pagamentos"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conta de débito"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fazer pagamentos"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carregar arquivo de pagamentos"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifique o conteúdo do arquivo"])},
  "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagamentos e transferências em massa"])}
}