export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаблони"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поповнення"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переказ"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виведення"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва шаблону"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оператор"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поля"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операції"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаблонів немає"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отримувач"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви дійсно хочете видалити шаблон №"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перейменувати шаблон"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перейменувати"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Використовувати"])}
}