export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes magasins"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun magasin trouvé"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un code de widget"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un lien de paiement"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le magasin"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous vraiment supprimer le magasin?"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer le magasin"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mode de paiement"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activité"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepter"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])}
}