export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecionar endereço"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Localidade"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereço"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvar"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["País"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Região"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distrito"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bairro"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cidade"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Território dentro da cidade"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Localidade"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rua"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elemento adicional do endereço"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Especificação"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Casa"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código postal"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereço"])}
}