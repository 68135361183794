export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Электрондық чек жасау"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дебеттік шот"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Әрбір чектің номиналды құны"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Түсініктеме"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чектер саны"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Артқа"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жасау"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сериясы:"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нөмірі:"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Белсендіру коды:"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чектер жасалды"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чектері бар файлды жүктеу"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бұл ақпаратты сақтаңыз - бұл бетті жапқаннан кейін белсендіру кодтары жасырылады"])}
}