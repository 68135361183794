export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आंतरिक संपत्ति"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खाता"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बाहरी संपत्ति"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समूह"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नाम"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नाम EN"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समानार्थक"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["देश"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यदि भरा गया है, तो घोषणा केवल निर्दिष्ट देशों के उपयोगकर्ताओं के लिए उपलब्ध होगी"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संपत्ति से बंधा हुआ"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["माप की इकाइयाँ"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संक्षेप में"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सटीकता"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विवरण"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विवरण"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्थानांतरण दिशा"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["न्यूनतम"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अधिकतम"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सीमा"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रतीक्षा समय (मिनट)"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विज्ञापन सक्रिय है"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["केवल लिंक के माध्यम से उपलब्ध"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["केवल सत्यापित उपयोगकर्ताओं के लिए उपलब्ध"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पिछले पर वापस जाएं"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रकाशित करें"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अगला"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दिशा"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रतिबंध"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["घोषणा बनाना"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपनी बाहरी संपत्ति"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बांधना नहीं"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हाँ"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मूल्य कम करें"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वापस लौटाएं"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इस संपत्ति में कोई खाता नहीं"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खाता बनाएं"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मूल्य निर्धारित करें"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मूल्य सही है"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मूल्य कम करें"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वापस लौटाएं"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["न्यूनतम अधिकतम से अधिक है"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कहां और कैसे आप स्थानांतरण प्राप्त करना चाहते हैं। उदाहरण के लिए: बैंक ऐप में फोन नंबर +919990001111 (प्राप्तकर्ता अलेक्जेंडर एन.) द्वारा स्थानांतरण करें"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["देता हूँ"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्राप्त करता हूँ"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संपत्ति"])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वांछित विवरण"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टेकर को कौन से विवरण भेजने होंगे ताकि आप उन्हें स्थानांतरण कर सकें"])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संपत्ति का चयन करें"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संपत्ति का चयन करें"])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आप क्या प्राप्त करना चाहते हैं: आंतरिक संपत्ति में धन, जैसे बीटीसी या बाहरी संपत्ति में, जैसे टोकन गोल्ड?"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कृपया इस लेनदेन की विशेषताओं पर टिप्पणी लिखें, जैसे: \\\"बाहरी संपत्ति केवल व्यक्तिगत मुलाकात पर ही हस्तांतरित की जाती है\\\""])},
  "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उन मूल्यों का प्रकार जिनके लिए एक बाहरी संपत्ति है, उदाहरण के लिए, \"नकद\""])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्लेटफ़ॉर्म पर प्रदर्शन के लिए संपत्ति का नाम"])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संपत्ति को सूची के शब्दों से भी बुलाया जा सकता है"])},
  "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मौजूदा संपत्ति के साथ तुलना"])},
  "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उदाहरण के लिए: लीटर, टुकड़े, घंटे, बक्से"])},
  "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उदाहरण के लिए: ल, टु, घं, बक"])},
  "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संपत्ति के मूल्यों में दशमलव के बाद कितनी संख्या हो सकती है"])},
  "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आपने अभी तक मौजूद नहीं संपत्ति को बनाने का चयन किया है, कृपया इसकी विशेषताएँ भरें"])},
  "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शर्तों को पूरा करने के लिए पक्षों को दिया गया समय"])},
  "62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आंतरिक संपत्ति वह संपत्ति है जो \\\"न्यू रियलिटी\\\" प्रणाली में मौजूद है। बाहरी संपत्ति वह संपत्ति है जो \\\"न्यू रियलिटी\\\" प्रणाली में मौजूद नहीं है।"])},
  "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बाध्य नहीं"])},
  "64": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपेक्षित मूल्य: 1 से 2147483 तक"])}
}