export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Převod"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Částka"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostat"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtry"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualizace"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kód"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prodávající"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dáváte"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostáváte"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limity"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akce"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["obchodů"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obchod"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádné obchody"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nabídky"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výhoda"])}
}