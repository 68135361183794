export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnungsliste"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Rechnungen gefunden"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betrag"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschäft"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichernummer"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Rechnung stornieren"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungscode speichern"])}
}