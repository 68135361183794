<template>
<div>
    <div :class="{'content p-4': this.border}"> 
    <h5 class="neue-roman">{{ this.message }}</h5>
    <div class="table-responsive col-12 p-0 mt-3">
        <table class="table table-borderless neue-roman m-0">
            <tbody class=''>
                <tr>
                    <td class="align-middle p-0 light mw_130" scope="col">
                        <span>{{ $t('components.service.PayStatusTemplate.3') }} </span>
                    </td>
                    <td v-if="this.loading && !this.get_transact" class="align-middle neue p-0 d-flex align-items-center justify-content-start pl-1" scope="col">
                        <div  class="loader-div"><slot name="loader"></slot></div>
                    </td>
                    <td v-if="this.get_transact" class="align-middle neue p-0 d-flex align-items-center justify-content-start pl-1" scope="col">
                        <span class="d-flex flex-row align-items-center">{{this.get_transact}} <i class='item-i ml-1 copy c18 cli_transact pointer' :data-clipboard-target="'#_transact'"></i></span>
                        <input type='hidden' :id='"_transact"' :value="this.get_transact"/>
                    </td>
                </tr>
                <tr>
                    <td  class="align-middle p-0 light mw_130" scope="col">
                        <span>{{ $t('components.service.PayStatusTemplate.1') }} </span>
                    </td>
                    <td v-if="this.loading && !this.get_sum" class="align-middle neue p-0 d-flex align-items-center justify-content-start pl-1" scope="col">
                        <div  class="loader-div"><slot name="loader"></slot></div>
                    </td>
                    <td v-if="this.get_sum" class="align-middle neue-roman p-0 d-flex align-items-center justify-content-start pl-1" scope="col">
                        <span>{{this.get_sum}}</span>
                    </td>
                </tr>
                <tr v-if="this.get_sum2 && this.get_sum != this.get_sum2">
                    <td class="align-middle p-0 light mw_130" scope="col">
                        <span>{{ $t('components.service.PayStatusTemplate.1') }} </span>
                    </td>
                    <td v-if="this.loading && !this.get_sum2" class="align-middle neue p-0 d-flex align-items-center justify-content-start pl-1" scope="col">
                        <div  class="loader-div"><slot name="loader"></slot></div>
                    </td>
                    <td v-if='this.get_sum2' class="align-middle neue-roman p-0 d-flex align-items-center justify-content-start pl-1" scope="col">
                        <span>{{this.get_sum2}}</span>
                    </td>
                </tr>
                <tr>
                    <td class="align-middle p-0 light mw_130" scope="col">
                        <span>{{ $t('components.service.PayStatusTemplate.2') }}</span>
                    </td>
                    <td v-if="this.loading && !this.get_res" class="align-middle neue p-0 d-flex align-items-center justify-content-start pl-1" scope="col">
                        <div  class="loader-div"><slot name="loader"></slot></div>
                    </td>
                    <td v-if="this.get_res" class="align-middle neue-roman p-0 d-flex align-items-center justify-content-start pl-1" scope="col">
                        <span>{{this.get_res}}</span>
                    </td>
                </tr>
                <tr v-if="this.exp_post && (Number(this.get_done) != -2 || Number(this.get_done) != -3)">
                    <td class="align-middle p-0 light mw_130" scope="col">
                        <span>{{ $t('components.service.PayStatusTemplate.6') }}</span>
                    </td>
                    <td v-if="this.exp_post[0] && !this.exp_post[0]['hash']" class="align-middle neue p-0 d-flex align-items-center justify-content-start pl-1" scope="col">
                        <CustomLoader
                            :is-full-page="false"
                            :height='20'
                            :width='50'
                            :type='"dots"'
                            :opacity='0'
                            :no_text="true"
                        />
                    </td>
                    <td v-if="this.exp_post.length > 0 && this.exp_post[0]['hash']" class="align-middle neue-roman p-0 d-flex flex-column align-items-start justify-content-start pl-1" scope="col">
                        <div v-for="(row, index) in this.exp_post" :key="index">
                            <span class="grey break-word break-all">{{ row.hash }}<input type='hidden' :id='"_h" + index' :value="row.hash"/></span>
                            <span style="position: relative;"><a v-if='row.explorer_link' style="display: inline-block; position: absolute; top: -1px;" :href="row.explorer_link" target="_blank"><i class='item-i c18 ml-2 ext-link'></i></a></span>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="mt-3" v-if="this.get_done === '0'" >
            <Button class='' :name="$t('components.service.PayStatusTemplate.4')" type='btn-outline-warning' v-on:click='this.getParamsExtTransact'/>
        </div>
    </div>
    </div>
    <h4 class='neue-roman mt-3'>{{ $t('components.service.PayStatusTemplate.5') }}</h4>
    <slot name="links">
    </slot>
</div>
</template>

<script>
    import Button from '@/components/service/Button';
    import CustomLoader from '@/components/service/CustomLoader';

    export default {
        components: {
            Button,
            CustomLoader
        },
        data() {
            return {
                k_name: this.$store.state.config["KEYT_ALTERNAME_TOKEN"],
            }
        },
        props: {
            message: [String, Boolean],
            get_transact: [String, Boolean],
            get_sum: [String, Boolean], 
            get_sum2: [String, Boolean], 
            get_res: [String, Boolean],
            get_done: [String, Boolean],
            border: [Boolean],
            exp_post: [Array, Boolean],
            loading: [Boolean],
        },
        methods: {
            getParamsExtTransact() {
                this.$emit('getParamsExtTransact');
            },
        }
    }
</script>

<style lang="scss" scoped>
  .content {
    box-shadow: 1px 2px 4px 1px rgb(19 110 0 / 24%) !important;
  }
  .loader-div {
    position: relative;
    min-height: 20px;
    width: 50px;
    z-index: 2000;
  }
  .mw_130 {
    width: 120px;
  }
</style>