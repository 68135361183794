export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्थिति चुनें"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वसूली हेतु आवेदन प्रस्तुत कर दिया गया है"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वसूली आवेदन रद्द कर दिया गया"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["निष्पादन के लिए संग्रहण स्वीकृत"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संग्रहण खजांची द्वारा किया जाता है (पैसा कैसेट में लोड किया जाता है)"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कैसेट संग्राहकों को सौंप दिए गए (रास्ते में पैसा)"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एटीएम पर कैश कलेक्शन स्थापित किया गया"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एक विफलता के परिणामस्वरूप, दूसरे संग्रह से अतिभारित हो गया"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एटीएम में संग्रह को दूसरे संग्रह से बदल दिया गया (रास्ते में रहता है)"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संग्रह शेष पोस्ट किए जाते हैं (संग्रह संग्रह में भेजा जाता है)"])}
}