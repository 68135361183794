export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإعلان"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوصف"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التفاصيل"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحد الأدنى"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحد الأقصى"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حد المبلغ"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وقت الانتظار (دقائق)"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإعلان نشط"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متاح فقط بالرابط"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متاح فقط للمستخدمين الموثوقين"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلغاء"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ"])}
}