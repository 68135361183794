export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вибір статусу"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заявку на інкасацію подано"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заявку на інкасацію скасовано"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Інкасація затверджена до виконання"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Інкасація виконана касиром (гроші завантажені у касети)"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Касети передані інкасаторам (гроші у дорозі)"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Інкасацію встановлено на банкоматі"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В результаті збою перевантажена іншою інкасацією"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Інкасація змінена іншою інкасацією на банкоматі (залишки в дорозі)"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рознесено інкасаційні залишки (інкасація відправлена ​​до архіву)"])}
}