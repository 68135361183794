export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista faktur"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie znaleziono faktur"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kod"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentarz"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kwota"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sklep"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numer sklepu"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anuluj fakturę"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przechowuj kod płatności"])}
}