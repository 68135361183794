export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дополнительная эмиссия"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Крипто-актив"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Счет - источник покрытия"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Счет списания"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дополнительная эмиссия"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комментарий"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продолжить"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текущая эмиссия"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ТОКЕН"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Расчет покрытия"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комиссия"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комиссия"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Расчет покрытия"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Привязка"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Основные данные"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Процент покрытия"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Будущее покрытие"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Списание покрытия"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cумма, которая будет находиться в резерве после выпуска доп. эмиссии."])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["К списанию"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текущее покрытие"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cумма, которая спишется с вашего счета после выпуска доп. эмиссии."])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Будущий размер покрытия"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E — Текущий размер эмиссии"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I — Дополнительная эмиссия"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["K — Курс привязки"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["P — Процент обеспеченности"])}
}