export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建电子支票"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["借记账户"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每张支票的面值"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["备注"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支票数量"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返回"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["系列："])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编号："])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["激活码："])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已创建支票"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下载支票文件"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存此信息 - 关闭此页面后激活码将被隐藏"])}
}