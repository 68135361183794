export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易所"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["市场。摘要"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["类似报价"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对手报价"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["汇率"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["给"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收到"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的申请"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最近交易"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["汇率"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["给出"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日期和时间"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日期"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我给"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我收到"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["状态"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["汇率"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手续费"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重复"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有报价"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有交易"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有申请"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完全完成"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未开始处理"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["开始处理"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["部分完成"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完全取消"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["部分完成并取消"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收到"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有申请"])}
}