export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Give away"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sum"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filters"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salesman"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Give it away"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You receive"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limits"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["transactions"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deal"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No deals"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offers"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benefit"])}
}