export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TICKER"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Missão"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ações"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de criptoativos"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sem criptoativos"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emissão de token"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceitar"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mercado"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precisão"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primário"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secundário"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pareado com outro ativo"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informações detalhadas sobre o criptoativo"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar dados do criptoativo"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emissão adicional"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excluir criptoativo"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de criptoativos"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceitar"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atividade"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sim"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Os tokens podem ser comprados no caixa"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Os tokens podem ser vendidos no caixa"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tokens do mercado secundário"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exibição de tokens"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos aceitos"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para um usuário específico"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UID do usuário"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Queima de ativo"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para usuário específico"])}
}