export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["transaction"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Common parameters"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changes in account balances"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At the end of the operation:"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At the beginning of the operation:"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra options"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operations in the blockchain"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["callback"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next try:"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Answer"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deal protection"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protection period:"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Before"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protection code:"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Template options"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Template name:"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel operation"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat operation"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Template"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сlose"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction number"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operation"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correspondent"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["payment date"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sum"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commission"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Result"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment form number"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Income/Expense"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changing address balances"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm enrollment"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return money to sender"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suspicious operation"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options:"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipient’s commission"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commission of the payer"])}
}