export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Данные крипто-актива"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес эмиссии:"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название:"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тикер:"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разрешения"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цель/Миссия/Назначение"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Финансовые характеристики"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категория"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Точность:"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контакты"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эмиссия:"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лицо, несущее обязательства:"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эмитент:"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип блокчейна"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стоимость единицы:"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обеспеченность:"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Покупка в банкоматах:"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продажа в банкоматах:"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сделки с активом на бирже:"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сделки с активом через P2P:"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес хостинга"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес белой книги"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Электронная почта"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оферент"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Другие контакты"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Копировать"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перейти"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эмитент"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Максимальная эмиссия:"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Другое"])}
}