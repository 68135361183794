export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato "])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oggetto"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultimo messaggio"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azioni"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna richiesta"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priorità"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oggetto"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testo del messaggio"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puoi caricare uno o più file. Dimensione massima del file - 10MB."])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invia"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bassa"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normale"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alta"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supporto tecnico"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richieste attive"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivio"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuova richiesta"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In elaborazione dall'operatore"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richiesta creata"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lavoro in corso, attendere una risposta dall'operatore"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richiesta chiusa"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richiesta chiusa"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun nuovo messaggio"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuovo messaggio dall'operatore"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun nuovo messaggio"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invia all'archivio"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutte le richieste"])}
}