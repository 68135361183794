export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر الحالة"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم تقديم طلب التحصيل"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم إلغاء طلب التحصيل"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المجموعة المعتمدة للتنفيذ"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يتم التحصيل بواسطة أمين الصندوق (يتم تحميل الأموال في أشرطة)"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أشرطة تم تسليمها إلى هواة جمع العملات (المال في الطريق)"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحصيل النقدية المثبتة على أجهزة الصراف الآلي"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ونتيجة للفشل، مثقلة مع مجموعة أخرى"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم استبدال التحصيل بتحصيل آخر في ماكينة الصراف الآلي (لا يزال في الطريق)"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يتم ترحيل أرصدة التحصيل (يتم إرسال التحصيل إلى الأرشيف)"])}
}