export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأمان"])},
  "Antiphishing": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعداد مكافحة التصيد الاحتيالي هو آلية أمان إضافية عند العمل مع الحساب."])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سيتم عرض العبارة الترحيبية التي تحددها في الجزء العلوي من أي صفحة في الحساب."])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ستظهر العبارة الصحيحة أنك في الموقع الصحيح، وستكون العبارة غير الصحيحة إشارة لعدم إجراء عمليات والاتصال فورًا بقسم الأمان في نظامنا."])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تمكين مكافحة التصيد الاحتيالي:"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عبارة مكافحة التصيد الاحتيالي:"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مكافحة الصيد"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ستصبح التغييرات سارية المفعول بعد التسجيل التالي"])}
  },
  "IP": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعداد الوصول عبر عنوان IP لن يسمح لك (أو للمخترق) بالعمل مع النظام من عنوان غير مدرج في قائمة العناوين المسموح بها."])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقييد الوصول إلى الحساب عبر عناوين IP"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة عناوين"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العنوان: "])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كتلة العناوين: "])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلى:"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان IP الخاص بك: "])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قائمة العناوين"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من العنوان"])},
    "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلى العنوان"])},
    "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوصول"])},
    "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإجراءات"])},
    "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حذف"])},
    "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد عناوين"])},
    "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ"])},
    "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حظر"])},
    "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السماح"])},
    "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل عنوان IP"])},
    "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان IP غير صحيح"])},
    "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم يتم تحديد أي عنوان IP أو كتلة عناوين."])},
    "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم يتم السماح بأي عنوان IP للتسجيل. لن تتمكن من الوصول إلى حسابك."])},
    "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوصول عبر IP"])},
    "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من:"])}
  },
  "Magic": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكلمة السحرية هي إعداد أمان إضافي."])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إذا تم تمكين الكلمة السحرية، فسيطلب منك النظام إدخال بعض الرموز (حسب ترتيبها) من الكلمة السحرية في كل مرة تسجل فيها الدخول."])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكلمة السحرية:"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طلب الكلمة السحرية:"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عند الدخول إلى الحساب:"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عند الدفع عبر التاجر:"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عند استخدام البطاقة:"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكلمة السحرية"])}
  },
  "Onekey": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تزيد المفاتيح ذات الاستخدام الواحد من أمان النظام بشكل كبير."])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عند تسجيل الدخول، سيطلب منك البرنامج إدخال اسم المستخدم وكلمة المرور، وأيضًا المفتاح ذو الاستخدام الواحد. يتم تفعيلها بالتتابع. يبقى المفتاح الأخير ساريًا حتى تقوم بإنشاء سلسلة جديدة من المفاتيح."])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استخدام المفاتيح ذات الاستخدام الواحد"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قائمة المفاتيح"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["توليد"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تصدير"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المفتاح"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المفاتيح ذات الاستخدام الواحد"])}
  },
  "Password": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور القديمة:"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور الجديدة:"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد كلمة المرور:"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"كلمة المرور الجديدة\" و \"تأكيد كلمة المرور\" غير متطابقتين."])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تغيير كلمة المرور"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سلسلة من 4 إلى 63 حرفًا. الأحرف المسموح بها هي الأحرف اللاتينية، الأرقام والرموز"])}
  },
  "PayPassword": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور القديمة:"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور الجديدة:"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد كلمة المرور:"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور الجديدة:"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد كلمة المرور:"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"كلمة المرور الجديدة\" و \"تأكيد كلمة المرور\" غير متطابقتين."])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب ألا تحتوي كلمة المرور على حروف كيريلية"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء كلمة مرور تأكيد"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تغيير كلمة مرور التأكيد"])},
    "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلى الصفحة الرئيسية"])},
    "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سلسلة من 4 إلى 65 حرفًا. الأحرف المسموح بها هي الأحرف اللاتينية، الأرقام والرموز"])}
  },
  "SecretQuestion": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["في حالة نسيانك كلمة المرور للدخول إلى الحساب، يمكنك استعادتها من خلال الإجابة على السؤال السري."])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["في هذا القسم يمكنك تغيير السؤال/الإجابة السرية."])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السؤال السري"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سؤالك:"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإجابة:"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السؤال السري"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سؤالك الخاص"])}
  }
}