export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ligne jusqu'à 255 caractères"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["entier"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["20 chiffres"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0 ou 1"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nombre fractionnaire, précision à deux chiffres, séparateur de points"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["entier"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chaîne jusqu'à 255 caractères, facultatif"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["entier, facultatif"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chaîne de 32 caractères"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["code de paiement du magasin ; doit être unique pour chaque paiement ; lors de la transmission d'une chaîne"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["un numéro unique sera généré automatiquement"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["numéro de magasin attribué après inscription"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["numéro de compte du magasin ; ce compte détermine également dans quelle devise le paiement doit être effectué (dans la plupart des cas, l'utilisateur peut effectuer un paiement dans n'importe quelle devise, et les fonds seront automatiquement convertis dans la devise du destinataire)"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["interdire au client de modifier le montant spécifié (1 - interdire, 0 - autoriser)"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["montant du bénéficiaire dans la devise du compte du bénéficiaire"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["numéro du formulaire par lequel le paiement est accepté"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["commentaire affiché au payeur comme objet du paiement"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paramètre optionnel supplémentaire qui sera transmis au script de résultat du paiement, ainsi qu'aux pages de retour"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL où le client reviendra après un paiement réussi ; s'il n'est pas spécifié, il est extrait des paramètres du magasin"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL où le client reviendra après une erreur ; s'il n'est pas spécifié, il est extrait des paramètres du magasin"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL où la notification des résultats de paiement sera envoyée ; s'il n'est pas spécifié, il est extrait des paramètres du magasin"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["durée de vie de la facture (en minutes), par défaut 60"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["signer un message en utilisant la clé secrète du magasin"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0 ou 1"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 - en cas de succès, 0 - sinon"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["entier"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["code d'erreur (0 s'il n'y a pas d'erreur)"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chaîne"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["texte d'erreur (ou explication de l'état du paiement)"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0 ou 1"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["un signe que le statut du paiement final n'est pas encore connu"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["code de paiement du magasin"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["numéro de transaction"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protocole du Marchand"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'acceptation des paiements sur le site n'est possible que pour les boutiques en ligne enregistrées dans le système. Pour enregistrer une boutique, rendez-vous sur la page"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer une boutique"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redirection de l'utilisateur vers le marchand pour le paiement"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour effectuer un paiement, il est nécessaire de rediriger l'utilisateur vers l'URL : https://merchant.[votre domaine] et de transmettre les paramètres suivants via GET ou POST :"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de données"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signature"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["est calculée en concaténant les paramètres transmis et en les hachant par la méthode hmac avec une clé secrète. Le nombre et l'ordre des paramètres concaténés dépendent du paramètre"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["identified"])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["montant fixe"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["l'ordre est le suivant :"])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ext_transact + num_shop + keyt_shop + identified + sum + payform + comment + free_param + url_success + url_decline + url_callback"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour un lien où le client"])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paiement, l'ordre est le suivant (le montant n'est pas inclus dans la signature) :"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ext_transact + num_shop + keyt_shop + identified + payform + comment + free_param + url_success + url_decline + url_callback"])},
  "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le lien de paiement peut être généré sur la page"])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'Mes boutiques'"])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exemple de formulaire :"])},
  "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour au site de la boutique après la fin de l'opération"])},
  "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si le payeur refuse le paiement ou si l'opération se termine par une erreur, le système de paiement suggère d'aller à la page appropriée spécifiée dans les paramètres de la boutique ou transmise avec le formulaire"])},
  "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["url_decline"])},
  "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si l'opération se termine avec succès, aller à la page"])},
  "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["url_success"])},
  "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ces scripts recevront également les résultats du paiement (par la méthode spécifiée dans les paramètres de la boutique. Par défaut - GET) :"])},
  "62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
  "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "64": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de données"])},
  "65": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signature"])},
  "66": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["est calculée en concaténant les paramètres transmis et en les hachant par la méthode hmac avec une clé secrète."])},
  "67": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordre des paramètres concaténés :"])},
  "68": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ext_transact + amount + success + result + result_text + free_param"])},
  "69": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notification de l'état du paiement de la boutique (callback)"])},
  "70": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Après l'exécution d'un paiement, les résultats du paiement sont envoyés au script spécifié lors de l'enregistrement de la boutique"])},
  "71": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["result_url"])},
  "72": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce script reçoit des données tant pour les paiements réussis que pour les cas d'erreur."])},
  "73": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ces scripts recevront également les résultats du paiement (par la méthode spécifiée dans les paramètres de la boutique. Par défaut - GET)."])},
  "74": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dans certains cas, la notification avec les résultats n'arrive pas (par exemple, si le client est allé payer et a fermé la page du navigateur)."])},
  "75": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
  "76": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "77": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de données"])},
  "78": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signature"])},
  "79": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["est calculée en concaténant les paramètres transmis et en les hachant par la méthode hmac avec une clé secrète."])},
  "80": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordre des paramètres concaténés :"])},
  "81": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["transact + ext_transact + amount + success + result + result_text + free_param"])},
  "82": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calcul de la signature"])},
  "83": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La signature est formée en concaténant les paramètres nécessaires dans l'ordre spécifié et en les hachant par la méthode hmac."])},
  "84": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'ordre et le nombre de paramètres concaténés sont décrits ci-dessus."])},
  "85": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les listes suivantes montrent des exemples de fonctions qui calculent la signature dans différents langages, où la chaîne concaténée à signer est passée en paramètre"])},
  "86": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste 1. Calcul de la signature en PHP."])},
  "87": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste 2. Calcul de la signature en Python."])},
  "88": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste 3. Calcul de la signature en Perl."])},
  "89": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour les paiements avec"])},
  "90": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["peut modifier le montant"])},
  "91": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paiement du produit"])},
  "92": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payer"])},
  "93": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Générer une signature avec la clé"])},
  "94": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["signed_str"])},
  "95": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["et la phrase secrète dans le paramètre"])},
  "96": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["key"])}
}