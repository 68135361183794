export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seguridad"])},
  "Antiphishing": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La configuración de anti-phishing es un mecanismo adicional de protección cuando trabajas con la oficina."])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La frase de saludo que configures se mostrará en la parte superior de cualquier página de la oficina."])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La frase correcta te mostrará que estás en el sitio correcto, una frase incorrecta será una señal: no realices operaciones, debes ponerte en contacto de inmediato con el departamento de seguridad de nuestro sistema."])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activar anti-phishing:"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frase anti-phishing:"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anti-pesca"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los cambios se aplicarán después de la próxima autorización"])}
  },
  "IP": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La configuración de acceso por dirección IP no permitirá que tú (o un atacante) trabajes con el sistema desde una dirección que no esté en la lista de permitidas."])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restricción de acceso a la oficina por direcciones IP"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar direcciones"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección: "])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bloque de direcciones: "])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a:"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu IP: "])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de direcciones"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nº"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desde la dirección"])},
    "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A la dirección"])},
    "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceso"])},
    "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acciones"])},
    "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar"])},
    "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay direcciones"])},
    "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar"])},
    "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prohibir"])},
    "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permitir"])},
    "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingresa la dirección IP"])},
    "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección IP ingresada incorrectamente"])},
    "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se ha especificado ninguna dirección IP o bloque de direcciones."])},
    "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se permite la autorización desde ninguna dirección IP. No podrás acceder a tu cuenta."])},
    "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceso por IP"])},
    "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de:"])}
  },
  "Magic": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La palabra mágica es una configuración de seguridad adicional."])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si la palabra mágica está activada, cada vez que inicies sesión el sistema te pedirá que ingreses algunos caracteres (por su número de orden) de la palabra mágica."])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palabra mágica:"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preguntar la palabra mágica:"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al ingresar a la oficina:"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al pagar a través del comerciante:"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al usar la tarjeta:"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palabra mágica"])}
  },
  "Onekey": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las claves de un solo uso mejoran significativamente la seguridad del sistema."])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En el momento de iniciar sesión, el programa te pedirá no solo que ingreses tu usuario y contraseña, sino también la siguiente clave de un solo uso. La activación se realiza secuencialmente. La última clave es válida hasta que crees una nueva serie de claves."])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usar claves de un solo uso"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de claves"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generar"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportar"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nº"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clave"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Claves de un solo uso"])}
  },
  "Password": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña antigua:"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nueva contraseña:"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmación de la contraseña:"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La \"Nueva contraseña\" y la \"Confirmación de la contraseña\" no coinciden."])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambiar contraseña"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cadena de 4 a 63 caracteres. Se permiten caracteres del alfabeto latino, números y símbolos"])}
  },
  "PayPassword": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña antigua:"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nueva contraseña:"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmación de la contraseña:"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nueva contraseña:"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmación de la contraseña:"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La \"Nueva contraseña\" y la \"Confirmación de la contraseña\" no coinciden."])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La contraseña no debe contener caracteres cirílicos"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear contraseña de confirmación"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambiar contraseña de confirmación"])},
    "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A la página principal"])},
    "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cadena de 4 a 65 caracteres. Se permiten caracteres del alfabeto latino, números y símbolos"])}
  },
  "SecretQuestion": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En caso de que olvides tu contraseña para acceder a la oficina, podrás recuperarla respondiendo a la pregunta secreta."])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En esta sección, puedes cambiar la pregunta/respuesta secreta."])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pregunta secreta"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu pregunta:"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respuesta:"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pregunta secreta"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu pregunta"])}
  }
}