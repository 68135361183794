export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отдать"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получить"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фильтры"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обновить"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продавец"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отдаете"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получаете"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лимиты"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Действия"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["сделок"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сделка"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет сделок"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предложения"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выгода"])}
}