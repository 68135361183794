export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаблоны"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пополнение"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перевод"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вывод"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название шаблона"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оператор"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поля"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операции"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет шаблонов"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получатель"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы действительно хотите удалить шаблон №"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переименовать шаблон "])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переименовать"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Использовать"])}
}