export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Инвойстар тізімі"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Инвойстар табылмады"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Күні"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Түсініктеме"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сома"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дүкен"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мәртебе"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дүкен нөмірі"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шот-фактураны болдырмаңыз"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Төлем кодын сақтау"])}
}