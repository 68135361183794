export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distributeurs automatiques de billets"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez pour agrandir le tableau en plein écran"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualiser"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activité"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sous-concessionnaires"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actif"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactif"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un guichet automatique"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier le cryptomate"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations principales"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Principal"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horaires d'ouverture"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifiant"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description du site d'installation"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normalisé"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non normalisé"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mode de fonctionnement"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lundi"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mardi"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mercredi"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeudi"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendredi"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samedi"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dimanche"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pause déjeuner"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Répéter le mot de passe"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utiliser les comptes existants"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer de nouveaux comptes"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations de base"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heures d'ouverture (informations de référence)"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activité"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune machine cryptographique trouvée"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de guichet automatique"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["latitude"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["longitude"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ligne d'assistance"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID du sous-revendeur"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comptes"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifier"])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devise"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte de transit"])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Création d'un cryptomat"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editer un cryptomat"])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui"])},
  "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interdire"])},
  "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permettre"])},
  "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des collectionneurs"])},
  "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un collectionneur"])},
  "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible d'accéder aux soldes de collecte - aucune donnée"])},
  "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des collectionneurs"])},
  "62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un collectionneur"])},
  "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorisé"])},
  "65": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceptation"])},
  "67": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
  "68": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier le guichet automatique"])},
  "69": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher les soldes de collecte actuels"])},
  "70": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher les soldes de collecte déchargés"])},
  "71": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code PIN"])},
  "73": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous ne précisez pas de sous-revendeur, le guichet automatique vous sera lié"])},
  "74": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La latitude et la longitude sont des coordonnées géographiques qui peuvent être copiées, par exemple, depuis Google Maps en saisissant l'adresse du guichet automatique."])},
  "75": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieu d'installation"])},
  "76": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Au moins une colonne doit être sélectionnée pour afficher le tableau"])},
  "77": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder"])},
  "78": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer"])},
  "79": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accédez aux appareils ATM"])}
}