export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daję"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otrzymuję"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentarz"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powód"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anuluj transakcję"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potwierdź udział w transakcji"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odrzuć transakcję"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przedłuż czas oczekiwania"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anulowanie transakcji"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status użytkownika w transakcji nieokreślony. Coś poszło nie tak"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poproś o arbitraż"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zgódź się na arbitraż"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powiadom o wykonaniu przelewu"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potwierdź otrzymanie przelewu"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oceń transakcję"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyślij ocenę"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transakcja nr "])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korespondent"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timeout"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dzisiaj"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jutro"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anuluj arbitraż"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anuluj arbitraż"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zwróciłeś się o arbitraż"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Złożono przeciwko Tobie wniosek o arbitraż"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trwa postępowanie arbitrażowe (Jesteś powodem)"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trwa arbitraż (jesteś pozwanym)"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Po upływie tego czasu zgoda na arbitraż nastąpi automatycznie i nie będzie już możliwości dodawania odpowiedzi na pytania arbitrażowe"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wymagany arbitraż"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Istnieje arbitraż"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Istnieje arbitraż"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podejmij decyzję w sprawie arbitrażu: płatność wewnętrzna"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prowadzić"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anulować"])}
}