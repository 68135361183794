export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我给"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我收到"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评论"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["原因"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消交易"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认参与交易"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拒绝交易"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["延长等待时间"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消交易"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户在交易中的状态未定义。出了点问题"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请求仲裁"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["同意仲裁"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通知完成转账"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认收到转账"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评估交易"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发送评估"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易编号"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对应方"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["超时"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["今天"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["明天"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消仲裁"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消仲裁"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您已请求仲裁"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已针对您提出仲裁请求"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["仲裁正在进行中（您是原告）"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["仲裁正在进行中（您是被告）"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["计时器到期后，将自动同意仲裁，您将无法再添加对仲裁问题的答案"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["要求的仲裁"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有仲裁"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有仲裁"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["决定仲裁：内部付款"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["执行"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消"])}
}