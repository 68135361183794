export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы можете сделать одновременно несколько платежей. Для этого Вам нужно предварительно создать файл с описанием платежей в формате \"XLS\", \"XLSX\", \"CSV\"."])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анализ файла"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Корреспондент"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коментарий"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Параметр"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Информация о файле"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всего платежей: "])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["на общую сумму: "])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Файл платежей"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Счет списания"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Провести платежи"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продолжить"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузка файла платежей"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проверьте содержимое файла"])},
  "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Массовые платежи и переводы"])}
}