export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["देता हूँ"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्राप्त करता हूँ"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टिप्पणी"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कारण"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सौदा रद्द करें"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सौदे में भागीदारी की पुष्टि करें"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सौदे से इंकार करें"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रतीक्षा समय बढ़ाएं"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सौदा रद्द करना"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सौदे में उपयोगकर्ता की स्थिति निर्धारित नहीं है। कुछ गलत हो गया"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मध्यस्थता का अनुरोध करें"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मध्यस्थता पर सहमत हों"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्थानांतरण की सूचना दें"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्थानांतरण प्राप्ति की पुष्टि करें"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सौदे का मूल्यांकन करें"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मूल्यांकन भेजें"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सौदा №"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रतिपक्षी"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समय सीमा"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आज"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कल"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मध्यस्थता रद्द करें"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मध्यस्थता रद्द करें"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आपने मध्यस्थता का अनुरोध किया है"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आपके विरुद्ध मध्यस्थता का अनुरोध किया गया है"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मध्यस्थता चल रही है (आप वादी हैं)"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मध्यस्थता चल रही है (आप प्रतिवादी हैं)"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टाइमर समाप्त होने के बाद, मध्यस्थता के लिए सहमति स्वचालित रूप से मिल जाएगी और आप अब मध्यस्थता प्रश्नों में अपने उत्तर नहीं जोड़ पाएंगे"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपेक्षित मध्यस्थता"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एक मध्यस्थता है"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एक मध्यस्थता है"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मध्यस्थता पर निर्णय लें: आंतरिक भुगतान"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आचरण"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रद्द करना"])}
}