export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إصدار إضافي"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أصل مشفر"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحساب - مصدر التغطية"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حساب الخصم"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إصدار إضافي"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعليق"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متابعة"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإصدار الحالي"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رمز"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حساب التغطية"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عمولة"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عمولة"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حساب التغطية"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ربط"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البيانات الأساسية"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نسبة التغطية"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التغطية المستقبلية"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شطب التغطية"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المبلغ الذي سيكون في الاحتياطي بعد إصدار الإصدار الإضافي."])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["للشطب"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التغطية الحالية"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المبلغ الذي سيتم خصمه من حسابك بعد إصدار الإصدار الإضافي."])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مبلغ التغطية المستقبلي"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E — مبلغ الإصدار الحالي"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I — الإصدار الإضافي"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["K — سعر الربط"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["P — نسبة التغطية"])}
}