export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройка уведомлений"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Включить"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уведомлять об операциях"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Авторизация (вход в личный кабинет, мобильное приложение и др.)"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уведомлять при событии \"Выполнение финансовых операций с помощью карты\""])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Расходы (любые платежи, переводы, оплата услуг и т.д.)"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поступление денежных средств"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получение письма по внутренней почте"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поступление счета на оплату"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменение настроек безопасности"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтверждать операции кодом из сообщения"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтверждать авторизацию кодом из сообщения"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтверждать при \"Выполнение финансовых операций с помощью карты\""])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтверждать изменение настроек безопасности кодом из сообщения"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтверждать расходы кодом из сообщения"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменения не сохранены, сохранить?"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не сохранять"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])}
}