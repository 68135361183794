export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TICKER"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégorie"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mission"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des crypto-actifs"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas de crypto-actifs"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Émission de token"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepter"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marché"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Précision"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primaire"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secondaire"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Associé à un autre actif"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations détaillées sur le crypto-actif"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier les données du crypto-actif"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Émission supplémentaire"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le crypto-actif"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des crypto-actifs"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepter"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activité"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les tokens peuvent être achetés au distributeur"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les tokens peuvent être vendus au distributeur"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégorie"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tokens du marché secondaire"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affichage des tokens"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous acceptés"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les miens"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UID de l'utilisateur"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destruction d'actif"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour un utilisateur spécifique"])}
}