export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите адрес"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Населенный пункт"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Страна"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регион"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Округ"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Район"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Город"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Внутригородская территория"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Населённый пункт"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Улица"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доп. элемент адреса"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уточнение"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дом"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Почтовый индекс"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес"])}
}