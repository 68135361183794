export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认支付"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["计算退还费用"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["退还支付"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["区块链哈希"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AML风险"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金额"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手续费"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["入账"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["状态"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果您同意更改的条件，请确认入账："])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果这些条件不适合您，您可以将资金退还给发送方，并扣除退还费用："])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金额"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有AML风险"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["因此重新计算了手续费"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["因此入账金额为"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有检测到新收入"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付日期"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["退还"])}
}