export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El servicio de pagos masivos permite transferir fondos rápidamente a otros usuarios y realizar pagos a proveedores de servicios."])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En la sección"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargar archivo de pagos"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["puedes cargar un archivo en formato Excel (XLS, XLSX, CSV) o XML."])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de tareas"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["permite ver el estado de las solicitudes de pagos masivos creadas anteriormente."])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedes crear un archivo en el editor de hojas de cálculo Excel."])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descargar ejemplo de archivo"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notas importantes:"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formato del archivo - CSV, XLS o XLSX."])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El archivo no debe tener encabezados."])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para que los números largos no se conviertan en formato exponencial (por ejemplo, \"5,46546E+15\") y no se recorten los ceros iniciales, puedes anteponer una comilla simple (') al número, o establecer el formato de celda como texto antes de ingresar los datos."])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formato de columnas para pagos a proveedores de servicios"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de columnas en el archivo:"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código del operador"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Se especifica de la lista de operadores proporcionados"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aquí"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". (Para todos los operadores de telefonía móvil, se puede usar el código de operador 7000 - en este caso, el operador se determinará automáticamente por el número de teléfono.)"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". El monto se especifica en el ticker del activo desde el cual se realiza la transferencia. Si no hay tal ticker, se aplicará automáticamente el coeficiente de conversión del sistema."])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentario al pago"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". No es obligatorio, se puede dejar en blanco; longitud máxima de 255 caracteres."])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parámetro de pago 1"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". \nSe prescribe el valor del parámetro requerido por el operador, por ejemplo, el número de teléfono o la dirección del criptoactual. \nMuchos operadores están limitados a un parámetro de pago, pero si el número de parámetros es más de uno, entonces debe indicarlos todos en las columnas vecinas."])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parámetro de pago 2"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parámetro de pago N"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedes ver los parámetros de pago yendo al pago de cualquier operador:"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formato de columnas para transferencias masivas a usuarios"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código del operador"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Se especifica 0 o no se especifica en absoluto."])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". El monto se muestra en el ticker de la cuenta desde la cual se transfiere el activo. Si no hay tal ticker, se aplicará automáticamente el coeficiente de conversión del sistema."])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentario al pago"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". No es obligatorio, se puede dejar en blanco; longitud máxima de 255 caracteres."])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta del destinatario"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Aquí se especifica el número de cuenta del usuario al que se debe realizar la transferencia (20 dígitos)."])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formato de columnas para la emisión masiva de préstamos por parte del agente de crédito"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Esta línea indica que los pagos son préstamos."])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". El monto se especifica en la moneda del producto crediticio."])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentario del préstamo"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". No es obligatorio, se puede dejar en blanco; longitud máxima de 255 caracteres."])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID del prestatario"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Ten en cuenta que el prestatario debe tener un pasaporte digital Mfo-BorrowPerson."])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código del producto crediticio"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Puedes verlo en la lista de productos crediticios para la emisión manual de préstamos."])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Período"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Simplemente el número de períodos (los períodos específicos utilizados están definidos en el producto crediticio, por ejemplo, pueden ser días, semanas, meses, etc.)"])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código del operador (número de formulario)"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Completar este y los siguientes campos no es obligatorio, si no se completan, el prestatario podrá retirar el préstamo más tarde a través de su oficina personal."])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar en caso de error"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Cancelar (1) el préstamo otorgado si la transacción de retiro falla, o dejarlo para el retiro autónomo posterior (0)."])},
  "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parámetro de pago 1"])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Se especifica el valor del parámetro requerido por el operador, por ejemplo, el número de teléfono. Muchos operadores se conforman con un parámetro de pago, pero si hay más de uno, debes especificarlos todos en las columnas adyacentes a la primera."])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parámetro de pago 2"])},
  "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parámetro de pago N"])},
  "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El archivo debe indicar las cuentas o direcciones de los cripto-activos de los destinatarios en una moneda, ya que la cancelación de fondos se lleva a cabo desde una cuenta, y no de varias."])}
}