export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общие сведения"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cведения об оплате"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контрольная подпись"])},
  "List": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мои магазины"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Магазинов не найдено"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать код виджета"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать платежную ссылку"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактировать"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить магазин"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы действительно хотите удалить магазин"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зарегистрировать магазин"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название"])},
    "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Режим платежей"])},
    "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активность"])},
    "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Акцепт"])},
    "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
    "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Действия"])}
  },
  "New": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зарегистрировать магазин"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зарегистрированный в системе электронный магазин может принимать платежи от клиентов через мерчант нашей системы."])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В качестве магазина регистрируются электронные магазины, обменные пункты и любые другие интернет-ресурсы, желающие принимать платежи в свой адрес."])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название или торговая марка"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL-адрес магазина"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Краткое описание магазина"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип сервиса"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Магазин активен"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Секретный ключ"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разрешить автовыплаты"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Посылать результаты на скрипт"])},
    "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес скрипта"])},
    "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Возврат после успешного платежа"])},
    "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["После НЕуспешного платежа"])},
    "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
    "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сгенерировать"])},
    "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не менее 30 знаков."])},
    "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Используется для проверки целостности и достоверности передаваемых данных платежной системой (при отправке магазином пользователя на мерчант) и магазином (при получении результатов платежа)"])},
    "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Далее"])},
    "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назад"])},
    "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["К списку магазинов"])},
    "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Некорректный URL"])}
  },
  "Invoices": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список инвойсов"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Инвойсов не найдено"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комментарий"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Магазин"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер магазина"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменить инвойс"])},
    "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код платежа магазина"])}
  }
}