export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сыртқы активті өңдеу"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Атауы"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Болдырмау"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сақтау"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Топ"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Синонимдер"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Елдер"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активке байланыстыру"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бірліктер"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қысқаша"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дәлдік"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Белсенді"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сауда үшін қабылданған"])}
}