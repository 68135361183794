export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मुद्रा जोड़े"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मुद्रा जोड़ा बनाएं"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मुद्रा जोड़ा संपादित करें"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मुद्रा जोड़ा हटाएं"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कार्रवाई"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दी गई मुद्रा"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्राप्त मुद्रा"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["गेटवे"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपडेट प्रकार"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई मुद्रा जोड़े नहीं मिले"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्या आप वाकई मुद्रा जोड़े को हटाना चाहते हैं"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दूसरी मुद्रा से जुड़ा हुआ"])}
}