export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зареєструвати магазин"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зареєстрований в системі електронний магазин може приймати платежі від клієнтів через мерчант нашої системи."])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Як магазин реєструються електронні магазини, обмінні пункти та будь-які інші інтернет-ресурси, які бажають приймати платежі на свою адресу."])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва або торгова марка"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL-адреса магазину"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Короткий опис магазину"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип сервісу"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Магазин активний"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Секретний ключ"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дозволити автовиплати"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відправляти результати на скрипт"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адреса скрипта"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повернення після успішного платежу"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Після НЕуспішного платежу"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберегти"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Згенерувати"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Щонайменше 30 знаків."])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Використовується для перевірки цілісності та достовірності переданих даних платіжною системою (при відправці магазином користувача на мерчант) та магазином (при отриманні результатів платежу)"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Далі"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назад"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["До списку магазинів"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неправильна URL -адреса"])}
}