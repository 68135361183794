export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Налаштування доступу за IP-адресою не дозволить вам (або зловмиснику) працювати із системою з адреси, не прописаної у списку дозволених."])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обмеження доступу до кабінету за IP-адресами"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати адреси"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адреса: "])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Блок адрес: "])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["по:"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш IP: "])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список адрес"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["З адреси"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["По адрес"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступ"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дії"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адреси відсутні"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберегти"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заборонити"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дозволити"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введіть IP-адресу"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP-адресу введено неправильно"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не вказано жодної IP-адреси або блоку адрес."])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жодна IP-адреса не дозволена для авторизації. Ви не зможете отримати доступ до свого рахунку."])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступ за IP"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["з:"])}
}