export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outgoing"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incoming"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["asset"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I give it away"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["internal"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I get"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internal assets"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assets not found"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["External assets"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["external"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected:"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internal"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["External"])}
}