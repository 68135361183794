export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset description"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["date"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No ads"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["my advertisements"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My advertisements"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New ad"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actively"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not active"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direction"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["External asset"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internal asset"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I exchange"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I receive"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limits"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publication date"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account balance"])}
}