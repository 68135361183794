export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bolsa"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mercado. Resumen"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ofertas similares"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ofertas de contraparte"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de cambio"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dan"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reciben"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mis órdenes"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Últimas operaciones"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de cambio"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entregado"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha y hora"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entregar"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recibir"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de cambio"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operaciones"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["comisión"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repetir"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay ofertas"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay operaciones"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay órdenes"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completado en su totalidad"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procesamiento no iniciado"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procesamiento iniciado"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completado parcialmente"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelado en su totalidad"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completado parcialmente y cancelado"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recibido"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todas las órdenes"])}
}