export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["盒式磁带数量"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["包埋盒ID"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["盒式磁带收藏编号"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["磁带评级"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已上传"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["和"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["服务器"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["数量"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["和"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自动提款机"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["和"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["实际上"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["数量"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["和"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["卡带评论"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无数据可显示"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["设备集合"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有效的"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["c"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["经过"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["遗体被运走"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["服务器"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["账单张数"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["和"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自动提款机"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["账单张数"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["和"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评论"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自动售货机"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拒绝/撤回"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["票据承兑人"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拒绝卡片"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["牌"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当前的"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["卸载的"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ATM 收款余额"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["设备集合"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有效的"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["安装在 ATM 上的卡带"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查获的"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["遗体被运走"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["遗体被运走"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["空盒："])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["附着力："])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["附加信息："])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["迄今为止"])}
}