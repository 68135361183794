export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кассета нөмірі"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кассета идентификаторы"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кассета жинағының нөмірі"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кассета рейтингі"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жүктеп салынды"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["сомасы"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сервер"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Саны"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["сомасы"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["банкомат"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["сомасы"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шын мәнінде"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Саны"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["сомасы"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кассета туралы түсініктеме"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Көрсетілетін деректер жоқ"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Құрылғы жинағы"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["жарамды"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["в"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Авторы"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["қалдықтары тасымалданады"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сервер"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шоттардың саны"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["сомасы"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["банкомат"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шоттардың саны"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["сомасы"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пікір"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Диспенсер"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қабылдамау/қайтару"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вексельді қабылдаушы"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карталардан бас тарту"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карталар"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ток"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["түсірілген"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Банкоматтардың инкассациясының қалдықтары"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Құрылғы жинағы"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["жарамды"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["банкоматқа орнатылған кассета"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ұсталды"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["қалдықтары тасымалданады"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["қалдықтары тасымалданады"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["бос кассета:"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["адгезия:"])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қосымша ақпарат:"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["бүгінгі күнге дейін"])}
}