export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["货币对"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建货币对"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编辑货币对"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除货币对"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["给出的货币"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收到的货币"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["网关"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新类型"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未找到货币对"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您确定要删除货币对吗"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["链接到其他货币"])}
}