export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres de notification"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activer"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifier des opérations"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorisation (connexion au compte personnel, application mobile, etc.)"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifier en cas d'événement « Réalisation d'opérations financières avec la carte »"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dépenses (tout paiement, transfert, paiement de services, etc.)"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrée de fonds"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réception d'un message via le courrier interne"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réception d'une facture de paiement"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modification des paramètres de sécurité"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer les opérations avec le code du message"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer l'autorisation avec le code du message"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer lors de la « Réalisation d'opérations financières avec la carte »"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer la modification des paramètres de sécurité avec le code du message"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer les dépenses avec le code du message"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifications non enregistrées, enregistrer ?"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne pas enregistrer"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])}
}