export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations sur l'annonce"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations de base"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Base"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supplémentaire"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de création"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actif"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepter"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de début de la transaction"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de fin de la transaction"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créateur"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["donne"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reçoit"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponible uniquement par lien"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponible uniquement pour les utilisateurs vérifiés"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["limites"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propriétaire de l'annonce"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Délai d'attente"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jours"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["heures"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minutes"])}
}