export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Толтырылатын шот"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Төлем әдісі"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Үлгі атауы:"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сақтау"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Үлгіні сақтау"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жалғастыру"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Үлгілер"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шотты толықтыру"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аударым деректері"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аударымды тексеру"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аударым туралы ақпарат"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нұсқаулық"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адресті толықтыру"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Толтырылатын адрес"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Экспорт"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тапсырмалар тізіміне"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аударым адресі"])}
}