export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nabídka"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popis"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Údaje"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimální"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximální"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limit částky"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čekací doba (min)"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nabídka je aktivní"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostupné pouze po odkazu"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostupné pouze ověřeným uživatelům"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušení"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložit"])}
}