export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrierung"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persönliches Konto"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschäftskonto"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname:"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname:"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vatersname"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lateinische Buchstaben, Bindestriche und Apostrophe sind erlaubt."])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bond"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lateinische Buchstaben sind erlaubt, ebenso Bindestriche, Leerzeichen und Apostrophe."])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["James"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["None"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lateinische Buchstaben sind erlaubt, ebenso wie Bindestriche, Anführungszeichen, Leerzeichen, Zahlen und Apostrophe."])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"My Company\" Ltd"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erlaubt sind lateinische Buchstaben (groß und klein), Zahlen, Symbole _ - . ! $ % ^ * ="])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort wiederholen"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwörter stimmen nicht überein"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrierung"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzerdaten"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie eine gültige E-Mail-Adresse oder Telefonnummer an. Telefonnummer im internationalen Format angeben, z. B.: +499001234567."])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben sich erfolgreich registriert"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jetzt können Sie"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sich in Ihrem persönlichen Konto anmelden"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Konto wird von unserem Mitarbeiter überprüft, und wir werden Ihnen später über die Ergebnisse der Aktivierung informieren"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine E-Mail mit einem Bestätigungslink wurde an Ihre E-Mail-Adresse gesendet"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An Ihre E-Mail-Adresse"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nach der ersten Autorisierung werden Sie aufgefordert, Ihre Telefonnummer zu bestätigen"])}
}