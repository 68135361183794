export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सौदे का कोड"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["देता हूँ"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्राप्त करता हूँ"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दिशा"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मूल्य"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्थिति"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समय सीमा"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्रियाएँ"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई सक्रिय सौदे नहीं"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मेरे सौदे"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सक्रिय"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आंतरिक संपत्ति"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["निष्क्रिय"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रतिपक्षी"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोड"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अवधि पी"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["द्वारा अवधि"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फिल्टर"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अद्यतन"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मैं देता हूं"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["= बराबर"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≠ समान नहीं है"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["> अधिक"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≥ अधिक या बराबर"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["< कम"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≤ कम या बराबर"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मैं समझ गया"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जोड़"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एक संपत्ति चुनें"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मेरी भूमिका"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["निर्माता"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लेने वाला"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आंतरिक परिसंपत्ति"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी विकल्प"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मैं समझ गया"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मैं देता हूं"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मेकर आईडी"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्थिति"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["घोषणा संख्या"])}
}