export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezpieczeństwo"])},
  "Antiphishing": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfiguracja antyphishingu jest dodatkowym mechanizmem ochrony podczas pracy z kontem."])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wprowadzone przez Ciebie powitanie będzie wyświetlane w górnej części każdej strony konta."])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prawidłowa fraza pokaże, że jesteś na właściwej stronie, nieprawidłowa fraza będzie sygnałem: nie wykonuj operacji, natychmiast skontaktuj się z działem bezpieczeństwa naszego systemu."])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Włącz antyphishing:"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fraza antyphishingu:"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przeciwpoślizg"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmiany wejdą w życie po następnej autoryzacji"])}
  },
  "IP": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfiguracja dostępu na podstawie adresu IP uniemożliwi Tobie (lub napastnikowi) pracę z systemem z adresu, który nie jest wymieniony na liście dozwolonych."])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ograniczenie dostępu do konta na podstawie adresów IP"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj adresy"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres: "])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blok adresów: "])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["do:"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twój IP: "])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista adresów"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nr"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Od adresu"])},
    "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do adresu"])},
    "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostęp"])},
    "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Działania"])},
    "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń"])},
    "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak adresów"])},
    "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz"])},
    "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zabroń"])},
    "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zezwól"])},
    "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wprowadź adres IP"])},
    "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieprawidłowy adres IP"])},
    "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie określono żadnego adresu IP ani bloku adresów."])},
    "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Żaden adres IP nie jest dozwolony do autoryzacji. Nie będziesz mieć dostępu do swojego konta."])},
    "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostęp na podstawie IP"])},
    "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["od:"])}
  },
  "Magic": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magiczne słowo to dodatkowe ustawienie bezpieczeństwa."])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeśli magiczne słowo jest włączone, przy każdej autoryzacji system poprosi o wprowadzenie pewnych znaków (według ich numeru) z magicznego słowa."])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magic Word:"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapytaj magiczne słowo:"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przy wejściu do konta:"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przy płatnościach przez merchanta:"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przy użyciu karty:"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magic Word"])}
  },
  "Onekey": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jednorazowe klucze znacznie zwiększają bezpieczeństwo systemu."])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podczas logowania program poprosi nie tylko o login i hasło, ale także o kolejny jednorazowy klucz. Aktywacja odbywa się sekwencyjnie. Ostatni klucz jest ważny do momentu utworzenia nowej serii kluczy."])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Użyj jednorazowych kluczy"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista kluczy"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generuj"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eksport"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nr"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klucz"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jednorazowe klucze"])}
  },
  "Password": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stare hasło:"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nowe hasło:"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potwierdzenie hasła:"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"Nowe hasło\" i \"Potwierdzenie hasła\" nie pasują do siebie."])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmiana hasła"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Od 4 do 63 znaków. Dozwolone są litery alfabetu łacińskiego, cyfry i symbole"])}
  },
  "PayPassword": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stare hasło:"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nowe hasło:"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potwierdzenie hasła:"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nowe hasło:"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potwierdzenie hasła:"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"Nowe hasło\" i \"Potwierdzenie hasła\" nie pasują do siebie."])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hasło nie powinno zawierać cyrylicy"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tworzenie hasła potwierdzającego"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmiana hasła potwierdzenia"])},
    "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do strony głównej"])},
    "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Od 4 do 65 znaków. Dozwolone są litery alfabetu łacińskiego, cyfry i symbole"])}
  },
  "SecretQuestion": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["W przypadku zapomnienia hasła do konta, będziesz mógł je odzyskać, odpowiadając na pytanie zabezpieczające."])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["W tej sekcji możesz zmienić pytanie/odpowiedź zabezpieczające."])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pytanie zabezpieczające"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twoje pytanie:"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odpowiedź:"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pytanie zabezpieczające"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Własne pytanie"])}
  }
}