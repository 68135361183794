export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrole elektroniczne"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz czek"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Działalność"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utworzony"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto do odpisu"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suma"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numer"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seria"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentarz"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["działania"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktywnie"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieaktywny"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Żadnych kontroli"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktywuj"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprawdzać"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zamknąć"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie znaleziono e-rachunków"])}
}