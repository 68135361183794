export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of invoices"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No invoices found"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["date"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A comment"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sum"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shop"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Store number"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel the invoice"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Store payment code"])}
}