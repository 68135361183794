export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дүкенді тіркеу"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жүйеде тіркелген электрондық дүкен клиенттерден төлемдер қабылдауға мүмкіндік береді."])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дүкен ретінде электрондық дүкендер, айырбастау пунктері және өз мекенжайларына төлемдер қабылдағысы келетін кез келген интернет-ресурстар тіркеледі."])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Атауы немесе сауда белгісі"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дүкеннің URL-адресі"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дүкеннің қысқаша сипаттамасы"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қызмет түрі"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дүкен белсенді"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Құпия кілт"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автовыплатуға рұқсат беру"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нәтижелерді скриптке жіберу"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скрипт мекенжайы"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сәтті төлемнен кейін қайтару"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сәтсіз төлемнен кейін"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сақтау"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Генерациялау"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кемінде 30 таңба."])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бұл төлем жүйесі (пайдаланушыны мерчантқа жіберу кезінде) және дүкен (төлем нәтижелерін алу кезінде) берілетін деректердің тұтастығы мен дұрыстығын тексеру үшін қолданылады"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Келесі"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Артқа"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дүкендер тізіміне"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қате URL мекенжайы"])}
}