export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Информация об объявлении"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Основная информация"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Основное"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дополнительно"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата создания"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активно"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Акцепт"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата начала сделки"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата окончания сделки"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курс"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мейкер"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["отдаёт"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["получает"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступно только по ссылке"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступно только верифицированным пользователям"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["лимиты"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Владелец объявления"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Описание"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Таймаут"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["дней"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["часов"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["минут"])}
}