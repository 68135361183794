<template>
    <div class="row container-fluid mt-1 g-3 p-0 align-items-center input">
        <div
            v-if="!this.withoutLabel"
            class="p-0"
            :class="{'col-sm-3': !this.isNumbersGroup, 
                'col-sm-5': this.isNumbersGroup, 
                'text-end pr-1': this.labelRight
            }"
        >
            <label :for="id" class="col-form-label input-label"
            >   
                {{this.label}}
                <span class='require' v-if="this.require"> *</span>
            </label>
        </div>
        <div class='p-0 input-wrapper' :class="!this.isNumbersGroup ? 'col-sm-9' : 'col-sm-7'">
            <div class="row align-items-center" :class="!this.isNumbersGroup ? 'col-sm-9' : ''">
                <div
                    class="col-6"
                    :class='{"pr-0": this.$store.state.isMobile,
                        "mr-1": this.isNumbersGroup,
                        "col-md-7": this.largeNumbers,
                        "col-md-4": !this.largeNumbers}' 
                    >
                    <span :class="this.denotation ? 'position-relative' : ''">
                        <input
                            class='form-control input-container'
                            :id="id"
                            type='text'
                            v-model="this.value"
                            v-on:input="this.validate"
                            :disabled="this.disabled"
                            :class="{ invalid: this.invalid}"
                        >
                        <span v-if="this.denotation" class="position-absolute grey denotation-element">
                            {{ this.denotation }}
                        </span>
                        <div v-if="this.invalidHint && this.invalid" class="invalid-tooltip" :id="this.id + '-invalid'">
                            {{ this.invalidHint }}
                        </div>
                    </span>
                </div>
                <div class="d-flex col-5 col-md-1 p-0" :class="!this.isNumbersGroup ? 'justify-content-between' : ''">
                    <Button
                        name='-'
                        :id="id + '_-'"
                        type='btn-outline-warning btn-sm mr-1'
                        v-on:click="() => {this.changeValue(-1)}"
                        :disabled="this.disabled"
                    />
                    <Button
                        name='+'
                        :id="id + '_+'"
                        type='btn-outline-warning btn-sm'
                        v-on:click="() => {this.changeValue(1)}"
                        :disabled="this.disabled"
                    />
                </div> 
            </div>
            <Tooltip
                v-if="this.hint"
                :id="id + '_hint'"
                class="bs-tooltip"
                :content='this.hint'
            />
        </div>
    </div>
</template>

<script>
import Button from './Button.vue';
import Tooltip from '@/components/service/Tooltip.vue';
export default {
  components: { 
    Button,
    Tooltip
  },
    data() {
        return {
            value: this.dataValue !== '' ? this.dataValue : '',
        }
    },
    mounted() {
        // console.log(this.dataValue)
    },
    props: {
        id: [String, Boolean],
        label: [String],
        withoutLabel: [Boolean],
        hint: [String],
        dataValue: [Number, String],
        require: [Boolean],
        def: [Number, Boolean],
        disabled: {
            type: Boolean,
            default: false,
        },
        isNumbersGroup: [Boolean],
        hotUpdate: {
            default: false,
            type: [Boolean],
        },
        labelRight: [Boolean],
        largeNumbers: [Boolean],
        denotation: [String, Boolean],
        invalid: [Boolean, String],
        invalidHint: [String, Boolean],
    },
    watch: {
        dataValue(newV, oldV) {
            console.log('watch', newV, oldV)
            this.value = newV
            if (this.hotUpdate) {
                this.validate()
            }
        }
    },
    methods: {
        setValue() {
            this.$emit('updateParentState', {
                id: this.id,
                value: this.value
            })
        },
        changeValue(i) {
            if ((+this.value + i) >= (this.def ? this.def : 0) ) {
                this.value = +this.value + i;
            }
            this.validate();
        },
        validate() {
            this.value = this.$lib.summValidate(String(this.value));
            if (isNaN(Number(this.value))) {
                this.value = this.def ? this.def : 0;
            }
            console.log('in', this.value)
            this.setValue();
        }
    }
}
</script>

<style lang="scss" scoped>
.input {
    font-family: 'HelveticaNeue roman';
    &-wrapper {
        position: relative;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-between;
    }
    .btn-sm {
        min-width: 1.8rem;
    }
}
.require {
    color: red;
}
.invalid-feedback {
  display: block;
}
.invalid {
  border-color: $color-invalid;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  box-shadow: none;
}
.invalid-tooltip {
  display: block;
}
.denotation-element {
    right: 10px;
    top: 8px;
}

</style>