export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear cheque electrónico"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta de débito"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor nominal de cada cheque"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentario"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de cheques"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atrás"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serie:"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número:"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de activación:"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cheques creados"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descargar archivo con cheques"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guarde esta información - los códigos de activación se ocultarán después de cerrar esta página"])}
}