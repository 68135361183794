export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Беремін"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аламын"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Түсініктеме"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Себебі"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мәміледен бас тарту"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мәмілеге қатысуды растау"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мәміледен бас тарту"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Күту уақытын ұзарту"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мәміледен бас тарту"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мәміледегі пайдаланушының статусы анықталмаған. Бірдеңе дұрыс емес"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Арбитраж сұрау"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Арбитражға келісу"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аударым жасалғаны туралы хабарлау"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аударымды алғанын растау"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мәмілені бағалау"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бағалау жіберу"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мәміле № "])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Корреспондент"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Таймаут"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["бүгін"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ертең"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Арбитражды тоқтату"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Арбитражды тоқтату"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сіз арбитражға жүгіндіңіз"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сізге қарсы арбитраж талап етілді"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Арбитраж жүріп жатыр (Сіз талапкерсіз)"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Арбитраж жүріп жатыр (Сіз жауапкерсіз)"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Таймер аяқталғаннан кейін арбитражға келісім автоматты түрде беріледі және сіз бұдан былай төрелік сұрақтарға жауаптарыңызды қоса алмайсыз."])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Талап етілген арбитраж"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Төрелік бар"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Төрелік бар"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Төрелік туралы шешім қабылдау: ішкі төлем"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бастау"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Күшін жою"])}
}