export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die IP-Zugangseinstellung verhindert, dass Sie (oder ein Angreifer) mit dem System von einer Adresse arbeiten, die nicht auf der Liste der erlaubten Adressen steht."])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP-Adressen-beschränkter Zugang zum Schrank"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adressen hinzufügen"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse: "])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinzufügen"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adressblock: "])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bis:"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre IP: "])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adressliste"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nr."])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Von Adresse"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bis Adresse"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zugang"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktionen"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Adressen"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verweigern"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erlauben"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP-Adresse eingeben"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falsche IP-Adresse eingegeben"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine IP-Adresse oder Adressblock angegeben."])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine IP-Adresse zur Autorisierung erlaubt. Sie können nicht auf Ihr Konto zugreifen."])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP-Zugang"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["von:"])}
}