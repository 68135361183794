export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加密资产数据"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["问题地址："])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["姓名："])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["股票代码："])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["权限"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目的/使命/宗旨"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["财务特征"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["类别"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["准确性："])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["联系方式"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["排放："])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["责任人："])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发行人："])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["单位成本："])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["安全："])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ATM机购买："])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ATM 机销售："])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易所资产交易："])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["P2P资产交易："])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["托管地址"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["白皮书地址"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子邮件"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["要约人"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["其他联系方式"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["去"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发行人"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最大排放："])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["其他"])}
}