export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caixas eletrônicos"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clique para expandir a tabela para tela cheia"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redefinir"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atualizar"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atividade"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subconcessionários"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ativo"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inativo"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crie um caixa eletrônico"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar criptomato"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvar"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informação principal"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Principal"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horário de funcionamento"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senha"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereço"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrição do local de instalação"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normalizado"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não normalizado"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horário de funcionamento"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segunda-feira"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terça-feira"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quarta-feira"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quinta-feira"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sexta-feira"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sábado"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domingo"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intervalo para almoço"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repetir senha"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usar contas existentes"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar novas contas"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informações principais"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conta"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horário de funcionamento (informação de referência)"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atividade"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ações"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma máquina criptográfica encontrada"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número do caixa eletrônico"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["latitude"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["longitude"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linha de apoio"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID do subrevendedor"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contas"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificar"])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moeda"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conta de trânsito"])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criação de um criptomata"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editando um criptomata"])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senha"])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sim"])},
  "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proibir"])},
  "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permitir"])},
  "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de colecionadores"])},
  "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar um coletor"])},
  "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não é possível acessar os saldos de cobrança - sem dados"])},
  "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de colecionadores"])},
  "62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar um coletor"])},
  "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permitido"])},
  "65": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceitação"])},
  "67": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereço"])},
  "68": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar caixa eletrônico"])},
  "69": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver saldos de cobrança atuais"])},
  "70": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver saldos de cobrança descarregados"])},
  "71": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código PIN"])},
  "73": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se você não especificar um subconcessionário, o caixa eletrônico estará vinculado a você"])},
  "74": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latitude e longitude são coordenadas geográficas que podem ser copiadas, por exemplo, do Google Maps inserindo o endereço do caixa eletrônico"])},
  "75": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Local de instalação"])},
  "76": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pelo menos uma coluna deve ser selecionada para exibir a tabela"])},
  "77": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvar"])},
  "78": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar"])},
  "79": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vá para dispositivos ATM"])}
}