export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destruction d'actif"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant à détruire"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte pour retour"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détruire"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant à brûler"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le montant à détruire ne peut pas être supérieur au montant d'émission"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour de couverture"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À retourner"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant qui restera en réserve après la destruction de l'actif"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant qui sera retourné sur votre compte après la destruction de l'actif"])}
}