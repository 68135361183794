export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorisation"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifiant :"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe :"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe oublié ?"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Première visite ?"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un compte"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Récupération de mot de passe"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphone ou e-mail :"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Récupérer"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Récupération de mot de passe"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de famille :"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom :"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patronyme :"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de naissance :"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrer au bureau"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trouver"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateur trouvé"])}
}