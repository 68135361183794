export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Період з"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Період по"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Експорт"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фільтри"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скинути"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оновити"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакція"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Параметр"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакція"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операція"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кореспондент"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Параметри/Коментар"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операцій не знайдено"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума операцій"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Надходження:"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" шт. на суму"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Витрати:"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Типи операцій"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виплати, оплата послуг"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Внутрішні перекази"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оплата рахунку"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перекази на свої рахунки"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створення крипто чеків"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активація крипто чеків"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Інше"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Надходження/витрати"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Надходження"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Витрати"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["= рівно"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≠ не рівно"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["> більше"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≥ більше або рівно"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["< менше"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≤ менше або рівно"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успішно завершено"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В обробці"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Помилка"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сьогодні"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вчора"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тиждень"])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Місяць"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рік"])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Історія операцій"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поповнення рахунку"])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рахунок не обрано"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обмін на біржі"])},
  "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поповнення адреси"])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перекази всередині свого гаманця"])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кореспондент"])},
  "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операція"])}
}