export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code / Link zur Zahlung generieren"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschäftsoperationsnummer"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betrag"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zusätzlicher Parameter"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgreiche URL"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehlerhafte URL"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benachrichtigungs-URL"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL, zu der der Kunde nach erfolgreicher Zahlung zurückkehrt"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL, zu der der Kunde nach einem Fehler zurückkehrt"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL, an die das Zahlungsergebnis gesendet wird"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betrag festlegen"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopieren"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüfen"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungslink"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formular"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumentation"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gültigkeitsdauer der Rechnung in Minuten, standardmäßig 60"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gültigkeitsdauer des Links"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formular"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Widget"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie den Betrag nicht festlegen, kann der Zahler ihn selbstständig eingeben (geeignet z. B. für Spenden für wohltätige Zwecke)"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verstecken"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsformular"])}
}