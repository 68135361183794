export default {
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["देने की राशि"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["राशि"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्राप्त करने की राशि"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फिल्टर"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपडेट"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लेन-देन"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लेन-देन"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई प्रस्ताव नहीं"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रस्ताव"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विक्रेता आईडी"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रस्ताव कोड"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्रिप्टो-संपत्ति"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विवरण"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विक्रेता सत्यापित है"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["केवल सत्यापित उपयोगकर्ताओं के लिए"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपने आप से लेन-देन नहीं कर सकते"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संपत्ति चुनें"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विज्ञापन की जानकारी"])}
}