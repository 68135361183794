export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom :"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nom est le nom complet du crypto-actif, tel qu’il sera vu par tous : l’émetteur lui-même, les acheteurs, les vendeurs, le transformateur, etc."])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TICKER"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une abréviation de 3 à 10 caractères. \nLes lettres latines majuscules, les tirets (tirets) et les chiffres sont acceptés."])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégorie :"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez la catégorie à laquelle votre crypto-actif correspond le mieux"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formats pris en charge : png, jpg, jpeg, svg. \nTaille du fichier ne dépassant pas 1 Mo, 500*500 pixels"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décrire la raison pour laquelle ce crypto-actif est émis par l'émetteur, dans quel domaine il travaille ou travaillera, quelle est sa mission."])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo :"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objectif / mission / but :"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Précision :"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Émission :"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeur unitaire :"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de décimales pour le montant. Par exemple, la précision de la plupart des monnaies fiduciaires telles que le dollar américain ou l'euro est de 2."])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nombre d'unités de l'actif cryptographique en cours d'émission. Le montant de l'émission sera immédiatement disponible sur votre compte une fois la transaction de création de l'actif cryptographique terminée."])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeur unitaire déclarée :"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garantie :"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte :"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personne responsable :"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décision d'émettre un crypto-actif:"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporter les opérations vers la blockchain :"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de blockchain :"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable crypto-asset transactions:"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restrictions/Sanctions :"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spécifiez quels citoyens ou résidents fiscaux de quels pays de la liste ci-dessus sont interdits d'utiliser des crypto-actifs dans leur chiffre d'affaires:"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres restrictions et conditions :"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse d'hébergement:"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse du livre blanc :"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email :"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telegram :"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twitter :"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook :"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres contacts :"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivant"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un crypto-actif"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom et objectif"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caractéristiques financières"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Émetteur"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export vers la blockchain"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limites et restrictions"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacts"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non défini"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixation au pair avec des devises fiduciaires et d'autres actifs"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Émetteur (Personne émettant un crypto-actif)"])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offrant (Personne distribuant un crypto-actif)"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expéditeur"])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destinataire"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moi (activer le parrainage)"])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Placement de crypto-actifs"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crypto-actif désigne une représentation numérique d'une valeur ou d'un droit qui peut être transférée et stockée électroniquement à l'aide de la technologie blockchain. Les crypto-actifs ne comprennent PAS les monnaies fiduciaires, les titres et autres instruments financiers et actifs soumis à une réglementation gouvernementale distincte."])},
  "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier l'actif cryptographique"])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration"])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceptation"])},
  "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motif du refus d'accepter"])},
  "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte de l'offrant"])},
  "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte de l'offrant dans la devise contraignante"])},
  "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'Offrant est un représentant de l'émetteur d'un actif dans les opérations d'achat/vente sur le marché primaire. \nLe compte est passif. \nPeut être un compte d'émission d'actifs"])},
  "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le compte est passif, lié en devise. \nDoit appartenir au même utilisateur que le compte précédent"])},
  "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoriser les utilisateurs à acheter un actif aux guichets automatiques"])},
  "62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoriser la vente de l'actif par les utilisateurs des distributeurs automatiques de billets"])},
  "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulaire d'achat après-vente"])},
  "64": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les transactions sur le marché secondaire sont effectuées via des passerelles"])},
  "65": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulaire de vente après-vente"])},
  "66": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les transactions sur le marché secondaire sont effectuées via des passerelles"])},
  "67": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoriser les transactions avec un actif en bourse"])},
  "68": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoriser les transactions d'actifs via P2P"])},
  "69": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous êtes un entrepreneur individuel ou un représentant d'une personne morale, vous devez télécharger le dossier de décision de libération d'un actif numérique au format PDF. \nLa solution doit contenir toutes les principales caractéristiques de l'actif virtuel et être signée avec une signature électronique de l'entrepreneur individuel ou de cette personne morale. \nUn exemple de décision d'émission d'un actif numérique est disponible sur les pages de notre site Internet dans la rubrique « Documents »"])},
  "70": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Placer un actif virtuel"])},
  "71": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte d'émission de remplacement"])},
  "72": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner parmi les comptes émetteurs"])},
  "73": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant à amortir :"])},
  "74": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas de compte"])},
  "75": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un compte"])},
  "76": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max. \némission:"])},
  "77": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La taille d'émission dépasse le maximum. \némissions"])},
  "78": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max. \nl'émission est inférieure à la taille de l'émission ou égale à zéro"])},
  "79": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les formes sont absentes"])}
}