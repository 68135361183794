export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzeige"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimal"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximal"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betragslimit"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wartezeit (Minuten)"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzeige aktiv"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur über den Link verfügbar"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur für verifizierte Benutzer verfügbar"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stornieren"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])}
}