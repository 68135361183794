export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Внешние активы"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Привязан к активу"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Единицы измерения"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Группа"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата создания"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автор"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Действия"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Период с"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Период по"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фильтры"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сбросить"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обновить"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет активов"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сегодня"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вчера"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неделя"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Месяц"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Год"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Привязан к активу"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Группа"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автор"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активность"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Страны"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активно"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неактивно"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактировать внешний актив"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить внешний актив"])}
}