export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["广告信息"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["基本信息"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["基本"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["附加"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建日期"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活跃"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["接受"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易开始日期"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易结束日期"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["汇率"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建者"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["给出"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["接收"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["仅通过链接可用"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["仅对已验证用户可用"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["限制"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["广告所有者"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["描述"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["超时"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["天"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["小时"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分钟"])}
}