export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कैसेट नंबर"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कैसेट आईडी"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कैसेट संग्रह संख्या"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कैसेट रेटिंग"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपलोड किए गए"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जोड़"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सर्वर"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मात्रा"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जोड़"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एटीएम"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जोड़"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वास्तव में"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मात्रा"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जोड़"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कैसेट पर टिप्पणी"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रदर्शित करने के लिए कोई डेटा नहीं"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपकरण संग्रह"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वैध"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सी"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["द्वारा"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अवशेषों को ले जाया जाता है"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सर्वर"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बिलों की संख्या"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जोड़"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एटीएम"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बिलों की संख्या"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जोड़"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टिप्पणी"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दवासाज़"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अस्वीकार/वापस लेना"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बिल स्वीकर्ता"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कार्ड अस्वीकार करें"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कार्ड"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मौजूदा"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उतार"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एटीएम संग्रह शेष"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपकरण संग्रह"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वैध"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एटीएम में कैसेट लगाया गया"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जब्त"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अवशेषों को ले जाया जाता है"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अवशेषों को ले जाया जाता है"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खाली कैसेट:"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आसंजन:"])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अतिरिक्त जानकारी:"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["तारीख तक"])}
}