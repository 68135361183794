export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internal Asset"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["External asset"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name RU"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synonyms"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Countries"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If completed, the ad will only be available to users from the specified countries"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tie it to an asset"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Units"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbreviated"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accuracy"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requisites"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer direction"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limit"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting time (min)"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad is active"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available only via link"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available only to verified users"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to previous"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direction"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restrictions"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creating an ad"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Own external asset"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do not tie"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shorten course"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return back"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No account in such an asset"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create an account"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specify course"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The course is correct"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shorten course"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return back"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum is greater than maximum"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Where and how you want to receive the transfer. For example: Transfer in the bank application to the phone number +499990001111 (recipient Alexander N.)"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I'm transfer"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receiving"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assets"])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requested details"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What details the taker should send so that you can make the transfer to him"])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select asset"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select asset"])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What would you like to receive: funds in an internal asset, for example, BTC, or in an external asset, for example, TOKEN GOLD?"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please comment on the specifics of this deal, for example: \\\"The external asset is transferred only during a personal meeting\\\""])},
  "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type of values ​​to which an external asset belongs, for example, \"cash\""])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name of the asset for display on the platform"])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The asset can also be named using words from the list"])},
  "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matching with an existing asset"])},
  "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For example: litres, pieces, hours, boxes"])},
  "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For example: L, pcs, hrs, bx"])},
  "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How many digits can be after the decimal point in asset values"])},
  "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have chosen to create a new asset that does not yet exist, please fill in its characteristics"])},
  "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time given to parties to meet the conditions"])},
  "62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An internal asset is an asset that exists within the \\\"New Reality\\\" system. An external asset is an asset that does not exist within the \\\"New Reality\\\" system."])},
  "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not tied"])},
  "64": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expected value: from 1 to 2147483"])}
}