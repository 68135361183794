export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أزواج العملات"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء زوج عملات"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل زوج العملات"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حذف زوج العملات"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إجراءات"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العملة المعطاة"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العملة المستلمة"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البوابة"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع التحديث"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم يتم العثور على أزواج العملات"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل أنت متأكد أنك تريد حذف زوج العملات"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مرتبط بعملة أخرى"])}
}