export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spalanie aktywa"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kwota do spalenia"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto do zwrotu"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spal"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kwota do spalenia"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kwota do spalenia nie może być większa niż kwota emisji"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zwrot pokrycia"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do zwrotu"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kwota, która pozostanie w rezerwie po spaleniu aktywa"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kwota, która zostanie zwrócona na twoje konto po spaleniu aktywa"])}
}