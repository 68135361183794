export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अतिरिक्त उत्सर्जन"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्रिप्टो-एसेट"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खाता - कवरेज स्रोत"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["डेबिट खाता"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अतिरिक्त उत्सर्जन"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टिप्पणी"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जारी रखें"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वर्तमान उत्सर्जन"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टोकन"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कवरेज गणना"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कमीशन"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कमीशन"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कवरेज गणना"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बाइंडिंग"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मूल डेटा"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कवरेज प्रतिशत"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भविष्य कवरेज"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कवरेज राइट-ऑफ"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अतिरिक्त उत्सर्जन जारी करने के बाद रिजर्व में रहने वाली राशि।"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["राइट-ऑफ के लिए"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वर्तमान कवरेज"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अतिरिक्त उत्सर्जन जारी करने के बाद आपके खाते से कटौती की जाने वाली राशि।"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भविष्य कवरेज राशि"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E — वर्तमान उत्सर्जन राशि"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I — अतिरिक्त उत्सर्जन"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["K — बाइंडिंग दर"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["P — कवरेज प्रतिशत"])}
}