export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrazione"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account personale"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account business"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cognome:"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome:"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secondo nome: (se presente)"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono ammesse lettere latine, trattini e apostrofi."])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bond"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono ammesse lettere latine, trattini, spazi e apostrofi."])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["James"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["None"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono ammesse lettere latine, trattini, virgolette, spazi, numeri e apostrofi."])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"My Company\" Ltd"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefono"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono ammesse lettere latine (maiuscole e minuscole), numeri, simboli _ - . ! $ % ^ * ="])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ripeti password"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le password non corrispondono"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indietro"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrazione"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avanti"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dati utente"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci un indirizzo email o numero di telefono corretto. Il numero di telefono deve essere inserito nel formato internazionale, ad esempio: +499001234567."])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrazione avvenuta con successo"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ora puoi"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["accedere al tuo account personale"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il tuo account sarà verificato da un nostro operatore, ti informeremo sui risultati della sua attivazione."])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Una email con un link per confermare la registrazione è stata inviata"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al tuo indirizzo email"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dopo il primo accesso ti sarà chiesto di confermare il numero di telefono"])}
}