export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आप एक ही समय में कई भुगतान कर सकते हैं. \nऐसा करने के लिए, आपको पहले \"XLS\", \"XLSX\", \"CSV\" प्रारूप में भुगतान के विवरण के साथ एक फ़ाइल बनानी होगी।"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फ़ाइल का विश्लेषण"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संपर्क व्यक्ति"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["राशि"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टिप्पणी"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पैरामीटर"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फ़ाइल की जानकारी"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कुल भुगतान: "])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कुल राशि: "])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भुगतान फ़ाइल"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["डिडक्शन खाता"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भुगतान करें"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जारी रखें"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भुगतान फ़ाइल अपलोड करें"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फ़ाइल सामग्री की जाँच करें"])},
  "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सामूहिक भुगतान और ट्रांसफर"])}
}