export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["देने के लिए"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["राशि"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्राप्त करने के लिए"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फ़िल्टर"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपडेट"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोड"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विक्रेता"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["देता हूँ"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्राप्त करता हूँ"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सीमा"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्रियाएँ"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सौदों"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सौदा"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई सौदा नहीं"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रस्ताव"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लाभ"])}
}