export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رمز الصفقة"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أعطي"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أستلم"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اتجاه"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السعر"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحالة"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المهلة الزمنية"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإجراءات"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد صفقات نشطة"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صفقاتي"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نشط"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأصل الداخلي"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غير نشط"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المراسل"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رمز"])}
}