export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Спалювання активу"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума спалювання"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рахунок для повернення"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Спалити"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скасувати спалення"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума спалювання не може бути більшою за суму емісії"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повернення покриття"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["До повернення"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума, яка залишиться в резерві після спалювання активу"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума, яка повернеться на ваш рахунок після спалювання активу"])}
}