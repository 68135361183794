export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a file"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This file format is not supported. \nAccepted formats:"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unknown format"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File size too large, maximum size:"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select or drag files to upload here"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Files uploaded"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["files"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])}
}