export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seznam faktur"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faktury nenalezeny"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kód"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stav"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentář"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Částka"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obchod"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postavení"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Číslo obchodu"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušit fakturu"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skladujte platební kód"])}
}