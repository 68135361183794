export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тема"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Останнє повідомлення"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категорія"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дії"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запитів немає"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категорія"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пріоритет"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тема"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текст повідомлення"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Файли"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви можете завантажити один або декілька файлів. Максимальний розмір файлу - 10МБ."])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відправити"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Низький"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нормальний"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Високий"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Технічна підтримка"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активні запити"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Архів"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новий запит"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обробляється оператором"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запит створено"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проводиться робота, чекайте відповіді оператора"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запит закрито"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запит закрито"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нових повідомлень немає"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нове повідомлення від оператора"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нових повідомлень немає"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відправити в архів"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всі запити"])}
}