export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل الأصل الخارجي"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلغاء"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المجموعة"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المرادفات"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البلدان"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ربط بالأصل"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوحدات"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاختصار"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدقة"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نشط"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مقبول للتداول"])}
}