export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Währungspaare"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Währungspaar erstellen"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Währungspaar bearbeiten"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Währungspaar löschen"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktionen"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gegebene Währung"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erhaltene Währung"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gateway"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualisierungstyp"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Währungspaare gefunden"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie das Währungspaar wirklich löschen"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mit anderer Währung verknüpft"])}
}