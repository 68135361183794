export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحالة"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الموضوع"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["آخر رسالة"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفئة"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإجراءات"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد طلبات"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفئة"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأولوية"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الموضوع"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نص الرسالة"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الملفات"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكنك تحميل ملف أو عدة ملفات. الحد الأقصى لحجم الملف هو 10 ميجابايت."])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إرسال"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منخفض"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عادي"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مرتفع"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدعم الفني"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطلبات النشطة"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأرشيف"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طلب جديد"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تتم معالجته من قبل المشغل"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم إنشاء الطلب"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يتم العمل على الطلب، يرجى انتظار رد المشغل"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم إغلاق الطلب"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم إغلاق الطلب"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد رسائل جديدة"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رسالة جديدة من المشغل"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد رسائل جديدة"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إرسال إلى الأرشيف"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جميع الطلبات"])}
}