export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الكاسيت"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معرف الكاسيت"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم جمع الكاسيت"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تصنيف الكاسيت"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم الرفع"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مجموع"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الخادم"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كمية"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مجموع"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أجهزة الصراف الآلي"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مجموع"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["في الحقيقة"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كمية"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مجموع"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التعليق على الكاسيت"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد بيانات لعرضها"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جمع الجهاز"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صالح"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ج"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بواسطة"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يتم نقل البقايا بعيدا"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الخادم"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الفواتير"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مجموع"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أجهزة الصراف الآلي"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الفواتير"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مجموع"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعليق"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["موزع"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رفض/سحب"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متقبل الفاتورة"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رفض البطاقات"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بطاقات"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حاضِر"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفريغ"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أرصدة تحصيل أجهزة الصراف الآلي"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جمع الجهاز"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صالح"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كاسيت مثبت في أجهزة الصراف الآلي"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استولى"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يتم نقل البقايا بعيدا"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يتم نقل البقايا بعيدا"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كاسيت فارغ:"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التصاق:"])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات إضافية:"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حتى الآن"])}
}