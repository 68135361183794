export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Віддати"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отримати"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фільтри"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оновити"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продавець"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Віддаєте"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отримуєте"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ліміти"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дії"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["угод"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Угода"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Угод немає"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пропозиції"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вигода"])}
}