export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Електронні чеки"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створити чек"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активність"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створено"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рахунок списання"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Серія"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коментар"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дії"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активно"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не активно"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чеків немає"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активувати"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перевірити"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрити"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Електронних чеків не знайдено"])}
}