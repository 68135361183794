export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecionar arquivo"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este formato de arquivo não é suportado. \nFormatos aceitos:"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formato desconhecido"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamanho do arquivo muito grande, tamanho máximo:"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecionar ou arrastar arquivos aqui para carregar"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecionar"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carregar"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arquivos carregados"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["arquivos"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excluir"])}
}