export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todas las órdenes"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restablecer"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtros"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizar"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doy"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recibo"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasa"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operaciones"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["comisiones"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repetir"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sin solicitudes"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad mínima para enviar"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad máxima para enviar"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad mínima para recibir"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad máxima para recibir"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de intentos para completar la transacción"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activo"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moneda para enviar"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moneda para recibir"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta para enviar"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta para recibir"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de subdistribuidores"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta no seleccionada"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Período desde"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Período hasta"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoy"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ayer"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semana"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Año"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de fecha no seleccionado"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vencer"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finalizar"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qué parte es fija y qué parte es variable"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La cantidad dada es fija"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La cantidad a recibir es fija"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de orden"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El usuario quiere intercambiar rápidamente por las mejores contraofertas"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intercambio a una tasa estrictamente especificada"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procesamiento no iniciado"])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procesamiento iniciado"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parcialmente completado"])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completamente completado"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completamente cancelado"])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parcialmente completado y cancelado"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activo saliente"])},
  "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activo entrante"])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de fecha"])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El importe mínimo es superior al máximo"])}
}