export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сыртқы активтер"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Атауы"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активке байланған"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Өлшем бірліктері"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Топ"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Құру күні"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автор"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Әрекеттер"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Период басталған күннен"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Период аяқталған күнге дейін"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фильтрлер"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қалпына келтіру"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жаңарту"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активтер жоқ"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бүгін"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кеше"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Апта"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ай"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жыл"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активке байланған"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Топ"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автор"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Белсенділік"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Елдер"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Белсенді"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Белсенді емес"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сыртқы активті өңдеу"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сыртқы активті жою"])}
}