export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جمع أجهزة الصراف الآلي"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة مجموعة"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإجراءات"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المرشحات"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعادة ضبط"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحديث"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا تعرض المجموعات الأرشيفية"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا تظهر المجموعات الملغاة"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم النقطة"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حالة"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد مجموعات"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم تقديم طلب التحصيل"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحرير المجموعة"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تغيير حالة التجميع"])}
}