export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je transmets"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je reçois"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raison"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler la transaction"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer la participation à la transaction"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refuser la transaction"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prolonger le temps d'attente"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulation de la transaction"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut de l'utilisateur dans la transaction non défini. Quelque chose a mal tourné"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demander un arbitrage"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepter l'arbitrage"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifier du transfert effectué"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer la réception du transfert"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évaluer la transaction"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer l'évaluation"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction n°"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correspondant"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Délai"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aujourd'hui"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["demain"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler l'arbitrage"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler l'arbitrage"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez demandé un arbitrage"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un arbitrage a été demandé contre vous"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'arbitrage est en cours (Vous êtes le demandeur)"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'arbitrage est en cours (Vous êtes le défendeur)"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une fois le délai écoulé, le consentement à l'arbitrage se fera automatiquement et vous ne pourrez plus ajouter vos réponses aux questions d'arbitrage."])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbitrage à la demande"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il y a un arbitrage"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il y a un arbitrage"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prendre une décision sur l'arbitrage: paiement interne"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conduire"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])}
}