export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Údaje o krypto aktivech"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresa vydání:"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jméno:"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticker:"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oprávnění"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Účel/Poslání/Účel"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finanční charakteristiky"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přesnost:"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakty"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emise:"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osoba odpovědná:"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vydavatel:"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jednotková cena:"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zabezpečení:"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nákupy v bankomatech:"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prodej v bankomatech:"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transakce s aktivy na burze:"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transakce aktiv prostřednictvím P2P:"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hostingová adresa"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresa bílé knihy"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nabízející"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Další kontakty"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jít"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vydavatel"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximální emise:"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ostatní"])}
}