export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset da dare"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset da ricevere"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["asset"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["do"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["interno"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ricevo"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset interni"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun asset trovato"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset esterni"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["esterno"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selezionato:"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interno"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esterno"])}
}