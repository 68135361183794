export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["模板"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["充值"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["转账"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提现"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["模板名称"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作员"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["字段"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有模板"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收件人"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您确定要删除模板编号"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重命名模板"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重命名"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用"])}
}