export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повторить операцию"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["История операций"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список счетов"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успех"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неизвестная ошибка"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаблоны"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мои активы"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Информация о новом активе"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дополнительная эмиссия"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список виртуальных активов"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ссылка на блокчейн"])}
}