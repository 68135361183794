export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereço"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conta"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informações adicionais"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ações"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ativo"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Depósito"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retirada"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar novo endereço"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar nova conta"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excluir selecionados"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meus ativos"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de contas"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome da conta"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você realmente deseja excluir as contas?"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você realmente deseja excluir a conta?"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum ativo encontrado"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma conta encontrada"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renomear endereço"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você realmente deseja excluir os endereços?"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você realmente deseja excluir o endereço?"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiat"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operações"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ações"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recarregar"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retirar"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intercâmbio"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esconder"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mudar"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excluir"])}
}