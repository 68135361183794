export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dar"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receber"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentário"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Razão"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar negociação"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar participação na negociação"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejeitar negociação"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estender tempo de espera"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar negociação"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status do usuário na negociação não definido. Algo deu errado"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicitar Arbitragem"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concordar com Arbitragem"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notificar sobre a transferência"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar recebimento da transferência"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaliar negociação"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar avaliação"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negociação Nº"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correspondente"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timeout"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hoje"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["amanhã"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar Arbitragem"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar arbitragem"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você solicitou arbitragem"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A arbitragem foi solicitada contra você"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A arbitragem está em andamento (você é o demandante)"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A arbitragem está em andamento (você é o réu)"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Depois que o tempo expirar, o consentimento para a arbitragem ocorrerá automaticamente e você não poderá mais adicionar suas respostas às perguntas da arbitragem"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbitragem necessária"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Há uma arbitragem"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Há uma arbitragem"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tome uma decisão sobre arbitragem: pagamento interno"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conduta"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])}
}