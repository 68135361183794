export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dispositivos ATM"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar dispositivo"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de dispositivo"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentário"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuração"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar cassete"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de cassete"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentário"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuração"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remover dispositivo"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remover cassete"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excluir dispositivo?"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excluir"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excluir a fita?"])}
}