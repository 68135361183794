export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last message"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No requests"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priority"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message text"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Files"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can upload one or more files. \nThe maximum file size is 10Mb."])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Low"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normal"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["High"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technical support"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active Requests"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archive"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New request"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handled by the operator"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request created"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work in progress, waiting for a response from the operator"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request closed"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request closed"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No new messages"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New message from operator"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No new messages"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send to archive"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All requests"])}
}