export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generazione codice/link di pagamento"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero operazione negozio"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commento"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametro aggiuntivo"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL di successo"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL di errore"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL di notifica"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL dove il cliente verrà reindirizzato dopo un pagamento riuscito"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL dove il cliente verrà reindirizzato in caso di errore"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL dove verrà inviata la notifica del risultato del pagamento"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fissa importo"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copia"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifica"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link di pagamento"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modulo"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documentazione"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durata del conto di pagamento in minuti, predefinito 60"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durata del link"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modulo"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Widget"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se non si fissa l'importo, il pagatore potrà inserirlo autonomamente (adatto, ad esempio, per pagamenti di beneficenza)"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nascondere"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modulo di pagamento"])}
}