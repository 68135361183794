export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice transazione"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricevo"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direzione"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasso"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timeout"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azioni"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna transazione attiva"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mie transazioni"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attivo"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset interno"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non attivo"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corrispondente"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periodo p"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il periodo di"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtri"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiornamento"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["= uguale"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≠ non è uguale"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["> altro"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≥ più o uguale"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["< Meno"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≤ meno o uguale"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ho capito"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Somma"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli una risorsa"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il mio ruolo"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutto"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creatore"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taker"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risorsa interna"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutte le opzioni"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ho capito"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creatore ID"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutto"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di annuncio"])}
}