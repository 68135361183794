export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conta de transferência:"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantia:"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentário:"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proteção de transação"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código:"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerar"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Período de proteção:"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dias"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome do modelo:"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvar"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvar como modelo"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferir"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modelos"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferir para outro usuário"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O código de proteção é um código que o destinatário deve inserir para completar a transação. A transferência protegida será imediatamente visível para o destinatário na seção \"Histórico de operações\", mas ele só poderá usar o dinheiro após inserir o código de proteção no cartão de transação. Se o prazo do pagamento expirar, os fundos serão devolvidos ao remetente."])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereço de transferência"])}
}