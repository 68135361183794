export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crie um par de moedas"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Par de moeda"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurando uma atualização"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dada moeda"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moeda recebida"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicione lucro ao curso,%"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de atualização do curso"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duração do curso, segundos"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intervalo de atualização do curso, segundos"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bem"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para que data o curso é válido?"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alias ​​da moeda fornecida"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alias ​​da moeda recebida"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portal"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["É necessário escolher uma moeda e, além disso, apenas uma: dada ou recebida"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor enviado * Taxa = valor recebido"])}
}