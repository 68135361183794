export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivität"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung des Aktiv"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Anzeigen"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Anzeigen"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Anzeigen"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Anzeige"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivität"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inaktiv"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richtung"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Externes Aktiv"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internes Aktiv"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tausche"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erhalte"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limits"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veröffentlichungsdatum"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontostand"])}
}