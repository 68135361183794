export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicitar transacción"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oferta"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendedor"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiempo de espera"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiempo de espera para acciones de las partes"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de cambio"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recibes"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicitar transacción"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear cuenta"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta no seleccionada"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad fuera del rango: "])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["min"])}
}