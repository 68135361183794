export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кіру"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Логин:"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Құпиясөз:"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Құпиясөзді ұмыттыңыз ба?"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Алғаш рет мұндасыз ба?"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аккаунт жасаңыз"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Құпиясөзді қалпына келтіру"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Телефон немесе e-mail:"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қалпына келтіру"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Құпиясөзді қалпына келтіру"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тегі:"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аты:"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Әкесінің аты:"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Туған күні:"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кіру"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Артқа"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жалғастыру"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Іздеу"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пайдаланушы табылды"])}
}