export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["255 таңбаға дейін жол"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["бүтін сан"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["20 цифр"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0 немесе 1"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["бөлшек сан, дәлдік екі цифр, нүкте бөлгіш"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["бүтін сан"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["255 таңбаға дейін жол, міндетті емес"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["бүтін сан, міндетті емес"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["32 таңбалы жол"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["дүкен төлем коды; әр төлем үшін бірегей болуы керек; жолды жіберу кезінде"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["бірегей нөмір автоматты түрде жасалады"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["тіркеуден кейін берілетін дүкен нөмірі"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["дүкен шотының нөмірі; бұл шот төлемнің қай валютада жасалуы керектігін де анықтайды (көп жағдайда пайдаланушы кез келген валютада төлем жасай алады, ал қаражат автоматты түрде алушының валютасына айырбасталады)"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["клиентке көрсетілген соманы өзгертуге тыйым салу (1 - тыйым салу, 0 - рұқсат ету)"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["алушының шот валютасындағы сомасы"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["төлем қабылданатын форманың нөмірі"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["төлем мақсаты ретінде төлеушіге көрсетілетін түсініктеме"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["төлем нәтижесі скриптіне және қайтару беттеріне берілетін қосымша міндетті емес параметр"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сәтті төлемнен кейін клиент қайтарылатын URL; көрсетілмеген жағдайда дүкен параметрлерінен алынады"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қате болған жағдайда клиент қайтарылатын URL; көрсетілмеген жағдайда дүкен параметрлерінен алынады"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Төлем нәтижелері туралы хабарлама жіберілетін URL; көрсетілмеген жағдайда дүкен параметрлерінен алынады"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["төлем шотының жарамдылық мерзімі (минутпен), әдепкі 60"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["дүкеннің құпия кілтін пайдаланып хабарламаға қол қою"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0 немесе 1"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 - сәтті болса, 0 - басқа жағдайда"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["бүтін сан"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["қате коды (қате болмаса 0)"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["жол"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["қате мәтіні (немесе төлем күйінің түсіндірмесі)"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0 немесе 1"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["соңғы төлем күйінің әлі белгісіз екендігінің белгісі"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["дүкен төлем коды"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["транзакция нөмірі"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мерчант протоколы"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сайтта төлемдерді қабылдау тек жүйеде тіркелген интернет-дүкендер үшін ғана мүмкін. Дүкенді тіркеу үшін бетке өтіңіз"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дүкенді тіркеу"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пайдаланушыны төлем жасау үшін мерчантқа бағыттау"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Төлем жасау үшін пайдаланушыны URL мекенжайына бағыттау қажет: https://merchant.[сіздің доменіңіз] және келесі параметрлерді GET немесе POST әдісімен жіберу:"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Атауы"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сипаттама"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Деректер түрі"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қолтаңба"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["берілетін параметрлерді біріктіру және құпия кілтпен hmac әдісімен хэштеу арқылы есептеледі. Біріктірілетін параметрлердің саны мен реті параметрге байланысты"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["identified"])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["тұрақты сома"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["реті келесідей:"])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ext_transact + num_shop + keyt_shop + identified + sum + payform + comment + free_param + url_success + url_decline + url_callback"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Клиент үшін сілтеме, онда"])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["төлем, реті келесідей (сома қолтаңбаға кірмейді):"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ext_transact + num_shop + keyt_shop + identified + payform + comment + free_param + url_success + url_decline + url_callback"])},
  "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Төлем сілтемесін бетте жасауға болады"])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'Менің дүкендерім'"])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Форма мысалы:"])},
  "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операция аяқталғаннан кейін дүкен сайтына қайту"])},
  "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Егер төлеуші төлемнен бас тартса немесе операция қатемен аяқталса, төлем жүйесі дүкен параметрлерінде көрсетілген немесе формамен бірге берілген тиісті бетке өтуді ұсынады"])},
  "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["url_decline"])},
  "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Егер операция сәтті аяқталса, бетке өту"])},
  "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["url_success"])},
  "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бұл скриптер төлем нәтижелерін де алады (дүкен параметрлерінде көрсетілген әдіспен. Әдепкі - GET):"])},
  "62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Атауы"])},
  "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сипаттама"])},
  "64": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Деректер түрі"])},
  "65": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қолтаңба"])},
  "66": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["берілетін параметрлерді біріктіру және құпия кілтпен hmac әдісімен хэштеу арқылы есептеледі."])},
  "67": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Біріктірілетін параметрлердің реті:"])},
  "68": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ext_transact + amount + success + result + result_text + free_param"])},
  "69": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дүкенге төлем күйі туралы хабарлама (callback)"])},
  "70": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Төлем жасалғаннан кейін төлем нәтижелері дүкенді тіркеу кезінде көрсетілген скриптке жіберіледі"])},
  "71": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["result_url"])},
  "72": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бұл скрипт сәтті төлемдер туралы да, қате жағдайында да деректерді алады."])},
  "73": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бұл скриптер төлем нәтижелерін де алады (дүкен параметрлерінде көрсетілген әдіспен. Әдепкі - GET)."])},
  "74": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кейбір жағдайларда нәтижелер туралы хабарлама келмейді (мысалы, клиент төлеуге өтіп, браузер бетін жапқан жағдайда)."])},
  "75": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Атауы"])},
  "76": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сипаттама"])},
  "77": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Деректер түрі"])},
  "78": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қолтаңба"])},
  "79": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["берілетін параметрлерді біріктіру және құпия кілтпен hmac әдісімен хэштеу арқылы есептеледі."])},
  "80": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Біріктірілетін параметрлердің реті:"])},
  "81": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["transact + ext_transact + amount + success + result + result_text + free_param"])},
  "82": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қолтаңбаны есептеу"])},
  "83": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қолтаңба көрсетілген ретпен қажетті параметрлерді біріктіру және hmac әдісімен хэштеу арқылы жасалады."])},
  "84": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Біріктірілетін параметрлердің реті мен саны жоғарыда сипатталған."])},
  "85": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Келесі тізімдерде параметр ретінде берілетін біріктірілген қол қойылатын жол бар әртүрлі тілдерде қолтаңбаны есептейтін функциялардың мысалдары көрсетілген"])},
  "86": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1-тізім. PHP-да қолтаңбаны есептеу."])},
  "87": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2-тізім. Python-да қолтаңбаны есептеу."])},
  "88": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3-тізім. Perl-де қолтаңбаны есептеу."])},
  "89": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Төлем үшін"])},
  "90": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["соманы өзгерте алады"])},
  "91": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["тауар төлемі"])},
  "92": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Төлеу"])},
  "93": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кілтпен қолтаңба жасау"])},
  "94": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["signed_str"])},
  "95": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["және құпия сөз параметрінде"])},
  "96": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["key"])}
}