export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeile bis zu 255 Zeichen"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ganze Zahl"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["20 Ziffern"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0 oder 1"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bruchzahl, Genauigkeit zwei Ziffern, Punkttrennzeichen"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ganze Zahl"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeichenfolge mit bis zu 255 Zeichen, optional"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ganzzahl, optional"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeichenfolge 32 Zeichen"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungscode speichern; sollte für jede Zahlung einzigartig sein bei der Übertragung einer Zeichenfolge"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es wird automatisch eine eindeutige Nummer generiert"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filialnummer wird nach der Registrierung vergeben"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontonummer speichern; Dieses Konto bestimmt auch, in welcher Währung die Zahlung erfolgen muss (in den meisten Fällen kann der Benutzer eine Zahlung in jeder beliebigen Währung vornehmen und der Betrag wird automatisch in die Währung des Empfängers umgerechnet)."])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dem Kunden verbieten, den angegebenen Betrag zu ändern (1 – verbieten, 0 – zulassen)"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betrag des Begünstigten in der Kontowährung des Begünstigten"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nummer des Formulars, über das die Zahlung akzeptiert wird"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar, der dem Zahler als Verwendungszweck der Zahlung angezeigt wird"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zusätzlicher optionaler Parameter, der an das Zahlungsergebnisskript sowie an die Rückgabeseiten übergeben wird"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL, zu der der Kunde nach erfolgreicher Zahlung zurückkehrt; Wenn nicht angegeben, wird es aus den Store-Einstellungen übernommen"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL, zu der der Client nach einem Fehler zurückkehrt; Wenn nicht angegeben, wird es aus den Store-Einstellungen übernommen"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL, an die die Benachrichtigung über die Zahlungsergebnisse gesendet wird; Wenn nicht angegeben, wird es aus den Store-Einstellungen übernommen"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnungslaufzeit (in Minuten), Standard 60"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signieren einer Nachricht mit dem geheimen Schlüssel des Geschäfts"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0 oder 1"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 – bei Erfolg, 0 – andernfalls"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ganze Zahl"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehlercode (0, wenn kein Fehler vorliegt)"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeichenfolge"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehlertext (oder Erläuterung zum Zahlungsstatus)"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0 oder 1"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ein Zeichen dafür, dass der endgültige Zahlungsstatus noch nicht bekannt ist"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shop-Zahlungscode"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaktionsnummer"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Händlerprotokoll"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Annahme von Zahlungen auf der Website ist nur für im System registrierte Online-Shops möglich. Um einen Shop zu registrieren, gehen Sie zur Seite"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shop-Registrierung"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umleitung des Benutzers zum Händler für die Zahlung"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für die Zahlung muss der Benutzer auf die URL umgeleitet werden: https://merchant.[Ihre Domain] und die folgenden Parameter per GET oder POST senden:"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datentyp"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signatur"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wird durch Hinzufügen der gesendeten Parameter und Hashing durch die hmac-Methode mit dem geheimen Schlüssel berechnet. Die Anzahl und Reihenfolge der hinzugefügten Parameter hängt von den Parametern ab"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["identified"])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fester Betrag"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Reihenfolge ist wie folgt:"])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ext_transact + num_shop + keyt_shop + identified + sum + payform + comment + free_param + url_success + url_decline + url_callback"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["für den Link, wo der Kunde"])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlung, die Reihenfolge ist wie folgt (Betrag ist nicht in der Signatur enthalten):"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ext_transact + num_shop + keyt_shop + identified + payform + comment + free_param + url_success + url_decline + url_callback"])},
  "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Zahlungslink kann auf der Seite generiert werden"])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'Mein Shop'"])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beispiel für ein Formular:"])},
  "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rückkehr zur Shop-Website nach Abschluss des Vorgangs"])},
  "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn der Zahler die Zahlung ablehnt oder der Vorgang mit einem Fehler endet, schlägt das Zahlungssystem vor, zur entsprechenden Seite zu gehen, die in den Shop-Einstellungen festgelegt oder mit dem Formular gesendet wurde"])},
  "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["url_decline"])},
  "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn der Vorgang erfolgreich abgeschlossen wurde, gehen Sie zur Seite"])},
  "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["url_success"])},
  "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Skripte erhalten auch die Zahlungsergebnisse (durch die in den Shop-Einstellungen festgelegte Methode. Standard - GET):"])},
  "62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung"])},
  "64": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datentyp"])},
  "65": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signatur"])},
  "66": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wird durch Hinzufügen der gesendeten Parameter und Hashing durch die hmac-Methode mit dem geheimen Schlüssel berechnet."])},
  "67": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reihenfolge der hinzugefügten Parameter:"])},
  "68": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ext_transact + amount + success + result + result_text + free_param"])},
  "69": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shop-Zahlungsstatus-Benachrichtigung (Callback)"])},
  "70": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nach Ausführung der Zahlung werden die Zahlungsergebnisse an das während der Shop-Registrierung angegebene Skript gesendet"])},
  "71": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["result_url"])},
  "72": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieses Skript empfängt Daten sowohl für erfolgreiche Zahlungen als auch für Fehlerfälle."])},
  "73": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Skripte erhalten auch die Zahlungsergebnisse (durch die in den Shop-Einstellungen festgelegte Methode. Standard - GET)."])},
  "74": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In einigen Fällen kommen die Informationen nicht mit den Ergebnissen an (zum Beispiel, wenn der Kunde zum Bezahlen ging und die Browser-Seite schloss)."])},
  "75": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "76": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung"])},
  "77": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datentyp"])},
  "78": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signatur"])},
  "79": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wird durch Hinzufügen der gesendeten Parameter und Hashing durch die hmac-Methode mit dem geheimen Schlüssel berechnet."])},
  "80": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reihenfolge der hinzugefügten Parameter:"])},
  "81": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["transact + ext_transact + amount + success + result + result_text + free_param"])},
  "82": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signaturberechnung"])},
  "83": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Signatur wird durch Hinzufügen der erforderlichen Parameter in der angegebenen Reihenfolge und Hashing durch die hmac-Methode erstellt."])},
  "84": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Reihenfolge und Anzahl der hinzugefügten Parameter ist oben beschrieben."])},
  "85": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die folgenden Listen zeigen Beispiele für Funktionen zur Berechnung der Signatur in verschiedenen Sprachen, wobei die für die Signatur hinzugefügte Zeichenfolge als Parameter übergeben wird"])},
  "86": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste 1. Signaturberechnung in PHP."])},
  "87": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste 2. Signaturberechnung in Python."])},
  "88": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste 3. Signaturberechnung in Perl."])},
  "89": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zur Zahlung"])},
  "90": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betrag kann geändert werden"])},
  "91": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produktzahlung"])},
  "92": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezahlen"])},
  "93": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signatur mit Schlüssel generieren"])},
  "94": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["signed_str"])},
  "95": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["und geheime Phrase im Parameter"])},
  "96": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["key"])}
}