export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documentación"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protocolo del Comerciante"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redirección al comerciante"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retorno a la tienda"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notificación de la tienda"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cálculo de firma"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Widget integrado"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instalación"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Llamada"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respuesta de la tienda"])}
}