export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["банкомат құрылғылары"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Құрылғыны қосыңыз"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Құрылғы түрі"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аты"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Түсініктеме"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Конфигурация"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кассета қосыңыз"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кассета түрі"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аты"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Түсініктеме"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Конфигурация"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Құрылғыны алып тастаңыз"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кассетаны алыңыз"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Құрылғыны жою керек пе?"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жою"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кассетаны жою керек пе?"])}
}