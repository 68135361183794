export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset burning"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Burning amount"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account for return"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Burn"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Burn amount"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Burning amount cannot be greater than emission amount"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coverage return"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To return"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount that will remain in reserve after asset burning"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount that will return to your account after asset burning"])}
}