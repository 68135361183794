export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de recebimento"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de processamento"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de pagamentos"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["incluindo bem-sucedidos"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["incluindo com erros"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagamentos em massa"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transação"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultado"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provedor de serviços"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantia"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxa"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentário"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1º parâmetro"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum pagamento encontrado"])}
}