export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einmalige Schlüssel erhöhen die Sicherheit des Systems erheblich."])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beim Betreten des Programms werden Sie nicht nur aufgefordert, den Login und das Passwort einzugeben, sondern auch den nächsten einmaligen Schlüssel. Die Aktivierung erfolgt nacheinander. Der letzte Schlüssel ist gültig, bis Sie eine neue Schlüsselserie erstellen."])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einmalige Schlüssel verwenden"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schlüsselliste"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generieren"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nr."])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schlüssel"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einmalige Schlüssel"])}
}