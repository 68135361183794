export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich gebe"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich erhalte"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapitalpaar"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konten"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Art des Austauschs"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marktüberweisung - es wird der günstigste Kurs zum Zeitpunkt der Auftragserteilung verwendet. Achtung, der zu erhaltende Betrag kann sich je nach den Aufträgen zum Zeitpunkt des Umtauschs ändern und vom berechneten Betrag abweichen."])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überweisung zu einem festen Preis erfolgt gemäß dem von Ihnen angegebenen Betrag und Kurs. Ein Auftrag wird erteilt. Wenn der angegebene Kurs stark vom Marktpreis abweicht, kann es lange dauern, bis der Auftrag abgeschlossen ist."])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurs (Prognose):"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurs"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurs nicht definiert,"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["es wird empfohlen, den Kurs selbst festzulegen"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summe"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alles verwenden"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommission"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommission"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insgesamt:"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nach Markt"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(schneller Austausch zum besten Gegenangebot)"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fester Kurs"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Auftrag zu einem festgelegten Kurs erteilen)"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine passenden Konten"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurs"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Änderung in 24 Stunden"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schwankungen in 24 Stunden"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handelsvolumen in 24 Stunden"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betrag darf nicht null sein!"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Austauschen"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um die Zusammenfassungsdaten zu sehen, wählen Sie das Handelspaar aus"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es ist nicht erlaubt, gleiche Vermögenswerte auszutauschen."])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zusätzlich"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Bewerbung wird erst in die Auswahl aufgenommen"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zu"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einschränkungen"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Feld 'Kurs' muss einen Wert ungleich 0 haben"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unzureichendes Guthaben auf dem Konto"])}
}