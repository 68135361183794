export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status auswählen"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Inkassoantrag wurde eingereicht"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inkassoantrag storniert"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sammlung zur Ausführung freigegeben"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Abholung erfolgt durch die Kasse (Geld wird in Kassetten geladen)"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kassetten an Sammler übergeben (Geld unterwegs)"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bargeldabholung am Geldautomaten installiert"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufgrund eines Fehlers mit einer anderen Sammlung überlastet"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Abholung wurde durch eine andere Abholung an einem Geldautomaten ersetzt (bleibt unterwegs)"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inkassosalden werden verbucht (Inkasso wird an das Archiv gesendet)"])}
}