export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modelli"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricarica"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trasferimento"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prelievo"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome del modello"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operatore"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campi"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operazioni"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun modello"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destinatario"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vuoi davvero eliminare il modello №"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rinomina modello"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rinomina"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usa"])}
}