export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One-time keys significantly increase system security."])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When you enter the program, you will be asked not only to enter your login and password, but also another one-time key. Activation is performed one by one. The last key is valid until you create a new series of keys."])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use one-time keys"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of keys"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Key"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One-time keys"])}
}