export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz adres"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miejscowość"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anuluj"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kraj"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Region"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okreg"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejon"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miasto"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teren miejski"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miejscowość"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ulica"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodatkowy element adresu"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precyzowanie"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dom"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kod pocztowy"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres"])}
}