export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Одноразові ключі значно підвищують безпеку системи."])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Під час входу в програму вас попросять не лише ввести логін і пароль, а й черговий одноразовий ключ. Активація відбувається по черзі. Останній ключ діє до моменту, поки ви не створите нову серію ключів."])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Використовувати одноразові ключі"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список ключів"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Згенерувати"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Експорт"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ключ"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Одноразові ключі"])}
}