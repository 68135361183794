export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Банкомат жинау"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жинақ қосу"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Әрекеттер"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сүзгілер"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қалпына келтіру"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жаңарту"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мұрағат жинақтарын көрсетпеңіз"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бас тартылған жинақтарды көрсетпеңіз"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Күй"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жинақтар жоқ"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коллекцияға өтінім жіберілді"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жинақты өңдеу"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жинақ күйін өзгерту"])}
}