export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elektronischen Scheck erstellen"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belastungskonto"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nennwert jedes Schecks"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der Schecks"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serie:"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nummer:"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivierungscode:"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schecks erstellt"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datei mit Schecks herunterladen"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern Sie diese Informationen - Aktivierungscodes werden nach dem Schließen dieser Seite ausgeblendet"])}
}