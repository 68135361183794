export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose an address"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locality"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Country"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Region"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["District"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Area"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intercity"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locality"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Street"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clarification"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["House"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postcode"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])}
}