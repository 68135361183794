export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Барлық тапсырыстар"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қалпына келтіру"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сүзгілер"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жаңарту"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Күні"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Беремін"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аламын"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Күйі"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бағам"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операциялар"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["комиссиялар"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Болдырмау"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қайталау"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Өтінімдер жоқ"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жіберудің мин. сомасы"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жіберудің макс. сомасы"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Алудың мин. сомасы"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Алудың макс. сомасы"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакцияны аяқтау әрекеттерінің саны"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Белсенді"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жіберу валютасы"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Алу валютасы"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жіберу шоты"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Алу шоты"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Субдилерлер тізімі"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шот таңдалмаған"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кезең басталуы"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кезең аяқталуы"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бүгін"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кеше"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Апта"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ай"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жыл"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Күн түрі таңдалмаған"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жасау"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мерзімі біту"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аяқтау"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бастау"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қай бөлік тұрақты және қай бөлік айнымалы"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Берілетін сома тұрақты"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Алынатын сома тұрақты"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тапсырыс түрі"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пайдаланушы ең жақсы қарсы ұсыныстарға тез айырбастағысы келеді"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қатаң көрсетілген бағам бойынша айырбастау"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Күйі"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Өңдеу басталған жоқ"])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Өңдеу басталды"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жартылай аяқталды"])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Толық аяқталды"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Толығымен болдырылмады"])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жартылай аяқталды және болдырылмады"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шығыс актив"])},
  "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кіріс актив"])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Күн түрі"])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Минималды сома максималды сомадан үлкен"])}
}