export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odevzdávané"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přijímané"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aktivum"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dávám"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["interní"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dostávám"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interní aktiva"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiva nebyla nalezena"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Externí aktiva"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["externí"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vybráno:"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interní"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Externí"])}
}