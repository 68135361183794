export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O serviço de pagamentos em massa permite transferir rapidamente fundos para outros usuários e realizar operações de pagamento para provedores de serviços."])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na seção"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carregar arquivo de pagamentos"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["você pode carregar um arquivo nos formatos Excel (XLS, XLSX, CSV) ou XML."])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de tarefas"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["permite visualizar os status dos pedidos de pagamento em massa criados anteriormente."])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você pode criar um arquivo no editor de planilhas Excel."])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baixar exemplo de arquivo"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Observações importantes:"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formato de arquivo - CSV, XLS ou XLSX."])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O arquivo deve estar sem cabeçalhos."])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para que números longos não sejam convertidos para formato exponencial (por exemplo, \"5,46546E+15\") e zeros à esquerda não sejam removidos, você pode colocar um apóstrofo (') antes do número ou definir o formato da célula como texto antes de inserir os dados."])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formato das colunas para pagamentos a provedores de serviços:"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome do serviço;"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conta de recebimento do serviço;"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantia do pagamento;"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentário;"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colunas obrigatórias: 1, 2 e 3."])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exemplo de arquivo"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formato das colunas para transferências a usuários:"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número da conta de recebimento;"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantia do pagamento;"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentário;"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". \nO valor do parâmetro exigido pelo operador, por exemplo, o número de telefone ou o endereço do cripto-atival, é prescrito. \nMuitos operadores estão limitados a um parâmetro de pagamento, mas se o número de parâmetros for superior a um, você precisará indicá -los todos nas colunas vizinhas."])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exemplo de arquivo"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se a quantia do pagamento for superior ao saldo da conta, todos os pagamentos serão rejeitados."])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O arquivo deve conter as seguintes colunas: Nome do serviço, Conta de recebimento do serviço, Quantia do pagamento, Comentário. Todas as colunas são obrigatórias."])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se o nome do serviço ou a conta de recebimento do serviço não estiverem corretos, o pagamento será rejeitado."])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formato das colunas para transferências a usuários:"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome do serviço;"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conta de recebimento do serviço;"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantia do pagamento;"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentário;"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colunas obrigatórias: 1 e 2."])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exemplo de arquivo"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se a quantia do pagamento for superior ao saldo da conta, todos os pagamentos serão rejeitados."])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O arquivo deve conter as seguintes colunas: Nome do serviço, Conta de recebimento do serviço, Quantia do pagamento, Comentário. Todas as colunas são obrigatórias."])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se o nome do serviço ou a conta de recebimento do serviço não estiverem corretos, o pagamento será rejeitado."])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montante"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O montante deve ser especificado na moeda do produto de crédito."])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentário ao crédito"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não é obrigatório ser preenchido, pode ser deixado em branco; o comprimento não pode exceder 255 caracteres."])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificação do mutuário"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note-se que o mutuário tem de ter um passaporte digital Mfo-BorrowPerson."])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código do produto de empréstimo"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pode visualizá-lo na lista de produtos de empréstimo no desembolso manual do empréstimo"])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Período"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simplesmente o número de períodos (os períodos utilizados são definidos no produto de empréstimo, por exemplo, podem ser dias, semanas, meses, etc.)."])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código do operador (número do formulário)"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O preenchimento deste campo e dos seguintes é facultativo; se não forem preenchidos, o mutuário poderá efetuar mais tarde o levantamento do empréstimo por sua conta pessoal."])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar em caso de erro"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar (1) o empréstimo emitido se a transação de levantamento for concluída com um erro, ou deixá-lo para posterior levantamento pelo próprio (0)."])},
  "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parâmetro de pagamento 1"])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O valor do parâmetro exigido pelo operador, por exemplo, um número de telefone. Muitos operadores estão limitados a um parâmetro de pagamento, mas se o número de parâmetros for superior a um, é necessário especificá-los todos nas colunas adjacentes à primeira."])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parâmetro de pagamento 2."])},
  "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parâmetro de pagamento n.º"])},
  "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O arquivo deve indicar as contas ou endereços dos ativos criptográficos dos destinatários em uma moeda, uma vez que a baixa dos fundos é realizada a partir de uma conta e não de vários."])}
}