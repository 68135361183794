export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्राप्ति तिथि"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रसंस्करण तिथि"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्थिति"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भुगतानों की संख्या"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सफल सहित"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["त्रुटियों सहित"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सामूहिक भुगतान"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लेन-देन"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["परिणाम"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सेवा प्रदाता"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["राशि"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शुल्क"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टिप्पणी"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पहला पैरामीटर"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भुगतान नहीं मिले"])}
}