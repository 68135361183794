export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код сделки"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отдаю"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получаю"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Направление"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курс"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Таймаут"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Действия"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет активных сделок"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мои сделки"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активно"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Внутренний актив"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неактивно"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Корресподент"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Период с"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Период по"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фильтры"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сбросить"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обновить"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отдаю"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["= равно"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≠ не равно"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["> больше"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≥ больше или равно"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["< меньше"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≤ меньше или равно"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получаю"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Актив"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Моя роль"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мейкер"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тейкер"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Внутренний актив"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все варианты"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получаю"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отдаю"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID мейкера"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Внешний актив привязан"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер объявления"])}
}