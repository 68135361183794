export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datos generales"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datos de pago"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firma de control"])},
  "List": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mis tiendas"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontraron tiendas"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear código de widget"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear enlace de pago"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar tienda"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Realmente quieres eliminar la tienda"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrar tienda"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
    "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modo de pagos"])},
    "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actividad"])},
    "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceptación"])},
    "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
    "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acciones"])}
  },
  "New": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrar tienda"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Una tienda electrónica registrada en el sistema puede recibir pagos de clientes a través del comerciante de nuestro sistema."])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se registran como tiendas electrónicas, puntos de intercambio y cualquier otro recurso en línea que desee recibir pagos."])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre o marca comercial"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección URL de la tienda"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Breve descripción de la tienda"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de servicio"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tienda activa"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clave secreta"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permitir pagos automáticos"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar resultados al script"])},
    "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección del script"])},
    "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retorno después de pago exitoso"])},
    "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Después de un pago fallido"])},
    "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar"])},
    "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generar"])},
    "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al menos 30 caracteres."])},
    "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se usa para verificar la integridad y autenticidad de los datos transmitidos por el sistema de pago (al enviar al usuario del comerciante) y por la tienda (al recibir los resultados del pago)"])},
    "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siguiente"])},
    "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atrás"])},
    "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A la lista de tiendas"])},
    "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL incorrecta"])}
  },
  "Invoices": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de facturas"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontraron facturas"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentario"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tienda"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de tienda"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar la factura"])},
    "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de pago de almacenamiento"])}
  }
}