export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أجهزة الصراف الآلي"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انقر لتوسيع الجدول إلى ملء الشاشة"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعادة تعيين"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحديث"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النشاط"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تجار من الباطن"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نشط"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غير نشط"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء جهاز صراف آلي"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحرير جهاز الصراف الآلي"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلغاء"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المعلومات الأساسية"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأساسي"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ساعات العمل"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الدخول"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العنوان"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وصف مكان التثبيت"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مقياسي"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غير مقياسي"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ساعات العمل"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاثنين"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الثلاثاء"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأربعاء"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الخميس"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجمعة"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السبت"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأحد"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استراحة الغداء"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تكرار كلمة المرور"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استخدام الحسابات الموجودة"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء حسابات جديدة"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المعلومات الأساسية"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحساب"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ساعات العمل (معلومات مرجعية)"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النشاط"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإجراءات"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم يتم العثور على آلات التشفير"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الصراف الآلي"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خط العرض"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خط الطول"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خط المساعدة"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معرف الوكيل الفرعي"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحسابات"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يفحص"])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عملة"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حساب العبور"])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء كريبتومات"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحرير كريبتومات"])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور"])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نعم"])},
  "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منع"])},
  "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يسمح"])},
  "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قائمة جامعي"])},
  "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة جامع"])},
  "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غير قادر على الذهاب إلى أرصدة التحصيل - لا توجد بيانات"])},
  "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قائمة جامعي"])},
  "62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة جامع"])},
  "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مسموح"])},
  "65": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قبول"])},
  "67": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان"])},
  "68": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحرير أجهزة الصراف الآلي"])},
  "69": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض أرصدة التحصيل الحالية"])},
  "70": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض أرصدة التحصيل المفرغة"])},
  "71": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رمز التعريف الشخصي"])},
  "73": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إذا لم تقم بتحديد وكيل فرعي، فسيتم ربط جهاز الصراف الآلي بك"])},
  "74": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خط العرض وخط الطول عبارة عن إحداثيات جغرافية يمكن نسخها، على سبيل المثال، من خرائط جوجل عن طريق إدخال عنوان ماكينة الصراف الآلي"])},
  "75": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["موقع التثبيت"])},
  "76": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب تحديد عمود واحد على الأقل لعرض الجدول"])},
  "77": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يحفظ"])},
  "78": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يخلق"])},
  "79": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انتقل إلى أجهزة الصراف الآلي"])}
}