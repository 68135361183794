export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registro"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta personal"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta de negocios"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apellido:"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre:"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segundo nombre: (si hay)"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se permiten letras latinas, guiones y apóstrofos."])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bond"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se permiten letras latinas, guiones, espacios y apóstrofos."])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["James"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ninguno"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se permiten letras latinas, comillas, espacios, números y apóstrofos."])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"Mi Compañía\" Ltd"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teléfono"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se permiten letras latinas (mayúsculas y minúsculas), números, símbolos _ - . ! $ % ^ * ="])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repetir contraseña"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las contraseñas no coinciden"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atrás"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registro"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siguiente"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datos del usuario"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proporciona una dirección de correo electrónico o un número de teléfono válido. El número de teléfono debe ingresarse en formato internacional, por ejemplo: +499001234567."])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Te has registrado con éxito"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ahora puedes"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iniciar sesión en tu cuenta personal"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu cuenta será revisada por nuestro empleado, te informaremos sobre los resultados de su activación más tarde"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["se ha enviado un correo electrónico con un enlace para confirmar el registro"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A tu dirección de correo electrónico"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Después del primer inicio de sesión, se te pedirá que confirmes tu número de teléfono"])}
}