export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ogłoszenie"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opis"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szczegóły"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimalna"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksymalna"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limit kwoty"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czas oczekiwania (min)"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ogłoszenie aktywne"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostępne tylko przez link"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostępne tylko dla zweryfikowanych użytkowników"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anuluj"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz"])}
}