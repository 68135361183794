export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Старый пароль:"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новый пароль:"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтверждение пароля:"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новый пароль:"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтверждение пароля:"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"Новый пароль\" и \"Подтверждение пароля\" не совпадают."])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль не должен содержать кириллицу"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создание пароля подтверждения"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменение пароля подтверждения"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На главную"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Строка от 4 до 65 символов. Допустимы символы латинского алфавита, цифры и символы"])}
}