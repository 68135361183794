export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات أصول التشفير"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان الإصدار:"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم:"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المؤشر:"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأذونات"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الغرض / المهمة / الغرض"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الخصائص المالية"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فئة"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دقة:"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اتصالات"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الانبعاثات:"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشخص المسؤول:"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المصدر:"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تكلفة الوحدة:"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حماية:"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مشتريات أجهزة الصراف الآلي:"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المبيعات عبر أجهزة الصراف الآلي:"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المعاملات مع الأصول في البورصة:"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معاملات الأصول عبر P2P:"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان الاستضافة"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان الكتاب الأبيض"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بريد إلكتروني"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مقدم العرض"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اتصالات أخرى"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يذهب"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المصدر"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحد الأقصى للانبعاثات:"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["آخر"])}
}