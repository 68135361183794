export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सक्रियता"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संपत्ति का विवरण"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["तारीख"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई घोषणा नहीं"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मेरी घोषणाएं"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मेरी घोषणाएं"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नई घोषणा"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सक्रियता"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सक्रिय"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["निष्क्रिय"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आईडी"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दिशा"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बाहरी संपत्ति"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आंतरिक संपत्ति"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विवरण"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दे रहा हूँ"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्राप्त कर रहा हूँ"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सीमाएँ"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रकाशन तिथि"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खाते में शेष"])}
}