export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأصول الخارجية"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرمز"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مرتبط بالأصل"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وحدات القياس"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المجموعة"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الإنشاء"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المؤلف"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإجراءات"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفترة من"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفترة إلى"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التصفية"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعادة تعيين"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحديث"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد أصول"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اليوم"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأمس"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأسبوع"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشهر"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السنة"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مرتبط بالأصل"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المجموعة"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المؤلف"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النشاط"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البلدان"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نشط"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غير نشط"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحرير الأصل الخارجي"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حذف الأصل الخارجي"])}
}