export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Émission supplémentaire"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crypto-actif"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte - source de couverture"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte de débit"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Émission supplémentaire"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Émission actuelle"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JETON"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calcul de la couverture"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commission"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commission"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calcul de la couverture"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liaison"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Données de base"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pourcentage de couverture"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Couverture future"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déduction de la couverture"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant qui sera en réserve après l'émission supplémentaire."])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À déduire"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Couverture actuelle"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant qui sera déduit de votre compte après l'émission supplémentaire."])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant de couverture future"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E — Montant d'émission actuel"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I — Émission supplémentaire"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["K — Taux de liaison"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["P — Pourcentage de couverture"])}
}