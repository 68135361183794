export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Šablony"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doplnění"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Převod"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výběr"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název šablony"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operátor"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pole"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operace"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádné šablony"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Příjemce"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opravdu chcete smazat šablonu č."])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přejmenovat šablonu"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přejmenovat"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smazat"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Použít"])}
}