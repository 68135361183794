export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasaporte digital"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Más detalles"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID de Usuario:"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de registro:"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Última autorización:"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mis activos"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuentas"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver todo"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recargar"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retirar"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay cuentas"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña de confirmación"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para confirmar las operaciones, debes crear una contraseña de confirmación."])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plantillas"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todo"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagos populares"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noticias"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraer"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Más..."])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay activos"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balance"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retirar"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completar"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intercambio"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["al usuario"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo global"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrar"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo no fiduciario"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["saldo fiduciario"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nueva información"])}
}