export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Période du"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Période au"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporter"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtres"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualiser"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètre"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opération"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correspondant"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres/Commentaire"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune opération trouvée"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant des opérations"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recettes :"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" pièces pour un montant de"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dépenses :"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Types d'opérations"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiements, paiement de services"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferts internes"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiement de facture"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferts entre vos comptes"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Création de crypto-chèques"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activation de crypto-chèques"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Divers"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recettes/Dépenses"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recettes"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dépenses"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["= égal"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≠ différent"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["> supérieur"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≥ supérieur ou égal"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["< inférieur"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≤ inférieur ou égal"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminé avec succès"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En cours de traitement"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aujourd'hui"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semaine"])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mois"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Année"])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historique des opérations"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechargement de compte"])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte non sélectionné"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échange sur le marché"])},
  "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechargement d'adresse"])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferts sur compte propre"])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correspondant"])},
  "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opération"])}
}