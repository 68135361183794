export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рахунок переказу:"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума:"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коментар:"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Протекція угоди"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код:"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Згенерувати"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Термін протекції:"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["днів"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва шаблону:"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберегти"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберегти як шаблон"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перевести"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаблони"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переказ іншому користувачеві"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код протекції — це код, який повинен ввести отримувач для завершення угоди. Захищений переказ отримувач побачить одразу ж у розділі \"Історія операцій\", але скористатися грошима зможе тільки після того, як введе код протекції в картці транзакції. Якщо термін дії платежу минув, то кошти повертаються відправнику."])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адреса переказу"])}
}