export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können mehrere Zahlungen gleichzeitig tätigen. \nDazu müssen Sie zunächst eine Datei mit einer Beschreibung der Zahlungen im Format „XLS“, „XLSX“, „CSV“ erstellen."])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dateianalyse"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korrespondent"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betrag"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameter"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dateiinformationen"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtanzahl der Zahlungen: "])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtbetrag: "])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsdatei"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbuchungskonto"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungen durchführen"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsdatei hochladen"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüfen Sie den Dateiinhalt"])},
  "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Massenüberweisungen und -zahlungen"])}
}