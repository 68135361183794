export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registro"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conta pessoal"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conta empresarial"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobrenome:"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome:"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome do meio: (se houver)"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["São permitidas letras latinas, bem como hífen e apóstrofo."])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bond"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["São permitidas letras latinas, bem como hífen, espaço e apóstrofo."])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["James"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["São permitidas letras latinas, bem como hífen, aspas, espaços, números e apóstrofo."])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"My Company\" Ltd"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefone"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senha"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["São permitidas letras latinas (maiúsculas e minúsculas), números, símbolos _ - . ! $ % ^ * ="])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repetir senha"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senhas não coincidem"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voltar"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registro"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avançar"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dados do usuário"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senha"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indique um endereço de e-mail ou número de telefone válido. O número de telefone deve estar no formato internacional, por exemplo: +499001234567."])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você foi registrado com sucesso"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agora você pode"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["autorizar-se no painel pessoal"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sua conta será verificada por nosso funcionário, informaremos os resultados de sua ativação posteriormente"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["um e-mail com um link de confirmação foi enviado"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para seu endereço de e-mail"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Após o primeiro login, você será solicitado a confirmar o número de telefone"])}
}