export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأصل الداخلي"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحساب"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأصل الخارجي"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المجموعة"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم EN"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المرادفات"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البلدان"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إذا تم ملؤه، سيكون الإعلان متاحًا فقط للمستخدمين من البلدان المحددة"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ربط بالأصل"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وحدات القياس"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختصار"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدقة"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وصف"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفاصيل"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اتجاه التحويل"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحد الأدنى"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحد الأقصى"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحد"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وقت الانتظار (دقائق)"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإعلان نشط"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متاح فقط بالرابط"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متاح فقط للمستخدمين الموثوقين"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["للعودة"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نشر"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التالي"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اتجاه"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القيود"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء إعلان"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أصلك الخارجي"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا تربط"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نعم"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خفض السعر"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعادة"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد حساب لهذا الأصل"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء حساب"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حدد السعر"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السعر صحيح"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خفض السعر"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعادة"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحد الأدنى أكبر من الحد الأقصى"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أين وكيف تريد استلام التحويل. على سبيل المثال: تحويل في تطبيق البنك على رقم الهاتف +9719990001111 (المستلم ألكسندر ن.)"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أعطي"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أستلم"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأصول"])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التفاصيل المطلوبة"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ما هي التفاصيل التي يجب أن يرسلها المستلم لكي تتمكن من إجراء التحويل"])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر الأصل"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر الأصل"])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ماذا تريد أن تحصل: أموال في الأصل الداخلي، مثل بي تي سي أو في الأصل الخارجي، مثل توكن غولد؟"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اكتب تعليقًا عن ظروف إجراء هذه الصفقة، على سبيل المثال: \\\"يتم نقل الأصل الخارجي فقط عند الاجتماع الشخصي\\\""])},
  "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع القيم التي ينتمي إليها الأصل الخارجي ، على سبيل المثال ، \"نقدي\""])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الأصل لعرضه على المنصة"])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكن أيضًا تسمية الأصل بالكلمات من القائمة"])},
  "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مطابقة مع أصل موجود"])},
  "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مثال: لترات، قطع، ساعات، صناديق"])},
  "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مثال: ل، ق، س، ص"])},
  "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كم عدد الأرقام بعد الفاصلة في قيم الأصل"])},
  "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لقد اخترت إنشاء أصل غير موجود بعد، يرجى ملء خصائصه"])},
  "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوقت الممنوح للأطراف لتنفيذ الشروط"])},
  "62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" الأصل الداخلي هو الأصل الموجود في نظام \\\"نيو ريالتي\\\". الأصل الخارجي هو الأصل غير الموجود في نظام \\\"نيو ريالتي\\"])},
  "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غير مرتبط"])},
  "64": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القيمة المتوقعة: من 1 إلى 2147483"])}
}