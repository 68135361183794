export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bankomaty"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klikněte pro rozbalení tabulky na celou obrazovku"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obnovit"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualizovat"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivita"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subdealeři"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivní"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neaktivní"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořte si bankomat"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upravit kryptomat"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušit"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložit"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Základní informace"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hlavní"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otevírací doba"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přihlašovací jméno"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heslo"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresa"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popis místa instalace"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normalizovaný"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenormalizovaný"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provozní režim"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pondělí"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úterý"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Středa"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čtvrtek"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pátek"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobota"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neděle"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obědová přestávka"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opakovat heslo"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Použijte existující účty"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořte nové účty"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Základní údaje"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Účet"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provozní doba (informace)"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivita"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akce"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebyly nalezeny žádné šifrovací stroje"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["číslo bankomatu"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zeměpisná šířka"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zeměpisná délka"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linka pomoci"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID subdealera"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Účty"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrola"])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Měna"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tranzitní účet"])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvoření kryptomatu"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úprava kryptomatu"])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heslo"])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ano"])},
  "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zakázat"])},
  "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Povolit"])},
  "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seznam sběratelů"])},
  "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidejte sběratele"])},
  "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nelze přejít na inkasní zůstatky – žádná data"])},
  "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seznam sběratelů"])},
  "62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidejte sběratele"])},
  "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Povoleno"])},
  "65": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přijetí"])},
  "67": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresa"])},
  "68": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upravit bankomat"])},
  "69": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobrazit aktuální inkasní zůstatky"])},
  "70": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobrazit vyložené zůstatky sbírky"])},
  "71": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PIN kód"])},
  "73": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokud neurčíte subdealera, bude s vámi bankomat spojen"])},
  "74": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeměpisná šířka a délka jsou zeměpisné souřadnice, které lze zkopírovat například z Map Google zadáním adresy bankomatu"])},
  "75": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Místo instalace"])},
  "76": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pro zobrazení tabulky musí být vybrán alespoň jeden sloupec"])},
  "77": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložit"])},
  "78": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořit"])},
  "79": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přejděte do ATM zařízení"])}
}