export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список інвойсів"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Інвойсів не знайдено"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коментар"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Магазин"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер магазину"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скасувати рахунок -фактуру"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберігайте код платежу"])}
}