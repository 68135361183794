export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The magic word is an additional security setting."])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If the magic word is enabled, then each time you log in, the system will ask you to enter some characters (by their serial number) from the magic word."])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magic word:"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ask for the magic word:"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When entering the office:"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When paying through a merchant:"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When using the card:"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magic word"])}
}