export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["状态"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主题"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最后消息"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["类别"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有请求"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["类别"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["优先级"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主题"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["消息内容"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文件"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您可以上传一个或多个文件。文件最大大小为10MB。"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发送"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["低"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正常"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["高"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["技术支持"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活动请求"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["存档"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新请求"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["由操作员处理"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请求已创建"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工作正在进行中，请等待操作员的答复"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请求已关闭"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请求已关闭"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有新消息"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["来自操作员的新消息"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有新消息"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发送到存档"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有请求"])}
}