export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejestracja"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osoba fizyczna"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osoba prawna"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwisko:"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imię:"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drugie imię:"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dozwolone są polskie litery, a także myślnik i apostrof."])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kowalski"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dozwolone są polskie litery, a także myślnik, spacja i apostrof."])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jan"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kowalski"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dozwolone są polskie i łacińskie litery, a także myślnik, cudzysłów, spacje, cyfry i apostrof."])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sp. z o.o. \"Rogi i kopyta\""])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hasło"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dozwolone są litery łacińskie (duże i małe), cyfry, symbole _ - . ! $ % ^ * ="])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powtórz hasło"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hasła nie pasują"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wstecz"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejestracja"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dalej"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dane użytkownika"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hasło"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podaj poprawny adres e-mail lub numer telefonu. Numer telefonu podaje się w formacie międzynarodowym, na przykład: +48123456789."])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zostałeś zarejestrowany"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teraz możesz "])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zalogować się do konta"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twoje konto zostanie sprawdzone przez naszego pracownika, o wynikach aktywacji poinformujemy Cię później"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wysłano wiadomość z linkiem do potwierdzenia rejestracji"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na Twój adres e-mail"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Po pierwszej autoryzacji zostaniesz poproszony o potwierdzenie numeru telefonu"])}
}