export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["数字护照"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["详细信息"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户 ID："])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注册日期："])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最后授权："])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的资产"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["账户"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看所有"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["充值"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提现"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有账户"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用于确认的密码"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["要确认交易，您需要创建一个确认密码。"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["模板"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金额"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["热门支付"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新闻"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["折叠"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更多..."])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有资产"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创造"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平衡"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提取"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["充值"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交换"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["致用户"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["整体平衡"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["显示于"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["非法定余额"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["法币余额"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新信息"])}
}