export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लेन-देन"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सामान्य पैरामीटर"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खातों में शेष परिवर्तन"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ऑपरेशन के अंत में:"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ऑपरेशन की शुरुआत में:"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अतिरिक्त पैरामीटर"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ब्लॉकचेन में ऑपरेशन्स"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कॉलबैक"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अगला प्रयास:"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समय"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उत्तर"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सौदे की प्रोटेक्शन"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रोटेक्शन अवधि:"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["तक"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रोटेक्शन कोड:"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पुष्टि करें"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टेम्पलेट पैरामीटर"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टेम्पलेट का नाम:"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सहेजें"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ऑपरेशन रद्द करें"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ऑपरेशन दोहराएं"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टेम्पलेट सहेजें"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बंद करें"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लेन-देन संख्या"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ऑपरेशन"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रतिपक्षी"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भुगतान की तारीख"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["राशि"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शुल्क"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टिप्पणी"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["परिणाम"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भुगतान फॉर्म संख्या"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आमद/खर्च"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पते की शेष राशि में परिवर्तन"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जमा की पुष्टि करें"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रेषक को पैसे वापस करें"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संदिग्ध ऑपरेशन"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्रियाओं के विकल्प:"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्राप्तकर्ता का आयोग"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अदाकार कमीशन"])}
}