export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ATMs"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to expand the table to full screen"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refresh"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subdealers"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactive"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create an ATM"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit crypto ATM"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main information"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opening hours"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installation site description"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normalized"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non-normalized"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operating mode"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monday"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuesday"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wednesday"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thursday"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friday"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saturday"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunday"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lunch break"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat password"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use existing accounts"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new accounts"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic details"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operating hours (reference information)"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No crypto machines found"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ATM number"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["latitude"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["longitude"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helpline"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub-dealer ID"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accounts"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check"])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transit account"])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creation of a cryptomat"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editing a cryptomat"])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
  "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prohibit"])},
  "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allow"])},
  "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of collectors"])},
  "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a collector"])},
  "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to go to collection balances - no data"])},
  "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of collectors"])},
  "62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a collector"])},
  "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allowed"])},
  "65": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceptance"])},
  "67": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
  "68": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit ATM"])},
  "69": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View current collection balances"])},
  "70": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View unloaded collection balances"])},
  "71": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pin code"])},
  "73": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you do not specify a subdealer, the ATM will be linked to you"])},
  "74": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latitude and longitude are geographic coordinates that can be copied, for example, from Google Maps by entering the ATM address"])},
  "75": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installation location"])},
  "76": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At least one column must be selected to display the table"])},
  "77": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
  "78": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
  "79": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to ATM devices"])}
}