export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["旧密码："])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新密码："])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认密码："])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新密码："])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认密码："])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"新密码\"和\"确认密码\"不匹配。"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码不应包含西里尔字母"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建确认密码"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更改确认密码"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返回主页"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["长度为4至65个字符。允许使用拉丁字母、数字和符号"])}
}