export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez effectuer plusieurs paiements en même temps. \nPour ce faire, vous devez d'abord créer un fichier avec une description des paiements au format \"XLS\", \"XLSX\", \"CSV\"."])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse du fichier"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correspondant"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètre"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations sur le fichier"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre total de paiements : "])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant total : "])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fichier de paiement"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte de débit"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effectuer les paiements"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléchargement du fichier de paiement"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifiez le contenu du fichier"])},
  "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiements et transferts de masse"])}
}