export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiunta raccolta"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni di base"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nozioni di base"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distributore"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero del punto"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di carte per applicazione"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accettatore di banconote"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carte"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collezionisti di contanti"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nota per i collezionisti"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commento alla raccolta"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caricamento fuori ordine"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sostituire la cassetta dell'accettatore di banconote"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commento alla cassetta"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commento alle carte"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rimuovere tutte le carte dal cassetto del dispenser delle carte di scarto"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rimuovere tutte le carte dal tubo"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il numero del punto è un campo obbligatorio che deve essere compilato"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID cassetta 1"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID cassetta 2"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID cassetta 3"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID cassetta 4"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classificazione della cassetta 1"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di banconote nella cassetta 1"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo nella cassetta 1"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classificazione della cassetta 2"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di fatture nella cassetta 2"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commento al nastro 1"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID della cassetta"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classificazione della cassetta"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di fatture"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Somma"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commento alla cassetta"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raccolta di modifiche"])}
}