export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bourse"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marché. Résumé"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offres similaires"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offres contraires"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ils donnent"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ils reçoivent"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes commandes"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernières transactions"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donné"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date et heure"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je remets"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je reçois"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opérations"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["commission"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Répéter"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune offre"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune transaction"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune commande"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complètement exécuté"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traitement non démarré"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traitement démarré"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partiellement exécuté"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complètement annulé"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partiellement exécuté et annulé"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reçu"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les commandes"])}
}