export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء زوج من العملات"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["زوج العملة"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعداد التحديث"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بوابة"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العملة المعطاة"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العملة المستلمة"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة الربح إلى الدورة %"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع تحديث الدورة"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مدة الدورة، ثواني"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفاصل الزمني لتحديث الدورة التدريبية، ثانية"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حسنًا"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ما هو تاريخ صلاحية الدورة؟"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم المستعار للعملة المحددة"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم المستعار للعملة المستلمة"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بوابة"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من الضروري اختيار عملة، علاوة على ذلك، عملة واحدة فقط: معينة أو مستلمة"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إرسال المبلغ * المعدل = استلام المبلغ"])}
}