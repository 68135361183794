export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorização"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login:"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senha:"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esqueceu a senha?"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primeira vez aqui?"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crie uma conta"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recuperação de senha"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefone ou e-mail:"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recuperar"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recuperação de senha"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobrenome:"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome:"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome do meio:"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de nascimento:"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrar"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voltar"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encontrar"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuário encontrado"])}
}