export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset esterni"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collegato all'asset"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unità di misura"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppo"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di creazione"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autore"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azioni"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periodo da"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periodo a"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtri"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reimposta"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiorna"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun asset trovato"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oggi"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ieri"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settimana"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mese"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anno"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collegato all'asset"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppo"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autore"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attività"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paesi"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attivo"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non attivo"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica asset esterno"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina asset esterno"])}
}