export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भुगतान कोड / लिंक जेनरेट करना"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्टोर ऑपरेशन संख्या"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["राशि"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टिप्पणी"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अतिरिक्त पैरामीटर"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सफल URL"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["त्रुटि URL"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सूचना URL"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सफल भुगतान के बाद ग्राहक को लौटाने का URL"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["त्रुटि के बाद ग्राहक को लौटाने का URL"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भुगतान परिणामों की सूचना भेजने का URL"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["राशि तय करें"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कॉपी करें"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जांचें"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भुगतान लिंक"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फॉर्म"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रलेखन"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भुगतान खाते की जीवन अवधि (मिनटों में), डिफ़ॉल्ट 60"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लिंक की वैधता अवधि"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लिंक"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फॉर्म"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विजेट"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यदि आप राशि तय नहीं करते हैं, तो भुगतानकर्ता इसे स्वतंत्र रूप से दर्ज करने में सक्षम होगा (उदाहरण के लिए, धर्मार्थ भुगतान के लिए उपयुक्त)"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["छिपाना"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भुगतान फार्म"])}
}