export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Төлемді растау"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қайтару комиссиясын есептеу"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Төлемді қайтару"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакция"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Блокчейн хеші"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AML тәуекелі"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сома"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комиссия"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Есепке алу"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Түзетілген шарттармен келіссеңіз, мынаны растаңыз:"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Егер бұл шарттар сізге сәйкес келмесе, қайтару шығындарын шегеріп, ақшаны жіберушінің шотына қайтаруға болады:"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакция"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["сомасына"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AML тәуекелі бар"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["сондықтан оны комиссиясы қайта есептелді"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["осылайша, есепке алынатын сома"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жаңадан келгендер табылмады"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Төлеу күні"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Каражатты қайтару"])}
}