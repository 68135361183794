export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أعطي"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المبلغ"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أستلم"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفلاتر"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحديث"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رمز"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البائع"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعطي"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تستلم"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحدود"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإجراءات"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صفقات"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صفقة"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد صفقات"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العروض"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفائدة"])}
}