export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generacja kodu / linku do płatności"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nr operacji sklepu"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kwota"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentarz"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodatkowy parametr"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL sukcesu"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL błędu"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL powiadomienia"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL, na który klient wróci po udanej płatności"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL, na który klient wróci po błędzie"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL, na który zostanie wysłane powiadomienie o wynikach płatności"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ustal kwotę"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopiuj"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprawdź"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link do płatności"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formularz"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumentacja"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okres ważności rachunku do płatności w minutach, domyślnie 60"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czas ważności linku"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formularz"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Widżet"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeśli nie ustalisz kwoty, płatnik będzie mógł ją wprowadzić samodzielnie (przydaje się np. przy wpłatach na cele charytatywne)"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukrywać"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formularz płatności"])}
}