export default {
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Give the amount"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sum"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get amount"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filters"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["transactions"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deal"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No offers"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["P2P offers"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seller ID"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer code"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seller verified"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only for verified users"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It's impossible to make a deal with yourself"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select asset"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Announcement information"])}
}