export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एक मुद्रा जोड़ी बनाएं"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मुद्रा -जोड़ी"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एक अद्यतन स्थापित करना"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["द्वार"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दी गई मुद्रा"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मुद्रा प्राप्त हुई"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पाठ्यक्रम में लाभ जोड़ें, %"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पाठ्यक्रम अद्यतन प्रकार"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पाठ्यक्रम जीवनकाल, सेकंड"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पाठ्यक्रम अद्यतन अंतराल, सेकंड"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कुंआ"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोर्स किस तारीख के लिए वैध है?"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दी गई मुद्रा का उपनाम"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्राप्त मुद्रा का उपनाम"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["द्वार"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एक मुद्रा चुनना आवश्यक है और, इसके अलावा, केवल एक: दिया या प्राप्त किया गया"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भेजने की राशि * दर = प्राप्त करने की राशि"])}
}