export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivita"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popis aktiva"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádné nabídky"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moje nabídky"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moje nabídky"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nová nabídka"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivita"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivní"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neaktivní"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vše"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Směr"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Externí aktivum"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Interní aktivum"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popis"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyměňuji"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostávám"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limity"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum zveřejnění"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zůstatek na účtu"])}
}