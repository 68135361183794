export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mis tiendas"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontraron tiendas"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear código de widget"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear enlace de pago"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar tienda"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Realmente quieres eliminar la tienda"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrar tienda"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modo de pagos"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actividad"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceptación"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acciones"])}
}