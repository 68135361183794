export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum příjmu"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum zpracování"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stav"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počet plateb"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vč. úspěšných"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vč. chybových"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hromadné platby"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transakce"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výsledek"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poskytovatel služeb"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Částka"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komise"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentář"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. parametr"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platby nenalezeny"])}
}