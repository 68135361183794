export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Одноразовые ключи значительно повышают безопасность системы."])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В момент входа программы вас попросят не только ввести логин и пароль, но и очередной одноразовый ключ. Активация производится поочередно. Последний ключ действует до момента, пока вы не создадите новую серию ключей."])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Использовать одноразовые ключи"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список ключей"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сгенерировать"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Экспорт"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ключ"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Одноразовые ключи"])}
}