export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название:"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название – это полное название крипто-актива, каким его будут видеть все: сам эмитент, покупатели, продавцы, процессинг и др."])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ТИКЕР"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сокращенная аббревиатура длиной от 3 до 10 символов. Допустимы латинские буквы в верхнем регистре, дефис (тире) и цифры."])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категория:"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите категорию, к которой больше подходит ваш крипто-актив"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поддерживаемые форматы: png, jpg, jpeg, svg. Размер файла не более 1 Мб, 500*500 пикселей"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опишите, зачем данный крипто-актив выпускается эмитентом, в какой сфере работает или будет работать, какова его миссия"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Логотип:"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цель / миссия / назначение:"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Точность:"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эмиссия:"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стоимость единицы:"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество знаков после запятой для суммы. Например, точность большинства фиатных валют, таких как доллар США или Евро равна 2."])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество единиц выпускаемого крипто-актива. Сумма эмиссии будет сразу доступна на вашем счете после завершения операции создания крипто-актива."])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заявленная стоимость единицы:"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обеспеченность:"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Счет:"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лицо, несущее обязательства:"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Решение о выпуске крипто-актива:"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Экспортировать операции в блокчейн:"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип блокчейна:"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Включить операции с крипто-активом:"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ограничения/Санкции:"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Укажите, гражданам или налоговым резидентам каких стран из приведенного списка запрещается использование в своем обороте крипто-актива: "])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Иные ограничения и условия:"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес хостинга:"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес белой книги:"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Электронная почта:"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telegram:"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twitter:"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook:"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Другие контакты:"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["К предыдущему"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Далее"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать крипто-актив"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название и назначение"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Финансовые характеристики"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эмитент"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Экспорт в блокчейн"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лимиты и ограничения"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контакты"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не определена"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фиксированная привязка к фиатным валютам и иным активам"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эмитент (Лицо, выпускающее крипто-актив)"])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оферент (Лицо, распространяющее крипто-актив)"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправитель"])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получатель"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я (включить спонсорство)"])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Размещение крипто-актива"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Крипто-актив означает цифровое представление стоимости или права, которое может быть передано и сохранено в электронном виде с использованием технологии блокчейна. Крипто-активы НЕ включают в себя фиатные валюты, ценные бумаги и иные финансовые инструменты и активы, подлежащие отдельному государственному регулированию."])},
  "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактировать крипто-актив"])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Администрирование"])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Акцепт"])},
  "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Причина отказа в акцепте"])},
  "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Счёт оферента"])},
  "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Счёт оферента в валюте привязки"])},
  "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оферент - представитель эмитента актива в сделках покупки/продажи на первичном рынке. Счёт пассивный. Может быть счетом эмиссии актива"])},
  "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Счёт пассивный, в валюте привязан. Должен принадлежать тому же пользователю, что и предыдущий счёт"])},
  "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разрешить покупку актива пользователями в банкоматах"])},
  "62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разрешить продажу актива пользователями в банкоматах"])},
  "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Форма покупки для вторичного рынка"])},
  "64": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сделки на вторичном рынке выполняются через шлюзы"])},
  "65": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Форма продажи для вторичного рынка"])},
  "66": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сделки на вторичном рынке выполняются через шлюзы"])},
  "67": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разрешить сделки с активом на бирже"])},
  "68": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разрешить сделки с активом через P2P"])},
  "69": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В случае, если Вы являетесь индивидуальным предпринимателем или представителем юридического лица, вам необходимо загрузить файл решения о выпуске цифрового актива в формате PDF. Решение должно содержать все основные характеристики виртуального актива и быть подписано электронной подписью ИП или данного юридического лица. Пример оформления решения о выпуске цифрового актива Вы можете найти на страницах нашего сайта в разделе \"Документы\""])},
  "70": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Размещение виртуального актива"])},
  "71": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подставить счёт эмиссии"])},
  "72": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать из счетов эмитента"])},
  "73": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма к списанию:"])},
  "74": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет счетов в валюте"])},
  "75": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать счет"])},
  "76": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Макс. эмиссия:"])},
  "77": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Размер эмиссии превышает макс. эмиссию"])},
  "78": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Макс. эмиссия меньше размера эмиссии или равна нулю"])},
  "79": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Формы отсутствуют"])}
}