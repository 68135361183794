export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastavení oznámení"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Povolit"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oznámit o operacích"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorizace (přihlášení do osobního kabinetu, mobilní aplikace atd.)"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oznámit při události \"Provedení finančních operací pomocí karty\""])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výdaje (jakékoli platby, převody, platba služeb atd.)"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Příjem peněžních prostředků"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přijetí dopisu na vnitřní poštu"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Příjem faktury k úhradě"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Změna nastavení zabezpečení"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrdit operace kódem ze zprávy"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrdit autorizaci kódem ze zprávy"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrdit při \"Provedení finančních operací pomocí karty\""])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrdit změnu nastavení zabezpečení kódem ze zprávy"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrdit výdaje kódem ze zprávy"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Změny nebyly uloženy, uložit?"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložit"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neukládat"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložit"])}
}