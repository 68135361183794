export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Génération de code / lien de paiement"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro d'opération du magasin"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètre supplémentaire"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL de succès"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL d'échec"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL de notification"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL de retour du client après paiement réussi"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL de retour du client après échec"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL d'envoi de notification de paiement"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixer le montant"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copier"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifier"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lien de paiement"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulaire"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documentation"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée de vie de la facture en minutes, par défaut 60"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée de validité du lien"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lien"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulaire"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Widget"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous ne fixez pas le montant, le payeur pourra le saisir lui-même (adapté, par exemple, aux paiements caritatifs)"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cacher"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulaire de paiement"])}
}