export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passeport numérique"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En savoir plus"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifiant utilisateur :"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d'inscription :"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernière connexion :"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes actifs"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comptes"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir tout"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recharger"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retirer"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun compte"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe de confirmation"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour confirmer les transactions, vous devez créer un mot de passe de confirmation."])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modèles"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiements populaires"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualités"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réduire"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus..."])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun actif"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Équilibre"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retirer"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recharger"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échange"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À l'utilisateur"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilan global"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher dans"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solde non fiduciaire"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilan Fiat"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelles informations"])}
}