export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat operation"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operations history"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of accounts"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unknown error"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Templates"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My assets"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information about new assets"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional issue"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of virtual assets"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link to blockchain"])}
}