export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moje sklepy"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie znaleziono sklepów"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz kod widżetu"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz link do płatności"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edytuj"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń sklep"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czy na pewno chcesz usunąć sklep"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zarejestruj sklep"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kod"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tryb płatności"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktywność"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akceptacja"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Działania"])}
}