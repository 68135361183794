export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero della cassetta"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID della cassetta"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di raccolta della cassetta"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classificazione della cassetta"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caricato"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Somma"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantità"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Somma"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ATM"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Somma"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In realtà"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantità"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Somma"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commento alla cassetta"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun dato da visualizzare"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raccolta dispositivi"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["valido"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["C"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Di"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["i resti vengono portati via"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di fatture"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Somma"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ATM"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di fatture"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Somma"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commento"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distributore"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rifiuta/Ritira"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accettatore di banconote"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rifiuta le carte"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carte"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["attuale"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["scaricato"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldi di incasso bancomat"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raccolta dispositivi"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["valido"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cassetta installata nel bancomat"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sequestrato"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["i resti vengono portati via"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["i resti vengono portati via"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cassetta vuota:"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["adesione:"])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ulteriori informazioni:"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fino ad oggi"])}
}