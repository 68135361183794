export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geldautomaten"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klicken Sie hier, um die Tabelle im Vollbildmodus zu öffnen"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurücksetzen"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualisieren"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivität"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unterhändler"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inaktiv"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen Sie einen Geldautomaten"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kryptomat bearbeiten"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hauptinformationen"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hauptsächlich"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öffnungszeiten"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung des Installationsortes"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normalisiert"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht normalisiert"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betriebsmodus"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montag"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dienstag"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mittwoch"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donnerstag"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freitag"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samstag"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonntag"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mittagspause"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort wiederholen"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestehende Konten nutzen"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen Sie neue Konten"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundlegende Informationen"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitszeiten (zur Information)"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivität"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktionen"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Kryptomaschinen gefunden"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geldautomatennummer"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Breite"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Länge"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helpline"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unterhändler-ID"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konten"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüfen"])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Währung"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transitkonto"])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellung eines Kryptomaten"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeiten eines Kryptomaten"])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])},
  "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbieten"])},
  "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erlauben"])},
  "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste der Sammler"])},
  "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fügen Sie einen Kollektor hinzu"])},
  "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Inkassosalden können nicht aufgerufen werden – keine Daten"])},
  "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste der Sammler"])},
  "62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fügen Sie einen Kollektor hinzu"])},
  "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erlaubt"])},
  "65": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annahme"])},
  "67": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
  "68": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geldautomaten bearbeiten"])},
  "69": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuelle Inkassosalden anzeigen"])},
  "70": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entladene Inkassosalden anzeigen"])},
  "71": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PIN-Code"])},
  "73": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie keinen Subhändler angeben, wird der Geldautomat mit Ihnen verknüpft"])},
  "74": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Breiten- und Längengrad sind geografische Koordinaten, die beispielsweise aus Google Maps durch Eingabe der Geldautomatenadresse übernommen werden können"])},
  "75": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installationsort"])},
  "76": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um die Tabelle anzuzeigen, muss mindestens eine Spalte ausgewählt sein"])},
  "77": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
  "78": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen"])},
  "79": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gehen Sie zu Geldautomaten"])}
}