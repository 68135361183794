export default {
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dare importo"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricevere importo"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtri"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiorna"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["transazioni"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transazione"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna offerta"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offerte"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID venditore"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice dell'offerta"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cripto-asset"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrizione"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Venditore verificato"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solo per utenti verificati"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossibile effettuare la transazione con se stessi"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona asset"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni sull'annuncio"])}
}