export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Безпека"])},
  "Antiphishing": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Налаштування антифішингу є додатковим механізмом захисту під час роботи з кабінетом."])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задана вами вітальна фраза буде відображатися у верхній частині будь-якої сторінки кабінету."])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Правильна фраза покаже вам, що ви перебуваєте на правильному сайті, неправильна фраза буде сигналом: не здійснюючи операцій, необхідно терміново зв'язатися зі службою безпеки нашої системи."])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Увімкнути антифішинг:"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рядок антифішингу:"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберегти"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анти-рибальський"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зміни набудуть чинності після наступної авторизації"])}
  },
  "IP": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Налаштування доступу за IP-адресою не дозволить вам (або зловмиснику) працювати із системою з адреси, не прописаної у списку дозволених."])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обмеження доступу до кабінету за IP-адресами"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати адреси"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адреса: "])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Блок адрес: "])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["по:"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш IP: "])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список адрес"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["З адреси"])},
    "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["По адрес"])},
    "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступ"])},
    "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дії"])},
    "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити"])},
    "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адреси відсутні"])},
    "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберегти"])},
    "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заборонити"])},
    "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дозволити"])},
    "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введіть IP-адресу"])},
    "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP-адресу введено неправильно"])},
    "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не вказано жодної IP-адреси або блоку адрес."])},
    "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жодна IP-адреса не дозволена для авторизації. Ви не зможете отримати доступ до свого рахунку."])},
    "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступ за IP"])},
    "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["з:"])}
  },
  "Magic": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чарівне слово - це додаткове налаштування безпеки."])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Якщо чарівне слово ввімкнено, то щоразу при авторизації система проситиме вас ввести якісь символи (за їх порядковим номером) із чарівного слова."])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чарівне слово:"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запитувати чарівне слово:"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["При вході в кабінет:"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["При оплаті через мерчант:"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["При використанні картки:"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберегти"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чарівне слово"])}
  },
  "Onekey": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Одноразові ключі значно підвищують безпеку системи."])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Під час входу в програму вас попросять не лише ввести логін і пароль, а й черговий одноразовий ключ. Активація відбувається по черзі. Останній ключ діє до моменту, поки ви не створите нову серію ключів."])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Використовувати одноразові ключі"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список ключів"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Згенерувати"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Експорт"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ключ"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Одноразові ключі"])}
  },
  "Password": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Старий пароль:"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новий пароль:"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Підтвердження паролю:"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберегти"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"Новий пароль\" і \"Підтвердження паролю\" не збігаються."])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зміна паролю"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рядок від 4 до 63 символів. Допустимі символи латинського алфавіту, цифри та символи"])}
  },
  "PayPassword": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Старий пароль:"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новий пароль:"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Підтвердження паролю:"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новий пароль:"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Підтвердження паролю:"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберегти"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберегти"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"Новий пароль\" і \"Підтвердження паролю\" не збігаються."])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль не повинен містити кирилицю"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створення паролю підтвердження"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зміна паролю підтвердження"])},
    "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На головну"])},
    "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рядок від 4 до 65 символів. Допустимі символи латинського алфавіту, цифри та символи"])}
  },
  "SecretQuestion": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У разі, якщо ви забудете пароль для доступу до кабінету, ви зможете відновити його, відповівши на секретне запитання."])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У цьому розділі ви можете змінити секретні питання/відповідь."])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Секретне питання"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваше питання:"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відповідь:"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберегти"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Секретне питання"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Своє питання"])}
  }
}