export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес таңдау"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тұрғылықты жері"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бас тарту"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сақтау"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ел"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Өңір"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Округ"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аудан"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қала"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қала ішіндегі аумақ"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тұрғылықты жері"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Көше"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес элементі"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Түсініктеме"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Үй"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пошталық индекс"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мекенжай"])}
}