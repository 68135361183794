export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auffüllkonto"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsmethode"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorlagenname:"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorlage speichern"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorlagen"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontoauffüllung"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überweisungsdetails"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüfung der Überweisung"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überweisungsinformationen"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anleitung"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wiederauffüllung der Krypto-Adresse"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kryptoadresse für den Nachschub"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zur Aufgabenliste"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überweisungsadresse"])}
}