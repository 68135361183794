export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bankomaty"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kliknij, aby rozszerzyć tabelę na pełny ekran"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zresetuj"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odśwież"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktywność"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podsprzedawcy"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktywny"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieaktywny"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz bankomat"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edytuj kryptomat"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anuluj"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Główne informacje"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Główne"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Godziny otwarcia"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hasło"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opis miejsca instalacji"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Znormalizowany"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieznormalizowany"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tryb pracy"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poniedziałek"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wtorek"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Środa"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czwartek"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piątek"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobota"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niedziela"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przerwa obiadowa"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powtórz hasło"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skorzystaj z istniejących kont"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz nowe konta"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podstawowe informacje"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Godziny pracy (informacja orientacyjna)"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktywność"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Działania"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie znaleziono maszyn kryptograficznych"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numer bankomatu"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["szerokość"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["długość geograficzna"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infolinia"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identyfikator sub-dealera"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konta"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprawdzać"])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waluta"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto tranzytowe"])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stworzenie kryptomatu"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edycja kryptomatu"])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hasło"])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tak"])},
  "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zakazać"])},
  "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umożliwić"])},
  "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista kolekcjonerów"])},
  "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj kolektor"])},
  "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie można przejść do sald odbioru - brak danych"])},
  "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista kolekcjonerów"])},
  "62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj kolektor"])},
  "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dozwolony"])},
  "65": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przyjęcie"])},
  "67": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres"])},
  "68": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edytuj bankomat"])},
  "69": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobacz aktualne salda kolekcji"])},
  "70": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobacz niezaładowane salda kolekcji"])},
  "71": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kod PIN"])},
  "73": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeśli nie określisz subdealera, bankomat zostanie z Tobą połączony"])},
  "74": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szerokość i długość geograficzna to współrzędne geograficzne, które można skopiować np. z Google Maps wpisując adres bankomatu"])},
  "75": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miejsce instalacji"])},
  "76": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aby wyświetlić tabelę, należy wybrać co najmniej jedną kolumnę"])},
  "77": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ratować"])},
  "78": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tworzyć"])},
  "79": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przejdź do bankomatów"])}
}