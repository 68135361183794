export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potwierdź płatność"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oblicz prowizję za zwrot"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zwróć płatność"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transakcja"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hash w blockchain"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ryzyko AML"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kwota"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prowizja"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do zaliczenia"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeśli zgadzasz się z zmienionymi warunkami, potwierdź zaliczenie:"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeśli te warunki Ci nie odpowiadają, możesz zwrócić środki na konto nadawcy z odliczeniem kosztów zwrotu:"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transakcja"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["na kwotę"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ma ryzyko AML"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dlatego za jej zaliczenie przeliczona jest prowizja"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tak więc kwota do zaliczenia wynosi"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie wykryto nowych wpływów"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data płatności"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zwrot"])}
}