export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La parola magica è una configurazione di sicurezza aggiuntiva."])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se la parola magica è attivata, ogni volta che accedi al sistema, ti verrà chiesto di inserire alcuni caratteri (in base alla loro posizione) della parola magica."])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parola magica:"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richiedi la parola magica:"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durante l'accesso al portafoglio:"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durante il pagamento tramite merchant:"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durante l'uso della carta:"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parola magica"])}
}