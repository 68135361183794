export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořte měnový pár"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Měnový pár"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastavení aktualizace"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brána"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dané měně"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přijatá měna"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidejte zisk do kurzu, %"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ aktualizace kurzu"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doba trvání kurzu, sekundy"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interval aktualizace kurzu v sekundách"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dobře"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na jaké datum kurz platí?"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alias ​​dané měny"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alias ​​přijaté měny"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brána"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je nutné zvolit měnu a navíc pouze jednu: danou nebo přijatou"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odesílaná částka * Sazba = Částka pro příjem"])}
}