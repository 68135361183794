export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सौदे का अनुरोध करें"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रस्ताव"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विक्रेता"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समय सीमा"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पार्टियों की क्रियाओं की प्रतीक्षा का समय"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मूल्य"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["देता हूँ"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्राप्त करता हूँ"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सौदे का अनुरोध करें"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खाता बनाएं"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खाता चयनित नहीं"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["राशि"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["राशि सीमा में नहीं है: "])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मिनट"])}
}