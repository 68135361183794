export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Беремін"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сома"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аламын"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сүзгілер"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жаңарту"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сатушы"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Беремін"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аламын"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шектеулер"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Әрекеттер"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["мәмілелер"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мәміле"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мәмілелер жоқ"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ұсыныстар"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пайда"])}
}