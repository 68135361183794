export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generování kódu/odkazu pro platbu"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Č. operace obchodu"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Částka"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentář"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Další parametr"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úspěšný URL"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neúspěšný URL"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL oznámení"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL, kam se klient vrátí po úspěšné platbě"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL, kam se klient vrátí po neúspěšné platbě"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL, kam bude odesláno oznámení o výsledcích platby"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixovat částku"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopírovat"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ověřit"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odkaz na platbu"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulář"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumentace"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doba platnosti účtu na platbu v minutách, výchozí 60"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doba platnosti odkazu"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odkaz"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulář"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Widget"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokud částku nezafixujete, bude ji moci plátce zadat samostatně (vhodné např. pro dobročinné platby)"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skrýt"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platební formulář"])}
}