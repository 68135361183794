export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кезеңі"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кезеңі"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Экспорт"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сүзгілер"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қалпына келтіру"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жаңарту"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сома"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакция"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Параметр"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Түрі"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакция"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Күні"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операция"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Корреспондент"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сома"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Параметрлер/Түсініктеме"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операциялар табылмады"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операция сомасы"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шотқа түсетін түсімдер:"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" дана, жалпы сомасы"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шығындар:"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операция түрлері"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Төлемдер, қызметтерді төлеу"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ішкі аударымдар"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шотты төлеу"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Өз шоттары бойынша аударымдар"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Криптоваучер жасау"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваучерді белсендіру"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Басқа"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кіріс/шығыс"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кіріс"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шығындар"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["= тең"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≠ тең емес"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["> артық"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≥ артық немесе тең"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["< кем"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≤ кем немесе тең"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сәтті аяқталды"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Өңделуде"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қате"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бүгін"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кеше"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Апта"])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["айы"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жыл"])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операция тарихы"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шотты толықтыру"])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шот таңдалмаған"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["қор биржасында айырбасы"])},
  "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адресті толықтыру"])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Өз әмияныңыздағы аударымдар"])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тілші"])},
  "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операция"])}
}