export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metody uzupełnienia"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kryptowaluta"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uzupełnienie przez P2P"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrola elektroniczna"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szablony uzupełniania"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metody wyjściowe"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["W kryptowalucie"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Katalog płatności"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Płatności masowe"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wniosek przez P2P"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szablony wniosków"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metody tłumaczenia"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wymiana na wymianę"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tłumaczenie na użytkownika"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przenieś według swoich kont"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szablony tłumaczeń"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uzupełnij wynik za pomocą Bitcoin, Tether USDT, Ethereum, Tron lub innych kryptowalut."])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wymiana bez pośredników: Tłumaczasz kryptowalutę, transfer bankowy lub przesyłanie gotówki na rzecz innego użytkownika i jest to przelew wewnętrzny do Ciebie."])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czy nabyłeś gdzieś elektroniczną kontrolę usługi? \nAktywuj go wkrótce i uzyskaj całą wartość nominalną na swój własny koszt. \nNawiasem mówiąc, możesz sprawdzić kontrolę bez aktywacji."])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uzupełnij wynik za pomocą wcześniej utworzonych szablonów. \nNawiasem mówiąc, szablony można tworzyć po transakcji lub z historii operacji."])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń fundusze na Bitcoin, Tether USDT, Ethereum, Tron lub inną kryptowalutę."])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapłać komunikację komórkową, mieszkanie wspólne lub inne usługi."])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrób dziesiątki, setki, a nawet tysiące operacji jednym kliknięciem. \nWystarczy pobrać plik z listą formatu Excel."])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wymiana bez pośredników: Tłumaczasz kryptowalutę, transfer bankowy lub przesyłanie gotówki na rzecz innego użytkownika i jest to przelew wewnętrzny do Ciebie."])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przypisz środki za pomocą wcześniej utworzonych szablonów. \nNawiasem mówiąc, szablony można tworzyć po transakcji lub z historii operacji."])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Możesz ustawić swój kurs i poczekać, aż kurs stanie się właśnie tym. \nLub szybko wymieniać się w obecnej stawce rynkowej."])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przenieś środki do innego użytkownika usług."])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Łatwo rozpowszechniać fundusze między kontami."])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szybko przenoszą środki za pomocą wcześniej utworzonych szablonów. \nNawiasem mówiąc, szablony można tworzyć po transakcji lub z historii operacji."])}
}