export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le paramétrage d'accès par adresse IP ne vous permettra pas (ou à un intrus) de travailler avec le système à partir d'une adresse qui n'est pas dans la liste des adresses autorisées."])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restriction d'accès au compte par adresse IP"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter des adresses"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse : "])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bloc d'adresses : "])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["à :"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre IP : "])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des adresses"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De l'adresse"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À l'adresse"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accès"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune adresse"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refuser"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoriser"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez l'adresse IP"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse IP incorrecte"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune adresse IP ou bloc d'adresses spécifié."])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune adresse IP autorisée pour l'autorisation. Vous ne pourrez pas accéder à votre compte."])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accès par IP"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de :"])}
}