export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emisión adicional"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criptoactivo"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta - fuente de cobertura"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta de débito"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emisión adicional"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentario"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emisión actual"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TOKEN"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cálculo de cobertura"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comisión"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comisión"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cálculo de cobertura"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vinculación"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datos básicos"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porcentaje de cobertura"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cobertura futura"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baja de cobertura"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad que estará en reserva después de emitir la emisión adicional."])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A descontar"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cobertura actual"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad que se deducirá de su cuenta después de emitir la emisión adicional."])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto de cobertura futura"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E — Monto de emisión actual"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I — Emisión adicional"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["K — Tasa de vinculación"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["P — Porcentaje de cobertura"])}
}