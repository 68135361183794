export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["डिजिटल पासपोर्ट"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अधिक जानकारी"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यूजर आईडी:"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पंजीकरण की तारीख:"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अंतिम प्रमाणीकरण:"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आईपी"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मेरे संसाधन"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खाते"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी देखें"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भरो"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["निकालो"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई खाते नहीं"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पुष्टि पासवर्ड"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ऑपरेशन की पुष्टि करने के लिए आपको एक पुष्टिकरण पासवर्ड बनाना होगा।"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टेम्प्लेट्स"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["राशि"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लोकप्रिय भुगतान"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समाचार"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["छोटा करें"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अगला..."])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई संसाधन नहीं"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बनाएँ"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बनाएं"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संतुलन"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["निकालना"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लबालब भरना"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अदला-बदली"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपयोगकर्ता को"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कुल मिलाकर संतुलन"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मुलाकात की जगह दिखाना"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["गैर-फ़िएट संतुलन"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फ़िएट संतुलन"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नई सूचना"])}
}