export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricevo"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtri"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiorna"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Venditore"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dai"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricevi"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limiti"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azioni"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["transazioni"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transazione"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna transazione"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offerte"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beneficio"])}
}