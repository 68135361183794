export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoryzacja"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login:"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hasło:"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapomniałeś hasła?"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pierwszy raz tutaj?"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz konto"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odzyskiwanie hasła"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon lub e-mail:"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odzyskaj"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odzyskiwanie hasła"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwisko:"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imię:"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drugie imię:"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data urodzenia:"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaloguj się"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wstecz"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontynuuj"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Znajdź"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Użytkownik znaleziony"])}
}