export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القوالب"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بحث"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["في المجموعة"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مجموعات الدفع"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المدفوعات"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مبلغ الدفع"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حساب التحويل"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المبلغ"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعليق"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القالب"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم القالب:"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ القالب"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحويل"])}
}