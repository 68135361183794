export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przekazywany"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otrzymywany"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aktywo"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["daję"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wewnętrzny"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["otrzymuję"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wewnętrzne aktywa"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktywa nie znalezione"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zewnętrzne aktywa"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zewnętrzny"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybrane:"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wewnętrzne"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zewnętrzne"])}
}