export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["给予"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["余额"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收到"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易对"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["账户"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易类型"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["市价交易 - 将使用执行订单时最优惠的汇率。注意，实际收到的金额可能会根据交易时的订单情况而变化，与计算的金额有所不同。"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["固定价格交易按照您指定的金额和汇率进行。将下一个订单。如果指定的汇率与市场汇率差异较大，订单完成可能需要较长时间。"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["汇率（预测）："])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["汇率"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["汇率未确定，"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["建议手动设置汇率"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金额"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全部使用"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["佣金"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["佣金"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["总计："])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["市价"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["（以最优对手价快速交易）"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["固定汇率"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["（按指定汇率下单）"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无匹配账户"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["汇率"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24小时变化"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24小时波动"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24小时交易量"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金额不能为零！"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["要查看汇总数据，请选择交易对"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不能交易相同的资产"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["另外"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["申请未包含在选择中直到"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["至"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["限制"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'汇率'字段必须有非零值"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["账户余额不足"])}
}