export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отдаваемый"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получаемый"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["актив"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["отдаю"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["внутренний"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["получаю"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Внутренние активы"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активы не найдены"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Внешние активы"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["внешний"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрано:"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Внутренние"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Внешние"])}
}