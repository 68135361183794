export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重复操作"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作历史"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["账户列表"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成功"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["错误"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未知错误"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["模板"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的资产"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有关新资产的信息"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["附加问题"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["链接到区块链"])}
}