export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar estado"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La solicitud de cobro ha sido enviada."])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicitud de cobro cancelada"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colección aprobada para ejecución"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El cobro lo realiza el cajero (el dinero se carga en casetes)"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Casetes entregados a los coleccionistas (dinero en camino)"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recogida de efectivo instalada en el cajero automático"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Como resultado de una falla, sobrecargado con otra colección."])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La recogida fue sustituida por otra recogida en un cajero automático (sigue en camino)"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se contabilizan los saldos de cobranza (la colección se envía al archivo)"])}
}