export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurar o acesso por endereço IP impedirá você (ou um invasor) de acessar o sistema a partir de um endereço não incluído na lista de endereços permitidos."])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restringir o acesso à conta por endereços IP"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar endereços"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereço: "])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bloco de endereços: "])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a:"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seu IP: "])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de endereços"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nº"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do endereço"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para endereço"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acesso"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ações"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excluir"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum endereço disponível"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvar"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proibir"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permitir"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digite o endereço IP"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereço IP inválido"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum endereço IP ou bloco de endereços especificado."])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum endereço IP permitido para autorização. Você não conseguirá acessar sua conta."])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acesso por IP"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de:"])}
}