export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["额外发行"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加密资产"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["账户 - 覆盖来源"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["借记账户"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["额外发行"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评论"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["继续"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当前发行量"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["代币"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["覆盖计算"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["佣金"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["佣金"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["覆盖计算"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["绑定"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["基本数据"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["覆盖百分比"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未来覆盖"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["覆盖注销"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["额外发行后将在储备中的金额。"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["待注销"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当前覆盖"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["额外发行后将从您的账户中扣除的金额。"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未来覆盖金额"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E — 当前发行金额"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I — 额外发行"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["K — 绑定率"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["P — 覆盖百分比"])}
}