export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Віддаю"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отримую"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коментар"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Причина"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скасувати угоду"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Підтвердити участь в угоді"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відмовитися від угоди"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продовжити час очікування"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скасування угоди"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус користувача в угоді не визначений. Щось пішло не так"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запросити арбітраж"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Погодитися на арбітраж"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повідомити про виконання переказу"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Підтвердити отримання переказу"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оцініть угоду"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відправити оцінку"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Угода №"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кореспондент"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Таймаут"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["сьогодні"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["завтра"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скасувати Арбітраж"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скасувати арбітраж"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви запросили арбітраж"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проти Вас запросили арбітраж"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Йде арбітраж (Ви – позивач)"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Йде арбітраж (Ви - відповідач)"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Після закінчення таймера згода на арбітраж відбудеться автоматично і ви вже не зможете додати відповіді на питання арбітражу"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вимагаючи арбітраж"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Є арбітраж"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Є арбітраж"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прийняти рішення про арбітраж: внутрішній платіж"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проводити"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скасувати"])}
}