export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["transacción"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parámetros generales"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambios en los saldos de las cuentas"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al final de la operación:"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al inicio de la operación:"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parámetros adicionales"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operaciones en blockchain"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Callback"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siguiente intento:"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hora"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respuesta"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protección de la transacción"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duración de la protección:"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hasta"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de protección:"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parámetros de la plantilla"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de la plantilla:"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar operación"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repetir operación"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar plantilla"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerrar"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de transacción"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operación"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corresponsal"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de pago"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comisión"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentario"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultado"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de formulario de pago"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingreso/Gasto"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambios en los saldos de las direcciones"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar crédito"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devolver dinero al remitente"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operación sospechosa"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opciones de acción:"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comisión del destinatario"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comisión del pagador"])}
}