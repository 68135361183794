export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pares de divisas"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear par de divisas"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar par de divisas"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar par de divisas"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acciones"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moneda dada"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moneda recibida"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasarela"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de actualización"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontraron pares de divisas"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Está seguro de que desea eliminar el par de divisas"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vinculado a otra moneda"])}
}