export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पता चुनें"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["निवास स्थान"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पता"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रद्द करें"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सहेजें"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["देश"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्षेत्र"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जिला"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["काउंटी"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शहर"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शहरी क्षेत्र"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["निवास स्थान"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सड़क"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पते का अतिरिक्त तत्व"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विवरण"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मकान"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पोस्टल कोड"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पता"])}
}