export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allgemeine Informationen"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsinformationen"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrollsignatur"])},
  "List": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Geschäfte"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Geschäfte gefunden"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Widget-Code erstellen"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungslink erstellen"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeiten"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschäft löschen"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie das Geschäft wirklich löschen"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschäft registrieren"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsmodus"])},
    "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivität"])},
    "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akzeptieren"])},
    "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
    "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktionen"])}
  },
  "New": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschäft registrieren"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein im System registriertes elektronisches Geschäft ermöglicht es, Zahlungen von Kunden über das Merchant-System zu akzeptieren."])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elektronische Geschäfte, Wechselstuben und andere Internetressourcen, die Zahlungen empfangen möchten, werden als Geschäfte registriert."])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name oder Marke"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL-Adresse des Geschäfts"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurze Beschreibung des Geschäfts"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dienstleistungstyp"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschäft ist aktiv"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geheimer Schlüssel"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatische Auszahlungen zulassen"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ergebnisse an das Skript senden"])},
    "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skript-Adresse"])},
    "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rückkehr nach erfolgreicher Zahlung"])},
    "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nach fehlgeschlagener Zahlung"])},
    "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
    "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generieren"])},
    "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindestens 30 Zeichen."])},
    "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wird verwendet, um die Integrität und Richtigkeit der von der Zahlungssystem übermittelten Daten (bei der Übermittlung des Benutzers durch das Geschäft an den Merchant) und durch das Geschäft (bei der Annahme der Zahlungsergebnisse) zu überprüfen"])},
    "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
    "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück"])},
    "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zur Liste der Geschäfte"])},
    "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falsche URL"])}
  },
  "Invoices": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnungsliste"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Rechnungen gefunden"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betrag"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschäft"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichernummer"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Rechnung stornieren"])},
    "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungscode speichern"])}
  }
}