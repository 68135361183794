export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التسجيل"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحساب الشخصي"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حساب الأعمال"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اللقب:"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم:"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الأب: (إذا كان موجودًا)"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأحرف اللاتينية، الواصلات والأبوسروفات مسموح بها."])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bond"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأحرف اللاتينية، الواصلات، الفراغات والأبوسروفات مسموح بها."])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["James"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["None"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأحرف اللاتينية، الواصلات، الاقتباسات، الفراغات، الأرقام والأبوسروفات مسموح بها."])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"My Company\" Ltd"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الهاتف"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البريد الإلكتروني"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأحرف اللاتينية (الحروف الكبيرة والصغيرة)، الأرقام، الرموز _ - . ! $ % ^ * = مسموح بها"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تكرار كلمة المرور"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمات المرور غير متطابقة"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عودة"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التسجيل"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التالي"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحالة"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات المستخدم"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجى تقديم عنوان بريد إلكتروني أو رقم هاتف صحيح. يجب إدخال رقم الهاتف بالتنسيق الدولي، على سبيل المثال: +499001234567."])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم التسجيل بنجاح"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكنك الآن"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الدخول إلى حسابك الشخصي"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سيتم مراجعة حسابك من قبل موظفينا، وسنبلغك بنتائج التفعيل لاحقًا"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم إرسال رسالة إلى بريدك الإلكتروني تحتوي على رابط لتأكيد التسجيل"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلى عنوان بريدك الإلكتروني"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بعد تسجيل الدخول الأول، سيُطلب منك تأكيد رقم هاتفك"])}
}