export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annonce"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limite de montant"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps d'attente (min)"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annonce active"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponible uniquement par lien"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponible uniquement pour les utilisateurs vérifiés"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])}
}