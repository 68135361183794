export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notification settings"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turn on"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notify about transactions"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authorization (login to personal account, mobile application, etc.)"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notify when \"Performing financial transactions using a card\" event"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expenses (any payments, transfers, payment for services, etc.)"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cash inflow"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receiving a letter by internal mail"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice receipt for payment"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changing security settings"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm operations with a code from the message"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm authorization with the code from the message"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm when \"Performing financial transactions using the card\" event"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm changes to security settings with a code from a message"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm expenses with the code from the message"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changes not saved, save?"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do not save"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])}
}