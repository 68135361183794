export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التوثيق"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بروتوكول التاجر"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعادة التوجيه إلى التاجر"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العودة إلى المتجر"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إشعار المتجر"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حساب التوقيع"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القطعة المضمنة"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التثبيت"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاتصال"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرد من المتجر"])}
}