export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шот"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қалдық"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қосымша ақпарат"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Әрекеттер"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Белсенді"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кіру"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шығу"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жаңа адрес жасау"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жаңа шот жасау"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Таңдалғандарды жою"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Менің активтерім"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шоттар тізімі"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Барлығы"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шот атауы"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шоттарды шынымен жойғыңыз келе ме?"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шотты шынымен жойғыңыз келе ме"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активтер табылмады"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шоттар табылмады"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес атауын өзгерту"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрестерді шынымен жойғыңыз келе ме?"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес шынымен жойғыңыз келе ме"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiat"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операциялар"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Әрекеттер"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Толықтыру"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Алып тастау"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Алмасу"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жасыру"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Өзгерту"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жою"])}
}