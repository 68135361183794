export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адреса"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рахунок"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Залишок"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додаткова інформація"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дії"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активний"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введення"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виведення"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створити нову адресу"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створити новий рахунок"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити обрані"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мої активи"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список рахунків"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Усі"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва рахунку"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви дійсно хочете видалити рахунки?"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви дійсно хочете видалити рахунок?"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активів не знайдено"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рахунків не знайдено"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перейменування адреси"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви дійсно хочете видалити адреси?"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви дійсно хочете видалити адресу?"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фіатні"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операції"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дії"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поповнити"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вивести"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обміняти"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приховати"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Змінити"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити"])}
}