export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Požadavek obchodu"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nabídka"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prodávající"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timeout"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čekací doba akcí stran"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurz"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dáváte"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostáváte"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Požádat o obchod"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořit účet"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Účet není vybrán"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Částka"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Částka nespadá do rozmezí:"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["min"])}
}