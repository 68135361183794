export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم:"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم هو الاسم الكامل للأصل المشفر، كما سيراه الجميع: المُصدر نفسه، والمشترين، والبائعين، والمعالجة، وما إلى ذلك."])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رمز"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختصار من 3 إلى 10 أحرف. \nيُسمح باستخدام الأحرف اللاتينية الكبيرة والواصلات (الشرطات) والأرقام."])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفئة:"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر الفئة التي تناسب أصلك المشفر"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التنسيقات المدعومة: png، jpg، jpeg، svg. \nحجم الملف لا يزيد عن 1 ميجا بايت، 500*500 بكسل"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اشرح لماذا يتم إصدار هذا الأصل المشفر من قبل المصدر، في أي مجال يعمل أو سيعمل، وما هي مهمته"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشعار:"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الهدف / المهمة / الغرض:"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدقة:"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإصدار:"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قيمة الوحدة:"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الخانات العشرية للمبلغ. على سبيل المثال، دقة معظم العملات الورقية مثل الدولار الأمريكي أو اليورو تساوي 2."])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الوحدات من الأصل المشفر الذي يتم إصداره. سيكون المبلغ المصدر متاحًا على حسابك فور إكمال عملية إنشاء الأصل المشفر."])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القيمة المعلنة للوحدة:"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الضمان:"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحساب:"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشخص الملزم:"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قرار إصدار الأصل المشفر:"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تصدير العمليات إلى البلوكشين:"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع البلوكشين:"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تمكين العمليات بالأصل المشفر:"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القيود/العقوبات:"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حدد الدول أو المقيمين الضريبيين من القائمة المحظور عليهم استخدام الأصل المشفر في تعاملاتهم:"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القيود والشروط الأخرى:"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان الاستضافة:"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان الورقة البيضاء:"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البريد الإلكتروني:"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telegram:"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twitter:"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook:"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جهات اتصال أخرى:"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السابقة"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التالي"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء أصل مشفر"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم والغرض"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الخصائص المالية"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المصدر"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التصدير إلى البلوكشين"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحدود والقيود"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جهات الاتصال"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غير محدد"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ربط ثابت بالعملات الورقية والأصول الأخرى"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المصدر (الشخص الذي يصدر الأصل المشفر)"])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المقدم (الشخص الذي يوزع الأصل المشفر)"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المرسل"])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المستلم"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أنا (تمكين الرعاية)"])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["توزيع الأصل المشفر"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأصل المشفر يعني التمثيل الرقمي للقيمة أو الحق الذي يمكن نقله وتخزينه إلكترونيًا باستخدام تقنية البلوكشين. الأصول المشفرة لا تشمل العملات الورقية، الأوراق المالية والأدوات المالية الأخرى التي تخضع لتنظيم حكومي منفصل."])},
  "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحرير أصول التشفير"])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إدارة"])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قبول"])},
  "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سبب رفض القبول"])},
  "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حساب مقدم العرض"])},
  "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حساب مقدم العرض بالعملة الملزمة"])},
  "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مقدم العرض هو ممثل لمصدر الأصل في معاملات الشراء/البيع في السوق الأولية. \nالحساب سلبي. \nيمكن أن يكون حساب إصدار الأصول"])},
  "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحساب سلبي، مرتبط بالعملة. \nيجب أن ينتمي إلى نفس المستخدم مثل الحساب السابق"])},
  "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السماح للمستخدمين بشراء الأصول من أجهزة الصراف الآلي"])},
  "62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السماح ببيع الأصول من قبل المستخدمين في أجهزة الصراف الآلي"])},
  "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نموذج شراء ما بعد البيع"])},
  "64": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تتم معاملات السوق الثانوية من خلال البوابات"])},
  "65": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نموذج مبيعات ما بعد البيع"])},
  "66": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تتم معاملات السوق الثانوية من خلال البوابات"])},
  "67": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السماح بالمعاملات مع الأصول في البورصة"])},
  "68": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السماح بمعاملات الأصول عبر P2P"])},
  "69": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إذا كنت رائد أعمال فرديًا أو ممثلًا لكيان قانوني، فأنت بحاجة إلى تنزيل ملف القرار بشأن إصدار الأصل الرقمي بتنسيق PDF. \nيجب أن يحتوي الحل على جميع الخصائص الرئيسية للأصل الافتراضي وأن يتم توقيعه بتوقيع إلكتروني لرجل الأعمال الفردي أو هذا الكيان القانوني. \nيمكن العثور على مثال لقرار إصدار أصل رقمي على صفحات موقعنا في قسم \\\"المستندات\\\""])},
  "70": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وضع الأصول الافتراضية"])},
  "71": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حساب الإصدار البديل"])},
  "72": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر من حسابات المصدر"])},
  "73": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المبلغ المطلوب شطبه:"])},
  "74": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد حساب"])},
  "75": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء حساب"])},
  "76": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأعلى. \nالانبعاثات:"])},
  "77": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يتجاوز حجم الانبعاثات الحد الأقصى. \nالانبعاثات"])},
  "78": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأعلى. \nالانبعاث أقل من حجم الانبعاث أو يساوي الصفر"])},
  "79": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النماذج غائبة"])}
}