export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zarejestruj sklep"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zarejestrowany w systemie sklep elektroniczny umożliwia przyjmowanie płatności od klientów przez merchant system."])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jako sklep rejestrują się sklepy elektroniczne, punkty wymiany i wszelkie inne zasoby internetowe chcące przyjmować płatności na swoje konto."])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa lub marka"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres URL sklepu"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krótki opis sklepu"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ serwisu"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sklep aktywny"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klucz tajny"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zezwól na automatyczne wypłaty"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wysyłaj wyniki na skrypt"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres skryptu"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powrót po udanej płatności"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Po NIEudanej płatności"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generuj"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Co najmniej 30 znaków."])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Używany do sprawdzania integralności i wiarygodności przesyłanych danych przez system płatności (przy wysyłaniu użytkownika na merchant) i sklep (przy otrzymywaniu wyników płatności)"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dalej"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wstecz"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do listy sklepów"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niepoprawny adres URL"])}
}