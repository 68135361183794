export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ATM"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clicca per espandere la tabella a schermo intero"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reimposta"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiorna"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attività"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sottorivenditori"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attivo"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non attivo"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea un bancomat"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica criptomat"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni principali"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Principale"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orari di apertura"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrizione del luogo di installazione"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orario normale"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orario non normale"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orario di lavoro"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lunedì"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Martedì"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mercoledì"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giovedì"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Venerdì"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sabato"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domenica"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pausa pranzo"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ripeti password"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizza account esistenti"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea nuovi account"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni principali"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conto"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orario di lavoro (informazione di riferimento)"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attività"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azioni"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna macchina crittografica trovata"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero del bancomat"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["latitudine"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["longitudine"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linea di assistenza"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID del sub-rivenditore"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conti"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controllo"])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valuta"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conto di transito"])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creazione di un crittografo"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificare un crittografo"])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SÌ"])},
  "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vietare"])},
  "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permettere"])},
  "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elenco dei collezionisti"])},
  "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi un collezionista"])},
  "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossibile accedere ai saldi di riscossione: nessun dato"])},
  "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elenco dei collezionisti"])},
  "62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi un collezionista"])},
  "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consentito"])},
  "65": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accettazione"])},
  "67": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo"])},
  "68": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica bancomat"])},
  "69": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizza i saldi attuali della riscossione"])},
  "70": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizza i saldi di riscossione scaricati"])},
  "71": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice PIN"])},
  "73": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se non specifichi un sottorivenditore, il bancomat sarà collegato a te"])},
  "74": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latitudine e longitudine sono coordinate geografiche che possono essere copiate, ad esempio, da Google Maps inserendo l'indirizzo del bancomat"])},
  "75": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posizione di installazione"])},
  "76": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["È necessario selezionare almeno una colonna per visualizzare la tabella"])},
  "77": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva"])},
  "78": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creare"])},
  "79": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vai ai dispositivi ATM"])}
}