export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактировать внешний актив"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Группа"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Синонимы"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Страны"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Привязать к активу"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Единицы измерения"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сокращенно"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Точность"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активен"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Акцептирован"])}
}