export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorización"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuario:"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña:"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Olvidaste tu contraseña?"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Eres nuevo aquí?"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear cuenta"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recuperación de contraseña"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teléfono o correo electrónico:"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recuperar"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recuperación de contraseña"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apellido:"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre:"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segundo nombre:"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de nacimiento:"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar sesión"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atrás"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuario encontrado"])}
}