export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuring access by IP address will not allow you (or an intruder) to work with the system from an address that is not registered in the list of allowed."])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restriction of access to the cabinet by IP addresses"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add addresses"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address: "])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address block: "])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to:"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Own IP:"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address list"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From address"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To address"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No addresses"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forbid"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allow"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter IP address"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incorrect IP address entered"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No IP address or block of addresses specified."])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No IP address is allowed for authorization. You will not be able to access your account."])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP Access"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["from:"])}
}