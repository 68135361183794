<template>
    <div class="light">
        <div class='d-flex justify-content-between neue'>
            <h2 id="c-m-hp-1">{{this.content[1].tl_code == '-' ? this.$t('components.service.modal.History.33') : this.content[1].typelocal}}, {{ $t('components.service.modal.History.1') }} {{this.content[1].transact}}</h2>
            <i id="c-m-hp-2" class='big item-i x_red' v-on:click='this.close'></i>
        </div>
        <div class='container-fluid mt-1 p-0 g-3'>
            <h4 class='p-0 mt-4 color-menu'>{{ $t('components.service.modal.History.2') }}</h4>
            <div class='d-flex p-0 container-fluid align-items-start justify-content-between'
            v-for="(row, index) in this.main_content" :key="index">
                <div v-if='index != "0"' class='col-4 p-0 grey' :id="'c-m-hp-i-1-'+index">{{row.param}}:</div>
                <div v-if='index != "0"' class='col-7 p-0' :id="'c-m-hp-i-2-'+index" :class="{'neue-bold':index == '1' || index == '3' }">{{row.value}}</div>
            </div>

            <div v-if="this.content[1]['done'] === '1'">
                <h4 v-if="this.k_name" class='p-0 mt-4 color-menu'>{{ $t('components.service.modal.History.34') }} </h4>
                <h4 v-else class='p-0 mt-4 color-menu'>{{ $t('components.service.modal.History.3') }} </h4>
                <div v-for="(row, index) in this.acc_balnace" :key="index" class='d-flex p-0 container-fluid align-items-center justify-content-between'>
                    <div v-if="this.k_name" class='col-4 p-0 grey' :id="'c-m-hp-i-3-'+index">{{ this.getAlias(row.keyt) }}
                         <!-- {{ row.curr_name }} -->
                    </div>
                    <div v-else class='col-4 p-0 grey' :id="'c-m-hp-i-4-'+index">{{ row.keyt }} 
                        <!-- {{ row.curr_name }} -->
                    </div>
                    <div class='col-7 p-0' :id="'c-m-hp-i-5-'+index">{{ row.balance_before }} -->  {{ row.balance_after }} {{ row.curr_name }}</div>
                </div>
            </div>

            <h4 v-if="this.more_opt.length" class='p-0 mt-4 color-menu'>{{ $t('components.service.modal.History.6') }}</h4>
            <div class='d-flex p-0 container-fluid align-items-start justify-content-between'
            v-for="(row, index) in this.more_opt" :key="index">
                <div class='col-4 p-0 grey' :id="'c-m-hp-i-6-'+index">{{row.param}}:</div>
                <div v-if="row.human_value" class='col-7 p-0' :id="'c-m-hp-i-7-'+index">{{row.human_value}}</div>
                <div v-else class='col-7 p-0' :id="'c-m-hp-i-8-'+index">{{row.value}}</div>
            </div>

            <div v-if="this.exp_post.length">
                <h4 class='p-0 mt-4 color-menu'>{{ $t('components.service.modal.History.7') }}</h4>
                    <div class='d-flex p-0 container-fluid align-items-center justify-content-between'
                    v-for="(row, index) in this.exp_post" :key="index">
                        <div class='col-4 p-0 grey' :id="'c-m-hp-i-9-'+index">{{row.purpose}}:</div>
                        <div class="p-0 col-7">
                            <div class="table-responsive p-0 m-0">
                                <table class="table w-unset table-borderless neue-roman m-0">
                                    <tbody class=''>
                                        <tr>
                                            <td class="align-middle p-0 light n_wrap" scope="col" :id="'c-m-hp-i-10-'+index">{{row.amount}} {{this.content[1]['curr']}}</td>
                                            <td class="align-middle p-0 light fs_08 pl-1" scope="col" :id="'c-m-hp-i-11-'+index">
                                                {{this.substrHash(row.hash)}}
                                                <input type='hidden' :id='"_h" + index' :value="row.hash"/>
                                            </td>
                                            <td class="align-middle p-0 d-flex align-items-center justify-content-start pl-1" scope="col">
                                                <i v-if='row.hash' :id="'c-m-hp-i-12-'+index" class='item-i copy c18 cli_h pointer' :data-clipboard-target="'#_h' + index"></i>
                                                <a v-if='row.explorer_link' :id="'c-m-hp-i-13-'+index" :href="row.explorer_link" target="_blank"><i class='item-i c18 ml-2 ext-link'></i></a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
            </div>
            <div v-if='this.content[1].need_pay_inflow_finish == 1' class='container-fluid mt-1 p-0 g-3'>
                <h4 class='p-0 mt-4 color-menu'>{{ $t('components.service.modal.History.37') }}</h4>
                <div class='d-flex p-0 container-fluid align-items-center justify-content-between'>
                    <div class='col-4 p-0 grey'>{{ $t('components.service.modal.History.38') }}</div>
                        <div class='d-flex col-7 p-0 justify-content-start align-items-center flex-wrap'>
                            <Button id="c-m-hp-3" :name="$t('components.service.modal.History.35')" class='mr-2' type='btn-warning btn-sm' v-on:click='this.payInflowFinish("accept")'/>
                            <Button id="c-m-hp-4" :name="$t('components.service.modal.History.36')" class='mr-2' type='btn-outline-warning btn-sm' v-on:click='this.payInflowFinish("decline ")'/>
                        </div>
                </div>
            </div>

            <div class="m-0 p-0" v-if="this.content[1]['callback_request']">
                <h4 v-if="this.more_opt.length" class='p-0 mt-4 color-menu'>{{ $t('components.service.modal.History.8') }}</h4>
                <div class='d-flex href p-0 container-fluid align-items-start justify-content-start'>
                    <span class="w_warp" id="c-m-hp-5">{{this.content[1]['callback_request']}}</span>
                </div>
                <div class='d-flex p-0 mt-2 flex-row container-fluid align-items-center justify-content-between' v-if="this.content[1]['callback_status']">
                    <i class='item-i mr-1 c28 contain' :class="{'check': this.content[1]['callback_status'] == '30',
                        'x_red': this.content[1]['callback_status'] == '40',
                        'clock': this.content[1]['callback_status'] == '10' || this.content[1]['callback_status'] == '20'}"></i>
                    <div class='col-12 p-0' id="c-m-hp-6" :class="{'color-green': this.content[1]['callback_status'] == '31',
                        'color-warning': this.content[1]['callback_status'] == '40',
                        'color-acent': this.content[1]['callback_status'] == '10' || this.content[1]['callback_status'] == '20'}">
                        {{this.content[1]['callback_status_text']}}
                    </div>
                </div>
                <div class='d-flex p-0 mt-2 container-fluid align-items-center justify-content-between' v-if="this.content[1]['callback_status'] == '10' || this.content[1]['callback_status'] == '20'">
                    <div class='col-4 p-0 grey'>{{ $t('components.service.modal.History.9') }}</div>
                    <div class='col-7 p-0' id="c-m-hp-7">
                        {{this.content[1]['callback_next_try_date']}}
                    </div>
                </div>
                <div class="table-responsive p-0 m-0" v-if="this.content[0][4]['colvalues'].length > 0">
                    <table class="table table-borderless table-striped neue-roman">
                        <thead class='t-head-custom'>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col fs_08">{{ $t('components.service.modal.History.10') }}</th>
                                <th scope="col fs_08">{{ $t('components.service.modal.History.11') }}</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody class='t-body-custom'>
                            <tr v-for="(row, index) in this.content[0][4]['colvalues']" :key="index"> 
                                <td class="align-middle" scope="col" :id="'c-m-hp-i-16-'+index">{{row.code}}</td>
                                <td class="align-middle" scope="col" :id="'c-m-hp-i-17-'+index">
                                    <div class="d-flex align-items-start flex-column justify-content-between fs_08">
                                        <div>
                                            <span>&#8594; </span>
                                            <span :id="'c-m-hp-i-14-'+index"> {{row.dt_request}}</span>
                                        </div>
                                        <div>
                                            <span>&#8592; </span>
                                            <span :id="'c-m-hp-i-15-'+index"> {{row.dt_response}}</span>
                                        </div>
                                    </div>
                                    {{row.date}}
                                </td>
                                <td class="align-middle" scope="col fs_08">{{row.response}}</td>
                                <td class="align-middle" scope="col">
                                    <i class='item-i' :class="{'check': row.success == '1',
                                        'x_red': row.success != '1'}"></i>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div v-if='this.content[1]["temp_block"] != "0" && (this.content[1]["done"] == "0" || this.content[1]["done"] == "4")'>
                <h4 class='p-0 mt-4 color-menu'>{{ $t('components.service.modal.History.12') }}</h4>
                <div class='d-flex p-0 container-fluid align-items-start justify-content-between'>
                    <div class='col-4 p-0 grey'>{{ $t('components.service.modal.History.13') }}</div>
                    <div class='col-7 p-0' id="c-m-hp-8">{{ $t('components.service.modal.History.14') }} {{this.content[1]['date_out_max']}}</div>
                </div>
                <div class='d-flex p-0 container-fluid align-items-center justify-content-between'>
                    <div class='col-4 p-0 grey mt-2'>{{ $t('components.service.modal.History.15') }}</div>
                    <div class='d-flex flex-column flex-sm-row col-7 col-sm-7 p-0 align-items-center justify-content-start'>
                        <div class='col-12 col-sm-6 col-md-7 mt-2 pr-1 no-pr'>
                            <InputSimple id="protect_code"  @updateParentState="this.updateState"/>
                        </div>
                        <Button id="c-m-hp-9" class='col-12 col-sm-5 col-md-4 mt-2' :name="$t('components.service.modal.History.16')" type='btn-outline-warning' v-on:click='this.payProtect'/>
                    </div> 
                </div>
            </div>
        </div>
        <div
            class="mt-3"
            v-if="(this.content[1]['code_cmd'] == 'pay_momental' || 
                this.content[1]['code_cmd'] == 'pay_from_gate' || 
                this.content[1]['code_cmd'] == 'pay_id' || 
                this.content[1]['code_cmd'] == 'merchant_purchase') &&
                this.content[1].tl_code !== '2'"
            @click='() => {this.save_t = !this.save_t}'
        >
            <span class="link-border color-menu pointer">{{ $t('components.service.modal.History.22') }}</span>
        </div>
        <div v-if='this.save_t' class="mt-3">
            <div class='d-flex p-0 pr-0 container-fluid align-items-center justify-content-between'>
                <div class='col-sm-4 p-0 grey'>{{ $t('components.service.modal.History.18') }}</div>
                <div class='col-sm-7 p-0 mt-1 d-flex container-fluid align-items-center justify-content-between'>
                    <div class="w-100 p-0 pr-1">
                        <InputSimple id="template_name"  @updateParentState="this.updateState"/>
                    </div>
                    <div class="p-0 d-flex justify-content-end">
                        <Button id="c-m-hp-10" :name="$t('components.service.modal.History.19')" type='btn-warning' v-on:click='this.saveTemplate'/>
                    </div>
                </div>
            </div>
        </div>
        <div class='d-flex mx-auto justify-content-end flex-wrap pt-3'>
            <Button
                id="c-m-hp-11"
                v-if='+this.content[1].repealing == 1'
                :name="$t('components.service.modal.History.20')"
                class='ml-2'
                type='btn-outline-warning'
                @click='this.cancelPayment'
            />
            <Button
                id="c-m-hp-12"
                v-if="(this.content[1]['code_cmd'] == 'pay_momental' ||
                    this.content[1]['code_cmd'] == 'pay_from_gate' ||
                    this.content[1]['code_cmd'] == 'pay_id' ||
                    this.content[1]['code_cmd'] == 'merchant_purchase') &&
                    this.content[1].tl_code !== '2'"
                :name="$t('components.service.modal.History.21')"
                class='ml-2'
                type='btn-outline-warning'
                @click='this.repeatOperation'
            />
        </div>
        
    </div>
</template>

<script>
import Button from '@/components/service/Button';
import InputSimple from '@/components/service/InputSimple';
import lib from '@/includes/lib.js';
import ClipboardJS from 'clipboard';

export default {
    components: {
        Button,
        InputSimple
    },
    props: {
        close: [Function],
    },
    data () {
        return {
            content: this.$store.state.modal.content,
            main_content: [],
            data: {
                template_name: '',
                protect_code: ''
            },
            more_opt: [],
            gen_opt: [],
            exp_post: [],
            acc_balnace: [],
            save_t: false,
            k_name: this.$store.state.config["KEYT_ALTERNAME_TOKEN"],
        }
    },
    created() {
        console.log(this.content);
        this.setMain();
    },
    mounted() {
        new ClipboardJS('.cli_h');
    },
    beforeUnmount() {

    },
    methods: {
        updateState(state) {
            this.data[state.id] = state.value;
        },
        substrHash(str) {
            if (str && str.length > 16) {
                return str.substr(0,8) + '...' + str.substr(str.length - 8, str.length);
            } else {
                return '';
            }
        },
        setMain() {
            this.content[0].forEach(e => {
                if (e.name == 'more_options') {
                    this.more_opt = e.colvalues;
                } else if (e.name == 'general_options') {
                    this.gen_opt = e.colvalues;
                } else if (e.name == 'export_postings') {
                    this.exp_post = e.colvalues;
                } else if (e.name == 'account_balances') {
                    this.acc_balnace = e.colvalues;
                } else {
                    // console.log(e)
                }
            })
            let o = [];
            o.push({param: this.$t('components.service.modal.History.24'), value: this.content[1]['transact']});
            o.push({param: this.$t('components.service.modal.History.25'), value: this.content[1]['code_text']});
            o.push({param: this.$t('components.service.modal.History.27'), value: this.content[1]['date']});
            o.push({param: this.$t('components.service.modal.History.28'), value: this.content[1]['itogo'] + " " + this.content[1]['curr']});
            if(+this.content[1]['id_corr'] == this.$store.state.userData.id) {
                o.push({param: this.$t('components.service.modal.History.40'), value: this.content[1]['comiss_shop'] + " " + this.content[1]['curr']});
                o.push({param: this.$t('components.service.modal.History.39'), value: this.content[1]['comiss_pay'] + " " + this.content[1]['curr']});
            } else {
                o.push({param: this.$t('components.service.modal.History.29'), value: this.content[1]['comiss'] + " " + this.content[1]['curr']});
            }
            if (this.content[1]['code_cmd'] == 'create_echeck') {
                o.push({param: this.$t('components.service.modal.History.26'), value: this.content[1]['name_corr']});
            } else if (this.content[1]['id_corr']) {
                o.push({param: this.$t('components.service.modal.History.26'), value: this.content[1]['id_corr']});
            }
            o.push({param: this.$t('components.service.modal.History.30'), value: this.content[1]['comment'].replace(/(\S{30})/g, "$1<br>")});
            o.push({param: this.$t('components.service.modal.History.31'), value: this.content[1]['result_text']});
            if (this.content[1]['code_cmd'] == 'pay_momental' || this.content[1]['code_cmd'] == 'pay_from_gate' || this.content[1]['code_cmd'] == 'pay_id' || this.content[1]['code_cmd'] == 'merchant_purchase') {
                o.push({param: this.$t('components.service.modal.History.32'), value: this.content[1]["subj_param"]});
            }
            this.main_content = o;
        },
        cancelPayment() {
            this.$store.commit('MODAL', {
                action: true,
                type: 'pay_pass',
                content: {callback: () => {
                    this.axios.post('CancelPayment', {data: {'transact': this.content[1].transact}})}
                },
            });
        },
        saveTemplate() {
            let keyt = this.$store.getters.get_keyts;
            let precision = 2;
            keyt.forEach(e => {
                if (e.keyt == this.content[1]['keyt']) {
                    precision = e.precision;
                } 
            })
            if (this.content[1]['code_cmd'] == 'pay_id') {
		        let params = {};
	            params['keyt'] = this.content[1]['keyt'];
	            params['corr_keyt'] = this.content[1]['keyt_shop'];
	            params['corr_id'] = this.content[1]['id_corr'];
	            //params['corr_name'] = this.content[1]['name_corr'];
	            params['curr'] = this.content[1]['curr'];
	            params['template_name'] = this.data.template_name;
	            params['comment'] = this.content[1]['comment'];
                params['sum'] = this.$lib.precRound(this, Number(this.content[1]['itogo']) - Number(this.content[1]['comiss']), Number(precision));
                console.log('saveTemplate params["sum"]', params['sum']);
	            // params['sum'] = this.content[1]['itogo'];
                this.axios.post('CreateTransferTemplate', {data: params})
            } else {
		        let params = {} ;
		        params['template_name'] = this.data.template_name;
		        params['comment'] = this.content[1]['comment'];
		        params['payform'] = this.content[1]["subj_param"];
                params['sum'] = this.$lib.precRound(this, Number(this.content[1]['itogo']) - Number(this.content[1]['comiss']), Number(precision));
		        // params['sum'] = ((+this.content[1]['itogo'].split(' ')[0]).toFixed(precision) - (+this.content[1]['comiss'].split(' ')[0]).toFixed(precision)).toFixed(precision);
		        params['keyt'] = this.content[1]['keyt'];

		        var fields = this.more_opt;
		        for (let i = 1, il = fields.length; i <= il; i++) {
			        params[i] = fields[i - 1] ? fields[i - 1].value : '';
		        }
                this.axios.post('SaveCatalogTemplate', {data: params});	        
            }

            this.save_t = !this.save_t;
        },
        repeatOperation(){
            let adv = this.content[1];
            let vals = this.content[0];
            if (adv.code_cmd === 'pay_id') {
                let params = {
                    id_corr: adv.id_corr,
                    summ: adv.itogo,
                    comment: adv.comment,
                    keyt: adv.keyt,
                };
                this.$router.push({name: 'Transfer_id', query: params})
            } else if (adv.code_cmd === 'pay_momental') {
                let params = {
                    payform: adv.subj_param,
                    summ: adv.itogo,
                    comment: adv.comment,
                    keyt: adv.keyt,
                    grp: this.more_opt[0]['field_group'],
                    fields: JSON.stringify(this.more_opt),
                    name: adv.name_corr,
                };
                this.$router.push({name: 'Pay', query: params})
            } else if (adv.code_cmd === 'merchant_purchase' || adv.code_cmd === 'merchant_purchase_pretransact' || adv.code_cmd === 'merchant_semiauto' || adv.code_cmd === 'merchant' || adv.code_cmd == 'pay_from_gate') {
                let params = {
                    payform: adv.subj_param,
                    keyt: adv.keyt,
                    fields: JSON.stringify(this.more_opt), 
                };
                this.$router.push({name: 'Payin', query: params})
            }
            this.close();
        },
        payProtect() {
            let data = {
                transact_num: this.content[1]['transact'],
	            protect_code: this.data.protect_code,
            }
            this.$store.commit('MODAL', {
                action: true,
                type: 'pay_pass',
                content: {callback: () => {
                    this.axios.post('EndPayProtect', {data: data}).then(res => {
                        this.content.update();
                    })
                }},
            });
        },
        payInflowFinish (operationType) {
            console.log(operationType)
            this.$store.commit('MODAL', {
                action: true,
                type: 'pay_pass',
                content: {callback: () => {
                    this.axios.post('PayInflowFinish ', {data: {'transact': this.content[1].transact, 'operation': operationType}})
                    .then(res => {
                        this.content.update();
                    })}
                },
            });
        },
        getAlias(keyt) {
            let k = this.$store.getters.get_keyts;
            let a = false;
            k.forEach(e => {
                if (e.keyt == keyt) {
                    if (e.alias) {
                        a = e.alias;
                    }
                }
            })
            return a ? a : keyt;
        }
    },
}
</script>

<style lang="scss" scoped>
    h4 {
        font-family: 'HelveticaNeue' !important;
    }
    .big {
        background-size: contain;
        cursor: pointer;
    }

    @media (max-width: 575px) {
        .no-pr {
            padding-right: 0 !important;
        }
    }
    .w_warp {
        word-break: break-all;
    }
    .n_wrap {
        white-space: nowrap;
    }
    .w-unset {
        width: unset;
    }
    .link-border {
        padding-bottom: 2px;
        border-bottom: 1px solid $color-menu;
    }
</style>