export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать валютную пару"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Валютная пара"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройка обновления"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шлюз"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отдаваемая валюта"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получаемая валюта"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавлять в курс прибыль, %"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип обновления курса"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Срок жизни курса, секунд"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Интервал обновления курса, секунд"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курс"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На какую дату курс актуален"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Алиас отдаваемой валюты"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Алиас получаемой валюты"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шлюз"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нужно обязательно выбрать валюту и притом только одну: отдаваемую или получаемую"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактировать валютную пару"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма отправки * Курс = Сумма получения"])}
}