export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Объявление"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Описание"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Реквизиты"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Минимальная"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Максимальная"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лимит суммы"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Время ожидания (мин)"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Объявление активно"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступно только по ссылке"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступно только верифицированным пользователям"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])}
}