export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meus lojas"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lojas não encontradas"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar código de widget"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar link de pagamento"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excluir loja"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você realmente deseja excluir a loja"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrar loja"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modo de pagamento"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atividade"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceitação"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ações"])}
}