export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفترة من"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفترة إلى"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تصدير"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مرشحات"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعادة ضبط"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحديث"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المبلغ"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المعاملة"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المعلمة"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النوع"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المعاملة"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التاريخ"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العملية"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المراسل"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المبلغ"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحالة"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المعلمات/التعليق"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم يتم العثور على عمليات"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إجمالي العمليات"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإيرادات:"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" وحدة بمبلغ"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النفقات:"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أنواع العمليات"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المدفوعات، دفع الخدمات"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التحويلات الداخلية"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دفع الفاتورة"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التحويلات بين الحسابات الخاصة"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء شيكات العملة المشفرة"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تنشيط شيكات العملة المشفرة"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أخرى"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إيرادات/نفقات"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإيرادات"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النفقات"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["= يساوي"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≠ لا يساوي"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["> أكبر"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≥ أكبر أو يساوي"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["< أقل"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≤ أقل أو يساوي"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحالة"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اكتملت بنجاح"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قيد المعالجة"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطأ"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اليوم"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أمس"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأسبوع"])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشهر"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السنة"])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ العمليات"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعبئة الحساب"])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم يتم اختيار الحساب"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تبادل في البورصة"])},
  "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعبئة العنوان"])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التحويلات داخل المحفظة"])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مراسل"])},
  "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عملية"])}
}