export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumentace"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protokol obchodníka"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přesměrování na obchodníka"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Návrat do obchodu"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oznámení obchodu"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výpočet podpisu"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vložený widget"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instalace"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volání"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odpověď z obchodu"])}
}