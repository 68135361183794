export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["allineare fino a 255 caratteri"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["intero"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["20 cifre"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0 o 1"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["numero frazionario, precisione due cifre, separatore di punti"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["intero"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["stringa fino a 255 caratteri, opzionale"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["intero, facoltativo"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["stringa di 32 caratteri"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archiviare il codice di pagamento; dovrebbe essere unico per ogni pagamento quando si trasmette una stringa"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["un numero univoco verrà generato automaticamente"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["numero del negozio assegnato dopo la registrazione"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["numero di conto del negozio; questo conto determina anche in quale valuta deve essere effettuato il pagamento (nella maggior parte dei casi l'utente può effettuare un pagamento in qualsiasi valuta e i fondi verranno automaticamente convertiti nella valuta del destinatario)"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vietare al cliente di modificare l'importo specificato (1 - proibire, 0 - consentire)"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["l'importo del beneficiario nella valuta del conto del beneficiario"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["numero del modulo attraverso il quale viene accettato il pagamento"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["commento visualizzato al pagatore come causale del pagamento"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ulteriore parametro facoltativo che verrà passato allo script dell'esito del pagamento, nonché alle pagine di reso"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL a cui il cliente tornerà dopo il pagamento andato a buon fine; se non specificato viene prelevato dalle impostazioni dello store"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL a cui il client tornerà dopo un errore; se non specificato viene prelevato dalle impostazioni dello store"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL a cui verrà inviata la notifica dell'esito del pagamento; se non specificato viene prelevato dalle impostazioni dello store"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["durata della fattura (in minuti), predefinito 60"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["firmare un messaggio utilizzando la chiave segreta del negozio"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0 o 1"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 - in caso di successo, 0 - altrimenti"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["intero"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["codice di errore (0 se non ci sono errori)"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["stringa"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["testo di errore (o spiegazione dello stato del pagamento)"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0 o 1"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["segno che non si conosce ancora lo stato finale dei pagamenti"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archiviare il codice di pagamento"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di transazione"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protocollo del commerciante"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'accettazione dei pagamenti sul sito web è possibile solo per i negozi online registrati nel sistema. Per registrare un negozio, vai alla pagina"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrazione del negozio"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reindirizzamento dell'utente al commerciante per il pagamento"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per il pagamento, è necessario reindirizzare l'utente all'URL: https://merchant.[tuo dominio] e inviare i seguenti parametri tramite GET o POST:"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrizione"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo di dati"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firma"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viene calcolato aggiungendo i parametri inviati e applicando l'hash mediante il metodo hmac con la chiave segreta. Il numero e l'ordine dei parametri aggiunti dipende dai parametri"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["identified"])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["importo fisso"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'ordine è il seguente:"])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ext_transact + num_shop + keyt_shop + identified + sum + payform + comment + free_param + url_success + url_decline + url_callback"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["per il link dove il cliente"])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pagamento, l'ordine è il seguente (l'importo non è incluso nella firma):"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ext_transact + num_shop + keyt_shop + identified + payform + comment + free_param + url_success + url_decline + url_callback"])},
  "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il link di pagamento può essere generato nella pagina"])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'Il mio negozio'"])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esempio di un modulo:"])},
  "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ritorno al sito web del negozio dopo il completamento dell'operazione"])},
  "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se il pagatore rifiuta il pagamento o l'operazione termina con un errore, il sistema di pagamento suggerisce di andare alla pagina appropriata specificata nelle impostazioni del negozio o inviata con il modulo"])},
  "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["url_decline"])},
  "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se l'operazione è stata completata con successo, vai alla pagina"])},
  "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["url_success"])},
  "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questi script riceveranno anche i risultati del pagamento (con il metodo specificato nelle impostazioni del negozio. Predefinito - GET):"])},
  "62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
  "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrizione"])},
  "64": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo di dati"])},
  "65": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firma"])},
  "66": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viene calcolato aggiungendo i parametri inviati e applicando l'hash mediante il metodo hmac con la chiave segreta."])},
  "67": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordine dei parametri aggiunti:"])},
  "68": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ext_transact + amount + success + result + result_text + free_param"])},
  "69": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifica dello stato del pagamento del negozio (callback)"])},
  "70": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dopo l'esecuzione del pagamento, i risultati del pagamento vengono inviati allo script specificato durante la registrazione del negozio"])},
  "71": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["result_url"])},
  "72": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questo script riceve i dati sia per i casi di pagamento riuscito che per gli errori."])},
  "73": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questi script riceveranno anche i risultati del pagamento (con il metodo specificato nelle impostazioni del negozio. Predefinito - GET)."])},
  "74": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In alcuni casi, le informazioni non arrivano con i risultati (ad esempio, se il cliente è andato a pagare e ha chiuso la pagina del browser)."])},
  "75": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
  "76": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrizione"])},
  "77": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo di dati"])},
  "78": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firma"])},
  "79": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viene calcolato aggiungendo i parametri inviati e applicando l'hash mediante il metodo hmac con la chiave segreta."])},
  "80": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordine dei parametri aggiunti:"])},
  "81": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["transact + ext_transact + amount + success + result + result_text + free_param"])},
  "82": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calcolo della firma"])},
  "83": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La firma viene creata aggiungendo i parametri richiesti nell'ordine specificato e applicando l'hash mediante il metodo hmac."])},
  "84": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'ordine e il numero dei parametri aggiunti è descritto sopra."])},
  "85": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I seguenti elenchi mostrano esempi di funzioni per il calcolo della firma in diverse lingue, dove la stringa aggiunta per la firma viene passata come parametro"])},
  "86": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elenco 1. Calcolo della firma in PHP."])},
  "87": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elenco 2. Calcolo della firma in Python."])},
  "88": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elenco 3. Calcolo della firma in Perl."])},
  "89": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per il pagamento"])},
  "90": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'importo può essere modificato"])},
  "91": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagamento del prodotto"])},
  "92": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paga"])},
  "93": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genera firma con chiave"])},
  "94": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["signed_str"])},
  "95": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e frase segreta nel parametro"])},
  "96": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["key"])}
}