export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भरोसेमंद खाता"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भुगतान विधि"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टेम्प्लेट नाम:"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सहेजें"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टेम्प्लेट सहेजें"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जारी रखें"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टेम्प्लेट्स"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खाता पूर्ति"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ट्रांसफर विवरण"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ट्रांसफर जांचें"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ट्रांसफर जानकारी"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["निर्देश"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पता पूर्ति"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भरोसेमंद पता"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["निर्यात"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कार्य सूची में"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ट्रांसफर पता"])}
}