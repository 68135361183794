export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pares de moedas"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar par de moedas"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar par de moedas"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excluir par de moedas"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ações"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moeda dada"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moeda recebida"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gateway"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de atualização"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum par de moedas encontrado"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tem certeza que deseja excluir o par de moedas"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vinculado a outra moeda"])}
}