export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorizace"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přihlášení:"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heslo:"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapomněli jste heslo?"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jste zde poprvé?"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořit účet"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obnovení hesla"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon nebo e-mail:"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obnovit"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obnovení hesla"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Příjmení:"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jméno:"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prostřední jméno:"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum narození:"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přihlásit se"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zpět"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokračovat"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Najít"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uživatel nalezen"])}
}