export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择状态"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["领取申请已提交"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收款申请已取消"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["批准执行的集合"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["由收银员进行收款（钱被装入钱箱）"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["磁带移交给收藏家（钱在路上）"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["安装在ATM机上的现金收集"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["由于失败，另一个集合超载"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["该集合已被 ATM 上的另一个集合取代（仍在途中）"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收款余额已过帐（收款将发送至档案）"])}
}