export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Templates"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Replenishment"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Translation"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conclusion"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Template name"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operator"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fields"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operations"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No templates"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipient"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete pattern no."])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rename Template"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rename"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use"])}
}