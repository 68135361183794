export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["充值账户"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付方式"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["模板名称："])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存模板"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["继续"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["模板"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["账户充值"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["转账详情"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["检查转账"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["转账信息"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["说明"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["充值地址"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["充值地址"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["导出"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返回任务列表"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["转账地址"])}
}