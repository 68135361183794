export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["line up to 255 characters"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["integer"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["20 digits"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0 or 1"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fractional number, precision two digits, dot separator"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["integer"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["string up to 255 characters, optional"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["integer, optional"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["string 32 characters"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Store payment code; should be unique for each payment when transmitting a string"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a unique number will be generated automatically"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["store number assigned after registration"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["store account number; this account also determines in which currency the payment must be made (in most cases, the user can make a payment in any currency, and the funds will be automatically converted into the recipient's currency)"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["prohibit the client from changing the specified amount (1 - prohibit, 0 - allow)"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["beneficiary's amount in the beneficiary's account currency"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["number of the form through which payment is accepted"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["comment displayed to the payer as the purpose of the payment"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["additional optional parameter that will be passed to the payment result script, as well as to the return pages"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL where the client will return after successful payment; if not specified, it is taken from the store settings"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL where the client will return after an error; if not specified, it is taken from the store settings"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL where notification of payment results will be sent; if not specified, it is taken from the store settings"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["invoice lifetime (in minutes), default 60"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["signing a message using the store's secret key"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0 or 1"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 - if successful, 0 - otherwise"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["integer"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["error code (0 if there is no error)"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["string"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["error text (or explanation of the payment status)"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0 or 1"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a sign that the final payment status is not yet known"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Store payment code"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction number"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merchant Protocol"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepting payments on the website is only possible for online stores registered in the system. To register a store, go to the page"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Store Registration"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redirecting the user to the merchant for payment"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For payment, it is necessary to redirect the user to the URL: https://merchant.[your domain] and send the following parameters via GET or POST:"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Type"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signature"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is calculated by adding the sent parameters and hashing by the hmac method with the secret key. The number and order of added parameters depends on the parameters"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["identified"])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fixed amount"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The order is as follows:"])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ext_transact + num_shop + keyt_shop + identified + sum + payform + comment + free_param + url_success + url_decline + url_callback"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for the link where the client"])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["payment, the order is as follows (amount is not included in the signature):"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ext_transact + num_shop + keyt_shop + identified + payform + comment + free_param + url_success + url_decline + url_callback"])},
  "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The payment link can be generated on the page"])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'My Store'"])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Example of a form:"])},
  "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return to the store website after operation completion"])},
  "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If the payer refuses payment or the operation ends with an error, the payment system suggests going to the appropriate page specified in the store settings or sent with the form"])},
  "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["url_decline"])},
  "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If the operation was completed successfully, go to the page"])},
  "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["url_success"])},
  "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["These scripts will also receive payment results (by the method specified in the store settings. Default - GET):"])},
  "62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "64": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Type"])},
  "65": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signature"])},
  "66": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is calculated by adding the sent parameters and hashing by the hmac method with the secret key."])},
  "67": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order of added parameters:"])},
  "68": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ext_transact + amount + success + result + result_text + free_param"])},
  "69": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Store payment status notification (callback)"])},
  "70": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After payment execution, payment results are sent to the script specified during store registration"])},
  "71": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["result_url"])},
  "72": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This script receives data for both successful payment and error cases."])},
  "73": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["These scripts will also receive payment results (by the method specified in the store settings. Default - GET)."])},
  "74": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In some cases, information does not arrive with the results (for example, if the client went to pay and closed the browser page)."])},
  "75": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "76": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "77": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Type"])},
  "78": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signature"])},
  "79": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is calculated by adding the sent parameters and hashing by the hmac method with the secret key."])},
  "80": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order of added parameters:"])},
  "81": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["transact + ext_transact + amount + success + result + result_text + free_param"])},
  "82": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signature calculation"])},
  "83": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The signature is created by adding the required parameters in the specified order and hashing by the hmac method."])},
  "84": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The order and number of added parameters is described above."])},
  "85": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The following lists show examples of signature calculation functions in different languages, where the string added for signature is passed as a parameter"])},
  "86": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List 1. Signature calculation in PHP."])},
  "87": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List 2. Signature calculation in Python."])},
  "88": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List 3. Signature calculation in Perl."])},
  "89": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For payment"])},
  "90": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount can be changed"])},
  "91": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product payment"])},
  "92": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay"])},
  "93": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate signature with key"])},
  "94": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["signed_str"])},
  "95": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and secret phrase in parameter"])},
  "96": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["key"])}
}