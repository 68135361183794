export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعداد الإشعارات"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تمكين"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإشعار بالعمليات"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التسجيل (الدخول إلى الحساب الشخصي، التطبيق المحمول، وغيرها)"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإشعار عند إجراء العمليات المالية باستخدام البطاقة"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النفقات (أي مدفوعات، تحويلات، دفع الخدمات، إلخ)"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استلام الأموال"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استلام الرسائل عبر البريد الداخلي"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استلام الفاتورة للدفع"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تغيير إعدادات الأمان"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد العمليات برمز من الرسالة"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد التسجيل برمز من الرسالة"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد عند إجراء العمليات المالية باستخدام البطاقة"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد تغيير إعدادات الأمان برمز من الرسالة"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد النفقات برمز من الرسالة"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم يتم حفظ التغييرات، هل تريد الحفظ؟"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدم الحفظ"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ"])}
}