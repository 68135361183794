export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona lo stato"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La richiesta di riscossione è stata presentata"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richiesta di riscossione annullata"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collezione approvata per l'esecuzione"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La riscossione viene effettuata dal cassiere (il denaro viene caricato nelle cassette)"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cassette consegnate ai collezionisti (soldi in arrivo)"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raccolta contanti installata sul bancomat"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A seguito di un errore, sovraccarico di un'altra raccolta"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il ritiro è stato sostituito da un altro ritiro presso un bancomat (rimane in corso)"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vengono registrati i saldi degli incassi (l'incasso viene inviato all'archivio)"])}
}