export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data wpływu"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data przetwarzania"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liczba płatności"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["w tym udanych"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["w tym błędnych"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Płatności masowe"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transakcja"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wynik"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostawca usług"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kwota"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prowizja"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentarz"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. parametr"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie znaleziono płatności"])}
}