export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жарнама туралы ақпарат"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Негізгі ақпарат"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Негізгі"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қосымша"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жасалған күні"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Белсенді"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қабылдау"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мәміле басталу күні"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мәміле аяқталу күні"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бағам"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жасаушы"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["береді"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["алады"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тек сілтеме арқылы қолжетімді"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тек расталған пайдаланушыларға қолжетімді"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["шектеулер"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жарнама иесі"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сипаттама"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тайм-аут"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["күн"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["сағат"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["минут"])}
}