export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البورصة"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السوق. ملخص"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عروض مشابهة"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عروض مضادة"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السعر"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يعطون"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يستلمون"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طلباتي"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["آخر الصفقات"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السعر"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أعطي"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التاريخ والوقت"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التاريخ"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أعطي"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أستلم"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحالة"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السعر"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العمليات"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العمولة"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلغاء"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعادة"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد عروض"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد صفقات"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد طلبات"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اكتمل بالكامل"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم تبدأ المعالجة"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بدأت المعالجة"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اكتمل جزئيًا"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم إلغاؤه بالكامل"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اكتمل جزئيًا وتم إلغاؤه"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استلم"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كل الطلبات"])}
}