export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información adicional"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acciones"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activo"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Depósito"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retiro"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear nueva dirección"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear nueva cuenta"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar seleccionados"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mis activos"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de cuentas"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todo"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de la cuenta"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Realmente quieres eliminar las cuentas?"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Realmente quieres eliminar la cuenta?"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontraron activos"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontraron cuentas"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renombrar dirección"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Realmente quieres eliminar las direcciones?"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Realmente quieres eliminar la dirección?"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No fiduciario"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fíat"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operaciones"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comportamiento"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completar"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retirar"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intercambio"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esconder"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambiar"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar"])}
}