export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एम्बेडेड विजेट"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्थापना"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्क्रिप्ट को पेज के head सेक्शन में CDN के माध्यम से या फ़ाइल के रूप में डाउनलोड करके कनेक्ट करें"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कॉल"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फ़ंक्शन को कॉल करें"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JS ऑब्जेक्ट को आर्गुमेंट के रूप में पास करके"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नाम"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विवरण"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["डेटा प्रकार"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उदाहरण"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iframe के लिए id"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iframe के लिए name"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भुगतान पैरामीटर के साथ ऑब्जेक्ट"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ऑपरेशन सफलता हैंडलर फ़ंक्शन"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ऑपरेशन असफलता हैंडलर फ़ंक्शन"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भुगतान सेवा का पता"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नीचे"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["और नीचे"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ऑपरेशन पूरा होने के बाद स्टोर साइट पर वापसी"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ऑपरेशन पूरा होने या अस्वीकार होने के बाद, विजेट बंद हो जाएगा। नीचे दिए गए पैरामीटर वाला ऑब्जेक्ट onFail या onSuccess कॉलबैक को पास किया जाएगा:"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भुगतान करें"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सफलता पर कार्रवाई"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["असफलता पर कार्रवाई"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भुगतान सेवा का पता"])}
}