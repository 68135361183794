export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm payment"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculate return fee"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return payment"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hash in the blockchain"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AML risk"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sum"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commission"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For enrollment"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you agree to the amended terms, please confirm your enrollment:"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If these conditions do not suit you, you can return the funds to the sender's account minus return costs:"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for the amount"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["has AML risk"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["therefore, the commission for its enrollment has been recalculated"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["thus, the amount to be credited is"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No new arrivals found"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment date"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refund"])}
}