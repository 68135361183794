export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ATM zařízení"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidat zařízení"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ zařízení"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jméno"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentář"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfigurace"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidejte kazetu"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ kazety"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jméno"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentář"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfigurace"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odstraňte zařízení"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyjměte kazetu"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smazat zařízení?"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vymazat"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smazat kazetu?"])}
}