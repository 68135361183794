export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名称："])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["该名称是加密资产的全名，每个人都会看到它：发行者本人、买家、卖家、处理者等。"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TICKER"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 至 10 个字符的缩写。\n大写拉丁字母、连字符（破折号）和数字都是可接受的。"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["类别："])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择您的加密资产最适合的类别"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支持的格式：png、jpg、jpeg、svg。\n文件大小不超过1MB，500*500像素"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["描述发行方发行该加密资产的原因，在哪个领域工作或将在哪个领域工作，其使命是什么"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["标志："])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目标/使命/目的："])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["精度："])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发行量："])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["单位成本："])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金额的小数位数。例如，大多数法定货币（如美元或欧元）的精度为 2。"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发行加密资产的单位数。加密资产创建交易完成后，您的账户将立即获得发行量。"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["单位声明价值："])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支持："])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["账户："])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["承担义务的人："])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["虚拟数字资产发行决议："])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["将操作导出到区块链："])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["区块链类型："])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["启用VA使用："])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["限制/制裁："])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["指定上述列表中哪些国家的公民或税务居民被禁止在其营业额中使用加密资产："])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["其他限制和条件："])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["托管地址："])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["白皮书地址："])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子邮件："])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telegram："])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twitter："])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook："])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["其他联系方式："])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返回上一页"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下一步"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建加密资产"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名称和目的"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["财务特征"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发行人"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["导出到区块链"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["限制和限制"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["联系方式"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未定义"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["与法定货币和其他资产的固定挂钩"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发行人（发行加密资产的人）"])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发行人（发行加密资产的人）"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发件人"])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收件人"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我（启用赞助）"])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加密资产投放"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加密资产是指可使用区块链技术以电子方式转让和存储的价值或权利的数字代表。加密资产不包括受政府单独监管的法定货币、证券和其他金融工具和资产。"])},
  "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编辑加密资产"])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["行政"])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["验收"])},
  "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拒绝受理理由"])},
  "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["报价人账户"])},
  "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["报价人的绑定货币账户"])},
  "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["要约人是一级市场购买/销售交易中资产发行人的代表。\n该帐户是被动的。\n可以是资产发行账户"])},
  "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["该账户是被动的，以货币挂钩。\n必须与之前的帐户属于同一用户"])},
  "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["允许用户在 ATM 上购买资产"])},
  "62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["允许用户在ATM上出售资产"])},
  "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["售后购买表"])},
  "64": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["二级市场交易通过网关进行"])},
  "65": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["售后销售表格"])},
  "66": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["二级市场交易通过网关进行"])},
  "67": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["允许在交易所进行资产交易"])},
  "68": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["允许通过P2P进行资产交易"])},
  "69": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果您是个体工商户或法人代表，您需要下载PDF格式的数字资产发行决策文件。\n该解决方案必须包含虚拟资产的所有主要特征，并使用个体企业家或该法人实体的电子签名进行签名。\n发行数字资产的决定示例可以在我们网站页面的“文件”部分找到"])},
  "70": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["放置虚拟资产"])},
  "71": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["替代发行账户"])},
  "72": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["从发行人账户中选择"])},
  "73": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拟核销金额："])},
  "74": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有账户"])},
  "75": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建一个帐户"])},
  "76": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最大限度。\n排放："])},
  "77": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发射尺寸超过最大值。\n排放量"])},
  "78": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最大限度。\n排放小于排放大小或等于零"])},
  "79": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不存在表格"])}
}