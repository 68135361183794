export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发票列表"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未找到发票"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["代码"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日期"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["状态"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评论"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金额"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商店"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地位"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["存储号码"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消发票"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["存储付款代码"])}
}