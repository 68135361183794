export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auswählen"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übertragen"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empfangen"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich gebe"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["intern"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich erhalte"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interne Aktiva"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Aktiva gefunden"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Externe Aktiva"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["extern"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausgewählt:"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intern"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extern"])}
}