export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المعلومات العامة"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات الدفع"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التوقيع الرقمي"])},
  "List": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متاجري"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم يتم العثور على متاجر"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء كود الودجت"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء رابط الدفع"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حذف المتجر"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل تريد حقًا حذف المتجر"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل المتجر"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكود"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم"])},
    "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وضع الدفع"])},
    "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النشاط"])},
    "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قبول"])},
    "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
    "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإجراءات"])}
  },
  "New": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل المتجر"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكن للمتجر الإلكتروني المسجل في النظام استقبال المدفوعات من العملاء عبر بوابة الدفع لدينا."])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكن تسجيل المتاجر الإلكترونية، مراكز الصرف وأي موارد إنترنت أخرى ترغب في استقبال المدفوعات."])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم أو العلامة التجارية"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان URL للمتجر"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وصف موجز للمتجر"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الخدمة"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المتجر نشط"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المفتاح السري."])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السماح بالدفع التلقائي."])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إرسال النتائج إلى البرنامج النصي."])},
    "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان البرنامج النصي"])},
    "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العودة بعد نجاح الدفع"])},
    "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بعد الدفع غير الناجح"])},
    "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ"])},
    "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء"])},
    "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ما لا يقل عن 30 حرفا."])},
    "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تُستخدم للتحقق من سلامة وصحة البيانات المرسلة من قبل نظام الدفع (عندما يرسل المتجر المستخدم إلى التاجر) ومن قبل المتجر (عند استلام نتائج الدفع)."])},
    "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التالي‘"])},
    "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رجوع‘"])},
    "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلى قائمة المتاجر"])},
    "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان URL غير صحيح"])}
  },
  "Invoices": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قائمة الفواتير"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم يتم العثور على فواتير"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رمز"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحالة"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعليق"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المجموع"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المتجر"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حالة"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم المتجر"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلغاء الفاتورة"])},
    "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تخزين رمز الدفع"])}
  }
}