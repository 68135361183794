export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica asset esterno"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppo"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sinonimi"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paesi"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collega all'asset"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unità di misura"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbreviazione"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precisione"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attivo"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accettato per il trading"])}
}