export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["चालानों की सूची"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई चालान नहीं मिला"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोड"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["तारीख"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्थिति"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टिप्पणी"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["राशि"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्टोर"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्थिति"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भंडार संख्या"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["चालान रद्द करें"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भंडार अदायगी कोड"])}
}