export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta de transferencia:"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto:"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentario:"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protección de la transacción"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código:"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generar"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duración de la protección:"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["días"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de la plantilla:"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar como plantilla"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferir"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plantillas"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferencia a otro usuario"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El código de protección es un código que debe introducir el destinatario para completar la transacción. El destinatario verá la transferencia protegida inmediatamente en la sección \"Historial de operaciones\", pero no podrá usar el dinero hasta que ingrese el código de protección en la tarjeta de transacción. Si el plazo del pago expira, los fondos se devolverán al remitente."])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección de transferencia"])}
}