export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eingangsdatum"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verarbeitungsdatum"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der Zahlungen"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["einschließlich erfolgreicher"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["einschließlich fehlerhafter"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Massenüberweisungen"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaktion"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ergebnis"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dienstleister"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betrag"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommission"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. Parameter"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Zahlungen gefunden"])}
}