export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metodi di rifornimento"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criptovaluta"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rifornimento tramite P2P"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controllo elettronico"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modelli di rifornimento"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metodi di output"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In criptovaluta"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catalogo dei pagamenti"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagamenti di massa"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conclusione tramite P2P"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modelli di conclusione"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metodi di traduzione"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exchange per lo scambio"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traduzione all'utente"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trasferisci secondo i tuoi conti"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modelli di traduzione"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rimuovere il punteggio usando Bitcoin, Tether USDT, Ethereum, Tron o altre criptovalute."])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exchange senza intermediari: traduci la criptovaluta, il bonifico bancario o il trasferimento in contanti a un altro utente ed è un trasferimento interno per te."])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hai acquisito da qualche parte un controllo elettronico del servizio? \nAttivalo presto e ottieni l'intero valore nominale a proprie spese. \nA proposito, puoi controllare il controllo senza attivazione."])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rimuovere il punteggio utilizzando modelli precedentemente creati. \nA proposito, i modelli possono essere creati dopo una transazione o dalla storia delle operazioni."])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rimuovere i fondi su Bitcoin, Tether USDT, Ethereum, Tron o altre criptovaluta."])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paga le comunicazioni cellulari, l'appartamento comunitario o altri servizi."])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea dozzine, centinaia o addirittura migliaia di operazioni in un clic. \nBasta scaricare il file con l'elenco dei formato Excel."])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exchange senza intermediari: traduci la criptovaluta, il bonifico bancario o il trasferimento in contanti a un altro utente ed è un trasferimento interno per te."])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assegna i fondi utilizzando modelli precedentemente creati. \nA proposito, i modelli possono essere creati dopo una transazione o dalla storia delle operazioni."])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puoi impostare il tuo corso e aspettare che il corso diventasse proprio questo. \nO scambiare rapidamente al tasso di mercato attuale."])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trasferisci i fondi a un altro utente del servizio."])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distribuire facilmente fondi tra i tuoi account."])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trasferisci i fondi rapidamente utilizzando modelli precedentemente creati. \nA proposito, i modelli possono essere creati dopo una transazione o dalla storia delle operazioni."])}
}