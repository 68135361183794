export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Período desde"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Período hasta"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportar"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtros"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restablecer"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizar"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transacción"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parámetro"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transacción"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operación"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corresponsal"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parámetros/Comentario"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontraron operaciones"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suma de operaciones"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingresos:"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" operaciones por un monto de"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gastos:"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipos de operaciones"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagos y servicios"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferencias internas"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pago de factura"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferencias entre tus cuentas"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creación de cheques criptográficos"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activación de cheques criptográficos"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otros"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingreso/gasto"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingreso"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gasto"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["= igual"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≠ no igual"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["> mayor"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≥ mayor o igual"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["< menor"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≤ menor o igual"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completado exitosamente"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En proceso"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoy"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ayer"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semana"])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Año"])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historial de operaciones"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recarga de cuenta"])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta no seleccionada"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intercambio en la bolsa"])},
  "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recarga de dirección"])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferencias dentro de tu billetera"])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corresponsal"])},
  "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operación"])}
}