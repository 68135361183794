export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP-адрес бойынша қолжетімділікті баптау сізге (немесе зиянкестің) рұқсат етілген тізімде көрсетілмеген адреспен жүйемен жұмыс істеуге мүмкіндік бермейді."])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кабинетке IP-адрес бойынша қолжетімділікті шектеу"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес қосу"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес: "])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қосу"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес блогы: "])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["дейін:"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Өз IP: "])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес тізімі"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрестен"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адреске"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қолжетімділік"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Әрекеттер"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жою"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес жоқ"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сақтау"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тыйым салу"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рұқсат беру"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP-адрес енгізіңіз"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP-адрес қате енгізілген"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ешбір IP-адрес немесе адрес блогы көрсетілмеген."])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Авторизация үшін ешбір IP-адрес рұқсат етілмеген. Сіз өз шотыңызға кіре алмайсыз."])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP арқылы қолжетімділік"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["бастап:"])}
}