export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastavení přístupu podle IP adresy vám (nebo útočníkovi) neumožní pracovat se systémem z adresy, která není uvedena v seznamu povolených."])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Omezení přístupu k účtu podle IP adres"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidat adresy"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresa: "])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidat"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blok adres: "])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["do:"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaše IP: "])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seznam adres"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["č."])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Od adresy"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do adresy"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přístup"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akce"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odstranit"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádné adresy nenalezeny"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložit"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zakázat"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Povolit"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadejte IP adresu"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP adresa je nesprávná"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Není zadána žádná IP adresa nebo blok adres."])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádná IP adresa není povolena pro autorizaci. Nebudete mít přístup ke svému účtu."])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přístup podle IP"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["od:"])}
}