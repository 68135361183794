export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elektronische Schecks"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen Sie einen Scheck"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivität"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellt"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abschreibungskonto"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summe"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nummer"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serie"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Kommentar"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktionen"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht aktiv"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Schecks"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aktivieren Sie"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüfen"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schließen"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine E-Quittungen gefunden"])}
}