export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виберіть"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Віддаваний"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отримуваний"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["актив"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["віддаю"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["внутрішній"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["отримую"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Внутрішні активи"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активи не знайдено"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зовнішні активи"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["зовнішній"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вибрано:"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Внутрішні"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зовнішні"])}
}