export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coppie di valute"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea coppia di valute"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica coppia di valute"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina coppia di valute"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azioni"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valuta data"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valuta ricevuta"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gateway"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo di aggiornamento"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna coppia di valute trovata"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sei sicuro di voler eliminare la coppia di valute"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["collegato a un'altra valuta"])}
}