export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["банкоматтар"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кестені толық экранға кеңейту үшін басыңыз"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қалпына келтіру"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жаңарту"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Белсенділік"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Субдилерлер"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Белсенді"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Белсенді емес"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Банкомат жасаңыз"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Криптоматты өңдеу"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бас тарту"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сақтау"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Негізгі ақпарат"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Негізгі"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жұмыс уақыты"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Атауы"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Логин"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Құпия сөз"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мекенжай"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Орнату орнының сипаттамасы"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нормаланған"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нормаланбаған"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жұмыс режимі"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дүйсенбі"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сейсенбі"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сәрсенбі"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бейсенбі"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жұма"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сенбі"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жексенбі"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Түскі үзіліс"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Құпия сөзді қайталау"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бар тіркелгілерді пайдаланыңыз"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жаңа тіркелгілерді жасаңыз"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Негізгі мәліметтер"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Есептік жазба"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жұмыс уақыты (анықтамалық ақпарат)"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Белсенділік"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Әрекеттер"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ешқандай крипто машиналары табылмады"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["банкомат нөмірі"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ендік"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["бойлық"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анықтама телефоны"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қосалқы дилердің идентификаторы"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Есептік жазбалар"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тексеру"])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Валюта"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзиттік шот"])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Криптоматтың құрылуы"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Криптоматты өңдеу"])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Құпия сөз"])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Иә"])},
  "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тыйым салу"])},
  "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рұқсат етіңіз"])},
  "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коллекционерлердің тізімі"])},
  "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коллекторды қосыңыз"])},
  "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Инкассация балансына өту мүмкін емес - деректер жоқ"])},
  "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коллекционерлердің тізімі"])},
  "62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коллекторды қосыңыз"])},
  "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рұқсат етілген"])},
  "65": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қабылдау"])},
  "67": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мекенжай"])},
  "68": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Банкоматты өңдеу"])},
  "69": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ағымдағы инкассация қалдықтарын қараңыз"])},
  "70": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жүктелмеген инкассация қалдықтарын қараңыз"])},
  "71": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пин коды"])},
  "73": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Егер субдилерді көрсетпесеңіз, банкомат сізге байланыстырылады"])},
  "74": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ендік пен бойлық географиялық координаттар болып табылады, мысалы, банкомат мекенжайын енгізу арқылы Google Maps қолданбасынан көшіруге болады."])},
  "75": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Орнату орны"])},
  "76": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кестені көрсету үшін кемінде бір баған таңдалуы керек"])},
  "77": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сақтау"])},
  "78": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жасау"])},
  "79": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Банкомат құрылғыларына өтіңіз"])}
}