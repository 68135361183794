export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Métodos de reabastecimiento"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criptomoneda"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reabastecimiento a través de P2P"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cheque electrónico"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plantillas de reabastecimiento"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Métodos de salida"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En criptomoneda"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catálogo de pagos"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagos de masa"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conclusión a través de P2P"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plantillas de conclusión"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Métodos de traducción"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intercambio por el intercambio"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traducción al usuario"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferir de acuerdo con sus cuentas"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plantillas de traducción"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reponga la puntuación con Bitcoin, Tether USDT, Ethereum, Tron u otras criptomonedas."])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intercambio sin intermediarios: traduce la criptomoneda, la transferencia bancaria o la transferencia de efectivo a otro usuario, y es una transferencia interna para usted."])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Ha adquirido en algún lugar un control electrónico del servicio? \nActíalo pronto y obtenga todo el valor nominal a su propio costo. \nPor cierto, puede verificar el cheque sin activación."])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reponga la puntuación usando plantillas creadas previamente. \nPor cierto, las plantillas se pueden crear después de una transacción o de la historia de las operaciones."])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retire los fondos a Bitcoin, Tether USDT, Ethereum, Tron u otra criptomoneda."])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pague las comunicaciones celulares, el apartamento comunitario u otros servicios."])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haga docenas, cientos o incluso miles de operaciones en un solo clic. \nSimplemente descargue el archivo con la lista de formatos de Excel."])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intercambio sin intermediarios: traduce la criptomoneda, la transferencia bancaria o la transferencia de efectivo a otro usuario, y es una transferencia interna para usted."])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asigne los fondos utilizando plantillas creadas previamente. \nPor cierto, las plantillas se pueden crear después de una transacción o de la historia de las operaciones."])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puede establecer su curso y esperar a que el curso se convierta en eso. \nO intercambiar rápidamente a la tasa de mercado actual."])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfiera fondos a otro usuario del servicio."])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distribuir fácilmente fondos entre sus cuentas."])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfiera fondos rápidamente utilizando plantillas creadas previamente. \nPor cierto, las plantillas se pueden crear después de una transacción o de la historia de las operaciones."])}
}