export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bolsa"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mercado. Resumo"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ofertas semelhantes"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ofertas correspondentes"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxa"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dando"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recebendo"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minhas ordens"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Últimas negociações"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxa"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dado"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data e hora"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dando"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recebendo"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxa"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operações"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["taxa"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repetir"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma oferta"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma negociação"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma ordem"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completamente preenchida"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processamento não iniciado"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processamento iniciado"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parcialmente preenchida"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completamente cancelada"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parcialmente preenchida e cancelada"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recebido"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todas as ordens"])}
}