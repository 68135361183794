export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digital Passport"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User ID:"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration date:"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last authorization:"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My assets"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accounts"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View all"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Replenish"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Output"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No bills"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation password"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To confirm transactions, you need to create a confirmation password."])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Templates"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sum"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popular payments"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["News"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimize"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next..."])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No assets"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balance"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Withdraw"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposit"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exchange"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To the user"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overall balance"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show in"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non-fiat balance"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiat balance"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New information"])}
}