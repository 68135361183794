export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz stan"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wniosek o zbiórkę został złożony"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zgłoszenie zbiórki anulowane"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kolekcja zatwierdzona do realizacji"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odbiór dokonywany jest przez kasjera (pieniądze ładowane są do kaset)"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kasety przekazane kolekcjonerom (pieniądze w drodze)"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zbiórka gotówki zainstalowana w bankomacie"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["W wyniku awarii przeciążony inną kolekcją"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kolekcja została zastąpiona inną kolekcją w bankomacie (pozostaje w drodze)"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salda kolekcji są księgowane (kolekcja wysyłana jest do archiwum)"])}
}