export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Méthodes de réapprovisionnement"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crypto-monnaie"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réapprovisionnement via P2P"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chèque électronique"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modèles de réapprovisionnement"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Méthodes de sortie"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En crypto-monnaie"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catalogue des paiements"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiements de masse"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conclusion via P2P"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modèles de conclusion"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Méthodes de traduction"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échange pour l'échange"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traduction à l'utilisateur"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transférer en fonction de vos comptes"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modèles de traduction"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réapprovisionnez le score en utilisant Bitcoin, Tether USDT, Ethereum, Tron ou d'autres crypto-monnaies."])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exchange sans intermédiaires: vous traduisez la crypto-monnaie, le transfert bancaire ou le transfert d'argent à un autre utilisateur, et il vous s'agit d'un transfert interne."])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avez-vous acquis quelque part une vérification électronique du service? \nActivez-le bientôt et obtenez toute la valeur nominale à vos frais. \nSoit dit en passant, vous pouvez vérifier le chèque sans activation."])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["REPLOSSION LA SCATE À l'aide de modèles créés précédemment. \nSoit dit en passant, des modèles peuvent être créés après une transaction ou à partir de l'historique des opérations."])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retirez les fonds à Bitcoin, Tether USDT, Ethereum, Tron ou autre crypto-monnaie."])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payez les communications cellulaires, l'appartement commun ou d'autres services."])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faites des dizaines, des centaines ou même des milliers d'opérations en un seul clic. \nTéléchargez simplement le fichier avec la liste des formats Excel."])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attribuez les fonds en utilisant des modèles créés précédemment. \nSoit dit en passant, des modèles peuvent être créés après une transaction ou à partir de l'historique des opérations."])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez définir votre cours et attendre que le cours devienne juste cela. \nOu échange rapidement au taux du marché actuel."])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transférer des fonds à un autre utilisateur de service."])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distribuez facilement les fonds entre vos comptes."])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transférer des fonds rapidement en utilisant des modèles créés précédemment. \nSoit dit en passant, des modèles peuvent être créés après une transaction ou à partir de l'historique des opérations."])}
}