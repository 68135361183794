export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ендірілген виджет"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Орнату"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скриптті беттің head бөліміне CDN арқылы немесе файл ретінде жүктеп қосыңыз"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шақыру"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Функцияны шақыру"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JS объектісін аргумент ретінде беру арқылы"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Атауы"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сипаттама"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Деректер түрі"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мысал"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iframe үшін id"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iframe үшін name"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["төлем параметрлері бар объект"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["операция сәтті аяқталу өңдеуші функциясы"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["операция сәтсіз аяқталу өңдеуші функциясы"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Төлем қызметінің мекенжайы"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Төменде"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Одан да төменде"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операция аяқталғаннан кейін дүкен сайтына оралу"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операция аяқталғаннан немесе бас тартылғаннан кейін, виджет жабылады. Төмендегі параметрлері бар объект onFail немесе onSuccess callback-ке беріледі:"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Төлеу"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сәтті аяқталған кездегі әрекеттер"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сәтсіз аяқталған кездегі әрекеттер"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Төлем қызметінің мекенжайы"])}
}