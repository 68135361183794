export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usługa płatności masowych umożliwia szybkie przesyłanie środków do innych użytkowników oraz dokonywanie operacji płatności na rzecz dostawców usług."])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["W sekcji"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Załaduj plik płatności"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["możesz załadować plik w formacie Excel (XLS, XLSX, CSV) lub XML."])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista zadań"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pozwala zobaczyć statusy wcześniej utworzonych wniosków o masowe wypłaty. "])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Możesz utworzyć plik w edytorze tabel Excel. "])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pobierz przykładowy plik"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ważne uwagi:"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format pliku - CSV, XLS lub XLSX."])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plik musi być bez nagłówków."])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aby długie liczby nie zmieniały się w format wykładniczy (np. \"5,46546E+15\") i nie były obcinane wiodące zera, przed liczbą można umieścić symbol pojedynczego cudzysłowu ('), lub przed wprowadzeniem danych ustawić dla komórki format tekstowy."])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format kolumn dla płatności na rzecz dostawców usług"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista kolumn w pliku:"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kod operatora"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Określa się z listy operatorów podanych"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tutaj"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". (Dla wszystkich operatorów telefonii komórkowej można określić jako kod operatora 7000 - w takim przypadku operator zostanie określony automatycznie na podstawie numeru telefonu.)"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kwota"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Kwota jest określona w aktywie, z którego dokonywany jest przelew. Jeśli kontrahent nie posiada takiego aktywa, do transakcji automatycznie stosuje się kurs przeliczeniowy systemu."])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentarz do płatności"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Wypełnianie nie jest obowiązkowe, można zostawić puste; długość nie więcej niż 255 znaków."])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametr płatności 1"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". \nWartość parametru wymagana przez operatora, na przykład numer telefonu lub adres kryptograficznego, jest określony. \nWielu operatorów jest ograniczonych do jednego parametru płatności, ale jeśli liczba parametrów jest więcej niż jeden, musisz wskazać je wszystkie w sąsiednich kolumnach."])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametr płatności 2"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametr płatności N"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametry płatności można zobaczyć, przechodząc do opłaty jakiegokolwiek operatora:"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format kolumn dla masowych przelewów użytkownikom"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kod operatora"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Określa się 0 lub nie określa się wcale."])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kwota"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Kwota jest wyświetlana w aktywie, z którego aktywo jest przelewane. Jeśli kontrahent nie posiada takiego aktywa, do transakcji automatycznie stosuje się kurs przeliczeniowy systemu."])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentarz do płatności"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Wypełnianie nie jest obowiązkowe, można zostawić puste; długość nie więcej niż 255 znaków."])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto odbiorcy"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Tutaj wpisuje się numer konta użytkownika, na które należy dokonać przelewu (20 cyfr)."])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format kolumn dla masowego udzielania pożyczek przez agenta kredytowego"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Ta linia wskazuje, że płatności stanowią pożyczki."])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kwota"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Kwota jest podawana w walucie produktu kredytowego."])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentarz do pożyczki"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Wypełnianie nie jest obowiązkowe, można zostawić puste; długość nie więcej niż 255 znaków."])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID pożyczkobiorcy"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Zwróć uwagę, że pożyczkobiorca musi mieć cyfrowy paszport Mfo-BorrowPerson."])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kod produktu kredytowego"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Można go znaleźć na liście produktów kredytowych przy ręcznym udzielaniu pożyczki."])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okres"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Po prostu liczba okresów (jakie okresy są używane, określa produkt kredytowy, np. dni, tygodnie, miesiące itp.)"])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kod operatora (numer formularza)"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Wypełnianie tego i następnych pól nie jest obowiązkowe, jeśli ich nie wypełnisz, pożyczkobiorca będzie mógł później samodzielnie dokonać wypłaty pożyczki przez swoje konto."])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anuluj w przypadku błędu"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Anuluj (1) udzieloną pożyczkę, jeśli transakcja wypłaty zakończy się błędem, lub zostaw do późniejszej samodzielnej wypłaty (0)."])},
  "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametr płatności 1"])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Wpisuje się wartość parametru wymaganego przez operatora, na przykład numer telefonu. Wielu operatorów ogranicza się do jednego parametru płatności, internetowi, telewizji komercyjnej itp.), ale jeśli liczba parametrów jest większa niż jeden, musisz podać je wszystkie w sąsiednich kolumnach od pierwszej."])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametr płatności 2"])},
  "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametr płatności N"])},
  "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plik musi wskazywać rachunki lub adresy kryptowalut odbiorców w jednej walucie, ponieważ odpis funduszy odbywa się z jednego konta, a nie z kilku."])}
}