export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modèles"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechargement"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfert"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retrait"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du modèle"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opérateur"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Champs"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opérations"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas de modèle de paiement"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bénéficiaire"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous vraiment supprimer le modèle n°"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renommer le modèle"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renommer"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utiliser"])}
}