export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бір реттік кілттер жүйенің қауіпсіздігін айтарлықтай арттырады."])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бағдарламаға кіру кезінде сізден тек логин мен құпиясөзді ғана емес, сонымен қатар кезекті бір реттік кілтті де енгізу сұралады. Активизация кезекпен жүзеге асырылады. Соңғы кілт жаңа кілттер сериясы жасалғанға дейін әрекет етеді."])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бір реттік кілттерді қолдану"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кілттер тізімі"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Генерациялау"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Экспорт"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кілт"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бір реттік кілттер"])}
}