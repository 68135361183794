export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Announcement"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requisites"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount limit"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting time (min)"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad is active"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available only via link"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available only to verified users"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])}
}