export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пристрої банкомату"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати пристрій"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип пристрою"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коментар"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Конфігурація"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати касету"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип касети"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коментар"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Конфігурація"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити пристрій"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити касету"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити пристрій?"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити касету?"])}
}