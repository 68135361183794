export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रमाणीकरण"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लॉगिन:"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पासवर्ड:"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पासवर्ड भूल गए?"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पहली बार यहाँ?"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खाता बनाएँ"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पासवर्ड पुनर्प्राप्ति"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फोन या ईमेल:"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पुनर्प्राप्त करें"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पासवर्ड पुनर्प्राप्ति"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपनाम:"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नाम:"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मध्य नाम:"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जन्म तिथि:"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रवेश"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वापस"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जारी रखें"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खोजें"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपयोगकर्ता मिला"])}
}