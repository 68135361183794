export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["转出"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["接收"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["资产"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我给"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["内部"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我收到"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["内部资产"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到资产"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["外部资产"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["外部"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择："])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["内部"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["外部"])}
}