export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додаткова емісія"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Криптоактив"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рахунок - джерело покриття"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рахунок списання"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додаткова емісія"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коментар"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продовжити"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поточна емісія"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ТОКЕН"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Розрахунок покриття"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комісія"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комісія"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Розрахунок покриття"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прив'язка"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Основні дані"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відсоток покриття"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Майбутнє покриття"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Списання покриття"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума, яка буде в резерві після випуску додаткової емісії."])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["До списання"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поточне покриття"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума, яка спишеться з вашого рахунку після випуску додаткової емісії."])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Майбутній розмір покриття"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E — Поточний розмір емісії"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I — Додаткова емісія"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["K — Курс прив'язки"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["P — Відсоток забезпеченості"])}
}