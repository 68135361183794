export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать файл"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Данный формат файла не поддерживается. Допустимые форматы:"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неизвестный формат"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Слишком большой размер файла, максимальный размер: "])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите или перетащите сюда файлы для загрузки"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузить"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменить"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Файлы загружены"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["файлов"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить"])}
}