export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您可以同时进行多次付款。\n为此，您需要首先创建一个包含“XLS”、“XLSX”、“CSV”格式的付款说明的文件。"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文件分析"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对应方"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金额"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评论"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["参数"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文件信息"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["总付款数："])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["总金额："])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["付款文件"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["扣款账户"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["执行付款"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["继续"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加载付款文件"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["检查文件内容"])},
  "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大宗支付和转账"])}
}