export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Толтыру әдістері"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Криптоцарттық"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["P2P арқылы толтыру"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Электронды тексеру"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Толтыру шаблондары"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шығу әдістері"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Криптоканутта"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Төлемдер каталогы"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жаппай төлемдер"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["P2P арқылы қорытынды"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қорытынды шаблондар"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аударма әдістері"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Биржа алмасу"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пайдаланушыға аударма"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тіркелгілерге сәйкес аударым"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аударма шаблондары"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Биткоинді, тетсерді, Etherum, Tron немесе басқа криптоштарды пайдаланып есепті толтырыңыз."])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Делдалсыз алмасу: сіз криптофаврлік, банктік аударым немесе қолма-қол ақшаны аударыңыз немесе басқа пайдаланушыға аударасыз және бұл сізге ішкі аударым болып табылады."])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сіз қызметті электронды чек сатып алдыңыз ба? \nЖақында оны іске қосыңыз және номиналды құнын өз есебіңізде алыңыз. \nАйтпақшы, сіз чекті іске қоспай тексере аласыз."])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бұрын жасалған шаблондарды пайдаланып есепті толтырыңыз. \nАйтпақшы, шаблондар транзакциядан кейін немесе операциялар тарихынан кейін құрылуы мүмкін."])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Биткоин, ЕСДТ, Etherum, Tron немесе басқа криптоцерлікке қаражат алыңыз."])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ұялы байланыс, коммуналдық пәтер немесе басқа қызметтерді төлеңіз."])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бір рет басу кезінде ондаған, жүздеген немесе тіпті мыңдаған операциялар жасаңыз. \nТек Excel пішімдері тізімімен файлды жүктеп алыңыз."])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Делдалсыз алмасу: сіз криптофаврлік, банктік аударым немесе қолма-қол ақшаны аударыңыз немесе басқа пайдаланушыға аударасыз және бұл сізге ішкі аударым болып табылады."])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выводите средства, используя созданные ранее шаблоны. \nАйтпақшы, шаблондар транзакциядан кейін немесе операциялар тарихынан кейін құрылуы мүмкін."])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сіз өз курсын орната аласыз және курстың дәл болуын күте аласыз. \nНемесе ағымдағы нарықтық бағамен жылдам айырбастау."])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Басқа қызмет пайдаланушысына ақша аударыңыз."])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шоттарыңыз арасындағы қаражаттарды оңай таратыңыз."])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бұрын жасалған шаблондарды пайдаланып қаражатты тез арада аударыңыз. \nАйтпақшы, шаблондар транзакциядан кейін немесе операциялар тарихынан кейін құрылуы мүмкін."])}
}