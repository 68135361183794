export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name:"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The name is the full name of the crypto-asset, as it will be seen by everyone: the issuer himself, buyers, sellers, processing, etc."])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TICKER"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An abbreviation of 3 to 10 characters. \nUppercase Latin letters, hyphens (dashes) and numbers are acceptable."])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category:"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose the category that best fits your crypto-asset"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supported formats: png, jpg, jpeg, svg. \nFile size no more than 1 MB, 500*500 pixels"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Describe why this asset is issued by the issuer, in what area it works or will work, what is its mission"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo:"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purpose / mission / purpose:"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accuracy:"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emission:"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unit cost:"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The number of decimal places for the amount. \nFor example, the accuracy of most fiat currencies such as the dollar, the euro is 2."])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The amount of the asset being issued. \nThe amount of the issue will be immediately available on your account after the completion of the operation of creating a crypto-asset."])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Declared unit price:"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security:"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obligated person:"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decision to issue a crypto-asset:"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export transactions to the blockchain:"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blockchain type:"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable VA usage:"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restrictions/Sanctions:"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specify, citizens or tax residents of which countries from the list below are prohibited from using a crypto-asset in their turnover:"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other restrictions and conditions:"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hosting address:"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["White paper address:"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email:"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telegram:"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twitter:"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook:"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other contacts:"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to previous"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create an asset"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name and purpose"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financial characteristics"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Issuer"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export to blockchain"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limits and restrictions"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacts"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unspecified"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed peg to fiat currencies and other assets"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Issuer (person producing crypto-active)"])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer (a person who distributes a crypto-active)"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sender"])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipient"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Me (include sponsorship)"])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Placement of a virtual asset"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crypto-asset means a digital representation of value or right that can be transferred and stored electronically using blockchain technology. Crypto-assets do NOT include fiat currencies, securities and other financial instruments and assets subject to separate government regulation."])},
  "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit crypto asset"])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration"])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceptance"])},
  "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason for refusal to accept"])},
  "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offeror's account"])},
  "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offeror's account in the binding currency"])},
  "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offeror is a representative of the issuer of an asset in purchase/sale transactions on the primary market. \nThe account is passive. \nCan be an asset issue account"])},
  "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The account is passive, linked in currency. \nMust belong to the same user as the previous account"])},
  "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allow users to purchase an asset at ATMs"])},
  "62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allow the sale of asset by users at ATMs"])},
  "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aftermarket Purchase Form"])},
  "64": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secondary market transactions are carried out through gateways"])},
  "65": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aftermarket Sales Form"])},
  "66": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secondary market transactions are carried out through gateways"])},
  "67": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allow transactions with an asset on the exchange"])},
  "68": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allow asset transactions via P2P"])},
  "69": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you are an individual entrepreneur or a representative of a legal entity, you need to download the decision file on the release of a digital asset in PDF format. \nThe solution must contain all the main characteristics of the virtual asset and be signed with an electronic signature of the individual entrepreneur or this legal entity. \nAn example of a decision to issue a digital asset can be found on the pages of our website in the \\\"Documents\\\" section"])},
  "70": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Placing a virtual asset"])},
  "71": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Substitute issue account"])},
  "72": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select from issuer accounts"])},
  "73": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount to be written off:"])},
  "74": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No account"])},
  "75": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create an account"])},
  "76": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max. \nemission:"])},
  "77": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The emission size exceeds the max. \nemissions"])},
  "78": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max. \nemission is less than the emission size or equal to zero"])},
  "79": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forms are absent"])}
}