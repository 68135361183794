export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generación de código/enlace de pago"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nº de operación de la tienda"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentario"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parámetro adicional"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL de éxito"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL de fallo"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL de notificación"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL al que volverá el cliente después del pago exitoso"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL al que volverá el cliente después del fallo"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL donde se enviará la notificación de los resultados del pago"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fijar cantidad"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiar"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificar"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enlace de pago"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulario"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documentación"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duración de la cuenta de pago en minutos, por defecto 60"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duración del enlace"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enlace"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulario"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Widget"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si no fija el importe, el pagador podrá introducirlo de forma independiente (adecuado, por ejemplo, para pagos benéficos)"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esconder"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulario de pago"])}
}