export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betrag"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erhalten"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualisieren"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkäufer"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie geben"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie erhalten"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limits"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktionen"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaktionen"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaktion"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Transaktionen"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angebote"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorteil"])}
}