export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ATM机集合"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加收藏"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["行动"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["过滤器"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重置"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不显示档案馆藏"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不显示已取消的集合"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["点数"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地位"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有收藏"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已提交收集申请"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编辑收藏"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更改收集状态"])}
}