export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create electronic check"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debit account"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nominal value of each check"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of checks"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Series:"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number:"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activation code:"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checks created"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download file with checks"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save this information - activation codes will be hidden after closing this page"])}
}