export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активті жою"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жою сомасы"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қайтару үшін шот"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жою"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жағу сомасы"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жою сомасы эмиссия сомасынан көп болмауы керек"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жабу қайтаруы"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қайтаруға"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активті жойғаннан кейін резервте қалатын сома"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активті жойғаннан кейін сіздің шотыңызға қайтарылатын сома"])}
}