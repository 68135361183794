export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modelos"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reabastecimento"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferência"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retirada"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome do modelo"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operador"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campos"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operações"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sem modelos"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destinatário"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você realmente deseja excluir o modelo Nº"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renomear modelo"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renomear"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excluir"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usar"])}
}