export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зовнішні активи"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прив'язано до активу"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Одиниці вимірювання"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Група"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата створення"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автор"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дії"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Період з"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Період по"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фільтри"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скинути"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оновити"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активів немає"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сьогодні"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вчора"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тиждень"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Місяць"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рік"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прив'язано до активу"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Група"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автор"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активність"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Країни"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активно"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неактивно"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редагувати зовнішній актив"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити зовнішній актив"])}
}