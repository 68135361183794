export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройка доступа по IP-адресу не позволит Вам (или злоумышленнику) работать с системой с адреса, не прописанного в списке разрешённых."])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ограничение доступа к кабинету по IP-адресам"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить адреса"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес: "])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Блок адресов: "])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["по:"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Свой IP: "])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список адресов"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["С адреса"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["По адрес"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступ"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Действия"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адреса отсутствуют"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запретить"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разрешить"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите IP-адрес"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP-адрес введен неверно"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не указан ни один IP-адрес или блок адресов."])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ни один IP-адрес не разрешен для авторизации. Вы не сможете получить доступ к своему счету."])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступ по IP"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["с:"])}
}