export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इलेक्ट्रॉनिक चेक"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["चेक बनाएं"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सक्रियता"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बनाया गया"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["डिडक्शन खाता"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["राशि"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संख्या"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["श्रृंखला"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टिप्पणी"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्रियाएँ"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सक्रिय"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["निष्क्रिय"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई चेक नहीं"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सक्रिय करें"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जांचें"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बंद करें"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई ई-रसीद नहीं मिली"])}
}