export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di ricezione"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di elaborazione"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di pagamenti"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["di cui riusciti"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["di cui falliti"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagamenti di massa"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transazione"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risultato"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fornitore di servizi"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commissione"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commento"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1° parametro"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun pagamento trovato"])}
}