export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ustawienia powiadomień"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Włącz"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powiadom o operacjach"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoryzacja (logowanie do konta, aplikacji mobilnej itp.)"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powiadom przy zdarzeniu \"Dokonywanie operacji finansowych za pomocą karty\""])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wydatki (wszelkie płatności, przelewy, opłaty za usługi itp.)"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wpływy pieniężne"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otrzymanie listu przez wewnętrzną pocztę"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otrzymanie rachunku do zapłaty"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmiana ustawień bezpieczeństwa"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potwierdź operacje kodem z wiadomości"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potwierdź autoryzację kodem z wiadomości"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potwierdź przy \"Dokonywaniu operacji finansowych za pomocą karty\""])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potwierdź zmianę ustawień bezpieczeństwa kodem z wiadomości"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potwierdź wydatki kodem z wiadomości"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmiany nie zapisane, zapisać?"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie zapisuj"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz"])}
}