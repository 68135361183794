export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جواز السفر الرقمي"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المزيد من التفاصيل"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معرف المستخدم:"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ التسجيل:"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["آخر تسجيل دخول:"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أصولي"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحسابات"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض الكل"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعبئة"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سحب"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد حسابات"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور التأكيدية"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لإكمال العمليات، يجب عليك إنشاء كلمة مرور تأكيدية."])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القوالب"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكل"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المبلغ"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المدفوعات الشائعة"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأخبار"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إخفاء"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التالي..."])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد أصول"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يخلق"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["توازن"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ينسحب"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اشحن رصيدك"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تبادل"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["للمستخدم"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التوازن العام"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض في"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرصيد غير القانوني"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["توازن فيات"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات جديدة"])}
}