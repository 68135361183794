export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All orders"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filters"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I give"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I receive"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rate"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operations"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["commissions"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No applications"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Min summ to send"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max summ to send"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Min summ to get"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max summ to get"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of attempts to complete a transaction"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency to send"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency to get"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account for send"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account for get"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of subdealers"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account not selected"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period from"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period to"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Today"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yesterday"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Week"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Month"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date type not selected"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expire"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finish"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Which part is fixed and which part is variable"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The amount given is fixed"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The amount to be received is fixed"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type order"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The user wants to quickly exchange for the best counter offers"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exchange at a strictly specified rate"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processing has not started"])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processing started"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partially completed"])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fully completed"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completely canceled"])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partially implemented and cancelled"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outgoing asset"])},
  "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incoming asset"])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date type"])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum amount is greater than maximum amount"])}
}