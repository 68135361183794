export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعطي"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تستلم"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأصل"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أعطي"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["داخلي"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أستلم"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأصول الداخلية"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم يتم العثور على أصول"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأصول الخارجية"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خارجي"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المحدد:"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الداخلية"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الخارجية"])}
}