export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Валютні пари"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створити валютну пару"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редагувати валютну пару"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити валютну пару"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дії"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Валюта, що віддається"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Валюта, що отримується"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шлюз"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип оновлення"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Валютні пари не знайдено"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви дійсно хочете видалити валютну пару"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["прив'язаний до іншої валюти"])}
}