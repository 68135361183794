export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrace"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osobní účet"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firemní účet"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Příjmení:"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jméno:"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patronymic: (pokud existuje):"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přijatelná jsou písmena latinky, pomlčka a apostrof."])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bond"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latinská písmena jsou přijatelná, stejně jako pomlčka, mezera a apostrof."])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["James"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádný"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latinská písmena jsou přijatelná, stejně jako pomlčka, uvozovky, mezera, číslice a apostrof."])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"My Company\" Ltd"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heslo"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latinská písmena (velká a malá), číslice, symboly _ - . ! $ % ^ * ="])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zopakujte heslo"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesla se neshodují"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zpět"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrace"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Další"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stav"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uživatelská data"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heslo"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uveďte správnou emailovou adresu nebo telefonní číslo. Telefonní číslo uvádějte v mezinárodním formátu, například: +499001234567."])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úspěšně jste se zaregistrovali"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyní můžete"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["přihlásit se do osobního kabinetu"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Váš účet bude ověřen naším zaměstnancem, o výsledcích jeho aktivace vás budeme informovat později"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email byl odeslán s odkazem na potvrzení registrace"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na vaši emailovou adresu"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Po prvním přihlášení vám bude nabídnuto potvrdit telefonní číslo"])}
}