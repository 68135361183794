export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appareils ATM"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un appareil"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type d'appareil"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une cassette"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de cassette"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer l'appareil"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retirer la cassette"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer l'appareil ?"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer la cassette ?"])}
}