export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активность"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Описание актива"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет объявлений"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мои объявления"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мои объявления"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новое объявление"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активность"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активно"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неактивно"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Направление"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Внешний актив"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Внутренний актив"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Описание"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отдаю"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получаю"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лимиты"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата публикации"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Остаток на счёте"])}
}