export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar pagamento"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calcular comissão de devolução"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devolver pagamento"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transação"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hash no blockchain"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risco AML"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantia"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comissão"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para crédito"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se você concorda com as condições alteradas, confirme o crédito:"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se estas condições não forem adequadas, você pode devolver os fundos para a conta do remetente, descontando os custos de devolução:"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transação"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no valor de"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tem risco AML"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["portanto, a comissão para seu crédito foi recalculada"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["assim, a quantia para crédito é"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma nova entrada encontrada"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data do pagamento"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devolução"])}
}