export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أعطي"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أستلم"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعليق"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السبب"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلغاء الصفقة"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد المشاركة في الصفقة"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رفض الصفقة"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تمديد وقت الانتظار"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلغاء الصفقة"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حالة المستخدم في الصفقة غير محددة. حدث خطأ ما"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طلب التحكيم"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الموافقة على التحكيم"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعلام بإتمام التحويل"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد استلام التحويل"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقييم الصفقة"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إرسال التقييم"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صفقة № "])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المراسل"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المهلة الزمنية"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اليوم"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غدًا"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلغاء التحكيم"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلغاء التحكيم"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لقد طلبت التحكيم"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لقد تم طلب التحكيم ضدك"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التحكيم جاري (أنت المدعي)"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التحكيم جاري (أنت المدعى عليه)"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بعد انتهاء المؤقت، ستتم الموافقة على التحكيم تلقائيًا ولن تتمكن بعد الآن من إضافة إجاباتك على أسئلة التحكيم"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طلب التحكيم"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هناك تحكيم"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هناك تحكيم"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اتخاذ قرار بشأن التحكيم: الدفع الداخلي"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سلوك"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يلغي"])}
}