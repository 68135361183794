export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сервіс масових платежів дозволяє швидко переводити кошти іншим користувачам та здійснювати платежі провайдерам послуг."])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У розділі"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завантажити файл платежів"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ви можете завантажити файл у форматах Excel (XLS, XLSX, CSV) або XML."])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список завдань"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["дозволяє переглядати статуси раніше створених запитів на масові платежі."])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви можете створити файл у редакторі таблиць Excel."])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завантажити приклад файлу"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Важливі зауваження:"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Формат файлу - CSV, XLS або XLSX."])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Файл має бути без заголовків."])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Щоб уникнути перетворення довгих номерів на експоненціальний формат (наприклад, \"5,46546E+15\") та видалення початкових нулів, ви можете вставити апостроф (') перед числом або встановити формат комірки як текст перед введенням даних."])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Формат колонок для платежів постачальникам послуг"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список колонок у файлі:"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код оператора"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Вказується зі списку операторів, наведених"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["тут"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". (Для всіх операторів мобільного зв'язку можна вказувати код оператора 7000 - у цьому випадку оператор буде визначений автоматично за номером телефону.)"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Сума вказується в тикері активу, з якого здійснюється переказ. Якщо такого тикера немає, до операції автоматично застосовується системний коефіцієнт конвертації."])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коментар до платежу"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Заповнювати не обов'язково, можна залишити порожнім; довжина не більше 255 символів."])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Параметр платежу 1"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". \nЗначення параметра, необхідного оператором, наприклад, призначається номер телефону або адреса крипто-актуальної діяльності. \nБагато операторів обмежені одним параметром оплати, але якщо кількість параметрів більше однієї, то вам потрібно вказати їх у сусідніх стовпцях."])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Параметр платежу 2"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Параметр платежу N"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Параметри платежу можна переглянути, перейшовши до оплати якогось оператора:"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Формат колонок для масових переказів користувачам"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код оператора"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Вказується 0 або взагалі не вказується."])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Сума відображається в тикері рахунку, з якого переводиться актив. Якщо такого тикера немає, до операції автоматично застосовується коефіцієнт перерахунку системи."])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коментар до платежу"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Заповнювати не обов'язково, можна залишити порожнім; довжина не більше 255 символів."])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рахунок одержувача"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Тут вказується номер рахунку користувача, якому необхідно виконати переказ (20 цифр)."])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Формат колонок для масової видачі позик кредитним агентом"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Цей рядок вказує, що платежі є позиками."])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Сума вказується у валюті кредитного продукту."])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коментар до кредиту"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Заповнювати не обов'язково, можна залишити порожнім; довжина не більше 255 символів."])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID позичальника"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Зверніть увагу, що у позичальника має бути цифровий паспорт Mfo-BorrowPerson."])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код кредитного продукту"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Переглянути його можна у списку кредитних продуктів на ручному наданні позики."])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Період"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Просто число періодів (які саме періоди використовуються, визначено у кредитному продукті, наприклад, це можуть бути дні, тижні, місяці тощо)"])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код оператора (номер форми)"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Заповнення цього та наступних полів не обов'язкове, якщо їх не заповнювати, позичальник зможе пізніше самостійно здійснити виведення кредиту через свій особистий кабінет."])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скасувати у разі помилки"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Скасувати (1) наданий кредит, якщо транзакція виведення завершиться з помилкою, або залишити для подальшого самостійного виведення (0)."])},
  "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Параметр платежу 1"])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Вказується значення параметра, потрібного оператором, наприклад, номер телефону. Багато операторів обмежуються одним параметром платежу, але якщо кількість параметрів більше одного, вам необхідно вказувати їх усі в сусідніх від першого стовпцях."])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Параметр платежу 2"])},
  "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Параметр платежу N"])},
  "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Файл повинен вказувати облікові записи або адреси криптовалют одержувачів в одній валюті, оскільки списання коштів здійснюється з одного облікового запису, а не з декількох."])}
}