export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["255 अक्षरों तक की पंक्ति"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पूर्णांक"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["20 अंक"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0 या 1"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भिन्नात्मक संख्या, परिशुद्धता दो अंक, बिंदु विभाजक"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पूर्णांक"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["255 अक्षरों तक की स्ट्रिंग, वैकल्पिक"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पूर्णांक, वैकल्पिक"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्ट्रिंग 32 अक्षर"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्टोर भुगतान कोड; प्रत्येक भुगतान के लिए अद्वितीय होना चाहिए; एक स्ट्रिंग प्रेषित करते समय"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एक अद्वितीय नंबर स्वचालित रूप से उत्पन्न हो जाएगा"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पंजीकरण के बाद स्टोर नंबर आवंटित किया जाता है"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्टोर खाता संख्या; यह खाता यह भी निर्धारित करता है कि भुगतान किस मुद्रा में किया जाना चाहिए (अधिकांश मामलों में, उपयोगकर्ता किसी भी मुद्रा में भुगतान कर सकता है, और धनराशि स्वचालित रूप से प्राप्तकर्ता की मुद्रा में परिवर्तित हो जाएगी)"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ग्राहक को निर्दिष्ट राशि बदलने से रोकें (1 - निषेध, 0 - अनुमति)"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लाभार्थी के खाते की मुद्रा में लाभार्थी की राशि"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वह फॉर्म संख्या जिसके माध्यम से भुगतान स्वीकार किया जाता है"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भुगतान के उद्देश्य के रूप में भुगतानकर्ता को दिखाई जाने वाली टिप्पणी"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अतिरिक्त वैकल्पिक पैरामीटर जो भुगतान परिणाम स्क्रिप्ट के साथ-साथ वापसी पृष्ठों को भी भेजा जाएगा"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वह URL जहां ग्राहक सफल भुगतान के बाद वापस आएगा; यदि निर्दिष्ट नहीं है, तो इसे स्टोर सेटिंग्स से लिया जाएगा"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वह URL जहां ग्राहक त्रुटि के बाद वापस आएगा; यदि निर्दिष्ट नहीं है, तो इसे स्टोर सेटिंग्स से लिया जाएगा"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वह URL जहां भुगतान परिणामों की सूचना भेजी जाएगी; यदि निर्दिष्ट नहीं है, तो इसे स्टोर सेटिंग्स से लिया जाएगा"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बिल की वैधता अवधि (मिनटों में), डिफ़ॉल्ट 60"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्टोर की गुप्त कुंजी का उपयोग करके संदेश पर हस्ताक्षर करना"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0 या 1"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 - यदि सफल हो, 0 - अन्यथा"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पूर्णांक"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["त्रुटि कोड (यदि कोई त्रुटि नहीं है तो 0)"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्ट्रिंग"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["त्रुटि पाठ (या भुगतान स्थिति का विवरण)"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0 या 1"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एक संकेत कि अंतिम भुगतान स्थिति अभी ज्ञात नहीं है"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्टोर भुगतान कोड"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लेनदेन संख्या"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मर्चेंट प्रोटोकॉल"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वेबसाइट पर भुगतान स्वीकार करना केवल सिस्टम में पंजीकृत ऑनलाइन स्टोर के लिए संभव है। स्टोर पंजीकृत करने के लिए, पृष्ठ पर जाएं"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्टोर पंजीकरण"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भुगतान के लिए उपयोगकर्ता को मर्चेंट पर रीडायरेक्ट करना"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भुगतान करने के लिए, उपयोगकर्ता को URL पर रीडायरेक्ट करना आवश्यक है: https://merchant.[आपका डोमेन] और निम्नलिखित पैरामीटर GET या POST के माध्यम से भेजें:"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नाम"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विवरण"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["डेटा प्रकार"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हस्ताक्षर"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रेषित पैरामीटर को जोड़कर और गुप्त कुंजी के साथ hmac विधि द्वारा हैश करके गणना की जाती है। जोड़े गए पैरामीटर की संख्या और क्रम पैरामीटर पर निर्भर करता है"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["identified"])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["निश्चित राशि"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्रम निम्नानुसार है:"])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ext_transact + num_shop + keyt_shop + identified + sum + payform + comment + free_param + url_success + url_decline + url_callback"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उस लिंक के लिए जहां ग्राहक"])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भुगतान, क्रम निम्नानुसार है (राशि हस्ताक्षर में शामिल नहीं है):"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ext_transact + num_shop + keyt_shop + identified + payform + comment + free_param + url_success + url_decline + url_callback"])},
  "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भुगतान लिंक पृष्ठ पर जनरेट किया जा सकता है"])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'मेरे स्टोर'"])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फॉर्म का उदाहरण:"])},
  "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ऑपरेशन पूरा होने के बाद स्टोर वेबसाइट पर वापसी"])},
  "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यदि भुगतानकर्ता भुगतान से इनकार करता है या ऑपरेशन त्रुटि के साथ समाप्त होता है, तो भुगतान प्रणाली स्टोर सेटिंग्स में निर्दिष्ट या फॉर्म के साथ भेजे गए उपयुक्त पृष्ठ पर जाने का सुझाव देती है"])},
  "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["url_decline"])},
  "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यदि ऑपरेशन सफलतापूर्वक पूरा हो जाता है, तो पृष्ठ पर जाएं"])},
  "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["url_success"])},
  "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ये स्क्रिप्ट भी भुगतान परिणाम प्राप्त करेंगी (स्टोर सेटिंग्स में निर्दिष्ट विधि द्वारा। डिफ़ॉल्ट - GET):"])},
  "62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नाम"])},
  "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विवरण"])},
  "64": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["डेटा प्रकार"])},
  "65": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हस्ताक्षर"])},
  "66": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रेषित पैरामीटर को जोड़कर और गुप्त कुंजी के साथ hmac विधि द्वारा हैश करके गणना की जाती है।"])},
  "67": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जोड़े गए पैरामीटर का क्रम:"])},
  "68": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ext_transact + amount + success + result + result_text + free_param"])},
  "69": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्टोर भुगतान स्थिति सूचना (कॉलबैक)"])},
  "70": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भुगतान निष्पादन के बाद, भुगतान परिणाम स्टोर पंजीकरण के दौरान निर्दिष्ट स्क्रिप्ट को भेजे जाते हैं"])},
  "71": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["result_url"])},
  "72": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यह स्क्रिप्ट सफल भुगतान और त्रुटि दोनों मामलों के लिए डेटा प्राप्त करती है।"])},
  "73": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ये स्क्रिप्ट भी भुगतान परिणाम प्राप्त करेंगी (स्टोर सेटिंग्स में निर्दिष्ट विधि द्वारा। डिफ़ॉल्ट - GET)।"])},
  "74": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कुछ मामलों में, परिणामों के साथ सूचना नहीं पहुंचती (उदाहरण के लिए, यदि ग्राहक भुगतान करने गया और ब्राउज़र पृष्ठ बंद कर दिया)।"])},
  "75": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नाम"])},
  "76": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विवरण"])},
  "77": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["डेटा प्रकार"])},
  "78": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हस्ताक्षर"])},
  "79": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रेषित पैरामीटर को जोड़कर और गुप्त कुंजी के साथ hmac विधि द्वारा हैश करके गणना की जाती है।"])},
  "80": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जोड़े गए पैरामीटर का क्रम:"])},
  "81": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["transact + ext_transact + amount + success + result + result_text + free_param"])},
  "82": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हस्ताक्षर की गणना"])},
  "83": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हस्ताक्षर निर्दिष्ट क्रम में आवश्यक पैरामीटर को जोड़कर और hmac विधि द्वारा हैश करके बनाया जाता है।"])},
  "84": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जोड़े गए पैरामीटर का क्रम और संख्या ऊपर वर्णित है।"])},
  "85": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["निम्नलिखित सूचियां विभिन्न भाषाओं में हस्ताक्षर की गणना करने वाले फ़ंक्शन के उदाहरण दिखाती हैं, जहां हस्ताक्षर के लिए जोड़ी गई स्ट्रिंग पैरामीटर के रूप में पास की जाती है"])},
  "86": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सूची 1. PHP में हस्ताक्षर की गणना।"])},
  "87": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सूची 2. Python में हस्ताक्षर की गणना।"])},
  "88": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सूची 3. Perl में हस्ताक्षर की गणना।"])},
  "89": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भुगतान के लिए"])},
  "90": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["राशि बदल सकता है"])},
  "91": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उत्पाद भुगतान"])},
  "92": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भुगतान करें"])},
  "93": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कुंजी के साथ हस्ताक्षर जनरेट करें"])},
  "94": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["signed_str"])},
  "95": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["और पैरामीटर में गुप्त वाक्यांश"])},
  "96": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["key"])}
}