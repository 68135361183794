export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spálení aktiva"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Částka ke spálení"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Účet pro vrácení"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spálit"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Částka ke spálení"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Částka ke spálení nemůže být větší než částka emise"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Návrat krytí"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["K vrácení"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Částka, která zůstane v rezervě po spálení aktiva"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Částka, která se vrátí na váš účet po spálení aktiva"])}
}