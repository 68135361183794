export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ऑपरेशन दोहराएं"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ऑपरेशन इतिहास"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खातों की सूची"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सफलता"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["त्रुटि"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अज्ञात त्रुटि"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टेम्पलेट्स"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मेरी संपत्ति"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नए संपत्ति की जानकारी"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अतिरिक्त इमिशन"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वर्चुअल संपत्ति की सूची"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ब्लॉकचेन से लिंक करें"])}
}