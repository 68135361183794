export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата поступления"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата обработки"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кол-во платежей"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["в т.ч. успешных"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["в т.ч. ошибочных"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Массовые платежи"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакция"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Результат"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поставщик услуг"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комиссия"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комментарий"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1-й параметр"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Платежей не найдено"])}
}