export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Беремін"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["қалдық "])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аламын"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сауда жұбы"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шоттар"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Айырбастау түрі"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нарықтық айырбастау - тапсырысты орындау кезінде ең тиімді бағам бойынша айырбастау қолданылады. Назар аударыңыз, алынатын сома айырбастау кезіндегі тапсырыстарға байланысты өзгеруі және есептелген сомадан өзгеше болуы мүмкін."])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тіркелген баға бойынша айырбастау сіз көрсеткен сома мен бағам бойынша жүзеге асырылады. Тапсырыс орналастырылады. Егер көрсетілген бағам нарықтық бағамнан айтарлықтай өзгеше болса, тапсырысты жабу көп уақытты алуы мүмкін."])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бағам (болжам):"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бағам"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бағам анықталмаған,"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["бағамды өзіңіз орнату ұсынылады"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сома"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Барлығын қолдану"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комиссия"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комиссия"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жиыны:"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нарық бойынша"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(ең жақсы қарсы ұсынысқа жылдам айырбастау)"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тіркелген бағам"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(көрсетілген бағам бойынша тапсырыс орналастыру)"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сәйкес келетін шоттар жоқ"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бағам"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24 сағаттағы өзгеріс"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24 сағаттағы ауытқулар"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24 сағаттағы сауда көлемі"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сома нөлге тең болмауы керек!"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Айырбастау"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қорытынды деректерді көру үшін сауда жұбын таңдаңыз"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бірдей активтерді айырбастауға болмайды"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қосымша"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Өтініш іріктеуге кірмейді"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["дейін"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шектеулер"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'Бағам' өрісінде 0-ден өзгеше мән болуы керек"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шотта қаражат жеткіліксіз"])}
}