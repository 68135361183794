export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择地址"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["居住地"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地址"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国家"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地区"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["区域"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["区"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["城市"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["城市区域"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["居住地"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["街道"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地址附加项"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["详细信息"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["房子"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邮政编码"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地址"])}
}