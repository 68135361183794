export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создание электронного чека"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Счет списания"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номинал каждого чека"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комментарий"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество чеков"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назад"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Серия:"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер:"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код активации:"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Созданы чеки"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скачать файл с чеками"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохраните информацию - после закрытия этой страницы коды активации будут скрыты."])}
}