export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zusätzliche Informationen"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktionen"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einzahlung"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auszahlung"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Adresse erstellen"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Konto erstellen"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausgewählte löschen"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Vermögenswerte"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontenliste"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontoname"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie die Konten wirklich löschen?"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie das Konto wirklich löschen"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Vermögenswerte gefunden"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Konten gefunden"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adressumbenennung"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie die Adressen wirklich löschen?"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie die Adresse wirklich löschen"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht-Fiat"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiat"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operationen"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktionen"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachfüllen"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurückziehen"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Austausch"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verstecken"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ändern"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])}
}