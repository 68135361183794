export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दस्तावेज़ीकरण"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["व्यापारी प्रोटोकॉल"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["व्यापारी को रीडायरेक्ट"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्टोर पर वापसी"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्टोर अधिसूचना"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हस्ताक्षर गणना"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एम्बेडेड विजेट"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्थापना"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कॉल"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्टोर से प्रतिक्रिया"])}
}