export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Файл таңдау"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бұл файл пішіміне қолдау көрсетілмейді. \nҚабылданған форматтар:"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Белгісіз формат"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Файлдың тым үлкен көлемі, максималды өлшемі: "])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жүктеу үшін файлдарды таңдаңыз немесе осында сүйреңіз"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Таңдау"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жүктеу"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бас тарту"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Файлдар жүктелді"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["файлдар"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жою"])}
}