export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authorization"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login:"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password:"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot your password?"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First time here?"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create an account"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password recovery"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone or email:"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restore"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password recovery"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name:"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name:"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Second Name:"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of birth:"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User found"])}
}