export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre:"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El nombre es el nombre completo del criptoactivo, como será visto por todos: el propio emisor, compradores, vendedores, procesadores, etc."])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TICKER"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Una abreviatura de 3 a 10 caracteres. \nSe aceptan letras latinas mayúsculas, guiones (guiones) y números."])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoría:"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elige la categoría que más se ajusta a tu cripto-activo"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formatos admitidos: png, jpg, jpeg, svg. \nTamaño de archivo no superior a 1 MB, 500*500 píxeles"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Describe por qué el emisor emite este cripto-activo, en qué sector trabaja o trabajará, cuál es su misión"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logotipo:"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objetivo/misión/propósito:"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precisión:"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emisión:"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costo por unidad:"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de decimales para la cantidad. Por ejemplo, la precisión de la mayoría de las monedas fiduciarias como el dólar estadounidense o el euro es 2."])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de unidades del cripto-activo emitido. La suma de la emisión estará disponible en tu cuenta después de completar la operación de creación del cripto-activo."])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costo declarado por unidad:"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garantía:"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta:"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persona responsable:"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decisión de emisión del cripto-activo:"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportar operaciones al blockchain:"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de blockchain:"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activar operaciones con el cripto-activo:"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restricciones/Sanciones:"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indica a los ciudadanos o residentes fiscales de qué países de la lista está prohibido usar el cripto-activo en sus operaciones: "])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otras restricciones y condiciones:"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección de hosting:"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección del libro blanco:"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico:"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telegram:"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twitter:"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook:"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otros contactos:"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anterior"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siguiente"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear cripto-activo"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre y propósito"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Características financieras"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emisor"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportar al blockchain"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Límites y restricciones"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactos"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No definido"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vinculación fija a monedas fiduciarias y otros activos"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emisor (Persona que emite el cripto-activo)"])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oferente (Persona que distribuye el cripto-activo"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remitente"])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destinatario"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yo (activar patrocinio)"])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colocación del cripto-activo"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un cripto-activo significa una representación digital de valor o derecho que puede ser transferida y almacenada electrónicamente utilizando tecnología blockchain. Los cripto-activos NO incluyen monedas fiduciarias, valores y otros instrumentos financieros y activos sujetos a regulación estatal separada."])},
  "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar activo criptográfico"])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administración"])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceptación"])},
  "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motivo de la negativa a aceptar"])},
  "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cuenta del oferente"])},
  "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta del oferente en la moneda vinculante"])},
  "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El oferente es un representante del emisor de un activo en operaciones de compra/venta en el mercado primario. \nLa cuenta es pasiva. \nPuede ser una cuenta de emisión de activos"])},
  "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La cuenta es pasiva, vinculada en moneda. \nDebe pertenecer al mismo usuario que la cuenta anterior."])},
  "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permitir a los usuarios comprar un activo en cajeros automáticos"])},
  "62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permitir la venta de activos por parte de los usuarios en ATMS"])},
  "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulario de compra posventa"])},
  "64": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las transacciones del mercado secundario se realizan a través de pasarelas."])},
  "65": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulario de ventas de posventa"])},
  "66": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las transacciones del mercado secundario se realizan a través de pasarelas."])},
  "67": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permitir transacciones con un activo en el intercambio."])},
  "68": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permitir transacciones de activos vía P2P"])},
  "69": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si es un empresario individual o un representante de una entidad jurídica, debe descargar el archivo de decisión sobre la liberación de un activo digital en formato PDF. \nLa solución debe contener todas las características principales del activo virtual y estar firmada con la firma electrónica del empresario individual o de esta persona jurídica. \nPuede encontrar un ejemplo de una decisión sobre la emisión de un activo digital en las páginas de nuestro sitio web en la sección \\\"Documentos\\\""])},
  "70": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colocar un activo virtual"])},
  "71": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta de emisión sustituta"])},
  "72": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar entre cuentas de emisor"])},
  "73": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto a cancelar:"])},
  "74": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sin cuenta"])},
  "75": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear una cuenta"])},
  "76": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Máx. \nemisión:"])},
  "77": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El tamaño de la emisión excede el máximo. \nemisiones"])},
  "78": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Máx. \nLa emisión es menor que el tamaño de la emisión o igual a cero."])},
  "79": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las formas están ausentes"])}
}