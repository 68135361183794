export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поповнюваний рахунок"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Спосіб платежу"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва шаблону:"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберегти"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберегти шаблон"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продовжити"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаблони"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поповнення рахунку"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Реквізити переказу"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перевірити переказ"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Інформація про перекази"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Інструкція"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поповнення адреси"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поповнювана адреса"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Експорт"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["До списку завдань"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адреса переказу"])}
}