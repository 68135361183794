export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Валюта жұптары"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Валюта жұбын құру"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Валюта жұбын өңдеу"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Валюта жұбын жою"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Әрекеттер"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Берілген валюта"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Алынған валюта"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шлюз"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жаңарту түрі"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Валюта жұптары табылмады"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Валюта жұбын шынымен жойғыңыз келе ме"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["басқа валютаға байланған"])}
}