export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorisierung"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login:"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort:"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort vergessen?"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zum ersten Mal hier?"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto erstellen"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort wiederherstellen"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon oder E-Mail:"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wiederherstellen"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort wiederherstellen"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname:"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname:"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mittlerer Name:"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geburtsdatum:"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einloggen"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finden"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer gefunden"])}
}