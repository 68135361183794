export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I give"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["residual "])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I receive"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trading pair"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accounts"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type of exchange"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer by market - exchange will be used at the most favorable rate at the time of execution of the order. Attention, the amount to be received may change and differ from the calculated one depending on the orders at the time of the exchange."])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A fixed price transfer occurs according to the amount and rate you specified. An order will be placed. If the specified rate is very different from the market rate, then closing the order may take a long time."])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rate (forecast):"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rate"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course not determined, "])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["it is recommended to set the course yourself"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sum"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use all"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commission"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commission"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total:"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["According to the market"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(quick exchange with best counteroffer)"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed rate"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(submit an order with a given exchange rate)"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No matching accounts"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rate"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daily change"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daily fluctuations"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trade volume per day"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The sum cannot be equal to zero!"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To see the summary data, select the trading pair."])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You cannot exchange identical assets"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additionally"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The application is not included in the selection until"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restrictions"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The 'Rate' field must have a value other than 0"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insufficient funds in the account"])}
}