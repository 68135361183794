export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dou"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["saldo "])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recebo"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Par de capital"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contas"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de câmbio"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferência de mercado - será utilizada a taxa mais vantajosa no momento da execução do pedido. Atenção, o valor a receber pode mudar e diferir do calculado dependendo dos pedidos no momento da troca."])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A transferência a preço fixo ocorre de acordo com o valor e a taxa que você especificou. Um pedido será feito. Se a taxa especificada for muito diferente da taxa de mercado, o fechamento do pedido pode levar muito tempo."])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxa (previsão):"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxa"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxa não definida,"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["recomenda-se definir a taxa manualmente"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soma"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usar tudo"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comissão"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comissão"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total:"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pelo mercado"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(troca rápida com a melhor contraoferta)"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxa fixa"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(fazer pedido com taxa especificada)"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sem contas adequadas"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxa"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mudança em 24 horas"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flutuações em 24 horas"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volume de negociações em 24 horas"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O valor não pode ser zero!"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trocar"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para ver os dados do resumo, selecione um par de negociação"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não é possível trocar ativos idênticos"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionalmente"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O pedido não está incluído na seleção até"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["para"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restrições"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O campo 'Taxa' deve ter um valor diferente de 0"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fundos insuficientes na conta"])}
}