export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segurança"])},
  "Antiphishing": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurar antiphishing é um mecanismo adicional de proteção ao trabalhar com a conta."])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A frase de boas-vindas definida por você será exibida na parte superior de qualquer página da conta."])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A frase correta mostrará que você está no site correto, enquanto uma frase incorreta será um sinal: não realize operações e entre em contato imediatamente com o serviço de segurança do nosso sistema."])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ativar antiphishing:"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frase antiphishing:"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvar"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anti-pesca"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As alterações entrarão em vigor após o próximo login"])}
  },
  "IP": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurar o acesso por endereço IP impedirá você (ou um invasor) de acessar o sistema a partir de um endereço não incluído na lista de endereços permitidos."])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restringir o acesso à conta por endereços IP"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar endereços"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereço: "])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bloco de endereços: "])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a:"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seu IP: "])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de endereços"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nº"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do endereço"])},
    "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para endereço"])},
    "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acesso"])},
    "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ações"])},
    "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excluir"])},
    "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum endereço disponível"])},
    "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvar"])},
    "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proibir"])},
    "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permitir"])},
    "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digite o endereço IP"])},
    "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereço IP inválido"])},
    "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum endereço IP ou bloco de endereços especificado."])},
    "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum endereço IP permitido para autorização. Você não conseguirá acessar sua conta."])},
    "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acesso por IP"])},
    "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de:"])}
  },
  "Magic": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A palavra mágica é uma configuração de segurança adicional."])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se a palavra mágica estiver ativada, toda vez que você fizer login, o sistema pedirá que você insira alguns caracteres (em sua ordem específica) da palavra mágica."])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palavra mágica:"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicitar a palavra mágica:"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ao entrar na conta:"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ao pagar via comerciante:"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ao usar o cartão:"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvar"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palavra mágica"])}
  },
  "Onekey": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As chaves de uso único aumentam significativamente a segurança do sistema."])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No momento do login, o programa pedirá que você insira não apenas o login e a senha, mas também a próxima chave de uso único. A ativação é feita sequencialmente. A última chave é válida até que você crie uma nova série de chaves."])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usar chaves de uso único"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de chaves"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerar"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportar"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nº"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chave"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chaves de uso único"])}
  },
  "Password": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senha antiga:"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nova senha:"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmação de senha:"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvar"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"Nova senha\" e \"Confirmação de senha\" não coincidem."])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alterar senha"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De 4 a 63 caracteres. Caracteres permitidos: letras latinas, números e símbolos"])}
  },
  "PayPassword": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senha antiga:"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nova senha:"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmação de senha:"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nova senha:"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmação de senha:"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvar"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvar"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"Nova senha\" e \"Confirmação de senha\" não coincidem."])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A senha não deve conter caracteres cirílicos"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar senha de confirmação"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alterar senha de confirmação"])},
    "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para a página inicial"])},
    "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De 4 a 65 caracteres. Caracteres permitidos: letras latinas, números e símbolos"])}
  },
  "SecretQuestion": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se você esquecer a senha para acessar a conta, poderá recuperá-la respondendo à pergunta secreta."])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nesta seção, você pode alterar a pergunta/resposta secreta."])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pergunta secreta"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sua pergunta:"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resposta:"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvar"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pergunta secreta"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sua pergunta"])}
  }
}