export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anúncio"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrição"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalhes"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mínimo"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Máximo"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limite de quantia"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tempo de espera (min)"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anúncio ativo"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponível apenas por link"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponível apenas para usuários verificados"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvar"])}
}