<template>
    <div>
        <div class='d-flex justify-content-between neue mb-3'>
            <h4>{{ $t('components.service.modal.ReEshop.1') }} {{ this.content.name }}</h4>
            <i class='big item-i x_red pointer' v-on:click='this.close'></i>
        </div>
        <div v-if="this.data">
            <div class = 'mb-3'>
                <Input type='text' :label="$t('views.Merchant.New.4')" :dataValue='this.data.name' :first="true" :require="true" id='name' @updateParentState="this.updateState" />
                <Input type='text' :label="$t('views.Merchant.New.5')" :dataValue='this.data.address' :require="true" id='address' @updateParentState="this.updateState" />
                <Select :label="$t('views.Merchant.New.7')" :require="true"  id='type_eshop' :dataValue='this.data.type_eshop' :keywords.sync='this.eshop_keywords' @updateParentState="this.updateState"/>
                <Input type='textarea' :label="$t('views.Merchant.New.6')" :dataValue='this.data.description' id='description' @updateParentState="this.updateState" />
                <Switch :label="$t('views.Merchant.New.8')" :dataValue='this.data.active'  id='active' @updateParentState="this.updateState"/>
            </div>

            <div class = 'mb-3'>
            <Input type='text' :label="$t('views.Merchant.New.9')" :dataValue='this.data.skeys' :first="true" :require="true" id='skeys' @updateParentState="this.updateState" />
            <div class="d-flex flex-column flex-sm-row mt-3 p-0 mr-0  g-3 align-items-center input">
                <label  class="col-form-label mt-0 pl-0 pt-0 col-12 col-sm-3 pb-0 pr-1 input-label"></label>
                <div class="col-12 col-sm-9 mt-0 p-0">
                    <p class='mb-0'>{{$t('views.Merchant.New.17')}}</p>
                    <div>
                        <Button :name="$t('views.Merchant.New.16')" type='btn-outline-warning btn' v-on:click="this.randomPasw"/>
                    </div>
                    <p class='mb-0'>{{$t('views.Merchant.New.18')}}</p>
                </div>
            </div>
            <Switch :label="$t('views.Merchant.New.10')" :dataValue='this.data.autopay'  id='autopay' @updateParentState="this.updateState"/>
            </div>

            <div class = 'mb-3'>
                <Switch :label="$t('views.Merchant.New.11')" :dataValue='this.data.is_result_url'  id='is_result_url' @updateParentState="this.updateState"/>
                <Input type='text' :label="$t('components.service.modal.PayHref.8')" :dataValue='this.data.result_url' id='result_url' @updateParentState="this.updateState" />
                <Input type='text' :label="$t('components.service.modal.PayHref.6')" :dataValue='this.data.success_url' id='success_url' @updateParentState="this.updateState" />
                <Input type='text' :label="$t('components.service.modal.PayHref.7')" :dataValue='this.data.fail_url' id='fail_url' @updateParentState="this.updateState" />
            </div>

            <div class="d-flex container-fluid g-3 align-items-center justify-content-center input mr-0">
                <div class="col-sm-2">
                    <Button :name="$t('views.Merchant.New.15')" type='btn-warning btn-lg' class="col-12" v-on:click="this.changeEshop"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Input from '@/components/service/Input';
import Select from '@/components/service/Select';
import Switch from '@/components/service/Switch';
import Button from '@/components/service/Button';

export default {
    components: {
        Button,
        Select,
        Input,
        Switch
    },
    props: {
        close: [Function],
    }, 
    data () {
        return { 
            content: this.$store.state.modal.content,
            eshop_keywords: [],
            data: false,
            keyt: '',
            stringData: '',
        }
    },
    mounted() {
        console.log(this.content);
        this.init();
    },

    methods: {
        init() {
            this.axios.post('MerchantSystemsInit', {data: {num_eshop: this.content.num}}).then(res => {
                this.eshop_keywords = res.data.body.merchant_types;
                this.data = res.data.body.eshop.advanced;
            });
        },
        updateState(arg) {
            this.data[arg.id] = arg.value;
        },
        changeEshop() {
            let params = this.data;
            params.num_eshop = this.content.num;

            delete params.accept;
            delete params.shop_comiss_persent;
            delete params.min_pay_comiss;
            delete params.id;
            delete params.pay_comiss_persent;
            delete params.accept_terminal;
            delete params.edit;
            delete params.min_shop_comiss;

            this.axios.post('SetParamsEshop', {data: this.data}).then(res => {
                if (res.data.body.result === '0') {
                    this.$store.commit('ROUTER_KEY');
                }
            })
        },
        randomPasw() {
	        var r;
            let len = 40;
	        var digit = new Array("0", "1", "2", "3", "4", "5", "6", "7", "8", "9");
	        var halp = new Array("A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "Y", "Z");
	        var lalp = new Array("a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "y", "z");

	        let pasw = "";

	        for (var i = 0; i < len; i++) {
		        r = Math.random();
		        if ((r - 1.0 / 3.0) < 0.0) {
			        r = Math.floor(Math.random() * 9);
			        pasw += digit[r];
		        }
		        else if ((r - 2.0 / 3.0) < 0.0) {
			        r = Math.floor(Math.random() * 24);
			        pasw += halp[r];
		        }
		        else {
			        r = Math.floor(Math.random() * 24);
			        pasw += lalp[r];
		        }
	        }
	        this.data.skeys = pasw;
        }
       
        
    },
}
</script>

<style lang="scss" scoped>
    
</style>