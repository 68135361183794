export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořit elektronický šek"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debetní účet"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nominální hodnota každého šeku"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentář"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počet šeků"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zpět"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořit"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Série:"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Číslo:"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivační kód:"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Šeky vytvořeny"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stáhnout soubor s šeky"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložte si tyto informace - aktivační kódy budou po zavření této stránky skryty"])}
}