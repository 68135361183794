export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal Account"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Account"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name:"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name:"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Second Name:"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Russian letters are allowed, as well as hyphens and apostrophes."])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bond"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latin letters are allowed, as well as hyphen, space and apostrophe."])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["James"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["None"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latin letters are allowed, as well as hyphens, quotation marks, spaces, numbers, and apostrophes."])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LLC My Company"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latin letters (uppercase and lowercase), numbers, symbols _ - . ! $ % % ^ * = “ are allowed."])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat password"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwords don't match"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User data"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a valid email address or phone number. The phone number is in international format, for example: +499001234567."])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have successfully registered"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Now you can"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["log in to your personal account"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your account will be checked by our employee, we will inform you about the results of its activation later"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An email has been sent with a link to confirm registration."])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To your email address"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After the first authorization, you will be asked to verify your phone number"])}
}