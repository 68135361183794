export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dávám"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zůstatek "])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostávám"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapitálový pár"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Účty"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ výměny"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Převod na trh - bude použit nejvýhodnější kurz v době provedení příkazu. Pozor, částka k přijetí se může změnit a lišit od vypočtené částky v závislosti na příkazech v době směny."])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Převod za pevnou cenu probíhá podle zadané částky a kurzu. Bude zadán příkaz. Pokud se zadaný kurz výrazně liší od tržního, uzavření příkazu může trvat delší dobu."])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurz (předpoklad):"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurz"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurz není určen, "])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["doporučuje se zadat kurz ručně"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Součet"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Použít vše"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komise"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komise"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Celkem:"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na trh"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(rychlá směna za nejlepší protinabídku)"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pevný kurz "])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(zadání příkazu se zadaným kurzem)"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nejsou žádné vhodné účty"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurz"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Změna za 24 hodin"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kolísání za 24 hodin"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objem obchodů za 24 hodin"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Částka nesmí být nulová!"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Směnit"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chcete-li zobrazit souhrnné údaje, vyberte obchodní pár"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nelze vyměňovat stejné aktiva"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navíc"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplikace není zařazena do výběru do"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["na"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Omezení"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pole 'Kurz' musí mít hodnotu jinou než 0"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nedostatek prostředků na účtu"])}
}