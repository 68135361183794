export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ativo interno"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conta"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ativo externo"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupo"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome EN"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sinônimos"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Países"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se preenchido, o anúncio estará disponível apenas para usuários dos países especificados"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vincular ao ativo"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unidades de medida"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abreviado"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precisão"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrição"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalhes"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direção da transferência"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mínimo"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Máximo"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limite"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tempo de espera (min)"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O anúncio está ativo"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponível apenas por link"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponível apenas para usuários verificados"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para o anterior"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publicar"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avançar"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direção"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restrições"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criação de anúncio"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seu ativo externo"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não vincular"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sim"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reduzir taxa"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reverter"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma conta nesse ativo"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar conta"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Definir taxa"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxa correta"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reduzir taxa"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reverter"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mínimo maior que o máximo"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para onde e como deseja receber a transferência. Exemplo: Transferir no aplicativo do banco pelo número de telefone +3519990001111 (destinatário Alexandre N.)"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dar"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["receber"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ativos"])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalhes solicitados"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quais detalhes o tomador precisa enviar para que você possa fazer a transferência"])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecionar ativo"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecionar ativo"])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O que você deseja receber: fundos em um ativo interno, por exemplo, BTC, ou em um ativo externo, por exemplo, TOKEN GOLD?"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escreva um comentário sobre as características desta transação, por exemplo: \\\"O ativo externo é transferido apenas em um encontro pessoal\\\""])},
  "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de valores aos quais um ativo externo pertence, por exemplo, \"dinheiro\""])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome do ativo para exibição na plataforma"])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O ativo também pode ser chamado por palavras da lista"])},
  "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correspondência com um ativo existente"])},
  "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por exemplo: litros, peças, horas, caixas"])},
  "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por exemplo: l, un, h, cx"])},
  "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantos dígitos podem existir após a vírgula nos valores do ativo"])},
  "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você escolheu criar um ativo ainda não existente, por favor preencha suas características"])},
  "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tempo que é dado às partes para cumprir as condições"])},
  "62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um ativo interno é um ativo que existe no sistema \\\"New Reality\\\". Um ativo externo é um ativo que não existe no sistema \\\"New Reality\\\"."])},
  "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não vinculado"])},
  "64": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor esperado: de 1 a 2147483"])}
}