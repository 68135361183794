export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tema"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Última mensagem"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ações"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma solicitação"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prioridade"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tema"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texto da mensagem"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arquivos"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você pode carregar um ou mais arquivos. Tamanho máximo do arquivo - 10MB."])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baixa"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normal"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alta"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suporte técnico"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicitações ativas"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arquivo"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nova solicitação"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Em processamento pelo operador"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicitação criada"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trabalhando, aguarde a resposta do operador"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicitação fechada"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicitação fechada"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sem novas mensagens"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nova mensagem do operador"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sem novas mensagens"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar para o arquivo"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todas as solicitações"])}
}