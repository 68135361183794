export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As chaves de uso único aumentam significativamente a segurança do sistema."])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No momento do login, o programa pedirá que você insira não apenas o login e a senha, mas também a próxima chave de uso único. A ativação é feita sequencialmente. A última chave é válida até que você crie uma nova série de chaves."])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usar chaves de uso único"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de chaves"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerar"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportar"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nº"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chave"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chaves de uso único"])}
}