export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Күйді таңдаңыз"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Инкассоға өтінім берілді"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коллекция қолданбасы тоқтатылды"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жинақ орындауға мақұлданды"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Инкассацияны кассир жүзеге асырады (ақша кассетаға салынады)"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Инкассаторларға кассеталар берілді (жолдағы ақша)"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қолма-қол ақшаны инкассациялау банкоматта орнатылған"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сәтсіздік нәтижесінде басқа жинақпен шамадан тыс жүктелді"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коллекция банкоматтағы басқа коллекциямен ауыстырылды (жолда қалады)"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коллекция қалдықтары орналастырылады (жинақ мұрағатқа жіберіледі)"])}
}