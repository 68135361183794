export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solde"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations supplémentaires"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actif"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrée"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sortie"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une nouvelle adresse"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un nouveau compte"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer sélectionnés"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes actifs"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des comptes"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du compte"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous vraiment supprimer les comptes ?"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous vraiment supprimer le compte"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun actif trouvé"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun compte trouvé"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renommer l'adresse"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous vraiment supprimer les adresses ?"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous vraiment supprimer l'adresse"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non fiduciaire"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décret"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opérations"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actes"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recharger"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retirer"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échange"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cacher"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changement"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])}
}