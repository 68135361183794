export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña antigua:"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nueva contraseña:"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmación de la contraseña:"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nueva contraseña:"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmación de la contraseña:"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La \"Nueva contraseña\" y la \"Confirmación de la contraseña\" no coinciden."])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La contraseña no debe contener caracteres cirílicos"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear contraseña de confirmación"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambiar contraseña de confirmación"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A la página principal"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cadena de 4 a 65 caracteres. Se permiten caracteres del alfabeto latino, números y símbolos"])}
}