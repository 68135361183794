export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz parę walutową"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para walut"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfigurowanie aktualizacji"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podana waluta"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otrzymano walutę"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj zysk do kursu,%"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ aktualizacji kursu"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czas życia kursu, sekundy"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interwał aktualizacji kursu, sekundy"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dobrze"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do jakiego terminu ważny jest kurs?"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alias ​​danej waluty"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alias ​​otrzymanej waluty"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wejście"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konieczne jest wybranie waluty i to w dodatku tylko jednej: danej lub otrzymanej"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kwota wysłania * Stawka = Kwota otrzymania"])}
}