export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jednorázové klíče výrazně zvyšují bezpečnost systému."])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Při přihlášení do programu budete požádáni nejen o zadání přihlašovacího jména a hesla, ale také o zadání jednorázového klíče. Aktivace probíhá postupně. Poslední klíč je platný, dokud nevytvoříte novou sérii klíčů."])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Použít jednorázové klíče"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seznam klíčů"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generovat"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["č."])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klíč"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jednorázové klíče"])}
}