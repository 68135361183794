export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informações do anúncio"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informações básicas"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Básico"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicional"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de criação"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ativo"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceitar"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de início da transação"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de término da transação"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxa"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criador"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dá"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["recebe"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponível apenas por link"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponível apenas para usuários verificados"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["limites"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proprietário do anúncio"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrição"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tempo limite"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dias"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["horas"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minutos"])}
}