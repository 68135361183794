export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سطر يصل إلى 255 حرفًا"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد صحيح"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["20 رقما"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0 أو 1"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم كسري، الدقة رقمين، فاصل نقطي"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد صحيح"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سلسلة تصل إلى 255 حرفًا، اختيارية"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد صحيح، اختياري"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سلسلة 32 حرفا"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رمز الدفع يجب أن يكون فريداً من نوعه لكل دفعة عند نقل السلسلة"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سيتم إنشاء رقم فريد تلقائيًا"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم المتجر المخصص بعد التسجيل"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم حساب المتجر؛ يحدد هذا الحساب أيضًا العملة التي يجب إجراء الدفع بها (في معظم الحالات، يمكن للمستخدم إجراء الدفع بأي عملة، وسيتم تحويل الأموال تلقائيًا إلى عملة المستلم)"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منع العميل من تغيير المبلغ المحدد (1 - حظر، 0 - السماح)"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مبلغ المستفيد بعملة حساب المستفيد"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم النموذج الذي يتم من خلاله قبول الدفع"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التعليق المعروض للدافع كغرض الدفع"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلمة اختيارية إضافية سيتم تمريرها إلى البرنامج النصي لنتائج الدفع، وكذلك إلى صفحات الإرجاع"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان URL الذي سيعود إليه العميل بعد الدفع الناجح؛ إذا لم يتم تحديده، يتم أخذه من إعدادات المتجر"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان URL الذي سيعود إليه العميل بعد حدوث خطأ؛ إذا لم يتم تحديده، يتم أخذه من إعدادات المتجر"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان URL الذي سيتم إرسال إشعار نتائج الدفع إليه؛ إذا لم يتم تحديده، يتم أخذه من إعدادات المتجر"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عمر الفاتورة (بالدقائق)، الافتراضي 60"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["توقيع رسالة باستخدام المفتاح السري للمتجر"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0 أو 1"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1- إذا نجح، 0- غير ذلك"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد صحيح"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رمز الخطأ (0 إذا لم يكن هناك خطأ)"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سلسلة"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نص الخطأ (أو شرح حالة الدفع)"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0 أو 1"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إشارة إلى أن حالة الدفع النهائية غير معروفة بعد"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رمز دفع المتجر"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم المعاملة"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بروتوكول التاجر"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قبول المدفوعات على الموقع ممكن فقط للمتاجر الإلكترونية المسجلة في النظام. لتسجيل متجر، انتقل إلى الصفحة"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل المتجر"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعادة توجيه المستخدم إلى التاجر للدفع"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["للدفع، من الضروري إعادة توجيه المستخدم إلى عنوان URL: https://merchant.[نطاقك] وإرسال المعلمات التالية عبر GET أو POST:"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوصف"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع البيانات"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التوقيع"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يتم حسابه عن طريق إضافة المعلمات المرسلة والتجزئة بواسطة طريقة hmac مع المفتاح السري. يعتمد عدد وترتيب المعلمات المضافة على المعلمات"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["identified"])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مبلغ ثابت"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الترتيب كما يلي:"])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ext_transact + num_shop + keyt_shop + identified + sum + payform + comment + free_param + url_success + url_decline + url_callback"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["للرابط حيث العميل"])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدفع، الترتيب كما يلي (المبلغ غير مدرج في التوقيع):"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ext_transact + num_shop + keyt_shop + identified + payform + comment + free_param + url_success + url_decline + url_callback"])},
  "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكن إنشاء رابط الدفع في الصفحة"])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'متجري'"])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مثال على النموذج:"])},
  "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العودة إلى موقع المتجر بعد اكتمال العملية"])},
  "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إذا رفض الدافع الدفع أو انتهت العملية بخطأ، يقترح نظام الدفع الانتقال إلى الصفحة المناسبة المحددة في إعدادات المتجر أو المرسلة مع النموذج"])},
  "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["url_decline"])},
  "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إذا اكتملت العملية بنجاح، انتقل إلى الصفحة"])},
  "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["url_success"])},
  "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ستتلقى هذه البرامج النصية أيضًا نتائج الدفع (بالطريقة المحددة في إعدادات المتجر. الافتراضي - GET):"])},
  "62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم"])},
  "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوصف"])},
  "64": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع البيانات"])},
  "65": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التوقيع"])},
  "66": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يتم حسابه عن طريق إضافة المعلمات المرسلة والتجزئة بواسطة طريقة hmac مع المفتاح السري."])},
  "67": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ترتيب المعلمات المضافة:"])},
  "68": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ext_transact + amount + success + result + result_text + free_param"])},
  "69": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إشعار حالة دفع المتجر (رد الاتصال)"])},
  "70": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بعد تنفيذ الدفع، يتم إرسال نتائج الدفع إلى البرنامج النصي المحدد أثناء تسجيل المتجر"])},
  "71": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["result_url"])},
  "72": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يتلقى هذا البرنامج النصي البيانات لكل من حالات الدفع الناجحة والأخطاء."])},
  "73": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ستتلقى هذه البرامج النصية أيضًا نتائج الدفع (بالطريقة المحددة في إعدادات المتجر. الافتراضي - GET)."])},
  "74": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["في بعض الحالات، لا تصل المعلومات مع النتائج (على سبيل المثال، إذا ذهب العميل للدفع وأغلق صفحة المتصفح)."])},
  "75": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم"])},
  "76": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوصف"])},
  "77": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع البيانات"])},
  "78": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التوقيع"])},
  "79": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يتم حسابه عن طريق إضافة المعلمات المرسلة والتجزئة بواسطة طريقة hmac مع المفتاح السري."])},
  "80": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ترتيب المعلمات المضافة:"])},
  "81": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["transact + ext_transact + amount + success + result + result_text + free_param"])},
  "82": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حساب التوقيع"])},
  "83": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يتم إنشاء التوقيع عن طريق إضافة المعلمات المطلوبة بالترتيب المحدد والتجزئة بواسطة طريقة hmac."])},
  "84": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ترتيب وعدد المعلمات المضافة موضح أعلاه."])},
  "85": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تظهر القوائم التالية أمثلة على وظائف حساب التوقيع بلغات مختلفة، حيث يتم تمرير السلسلة المضافة للتوقيع كمعلمة"])},
  "86": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القائمة 1. حساب التوقيع في PHP."])},
  "87": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القائمة 2. حساب التوقيع في Python."])},
  "88": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القائمة 3. حساب التوقيع في Perl."])},
  "89": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["للدفع"])},
  "90": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكن تغيير المبلغ"])},
  "91": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دفع المنتج"])},
  "92": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادفع"])},
  "93": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء توقيع بالمفتاح"])},
  "94": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["signed_str"])},
  "95": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["والعبارة السرية في المعلمة"])},
  "96": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["key"])}
}