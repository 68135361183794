export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entregar"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["saldo "])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recibir"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pareja capital"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuentas"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de intercambio"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferencia de mercado: se utilizará el intercambio al mejor precio disponible en el momento de la ejecución de la solicitud. Atención, la cantidad a recibir puede cambiar y diferir de la calculada dependiendo de las solicitudes en el momento del intercambio."])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferencia a precio fijo: se realiza de acuerdo con la cantidad y el tipo de cambio que especifiques. Se colocará una orden. Si el tipo de cambio especificado difiere mucho del mercado, el cierre de la orden puede tardar mucho tiempo."])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de cambio (estimado):"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de cambio"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El tipo de cambio no está definido, "])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["se recomienda especificar el tipo de cambio manualmente"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suma"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usar todo"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comisión"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comisión"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total:"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En el mercado"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(intercambio rápido al mejor precio disponible)"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de cambio fijo "])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(colocar orden con el tipo de cambio especificado)"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay cuentas adecuadas"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de cambio"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambio en 24 horas"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Variaciones en 24 horas"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volumen de operaciones en 24 horas"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡El monto no puede ser igual a cero!"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intercambiar"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para ver los datos del resumen, selecciona un par de intercambio"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se pueden intercambiar activos iguales"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Además"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La solicitud no se incluye en la selección hasta"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restricciones"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El campo 'Tasa' debe tener un valor diferente de 0"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fondos insuficientes en la cuenta"])}
}