export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documentazione"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protocollo del Commerciante"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reindirizzamento al commerciante"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ritorno al negozio"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifica del negozio"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calcolo della firma"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Widget incorporato"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installazione"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiamata"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risposta dal negozio"])}
}