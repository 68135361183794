export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digitaler Pass"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehr erfahren"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer-ID:"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrierungsdatum:"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letzte Autorisierung:"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Vermögenswerte"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konten"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle anzeigen"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auffüllen"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abheben"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Konten"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort zur Bestätigung"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um Transaktionen zu bestätigen, müssen Sie ein Bestätigungskennwort erstellen."])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorlagen"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betrag"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beliebte Zahlungen"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuigkeiten"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausblenden"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter..."])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Vermögenswerte"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gleichgewicht"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurückziehen"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachfüllen"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Austausch"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An den Benutzer"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtbilanz"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einblenden"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht-Fiat-Guthaben"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiat-Balance"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Informationen"])}
}