export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мої магазини"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Магазинів не знайдено"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створити код віджета"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створити платіжне посилання"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редагувати"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити магазин"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви дійсно хочете видалити магазин"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зареєструвати магазин"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Режим платежів"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активність"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Акцепт"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дії"])}
}