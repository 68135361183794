export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zusätzliche Emission"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krypto-Asset"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto - Deckungsquelle"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belastungskonto"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zusätzliche Emission"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuelle Emission"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TOKEN"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deckungsberechnung"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommission"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommission"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deckungsberechnung"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bindung"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grunddaten"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deckungsprozentsatz"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zukünftige Deckung"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deckungsabschreibung"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betrag, der nach Ausgabe der zusätzlichen Emission in Reserve sein wird."])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abzuschreiben"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuelle Deckung"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betrag, der nach Ausgabe der zusätzlichen Emission von Ihrem Konto abgebucht wird."])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zukünftiger Deckungsbetrag"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E — Aktueller Emissionsbetrag"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I — Zusätzliche Emission"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["K — Bindungskurs"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["P — Deckungsprozentsatz"])}
}