export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaktionscode"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich gebe"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich erhalte"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richtung"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurs"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timeout"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktionen"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine aktiven Transaktionen"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Transaktionen"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["innerer Vermögenswert"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inaktiv"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korrespondent"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])}
}