export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаблоны"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Найти"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В группе"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Группы платежей"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Платежи"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма платежа"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Счет перевода"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комментарий"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаблон"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название шаблона:"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить шаблон"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перевести"])}
}