export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қосымша эмиссия"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Криптоактив"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шот - жабу көзі"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Есептен шығару шоты"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қосымша эмиссия"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Түсініктеме"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жалғастыру"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ағымдағы эмиссия"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ТОКЕН"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жабуды есептеу"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комиссия"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комиссия"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жабуды есептеу"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Байланыс"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Негізгі деректер"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жабу пайызы"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Болашақ жабу"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жабуды есептен шығару"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қосымша эмиссияны шығарғаннан кейін резервте болатын сома."])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Есептен шығаруға"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ағымдағы жабу"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қосымша эмиссияны шығарғаннан кейін сіздің шотыңыздан есептен шығарылатын сома."])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Болашақ жабу сомасы"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E — Ағымдағы эмиссия сомасы"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I — Қосымша эмиссия"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["K — Байланыс бағамы"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["P — Жабу пайызы"])}
}