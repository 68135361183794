export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refillable account"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment method"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Template name:"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save template"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Templates"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account top-up"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer details"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check transfer"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer Information"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instructions"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address replenishment"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated address"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To the list of tasks"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer address"])}
}