export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المعاملة"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المعلمات العامة"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تغيير الأرصدة في الحسابات"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["في نهاية العملية:"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["في بداية العملية:"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلمات إضافية"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العمليات في البلوكشين"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Callback"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المحاولة التالية:"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوقت"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرد"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حماية الصفقة"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مدة الحماية:"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حتى"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رمز الحماية:"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلمات القالب"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم القالب:"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلغاء العملية"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تكرار العملية"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ القالب"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إغلاق"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم المعاملة"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العملية"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المراسل"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الدفع"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المبلغ"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العمولة"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعليق"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النتيجة"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم استمارة الدفع"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إيداع/سحب"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تغيير أرصدة العناوين"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد الائتمان"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعادة الأموال إلى المرسل"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عملية مشبوهة"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خيارات العمل:"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لجنة المستلم"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لجنة الدافع"])}
}