export default {
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entregar monto"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recibir monto"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtros"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizar"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["operaciones"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operación"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay ofertas"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ofertas"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID del vendedor"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de la oferta"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cripto-activo"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descripción"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendedor verificado"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solo para usuarios verificados"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se puede realizar una operación contigo mismo"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar activo"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información del anuncio"])}
}