export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حرق الأصول"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مبلغ الحرق"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حساب للإرجاع"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حرق"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مبلغ الحرق"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يمكن أن يكون مبلغ الحرق أكبر من مبلغ الإصدار"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إرجاع التغطية"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["للإرجاع"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المبلغ الذي سيبقى في الاحتياطي بعد حرق الأصول"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المبلغ الذي سيعود إلى حسابك بعد حرق الأصول"])}
}