export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powtórz operację"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historia operacji"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista kont"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sukces"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Błąd"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieznany błąd"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szablony"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moje aktywa"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informacje o nowym nabytku"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodatkowy problem"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link do blockchaina"])}
}