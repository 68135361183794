export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assegni elettronici"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea assegno"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attività"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creato"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conto di addebito"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serie"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commento"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azioni"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attivo"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non attivo"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun assegno"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attiva"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifica"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiudi"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna ricevuta elettronica trovata"])}
}