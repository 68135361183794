export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["External Assets"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bound to Asset"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Units of Measurement"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creation Date"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Author"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period From"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period To"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filters"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Assets"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Today"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yesterday"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Week"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Month"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bound to Asset"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Author"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Countries"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactive"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit external asset"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete external asset"])}
}