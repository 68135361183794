export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повторити операцію"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Історія операцій"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список рахунків"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успіх"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Помилка"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Невідома помилка"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаблони"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мої активи"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Інформація про новий актив"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додаткова емісія"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список віртуальних активів"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Посилання на блокчейн"])}
}