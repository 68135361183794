export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النشاط"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وصف الأصل"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التاريخ"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد إعلانات"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعلاناتي"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعلاناتي"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعلان جديد"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النشاط"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نشط"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غير نشط"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكل"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المعرف"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اتجاه"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأصل الخارجي"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأصل الداخلي"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وصف"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أعطي"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أستلم"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحدود"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ النشر"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرصيد في الحساب"])}
}