export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Możesz dokonać kilku płatności jednocześnie. \nW tym celu należy najpierw utworzyć plik z opisem płatności w formacie „XLS”, „XLSX”, „CSV”."])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analiza pliku"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korespondent"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kwota"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentarz"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametr"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informacje o pliku"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Łączna liczba płatności: "])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["na łączną kwotę: "])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plik płatności"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto debetowe"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wykonaj płatności"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontynuuj"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ładowanie pliku płatności"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprawdź zawartość pliku"])},
  "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Płatności masowe i przelewy"])}
}