export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elenco fatture"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna fattura trovata"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commento"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negozio"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero del negozio"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla la fattura"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archiviare il codice di pagamento"])}
}