export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جميع الطلبات"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعادة تعيين"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفلاتر"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحديث"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التاريخ"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أعطي"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أستلم"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحالة"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السعر"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العمليات"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العمولات"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلغاء"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تكرار"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد طلبات"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحد الأدنى للإرسال"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحد الأقصى للإرسال"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحد الأدنى للاستلام"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحد الأقصى للاستلام"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد محاولات إتمام المعاملة"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نشط"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عملة الإرسال"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عملة الاستلام"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حساب الإرسال"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حساب الاستلام"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قائمة الوكلاء الفرعيين"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم يتم اختيار الحساب"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفترة من"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفترة إلى"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اليوم"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأمس"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأسبوع"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشهر"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السنة"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم يتم اختيار نوع التاريخ"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انتهاء"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنهاء"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بدء"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أي جزء ثابت وأي جزء متغير"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المبلغ المعطى ثابت"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المبلغ المستلم ثابت"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الطلب"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يريد المستخدم التبادل السريع بأفضل العروض المقابلة"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التبادل بسعر محدد بدقة"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحالة"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم تبدأ المعالجة"])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بدأت المعالجة"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم التنفيذ جزئياً"])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم التنفيذ بالكامل"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم الإلغاء بالكامل"])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم التنفيذ جزئياً والإلغاء"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأصل الصادر"])},
  "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأصل الوارد"])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع التاريخ"])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحد الأدنى للمبلغ أكبر من الحد الأقصى"])}
}