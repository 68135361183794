export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تكرار العملية"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ العمليات"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قائمة الحسابات"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نجاح"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطأ"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطأ غير معروف"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القوالب"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أصولي"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات عن الأصل الجديد"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إصدار إضافي"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قائمة الأصول الافتراضية"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رابط إلى blockchain"])}
}