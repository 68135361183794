export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remainder"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional information"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Output"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a new wallet"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a new account"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete selected"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of tokens"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of accounts"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account name"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete accounts?"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete the account?"])}
}