export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["transakce"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obecné parametry"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Změna zůstatků na účtech"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na konci operace:"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na začátku operace:"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Další parametry"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operace v blockchainu"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Callback"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Další pokus:"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čas"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odpověď"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chráněné převody"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doba ochrany:"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kód ochrany:"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrdit"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametry šablony"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název šablony:"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložit"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušit operaci"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opakovat operaci"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložit šablonu"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zavřít"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Číslo transakce"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operace"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korespondent"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum platby"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Částka"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komise"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentář"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výsledek"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Číslo platebního formuláře"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Příchod/Výdej"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Změna zůstatků adres"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrdit připsání"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vrátit peníze odesílateli"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podezřelá operace"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Možnosti akcí:"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provize příjemce"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komise plátce"])}
}