export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Электрондық чектер"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чек жасаңыз"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Белсенділік"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Құрылды"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Есептен шығару шот"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["сомасы"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сан"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сериялар"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пікір"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Әрекеттер"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Белсенді"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Белсенді емес"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тексерулер жоқ"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Іске қосу"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тексеру"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жабық"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Электрондық түбіртек табылмады"])}
}