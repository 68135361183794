export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modèles de paiement"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trouver"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dans le groupe"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupes de paiements"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiements"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant du paiement"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte de transfert"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modèle"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du modèle :"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer le modèle"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transférer"])}
}