export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["安全"])},
  "Antiphishing": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["反钓鱼设置是使用账户时的额外保护机制。"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您设置的欢迎短语将显示在账户的每一页顶部。"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正确的短语将表明您在正确的网站上，不正确的短语将是一个信号：不要进行操作，立即联系我们的系统安全部门。"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["启用反钓鱼："])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["反钓鱼短语："])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["反钓鱼"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更改将在下次授权后生效"])}
  },
  "IP": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP地址访问设置将阻止您（或攻击者）从未列入允许列表的地址访问系统。"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["根据IP地址限制访问账户"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加地址"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地址： "])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地址块： "])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["至："])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的IP： "])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地址列表"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编号"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["从地址"])},
    "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["至地址"])},
    "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["访问"])},
    "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作"])},
    "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除"])},
    "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有地址"])},
    "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存"])},
    "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["禁止"])},
    "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["允许"])},
    "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入IP地址"])},
    "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP地址无效"])},
    "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未指定任何IP地址或地址块。"])},
    "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有IP地址被允许授权。您将无法访问您的账户。"])},
    "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["根据IP访问"])},
    "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["从："])}
  },
  "Magic": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["魔法词是额外的安全设置。"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果启用了魔法词，每次授权时，系统将要求您输入魔法词中的一些字符（按其序号）。"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["魔法词："])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["要求魔法词："])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录账户时："])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通过商家支付时："])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用卡时："])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["魔法词"])}
  },
  "Onekey": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一次性密钥大大提高了系统的安全性。"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录时，程序不仅要求您输入登录名和密码，还要求输入下一个一次性密钥。激活依次进行。最后一个密钥有效，直到您创建新的一组密钥。"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用一次性密钥"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密钥列表"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["生成"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["导出"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编号"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密钥"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一次性密钥"])}
  },
  "Password": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["旧密码："])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新密码："])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认密码："])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"新密码\"和\"确认密码\"不匹配。"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更改密码"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["长度为4至63个字符。允许使用拉丁字母、数字和符号"])}
  },
  "PayPassword": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["旧密码："])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新密码："])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认密码："])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新密码："])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认密码："])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"新密码\"和\"确认密码\"不匹配。"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码不应包含西里尔字母"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建确认密码"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更改确认密码"])},
    "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返回主页"])},
    "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["长度为4至65个字符。允许使用拉丁字母、数字和符号"])}
  },
  "SecretQuestion": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果您忘记了账户访问密码，可以通过回答秘密问题来恢复。"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在此部分，您可以更改秘密问题/答案。"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["秘密问题"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的问题："])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["答案："])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["秘密问题"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自定义问题"])}
  }
}