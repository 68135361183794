export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Widget Incorporato"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installazione"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collega lo script alla pagina nella sezione head tramite CDN o scaricandolo come file"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiamata"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiama la funzione"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["passando un oggetto JS come argomento"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrizione"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo di dati"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esempio"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["id per iframe"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["name per iframe"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oggetto con parametri di pagamento"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["funzione gestore successo operazione"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["funzione gestore fallimento operazione"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo del servizio di pagamento"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sotto"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ancora più sotto"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ritorno al sito del negozio dopo il completamento dell'operazione"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dopo il completamento dell'operazione o il rifiuto, il widget verrà chiuso. L'oggetto con i parametri sottostanti verrà passato al callback onFail o onSuccess:"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paga"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azioni in caso di successo"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azioni in caso di fallimento"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo del servizio di pagamento"])}
}