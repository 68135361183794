export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सामान्य जानकारी"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भुगतान की जानकारी"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कंट्रोल सिग्नेचर"])},
  "List": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मेरे स्टोर"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई स्टोर नहीं मिला"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विजेट कोड बनाएं"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भुगतान लिंक बनाएं"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संपादित करें"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्टोर हटाएं"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्या आप वाकई स्टोर को हटाना चाहते हैं?"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्टोर पंजीकरण करें"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोड"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नाम"])},
    "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भुगतान मोड"])},
    "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सक्रियता"])},
    "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्वीकृति"])},
    "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
    "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्रियाएँ"])}
  },
  "New": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्टोर पंजीकरण करें"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सिस्टम में पंजीकृत इलेक्ट्रॉनिक स्टोर ग्राहक से भुगतान प्राप्त कर सकते हैं।"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्टोर के रूप में इलेक्ट्रॉनिक स्टोर, एक्सचेंज प्वाइंट और अन्य इंटरनेट संसाधन पंजीकृत होते हैं, जो भुगतान स्वीकार करना चाहते हैं।"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नाम या ट्रेडमार्क"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्टोर का URL पता"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्टोर का संक्षिप्त विवरण"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सेवा का प्रकार"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्टोर सक्रिय है"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["गुप्त कुंजी"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्वचालित भुगतान की अनुमति दें"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["परिणाम को स्क्रिप्ट पर भेजें"])},
    "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्क्रिप्ट का पता"])},
    "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सफल भुगतान के बाद वापसी"])},
    "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["असफल भुगतान के बाद वापसी"])},
    "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सहेजें"])},
    "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जेनरेट करें"])},
    "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कम से कम 30 अक्षर."])},
    "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भुगतान प्रणाली द्वारा भेजे गए और प्राप्त डेटा की सत्यता और अखंडता की जाँच करने के लिए उपयोग किया जाता है।"])},
    "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अगला"])},
    "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पीछे"])},
    "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्टोर की सूची पर वापस जाएं"])},
    "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["गलत URL"])}
  },
  "Invoices": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["चालानों की सूची"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई चालान नहीं मिला"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोड"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["तारीख"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्थिति"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टिप्पणी"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["राशि"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्टोर"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्थिति"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भंडार संख्या"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["चालान रद्द करें"])},
    "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भंडार अदायगी कोड"])}
  }
}