export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["转账账户："])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金额："])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评论："])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易保护"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["代码："])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["生成"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保护期限："])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["天"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["模板名称："])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["另存为模板"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["转账"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["模板"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["转账给其他用户"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保护代码是收款人必须输入的代码，以完成交易。受保护的转账收款人会立即在“操作历史”中看到，但只有在交易卡中输入保护代码后才能使用资金。如果支付期限到期，资金将返还给发送人。"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["转账地址"])}
}