export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Период с"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Период по"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Экспорт"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фильтры"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сбросить"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обновить"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакция"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Параметр"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакция"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Корреспондент/Операция"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Корреспондент"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Параметры/Комментарий"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операций не найдено"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма операций"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поступления:"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" шт. на сумму"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Расходы:"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Типы операций"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выплаты, оплата услуг"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Внутренние переводы"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оплата счета"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переводы по своим счетам"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создание крипто чеков"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активация крипто чеков"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прочее"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приход/расход"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приход"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Расход"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["= равно"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≠ не равно"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["> больше"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≥ больше или равно"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["< меньше"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≤ меньше или равно"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успешно завершена"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В обработке"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сегодня"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вчера"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неделя"])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Месяц"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Год"])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["История операций"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пополнение счета"])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Счет не выбран"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обмен на бирже"])},
  "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пополнение адреса"])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переводы внутри своего кошелька"])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Корреспондент"])},
  "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операция"])}
}