export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le service de paiements de masse permet de transférer rapidement des fonds à d'autres utilisateurs et d'effectuer des opérations de paiement aux fournisseurs de services."])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dans la section"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger le fichier de paiement"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vous pouvez télécharger un fichier au format Excel (XLS, XLSX, CSV) ou XML."])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des tâches"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vous permet de voir les statuts des demandes de paiements de masse créées précédemment."])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez créer un fichier dans un éditeur de tableur comme Excel."])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger un fichier exemple"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remarques importantes :"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format de fichier - CSV, XLS ou XLSX."])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le fichier ne doit pas contenir de titres."])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour que les grands nombres ne se transforment pas en format exponentiel (par exemple, \"5,46546E+15\") et que les zéros initiaux ne soient pas coupés, vous pouvez ajouter un apostrophe (') avant le nombre, ou définir le format de la cellule comme texte avant de saisir les données."])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format des colonnes pour les paiements aux fournisseurs de services"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des colonnes dans le fichier :"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code de l'opérateur"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Spécifiez-le à partir de la liste des opérateurs"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ici"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". (Pour tous les opérateurs mobiles, vous pouvez spécifier le code opérateur 7000 - dans ce cas, l'opérateur sera automatiquement déterminé par le numéro de téléphone.)"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Le montant est indiqué dans le ticker de l'actif à partir duquel le transfert est effectué. S'il n'y a pas de tel ticker, le taux de conversion du système est automatiquement appliqué à l'opération."])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire de paiement"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Optionnel, peut être laissé vide ; longueur maximale de 255 caractères."])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètre de paiement 1"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". \nLa valeur du paramètre requise par l'opérateur, par exemple, le numéro de téléphone ou l'adresse de la crypto-actuelle, est prescrite. \nDe nombreux opérateurs sont limités à un paramètre de paiement, mais si le nombre de paramètres est supérieur à un, vous devez les indiquer tous dans les colonnes voisines."])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètre de paiement 2"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètre de paiement N"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez consulter les paramètres de paiement en accédant au paiement d'un opérateur spécifique :"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format des colonnes pour les transferts de masse aux utilisateurs"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code de l'opérateur"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Spécifiez 0 ou laissez-le vide."])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Le montant est affiché dans le ticker du compte à partir duquel l'actif est transféré. S'il n'y a pas de tel ticker, le taux de conversion du système est automatiquement appliqué à l'opération."])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire de paiement"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Optionnel, peut être laissé vide ; longueur maximale de 255 caractères."])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte bénéficiaire"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Saisissez ici le numéro de compte de l'utilisateur auquel le transfert doit être effectué (20 chiffres)."])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format des colonnes pour la distribution de prêts par un agent de crédit"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Cette ligne indique que les paiements sont des prêts."])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Le montant est spécifié dans la devise du produit de crédit."])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire sur le crédit"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Optionnel, peut être laissé vide ; longueur maximale de 255 caractères."])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID de l'emprunteur"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Veuillez noter que l'emprunteur doit avoir un passeport numérique Mfo-BorrowPerson."])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code du produit de crédit"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Vous pouvez le voir dans la liste des produits de crédit lors de la distribution manuelle du prêt."])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Période"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Simplement le nombre de périodes (les périodes utilisées sont définies dans le produit de crédit, par exemple, elles peuvent être des jours, des semaines, des mois, etc.)"])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code de l'opérateur (numéro du formulaire)"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Le remplissage de ce champ et des suivants est optionnel. Si vous ne les remplissez pas, l'emprunteur pourra retirer le prêt plus tard de manière autonome via son compte personnel."])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler en cas d'erreur"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Annuler (1) le prêt accordé si la transaction de retrait échoue, ou le laisser pour un retrait autonome ultérieur (0)."])},
  "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètre de paiement 1"])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Saisissez la valeur du paramètre requis par l'opérateur, par exemple le numéro de téléphone. De nombreux opérateurs se contentent d'un seul paramètre de paiement, mais si le nombre de paramètres est supérieur à un, vous devez les spécifier tous dans les colonnes adjacentes à la première."])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètre de paiement 2"])},
  "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètre de paiement N"])},
  "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le fichier doit indiquer les comptes ou les adresses des crypto-actifs des destinataires dans une seule devise, car la radiation des fonds est effectuée à partir d'un compte, et non de plusieurs."])}
}