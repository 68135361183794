export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбор статуса"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заявка на инкассацию подана"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заявка на инкассацию отменена"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Инкассация утверждена к выполнению"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Инкассация выполнена кассиром (деньги загружены в кассеты)"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кассеты переданы инкассаторам (деньги в пути)"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Инкассация установлена на банкомате"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В результате сбоя перегружена другой инкассацией"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Инкассация сменена другой инкассацией на банкомате (остатки в пути)"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разнесены инкассационные остатки (инкассация отправлена в архив)"])}
}