export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ақша шот:"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сома:"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Түсініктеме:"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мәміле қорғауы"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код:"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Генерациялау"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қорғау мерзімі:"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["күндер"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Үлгі атауы:"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сақтау"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Үлгі ретінде сақтау"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аудару"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Үлгілер"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Басқа пайдаланушыға аудару"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қорғау коды - бұл мәмілені аяқтау үшін алушы енгізуі қажет код. Қорғалған аударымды алушы \"Операциялар тарихы\" бөлімінде бірден көреді, бірақ қорғаныс кодын транзакция картасына енгізгеннен кейін ғана ақшаны пайдалана алады. Төлем мерзімі аяқталған жағдайда қаражат жіберушіге қайтарылады."])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аударым адресі"])}
}