export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्रिप्टो संपत्ति डेटा"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समस्या का पता:"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नाम:"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टिकर:"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अनुमतियां"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उद्देश्य/मिशन/उद्देश्य"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वित्तीय विशेषताएँ"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वर्ग"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शुद्धता:"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संपर्क"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उत्सर्जन:"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उत्तरदायी व्यक्ति:"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जारीकर्ता:"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इकाई लागत:"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सुरक्षा:"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एटीएम से खरीदारी:"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एटीएम पर बिक्री:"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एक्सचेंज पर परिसंपत्तियों के साथ लेनदेन:"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पी2पी के माध्यम से परिसंपत्ति लेनदेन:"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["होस्टिंग का पता"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["श्वेत पुस्तक पता"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ई-मेल"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रस्तावक"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अन्य संपर्क"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जाना"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जारीकर्ता"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अधिकतम उत्सर्जन:"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अन्य"])}
}