export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les commandes"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtres"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je donne"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je reçois"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opérations"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["commissions"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Répéter"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas de demandes"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant min à envoyer"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant max à envoyer"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant min à recevoir"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant max à recevoir"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de tentatives pour compléter la transaction"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actif"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devise à envoyer"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devise à recevoir"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte pour l'envoi"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte pour la réception"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des sous-concessionnaires"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte non sélectionné"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Période du"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Période au"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aujourd'hui"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semaine"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mois"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Année"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de date non sélectionné"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expirer"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminer"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commencer"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quelle partie est fixe et quelle partie est variable"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le montant donné est fixe"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le montant à recevoir est fixe"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de commande"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'utilisateur veut échanger rapidement aux meilleures contre-offres"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échange à un taux strictement spécifié"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traitement non commencé"])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traitement commencé"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partiellement complété"])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entièrement complété"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complètement annulé"])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partiellement complété et annulé"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actif sortant"])},
  "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actif entrant"])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de date"])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le montant minimum est supérieur au montant maximum"])}
}