export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Банкомати"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Натисніть для розвороту таблиці на повний екран"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скинути"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оновити"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активність"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Субдилери"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активно"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неактивно"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створити банкомат"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редагувати криптомат"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скасувати"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберегти"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Основна інформація"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Основне"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Розклад роботи"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Логін"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адреса"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опис місця встановлення"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нормований"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ненормований"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Режим роботи"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Понеділок"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вівторок"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Середа"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Четвер"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["П'ятниця"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Субота"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неділя"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обідня перерва"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повтор пароля"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Використати існуючі рахунки"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створити нові рахунки"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Основні відомості"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рахунок"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Час роботи (довідкова інформація)"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активність"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дії"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Криптомати не знайдені"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер банкомату"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["широта"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["довгота"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Телефон підтримки"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID субдилера"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рахунки"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рахунок"])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Валюта"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзитний рахунок"])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створення криптомату"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редагування криптомату"])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль"])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Так"])},
  "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заборонити"])},
  "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дозволити"])},
  "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список інкасаторів"])},
  "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати інкасатора"])},
  "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неможливо перейти до інкасаційних залишків – немає даних"])},
  "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список інкасаторів"])},
  "62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати інкасатора"])},
  "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дозволено"])},
  "65": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Акцепт"])},
  "67": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адреса"])},
  "68": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редагувати банкомат"])},
  "69": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переглянути поточні інкасаційні залишки"])},
  "70": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переглянути вивантажені інкасаційні залишки"])},
  "71": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пін-код"])},
  "73": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Якщо не вказувати субдилера, то банкомат буде прив'язаний до вас"])},
  "74": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Широта і довгота - це географічні координати, які можна скопіювати, наприклад, з Google Maps, ввівши адресу банкомату"])},
  "75": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Місце встановлення"])},
  "76": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для відображення таблиці має бути обраний хоча б один стовпець"])},
  "77": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберегти"])},
  "78": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створити"])},
  "79": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перейти до пристроїв банкомату"])}
}