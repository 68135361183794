export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पुराना पासवर्ड:"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नया पासवर्ड:"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पासवर्ड की पुष्टि:"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नया पासवर्ड:"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पासवर्ड की पुष्टि:"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सहेजें"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सहेजें"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"नया पासवर्ड\" और \"पासवर्ड की पुष्टि\" मेल नहीं खाते।"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पासवर्ड में सिरिलिक वर्ण नहीं होने चाहिए"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पुष्टिकरण पासवर्ड बनाएं"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पुष्टिकरण पासवर्ड बदलें"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मुख्य पृष्ठ पर जाएं"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4 से 65 वर्णों की स्ट्रिंग। स्वीकार्य वर्ण: लैटिन वर्णमाला, अंक और प्रतीक"])}
}