export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I give it away"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I receive"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A comment"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cause"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel deal"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm participation in the transaction"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refuse the deal"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extend waiting time"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canceling a deal"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The user's status in the transaction is not defined. \nSomething went wrong"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request Arbitration"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agree to Arbitration"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notify about transfer completion"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm receipt of transfer"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rate the deal"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit rating"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deal No."])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correspondent"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time-out"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Today"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tomorrow"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel Arbitration"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel arbitration"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have requested arbitration"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbitration has been requested against you"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbitration is underway (You are the plaintiff)"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbitration is underway (You are the defendant)"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After the timer expires, consent to arbitration will occur automatically and you will no longer be able to add your answers to arbitration questions"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requered arbitration"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is an arbitration"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is an arbitration"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make a decision on arbitration: internal payment"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conduct"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])}
}