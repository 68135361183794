export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पंजीकरण"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["व्यक्तिगत खाता"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["व्यापार खाता"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपनाम:"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नाम:"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मध्य नाम: (यदि है)"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नाम"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लैटिन अक्षर, हाइफ़न और अपोस्ट्रॉफ अनुमत हैं।"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bond"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लैटिन अक्षर, हाइफ़न, स्पेस और अपोस्ट्रॉफ अनुमत हैं।"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["James"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई नहीं"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लैटिन अक्षर, हाइफ़न, उद्धरण, स्पेस, अंक और अपोस्ट्रॉफ अनुमत हैं।"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"My Company\" Ltd"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फोन"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ईमेल"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पासवर्ड"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लैटिन अक्षर (अपरकेस और लोअरकेस), अंक, प्रतीक _ - . ! $ % ^ * = अनुमत हैं"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पासवर्ड दोहराएं"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पासवर्ड मेल नहीं खाते"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वापस"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पंजीकरण"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अगला"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्थिति"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपयोगकर्ता जानकारी"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पासवर्ड"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सही ईमेल पता या फोन नंबर दर्ज करें। फोन नंबर अंतर्राष्ट्रीय प्रारूप में दर्ज करें, उदाहरण: +499001234567."])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आप सफलतापूर्वक पंजीकृत हैं"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अब आप कर सकते हैं"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["व्यक्तिगत खाते में लॉग इन करें"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आपका खाता हमारे कर्मचारी द्वारा जाँच के अधीन है, हम आपको सक्रियता के परिणामों की सूचना देंगे।"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पंजीकरण की पुष्टि के लिए लिंक के साथ ईमेल भेजा गया है"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आपके ईमेल पते पर"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पहली लॉगिन के बाद आपको फोन नंबर की पुष्टि करनी होगी"])}
}