export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code generation / payment links"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Store transaction no."])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sum"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A comment"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional parameter"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successful URL"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wrong URL"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notification URL"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL where the client will return after successful payment"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL where the client will return after an error"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL where notification of payment results will be sent"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fix the amount"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Link"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Form"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documentation"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice lifetime in minutes, default 60"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link validity period"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Form"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Widget"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you do not fix the amount, the payer will be able to enter it independently (suitable, for example, for charitable payments)"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment form"])}
}