export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Безопасность"])},
  "Antiphishing": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройка антифишинга является дополнительным механизмом защиты при работе с кабинетом."])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заданная вами приветственная фраза будет отображаться в верхней части любой страницы кабинета."])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Правильная фраза покажет вам, что вы находитесь на правильном сайте, неверная фраза явится сигналом: не осуществляя операций, необходимо срочно связаться со службой безопасности нашей системы."])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Включить антифишинг:"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Строка антифишинга:"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Антифишинг"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменения вступят в силу после следующей авторизации"])}
  },
  "IP": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройка доступа по IP-адресу не позволит Вам (или злоумышленнику) работать с системой с адреса, не прописанного в списке разрешённых."])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ограничение доступа к кабинету по IP-адресам"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить адреса"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес: "])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Блок адресов: "])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["по:"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Свой IP: "])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список адресов"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["С адреса"])},
    "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["По адрес"])},
    "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступ"])},
    "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Действия"])},
    "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить"])},
    "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адреса отсутствуют"])},
    "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
    "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запретить"])},
    "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разрешить"])},
    "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите IP-адрес"])},
    "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP-адрес введен неверно"])},
    "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не указан ни один IP-адрес или блок адресов."])},
    "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ни один IP-адрес не разрешен для авторизации. Вы не сможете получить доступ к своему счету."])},
    "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступ по IP"])},
    "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["с:"])}
  },
  "Magic": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Волшебное слово - это дополнительная настройка безопасности."])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если волшебное слово включено, то каждый раз при авторизации система будет просить вас ввести какие-то символы (по их порядковому номеру) из волшебного слова."])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Волшебное слово:"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Спрашивать волшебное слово:"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["При входе в кабинет:"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["При оплате через мерчант:"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["При использовании карты:"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Волшебное слово"])}
  },
  "Onekey": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Одноразовые ключи значительно повышают безопасность системы."])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В момент входа программы вас попросят не только ввести логин и пароль, но и очередной одноразовый ключ. Активация производится поочередно. Последний ключ действует до момента, пока вы не создадите новую серию ключей."])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Использовать одноразовые ключи"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список ключей"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сгенерировать"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Экспорт"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ключ"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Одноразовые ключи"])}
  },
  "Password": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Старый пароль:"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новый пароль:"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтверждение пароля:"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"Новый пароль\" и \"Подтверждение пароля\" не совпадают."])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменение пароля"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Строка от 4 до 63 символов. Допустимы символы латинского алфавита, цифры и символы"])}
  },
  "PayPassword": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Старый пароль:"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новый пароль:"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтверждение пароля:"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новый пароль:"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтверждение пароля:"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"Новый пароль\" и \"Подтверждение пароля\" не совпадают."])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль не должен содержать кириллицу"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создание пароля подтверждения"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменение пароля подтверждения"])},
    "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На главную"])},
    "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Строка от 4 до 65 символов. Допустимы символы латинского алфавита, цифры и символы"])}
  },
  "SecretQuestion": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В случае, если вы забудете пароль для доступа к кабинету, вы сможете восстановить его, ответив на секретный вопрос."])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В данном разделе вы можете сменить секретные вопрос/ответ."])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Секретный вопрос"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш вопрос:"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ответ:"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Секретный вопрос"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Свой вопрос"])}
  }
}