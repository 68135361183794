export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टेम्प्लेट्स"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पुनः पूर्ति"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्थानांतरण"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["निकासी"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टेम्पलेट का नाम"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ऑपरेटर"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्षेत्र"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्रियाएँ"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई टेम्पलेट नहीं"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्राप्तकर्ता"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्या आप वाकई टेम्पलेट № को हटाना चाहते हैं?"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टेम्पलेट का नाम बदलें"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नाम बदलें"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हटाएं"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपयोग करें"])}
}