export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opakovat operaci"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historie operací"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seznam účtů"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úspěch"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chyba"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neznámá chyba"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Šablony"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moje aktiva"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informace o novém aktivu"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Další problém"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odkaz na blockchain"])}
}