export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chèques électroniques"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un chèque"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activité"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créé"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte de radiation"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Somme"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Série"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un commentaire"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activement"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas actif"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas de chèques"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activer"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifier"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun reçu électronique trouvé"])}
}