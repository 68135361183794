export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文档"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商户协议"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重定向到商户"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返回商店"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商店通知"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["签名计算"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["嵌入式小部件"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["安装"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["调用"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商店响应"])}
}