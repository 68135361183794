export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma pagamento"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calcola commissione di ritorno"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restituisci pagamento"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transazione"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hash nella blockchain"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rischio AML"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commissione"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Da accreditare"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se sei d'accordo con le condizioni modificate, conferma l'accredito:"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se queste condizioni non ti sono favorevoli, puoi restituire i fondi al conto del mittente al netto delle spese di ritorno:"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transazione"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["per un importo di"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ha un rischio AML"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["quindi la commissione di accredito è stata ricalcolata"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["così, l'importo da accreditare è"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun nuovo accredito trovato"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data pagamento"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ritorno"])}
}