export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Электронные чеки"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать чек"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активность"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создано"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Счет списания"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Серия"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комментарий"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Действия"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активно"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не активно"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет чеков"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активировать"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проверить"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрыть"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Электронных чеков не найдено"])}
}