export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informacje o ogłoszeniu"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podstawowe informacje"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podstawowe"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodatkowe"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data utworzenia"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktywne"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akceptuj"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data rozpoczęcia transakcji"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data zakończenia transakcji"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurs"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maker"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["daje"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["otrzymuje"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostępne tylko przez link"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostępne tylko dla zweryfikowanych użytkowników"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["limity"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Właściciel ogłoszenia"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opis"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limit czasu"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dni"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["godzin"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minut"])}
}