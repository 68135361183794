export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Widget Incorporado"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instalação"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conecte o script à página na seção head via CDN ou baixando como arquivo"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chamada"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chame a função"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["passando um objeto JS como argumento"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrição"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de dados"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exemplo"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["id para iframe"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["name para iframe"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["objeto com parâmetros de pagamento"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["função manipuladora de sucesso da operação"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["função manipuladora de falha da operação"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereço do serviço de pagamento"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abaixo"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mais abaixo"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retorno ao site da loja após conclusão da operação"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Após a conclusão ou recusa da operação, o widget será fechado. O objeto com os parâmetros abaixo será passado para o callback onFail ou onSuccess:"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagar"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ações em caso de sucesso"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ações em caso de falha"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereço do serviço de pagamento"])}
}