export default {
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["给出金额"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金额"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收到金额"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["过滤器"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刷新"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有报价"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["P2P报价"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["卖方ID"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["报价代码"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["资产"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["描述"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["卖方已验证"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["仅限已验证用户"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无法与自己交易"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择资产"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公告信息"])}
}