export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الاستلام"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ المعالجة"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحالة"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد المدفوعات"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منها ناجحة"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منها خاطئة"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المدفوعات الجماعية"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المعاملة"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النتيجة"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مزود الخدمة"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المبلغ"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العمولة"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التعليق"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المعلمة الأولى"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم يتم العثور على مدفوعات"])}
}