export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dávám"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostávám"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentář"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Důvod"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušit obchod"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrdit účast v obchodu"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odmítnout obchod"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prodloužit čekací dobu"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušení obchodu"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status uživatele v obchodu není určen. Něco se pokazilo"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Požádat o Arbitráž"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Souhlasit s Arbitráží"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oznámit provedení převodu"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrdit přijetí převodu"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ohodnoťte obchod"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odeslat hodnocení"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obchod č."])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korespondent"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timeout"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dnes"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zítra"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušit arbitráž"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušit rozhodčí řízení"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Požádali jste o rozhodčí řízení"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bylo proti vám požadováno rozhodčí řízení"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Probíhá arbitráž (vy jste žalobce)"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Probíhá arbitráž (vy jste žalovaný)"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Po vypršení časovače dojde automaticky k souhlasu s arbitráží a již nebudete moci přidávat své odpovědi na arbitrážní otázky"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyžádané rozhodčí řízení"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Existuje rozhodčí řízení"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Existuje rozhodčí řízení"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rozhodněte se o rozhodčím řízení: Interní platba"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chování"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušit"])}
}