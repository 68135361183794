export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona file"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questo formato di file non è supportato. \nFormati accettati:"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formato sconosciuto"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dimensione file troppo grande, dimensione massima:"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona o trascina qui i file da caricare"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carica"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File caricati"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["file"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminare"])}
}