export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kolekcja bankomatów"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj kolekcję"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Działania"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtry"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastawić"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualizacja"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie pokazuj zbiorów archiwalnych"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie pokazuj anulowanych kolekcji"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numer punktu"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak kolekcji"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Złożono wniosek o zbiórkę"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edytuj kolekcję"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmień status kolekcji"])}
}