export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sujet"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernier message"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégorie"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune demande"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégorie"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priorité"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sujet"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texte du message"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fichiers"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez télécharger un ou plusieurs fichiers. Taille maximale du fichier - 10 Mo."])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basse"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normale"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haute"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support technique"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demandes actives"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archive"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle demande"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traitement par l'opérateur"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demande créée"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En cours de traitement, attendez la réponse de l'opérateur"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demande fermée"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demande fermée"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun nouveau message"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau message de l'opérateur"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun nouveau message"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer à l'archive"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les demandes"])}
}