export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dar"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantia"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receber"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtros"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atualizar"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendedor"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dar"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receber"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limites"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ações"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["negociações"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negociação"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sem negociações"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ofertas"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benefício"])}
}