export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il servizio di pagamenti di massa consente di trasferire rapidamente fondi ad altri utenti ed effettuare operazioni di pagamento a favore dei fornitori di servizi."])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nella sezione"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carica file dei pagamenti"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["puoi caricare un file nei formati Excel (XLS, XLSX, CSV) o XML."])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elenco delle attività"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["consente di visualizzare lo stato delle richieste di pagamento di massa create in precedenza."])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puoi creare un file in un editor di fogli di calcolo come Excel."])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scarica esempio di file"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note importanti:"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formato file - CSV, XLS o XLSX."])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il file deve essere senza intestazioni."])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per evitare che i numeri lunghi vengano visualizzati in formato esponenziale (ad esempio, \"5,46546E+15\") e che vengano troncati gli zeri iniziali, puoi anteporre un singolo apice (') al numero, oppure impostare la cella in formato testo prima di inserire i dati."])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formato delle colonne per i pagamenti ai fornitori di servizi"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elenco delle colonne nel file:"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice operatore"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Indicato dall'elenco degli operatori riportato"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["qui"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". (Per tutti gli operatori di telefonia mobile puoi indicare il codice operatore 7000 - in questo caso l'operatore verrà determinato automaticamente in base al numero di telefono.)"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". L'importo è indicato nel ticker dell'asset da cui viene effettuato il trasferimento. Se non esiste tale ticker, verrà applicato automaticamente il tasso di conversione del sistema."])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commento al pagamento"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Non è obbligatorio, puoi lasciarlo vuoto; lunghezza non superiore a 255 caratteri."])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametro del pagamento 1"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". \nViene prescritto il valore del parametro richiesto dall'operatore, ad esempio il numero di telefono o l'indirizzo del cripto-attuale. \nMolti operatori sono limitati a un parametro di pagamento, ma se il numero di parametri è più di uno, è necessario indicarli tutti nelle colonne vicine."])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametro del pagamento 2"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametro del pagamento N"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puoi vedere i parametri del pagamento accedendo al pagamento di qualsiasi operatore:"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formato delle colonne per i trasferimenti di massa agli utenti"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice operatore"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Indicato come 0 o non indicato affatto."])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". L'importo è indicato nel ticker del conto da cui viene trasferito l'asset. Se non esiste tale ticker, verrà applicato automaticamente il tasso di conversione del sistema."])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commento al pagamento"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Non è obbligatorio, puoi lasciarlo vuoto; lunghezza non superiore a 255 caratteri."])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conto del destinatario"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Qui è indicato il numero di conto dell'utente a cui deve essere effettuato il trasferimento (20 cifre)."])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formato delle colonne per l'emissione di prestiti di massa da parte di un agente di credito"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Questa riga indica che i pagamenti sono prestiti."])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". L'importo è indicato nella valuta del prodotto di credito."])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commento al credito"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Non è obbligatorio, puoi lasciarlo vuoto; lunghezza non superiore a 255 caratteri."])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID del mutuatario"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Nota che il mutuatario deve avere un passaporto digitale Mfo-BorrowPerson."])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice del prodotto di credito"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Puoi visualizzarlo nell'elenco dei prodotti di credito durante l'emissione manuale del prestito."])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periodo"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Solo il numero dei periodi (i periodi specifici sono determinati nel prodotto di credito, ad esempio, possono essere giorni, settimane, mesi, ecc.)"])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice operatore (numero del modulo)"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Il completamento di questo e dei campi successivi non è obbligatorio; se non vengono completati, il mutuatario potrà successivamente effettuare il prelievo del prestito tramite il proprio account personale."])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annullare in caso di errore"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Annullare (1) il prestito emesso se la transazione di prelievo non riesce, oppure lasciarlo per il prelievo successivo (0)."])},
  "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametro del pagamento 1"])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Viene specificato il valore del parametro richiesto dall'operatore, ad esempio, il numero di telefono. Molti operatori si limitano a un solo parametro di pagamento, ma se il numero di parametri è maggiore di uno, devi specificarli tutti nelle colonne adiacenti alla prima."])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametro del pagamento 2"])},
  "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametro del pagamento N"])},
  "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il file deve indicare i conti o gli indirizzi dei cripto-asset dei destinatari in una valuta, poiché la cancellazione dei fondi viene eseguita da un conto e non da diversi."])}
}