export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vecchia password:"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuova password:"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma password:"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuova password:"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma password:"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"Nuova password\" e \"Conferma password\" non coincidono."])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La password non deve contenere caratteri cirillici"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creazione della password di conferma"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambio della password di conferma"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Torna alla home"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stringa da 4 a 65 caratteri. Sono ammessi caratteri dell'alfabeto latino, numeri e simboli"])}
}