export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paszport cyfrowy"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Więcej"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID Użytkownika:"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data rejestracji:"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ostatnia autoryzacja:"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moje aktywa"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konta"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobacz wszystkie"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doładuj"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wypłać"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak kont"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hasło potwierdzenia"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aby potwierdzić transakcje, należy utworzyć hasło potwierdzające."])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szablony"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszystko"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kwota"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popularne płatności"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wiadomości"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zwiń"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dalej..."])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak aktywów"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tworzyć"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balansować"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wycofać"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uzupełniać"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giełda"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do użytkownika"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ogólny balans"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wprowadzać"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilans niefiatowy"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilans Fiata"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nowe informacje"])}
}