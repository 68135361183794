export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuração de notificações"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ativar"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notificar sobre operações"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorização (login na conta pessoal, aplicativo móvel, etc.)"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notificar ao realizar \"Operações financeiras usando cartão\""])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Despesas (qualquer pagamento, transferência, pagamento de serviços, etc.)"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recebimento de fundos"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recebimento de mensagem interna"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recebimento de fatura para pagamento"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alteração de configurações de segurança"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar operações com código da mensagem"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar autorização com código da mensagem"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar ao realizar \"Operações financeiras usando cartão\""])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar alteração de configurações de segurança com código da mensagem"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar despesas com código da mensagem"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alterações não salvas, salvar?"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvar"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não salvar"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvar"])}
}