export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Všechny objednávky"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resetovat"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtry"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obnovit"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dávám"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostávám"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stav"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurz"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operace"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["poplatek"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušit"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opakovat"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádné objednávky"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimální částka uvedená pro transakci"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximální částka poskytnutá za transakci"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimální částka, která má být přijata"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximální přijatá částka"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počet pokusů o transakci"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivita"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Převedená kryptoaktiva"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ve kterém bude kryptoaktivum přijato"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nabíjecí účet"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Připsaný účet"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seznam subdealerů"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Účet není vybrán"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Období od"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Období do"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dnes"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Včera"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Týden"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Měsíc"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rok"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ data není vybrán"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvoření"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukončení"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konec"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Začátek"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Která část je fixní a která proměnná"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixuje se dávkovaná částka"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixuje se přijímaná částka"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ objednávky"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uživatel chce rychle směnit za nejlepší protinabídky"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Směna za pevný kurz"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stav"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zpracování nezačalo"])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zpracování začalo"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Částečně dokončeno"])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plně dokončeno"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plně zrušeno"])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Částečně dokončeno a zrušeno"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odevzdávané aktivum"])},
  "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přijímané aktivum"])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ data"])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimální částka je větší než maximální částka"])}
}