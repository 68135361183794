export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de transacción"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doy"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recibo"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de cambio"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiempo de espera"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acciones"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay transacciones activas"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mis transacciones"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activo"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activo interno"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactivo"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corresponsal"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código"])}
}