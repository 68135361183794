export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cassette number"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cassette ID"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cassette collection number"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cassette rating"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uploaded"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sum"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sum"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ATM"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sum"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actually"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sum"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentary on the cassette"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No data to display"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Device collection"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["valid"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["c"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the remains are carried away"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of bills"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sum"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ATM"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of bills"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sum"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dispenser"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reject/Retract"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bill acceptor"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reject cards"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cards"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["current"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unloaded"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ATM collection balances"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Device collection"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["valid"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cassette installed in ATM"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["seized"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the remains are carried away"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the remains are carried away"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["empty cassette:"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["adhesion:"])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional information:"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to date"])}
}