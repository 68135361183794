export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My stores"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stores not found"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate Widget Code"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create payment link"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete store"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete the store?"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register a store"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment mode"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceptance"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])}
}