export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["transazione"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametri generali"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica dei saldi sui conti"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alla fine dell'operazione:"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All'inizio dell'operazione:"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametri aggiuntivi"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operazioni nella blockchain"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Callback"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prossimo tentativo:"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tempo"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risposta"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protezione della transazione"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durata della protezione:"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fino a"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice di protezione:"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametri del modello"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome del modello:"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla operazione"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ripeti operazione"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva modello"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiudi"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero della transazione"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operazione"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corrispondente"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data del pagamento"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commissione"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commento"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risultato"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero del modulo di pagamento"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrata/Uscita"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica dei saldi degli indirizzi"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma accredito"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restituisci denaro al mittente"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operazione sospetta"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opzioni di azione:"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commissione del destinatario"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commissione del pagatore"])}
}