export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الدخول"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الدخول:"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور:"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نسيت كلمة المرور؟"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل هذه هي المرة الأولى هنا؟"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء حساب"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استعادة كلمة المرور"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الهاتف أو البريد الإلكتروني:"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استعادة"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استعادة كلمة المرور"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اللقب:"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم:"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الأب:"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الميلاد:"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الدخول"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العودة"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متابعة"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بحث"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم العثور على المستخدم"])}
}