export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通知设置"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["启用"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通知操作"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["授权（登录账户、移动应用等）"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在事件“使用卡进行金融操作”时通知"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支出（任何付款、转账、服务支付等）"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["资金收入"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通过内部邮件收到信件"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收到支付账单"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更改安全设置"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通过消息中的代码确认操作"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通过消息中的代码确认授权"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在“使用卡进行金融操作”时确认"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通过消息中的代码确认更改安全设置"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通过消息中的代码确认支出"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未保存更改，是否保存？"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不保存"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存"])}
}