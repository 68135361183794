export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emissione aggiuntiva"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crypto-asset"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conto - fonte di copertura"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conto di addebito"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emissione aggiuntiva"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commento"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continua"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emissione corrente"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TOKEN"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calcolo della copertura"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commissione"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commissione"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calcolo della copertura"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collegamento"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dati di base"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percentuale di copertura"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copertura futura"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Storno della copertura"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo che sarà in riserva dopo l'emissione aggiuntiva."])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Da stornare"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copertura attuale"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo che verrà addebitato dal tuo conto dopo l'emissione aggiuntiva."])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo di copertura futuro"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E — Importo di emissione attuale"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I — Emissione aggiuntiva"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["K — Tasso di collegamento"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["P — Percentuale di copertura"])}
}