export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status "])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thema"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letzte Nachricht"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktionen"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Anfragen"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priorität"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thema"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachrichtentext"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dateien"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können eine oder mehrere Dateien hochladen. Maximale Dateigröße - 10MB."])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senden"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niedrig"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normal"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoch"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technischer Support"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktive Anfragen"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archiv"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Anfrage"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wird vom Operator bearbeitet"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anfrage erstellt"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anfrage in Bearbeitung, warten Sie auf die Antwort des Operators"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anfrage geschlossen"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anfrage geschlossen"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine neuen Nachrichten"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Nachricht vom Operator"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine neuen Nachrichten"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ins Archiv senden"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Anfragen"])}
}