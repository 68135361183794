export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विज्ञापन की जानकारी"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मूल जानकारी"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मूल"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अतिरिक्त"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बनाने की तिथि"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सक्रिय"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्वीकार करें"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सौदे की शुरुआत तिथि"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सौदे की समाप्ति तिथि"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दर"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["निर्माता"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["देता है"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्राप्त करता है"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["केवल लिंक द्वारा उपलब्ध"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["केवल सत्यापित उपयोगकर्ताओं के लिए उपलब्ध"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सीमाएं"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विज्ञापन का मालिक"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विवरण"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टाइमआउट"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दिन"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["घंटे"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मिनट"])}
}