export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Инкассация банкоматов"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить инкассацию"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Действия"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фильтры"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сбросить"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обновить"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не показывать архивные инкассации"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не показывать отмененные инкассации"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер точки"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет инкассаций"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заявка на инкассацию подана"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактировать инкассацию"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить статус инкассации"])}
}