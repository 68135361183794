export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name:"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Name ist der vollständige Name des Krypto-Assets, wie er für jeden sichtbar ist: für den Emittenten selbst, für Käufer, Verkäufer, für die Verarbeitung usw."])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TICKER"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine Abkürzung mit 3 bis 10 Zeichen. \nLateinische Großbuchstaben, Bindestriche (Bindestriche) und Zahlen sind zulässig."])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie:"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie die Kategorie, in die Ihr Krypto-Asset am besten passt"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unterstützte Formate: PNG, JPG, JPEG, SVG. \nDateigröße nicht mehr als 1 MB, 500*500 Pixel"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreiben Sie, warum dieses Krypto-Asset vom Emittenten ausgegeben wird, in welchem Bereich es arbeitet oder arbeiten wird, was seine Aufgabe ist."])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo:"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ziel / Mission / Zweck:"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genauigkeit:"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emission:"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einheitswert:"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der Dezimalstellen für den Betrag. Die Genauigkeit der meisten Fiat-Währungen wie US-Dollar oder Euro beträgt zum Beispiel 2."])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Anzahl der Einheiten des auszugebenden Krypto-Assets. Der Ausgabebetrag wird sofort auf Ihrem Konto verfügbar sein, nachdem die Transaktion zur Erstellung des Kryptoaktivums abgeschlossen ist."])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angegebener Einheitswert:"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sicherheit:"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto:"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verpflichtete Person:"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschluss zur Ausgabe eines virtuellen digitalen Vermögenswerts:"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operationen in die Blockchain exportieren:"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blockchain-Typ:"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krypto-Asset-Transaktionen aktivieren:"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschränkungen/Sanktionen:"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie an, welche Staatsangehörigen oder steuerlich Ansässigen welcher Länder aus der obigen Liste nicht berechtigt sind, Krypto-Assets in Umlauf zu bringen:"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere Beschränkungen und Bedingungen:"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hosting-Adresse:"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whitepaper-Adresse:"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail:"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telegram:"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twitter:"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook:"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Kontakte:"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krypto-Asset erstellen"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name und Zweck"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finanzielle Merkmale"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emittent"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export in die Blockchain"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limits und Beschränkungen"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakte"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht definiert"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feste Bindung an Fiat-Währungen und andere Vermögenswerte"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emittent (Person, die ein Krypto-Asset ausgibt)"])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anbieter (Person, die ein Krypto-Asset vertreibt)"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Absender"])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empfänger"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich (Sponsoring aktivieren)"])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krypto-Asset-Platzierung"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krypto-Asset bezeichnet eine digitale Darstellung eines Wertes oder Rechts, die elektronisch mit Hilfe der Blockchain-Technologie übertragen und gespeichert werden kann. Krypto-Assets umfassen NICHT Fiat-Währungen, Wertpapiere und andere Finanzinstrumente und Vermögenswerte, die einer gesonderten staatlichen Regulierung unterliegen."])},
  "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krypto-Asset bearbeiten"])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwaltung"])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annahme"])},
  "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grund für die Ablehnung der Annahme"])},
  "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto des Anbieters"])},
  "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto des Anbieters in der verbindlichen Währung"])},
  "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Anbieter ist ein Vertreter des Emittenten eines Vermögenswerts bei Kauf-/Verkaufstransaktionen auf dem Primärmarkt. \nDas Konto ist passiv. \nKann ein Vermögensausgabekonto sein"])},
  "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Konto ist passiv, währungsgebunden. \nMuss demselben Benutzer gehören wie das vorherige Konto"])},
  "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ermöglichen Sie Benutzern den Kauf eines Vermögenswerts an Geldautomaten"])},
  "62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ermöglichen Sie den Verkauf von Vermögenswerten durch Benutzer an Geldautomaten"])},
  "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aftermarket-Kaufformular"])},
  "64": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sekundärmarkttransaktionen werden über Gateways abgewickelt"])},
  "65": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aftermarket-Verkaufsformular"])},
  "66": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sekundärmarkttransaktionen werden über Gateways abgewickelt"])},
  "67": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erlauben Sie Transaktionen mit einem Vermögenswert an der Börse"])},
  "68": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erlauben Sie Vermögenstransaktionen über P2P"])},
  "69": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie ein Einzelunternehmer oder ein Vertreter einer juristischen Person sind, müssen Sie die Entscheidungsdatei über die Freigabe eines digitalen Vermögenswerts im PDF-Format herunterladen. \nDie Lösung muss alle wesentlichen Merkmale des virtuellen Vermögenswerts enthalten und mit einer elektronischen Signatur des einzelnen Unternehmers oder dieser juristischen Person versehen sein. \nEin Beispiel für eine Entscheidung zur Ausgabe eines digitalen Vermögenswerts finden Sie auf den Seiten unserer Website im Bereich „Dokumente“."])},
  "70": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platzieren eines virtuellen Assets"])},
  "71": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ersatzausgabekonto"])},
  "72": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie aus Emittentenkonten aus"])},
  "73": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abzuschreibender Betrag:"])},
  "74": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kein Konto"])},
  "75": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Konto erstellen"])},
  "76": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max. \nEmission:"])},
  "77": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Emissionsgröße überschreitet die max. \nEmissionen"])},
  "78": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max. \nDie Emission ist kleiner als die Emissionsgröße oder gleich Null"])},
  "79": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formen fehlen"])}
}