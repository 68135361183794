export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редагувати зовнішній актив"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скасувати"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберегти"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Група"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Синоніми"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Країни"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прив'язати до активу"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Одиниці вимірювання"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скорочено"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Точність"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активний"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Акцептований для торгівлі"])}
}