export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["广告"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["描述"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["细节"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最小"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最大"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金额限制"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["等待时间（分钟）"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["广告活动"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["仅通过链接可用"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["仅对已验证用户可用"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存"])}
}