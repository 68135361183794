export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаблони"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Знайти"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У групі"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Групи платежів"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Платежі"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума платежу"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рахунок переказу"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коментар"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаблон"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва шаблону:"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберегти"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберегти шаблон"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перевести"])}
}