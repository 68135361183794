export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logotipo"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TICKER"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoría"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Misión"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acciones"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de criptoactivos"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay criptoactivos"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emisión de token"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceptar"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mercado"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precisión"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primario"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secundario"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emparejado con otro activo"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información detallada sobre el criptoactivo"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar datos del criptoactivo"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emisión adicional"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar criptoactivo"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de criptoactivos"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceptar"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actividad"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sí"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los tokens se pueden comprar en el cajero"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los tokens se pueden vender en el cajero"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoría"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tokens del mercado secundario"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualización de tokens"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos aceptados"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos los míos"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UID del usuario"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quema de activos"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para usuario específico"])}
}