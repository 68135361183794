export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidávání sbírky"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Základní informace"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Základy"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dávkovač"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Číslo bodu"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počet karet na aplikaci"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Příjemce faktury"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karty"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sběratelé hotovosti"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poznámka pro sběratele"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentář ke sbírce"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Načítání mimo provoz"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyměňte kazetu akceptoru bankovek"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentář ke kazetě"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentář ke kartám"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyjměte všechny karty z kazety dávkovače Reject Card"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyjměte všechny karty z tuby"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Číslo bodu je povinné pole, které je nutné vyplnit"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID kazety 1"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID kazety 2"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID kazety 3"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID kazety 4"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hodnocení kazety 1"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počet bankovek v kazetě 1"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Množství v kazetě 1"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hodnocení kazety 2"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počet bankovek v kazetě 2"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentář k kazetě 1"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID kazety"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hodnocení kazety"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počet účtů"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Součet"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentář ke kazetě"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložit"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úprava sbírky"])}
}