export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Атауы:"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аты крипто-активтің толық атауы болып табылады, өйткені оны әркім көреді: эмитенттің өзі, сатып алушылар, сатушылар, өңдеу және т.б."])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ТИКЕР"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3-10 таңбадан тұратын аббревиатура. \nЛатынның бас әріптері, сызықшалар (сызықшалар) және сандар қабылданады."])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Санат:"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Криптографиялық активіңізге ең қолайлы санатты таңдаңыз"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қолдау көрсетілетін пішімдері: png, jpg, jpeg, svg. \nФайл өлшемі 1 МБ аспайды, 500*500 пиксель"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бұл крипто-активті неліктен эмитент шығарғанын сипаттаңыз, ол қай салада жұмыс істейді немесе жұмыс істейді, оның миссиясы қандай"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Логотипі:"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мақсат / миссия / тағайындалу:"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дәлдік:"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эмиссия:"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бірліктің құны:"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сома үшін ондық таңбалардың саны. Мысалы, АҚШ доллары немесе Еуро сияқты көптеген фиат валюталарының дәлдігі 2."])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шығарылған крипто-актив бірліктерінің саны. Эмиссия сомасы крипто-актив жасау операциясы аяқталғаннан кейін сіздің шотыңызда бірден қолжетімді болады."])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бір бірліктің белгіленген құны:"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қамтамасыз ету:"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шот:"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Міндеттемелерді орындайтын тұлға:"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Криптографиялық активті шығару туралы шешім:"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Блокчейнге операцияларды экспорттау:"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Блокчейн түрі:"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Криптоактивпен операцияларды қосу:"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шектеулер/Санкциялар:"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жоғарыда аталған тізімдегі қай елдердің азаматтарына немесе салық резиденттеріне крипто-активті айналымда пайдалануға тыйым салынғанын көрсетіңіз:"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Басқа шектеулер мен шарттар:"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хостинг мекенжайы:"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ақ кітаптың мекенжайы:"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Электрондық пошта:"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telegram:"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twitter:"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook:"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Басқа байланыстар:"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Алдыңғыға"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сақтау"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Келесі"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Криптографиялық активті жасау"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Атауы мен мақсаты"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қаржылық сипаттамалар"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эмитент"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Блокчейнге экспорт"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шектеулер мен шектеулер"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Байланыстар"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анықталмаған"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фиаттық валюталар мен басқа активтерге бекітілген бекіту"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эмитент (крипто-активті шығаратын тұлға)"])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оферташы (крипто-активті таратушы тұлға)"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жіберуші"])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Алушы"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мен (демеушілікті қосу)"])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Криптоактивті орналастыру"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Крипто активі блокчейн технологиясын пайдалана отырып электронды түрде тасымалданатын және сақталуы мүмкін құнның немесе құқықтың сандық көрінісін білдіреді. Крипто активтерге фиат валюталары, бағалы қағаздар және басқа да қаржы құралдары мен жеке мемлекеттік реттеуге жататын активтер ЕМЕС."])},
  "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Криптографиялық активті өңдеу"])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Әкімшілік"])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қабылдау"])},
  "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қабылдаудан бас тарту себебі"])},
  "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ұсыныс берушінің шоты"])},
  "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Міндетті валютадағы ұсыныс берушінің шоты"])},
  "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ұсынушы – бастапқы нарықта сатып алу/сату мәмілелерінде актив эмитентінің өкілі. \nЕсептік жазба пассивті. \nАктив шығару шоты болуы мүмкін"])},
  "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Есептік жазба пассивті, валютамен байланысты. \nАлдыңғы тіркелгі сияқты бір пайдаланушыға тиесілі болуы керек"])},
  "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пайдаланушыларға банкоматтардан актив сатып алуға рұқсат беріңіз"])},
  "62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Банкоматтардағы пайдаланушылар активті сатуға мүмкіндік береді"])},
  "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сатудан кейінгі сатып алу нысаны"])},
  "64": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Екінші нарықтық операциялар шлюздер арқылы жүзеге асырылады"])},
  "65": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кейінгі сату формасы"])},
  "66": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Екінші нарықтық операциялар шлюздер арқылы жүзеге асырылады"])},
  "67": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Биржадағы активпен транзакцияларға рұқсат беріңіз"])},
  "68": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["P2P арқылы актив транзакцияларына рұқсат беріңіз"])},
  "69": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Егер сіз жеке кәсіпкер немесе заңды тұлғаның өкілі болсаңыз, цифрлық активті шығару туралы шешім файлын PDF форматында жүктеп алуыңыз қажет. \nШешім виртуалды активтің барлық негізгі сипаттамаларын қамтуы және жеке кәсіпкердің немесе осы заңды тұлғаның электрондық қолтаңбасымен қол қойылуы тиіс. \nЦифрлық активті шығару туралы шешімнің мысалын біздің веб-сайт беттерінде \\\"Құжаттар\\\" бөлімінде табуға болады."])},
  "70": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виртуалды активті орналастыру"])},
  "71": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Есептік жазбаны ауыстырыңыз"])},
  "72": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эмитент шоттарынан таңдаңыз"])},
  "73": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Есептен шығаруға жататын сома:"])},
  "74": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Есептік жазба жоқ"])},
  "75": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аккаунты құру"])},
  "76": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Макс. \nэмиссия:"])},
  "77": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шығарылым мөлшері максимумнан асады. \nшығарындылар"])},
  "78": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Макс. \nэмиссия мөлшерінен аз немесе нөлге тең"])},
  "79": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Формалар жоқ"])}
}