export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency pairs"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create currency pair"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit currency pair"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete currency pair"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Given currency"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Received currency"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gateway"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update type"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency pairs not found"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete the currency pair"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["linked to another currency"])}
}