export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регистрация"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Персональный аккаунт"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бизнес аккаунт"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фамилия:"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя:"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отчество: (если есть)"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Допустимы буквы латинницы, а также дефис и апостроф."])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bond"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Допустимы буквы латинницы, а также дефис, пробел и апостроф."])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["James"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["None"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Допустимы буквы латинницы, а также дефис, кавычки, пробелы, цифры и апостроф."])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"My Company\" Ltd"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Телефон"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Допустимы латинские буквы (прописные и строчные), цифры, символы _ - . ! $ % ^ * ="])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повторите пароль"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароли не совпадают"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назад"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регистрация"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Далее"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Данные пользователя"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Укажите корректный адрес электронной почты или номер телефона. Номер телефона указывается в международном формате, например: +499001234567."])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы успешно зарегистрированы"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Теперь вы можете "])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["авторизоваться в личном кабинете"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш аккаунт будет проверен нашем сотрудником, о результатах его активации мы сообщим вам позже"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["выслано письмо со ссылкой для подтверждения регистрации"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На ваш адрес электронной почты"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["После первой авторизации вам будет предложено подтвердить номер телефона"])}
}