export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сервис массовых платежей позволяет быстро переводить средства другим пользователям и выполнять операции оплаты в адрес поставщиков услуг."])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В разделе"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузить файл платежей"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["вы можете загрузить файл в формате Excel (XLS, XLSX, CSV) или XML."])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список задач"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["позволяет посмотреть статусы созданных ранее запросов на массовые выплаты. "])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы можете создать файл в табличном редакторе Excel. "])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скачать пример файла"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Важные замечания:"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Формат файла - CSV, XLS или XLSX."])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Файл должен быть без заголовков."])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для того, чтобы длинные числа не превращались в формат экспоненты (например, \"5,46546E+15\") и не отрезались ведущие нули, можно перед числом поставить символ одиночной кавычки ('), либо перед вводом данных задать ячейке текстовый формат."])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Формат колонок для платежей в адрес поставщиков услуг"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список колонок в файле:"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код оператора"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Указывается из списка операторов, приведенных"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["здесь"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". (Для всех операторов сотовой связи можно указывать в качестве кода оператора 7000 - в этом случае оператор будет опеределен автоматически по номеру телефона.)"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Сумма указывается в тикере актива, с которого осуществляется перевод. Если такого тикера нет, к операции автоматически применяется системный коэффициент конвертации."])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комментарий к платежу"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Заполнять не обязательно, можно оставить пустым; длина не более 255 символов."])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Параметр платежа 1"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Прописывается значение параметра, требуемого оператором, например, номер телефона или адрес крипто-актива. Многие операторы ограничиваются одним параметром платежа, но если количество параметров больше одного, то Вам необходимо указывать их все в соседних от первого столбцах."])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Параметр платежа 2"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Параметр платежа N"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Параметры платежа можно посмотреть перейдя к оплате какого-либо оператора:"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Формат колонок для массовых переводов пользователям"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код оператора"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Указывается 0 или вообще не указывается."])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Сумма отображается в тикере счета, с которого переводится актив. Если нет такого тикера, к операции автоматически применяется коэффициент пересчета системы."])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комментарий к платежу"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Заполнять не обязательно, можно оставить пустым; длина не более 255 символов."])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Счет получателя"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Здесь указывается номер счета пользователя, которому необходимо выполнить перевод (20 цифр)."])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Формат колонок для массовой выдачи займов кредитным агентом"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Эта строка указывает, что платежи представляют собой займы."])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Сумма указывается в валюте кредитного продукта."])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комментарий к кредиту"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Заполнять не обязательно, можно оставить пустым; длина не более 255 символов."])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID заемщика"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Обратите внимание, что у заемщика должен быть цифровой паспорт Mfo-BorrowPerson."])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код кредитного продукта"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Посмотреть его можно в списке кредитных продуктов на ручном предоставлении займа."])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Период"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Просто число периодов (какие именно периоды используются определено в кредитном продукте, например, это могут быть дни, недели, месяцы и т.д.)"])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код оператора (номер формы)"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Заполнение этого и следующих полей не обязательно, если их не заполнять, то заемщик сможет позднее самостоятельно осуществить вывод кредита через свой личный кабинет."])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменить в случае ошибки"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Отменить (1) выданный кредит, если транзакция вывода завершится с ошибкой, или оставить для последующего самостоятельного вывода (0)."])},
  "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Параметр платежа 1"])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Прописывается значение параметра, требуемого оператором, например, номер телефона. Многие операторы ограничиваются одним параметром платежа, но если количество параметров больше одного, то Вам необходимо указывать их все в соседних от первого столбцах."])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Параметр платежа 2"])},
  "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Параметр платежа N"])},
  "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В файле должны быть указаны счета или адреса крипто-активов получателей в одной валюте, так как списание средств осуществляется с одного счета, а не с нескольких."])}
}