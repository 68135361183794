export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Үлгілер"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Толықтыру"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аудару"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шығару"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Үлгі атауы"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оператор"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Өрістер"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операциялар"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Үлгілер жоқ"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Алушы"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№ үлгіні шынымен жойғыңыз келе ме"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Үлгіні қайта атау "])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қайта атау"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жою"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пайдалану"])}
}