export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور القديمة:"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور الجديدة:"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد كلمة المرور:"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور الجديدة:"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد كلمة المرور:"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"كلمة المرور الجديدة\" و \"تأكيد كلمة المرور\" غير متطابقتين."])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب ألا تحتوي كلمة المرور على حروف كيريلية"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء كلمة مرور تأكيد"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تغيير كلمة مرور التأكيد"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلى الصفحة الرئيسية"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سلسلة من 4 إلى 65 حرفًا. الأحرف المسموح بها هي الأحرف اللاتينية، الأرقام والرموز"])}
}