export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["linia o długości do 255 znaków"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["liczba całkowita"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["20 cyfr"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0 lub 1"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["liczba ułamkowa, precyzja dwie cyfry, separator kropki"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["liczba całkowita"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ciąg do 255 znaków, opcjonalnie"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["liczba całkowita, opcjonalna"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ciąg 32 znaków"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przechowuj kod płatności; powinien być unikalny dla każdej płatności; Podczas transmisji linii"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unikalny numer zostanie wygenerowany automatycznie"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["numer sklepu nadawany po rejestracji"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["numer konta sklepu; konto to określa także w jakiej walucie należy dokonać płatności (w większości przypadków użytkownik może dokonać płatności w dowolnej walucie, a środki zostaną automatycznie przeliczone na walutę odbiorcy)"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zabronić klientowi zmiany określonej kwoty (1 - zakaz, 0 - zezwolenie)"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kwota beneficjenta w walucie rachunku beneficjenta"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["numer formularza za pośrednictwem którego akceptowana jest płatność"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["komentarz wyświetlany płatnikowi jako cel płatności"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dodatkowy opcjonalny parametr, który będzie przekazywany do skryptu wyniku płatności, a także do stron zwrotnych"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres URL, pod który klient powróci po udanej płatności; jeśli nie jest określony, jest pobierany z ustawień sklepu"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres URL, na który klient powróci po wystąpieniu błędu; jeśli nie jest określony, jest pobierany z ustawień sklepu"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres URL, pod który zostanie wysłane powiadomienie o wynikach płatności; jeśli nie jest określony, jest pobierany z ustawień sklepu"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["okres ważności faktury (w minutach), domyślnie 60"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["podpisanie wiadomości tajnym kluczem sklepu"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0 lub 1"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 - jeśli się powiedzie, 0 - w przeciwnym razie"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["liczba całkowita"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kod błędu (0 jeśli nie ma błędu)"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["linia"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tekst błędu (lub wyjaśnienie statusu płatności)"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0 lub 1"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sygnał, że ostateczny status płatności nie jest jeszcze znany"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przechowuj kod płatności"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numer transakcji"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protokół Merchant"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akceptacja płatności na stronie jest możliwa tylko dla sklepów internetowych zarejestrowanych w systemie. Aby zarejestrować sklep, przejdź na stronę"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zarejestruj sklep"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przekierowanie użytkownika do merchant w celu dokonania płatności"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aby dokonać płatności, należy przekierować użytkownika na URL: https://merchant.[twoja domena] i przekazać następujące parametry metodą GET lub POST:"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opis"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ danych"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podpis"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["obliczany jest poprzez konkatenację przekazywanych parametrów i hashowanie metodą hmac z kluczem tajnym. Liczba i kolejność konkatenowanych parametrów zależy od parametru"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["identified"])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["stała kwota"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kolejność jest następująca:"])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ext_transact + num_shop + keyt_shop + identified + sum + payform + comment + free_param + url_success + url_decline + url_callback"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dla linku, gdzie klient"])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["płatność, kolejność jest następująca (kwota nie jest uwzględniana w podpisie):"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ext_transact + num_shop + keyt_shop + identified + payform + comment + free_param + url_success + url_decline + url_callback"])},
  "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link do płatności można wygenerować na stronie"])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'Moje sklepy'"])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przykład formularza:"])},
  "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powrót na stronę sklepu po zakończeniu operacji"])},
  "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeśli płatnik odmawia płatności lub operacja kończy się błędem, system płatności proponuje przejście na odpowiednią stronę określoną w ustawieniach sklepu lub przekazaną wraz z formularzem"])},
  "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["url_decline"])},
  "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeśli operacja kończy się pomyślnie, następuje przejście na stronę"])},
  "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["url_success"])},
  "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Te skrypty otrzymają również wyniki płatności (metodą określoną w ustawieniach sklepu. Domyślnie - GET):"])},
  "62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa"])},
  "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opis"])},
  "64": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ danych"])},
  "65": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podpis"])},
  "66": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["obliczany jest poprzez konkatenację przekazywanych parametrów i hashowanie metodą hmac z kluczem tajnym."])},
  "67": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kolejność konkatenowanych parametrów:"])},
  "68": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ext_transact + amount + success + result + result_text + free_param"])},
  "69": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powiadomienie sklepu o statusie płatności (callback)"])},
  "70": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Po dokonaniu płatności wyniki płatności są wysyłane na skrypt określony podczas rejestracji sklepu"])},
  "71": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["result_url"])},
  "72": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ten skrypt otrzymuje dane zarówno o udanych płatnościach, jak i w przypadku błędu."])},
  "73": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Te skrypty otrzymają również wyniki płatności (metodą określoną w ustawieniach sklepu. Domyślnie - GET)."])},
  "74": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["W niektórych przypadkach powiadomienie z wynikami nie przychodzi (na przykład, jeśli klient przeszedł do płatności i zamknął stronę przeglądarki)."])},
  "75": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa"])},
  "76": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opis"])},
  "77": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ danych"])},
  "78": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podpis"])},
  "79": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["obliczany jest poprzez konkatenację przekazywanych parametrów i hashowanie metodą hmac z kluczem tajnym."])},
  "80": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kolejność konkatenowanych parametrów:"])},
  "81": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["transact + ext_transact + amount + success + result + result_text + free_param"])},
  "82": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obliczanie podpisu"])},
  "83": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podpis jest tworzony poprzez konkatenację wymaganych parametrów w określonej kolejności i hashowanie metodą hmac."])},
  "84": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kolejność i liczba konkatenowanych parametrów zostały opisane powyżej."])},
  "85": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["W poniższych listingach pokazano przykłady funkcji obliczających podpis w różnych językach, gdzie konkatenowany podpisywany ciąg jest przekazywany jako parametr"])},
  "86": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listing 1. Obliczanie podpisu w PHP."])},
  "87": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listing 2. Obliczanie podpisu w Pythonie."])},
  "88": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listing 3. Obliczanie podpisu w Perlu."])},
  "89": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dla płatności z"])},
  "90": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["może zmienić kwotę"])},
  "91": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["płatność za towar"])},
  "92": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapłać"])},
  "93": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wygeneruj podpis z kluczem"])},
  "94": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["signed_str"])},
  "95": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a hasło w parametrze"])},
  "96": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["key"])}
}