export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अवधि से"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अवधि तक"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["निर्यात"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फिल्टर"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रीसेट"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपडेट"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["राशि"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लेन-देन"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पैरामीटर"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रकार"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लेन-देन"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["तिथि"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ऑपरेशन"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संबंधी"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["राशि"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्थिति"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पैरामीटर/टिप्पणी"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई ऑपरेशन नहीं मिला"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ऑपरेशन की राशि"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आमदनी:"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" बार"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खर्च:"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ऑपरेशन प्रकार"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भुगतान, सेवा भुगतान"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आंतरिक ट्रांसफर"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बिल भुगतान"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपने खातों के बीच ट्रांसफर"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्रिप्टो चेक बनाना"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्रिप्टो चेक सक्रियण"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अन्य"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आमद/खर्च"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आमद"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खर्च"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["= बराबर"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≠ बराबर नहीं"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["> से अधिक"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≥ से अधिक या बराबर"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["< से कम"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≤ से कम या बराबर"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्थिति"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सफलतापूर्वक पूर्ण"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रक्रिया में"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["त्रुटि"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आज"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कल"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सप्ताह"])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["महीना"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वर्ष"])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ऑपरेशन इतिहास"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खाता पूर्ति"])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई खाता चयनित नहीं"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एक्सचेंज पर विनिमय"])},
  "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पता पूर्ति"])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपने बटुए के भीतर ट्रांसफर"])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संवाददाता"])},
  "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संचालन"])}
}