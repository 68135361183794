export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszystkie zamówienia"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resetuj"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtry"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualizuj"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daję"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otrzymuję"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurs"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operacje"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["prowizje"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anuluj"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powtórz"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak wniosków"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Min. kwota do wysłania"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maks. kwota do wysłania"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Min. kwota do otrzymania"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maks. kwota do otrzymania"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liczba prób wykonania transakcji"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktywny"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waluta do wysłania"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waluta do otrzymania"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto do wysyłania"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto do otrzymywania"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista subdealerów"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie wybrano konta"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okres od"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okres do"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dziś"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wczoraj"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tydzień"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miesiąc"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rok"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie wybrano typu daty"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wygaśnij"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zakończ"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rozpocznij"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Która część jest stała, a która zmienna"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podana kwota jest stała"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kwota do otrzymania jest stała"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ zamówienia"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Użytkownik chce szybko wymienić na najlepsze kontroferty"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wymiana po ściśle określonym kursie"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przetwarzanie nie rozpoczęte"])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przetwarzanie rozpoczęte"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Częściowo zakończone"])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Całkowicie zakończone"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Całkowicie anulowane"])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Częściowo zakończone i anulowane"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktywo wychodzące"])},
  "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktywo przychodzące"])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ daty"])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimalna kwota jest większa niż maksymalna kwota"])}
}