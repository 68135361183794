export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modelos"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encontrar"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No grupo"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupos de pagamentos"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagamentos"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantia do pagamento"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conta de transferência"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantia"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentário"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modelo"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome do modelo:"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvar"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvar modelo"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferir"])}
}