export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشعار"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رمز"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفئة"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المهمة"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإجراءات"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قائمة الأصول المشفرة"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد أصول مشفرة"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إصدار الرمز"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القبول"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السوق"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدقة"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أولي"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ثانوي"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مطابق مع أصل آخر"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات مفصلة عن أصول التشفير"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحرير البيانات المتعلقة بأصول التشفير"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قضية إضافية"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حذف أصول التشفير"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قائمة الأصول المشفرة"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قبول"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نشاط"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نعم"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكن شراء الرموز المميزة من cryptomat"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكن بيع الرموز المميزة في cryptomat"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فئة"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رموز السوق الثانوي"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض الرموز"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كل المقبولة"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كل ما لدي"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معرف المستخدم"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حرق الأصول"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لمستخدم محدد"])}
}