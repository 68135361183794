export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一行最多 255 个字符"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["整数"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["20位数字"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0 或 1"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["小数、精度两位数、点分隔符"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["整数"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["字符串最多 255 个字符，可选"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["整数，可选"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["字符串 32 个字符"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商店支付代码；每次支付必须唯一；当传输字符串时"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["将自动生成一个唯一的编号"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注册后分配的商店编号"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商店账号；该账户还决定必须以哪种货币进行支付（大多数情况下，用户可以用任何货币进行支付，资金将自动转换为收款人的货币）"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["禁止客户端更改指定金额（1 - 禁止，0 - 允许）"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收款人账户货币金额"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["接受支付的表单编号"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["作为支付目的向支付人显示的备注"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["将传递给支付结果脚本以及返回页面的附加可选参数"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客户端支付成功后返回的URL；如果未指定，则从商店设置中获取"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客户端出错后返回的URL；如果未指定，则从商店设置中获取"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["将发送支付结果通知的URL；如果未指定，则从商店设置中获取"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付单有效期（以分钟为单位），默认60"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用商店的密钥签名消息"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0 或 1"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 - 如果成功，0 - 否则"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["整数"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["错误代码（如果没有错误则为0）"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["字符串"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["错误文本（或支付状态说明）"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0 或 1"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["表示最终支付状态尚未确定的标志"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商店支付代码"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易号"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商家协议"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["网站只有注册在系统中的在线商店才能接受支付。要注册商店，请访问页面"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注册商店"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重定向用户到商家进行支付"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["要进行支付，需要将用户重定向到URL：https://merchant.[您的域名]，并通过GET或POST方法传递以下参数："])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名称"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["描述"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["数据类型"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["签名"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通过连接传递的参数并使用密钥进行hmac哈希计算得出。连接参数的数量和顺序取决于参数"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["identified"])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["固定金额"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["顺序如下："])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ext_transact + num_shop + keyt_shop + identified + sum + payform + comment + free_param + url_success + url_decline + url_callback"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对于客户"])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付的链接，顺序如下（金额不包含在签名中）："])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ext_transact + num_shop + keyt_shop + identified + payform + comment + free_param + url_success + url_decline + url_callback"])},
  "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可以在页面上生成支付链接"])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'我的商店'"])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["表单示例："])},
  "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作完成后返回商店网站"])},
  "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果支付人拒绝支付或操作出错，支付系统会建议跳转到商店设置中指定的或通过表单传递的相应页面"])},
  "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["url_decline"])},
  "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果操作成功完成，跳转到页面"])},
  "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["url_success"])},
  "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["这些脚本也将收到支付结果（通过商店设置中指定的方法。默认 - GET）："])},
  "62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名称"])},
  "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["描述"])},
  "64": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["数据类型"])},
  "65": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["签名"])},
  "66": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通过连接传递的参数并使用密钥进行hmac哈希计算得出。"])},
  "67": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["连接参数的顺序："])},
  "68": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ext_transact + amount + success + result + result_text + free_param"])},
  "69": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商店支付状态通知（回调）"])},
  "70": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付完成后，支付结果将发送到商店注册时指定的脚本"])},
  "71": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["result_url"])},
  "72": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["该脚本接收成功支付和错误情况的数据。"])},
  "73": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["这些脚本也将收到支付结果（通过商店设置中指定的方法。默认 - GET）。"])},
  "74": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在某些情况下，结果通知可能不会到达（例如，如果客户去支付并关闭了浏览器页面）。"])},
  "75": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名称"])},
  "76": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["描述"])},
  "77": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["数据类型"])},
  "78": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["签名"])},
  "79": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通过连接传递的参数并使用密钥进行hmac哈希计算得出。"])},
  "80": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["连接参数的顺序："])},
  "81": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["transact + ext_transact + amount + success + result + result_text + free_param"])},
  "82": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["签名计算"])},
  "83": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["签名通过按指定顺序连接必要参数并使用hmac方法进行哈希计算生成。"])},
  "84": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["连接参数的顺序和数量如上所述。"])},
  "85": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以下列表显示了使用不同语言计算签名的函数示例，其中要签名的连接字符串作为参数传递"])},
  "86": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["列表1. PHP中的签名计算。"])},
  "87": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["列表2. Python中的签名计算。"])},
  "88": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["列表3. Perl中的签名计算。"])},
  "89": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对于支付"])},
  "90": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可以更改金额"])},
  "91": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商品支付"])},
  "92": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付"])},
  "93": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用密钥生成签名"])},
  "94": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["signed_str"])},
  "95": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["和参数中的密钥短语"])},
  "96": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["key"])}
}