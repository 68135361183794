export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код угоди"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Віддаю"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отримую"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Напрямок"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курс"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Таймаут"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дії"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активних угод немає"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мої угоди"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активно"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Внутрішній актив"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неактивно"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кореспондент"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код"])}
}