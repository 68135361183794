export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№  "])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Логотип"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ТИКЕР"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категория"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Миссия"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Действия"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список крипто-активов"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет крипто-активов"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выпуск токена"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Акцепт"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рынок"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Точность"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Первичный"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вторичный"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сопоставлен с другим активом"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подробная информация о криптоактиве"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактировать данные о крипто-активе"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дополнительная эмиссия"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить криптоактив"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список криптоактивов"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Акцепт"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активность"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Да"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Токены можно купить в криптомате"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Токены можно продать в криптомате"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категория"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Токены вторичного рынка"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отображение токенов"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все акцептованные"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все мои"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UID пользователя"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сжигание актива"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для конкретного пользователя"])}
}