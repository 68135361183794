export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收入日期"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["处理日期"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["状态"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["付款数量"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["其中成功"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["其中错误"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大宗支付"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["结果"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["服务提供商"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金额"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手续费"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评论"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. 参数"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未找到付款"])}
}