export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attività"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrizione dell'asset"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun annuncio"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I miei annunci"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I miei annunci"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuovo annuncio"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attività"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attivo"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non attivo"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direzione"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset esterno"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset interno"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrizione"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dò"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricevo"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limiti"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di pubblicazione"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo del conto"])}
}