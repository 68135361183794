export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can make several payments at the same time. \nTo do this, you need to first create a file with a description of payments in the \"XLS\", \"XLSX\", \"CSV\" format."])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File analysis"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correspondent"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sum"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameter"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File Information"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total payments:"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for total cost:"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment File"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Withdrawal account"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make payments"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading a payment file"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check the file contents"])},
  "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mass payments and transfers"])}
}