export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус "])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тақырып"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Соңғы хабарлама"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Санат"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Әрекеттер"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сұраулар жоқ"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Санат"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Басымдық"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тақырып"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хабарлама мәтіні"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Файлдар"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бір немесе бірнеше файлды жүктей аласыз. Файлдың максималды өлшемі - 10 Мб."])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жіберу"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Төмен"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қалыпты"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жоғары"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Техникалық қолдау"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Белсенді сұраулар"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мұрағат"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жаңа сұрау"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оператор өңдеп жатыр"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сұрау жасалды"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жұмыс жүргізілуде, оператордың жауабын күтіңіз"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сұрау жабылды"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сұрау жабылды"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жаңа хабарламалар жоқ"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оператордан жаңа хабарлама"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жаңа хабарламалар жоқ"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мұрағатқа жіберу"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Барлық сұраулар"])}
}