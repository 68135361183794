export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自动柜员机"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["点击以全屏显示表格"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重置"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刷新"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活动"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["经销商"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活跃"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不活跃"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建自动柜员机"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编辑加密货币ATM"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主要信息"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主要"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["开放时间"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名称"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录名"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地址"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["安装位置描述"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["标准化"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["非标准化"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工作模式"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["星期一"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["星期二"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["星期三"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["星期四"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["星期五"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["星期六"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["星期日"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["午休时间"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重复密码"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用现有帐户"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建新帐户"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["基本信息"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["账户"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工作时间（参考信息）"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活动"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未找到加密机"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自动柜员机号码"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["纬度"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["经度"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帮助热线"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["子经销商 ID"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["账户"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看"])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["货币"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中转账户"])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建密码机"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编辑加密货币"])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码"])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是的"])},
  "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["禁止"])},
  "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["允许"])},
  "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收藏家名单"])},
  "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加收集器"])},
  "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无法转到托收余额 - 无数据"])},
  "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收藏家名单"])},
  "62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加收集器"])},
  "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["允许"])},
  "65": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["验收"])},
  "67": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地址"])},
  "68": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编辑自动柜员机"])},
  "69": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看当前收款余额"])},
  "70": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看已卸载的收款余额"])},
  "71": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码"])},
  "73": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果您未指定经销商，ATM 将链接到您"])},
  "74": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["纬度和经度是可以复制的地理坐标，例如，通过输入 ATM 地址从 Google 地图复制"])},
  "75": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["安装位置"])},
  "76": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["必须至少选择一列才能显示表格"])},
  "77": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["节省"])},
  "78": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创造"])},
  "79": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["前往 ATM 设备"])}
}