export default {
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant à transférer"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant à recevoir"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtres"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualiser"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["transactions"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune offre"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offres P2P"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID du vendeur"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code de l'offre"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actif"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendeur vérifié"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réservé aux utilisateurs vérifiés"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de faire une transaction avec soi-même"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner l'actif"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations sur l'annonce"])}
}