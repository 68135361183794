export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plantillas"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recarga"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferencia"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retiro"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de la plantilla"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operador"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campos"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operaciones"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay plantillas"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destinatario"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Realmente quieres eliminar la plantilla nº"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renombrar plantilla "])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renombrar"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usar"])}
}