export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conta a recarregar"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Método de pagamento"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome do modelo:"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvar"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvar modelo"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modelos"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recarregar conta"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalhes da transferência"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificar transferência"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informações sobre transferências"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instrução"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recarregar endereço"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereço a recarregar"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportar"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para a lista de tarefas"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereço de transferência"])}
}