export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un chèque électronique"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte de débit"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeur nominale de chaque chèque"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de chèques"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Série:"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro:"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code d'activation:"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chèques créés"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger le fichier avec les chèques"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrez ces informations - les codes d'activation seront masqués après la fermeture de cette page"])}
}