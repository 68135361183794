export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de recepción"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de procesamiento"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad de pagos"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de los cuales exitosos"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de los cuales fallidos"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagos masivos"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transacción"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultado"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proveedor de servicios"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comisión"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentario"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1er parámetro"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontraron pagos"])}
}