export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Externe Vermögenswerte"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An Asset gebunden"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maßeinheiten"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppe"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellungsdatum"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autor"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktionen"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeitraum von"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeitraum bis"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurücksetzen"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualisieren"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Vermögenswerte"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heute"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestern"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Woche"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monat"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jahr"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An Asset gebunden"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppe"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autor"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivität"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Länder"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inaktiv"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Externes Asset bearbeiten"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Externes Asset löschen"])}
}