export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dati sugli asset crittografici"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo del problema:"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome:"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticker:"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorizzazioni"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scopo/Missione/Scopo"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caratteristiche finanziarie"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precisione:"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contatti"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emissione:"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persona responsabile:"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emittente:"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costo unitario:"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sicurezza:"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acquisti bancomat:"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendite agli ATM:"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transazioni con beni in borsa:"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transazioni di asset tramite P2P:"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo di hosting"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo del Libro Bianco"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offerente"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altri contatti"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andare"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emittente"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emissione massima:"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altro"])}
}