export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea assegno elettronico"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conto di addebito"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valore nominale di ogni assegno"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commento"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di assegni"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indietro"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serie:"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero:"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice di attivazione:"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assegni creati"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scarica file con assegni"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva queste informazioni - i codici di attivazione verranno nascosti dopo la chiusura di questa pagina"])}
}