export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвердить платеж"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рассчитать комиссию за возврат"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вернуть платеж"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакция"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хеш в блокчейне"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AML-риск"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комиссия"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["К зачислению"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если вы согласны с измененными условиями, подтвердите зачисление:"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если эти условия вам не подходят, вы можете вернуть средства на счет отправителя за вычетом расходов на возврат:"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакция"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["на сумму"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["имеет AML-риск"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["поэтому за ее зачисление пересчитана комиссия"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["таким образом, сумма к зачислению составляет"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новых поступлений не обнаружено"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата платежа"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Возврат"])}
}