export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Období od"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Období do"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtry"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualizovat"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Částka"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transakce"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametr"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transakce"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operace"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korespondent"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Částka"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stav"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametry/Komentář"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádné operace nenalezeny"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Součet operací"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Příjmy:"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" ks na částku"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výdaje:"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typy operací"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platby, platba za služby"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vnitřní převody"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platba účtu"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Převody mezi vlastními účty"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvoření šeku v kryptoměně"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivujte šek v kryptoměně"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ostatní"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Příjmy/výdaje"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Příjmy"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výdaje"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["= rovná se"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≠ nerovná se"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["> větší"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≥ větší nebo rovný"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["< menší"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≤ menší nebo rovný"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stav"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úspěšně dokončeno"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zpracovává se"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chyba"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dnes"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Včera"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Týden"])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Měsíc"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rok"])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historie operací"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doplnění účtu"])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Účet není vybrán"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Směna na burze"])},
  "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doplnění adresy"])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Převody uvnitř vlastního peněženky"])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korespondent"])},
  "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operace"])}
}