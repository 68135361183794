export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус "])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тема"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Последнее сообщение"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категория"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Действия"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет запросов"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категория"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приоритет"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тема"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текст сообщения"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Файлы"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы можете загрузить один или несколько файлов. Максимальный размер файла - 10Мб."])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Низкий"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нормальный"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Высокий"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Техническая поддержка"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активные запросы"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Архив"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новый запрос"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обрабатывается оператором"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запрос создан"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ведется работа, ожидайте ответа от оператора"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запрос закрыт"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запрос закрыт"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новых сообщений нет"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новое сообщение от оператора"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новых сообщений нет"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить в архив"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все запросы"])}
}