export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Templates"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In group"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment groups"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payments"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount of payment"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer account"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pattern"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Template name:"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save template"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer"])}
}