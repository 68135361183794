export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["外部资产"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["代码"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名称"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["绑定到资产"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["计量单位"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["组"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建日期"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["作者"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["期间从"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["期间到"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["过滤器"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重置"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有资产"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["今天"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["昨天"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["周"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["月"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["年"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["绑定到资产"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["组"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["作者"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活动"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国家"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活跃"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不活跃"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编辑外部资产"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除外部资产"])}
}