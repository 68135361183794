export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Старий пароль:"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новий пароль:"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Підтвердження паролю:"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новий пароль:"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Підтвердження паролю:"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберегти"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберегти"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"Новий пароль\" і \"Підтвердження паролю\" не збігаються."])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль не повинен містити кирилицю"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створення паролю підтвердження"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зміна паролю підтвердження"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На головну"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рядок від 4 до 65 символів. Допустимі символи латинського алфавіту, цифри та символи"])}
}