export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodatkowe informacje"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Działania"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktywny"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wpłata"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wypłata"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz nowy adres"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz nowe konto"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń wybrane"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moje aktywa"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista kont"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszystko"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa konta"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czy na pewno chcesz usunąć konta?"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czy na pewno chcesz usunąć konto"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie znaleziono aktywów"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie znaleziono kont"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmiana nazwy adresu"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czy na pewno chcesz usunąć adresy?"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czy na pewno chcesz usunąć adres"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dekret"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operacje"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Działania"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uzupełniać"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wycofać"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giełda"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukrywać"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmiana"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuwać"])}
}