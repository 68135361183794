export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["期间从"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["期间到"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["导出"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["过滤器"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重置"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刷新"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金额"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["参数"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["类型"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日期"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对应方"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金额"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["状态"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["参数/评论"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未找到操作"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作金额"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收入："])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" 笔，总金额"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支出："])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作类型"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付、服务付款"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["内部转账"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["账单支付"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自己的账户转账"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建加密货币支票"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["激活加密货币支票"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["其他"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收入/支出"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收入"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支出"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["= 等于"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≠ 不等于"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["> 大于"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≥ 大于或等于"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["< 小于"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≤ 小于或等于"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["状态"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成功完成"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["处理中"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["错误"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["今天"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["昨天"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["周"])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["月"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["年"])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作历史"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["账户充值"])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未选择账户"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在交易所交换"])},
  "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["充值地址"])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自己的钱包内转账"])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通讯员"])},
  "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手术"])}
}