export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цифровой паспорт"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подробнее"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User ID:"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата регистрации:"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Последняя авторизация:"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мои активы"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Счета"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Смотреть все"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пополнить"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вывести"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Счетов нет"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль подтверждения"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для подтверждения операций вам необходимо создать пароль подтверждения."])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаблоны"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Популярные платежи"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новости"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Свернуть"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Далее..."])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активов нет"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Баланс"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вывести"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пополнить"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обменять"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователю"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общий баланс"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показывать в"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нефиатный баланс"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фиатный баланс"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новая информация"])}
}