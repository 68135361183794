export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quema de activos"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad a quemar"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta para devolución"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quemar"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad de quema"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La cantidad a quemar no puede ser mayor que la cantidad de emisión"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devolución de cobertura"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A devolver"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad que permanecerá en la reserva después de la quema de activos"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad que se devolverá a su cuenta después de la quema de activos"])}
}