export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["транзакция "])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общие параметры"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменение остатков на счетах"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На конец операции:"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На начало операции:"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дополнительные параметры"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операции в блокчейне"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Callback"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Следующая попытка:"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Время"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ответ"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Протекция сделки"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Срок протекции:"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["До"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код протекции:"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвердить"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Параметры шаблона"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название шаблона :"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменить операцию"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повторить операцию"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить шаблон"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрыть"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер транзакции"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операция"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Корреспондент"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата платежа"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комиссия"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комментарий"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Результат"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер платежной формы"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приход/Расход"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменение остатков адресов"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвердить зачисление"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вернуть деньги отправителю"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подозрительная операция"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Варианты действий:"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комиссия получателя"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комиссия плательщика"])}
}