export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қауіпсіздік"])},
  "Antiphishing": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Антифишингті баптау кабинетпен жұмыс істеу кезінде қосымша қорғаныс механизмі болып табылады."])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сіз енгізген сәлемдесу фразасы кабинетінің кез келген бетінің жоғарғы жағында көрсетіледі."])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дұрыс фраза сізге дұрыс сайтта екеніңізді көрсетеді, қате фраза - бұл сигнал: ешқандай операция жасамай, жүйенің қауіпсіздік қызметімен жедел байланысу керек."])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Антифишингті қосу:"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Антифишинг жолы:"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сақтау"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Антидингке қарсы"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Өзгерістер келесі кіруде күшіне енеді"])}
  },
  "IP": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP-адрес бойынша қолжетімділікті баптау сізге (немесе зиянкестің) рұқсат етілген тізімде көрсетілмеген адреспен жүйемен жұмыс істеуге мүмкіндік бермейді."])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кабинетке IP-адрес бойынша қолжетімділікті шектеу"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес қосу"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес: "])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қосу"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес блогы: "])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["дейін:"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Өз IP: "])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес тізімі"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрестен"])},
    "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адреске"])},
    "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қолжетімділік"])},
    "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Әрекеттер"])},
    "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жою"])},
    "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес жоқ"])},
    "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сақтау"])},
    "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тыйым салу"])},
    "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рұқсат беру"])},
    "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP-адрес енгізіңіз"])},
    "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP-адрес қате енгізілген"])},
    "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ешбір IP-адрес немесе адрес блогы көрсетілмеген."])},
    "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Авторизация үшін ешбір IP-адрес рұқсат етілмеген. Сіз өз шотыңызға кіре алмайсыз."])},
    "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP арқылы қолжетімділік"])},
    "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["бастап:"])}
  },
  "Magic": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сиқырлы сөз - бұл қосымша қауіпсіздік баптауы."])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Егер сиқырлы сөз қосылған болса, әрбір авторизация кезінде жүйе сізден сиқырлы сөздің қандай да бір символдарын (олардың реттік нөмірі бойынша) енгізуді сұрайды."])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сиқырлы сөз:"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сиқырлы сөзді сұрау:"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кабинетке кіру кезінде:"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мерчант арқылы төлем жасау кезінде:"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Картаны қолдану кезінде:"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сақтау"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сиқырлы сөз"])}
  },
  "Onekey": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бір реттік кілттер жүйенің қауіпсіздігін айтарлықтай арттырады."])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бағдарламаға кіру кезінде сізден тек логин мен құпиясөзді ғана емес, сонымен қатар кезекті бір реттік кілтті де енгізу сұралады. Активизация кезекпен жүзеге асырылады. Соңғы кілт жаңа кілттер сериясы жасалғанға дейін әрекет етеді."])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бір реттік кілттерді қолдану"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кілттер тізімі"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Генерациялау"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Экспорт"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кілт"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бір реттік кілттер"])}
  },
  "Password": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ескі құпиясөз:"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жаңа құпиясөз:"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Құпиясөзді растау:"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сақтау"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"Жаңа құпиясөз\" және \"Құпиясөзді растау\" сәйкес емес."])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Құпиясөзді өзгерту"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4-тен 63 символға дейінгі жол. Латын алфавитінің әріптері, цифрлар және таңбалар рұқсат етілген"])}
  },
  "PayPassword": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ескі құпиясөз:"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жаңа құпиясөз:"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Құпиясөзді растау:"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жаңа құпиясөз:"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Құпиясөзді растау:"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сақтау"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сақтау"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"Жаңа құпиясөз\" және \"Құпиясөзді растау\" сәйкес емес."])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Құпиясөзде кириллица болмауы керек"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Екінші құпия сөзді жасаңыз"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Екінші құпия сөзді өзгертіңіз"])},
    "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Басты бетке"])},
    "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4-тен 65 символға дейінгі жол. Латын алфавитінің әріптері, цифрлар және таңбалар рұқсат етілген"])}
  },
  "SecretQuestion": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Егер сіз кабинетке кіру құпиясөзін ұмытсаңыз, оны құпия сұраққа жауап беру арқылы қалпына келтіре аласыз."])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Осы бөлімде сіз құпия сұрақ/жауапты өзгерте аласыз."])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Құпия сұрақ"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сіздің сұрағыңыз:"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жауап:"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сақтау"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Құпия сұрақ"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Өз сұрағы"])}
  }
}