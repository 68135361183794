export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doplněný účet"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Způsob platby"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název šablony:"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložit"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložit šablonu"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokračovat"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Šablony"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doplnění účtu"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Údaje o převodu"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zkontrolovat převod"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informace o převodech"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instrukce"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doplnění adresy"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doplněná adresa"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na seznam úkolů"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresa převodu"])}
}