export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["给"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金额"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收到"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["过滤器"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刷新"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["代码"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["卖方"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您给"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您收到"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["限制"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有交易"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["报价"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["好处"])}
}