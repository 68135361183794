export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzeigeninformationen"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundlegende Informationen"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundlegend"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zusätzlich"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellungsdatum"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akzeptieren"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startdatum des Geschäfts"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enddatum des Geschäfts"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rate"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ersteller"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gibt"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["erhält"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur über Link verfügbar"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur für verifizierte Benutzer verfügbar"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limits"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzeigenbesitzer"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeitüberschreitung"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tage"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stunden"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minuten"])}
}