export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Менің дүкендерім"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дүкендер табылмады"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виджет кодын жасау"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Төлем сілтемесін жасау"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Өңдеу"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дүкенді жою"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дүкенді шынымен жойғыңыз келе ме"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дүкенді тіркеу"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Атауы"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Төлем режимі"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Белсенділік"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қабылдау"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Әрекеттер"])}
}