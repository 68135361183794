export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Účet převodu:"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Částka:"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentář:"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ochrana transakce"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kód:"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vygenerovat"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doba ochrany:"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dnů"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název šablony:"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložit"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložit jako šablonu"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Převést"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Šablony"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Převod jinému uživateli"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ochranný kód - kód, který musí příjemce zadat pro dokončení transakce. Ochráněný převod příjemce uvidí ihned v sekci \"Historie operací\", ale prostředky bude moci použít až po zadání ochranného kódu v kartě transakce. Pokud platnost platby vyprší, prostředky se vrátí odesílateli."])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresa převodu"])}
}