export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дані крипто-активу"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адреса емісії:"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва:"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тікер:"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дозволи"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мета/Місія/Призначення"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фінансові характеристики"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категорія"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Точність:"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контакти"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Емісія:"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Особа, яка несе зобов'язання:"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Емітент:"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вартість одиниці:"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Забезпеченість:"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Купівля у банкоматах:"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продаж у банкоматах:"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Угоди з активом на біржі:"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Угоди з активом через P2P:"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адреса хостингу"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адреса білої книги"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Електронна пошта"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оферент"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Інші контакти"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перейти"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Емітент"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Максимальне викиди:"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Інший"])}
}