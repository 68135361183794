export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borsa"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mercato. Riepilogo"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proposte simili"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proposte corrispondenti"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasso"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Danno"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricevono"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I miei ordini"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultime transazioni"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasso"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dato"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data e ora"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricevo"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasso"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operazioni"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["commissione"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ripeti"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna offerta"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna transazione"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun ordine"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completato al 100%"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elaborazione non iniziata"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elaborazione iniziata"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parzialmente completato"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annullato completamente"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parzialmente completato e annullato"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricevuto"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti gli ordini"])}
}