export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner un fichier"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce format de fichier n'est pas pris en charge. \nFormats acceptés :"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format inconnu"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fichier trop volumineux, taille maximale :"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez ou faites glisser les fichiers ici pour les télécharger"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fichiers téléchargés"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fichiers"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])}
}