export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["总体参数"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["账户余额变化"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作结束时："])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作开始时："])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["附加参数"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["区块链操作"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["回调"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下一次尝试："])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["时间"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["响应"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易保护"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保护期限："])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["到"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保护代码："])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["模板参数"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["模板名称："])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消操作"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重复操作"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存模板"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关闭"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易编号"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对应方"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付日期"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金额"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手续费"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评论"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["结果"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付表单编号"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收入/支出"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地址余额变化"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认收入"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["退还资金给发送人"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可疑操作"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作选项："])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收件人的委员会"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["付款人委员会"])}
}