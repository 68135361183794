export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sécurité"])},
  "Antiphishing": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le paramétrage antiphishing est un mécanisme de protection supplémentaire lors de l'utilisation du compte."])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La phrase de bienvenue que vous avez définie sera affichée en haut de chaque page du compte."])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La phrase correcte vous indique que vous êtes sur le bon site, une phrase incorrecte signalera : n'effectuez aucune opération et contactez immédiatement le service de sécurité de notre système."])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activer antiphishing :"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phrase antiphishing :"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anti-pêche"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les modifications prendront effet après la prochaine connexion"])}
  },
  "IP": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le paramétrage d'accès par adresse IP ne vous permettra pas (ou à un intrus) de travailler avec le système à partir d'une adresse qui n'est pas dans la liste des adresses autorisées."])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restriction d'accès au compte par adresse IP"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter des adresses"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse : "])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bloc d'adresses : "])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["à :"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre IP : "])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des adresses"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De l'adresse"])},
    "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À l'adresse"])},
    "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accès"])},
    "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
    "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])},
    "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune adresse"])},
    "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
    "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refuser"])},
    "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoriser"])},
    "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez l'adresse IP"])},
    "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse IP incorrecte"])},
    "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune adresse IP ou bloc d'adresses spécifié."])},
    "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune adresse IP autorisée pour l'autorisation. Vous ne pourrez pas accéder à votre compte."])},
    "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accès par IP"])},
    "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de :"])}
  },
  "Magic": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mot magique est un paramètre de sécurité supplémentaire."])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si le mot magique est activé, le système vous demandera, à chaque connexion, d'entrer certains caractères (selon leur ordre) du mot magique."])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot magique :"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demander le mot magique :"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lors de l'accès au compte :"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lors du paiement par le marchand :"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lors de l'utilisation de la carte :"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot magique"])}
  },
  "Onekey": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les clés à usage unique augmentent considérablement la sécurité du système."])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lors de la connexion au programme, on vous demandera non seulement de saisir l'identifiant et le mot de passe, mais aussi la clé à usage unique suivante. L'activation est effectuée successivement. La dernière clé est valide jusqu'à ce que vous créiez une nouvelle série de clés."])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utiliser des clés à usage unique"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des clés"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Générer"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporter"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clé"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clés à usage unique"])}
  },
  "Password": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ancien mot de passe :"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau mot de passe :"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation du mot de passe :"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"Nouveau mot de passe\" et \"Confirmation du mot de passe\" ne correspondent pas."])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer le mot de passe"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chaîne de 4 à 63 caractères. Les caractères autorisés sont les lettres de l'alphabet latin, les chiffres et les symboles"])}
  },
  "PayPassword": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ancien mot de passe :"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau mot de passe :"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation du mot de passe :"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau mot de passe :"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation du mot de passe :"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"Nouveau mot de passe\" et \"Confirmation du mot de passe\" ne correspondent pas."])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mot de passe ne doit pas contenir de caractères cyrilliques"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un mot de passe de confirmation"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier le mot de passe de confirmation"])},
    "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À la page principale"])},
    "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chaîne de 4 à 65 caractères. Les caractères autorisés sont les lettres de l'alphabet latin, les chiffres et les symboles"])}
  },
  "SecretQuestion": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous oubliez le mot de passe pour accéder au compte, vous pourrez le récupérer en répondant à la question secrète."])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dans cette section, vous pouvez changer la question/réponse secrète."])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question secrète"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre question :"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réponse :"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question secrète"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question personnelle"])}
  }
}