export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отдаю"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["остаток "])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получаю"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Торговая пара"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Счета"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип обмена"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перевод по рынку - будет использован обмен по самому выгодному курсу на момент исполнения заявки. Внимание, сумма к получению может измениться и отличаться от рассчитанной в зависимости от заявок на момент обмена."])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перевод по фиксированной цене происходит в соотвествии с указанной вами суммой и курсом. Будет выставлена заявка. Если указанный курс сильно отличается от рыночного, то закрытие заявки может занять много времени."])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курс (прогноз):"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курс"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курс не определен, "])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["рекомендуется задать курс самостоятельно"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Использовать все"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комиссия"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комиссия"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Итого:"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["По рынку"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(быстрый обмен по лучшему встречному предложению)"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фиксированный курс "])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(выставить заявку с заданным курсом)"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет подходящих счетов"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курс"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменение за 24 часа"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Колебания за 24 часа"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Объем сделок за 24 часа"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма не может быть равна нулю!"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обменять"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чтобы увидеть данные сводки, выберите обменную пару"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нельзя обменивать одинаковые активы"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дополнительно"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заявка активна с"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["до"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ограничения"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для полей \"Курс\" требуется указать значение, отличное от 0"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Недостаточно средств на счете"])}
}