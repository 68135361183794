export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte adresu"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osada"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresa"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušení"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložit"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Země"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Region"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oblast"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okres"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Město"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Městská část"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osada"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ulice"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Další adresa"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upřesnění"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dům"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PSČ"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresa"])}
}