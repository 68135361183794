export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एटीएम"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पूरी स्क्रीन पर तालिका को विस्तृत करने के लिए क्लिक करें"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रीसेट"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपडेट"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सक्रियता"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपडीलर"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सक्रिय"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["निष्क्रिय"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एक एटीएम बनाएं"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्रिप्टोमाट संपादित करें"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रद्द करें"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सहेजें"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मुख्य जानकारी"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मुख्य"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खुलने का समय"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नाम"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लॉगिन"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पासवर्ड"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पता"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्थापना स्थान का विवरण"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मानक"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अमानक"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कार्य का मोड"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सोमवार"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मंगलवार"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बुधवार"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["गुरुवार"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शुक्रवार"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शनिवार"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रविवार"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दोपहर का भोजन"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पासवर्ड पुनः दर्ज करें"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मौजूदा खातों का उपयोग करें"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नए खाते बनाएं"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मुख्य जानकारी"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खाता"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कार्य समय (संदर्भ जानकारी)"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सक्रियता"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्रियाएँ"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई क्रिप्टो मशीन नहीं मिली"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एटीएम नंबर"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अक्षांश"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["देशान्तर"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हेल्पलाइन"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उप-डीलर आईडी"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हिसाब किताब"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जाँच करना"])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मुद्रा"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पारगमन खाता"])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्रिप्टोमैट का निर्माण"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्रिप्टोमैट का संपादन"])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पासवर्ड"])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हाँ"])},
  "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["निषेध"])},
  "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अनुमति दें"])},
  "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संग्राहकों की सूची"])},
  "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एक संग्राहक जोड़ें"])},
  "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संग्रह शेष पर जाने में असमर्थ - कोई डेटा नहीं"])},
  "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संग्राहकों की सूची"])},
  "62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एक संग्राहक जोड़ें"])},
  "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अनुमत"])},
  "65": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्वीकार"])},
  "67": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पता"])},
  "68": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एटीएम संपादित करें"])},
  "69": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वर्तमान संग्रह शेष देखें"])},
  "70": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अनलोड किए गए संग्रह शेष देखें"])},
  "71": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पिन कोड"])},
  "73": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यदि आप कोई सबडीलर निर्दिष्ट नहीं करते हैं, तो एटीएम आपसे लिंक कर दिया जाएगा"])},
  "74": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अक्षांश और देशांतर भौगोलिक निर्देशांक हैं जिन्हें कॉपी किया जा सकता है, उदाहरण के लिए, एटीएम पता दर्ज करके Google मानचित्र से"])},
  "75": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्थापना स्थान"])},
  "76": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["तालिका प्रदर्शित करने के लिए कम से कम एक कॉलम का चयन किया जाना चाहिए"])},
  "77": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बचाना"])},
  "78": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बनाएं"])},
  "79": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एटीएम डिवाइस पर जाएं"])}
}