export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar pago"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calcular comisión de reembolso"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devolver pago"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transacción"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hash en blockchain"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riesgo AML"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comisión"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A acreditar"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si aceptas los términos modificados, confirma el crédito:"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si estos términos no te convienen, puedes devolver los fondos a la cuenta del remitente, menos los costos de devolución:"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transacción"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["por la cantidad de"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tiene riesgo AML"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["por lo tanto, la comisión ha sido recalculada"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de esta manera, la cantidad a acreditar es"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontraron nuevos ingresos"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha del pago"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reembolso"])}
}