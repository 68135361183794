export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modelli"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trova"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nel gruppo"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppi di pagamento"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagamenti"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo del pagamento"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conto di trasferimento"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commento"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modello"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome del modello:"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva modello"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trasferisci"])}
}