export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ritiro bancomat"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi raccolta"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azioni"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtri"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiornamento"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non mostrare le raccolte d'archivio"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non mostrare le raccolte annullate"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero del punto"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna raccolta"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domanda di riscossione presentata"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica raccolta"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica lo stato della raccolta"])}
}