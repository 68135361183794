export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de casete"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID del casete"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de recogida del casete"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clasificación del casete"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["subido"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suma"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servidor"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suma"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cajero automático"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suma"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De hecho"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suma"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentario sobre el casete."])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay datos para mostrar"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colección de dispositivos"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["válido"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["do"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["los restos son llevados"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servidor"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de billetes"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suma"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cajero automático"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de billetes"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suma"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentario"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dispensador"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechazar/Retraer"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aceptador de billetes"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechazar tarjetas"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarjetas"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["actual"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["descargado"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldos de cobranza en cajeros automáticos"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colección de dispositivos"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["válido"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["casete instalado en cajero automático"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["incautado"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["los restos son llevados"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["los restos son llevados"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["casete vacío:"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["adhesión:"])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información adicional:"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hasta la fecha"])}
}