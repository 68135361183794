export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A palavra mágica é uma configuração de segurança adicional."])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se a palavra mágica estiver ativada, toda vez que você fizer login, o sistema pedirá que você insira alguns caracteres (em sua ordem específica) da palavra mágica."])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palavra mágica:"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicitar a palavra mágica:"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ao entrar na conta:"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ao pagar via comerciante:"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ao usar o cartão:"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvar"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palavra mágica"])}
}