export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Widget Intégré"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installation"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connectez le script à la page dans la section head via CDN ou en le téléchargeant comme fichier"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appel"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appelez la fonction"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en passant un objet JS comme argument"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de données"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exemple"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["id pour iframe"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["name pour iframe"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["objet avec paramètres de paiement"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fonction gestionnaire de succès d'opération"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fonction gestionnaire d'échec d'opération"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse du service de paiement"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ci-dessous"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encore plus bas"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour au site de la boutique après la fin de l'opération"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Après la fin de l'opération ou le refus, le widget sera fermé. L'objet avec les paramètres ci-dessous sera transmis au callback onFail ou onSuccess:"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payer"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions en cas de succès"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions en cas d'échec"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse du service de paiement"])}
}