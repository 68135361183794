export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Widget Osadzony"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instalacja"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podłącz skrypt do strony w sekcji head przez CDN lub pobierając jako plik"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wywołanie"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wywołaj funkcję"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["przekazując obiekt JS jako argument"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opis"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ danych"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przykład"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["id dla iframe"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["name dla iframe"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["obiekt z parametrami płatności"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["funkcja obsługi sukcesu operacji"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["funkcja obsługi niepowodzenia operacji"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres usługi płatności"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poniżej"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeszcze niżej"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powrót do strony sklepu po zakończeniu operacji"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Po zakończeniu operacji lub odmowie, widget zostanie zamknięty. Obiekt z poniższymi parametrami zostanie przekazany do callback onFail lub onSuccess:"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapłać"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Działania w przypadku sukcesu"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Działania w przypadku niepowodzenia"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres usługi płatności"])}
}