export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szablony"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doładowanie"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przelew"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wypłata"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa szablonu"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operator"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pola"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operacje"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak szablonów"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odbiorca"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czy na pewno chcesz usunąć szablon nr"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmień nazwę szablonu "])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmień nazwę"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Użyj"])}
}