export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuncio"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrizione"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettagli"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimo"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Massimo"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limite importo"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tempo di attesa (min)"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuncio attivo"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponibile solo tramite link"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponibile solo per utenti verificati"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva"])}
}