export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edytuj zewnętrzny aktyw"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anuluj"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupa"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synonimy"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kraje"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powiąż z aktywem"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jednostki miary"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skrót"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precyzja"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktywny"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaakceptowany do obrotu"])}
}