export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цифровий паспорт"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Докладніше"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User ID:"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата реєстрації:"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Остання авторизація:"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мої активи"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рахунки"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дивитися всі"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поповнити"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вивести"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рахунків немає"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль підтвердження"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для підтвердження операцій необхідно створити пароль підтвердження."])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаблони"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всі"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Популярні платежі"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новини"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Згорнути"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Далі..."])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активів немає"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створити"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створити"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Баланс"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вивести"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поповнити"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обміняти"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Користувачеві"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загальний баланс"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показувати в"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нефіатний баланс"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фіатний баланс"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нова інформація"])}
}