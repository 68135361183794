export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додавання інкасації"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Основна інформація"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Основне"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Диспенсер"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер точки"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кількість карток по заявці"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Купюроприймач"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карти"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Інкасатори"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Примітка для інкасаторів"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коментар до інкасації"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завантаження позачергово"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Замінити касету купюроприймача"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коментар до касети"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коментар до карт"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вилучити всі картки з касети Reject диспенсера карт"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вилучити всі карти з туби"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер точки – обов'язкове поле, яке має бути заповнене"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID касети 1"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID касети 2"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID касети 3"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID касети 4"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номінал касети 1"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кількість купюр у касеті 1"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума у ​​касеті 1"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номінал касети 2"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кількість купюр у касеті 2"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коментар до касети 1"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID касети"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номінал касети"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кількість купюр"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коментар до касети"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберегти"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редагування інкасації"])}
}