export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एटीएम उपकरण"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["डिवाइस जोडे"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["डिवाइस का प्रकार"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नाम"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टिप्पणी"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विन्यास"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कैसेट जोड़ें"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कैसेट प्रकार"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नाम"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टिप्पणी"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विन्यास"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["डिवाइस निकालें"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कैसेट निकालें"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["डिवाइस हटाएं?"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मिटाना"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कैसेट हटाएँ?"])}
}