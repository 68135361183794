export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लोगो"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नाम"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टिकर"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["श्रेणी"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मिशन"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कार्रवाई"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्रिप्टो एसेट्स की सूची"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई क्रिप्टो एसेट नहीं"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टोकन जारी करना"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्वीकार करें"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बाजार"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सटीकता"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्राथमिक"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["द्वितीयक"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अन्य एसेट के साथ जोड़ा गया"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्रिप्टो एसेट की विस्तृत जानकारी"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्रिप्टो एसेट डेटा संपादित करें"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अतिरिक्त निर्गमन"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्रिप्टो एसेट हटाएं"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्रिप्टो एसेट्स की सूची"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्वीकार करें"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["गतिविधि"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हां"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नहीं"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टोकन एटीएम में खरीदे जा सकते हैं"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टोकन एटीएम में बेचे जा सकते हैं"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["श्रेणी"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["द्वितीयक बाजार टोकन"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टोकन प्रदर्शन"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी स्वीकृत"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी मेरे"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपयोगकर्ता UID"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एसेट बर्निंग"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विशिष्ट उपयोगकर्ता के लिए"])}
}