export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一次性密钥大大提高了系统的安全性。"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录时，程序不仅要求您输入登录名和密码，还要求输入下一个一次性密钥。激活依次进行。最后一个密钥有效，直到您创建新的一组密钥。"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用一次性密钥"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密钥列表"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["生成"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["导出"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编号"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密钥"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一次性密钥"])}
}