export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बाहरी संपत्ति संपादित करें"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नाम"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रद्द करें"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सहेजें"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समूह"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पर्यायवाची"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["देश"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संपत्ति से जोड़ें"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इकाइयाँ"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संक्षिप्त"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सटीकता"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सक्रिय"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["व्यापार के लिए स्वीकृत"])}
}