export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फाइल चुनें"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यह फ़ाइल स्वरूप समर्थित नहीं है. \nस्वीकृत प्रारूप:"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अज्ञात प्रारूप"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फाइल का आकार बहुत बड़ा है, अधिकतम आकार:"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फाइल अपलोड करने के लिए चुनें या खींचें"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["चुनें"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपलोड करें"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रद्द करें"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फाइलें अपलोड की गईं"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फाइलें"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मिटाना"])}
}