export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建货币对"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["货币对"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["设置更新"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["给定的货币"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收到的货币"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["为课程增加利润，%"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["课程更新类型"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["课程生命周期，秒"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["课程更新间隔，秒"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出色地"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["课程的有效期是哪一天？"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["给定货币的别名"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收到币种的别名"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["网关"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有必要选择一种货币，而且只能选择一种：给予或接收"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发送金额*费率=接收金额"])}
}