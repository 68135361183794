export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vložený Widget"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instalace"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Připojte skript na stránku v sekci head přes CDN nebo stažením jako soubor"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volání"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zavolejte funkci"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["předáním JS objektu jako argument"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popis"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datový typ"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Příklad"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["id pro iframe"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["name pro iframe"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["objekt s parametry platby"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["funkce pro zpracování úspěchu operace"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["funkce pro zpracování neúspěchu operace"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresa platební služby"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Níže"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ještě níže"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Návrat na stránku obchodu po dokončení operace"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Po dokončení operace nebo odmítnutí bude widget uzavřen. Objekt s parametry níže bude předán do callback onFail nebo onSuccess:"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaplatit"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akce při úspěchu"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akce při neúspěchu"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresa platební služby"])}
}