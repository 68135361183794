export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recogida en cajero automático"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar colección"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comportamiento"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtros"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reiniciar"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizar"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No mostrar colecciones de archivos"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No mostrar colecciones canceladas"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de punto"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sin colecciones"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicitud de cobro enviada"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar colección"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambiar estado de colección"])}
}