export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Šablony"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Najít"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ve skupině"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skupiny plateb"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platby"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Částka platby"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Účet převodu"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Částka"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentář"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Šablona"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název šablony:"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložit"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložit šablonu"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Převod"])}
}