export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعداد الوصول عبر عنوان IP لن يسمح لك (أو للمخترق) بالعمل مع النظام من عنوان غير مدرج في قائمة العناوين المسموح بها."])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقييد الوصول إلى الحساب عبر عناوين IP"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة عناوين"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العنوان: "])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كتلة العناوين: "])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلى:"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان IP الخاص بك: "])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قائمة العناوين"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من العنوان"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلى العنوان"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوصول"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإجراءات"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حذف"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد عناوين"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حظر"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السماح"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل عنوان IP"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان IP غير صحيح"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم يتم تحديد أي عنوان IP أو كتلة عناوين."])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم يتم السماح بأي عنوان IP للتسجيل. لن تتمكن من الوصول إلى حسابك."])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوصول عبر IP"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من:"])}
}