export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأداة المضمنة"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التثبيت"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قم بتوصيل البرنامج النصي بالصفحة في قسم head عبر CDN أو عن طريق التنزيل كملف"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاستدعاء"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استدعاء الدالة"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عن طريق تمرير كائن JS كوسيط"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوصف"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع البيانات"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مثال"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معرف للـ iframe"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم للـ iframe"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كائن مع معلمات الدفع"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دالة معالجة نجاح العملية"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دالة معالجة فشل العملية"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان خدمة الدفع"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدناه"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدناه مرة أخرى"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العودة إلى موقع المتجر بعد اكتمال العملية"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بعد اكتمال العملية أو رفضها، سيتم إغلاق الأداة. سيتم تمرير الكائن مع المعلمات أدناه إلى رد الاتصال onFail أو onSuccess:"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دفع"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإجراءات عند النجاح"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإجراءات عند الفشل"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان خدمة الدفع"])}
}