export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer Account:"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount:"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment:"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protection of the deal"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code:"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protection period:"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["days"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Template name:"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save as template"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Templates"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer to another user"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The protection code is the code that the recipient must enter to complete the transaction. \nThe recipient will see the protected transfer immediately in the “Transaction History” section, but will be able to use the money only after entering the protection code in the transaction card. \nIf the payment has expired, the funds are returned to the sender."])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer address"])}
}