export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zewnętrzne aktywa"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kod"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powiązany z aktywem"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jednostki miary"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupa"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data utworzenia"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autor"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Działania"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okres od"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okres do"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtry"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resetuj"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualizuj"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak aktywów"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dzisiaj"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wczoraj"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tydzień"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miesiąc"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rok"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powiązany z aktywem"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupa"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autor"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktywność"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kraje"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktywny"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieaktywny"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edytuj zewnętrzny aktyw"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń zewnętrzny aktyw"])}
}