export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Můžete provést několik plateb současně. \nK tomu je potřeba nejprve vytvořit soubor s popisem plateb ve formátu „XLS“, „XLSX“, „CSV“."])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analýza souboru"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korespondent"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Částka"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentář"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametr"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informace o souboru"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Celkem plateb:"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["v celkové částce:"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soubor plateb"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odepsaný účet"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provést platby"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokračovat"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nahrání souboru plateb"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zkontrolujte obsah souboru"])},
  "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hromadné platby a převody"])}
}