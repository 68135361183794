export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इलेक्ट्रॉनिक चेक बनाएं"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["डेबिट खाता"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रत्येक चेक का नाममात्र मूल्य"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टिप्पणी"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["चेक की संख्या"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वापस"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बनाएं"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["श्रृंखला:"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संख्या:"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सक्रियण कोड:"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["चेक बनाए गए"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["चेक वाली फ़ाइल डाउनलोड करें"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यह जानकारी सहेजें - इस पृष्ठ को बंद करने के बाद सक्रियण कोड छिपा दिए जाएंगे"])}
}