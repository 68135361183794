export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte à recharger"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Méthode de paiement"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du modèle :"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer le modèle"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modèles"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechargement de compte"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails du transfert"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifier le transfert"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations sur les transferts"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instructions"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechargement d'adresse"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse à recharger"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporter"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À la liste des tâches"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse de transfert"])}
}