export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kod transakcji"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daję"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otrzymuję"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kierunek"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurs"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timeout"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Działania"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak aktywnych transakcji"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moje transakcje"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktywne"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktywa wewnętrzne"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieaktywne"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korespondent"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kod"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okres str"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okres przez"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtry"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualizacja"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daję"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["= równe"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≠ nie jest równe"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["> Więcej"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≥ więcej lub równych"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["< Mniej"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≤ mniej lub równe"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rozumiem"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suma"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz zasób"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moja rola"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszystko"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Producent"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cheak"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktywa wewnętrzne"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszystkie opcje"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rozumiem"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daję"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identyfikator producenta"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszystko"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numer ogłoszenia"])}
}