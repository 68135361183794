export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okres od"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okres do"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eksport"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtry"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resetuj"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odśwież"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kwota"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transakcja"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametr"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transakcja"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operacja"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korespondent"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kwota"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametry/Komentarz"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak operacji"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suma operacji"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przychody:"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" szt. na kwotę"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wydatki:"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typy operacji"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wypłaty, opłaty za usługi"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przelewy wewnętrzne"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opłata rachunku"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przelewy na własne rachunki"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Création d'un bon de crypto-monnaie"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activation des bons de crypto-monnaie"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inne"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przychód/Wydatek"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przychód"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wydatek"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["= równe"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≠ nie równe"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["> większe"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≥ większe lub równe"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["< mniejsze"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≤ mniejsze lub równe"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zakończone sukcesem"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["W trakcie"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Błąd"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dziś"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wczoraj"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tydzień"])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miesiąc"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rok"])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historia operacji"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doładowanie konta"])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto nie wybrane"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wymiana na giełdzie"])},
  "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doładowanie adresu"])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przelewy wewnątrz portfela"])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korespondent"])},
  "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Działanie"])}
}