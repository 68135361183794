export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加收藏"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["基本信息"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["基础知识"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自动售货机"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["点数"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每个申请的卡数"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["票据承兑人"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["牌"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["现金收集者"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收藏家须知"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收藏评论"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加载无序"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更换纸币接收盒"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["卡带评论"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对卡片的评论"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["从拒绝发卡器盒中取出所有卡片"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["从管中取出所有卡片"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["点号为必填字段，必须填写"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["包埋盒 ID 1"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["包埋盒 ID 2"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["包埋盒 ID 3"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["包埋盒 ID 4"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["磁带评级 1"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["钞箱 1 中的钞票数量"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["试剂盒 1 中的数量"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["磁带等级 2"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["纸盒 2 中的钞票数量"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["磁带评论1"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["包埋盒ID"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["磁带评级"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["账单张数"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["和"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["卡带评论"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["节省"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编辑收藏"])}
}