export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أعطي"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرصيد"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أستلم"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["زوجين رأس المال"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حسابات"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع التبادل"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التبادل حسب السوق - سيتم استخدام التبادل بأفضل سعر في وقت تنفيذ الطلب. انتباه، يمكن أن يتغير المبلغ المستلم ويختلف عن المبلغ المحسوب حسب الطلبات في وقت التبادل."])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التبادل بالسعر الثابت يحدث وفقًا للمبلغ والسعر الذي تحدده. سيتم تقديم الطلب. إذا كان السعر المحدد يختلف بشكل كبير عن سعر السوق، فقد يستغرق إكمال الطلب وقتًا طويلاً."])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السعر (التوقع):"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السعر"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم يتم تحديد السعر،"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يوصى بتحديد السعر بنفسك"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مجموع"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استخدام الكل"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عمولة"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عمولة"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المجموع:"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حسب السوق"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(تبادل سريع بأفضل عرض مضاد)"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السعر الثابت"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(تقديم طلب بالسعر المحدد)"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد حسابات مناسبة"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السعر"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التغير خلال 24 ساعة"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التقلبات خلال 24 ساعة"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حجم التداول خلال 24 ساعة"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يمكن أن يكون المبلغ صفرًا!"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تبادل"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لرؤية بيانات الملخص، اختر زوج التبادل"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يمكن تبادل الأصول المتشابهة"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بالإضافة إلى ذلك"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم يتم تضمين التطبيق في الاختيار حتى"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ل"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القيود"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب أن يكون لحقل 'السعر' قيمة غير صفرية"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رصيد غير كافٍ في الحساب"])}
}