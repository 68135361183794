export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dispositivos de cajero automático"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar dispositivo"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de dispositivo"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentario"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuración"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar casete"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tipo casete"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentario"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuración"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quitar dispositivo"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quitar casete"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Eliminar dispositivo?"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Eliminar el casete?"])}
}