export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Staré heslo:"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nové heslo:"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrzení hesla:"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nové heslo:"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrzení hesla:"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložit"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložit"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["''Nové heslo'' a ''Potvrzení hesla'' se neshodují."])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heslo nesmí obsahovat azbuku"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořit potvrzovací heslo"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Změnit potvrzovací heslo"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na hlavní stránku"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Řetězec od 4 do 65 znaků. Povolené znaky jsou latinské písmena, číslice a symboly"])}
}