export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Таңдау"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Берілетін"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Алынатын"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["актив"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["беремін"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ішкі"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["аламын"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ішкі активтер"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активтер табылмады"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сыртқы активтер"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["сыртқы"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Таңдалған:"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ішкі"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сыртқы"])}
}