export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vybrat soubor"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tento formát souboru není podporován. \nPřijímané formáty:"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neznámý formát"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Příliš velká velikost souboru, maximální velikost:"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte nebo přetáhněte sem soubory pro nahrání"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vybrat"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nahrát"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušit"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soubory nahrány"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["soubory"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vymazat"])}
}