export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编辑外部资产"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名称"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["组"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["同义词"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国家"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["绑定到资产"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["计量单位"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["缩写"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["精度"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活跃"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已接受交易"])}
}