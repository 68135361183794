export default {
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zu gebender Betrag"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betrag"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zu erhaltender Betrag"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualisieren"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaktionen"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaktion"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Angebote"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["P2P-Angebote"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkäufer-ID"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angebotscode"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkäufer verifiziert"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur für verifizierte Benutzer"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaktion mit sich selbst nicht möglich"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv auswählen"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzeigeninformationen"])}
}