export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नाम:"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नाम क्रिप्टो-परिसंपत्ति का पूरा नाम है, क्योंकि इसे हर कोई देखेगा: जारीकर्ता स्वयं, खरीदार, विक्रेता, प्रसंस्करण, आदि।"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टिकर"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 से 10 अक्षरों का संक्षिप्त रूप। \nबड़े लैटिन अक्षर, हाइफ़न (डैश) और संख्याएँ स्वीकार्य हैं।"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["श्रेणी:"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["श्रेणी चुनें जो आपकी क्रिप्टो-संपत्ति के लिए सबसे उपयुक्त है"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समर्थित प्रारूप: पीएनजी, जेपीजी, जेपीईजी, एसवीजी। \nफ़ाइल का आकार 1 एमबी, 500*500 पिक्सेल से अधिक नहीं"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वर्णन करें कि इमिटर इस क्रिप्टो-संपत्ति को क्यों जारी कर रहा है, यह किस क्षेत्र में काम करेगा या करेगा, इसका मिशन क्या है"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लोगो:"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उद्देश्य/मिशन/उद्देश्य:"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सटीकता:"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इमीशन:"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रति यूनिट लागत:"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["राशि के लिए दशमलव के बाद के अंक। उदाहरण के लिए, अधिकांश फिएट मुद्राओं की सटीकता, जैसे अमेरिकी डॉलर या यूरो, 2 है।"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जारी की जाने वाली क्रिप्टो-संपत्ति की इकाइयों की संख्या। इमीशन राशि निर्माण संचालन के पूरा होने के तुरंत बाद आपके खाते में उपलब्ध होगी।"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रति यूनिट घोषित लागत:"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सुरक्षा:"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खाता:"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दायित्व वहन करने वाला व्यक्ति:"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्रिप्टो-संपत्ति जारी करने का निर्णय:"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ऑपरेशनों को ब्लॉकचेन में निर्यात करें:"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ब्लॉकचेन प्रकार:"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्रिप्टो-संपत्ति के साथ ऑपरेशनों को सक्षम करें:"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रतिबंध/प्रतिबंध:"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इस सूची में शामिल देशों के नागरिकों या कर निवासियों को अपने व्यापार में क्रिप्टो-संपत्ति के उपयोग की अनुमति नहीं दें:"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अन्य प्रतिबंध और शर्तें:"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["होस्टिंग का पता:"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सफेद पुस्तक का पता:"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ईमेल:"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टेलीग्राम:"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ट्विटर:"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फेसबुक:"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अन्य संपर्क:"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पिछले पर वापस जाएं"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सहेजें"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आगे बढ़ें"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्रिप्टो-संपत्ति बनाएं"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नाम और उद्देश्य"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वित्तीय विशेषताएँ"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इमिटर"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ब्लॉकचेन में निर्यात"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सीमाएँ और प्रतिबंध"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संपर्क"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["निर्धारित नहीं"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फिएट मुद्राओं और अन्य संपत्तियों के लिए स्थिर निर्धारण"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इमिटर (क्रिप्टो-संपत्ति जारी करने वाला व्यक्ति)"])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ऑफरेंट (क्रिप्टो-संपत्ति वितरित करने वाला व्यक्ति)"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भेजने वाला"])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्राप्तकर्ता"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मैं (प्रायोजन को सक्षम करें)"])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्रिप्टो-संपत्ति का प्लेसमेंट"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्रिप्टो-संपत्ति का अर्थ है मूल्य या अधिकार का डिजिटल प्रतिनिधित्व, जो ब्लॉकचेन तकनीक का उपयोग करके इलेक्ट्रॉनिक रूप में प्रेषित और सहेजा जा सकता है। क्रिप्टो-संपत्तियों में फिएट मुद्राएँ, प्रतिभूतियाँ और अन्य वित्तीय साधन और संपत्तियाँ शामिल नहीं हैं, जो अलग से सरकारी विनियमन के अधीन हैं।"])},
  "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्रिप्टो परिसंपत्ति संपादित करें"])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रशासन"])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्वीकार"])},
  "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्वीकार करने से इंकार करने का कारण"])},
  "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रस्तावक का खाता"])},
  "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बाध्यकारी मुद्रा में प्रस्तावक का खाता"])},
  "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रस्तावक प्राथमिक बाजार पर खरीद/बिक्री लेनदेन में परिसंपत्ति के जारीकर्ता का प्रतिनिधि है। \nखाता निष्क्रिय है. \nएक परिसंपत्ति निर्गम खाता हो सकता है"])},
  "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खाता निष्क्रिय है, मुद्रा में जुड़ा हुआ है। \nयह पिछले खाते के समान उपयोगकर्ता का होना चाहिए"])},
  "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपयोगकर्ताओं को एटीएम पर संपत्ति खरीदने की अनुमति दें"])},
  "62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एटीएम में उपयोगकर्ताओं द्वारा परिसंपत्ति की बिक्री की अनुमति दें"])},
  "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आफ्टरमार्केट खरीद फॉर्म"])},
  "64": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["द्वितीयक बाज़ार लेनदेन गेटवे के माध्यम से किए जाते हैं"])},
  "65": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आफ्टरमार्केट सेल्स फॉर्म"])},
  "66": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["द्वितीयक बाज़ार लेनदेन गेटवे के माध्यम से किए जाते हैं"])},
  "67": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एक्सचेंज पर किसी परिसंपत्ति के साथ लेनदेन की अनुमति दें"])},
  "68": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पी2पी के माध्यम से परिसंपत्ति लेनदेन की अनुमति दें"])},
  "69": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यदि आप एक व्यक्तिगत उद्यमी या कानूनी इकाई के प्रतिनिधि हैं, तो आपको पीडीएफ प्रारूप में डिजिटल संपत्ति जारी करने पर निर्णय फ़ाइल डाउनलोड करनी होगी। \nसमाधान में आभासी संपत्ति की सभी मुख्य विशेषताएं शामिल होनी चाहिए और व्यक्तिगत उद्यमी या इस कानूनी इकाई के इलेक्ट्रॉनिक हस्ताक्षर के साथ हस्ताक्षरित होना चाहिए। \nडिजिटल परिसंपत्ति जारी करने के निर्णय का एक उदाहरण हमारी वेबसाइट के पृष्ठों पर \"दस्तावेज़\" अनुभाग में पाया जा सकता है"])},
  "70": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एक आभासी संपत्ति रखना"])},
  "71": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्थानापन्न निर्गम खाता"])},
  "72": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जारीकर्ता खातों में से चयन करें"])},
  "73": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बट्टे खाते में डाली जाने वाली राशि:"])},
  "74": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खाता नहीं"])},
  "75": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खाता बनाएं"])},
  "76": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अधिकतम. \nउत्सर्जन:"])},
  "77": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उत्सर्जन का आकार अधिकतम से अधिक है. \nउत्सर्जन"])},
  "78": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अधिकतम. \nउत्सर्जन उत्सर्जन आकार से कम या शून्य के बराबर है"])},
  "79": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रूप अनुपस्थित हैं"])}
}