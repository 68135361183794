export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todas as ordens"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redefinir"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtros"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atualizar"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dou"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recebo"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxa"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operações"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["comissões"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repetir"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sem solicitações"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor mín para enviar"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor máx para enviar"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor mín para receber"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor máx para receber"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de tentativas para completar a transação"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ativo"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moeda para enviar"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moeda para receber"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conta para envio"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conta para recebimento"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de subdealers"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conta não selecionada"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Período de"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Período até"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoje"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ontem"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semana"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mês"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ano"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de data não selecionado"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expirar"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finalizar"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qual parte é fixa e qual é variável"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O valor dado é fixo"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O valor a receber é fixo"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de ordem"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O usuário quer trocar rapidamente pelas melhores contrapropostas"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Troca a uma taxa estritamente especificada"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processamento não iniciado"])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processamento iniciado"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parcialmente concluído"])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totalmente concluído"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totalmente cancelado"])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parcialmente concluído e cancelado"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ativo de saída"])},
  "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ativo de entrada"])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de data"])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O montante mínimo é superior ao montante máximo"])}
}