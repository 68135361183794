export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I miei negozi"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun negozio trovato"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea codice widget"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea link di pagamento"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina negozio"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vuoi davvero eliminare il negozio"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrare negozio"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modalità di pagamento"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attività"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accettazione"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azioni"])}
}