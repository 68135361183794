export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["transaction"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres généraux"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changement des soldes des comptes"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À la fin de l'opération :"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Au début de l'opération :"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres supplémentaires"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opérations dans la blockchain"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Callback"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prochaine tentative :"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réponse"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protection de la transaction"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée de protection :"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jusqu'à"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code de protection :"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres du modèle"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du modèle :"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler l'opération"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Répéter l'opération"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer le modèle"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de transaction"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opération"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correspondant"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de paiement"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commission"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultat"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro du formulaire de paiement"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recettes/Dépenses"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changement des soldes des adresses"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer l'accréditation"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renvoyer l'argent à l'expéditeur"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opération suspecte"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options d'actions :"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commission du bénéficiaire"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commission du payeur"])}
}