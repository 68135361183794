export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conto da ricaricare"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metodo di pagamento"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome del modello:"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva modello"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continua"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modelli"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricarica conto"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettagli del trasferimento"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifica il trasferimento"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni sui trasferimenti"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Istruzioni"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricarica indirizzo"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo da ricaricare"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esporta"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Torna alla lista delle attività"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo del trasferimento"])}
}