export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Externí aktiva"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kód"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vázáno na aktivum"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jednotky měření"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skupina"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum vytvoření"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autor"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akce"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Období od"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Období do"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtry"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resetovat"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualizovat"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádná aktiva"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dnes"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Včera"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Týden"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Měsíc"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rok"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vázáno na aktivum"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skupina"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autor"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivita"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Země"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivní"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neaktivní"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upravit externí aktiv"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smazat externí aktiv"])}
}