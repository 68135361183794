export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мәміле сұрауы"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ұсыныс"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сатушы"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Таймаут"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тараптардың әрекет ету уақыты"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курс"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бересіз"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аласыз"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мәміле сұрау"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шот жасау"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шот таңдалмаған"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сома"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сома диапазонға жатпайды:"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["мин"])}
}