export default {
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дати суму"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отримати суму"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фільтри"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оновити"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["торгів"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Торг"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пропозицій немає"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пропозиції"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID продавця"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код пропозиції"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Крипто-актив"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опис"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перевірений продавець"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тільки для перевірених користувачів"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неможливо торгувати з собою"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обрати актив"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Інформація про оголошення"])}
}