export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaktion"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allgemeine Parameter"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Änderung der Salden auf Konten"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Am Ende der Operation:"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Am Anfang der Operation:"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zusätzliche Parameter"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operationen in der Blockchain"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Callback"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nächster Versuch:"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeit"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antwort"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaktionsschutz"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schutzdauer:"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bis"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schutzcode:"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigen"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorlagenparameter"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorlagenname :"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operation stornieren"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operation wiederholen"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorlage speichern"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schließen"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaktionsnummer"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operation"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korrespondent"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsdatum"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betrag"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommission"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ergebnis"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsformularnummer"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einnahmen/Ausgaben"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Änderung der Adresssalden"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gutschrift bestätigen"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geld an Absender zurücksenden"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verdächtige Operation"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handlungsoptionen:"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provision des Empfängers"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommission des Zahlers"])}
}