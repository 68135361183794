export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все заявки"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сбросить"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фильтры"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обновить"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отдаю"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получаю"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курс"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операции"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["комиссия"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменить"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повторить"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет заявок"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Минимальная отдаваемая сумма"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Максимальная отдаваемая сумма"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Минимальная получаемая сумма"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Максимальная получаемая сумма"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество попыток совершить транзакцию"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активность"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отдаваемый актив"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получаемый актив"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Счет списания"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Счет зачисления"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список субдилеров"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Счет не выбран"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Период с"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Период по"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сегодня"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вчера"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неделя"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Месяц"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Год"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип даты не выбран"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создание"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Окончание"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Финиш"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Старт"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Какая часть зафиксирована, а какая - переменная"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фиксируется отдаваемая сумма"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фиксируется сумма к получению"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип заявки"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователь желает быстро обменять по лучшим встречным предложениям"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обмен по строго заданному курсу"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обработка не начата"])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обработка начата"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Частично выполнена"])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Полностью выполнена"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Полностью отменена"])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Частично выполнена и отменена"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отдаваемый актив"])},
  "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получаемый актив"])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип даты"])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Минимальная сумма больше максимальной суммы"])}
}