export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["देता हूँ"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शेष"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्राप्त करता हूँ"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पूंजी युगल"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हिसाब किताब"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विनिमय प्रकार"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बाजार दर पर हस्तांतरण - सबसे लाभकारी दर का उपयोग किया जाएगा। ध्यान दें, प्राप्त राशि बदल सकती है और अनुरोध के समय की दर के अनुसार भिन्न हो सकती है।"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्थिर दर पर हस्तांतरण आपके द्वारा निर्दिष्ट राशि और दर के अनुसार होता है। अनुरोध प्रस्तुत किया जाएगा। यदि निर्दिष्ट दर बाजार दर से बहुत अलग है, तो अनुरोध को पूरा करने में समय लग सकता है।"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्राप्त राशि (अनुमान):"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दर"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दर निर्धारित नहीं है,"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दर को स्वयं सेट करने की अनुशंसा की जाती है"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जोड़"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी का उपयोग करें"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आयोग"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आयोग"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कुल:"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बाजार पर"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(बेहतर मुकाबले की पेशकश के साथ तेज़ एक्सचेंज)"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्थिर दर"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(निर्धारित दर पर अनुरोध प्रस्तुत करें)"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई उपयुक्त खाते नहीं"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दर"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24 घंटे में परिवर्तन"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24 घंटे में उतार-चढ़ाव"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24 घंटे में लेन-देन की मात्रा"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["राशि शून्य नहीं हो सकती!"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एक्सचेंज"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सारांश डेटा देखने के लिए एक्सचेंज पेयर चुनें"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समान संपत्तियों का आदान-प्रदान नहीं कर सकते"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इसके अतिरिक्त"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आवेदन को तब तक चयन में शामिल नहीं किया जाता"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["को"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रतिबंध"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'दर' फ़ील्ड में 0 से अलग मान होना चाहिए"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खाते में अपर्याप्त धनराशि"])}
}