export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome:"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il nome è il nome completo del cripto-asset, così come sarà visto da tutti: l'emittente stesso, gli acquirenti, i venditori, l'elaborazione, ecc."])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TICKER"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un'abbreviazione da 3 a 10 caratteri. \nSono accettabili lettere latine maiuscole, trattini (trattini) e numeri."])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria:"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona la categoria che meglio si adatta al tuo cripto-asset"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formati supportati: png, jpg, jpeg, svg. \nLa dimensione del file non è superiore a 1 MB, 500*500 pixel"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrivi il motivo per cui l'emittente rilascia questo cripto-asset, in quale settore opera o opererà, qual è la sua missione"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo:"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scopo / missione / destinazione:"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precisione:"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emissione:"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costo per unità:"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di cifre decimali per l'importo. Ad esempio, la precisione della maggior parte delle valute fiat, come il dollaro USA o l'euro, è pari a 2."])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di unità del cripto-asset emesso. L'importo dell'emissione sarà immediatamente disponibile sul tuo conto dopo il completamento dell'operazione di creazione del cripto-asset."])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costo dichiarato per unità:"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garanzia:"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conto:"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persona che assume le obbligazioni:"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decisione di emissione del cripto-asset:"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esporta le operazioni nella blockchain:"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo di blockchain:"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abilita operazioni con il cripto-asset:"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restrizioni/Sanzioni:"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indica a quali cittadini o residenti fiscali dei paesi dell'elenco è vietato utilizzare il cripto-asset:"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altre restrizioni e condizioni:"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo di hosting:"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo del white paper:"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email:"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telegram:"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twitter:"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook:"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altri contatti:"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al precedente"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successivo"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea cripto-asset"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome e destinazione"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caratteristiche finanziarie"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emittente"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esporta nella blockchain"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limiti e restrizioni"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contatti"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non definito"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legame fisso con valute fiat e altri asset"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emittente (persona che emette il cripto-asset)"])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offerente (persona che distribuisce il cripto-asset)"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mittente"])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destinatario"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Io (includi sponsorizzazione)"])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distribuzione del cripto-asset"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il cripto-asset rappresenta un valore digitale o un diritto che può essere trasferito e conservato elettronicamente utilizzando la tecnologia blockchain. I cripto-asset NON includono valute fiat, titoli e altri strumenti finanziari e asset soggetti a regolamentazione statale separata."])},
  "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica risorsa crittografica"])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amministrazione"])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accettazione"])},
  "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motivo del rifiuto di accettazione"])},
  "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conto dell'offerente"])},
  "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conto dell'offerente nella valuta vincolante"])},
  "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'Offerente è un rappresentante dell'emittente di un bene nelle operazioni di acquisto/vendita sul mercato primario. \nIl conto è passivo. \nPuò essere un conto di emissione di asset"])},
  "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il conto è passivo, collegato in valuta. \nDeve appartenere allo stesso utente dell'account precedente"])},
  "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consenti agli utenti di acquistare un bene presso gli sportelli bancomat"])},
  "62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consenti la vendita di risorse da parte degli utenti presso gli sportelli automatici"])},
  "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modulo di acquisto post-vendita"])},
  "64": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le transazioni sul mercato secondario vengono effettuate tramite gateway"])},
  "65": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modulo di vendita post-vendita"])},
  "66": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le transazioni sul mercato secondario vengono effettuate tramite gateway"])},
  "67": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consenti transazioni con un asset in borsa"])},
  "68": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consenti transazioni di risorse tramite P2P"])},
  "69": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se sei un imprenditore individuale o un rappresentante di una persona giuridica, devi scaricare il file della decisione sul rilascio di una risorsa digitale in formato PDF. \nLa soluzione deve contenere tutte le principali caratteristiche del bene virtuale ed essere firmata con la firma elettronica del singolo imprenditore o di questa persona giuridica. \nUn esempio di decisione di emettere una risorsa digitale può essere trovato sulle pagine del nostro sito web nella sezione \\\"Documenti\\\""])},
  "70": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posizionamento di una risorsa virtuale"])},
  "71": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conto di emissione sostitutivo"])},
  "72": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona dai conti dell'emittente"])},
  "73": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo da cancellare:"])},
  "74": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun conto"])},
  "75": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creare un account"])},
  "76": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Massimo. \nemissione:"])},
  "77": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La dimensione dell'emissione supera il massimo. \nemissioni"])},
  "78": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Massimo. \nl'emissione è inferiore alla dimensione dell'emissione o uguale a zero"])},
  "79": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le forme sono assenti"])}
}