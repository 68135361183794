export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une paire de devises"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paire de devises"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurer une mise à jour"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porte"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De monnaie donnée"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devise reçue"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter du profit au cours, %"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de mise à jour du cours"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée de vie du cours, secondes"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intervalle de mise à jour du cours, secondes"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bien"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour quelle date le cours est-il valable ?"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alias ​​​​de la devise donnée"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alias ​​​​de la devise reçue"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porte"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il faut choisir une monnaie et, de plus, une seule : donnée ou reçue"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant d'envoi * Tarif = Montant de réception"])}
}