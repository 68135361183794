export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doy"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recibo"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentario"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Razón"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar transacción"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar participación en la transacción"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechazar transacción"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extender tiempo de espera"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar transacción"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado del usuario en la transacción no definido. Algo salió mal"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicitar arbitraje"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceptar arbitraje"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notificar sobre transferencia completada"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar recepción de la transferencia"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calificar transacción"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar calificación"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transacción nº"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corresponsal"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiempo de espera"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hoy"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mañana"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar arbitraje"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar arbitraje"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Has solicitado arbitraje"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha solicitado arbitraje en su contra"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El arbitraje está en marcha (usted es el demandante)"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El arbitraje está en marcha (usted es el demandado)"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Una vez que expire el tiempo, el consentimiento al arbitraje se producirá automáticamente y ya no podrá agregar sus respuestas a las preguntas de arbitraje."])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbitraje requerido"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hay un arbitraje"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hay un arbitraje"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tomar una decisión sobre el arbitraje: pago interno"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conducta"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])}
}