export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset-Verbrennung"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbrennungsbetrag"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto für Rückgabe"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbrennen"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbrennungsbetrag"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Verbrennungsbetrag kann nicht größer sein als der Emissionsbetrag"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deckungsrückgabe"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rückzuzahlen"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betrag, der nach der Asset-Verbrennung in der Reserve verbleibt"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betrag, der nach der Asset-Verbrennung auf Ihr Konto zurückgeht"])}
}