export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exchange"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Market Summary"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Similar offers"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Counter offers"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rate"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sell"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My applications"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latest deals"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rate"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Given away"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date and time"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I give"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I receive"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rate"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operations"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["commission"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No offers"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No deals"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No applications"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fully implemented"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processing not started"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processing started"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partially completed"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completely canceled"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partially completed and canceled"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Received"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All applications"])}
}