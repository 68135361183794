export default {
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Соманы беру"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сома"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Соманы алу"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сүзгілер"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жаңарту"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["мәмілелер"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мәміле"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ұсыныстар жоқ"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["P2P ұсыныстар"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сатушының ID"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ұсыныс коды"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Актив"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сипаттама"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сатушы расталған"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тек расталған пайдаланушылар үшін"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Өзіңізбен мәміле жасау мүмкін емес"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активті таңдау"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хабарландыру туралы ақпарат"])}
}