export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Aufträge"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurücksetzen"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualisieren"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich gebe"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich erhalte"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurs"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operationen"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provisionen"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wiederholen"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Anträge"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Min. Betrag zum Senden"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max. Betrag zum Senden"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Min. Betrag zum Empfangen"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max. Betrag zum Empfangen"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der Transaktionsversuche"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Währung zum Senden"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Währung zum Empfangen"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto für Versand"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto für Empfang"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste der Unterhändler"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto nicht ausgewählt"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeitraum von"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeitraum bis"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heute"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestern"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Woche"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monat"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jahr"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datumstyp nicht ausgewählt"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ablauf"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abschluss"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcher Teil ist fix und welcher variabel"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der gegebene Betrag ist fix"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der zu erhaltende Betrag ist fix"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auftragstyp"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Benutzer möchte schnell zum besten Gegenangebot tauschen"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tausch zu einem streng festgelegten Kurs"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verarbeitung nicht gestartet"])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verarbeitung gestartet"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teilweise abgeschlossen"])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vollständig abgeschlossen"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vollständig storniert"])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teilweise abgeschlossen und storniert"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausgehendes Asset"])},
  "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eingehendes Asset"])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datumstyp"])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindestbetrag ist größer als Maximalbetrag"])}
}