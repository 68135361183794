export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer le paiement"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculer la commission de remboursement"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rembourser le paiement"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hash dans la blockchain"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risque AML"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commission"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À créditer"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous acceptez les nouvelles conditions, confirmez le crédit :"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si ces conditions ne vous conviennent pas, vous pouvez retourner les fonds sur le compte de l'expéditeur, moins les frais de remboursement :"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pour un montant de"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["présente un risque AML"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["par conséquent, la commission a été recalculée"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ainsi, le montant à créditer est"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune nouvelle entrée trouvée"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de paiement"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remboursement"])}
}