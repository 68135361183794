export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaktionsanfrage"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angebot"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkäufer"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timeout"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wartezeit auf Aktionen der Parteien"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurs"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie geben"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie erhalten"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaktion anfordern"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto erstellen"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto nicht ausgewählt"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betrag"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Betrag liegt nicht im Bereich:"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["min"])}
}