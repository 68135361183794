export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Číslo kazety"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID kazety"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Číslo sbírky kazet"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hodnocení kazety"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nahráno"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Součet"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Množství"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Součet"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bankomat"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Součet"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vlastně"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Množství"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Součet"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentář ke kazetě"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádná data k zobrazení"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sbírka zařízení"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["platný"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["C"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podle"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ostatky jsou odneseny"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počet účtů"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Součet"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bankomat"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počet účtů"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Součet"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentář"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dávkovač"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odmítnout/Odvolat"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Příjemce faktury"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odmítnout karty"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karty"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["proud"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vyloženo"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inkasní zůstatky z bankomatu"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sbírka zařízení"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["platný"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kazeta instalovaná v bankomatu"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zabaveno"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ostatky jsou odneseny"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ostatky jsou odneseny"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["prázdná kazeta:"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["přilnavost:"])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Další informace:"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["k dnešnímu dni"])}
}