export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte stav"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádost o inkaso byla podána"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přihláška k odběru byla zrušena"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sbírka schválena k provedení"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyzvednutí provádí pokladní (peníze se vkládají do kazet)"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kazety předané sběratelům (peníze na cestě)"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výběr hotovosti nainstalovaný na bankomatu"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V důsledku poruchy přetížení jinou kolekcí"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyzvednutí bylo nahrazeno jiným vyzvednutím v bankomatu (zůstává na cestě)"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inkasní zůstatky jsou zaúčtovány (sbírka je odeslána do archivu)"])}
}