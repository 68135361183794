export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Авторизация"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Логин:"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль:"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Забыли пароль?"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Впервые здесь?"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создайте аккаунт"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Восстановление пароля"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Телефон или e-mail:"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Восстановить"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Восстановление пароля"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фамилия:"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя:"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отчество:"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата рождения:"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Войти"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назад"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продолжить"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Найти"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователь найден"])}
}