export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قائمة الفواتير"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم يتم العثور على فواتير"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رمز"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحالة"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعليق"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المجموع"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المتجر"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حالة"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم المتجر"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلغاء الفاتورة"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تخزين رمز الدفع"])}
}