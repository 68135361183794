export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedes realizar varios pagos al mismo tiempo. \nPara hacer esto, primero debe crear un archivo con una descripción de los pagos en formato \"XLS\", \"XLSX\", \"CSV\"."])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análisis de archivo"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corresponsal"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentario"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parámetro"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información sobre el archivo"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total de pagos: "])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["por un monto total de: "])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivo de pagos"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta de débito"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Realizar pagos"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargar archivo de pagos"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifique el contenido del archivo"])},
  "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagos y transferencias masivas"])}
}