export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cajeros automáticos"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haz clic para expandir la tabla a pantalla completa"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restablecer"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizar"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actividad"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subdistribuidores"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activo"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactivo"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["crear un cajero automático"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar criptomoneda"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información principal"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Principal"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horario de apertura"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuario"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descripción del lugar de instalación"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normado"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No normado"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horario de trabajo"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lunes"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Martes"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miércoles"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jueves"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viernes"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sábado"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domingo"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hora de almuerzo"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repetir contraseña"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usar cuentas existentes"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear nuevas cuentas"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información principal"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horario de trabajo (información de referencia)"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actividad"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acciones"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontraron máquinas criptográficas"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["número de cajero automático"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["latitud"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["longitud"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Línea de ayuda"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID del subdistribuidor"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuentas"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controlar"])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Divisa"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cuenta de tránsito"])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creación de una criptomat"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar un criptomat"])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña"])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sí"])},
  "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prohibir"])},
  "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permitir"])},
  "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de coleccionistas"])},
  "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar un coleccionista"])},
  "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se puede acceder a los saldos de cobro: no hay datos"])},
  "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de coleccionistas"])},
  "62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar un coleccionista"])},
  "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permitido"])},
  "65": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceptación"])},
  "67": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DIRECCIÓN"])},
  "68": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar cajero automático"])},
  "69": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver saldos de cobranza actuales"])},
  "70": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver saldos de cobro descargados"])},
  "71": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["código pin"])},
  "73": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si no especifica un subdistribuidor, el cajero automático estará vinculado a usted"])},
  "74": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La latitud y la longitud son coordenadas geográficas que se pueden copiar, por ejemplo, de Google Maps introduciendo la dirección del cajero automático."])},
  "75": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubicación de instalación"])},
  "76": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se debe seleccionar al menos una columna para mostrar la tabla."])},
  "77": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ahorrar"])},
  "78": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear"])},
  "79": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ir a dispositivos cajeros automáticos"])}
}