export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jednorazowe klucze znacznie zwiększają bezpieczeństwo systemu."])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podczas logowania program poprosi nie tylko o login i hasło, ale także o kolejny jednorazowy klucz. Aktywacja odbywa się sekwencyjnie. Ostatni klucz jest ważny do momentu utworzenia nowej serii kluczy."])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Użyj jednorazowych kluczy"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista kluczy"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generuj"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eksport"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nr"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klucz"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jednorazowe klucze"])}
}