export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajout d'une collection"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations de base"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les bases"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distributeur"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de point"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de cartes par demande"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepteur de billets"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cartes"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["collecteurs de fonds"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remarque pour les collectionneurs"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire sur la collecte"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chargement dans le désordre"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remplacer la cassette accepteur de billets"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire sur la cassette"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire sur les cartes"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retirez toutes les cartes de la cassette du distributeur de cartes de rejet."])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retirez toutes les cartes du tube"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le numéro de point est un champ obligatoire qui doit être rempli"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID de cassette 1"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID de cassette 2"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID de cassette 3"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID de cassette 4"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classement des cassettes 1"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de factures dans la cassette 1"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantité dans la cassette 1"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classement des cassettes 2"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de factures dans la cassette 2"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire sur la cassette 1"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID de cassette"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classement des cassettes"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de factures"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Somme"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire sur la cassette"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Édition de la collection"])}
}