export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cheques eletrônicos"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar cheque"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atividade"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criado"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conta de débito"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantia"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Série"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentário"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ações"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ativo"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inativo"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sem cheques"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ativar"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificar"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fechar"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum recibo eletrônico encontrado"])}
}