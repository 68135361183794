export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktywność"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opis aktywa"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak ogłoszeń"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moje ogłoszenia"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moje ogłoszenia"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nowe ogłoszenie"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktywność"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktywne"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieaktywne"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszystkie"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kierunek"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zewnętrzne aktywo"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wewnętrzne aktywo"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opis"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oddaję"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otrzymuję"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limity"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data publikacji"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo na koncie"])}
}