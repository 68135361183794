export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado "])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asunto"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Último mensaje"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoría"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acciones"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay solicitudes"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoría"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prioridad"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asunto"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texto del mensaje"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivos"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedes cargar uno o varios archivos. Tamaño máximo del archivo: 10 MB."])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baja"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normal"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alta"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soporte técnico"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicitudes activas"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivo"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nueva solicitud"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En proceso por el operador"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicitud creada"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En proceso, espera una respuesta del operador"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicitud cerrada"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicitud cerrada"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay mensajes nuevos"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensaje nuevo del operador"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay mensajes nuevos"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar al archivo"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todas las solicitudes"])}
}