export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكلمة السحرية هي إعداد أمان إضافي."])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إذا تم تمكين الكلمة السحرية، فسيطلب منك النظام إدخال بعض الرموز (حسب ترتيبها) من الكلمة السحرية في كل مرة تسجل فيها الدخول."])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكلمة السحرية:"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طلب الكلمة السحرية:"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عند الدخول إلى الحساب:"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عند الدفع عبر التاجر:"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عند استخدام البطاقة:"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكلمة السحرية"])}
}