export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متاجري"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم يتم العثور على متاجر"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء كود الودجت"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء رابط الدفع"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حذف المتجر"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل تريد حقًا حذف المتجر"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل المتجر"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكود"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وضع الدفع"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النشاط"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قبول"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإجراءات"])}
}