export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti gli ordini"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reimposta"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtri"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiorna"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricevo"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasso"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operazioni"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["commissioni"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ripeti"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna richiesta"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo min da inviare"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo max da inviare"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo min da ricevere"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo max da ricevere"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di tentativi per completare la transazione"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attivo"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valuta da inviare"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valuta da ricevere"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conto per l'invio"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conto per la ricezione"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elenco dei sub-dealer"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conto non selezionato"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periodo dal"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periodo al"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oggi"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ieri"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settimana"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mese"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anno"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo di data non selezionato"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scade"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fine"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inizio"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quale parte è fissa e quale variabile"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'importo dato è fisso"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'importo da ricevere è fisso"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo di ordine"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'utente vuole scambiare rapidamente per le migliori controfferte"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scambio a un tasso strettamente specificato"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elaborazione non iniziata"])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elaborazione iniziata"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parzialmente completato"])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completamente completato"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completamente annullato"])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parzialmente completato e annullato"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset in uscita"])},
  "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset in entrata"])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo di data"])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'importo minimo è maggiore dell'importo massimo"])}
}