export default {
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отдать сумму"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получить сумму"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фильтры"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обновить"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["сделок"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сделка"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет предложений"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предложения"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID продавца"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код предложения"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Крипто-актив"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Описание"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продавец верифицирован"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Только для верифицированных пользователей"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Невозможно совершить сделку с самим собой"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать актив"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Информация об объявлении"])}
}