export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plantillas"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En el grupo"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupos de pagos"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagos"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto del pago"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta de transferencia"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentario"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plantilla"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de la plantilla:"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar plantilla"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferir"])}
}