export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TICKER"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mission"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of crypto assets"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No crypto assets"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token issuance"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Market"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precision"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primary"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secondary"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matched with another asset"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detailed information about the crypto asset"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit crypto asset data"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional emission"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete crypto asset"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of crypto assets"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tokens can be bought at an ATM"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tokens can be sold at an ATM"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secondary market tokens"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token display"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All accepted"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All mine"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User UID"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset burning"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For specific user"])}
}