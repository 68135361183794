export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отдаю"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получаю"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комментарий"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Причина"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменить сделку"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвердить участие в сделке"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отказаться от сделки"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продлить время ожидания"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена сделки"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус юзера в сделке не определен. Что-то пошло не так"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запросить Арбитраж"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Согласиться на Арбитраж"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уведомить о выполнении перевода"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвердить получение перевода"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оцените сделку"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить оценку"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сделка № "])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Корреспондент"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Таймаут"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["сегодня"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["завтра"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменить Арбитраж"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменить арбитраж"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы запросили арбитраж"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Против Вас запросили арбитраж"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Идет арбитраж (Вы - истец)"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Идет арбитраж (Вы - ответчик)"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["По истечении таймера согласие на арбитраж произойдёт автоматически и вы уже не сможете добавить свои ответы на вопросы арбитража"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запрошен арбитраж"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Идёт арбитраж"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Идет арбитраж"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вынести решение по арбитражу: внутренний платёж"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Провести"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменить"])}
}