export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impostazione notifiche"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attiva"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifica delle operazioni"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorizzazione (accesso al portafoglio personale, app mobile, ecc.)"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifica per l'evento \"Esecuzione di operazioni finanziarie tramite carta\""])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spese (qualsiasi pagamento, trasferimento, pagamento di servizi, ecc.)"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricevimento di fondi"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricezione di messaggi nella posta interna"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricezione di una fattura"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica delle impostazioni di sicurezza"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma delle operazioni con codice dal messaggio"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma dell'autorizzazione con codice dal messaggio"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma di \"Esecuzione di operazioni finanziarie tramite carta\""])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma della modifica delle impostazioni di sicurezza con codice dal messaggio"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma delle spese con codice dal messaggio"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifiche non salvate, salvare?"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non salvare"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva"])}
}