export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сиқырлы сөз - бұл қосымша қауіпсіздік баптауы."])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Егер сиқырлы сөз қосылған болса, әрбір авторизация кезінде жүйе сізден сиқырлы сөздің қандай да бір символдарын (олардың реттік нөмірі бойынша) енгізуді сұрайды."])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сиқырлы сөз:"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сиқырлы сөзді сұрау:"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кабинетке кіру кезінде:"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мерчант арқылы төлем жасау кезінде:"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Картаны қолдану кезінде:"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сақтау"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сиқырлы сөз"])}
}