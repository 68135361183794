export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dispositivi bancomat"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi dispositivo"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo di dispositivo"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commento"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurazione"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi cassetta"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo di cassetta"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commento"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurazione"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rimuovi il dispositivo"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rimuovere la cassetta"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminare il dispositivo?"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminare"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminare la cassetta?"])}
}