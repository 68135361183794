export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TICKER"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategoria"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Misja"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akcje"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista kryptoaktywów"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak kryptoaktywów"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emisja tokena"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akceptuj"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rynek"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precyzja"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pierwotny"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wtórny"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sparowany z innym aktywem"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szczegółowe informacje o kryptoaktywie"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edytuj dane kryptoaktywa"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodatkowa emisja"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń kryptoaktywo"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista kryptoaktywów"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akceptuj"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktywność"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tak"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tokeny można kupić w bankomacie"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tokeny można sprzedać w bankomacie"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategoria"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tokeny rynku wtórnego"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyświetlanie tokenów"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszystkie zaakceptowane"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszystkie moje"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UID użytkownika"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spalanie aktywów"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dla konkretnego użytkownika"])}
}