export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je remets"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["solde "])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je reçois"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Couple"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comptes"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type d'échange"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfert au marché - l'échange se fera au taux le plus avantageux au moment de l'exécution de l'ordre. Attention, le montant à recevoir peut changer et différer du montant calculé en fonction des ordres au moment de l'échange."])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfert au prix fixe se fait conformément au montant et au taux que vous avez spécifiés. Une commande sera créée. Si le taux spécifié diffère fortement du taux du marché, la fermeture de la commande peut prendre beaucoup de temps."])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux (prévision) :"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux non défini,"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["il est recommandé de définir le taux soi-même"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Somme"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utiliser tout"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commission"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commission"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total :"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selon le marché"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(échange rapide à la meilleure offre)"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux fixe"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(placer une commande au taux spécifié)"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun compte approprié"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changement sur 24 heures"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fluctuations sur 24 heures"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volume des transactions sur 24 heures"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le montant ne peut pas être nul !"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échanger"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour voir les données récapitulatives, sélectionnez la paire de trading"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il est interdit d'échanger des actifs identiques"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En plus"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La candidature n'est incluse dans la sélection qu'après"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["à"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restrictions"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le champ 'Taux' doit avoir une valeur différente de 0"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fonds insuffisants sur le compte"])}
}