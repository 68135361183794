export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["alinea hasta 255 caracteres"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["entero"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["20 dígitos"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0 o 1"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["número fraccionario, precisión de dos dígitos, separador de puntos"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["entero"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cadena de hasta 255 caracteres, opcional"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["entero, opcional"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cadena de 32 caracteres"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["código de pago de la tienda; debe ser único para cada pago; al transmitir una cadena"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["se generará un número único automáticamente"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["número de tienda asignado después del registro"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["número de cuenta de la tienda; esta cuenta también determina en qué moneda se debe realizar el pago (en la mayoría de los casos, el usuario puede realizar un pago en cualquier moneda y los fondos se convertirán automáticamente a la moneda del destinatario)"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["prohibir al cliente cambiar la cantidad especificada (1 - prohibir, 0 - permitir)"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["monto del beneficiario en la moneda de la cuenta del beneficiario"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["número del formulario a través del cual se acepta el pago"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["comentario mostrado al pagador como el propósito del pago"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["parámetro opcional adicional que se pasará al script de resultados de pago, así como a las páginas de devolución"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL a la que el cliente regresará después del pago exitoso; si no se especifica, se toma de la configuración de la tienda"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL donde el cliente regresará después de un error; si no se especifica, se toma de la configuración de la tienda"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL donde se enviará la notificación de los resultados del pago; si no se especifica, se toma de la configuración de la tienda"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["duración de la factura (en minutos), por defecto 60"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["firmar un mensaje usando la clave secreta de la tienda"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0 o 1"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 - si tiene éxito, 0 - en caso contrario"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["entero"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["código de error (0 si no hay error)"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cadena"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["texto de error (o explicación del estado del pago)"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0 o 1"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["una señal de que aún no se conoce el estado del pago final"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["código de pago de la tienda"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["número de transacción"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protocolo del Comerciante"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La aceptación de pagos en el sitio web solo es posible para las tiendas en línea registradas en el sistema. Para registrar una tienda, vaya a la página"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrar tienda"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redirección del usuario al comerciante para el pago"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para realizar un pago, es necesario redirigir al usuario a la URL: https://merchant.[su dominio] y pasar los siguientes parámetros a través de GET o POST:"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descripción"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de datos"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firma"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["se calcula concatenando los parámetros transmitidos y haciendo hash mediante el método hmac con una clave secreta. El número y orden de los parámetros concatenados depende del parámetro"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["identified"])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["monto fijo"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["el orden es el siguiente:"])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ext_transact + num_shop + keyt_shop + identified + sum + payform + comment + free_param + url_success + url_decline + url_callback"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para un enlace donde el cliente"])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pago, el orden es el siguiente (el monto no se incluye en la firma):"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ext_transact + num_shop + keyt_shop + identified + payform + comment + free_param + url_success + url_decline + url_callback"])},
  "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El enlace de pago se puede generar en la página"])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'Mis tiendas'"])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ejemplo de formulario:"])},
  "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retorno al sitio web de la tienda después de completar la operación"])},
  "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si el pagador rechaza el pago o la operación se completa con un error, el sistema de pago sugiere ir a la página apropiada especificada en la configuración de la tienda o pasada con el formulario"])},
  "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["url_decline"])},
  "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si la operación se completa con éxito, ir a la página"])},
  "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["url_success"])},
  "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estos scripts también recibirán los resultados del pago (por el método especificado en la configuración de la tienda. Por defecto - GET):"])},
  "62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
  "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descripción"])},
  "64": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de datos"])},
  "65": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firma"])},
  "66": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["se calcula concatenando los parámetros transmitidos y haciendo hash mediante el método hmac con una clave secreta."])},
  "67": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orden de los parámetros concatenados:"])},
  "68": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ext_transact + amount + success + result + result_text + free_param"])},
  "69": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notificación del estado del pago de la tienda (callback)"])},
  "70": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Después de la ejecución de un pago, los resultados del pago se envían al script especificado durante el registro de la tienda"])},
  "71": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["result_url"])},
  "72": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este script recibe datos tanto para pagos exitosos como para casos de error."])},
  "73": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estos scripts también recibirán los resultados del pago (por el método especificado en la configuración de la tienda. Por defecto - GET)."])},
  "74": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En algunos casos, la notificación con los resultados no llega (por ejemplo, si el cliente fue a pagar y cerró la página del navegador)."])},
  "75": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
  "76": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descripción"])},
  "77": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de datos"])},
  "78": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firma"])},
  "79": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["se calcula concatenando los parámetros transmitidos y haciendo hash mediante el método hmac con una clave secreta."])},
  "80": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orden de los parámetros concatenados:"])},
  "81": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["transact + ext_transact + amount + success + result + result_text + free_param"])},
  "82": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cálculo de la firma"])},
  "83": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La firma se forma concatenando los parámetros necesarios en el orden especificado y haciendo hash mediante el método hmac."])},
  "84": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El orden y número de parámetros concatenados se describen arriba."])},
  "85": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las siguientes listas muestran ejemplos de funciones que calculan la firma en diferentes lenguajes, donde la cadena concatenada a firmar se pasa como parámetro"])},
  "86": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista 1. Cálculo de la firma en PHP."])},
  "87": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista 2. Cálculo de la firma en Python."])},
  "88": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista 3. Cálculo de la firma en Perl."])},
  "89": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para pagos con"])},
  "90": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["puede cambiar el monto"])},
  "91": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pago del producto"])},
  "92": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagar"])},
  "93": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generar firma con clave"])},
  "94": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["signed_str"])},
  "95": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["y la frase secreta en el parámetro"])},
  "96": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["key"])}
}