export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["चुनें"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["देने के लिए"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्राप्त करने के लिए"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संपत्ति"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["देता हूँ"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आंतरिक"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्राप्त करता हूँ"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आंतरिक संपत्तियाँ"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संपत्तियाँ नहीं मिलीं"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बाहरी संपत्तियाँ"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बाहरी"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["चयनित:"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आंतरिक"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बाहरी"])}
}