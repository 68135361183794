export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Құжаттама"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сатушы протоколы"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сатушыға қайта бағыттау"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дүкенге оралу"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дүкен хабарламасы"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қолтаңба есептеу"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кірістірілген виджет"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Орнату"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шақыру"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дүкеннен жауап"])}
}