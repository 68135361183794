export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dados de ativos criptográficos"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereço do problema:"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome:"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relógio:"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permissões"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propósito/Missão/Propósito"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Características financeiras"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precisão:"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contatos"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emissão:"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pessoa responsável:"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emissor:"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custo unitário:"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segurança:"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compras em caixas eletrônicos:"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendas em caixas eletrônicos:"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transações com ativos em bolsa:"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transações de ativos via P2P:"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereço de hospedagem"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereço do Livro Branco"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ofertante"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outros contatos"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ir"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emissor"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emissão máxima:"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outro"])}
}