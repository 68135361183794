export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demande de transaction"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offre"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendeur"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Délai"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps d'attente des actions des parties"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous donnez"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous recevez"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demander une transaction"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un compte"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte non sélectionné"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le montant n’entre pas dans la fourchette :"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["min"])}
}