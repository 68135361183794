export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ट्रांसफर खाता:"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["राशि:"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टिप्पणी:"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["डील प्रोटेक्शन"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोड:"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जनरेट करें"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रोटेक्शन अवधि:"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दिन"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टेम्प्लेट नाम:"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सहेजें"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टेम्प्लेट के रूप में सहेजें"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ट्रांसफर"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टेम्प्लेट्स"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दूसरे उपयोगकर्ता को ट्रांसफर"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रोटेक्शन कोड वह कोड है जिसे प्राप्तकर्ता को डील पूरी करने के लिए दर्ज करना होगा। संरक्षित ट्रांसफर प्राप्तकर्ता को तुरंत ''ऑपरेशन इतिहास'' में दिखाई देगा, लेकिन वह राशि का उपयोग तभी कर सकता है जब वह ट्रांसफर कार्ड में प्रोटेक्शन कोड दर्ज करेगा। यदि भुगतान की अवधि समाप्त हो जाती है, तो राशि भेजने वाले को वापस कर दी जाती है।"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ट्रांसफर पता"])}
}