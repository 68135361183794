export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de réception"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de traitement"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de paiements"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["y compris réussis"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["y compris échoués"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiements de masse"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultat"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fournisseur de services"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commission"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1er paramètre"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun paiement trouvé"])}
}