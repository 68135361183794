export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moje aktiva"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Účet"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Externí aktivum"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skupina"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název EN"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synonyma"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Země"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokud je vyplněno, nabídka bude dostupná pouze uživatelům z uvedených zemí"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vázat na aktivum"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jednotky"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zkráceně"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přesnost"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popis"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Údaje"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Směr převodu"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limit"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čekací doba (min)"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reklama je aktivní"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostupné pouze po odkazu"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostupné pouze ověřeným uživatelům"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zpět"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umístit"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Další"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Směr"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Omezení"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvoření nabídky"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vlastní externí aktivum"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nepřipoutávat"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ano"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snížit kurz"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vrátit zpět"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neexistuje účet v takovém aktivu"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořit účet"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uveďte kurz"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurz je správný"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snížit kurz"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vrátit zpět"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum je větší než maximum"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kam a jak chcete obdržet převod. Například: Převést v bankovní aplikaci na telefonní číslo +499990001111 (příjemce Alexander N.)"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přenáším"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Příjem"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiva"])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Požadované údaje"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaké údaje musí taker zaslat, abyste mu mohli provést převod"])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte aktivum"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte aktivum"])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Co chcete získat: prostředky ve vnitřním aktivu, například BTC, nebo ve vnějším, například TOKEN GOLD?"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Napište komentář o vlastnostech této transakce, například: \\\"Vnější aktivum je předáváno pouze při osobním setkání\\\""])},
  "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ hodnot, do kterých patří externí aktivum, například „Cash“"])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název aktiva pro zobrazení na platformě"])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivum může být také nazýváno slovy ze seznamu"])},
  "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porovnání s existujícím aktivem"])},
  "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Například: litry, kusy, hodiny, krabice"])},
  "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Například: l, ks, h, kr"])},
  "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kolik čísel za desetinnou čárkou může mít hodnota aktiva"])},
  "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vybrali jste vytvoření aktiva, které ještě nebylo vytvořeno, prosím, vyplňte jeho charakteristiky"])},
  "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čas, který mají strany na splnění podmínek"])},
  "62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vnitřní aktivum je aktivum, které existuje v systému \\\"New Reality\\\". Vnější aktivum je aktivum, které neexistuje v systému \\\"New Reality\\\"."])},
  "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Není přiřazen"])},
  "64": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Očekávaná hodnota: od 1 do 2147483"])}
}