export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altes Passwort:"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Passwort:"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwortbestätigung:"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Passwort:"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwortbestätigung:"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"Neues Passwort\" und \"Passwortbestätigung\" stimmen nicht überein."])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort darf keine kyrillischen Buchstaben enthalten"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Bestätigungspasswort erstellen"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ändern Sie das Bestätigungspasswort"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zur Hauptseite"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeichenfolge von 4 bis 65 Zeichen. Erlaubte Zeichen sind lateinische Buchstaben, Zahlen und Symbole"])}
}