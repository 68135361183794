export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název:"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název je úplný název kryptoaktiva, jak jej uvidí každý: samotný emitent, kupující, prodávající, zpracování atd."])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TIKER"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zkratka 3 až 10 znaků. \nJsou povolena velká písmena latinky, pomlčky (pomlčky) a čísla."])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie:"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte kategorii, která nejlépe odpovídá vaší kryptoaktivitě"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podporované formáty: png, jpg, jpeg, svg. \nVelikost souboru ne více než 1 MB, 500*500 pixelů"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popište, proč je tato cryptoasset emitována emitentem, v jakém odvětví působí nebo bude působit, jaké je její poslání"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo:"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Účel / mise / určení:"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přesnost:"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emise:"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hodnota jednotky:"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počet desetinných míst pro částku. Například přesnost většiny fiat měn, jako je americký dolar nebo euro, je 2."])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počet vydaných jednotek kryptoakcií.Celková částka emise bude okamžitě k dispozici na vašem účtu po dokončení transakce vytvoření kryptoaktiv."])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deklarovaná hodnota jednotky:"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zajištění:"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Účet:"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osoba nesoucí závazky:"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rozhodnutí o emisi kryptoaktivity:"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportovat operace do blockchainu:"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ blockchainu:"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Povolit operace s kryptoaktivitou:"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Omezení/Sankce:"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uveďte, kteří občané nebo daňoví rezidenti kterých zemí z výše uvedeného seznamu mají zakázáno používat kryptoaktiva"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Další omezení a podmínky:"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresa hostingu:"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresa bílé knihy:"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail:"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telegram:"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twitter:"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook:"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Další kontakty:"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zpět"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložit"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Další"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořit aktivum"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název a účel"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finanční charakteristiky"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emitent"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export do blockchainu"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limity a omezení"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakty"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neurčeno"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixní vazba na fiat měny a další aktiva"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emitent (Osoba, která vydává kryptoaktivitu)"])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nabízející (Osoba, která distribuuje kryptoaktivitu)"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odesílatel"])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Příjemce"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Já (zapnout sponzorství)"])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umístění kryptoaktivity"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kryptoaktivita znamená digitální vyjádření hodnoty nebo práva, které může být přeneseno a uloženo elektronicky pomocí technologie blockchainu. Kryptoaktiva NEzahrnují fiat měny, cenné papíry a jiné finanční nástroje a aktiva, které podléhají zvláštní státní regulaci."])},
  "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upravit krypto aktivum"])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Správa"])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přijetí"])},
  "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Důvod odmítnutí přijetí"])},
  "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Účet navrhovatele"])},
  "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Účet navrhovatele v závazné měně"])},
  "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navrhovatel je zástupcem emitenta aktiva v nákupních/prodejních transakcích na primárním trhu. \nÚčet je pasivní. \nMůže to být účet pro vydání aktiv"])},
  "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Účet je pasivní, propojený v měně. \nMusí patřit stejnému uživateli jako předchozí účet"])},
  "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umožnit uživatelům nákup aktiv v bankomatech"])},
  "62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Povolte prodej aktiva uživateli na bankomatech"])},
  "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poprodejní formulář nákupu"])},
  "64": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transakce na sekundárním trhu se provádějí prostřednictvím bran"])},
  "65": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poprodejní formulář prodeje"])},
  "66": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transakce na sekundárním trhu se provádějí prostřednictvím bran"])},
  "67": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Povolit transakce s aktivem na burze"])},
  "68": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Povolit transakce aktiv prostřednictvím P2P"])},
  "69": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokud jste samostatný podnikatel nebo zástupce právnické osoby, musíte si stáhnout soubor rozhodnutí o vydání digitálního majetku ve formátu PDF. \nŘešení musí obsahovat všechny hlavní charakteristiky virtuálního majetku a být podepsáno elektronickým podpisem jednotlivého podnikatele nebo této právnické osoby. \nPříklad rozhodnutí o vydání digitálního aktiva naleznete na stránkách našeho webu v sekci \\\"Dokumenty\\\""])},
  "70": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umístění virtuálního majetku"])},
  "71": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Náhradní účet vydání"])},
  "72": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte z účtů vydavatelů"])},
  "73": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Částka k odepsání:"])},
  "74": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádný účet"])},
  "75": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořte si účet"])},
  "76": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max. \nemise:"])},
  "77": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velikost emise přesahuje max. \nemisí"])},
  "78": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max. \nemise je menší než velikost emise nebo se rovná nule"])},
  "79": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formy chybí"])}
}