export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta a recargar"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Método de pago"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de la plantilla:"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar plantilla"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plantillas"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recarga de cuenta"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalles de transferencia"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificar transferencia"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información sobre transferencias"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instrucción"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recarga de dirección"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección a recargar"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportar"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A la lista de tareas"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección de transferencia"])}
}