export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活动"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["资产描述"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日期"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有广告"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的广告"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的广告"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新广告"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活动"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活动"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["非活动"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["方向"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["外部资产"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["内部资产"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["描述"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["给出"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["接收"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["限额"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发布日期"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["账户余额"])}
}