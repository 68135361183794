export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activos externos"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vinculado al activo"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unidades de medida"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupo"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de creación"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autor"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acciones"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Período desde"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Período hasta"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtros"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restablecer"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizar"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay activos"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoy"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ayer"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semana"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Año"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vinculado al activo"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupo"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autor"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actividad"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Países"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activo"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactivo"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar activo externo"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar activo externo"])}
}