export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abholung am Geldautomaten"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sammlung hinzufügen"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktionen"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurücksetzen"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualisieren"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abgebrochene Sammlungen nicht anzeigen"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punktnummer"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Sammlungen"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inkassoantrag eingereicht"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sammlung bearbeiten"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sammlungsstatus ändern"])}
}