export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorlagen"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auffüllung"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überweisung"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auszahlung"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorlagenname"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betreiber"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felder"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operationen"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Vorlagen"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empfänger"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie die Vorlage Nr. wirklich löschen"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorlage umbenennen"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umbenennen"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwenden"])}
}