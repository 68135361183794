export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kód obchodu"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dávám"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostávám"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Směr"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurz"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stav"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timeout"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akce"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádné aktivní obchody"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moje obchody"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivní"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vnitřní aktivum"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neaktivní"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korespondent"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kód"])}
}