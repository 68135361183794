export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numer kasety"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identyfikator kasety"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numer kolekcji kasety"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ocena kasety"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Załadowany"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suma"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serwer"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilość"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suma"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bankomat"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suma"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faktycznie"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilość"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suma"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentarz do kasety"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak danych do wyświetlenia"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kolekcja urządzeń"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ważny"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["C"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przez"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["szczątki są wynoszone"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serwer"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liczba rachunków"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suma"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bankomat"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liczba rachunków"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suma"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentarz"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szafarka"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odrzuć/Wycofaj"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akceptant rachunku"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odrzuć karty"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karty"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aktualny"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rozładowany"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salda kolekcji bankomatów"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kolekcja urządzeń"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ważny"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kaseta zainstalowana w bankomacie"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["schwytany"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["szczątki są wynoszone"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["szczątki są wynoszone"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pusta kaseta:"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["przyczepność:"])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodatkowe informacje:"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["do tej pory"])}
}