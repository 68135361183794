export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear un par de divisas"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pareja"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurar una actualización"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puerta"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moneda dada"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moneda recibida"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añadir beneficio al curso, %"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de actualización del curso"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duración del curso, segundos"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intervalo de actualización del curso, segundos"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bien"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Para qué fecha es válido el curso?"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alias ​​de la moneda dada"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alias ​​de la moneda recibida"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puerta"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es necesario elegir una moneda y, además, solo una: entregada o recibida."])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto enviado * Tarifa = Monto recibido"])}
}