export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["transakcja "])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametry ogólne"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmiana salda na kontach"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na koniec operacji:"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na początku operacji:"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodatkowe parametry"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operacje w blockchain"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Callback"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Następna próba:"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czas"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odpowiedź"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ochrona transakcji"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czas ochrony:"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kod ochrony:"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potwierdź"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametry szablonu"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa szablonu :"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anuluj operację"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powtórz operację"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz szablon"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zamknij"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numer transakcji"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operacja"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korespondent"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data płatności"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kwota"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prowizja"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentarz"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wynik"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numer formularza płatności"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przychód/Wydatek"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmiana salda adresów"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potwierdź zaliczenie"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zwróć pieniądze nadawcy"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podejrzana operacja"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opcje działań:"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komisja odbiorcy"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komisja płatnika"])}
}