export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Інформація про оголошення"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Основна інформація"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Основне"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додатково"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата створення"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активно"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прийняти"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата початку угоди"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата закінчення угоди"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курс"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мейкер"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["віддає"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["отримує"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступно тільки за посиланням"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступно тільки верифікованим користувачам"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ліміти"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Власник оголошення"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опис"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Таймаут"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["днів"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["годин"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["хвилин"])}
}