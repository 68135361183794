export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actividad"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descripción del activo"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay anuncios"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mis anuncios"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mis anuncios"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuevo anuncio"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actividad"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activo"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactivo"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todo"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activo externo"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activo interno"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descripción"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intercambio"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceptando"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Límites"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de publicación"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo en cuenta"])}
}