export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Волшебное слово - это дополнительная настройка безопасности."])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если волшебное слово включено, то каждый раз при авторизации система будет просить вас ввести какие-то символы (по их порядковому номеру) из волшебного слова."])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Волшебное слово:"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Спрашивать волшебное слово:"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["При входе в кабинет:"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["При оплате через мерчант:"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["При использовании карты:"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Волшебное слово"])}
}