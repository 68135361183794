export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activité"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description de l'actif"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune annonce"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes annonces"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes annonces"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle annonce"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activité"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actif"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactif"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direction"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actif externe"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actif interne"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échange"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reçois"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limites"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de publication"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solde du compte"])}
}