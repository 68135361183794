export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moje obchody"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obchody nenalezeny"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořit widget kód"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořit platební odkaz"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upravit"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smazat obchod"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opravdu chcete smazat obchod?"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrovat obchod"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kód"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Režim plateb"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivita"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přijetí"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akce"])}
}