export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пополняемый счет"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Способ платежа"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название шаблона:"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить шаблон"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продолжить"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаблоны"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пополнение счета"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Реквизиты перевода"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проверить перевод"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Информация о переводах"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Инструкция"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пополнение адреса"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пополняемый адрес"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Экспорт"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["К списку задач"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес перевода"])}
}