export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Měnové páry"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořit měnový pár"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upravit měnový pár"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smazat měnový pár"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akce"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadaná měna"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přijatá měna"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brána"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ aktualizace"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Měnové páry nenalezeny"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opravdu chcete smazat měnový pár"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["propojeno s jinou měnou"])}
}