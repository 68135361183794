export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العنوان"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحساب"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرصيد"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحالة"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات إضافية"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإجراءات"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نشط"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إيداع"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سحب"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء عنوان جديد"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء حساب جديد"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حذف المحدد"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أصولي"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قائمة الحسابات"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجميع"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الحساب"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل تريد حقًا حذف الحسابات؟"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل تريد حقًا حذف الحساب؟"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم يتم العثور على أصول"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم يتم العثور على حسابات"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعادة تسمية العنوان"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل تريد حقًا حذف العناوين؟"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل تريد حقًا حذف العنوان؟"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غير إلزامي"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فيات"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العمليات"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإجراءات"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اشحن رصيدك"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ينسحب"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تبادل"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يخفي"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يتغير"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمسح"])}
}