export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жаппай төлемдер қызметі басқа пайдаланушыларға жылдам ақша аударуға және қызмет көрсетушілерге төлем операцияларын орындауға мүмкіндік береді."])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бөлімде"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Төлем файлын жүктеу"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excel форматындағы (XLS, XLSX, CSV) немесе XML файлды жүктей аласыз."])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тапсырмалар тізімі"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["бұрын жасалған жаппай төлем сұраулардың статустарын көруге мүмкіндік береді. "])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Файлды Excel кестелік редакторында жасауға болады. "])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Файл үлгісін жүктеу"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Маңызды ескертулер:"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Файл форматы - CSV, XLS немесе XLSX."])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Файл тақырыпсыз болуы керек."])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ұзын сандар экспоненталық форматқа (мысалы, \"5,46546E+15\") айналмауы және жетекші нөлдер кесілмеуі үшін, санның алдына бір тырнақша (') таңбасын қоюға болады немесе деректерді енгізу алдында ұяшыққа мәтіндік форматты орнатуға болады."])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қызмет көрсетушілерге төлемдер үшін баған форматтары"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Файлдағы бағандар тізімі:"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оператор коды"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Мұнда көрсетілген операторлар тізімінен көрсетіледі"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["мұнда"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". (Барлық ұялы байланыс операторлары үшін оператор коды ретінде 7000 көрсетуге болады - бұл жағдайда оператор (МТС, Билайн, Теле2 және т.б.) телефон нөмірі бойынша автоматты түрде анықталады.)"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сома"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Сома аударылуы тиіс активте көрсетіледі. Егер контрагентте мұндай актив болмаса, транзакцияға жүйені түрлендіру коэффициенті автоматты түрде қолданылады."])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Төлемге түсініктеме"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Міндетті емес, бос қалдыруға болады; ұзындығы 255 символдан аспайды."])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1-параметр"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". \nОператор талап ететін параметрдің мәні, мысалы, телефон нөмірі немесе крипто-өзекті мекен-жайы көрсетілген. \nКөптеген операторлар бір төлем параметрімен шектелген, бірақ егер параметрлер саны бірнеше болса, онда сіз олардың барлығын көрші бағандарда көрсетуіңіз керек."])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2-параметр"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N-параметр"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оператордың төлем параметрлерін көруге болады, бір операторға төлем жасау арқылы:"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пайдаланушыларға жаппай аударымдар үшін баған форматтары"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оператор коды"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Мұнда 0 көрсетуге немесе мүлде көрсетпеуге болады."])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сома"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Сома ол аударылған активте көрсетіледі. Егер контрагентте мұндай актив болмаса, жүйенің конверсия коэффициенті транзакцияға автоматты түрде қолданылады."])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Төлемге түсініктеме"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Міндетті емес, бос қалдыруға болады; ұзындығы 255 символдан аспайды."])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Алушының шоты"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Мұнда пайдаланушының шот нөмірі енгізіледі, ол үшін аударым жасалады (20 сан)."])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кредит агенті арқылы жаппай несие беру үшін баған форматтары"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Бұл жол төлемдердің несие екенін көрсетеді."])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сома"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Сома кредиттік өнімнің валютасында көрсетіледі."])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Несиеге түсініктеме"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Міндетті емес, бос қалдыруға болады; ұзындығы 255 символдан аспайды."])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қарыз алушының ID"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Назар аударыңыз, қарыз алушының Mfo-BorrowPerson сандық паспорты болуы керек."])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кредиттік өнімнің коды"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Мұны несие беру өнімдерінің тізімінде көруге болады."])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мерзім"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Тек мерзімдердің саны (нақты қандай мерзімдер қолданылатыны кредиттік өнімде көрсетілген, мысалы, бұл күндер, апта, айлар және т.б. болуы мүмкін)"])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оператор коды (форма нөмірі)"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Бұл және келесі өрістерді толтыру міндетті емес, егер олар толтырылмаса, қарыз алушы кейінірек өз жеке кабинетінде несиені алу транзакциясын өз бетінше жүзеге асыра алады."])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қате болған жағдайда бас тарту"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Қате болған жағдайда (1) берілген несиені бас тарту немесе кейінгі өз бетінше алу үшін қалдыру (0)."])},
  "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1-параметр"])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Оператор талап ететін параметрдің мәні енгізіледі, мысалы, телефон нөмірі. Көптеген операторлар бір төлем параметрімен шектеледі (ұялы байланыс операторлары, интернет, коммерциялық ТВ және т.б.), бірақ егер параметрлер саны біреуден көп болса, олардың барлығын бірінші бағаннан кейінгі бағандарға енгізу қажет."])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2-параметр"])},
  "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N-параметр"])},
  "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Файл алушылардың крипто-активтерінің шоттары немесе мекен-жайы, бір валютадағы алғышарттардың шоттары немесе мекен-жайы көрсетілуі керек, өйткені қаражаттан есептен шығару бір шоттан алынады, ал бірнеше есебінен емес."])}
}