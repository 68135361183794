export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сандық паспорт"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Толығырақ"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пайдаланушы ID:"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тіркелген күні:"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Соңғы кіру:"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Менің активтерім"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шоттар"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Барлығын көру"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Толықтыру"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шығару"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шоттар жоқ"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Екінші құпия сөз"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакцияларды растау үшін сізге екінші құпия сөзді пайдалану қажет."])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Үлгілер"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Барлығы"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сома"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Танымал төлемдер"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жаңалықтар"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жабу"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Келесі..."])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активтер жоқ"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жасау"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жасау"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Баланс"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Алып тастау"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Толықтыру"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Алмасу"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пайдаланушыға"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жалпы баланс"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Көрсету"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фиат емес баланс"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiat балансы"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жаңа ақпарат"])}
}