export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezpečnost"])},
  "Antiphishing": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastavení ochrany proti phishingu je dodatečný mechanismus ochrany při práci s účtem."])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vámi nastavená uvítací fráze se bude zobrazovat v horní části každé stránky účtu."])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Správná fráze vám ukáže, že jste na správné stránce, nesprávná fráze je signálem, že je třeba okamžitě kontaktovat bezpečnostní službu našeho systému, aniž byste prováděli jakékoliv operace."])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapnout ochranu proti phishingu:"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ochranná fráze proti phishingu:"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložit"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anti-Phishing"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Změny vstoupí v platnost po další autorizaci"])}
  },
  "IP": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastavení přístupu podle IP adresy vám (nebo útočníkovi) neumožní pracovat se systémem z adresy, která není uvedena v seznamu povolených."])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Omezení přístupu k účtu podle IP adres"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidat adresy"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresa: "])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidat"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blok adres: "])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["do:"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaše IP: "])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seznam adres"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["č."])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Od adresy"])},
    "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do adresy"])},
    "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přístup"])},
    "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akce"])},
    "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odstranit"])},
    "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádné adresy nenalezeny"])},
    "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložit"])},
    "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zakázat"])},
    "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Povolit"])},
    "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadejte IP adresu"])},
    "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP adresa je nesprávná"])},
    "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Není zadána žádná IP adresa nebo blok adres."])},
    "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádná IP adresa není povolena pro autorizaci. Nebudete mít přístup ke svému účtu."])},
    "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přístup podle IP"])},
    "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["od:"])}
  },
  "Magic": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kouzelná slova jsou dodatečné bezpečnostní nastavení."])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokud je kouzelné slovo zapnuto, při každé autorizaci vás systém požádá o zadání některých symbolů (podle jejich pořadí) z kouzelného slova."])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kouzelné slovo:"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Požadovat kouzelné slovo:"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Při přihlášení do účtu:"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Při platbě přes obchodníka:"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Při použití karty:"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložit"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kouzelné slovo"])}
  },
  "Onekey": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jednorázové klíče výrazně zvyšují bezpečnost systému."])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Při přihlášení do programu budete požádáni nejen o zadání přihlašovacího jména a hesla, ale také o zadání jednorázového klíče. Aktivace probíhá postupně. Poslední klíč je platný, dokud nevytvoříte novou sérii klíčů."])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Použít jednorázové klíče"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seznam klíčů"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generovat"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["č."])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klíč"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jednorázové klíče"])}
  },
  "Password": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Staré heslo:"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nové heslo:"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrzení hesla:"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložit"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["''Nové heslo'' a ''Potvrzení hesla'' se neshodují."])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Změna hesla"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Řetězec od 4 do 63 znaků. Povolené znaky jsou latinské písmena, číslice a symboly"])}
  },
  "PayPassword": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Staré heslo:"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nové heslo:"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrzení hesla:"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nové heslo:"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrzení hesla:"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložit"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložit"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["''Nové heslo'' a ''Potvrzení hesla'' se neshodují."])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heslo nesmí obsahovat azbuku"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořit potvrzovací heslo"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Změnit potvrzovací heslo"])},
    "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na hlavní stránku"])},
    "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Řetězec od 4 do 65 znaků. Povolené znaky jsou latinské písmena, číslice a symboly"])}
  },
  "SecretQuestion": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V případě, že zapomenete heslo pro přístup k účtu, můžete jej obnovit odpovědí na tajnou otázku."])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V této sekci můžete změnit tajnou otázku/odpověď."])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tajná otázka"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaše otázka:"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odpověď:"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložit"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tajná otázka"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vlastní otázka"])}
  }
}