export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सूचना सेटिंग"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सक्षम करें"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ऑपरेशनों के बारे में सूचित करें"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रमाणीकरण (व्यक्तिगत कैबिनेट, मोबाइल एप्लिकेशन आदि में प्रवेश)"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"कार्ड के माध्यम से वित्तीय ऑपरेशनों का निष्पादन\" घटना पर सूचित करें"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खर्च (कोई भी भुगतान, ट्रांसफर, सेवा भुगतान आदि)"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नकदी की प्राप्ति"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आंतरिक मेल द्वारा पत्र की प्राप्ति"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भुगतान के लिए बिल की प्राप्ति"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सुरक्षा सेटिंग्स का परिवर्तन"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संदेश से कोड द्वारा ऑपरेशनों की पुष्टि करें"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संदेश से कोड द्वारा प्रमाणीकरण की पुष्टि करें"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"कार्ड के माध्यम से वित्तीय ऑपरेशनों का निष्पादन\" की घटना पर पुष्टि करें"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संदेश से कोड द्वारा सुरक्षा सेटिंग्स के परिवर्तन की पुष्टि करें"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संदेश से कोड द्वारा खर्च की पुष्टि करें"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["परिवर्तन सहेजे नहीं गए, सहेजें?"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सहेजें"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सहेजें नहीं"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सहेजें"])}
}