export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أجهزة الصراف الآلي"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة جهاز"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الجهاز"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعليق"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعدادات"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أضف كاسيت"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الكاسيت"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعليق"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعدادات"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قم بإزالة الجهاز"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قم بإزالة الكاسيت"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل تريد حذف الجهاز؟"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمسح"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حذف الكاسيت؟"])}
}