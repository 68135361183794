export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier l'actif externe"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupe"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synonymes"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lier à l'actif"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unités de mesure"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abréviation"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Précision"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actif"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepté pour négociation"])}
}