export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conto"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni aggiuntive"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azioni"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attivo"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrata"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uscita"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea nuovo indirizzo"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea nuovo conto"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina selezionati"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I miei asset"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista dei conti"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutto"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome del conto"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vuoi davvero eliminare i conti?"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vuoi davvero eliminare il conto?"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun asset trovato"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun conto trovato"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rinomina indirizzo"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vuoi davvero eliminare gli indirizzi?"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vuoi davvero eliminare l'indirizzo?"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operazioni"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azioni"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricaricare"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ritirare"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scambio"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nascondere"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminare"])}
}