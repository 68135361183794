export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Налаштування сповіщень"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Увімкнути"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сповіщати про операції"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Авторизація (вхід до кабінету, мобільного додатку тощо)"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сповіщати при здійсненні \"Фінансових операцій за допомогою картки\""])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Витрати (будь-яка оплата, переказ, оплата послуг тощо)"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отримання коштів"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отримання внутрішнього повідомлення"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отримання рахунку до сплати"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зміна налаштувань безпеки"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Підтвердження операцій кодом повідомлення"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Підтвердження авторизації кодом повідомлення"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Підтвердження при здійсненні \"Фінансових операцій за допомогою картки\""])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Підтвердження зміни налаштувань безпеки кодом повідомлення"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Підтвердження витрат кодом повідомлення"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зміни не збережено, зберегти?"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберегти"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не зберігати"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберегти"])}
}