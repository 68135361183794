export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طلب الصفقة"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العرض"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البائع"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المهلة الزمنية"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وقت انتظار الإجراءات من الأطراف"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السعر"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعطي"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تستلم"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طلب الصفقة"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء حساب"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم يتم اختيار الحساب"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المبلغ"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المبلغ خارج النطاق: "])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دقيقة"])}
}