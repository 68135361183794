export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["标志"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名称"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["代码"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["类别"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使命"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加密资产列表"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无加密资产"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["代币发行"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["接受"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["市场"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["精度"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主要"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["次要"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["与其他资产配对"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加密资产详细信息"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编辑加密资产数据"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["额外发行"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除加密资产"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加密资产列表"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["接受"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活动"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["否"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可以在自动取款机购买代币"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可以在自动取款机出售代币"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["类别"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["二级市场代币"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["代币显示"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全部已接受"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全部我的"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户UID"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["资产销毁"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["针对特定用户"])}
}