export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вбудований віджет"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Встановлення"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Підключити скрипт на сторінку в розділ head через CDN або завантаживши як файл"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виклик"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Викликати функцію"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["передавши як аргумент JS об'єкт"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опис"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип даних"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приклад"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["id для iframe"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["name для iframe"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["об'єкт з параметрами для платежу"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["функція обробник успіху операції"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["функція обробник неуспіху операції"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адреса платіжного сервісу"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нижче"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ще нижче"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повернення на сайт магазину після завершення операції"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Після завершення операції або відмови, віджет буде закрито. Об'єкт з параметрами нижче буде передано в callback onFail або onSuccess:"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оплатити"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дії при успіху"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дії при неуспіху"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адреса платіжного сервісу"])}
}