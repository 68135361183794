export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сіз бір уақытта бірнеше төлем жасай аласыз. \nОл үшін алдымен «XLS», «XLSX», «CSV» форматындағы төлемдердің сипаттамасы бар файлды жасау керек."])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Файлды талдау"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Корреспондент"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сома"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Түсініктеме"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Параметр"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Файл туралы ақпарат"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Барлық төлемдер: "])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["жалпы сомасы: "])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Төлем файлы"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шотты шығару"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Төлемдерді жүргізу"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жалғастыру"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Төлем файлын жүктеу"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Файл мазмұнын тексеріңіз"])},
  "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жаппай төлемдер мен аударымдар"])}
}