export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["嵌入式小部件"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["安装"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通过CDN或下载文件将脚本连接到页面的head部分"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["调用"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["调用函数"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["传递JS对象作为参数"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名称"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["描述"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["数据类型"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["示例"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iframe的id"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iframe的name"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["带支付参数的对象"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作成功处理函数"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作失败处理函数"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付服务地址"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下面"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更下面"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作完成后返回商店网站"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作完成或拒绝后，小部件将关闭。带有以下参数的对象将传递给onFail或onSuccess回调："])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成功时的操作"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["失败时的操作"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付服务地址"])}
}