export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地址"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["账户"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["余额"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["状态"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["附加信息"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活跃"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["充值"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提现"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建新地址"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建新账户"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除选定"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的资产"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["账户列表"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全部"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["账户名称"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您确定要删除账户吗？"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您确定要删除账户"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未找到资产"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未找到账户"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重命名地址"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您确定要删除地址吗？"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您确定要删除地址"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["菲亚特"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["运营"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["行动"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["充值"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提取"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交换"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["隐藏"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["改变"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除"])}
}