export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorizzazione"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login:"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password:"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hai dimenticato la password?"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prima volta qui?"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea un account"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recupero password"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefono o e-mail:"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recupera"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recupero password"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cognome:"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome:"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secondo nome:"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di nascita:"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accedi"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indietro"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continua"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trova"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utente trovato"])}
}