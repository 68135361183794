export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оголошення"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опис"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Реквізити"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мінімальна"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Максимальна"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ліміт суми"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Час очікування (хв)"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оголошення активне"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступно тільки за посиланням"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступно тільки верифікованим користувачам"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скасувати"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберегти"])}
}