export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операцияны қайталау"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операция тарихы"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шоттар тізімі"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сәтті"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қате"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Белгісіз қате"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Үлгілер"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Менің активтерім"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жаңа актив туралы ақпарат"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қосымша мәселе"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Блокчейнге сілтеме"])}
}