export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Old password:"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password:"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Password:"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password:"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Password:"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"New Password\" and \"Confirm Password\" do not match."])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password must not contain Cyrillic"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create confirmation password"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change confirmation password"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To the main"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["String from 4 to 65 characters. \nLatin characters, numbers and symbols are allowed"])}
}