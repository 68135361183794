export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodatkowa emisja"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kryptoaktywo"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto - źródło pokrycia"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto debetowe"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodatkowa emisja"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentarz"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontynuuj"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualna emisja"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TOKEN"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obliczanie pokrycia"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prowizja"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prowizja"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obliczanie pokrycia"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powiązanie"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dane podstawowe"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procent pokrycia"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przyszłe pokrycie"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odpis pokrycia"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kwota, która będzie w rezerwie po wydaniu dodatkowej emisji."])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do odpisania"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualne pokrycie"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kwota, która zostanie odpisana z Twojego konta po wydaniu dodatkowej emisji."])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przyszła kwota pokrycia"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E — Aktualna kwota emisji"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I — Dodatkowa emisja"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["K — Kurs powiązania"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["P — Procent pokrycia"])}
}