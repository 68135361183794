export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ішкі актив"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шот"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сыртқы актив"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Топ"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Атауы"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Атауы RU"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Синонимдер"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Елдер"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Егер толтырылса, хабарландыру көрсетілген елдердің пайдаланушыларына ғана қолжетімді болады"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активке сәйкестендіру"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Өлшем бірліктері"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қысқаша"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дәлдік"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Түсіндірме"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Деректемелер"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аударым бағыты"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Минимум"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Максимум"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шектеу"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Күту уақыты (мин)"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жарнама белсенді"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тек сілтеме бойынша қолжетімді"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тек расталған пайдаланушыларға қолжетімді"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Алдыңғыға"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Орналастыру"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Келесі"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бағыт"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шектеулер"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хабарландыру жасау"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Өзінің сыртқы активі"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Байламау"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Иә"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курсты қысқарту"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кері қайтару"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мұндай шот жоқ"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шот жасау"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курсты көрсетіңіз"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курс дұрыс"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курсты қысқарту"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кері қайтару"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Минимум максимумнан үлкен"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аударымды қайда және қалай алғыңыз келеді. Мысалы: Банктің қосымшасында +499990001111 телефон нөмірі бойынша аудару (алушы Александр Н.)"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Беремін"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қабылдау үшін"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активтер"])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сұралатын реквизиттер"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аударымды жасау үшін тейкер қандай реквизиттерді жіберуі керек"])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Актив таңдаңыз"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Актив таңдаңыз"])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не алғыңыз келеді: ішкі актив түрінде қаражат, мысалы, BTC немесе сыртқы актив түрінде, мысалы, TOKEN GOLD?"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бұл мәміленің ерекшеліктері туралы түсініктеме жазыңыз, мысалы: \\\"Сыртқы актив тек жеке кездесу кезінде беріледі\\\""])},
  "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сыртқы активке тиесілі мәндердің түрі, мысалы, «қолма-қол ақша»"])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Платформада көрсетілетін активтің атауы"])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Актив сондай-ақ тізімдегі сөздермен аталуы мүмкін"])},
  "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қолданыстағы активпен сәйкестендіру"])},
  "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мысалы: литрлер, даналар, сағаттар, қораптар"])},
  "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мысалы: л, дана, сағ, қорап"])},
  "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Актив мәндерінде үтірден кейін қанша сан болуы мүмкін?"])},
  "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сіз әлі жоқ активті жасауды таңдадыңыз, өтінеміз, оның сипаттамаларын толтырыңыз"])},
  "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тараптарға шарттарды орындау үшін берілетін уақыт"])},
  "62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ішкі актив деп \\\"New Reality\\\" жүйесінде бар активті айтады. Сыртқы актив - бұл \\\"New Reality\\\" жүйесінде жоқ актив."])},
  "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сәйкестік берілген жоқ"])},
  "64": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Күтілетін құны: 1-ден 2147483-ке дейін"])}
}