export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puoi effettuare più pagamenti contemporaneamente. \nPer fare ciò, devi prima creare un file con una descrizione dei pagamenti nel formato \"XLS\", \"XLSX\", \"CSV\"."])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analizza file"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corrispondente"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commento"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametro"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni sul file"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale pagamenti: "])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["per un importo totale di: "])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File dei pagamenti"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conto di addebito"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esegui pagamenti"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continua"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caricamento file dei pagamenti"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controlla il contenuto del file"])},
  "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagamenti e trasferimenti di massa"])}
}