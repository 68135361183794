export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapytanie transakcji"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oferta"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprzedawca"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timeout"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czas oczekiwania działań stron"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurs"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dajesz"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otrzymujesz"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poproś o transakcję"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz konto"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto nie wybrane"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kwota"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kwota nie mieści się w przedziale:"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["min"])}
}