export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["saldo "])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricevo"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coppia di capitale"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conti"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo di scambio"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trasferimento di mercato - verrà utilizzato il tasso più vantaggioso al momento dell'esecuzione dell'ordine. Attenzione, l'importo da ricevere può cambiare e differire da quello calcolato a seconda degli ordini al momento dello scambio."])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il trasferimento a prezzo fisso avviene secondo l'importo e il tasso specificati. Verrà effettuato un ordine. Se il tasso specificato è molto diverso da quello di mercato, la chiusura dell'ordine potrebbe richiedere molto tempo."])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasso (previsione):"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasso"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasso non definito,"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["si consiglia di impostare il tasso manualmente"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Somma"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usa tutto"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commissione"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commissione"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale:"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al mercato"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(scambio rapido con la migliore offerta)"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasso fisso"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(effettua un ordine con il tasso specificato)"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun conto adatto"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasso"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Variazione in 24 ore"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fluttuazioni in 24 ore"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volume di scambi in 24 ore"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'importo non può essere zero!"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scambia"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per vedere i dati di riepilogo, seleziona una coppia di scambio"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non è possibile scambiare asset identici"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inoltre"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La domanda non è inclusa nella selezione fino a"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restrizioni"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il campo 'Tasso' deve avere un valore diverso da 0"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fondi insufficienti sul conto"])}
}