export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Биржа"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нарық. Жиынтық"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ұқсас ұсыныстар"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Керісінше ұсыныстар"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курс"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Берілді"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Алынды"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Менің тапсырыстарым"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Соңғы мәмілелер"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курс"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Берілді"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Күні мен уақыты"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Күні"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Беремін"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аламын"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курс"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операциялар"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["комиссия"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бас тарту"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қайта қайталау"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ұсыныстар жоқ"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мәмілелер жоқ"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тапсырыстар жоқ"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Толық орындалды"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Өңдеу басталған жоқ"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Өңдеу басталды"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ішінара орындалды"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Толық бас тартылды"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ішінара орындалды және бас тартылды"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Алынды"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Барлық тапсырыстар"])}
}