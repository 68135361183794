export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresa"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Účet"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zůstatek"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stav"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Další informace"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akce"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivní"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vstup"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výstup"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořit novou adresu"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořit nový účet"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odstranit vybrané"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moje aktiva"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seznam účtů"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vše"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název účtu"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opravdu chcete odstranit účty?"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opravdu chcete odstranit účet"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádná aktiva nenalezena"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádné účty nenalezeny"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přejmenování adresy"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opravdu chcete odstranit adresy?"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opravdu chcete odstranit adresu"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non-fiat"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiat"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operace"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akce"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doplňte"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odebrat"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výměna"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skrýt"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přeměna"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vymazat"])}
}