export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Período de"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Período até"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportar"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtros"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redefinir"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atualizar"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantidade"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transação"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parâmetro"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transação"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operação"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correspondente"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantidade"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parâmetros/Comentário"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma operação encontrada"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soma das operações"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receitas:"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" unidades no valor de"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Despesas:"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipos de operações"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagamentos, pagamento de serviços"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferências internas"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagamento de conta"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferências entre contas próprias"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criação de cheques cripto"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ativação de cheques cripto"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outros"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receita/Despesa"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receita"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Despesa"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["= igual"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≠ não igual"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["> maior"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≥ maior ou igual"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["< menor"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≤ menor ou igual"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concluído com sucesso"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Em processamento"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erro"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoje"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ontem"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semana"])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mês"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ano"])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Histórico de operações"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recarregar conta"])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conta não selecionada"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Troca na bolsa"])},
  "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recarregar endereço"])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferências dentro da própria carteira"])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correspondente"])},
  "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operação"])}
}