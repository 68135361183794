export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حساب التحويل:"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المبلغ:"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعليق:"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حماية الصفقة"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرمز:"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["توليد"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مدة الحماية:"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أيام"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم القالب:"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ كقالب"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحويل"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القوالب"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحويل لمستخدم آخر"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رمز الحماية هو رمز يجب على المستلم إدخاله لإكمال الصفقة. سيرى المستلم التحويل المحمي فورًا في قسم ''تاريخ العمليات''، ولكنه سيتمكن من استخدام الأموال فقط بعد إدخال رمز الحماية في بطاقة المعاملة. إذا انتهت صلاحية الدفع، فسيتم إعادة الأموال إلى المرسل."])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان التحويل"])}
}