export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有订单"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重置"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["筛选"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日期"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我给"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我收"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["状态"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["汇率"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["佣金"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重复"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无申请"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最小发送金额"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最大发送金额"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最小接收金额"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最大接收金额"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完成交易的尝试次数"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活跃"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发送货币"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["接收货币"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发送账户"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["接收账户"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["子经销商列表"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未选择账户"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["期间从"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["期间至"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["今天"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["昨天"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["周"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["月"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["年"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未选择日期类型"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["到期"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完成"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["开始"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["哪部分是固定的，哪部分是可变的"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["给出的金额是固定的"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["要接收的金额是固定的"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订单类型"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户想要快速交换最佳对手报价"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["按严格指定的汇率交换"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["状态"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["处理未开始"])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["处理已开始"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["部分完成"])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完全完成"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完全取消"])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["部分完成并取消"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输出资产"])},
  "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入资产"])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日期类型"])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最小金额大于最大金额"])}
}