export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TICKER"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mission"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktionen"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste der Krypto-Assets"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Krypto-Assets"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token-Ausgabe"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akzeptieren"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Markt"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genauigkeit"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primär"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sekundär"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit anderem Asset verknüpft"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detaillierte Informationen zum Krypto-Asset"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krypto-Asset-Daten bearbeiten"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zusätzliche Emission"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krypto-Asset löschen"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste der Krypto-Assets"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akzeptieren"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivität"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nein"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token können am Automaten gekauft werden"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token können am Automaten verkauft werden"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token des Sekundärmarkts"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token-Anzeige"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle akzeptierten"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle meine"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer-UID"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset-Verbrennung"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für bestimmten Benutzer"])}
}