export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daję"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kwota"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otrzymuję"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtry"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odśwież"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kod"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprzedawca"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dajesz"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otrzymujesz"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limity"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Działania"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["transakcji"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transakcja"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak transakcji"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oferty"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korzyść"])}
}