export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kouzelná slova jsou dodatečné bezpečnostní nastavení."])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokud je kouzelné slovo zapnuto, při každé autorizaci vás systém požádá o zadání některých symbolů (podle jejich pořadí) z kouzelného slova."])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kouzelné slovo:"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Požadovat kouzelné slovo:"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Při přihlášení do účtu:"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Při platbě přes obchodníka:"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Při použití karty:"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložit"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kouzelné slovo"])}
}