export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crypto asset data"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Issue address:"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name:"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticker:"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permissions"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purpose/Mission/Purpose"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financial characteristics"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accuracy:"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacts"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emission:"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Person liable:"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Issuer:"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unit cost:"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security:"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ATM purchases:"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sales at ATMs:"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transactions with assets on the exchange:"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset transactions via P2P:"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hosting address"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["White Book Address"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offeror"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other contacts"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Issuer"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum emission:"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])}
}