export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elektronické kontroly"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořte šek"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivita"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořeno"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odepisovací účet"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Součet"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Číslo"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Série"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentář"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akce"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivně"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neaktivní"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádné kontroly"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aktivovat"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Šek"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zavřít"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebyly nalezeny žádné elektronické účtenky"])}
}