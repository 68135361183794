export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bruciatura dell'asset"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo da bruciare"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conto per il rimborso"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brucia"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo da bruciare"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'importo da bruciare non può essere maggiore dell'importo di emissione"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restituzione della copertura"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Da restituire"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo che rimarrà nella riserva dopo la bruciatura dell'asset"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo che verrà restituito sul tuo conto dopo la bruciatura dell'asset"])}
}