export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ancien mot de passe :"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau mot de passe :"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation du mot de passe :"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau mot de passe :"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation du mot de passe :"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"Nouveau mot de passe\" et \"Confirmation du mot de passe\" ne correspondent pas."])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mot de passe ne doit pas contenir de caractères cyrilliques"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un mot de passe de confirmation"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier le mot de passe de confirmation"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À la page principale"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chaîne de 4 à 65 caractères. Les caractères autorisés sont les lettres de l'alphabet latin, les chiffres et les symboles"])}
}