export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мои магазины"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Магазинов не найдено"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать код виджета"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать платежную ссылку"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактировать"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить магазин"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы действительно хотите удалить магазин"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зарегистрировать магазин"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Режим платежей"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активность"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Акцепт"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Действия"])}
}