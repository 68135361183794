export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注册"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["个人账户"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商业账户"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["姓："])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名："])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["父名：（如有："])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名称"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可使用拉丁字母以及连字符和撇号"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bond"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可使用拉丁字母以及连字符、空格和撇号”。"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["James"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可使用拉丁字母，以及连字符、倒逗号、空格、数字和撇号"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Company Ltd."])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电话"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子邮件"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拉丁字母（大写和小写）、数字、符号 _ - . ! $ % ^ * ="])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重复密码"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码不匹配"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返回"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注册"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下一步"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["状态"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户数据"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入正确的电子邮件地址或电话号码。电话号码应以国际格式指定，例如：+499001234567"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您已成功注册"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["现在您可以"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录到您的账户"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的账户将由我们的员工检查，激活结果我们稍后会通知您"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已发送确认注册链接的电子邮件"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["到您的电子邮件地址"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第一次登录后，您将被要求确认电话号码"])}
}