export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szablony"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Znajdź"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["W grupie"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupy płatności"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Płatności"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kwota płatności"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto przelewu"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kwota"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentarz"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szablon"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa szablonu:"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz szablon"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przelej"])}
}