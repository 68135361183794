export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国内资产"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["账户"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["外部资产"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["组"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名称"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名称 RU"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["同义词"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国家"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果填写，广告将仅对指定国家的用户可见"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["与资产挂钩"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["单位"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["简写"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["精度"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["描述"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["细节"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["转账方向"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最低"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最高"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["限制"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["等待时间（分钟）"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["广告已激活"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["仅通过链接可用"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["仅对已验证用户可用"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返回上一页"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发布"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下一步"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["方向"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["限制"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建广告"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自己的外部资产"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不要绑定"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["降低汇率"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["恢复"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" 没有这种资产的账户"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建账户"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["指定汇率"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["汇率正确"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["降低汇率"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["恢复"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最低大于最高"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你想在哪里和如何接收转账。例如：通过银行应用程序将其转移到电话号码+499990001111（收款人：亚历山大·N）"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我给出"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我收到"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["资产"])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请求的详细信息"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["К承兑人应提供哪些详细信息，以便您可以向他转账"])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择资产"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择资产"])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您想获得什么：内部资产中的资金，例如比特币，还是外部资产中的资金，例如托肯戈德？"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请写下这笔交易的特点，例如：\\\"外部资产仅在面对面时转移\\\""])},
  "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["外部资产所属的价值类型，例如“现金”"])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在平台上显示的资产名称"])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["资产也可以用列表中的词语来命名"])},
  "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["与现有资产的匹配"])},
  "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["例如：升，件，小时，箱"])},
  "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["例如：升，件，小时，箱"])},
  "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["资产值中小数点后的位数"])},
  "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您选择创建一个尚不存在的资产，请填写其特征"])},
  "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["给各方履行条件的时间"])},
  "62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["内部资产是指存在于系统\\\"纽瑞尔提\\\"中的资产。外部资产是指不存在于系统\\\"纽瑞尔提\\\"中的资产。"])},
  "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未绑定"])},
  "64": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["期望值：从1到2147483"])}
}