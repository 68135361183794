export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez le statut"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La demande de collecte a été déposée"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demande de recouvrement annulée"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collection approuvée pour exécution"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'encaissement est effectué par le caissier (l'argent est chargé dans des cassettes)"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cassettes remises aux collectionneurs (argent en route)"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collecte d'espèces installée sur le guichet automatique"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suite à une panne, surchargé avec une autre collection"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La collecte a été remplacée par une autre collecte à un distributeur automatique (reste en route)"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les soldes de collecte sont comptabilisés (la collecte est envoyée aux archives)"])}
}