export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Burza"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trh. Souhrn"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podobné nabídky"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protichůdné nabídky"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurz"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dávají"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostávají"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moje objednávky"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poslední obchody"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurz"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dáno"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum a čas"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dávám"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostávám"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stav"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurz"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operace"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["poplatek"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušit"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opakovat"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádné nabídky"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádné obchody"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádné objednávky"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plně dokončeno"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zpracování nezačalo"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zpracování začalo"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Částečně dokončeno"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plně zrušeno"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Částečně dokončeno a zrušeno"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostáváno"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Všechny objednávky"])}
}