export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecionar"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dar"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receber"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ativo"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dar"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["interno"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["receber"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ativos internos"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ativos não encontrados"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ativos externos"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["externo"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecionado:"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internos"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Externos"])}
}