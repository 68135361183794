export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Үлгілер"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Іздеу"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Топта"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Төлем топтары"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Төлемдер"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Төлем сомасы"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ақша аудару шоты"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сома"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Түсініктеме"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ақша аудару схемасы"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Үлгі атауы:"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сақтау"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Үлгіні сақтау"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аудару"])}
}