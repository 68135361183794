export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大宗支付服务允许快速将资金转移到其他用户并执行向服务提供商的支付操作。"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在部分"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加载付款文件"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您可以加载Excel格式（XLS、XLSX、CSV）或XML格式的文件。"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["任务列表"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["允许查看先前创建的大宗支付请求的状态。"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您可以在Excel表格编辑器中创建文件。"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下载示例文件"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重要说明："])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文件格式 - CSV、XLS或XLSX。"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文件必须没有标题。"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["为了防止长数字变成指数形式（例如，“5,46546E+15”）并且不截断前导零，可以在数字前面放置一个单引号(')，或者在输入数据之前将单元格格式设置为文本格式。"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["服务提供商支付的列格式"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文件中的列列表："])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作码"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["。从提供的操作员列表中选择"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["这里"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["。对于所有移动通信运营商，可以指定操作码为7000 - 在这种情况下，将根据电话号码自动确定操作员。"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金额"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金额在进行转账的资产中注明。如果对方没有这种资产，系统的转换率将自动应用于交易。"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付评论"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["。填写不是必须的，可以留空；长度不超过255个字符。"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付参数1"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["。\n规定了操作员所需的参数的值，例如，电话号码或加密性实践的地址。\n许多运营商仅限于一个付款参数，但是如果参数数不止一个，则需要在相邻列中指示它们。"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付参数2"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付参数N"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您可以在支付任何操作员时查看支付参数："])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户大宗转账的列格式"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作码"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["。指定为0或完全不指定。"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金额"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金额显示在进行转账的账户资产中。如果对方没有这种资产，系统的转换率将自动应用于交易。"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付评论"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["。填写不是必须的，可以留空；长度不超过255个字符。"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收款账户"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["。在此输入需要转账到的用户账户号码（20位数字）。"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["信用代理人批量贷款发放的列格式"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["。该行表明付款是贷款。"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金额"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["。金额以信贷产品的货币表示。"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["贷款评论"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["。填写不是必须的，可以留空；长度不超过255个字符。"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["借款人ID"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["。请注意，借款人必须持有Mfo-BorrowPerson数字护照。"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["信贷产品代码"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["。您可以在手动提供贷款的信贷产品列表中找到它。"])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["期间"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["。只是期间的数量（具体使用的期间由信贷产品定义，例如，这可以是天、周、月等。）"])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作码（表单号码）"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["。填写此字段和以下字段不是必须的，如果不填写，借款人以后可以通过自己的账户独立提取贷款。"])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果有错误则取消"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["。如果转账交易错误，取消（1）发放的贷款，或者保留以供后续独立提取（0）。"])},
  "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付参数1"])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["。输入操作员要求的参数值，例如电话号码。许多操作员只需要一个支付参数（例如移动通信、互联网、商业电视等），但如果参数数量超过一个，您需要在第一列的相邻列中输入所有参数。"])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付参数2"])},
  "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付参数N"])},
  "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["该文件必须以一种货币表示收件人的加密资产的帐户或地址，因为从一个帐户中进行资金的注销，而不是从几个帐户进行。"])}
}