export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código da negociação"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dar"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receber"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direção"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxa"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timeout"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ações"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sem negociações ativas"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minhas negociações"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ativo"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ativo interno"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inativo"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correspondente"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Período p"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O período por"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtros"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atualizar"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eu dou"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["= igual"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≠ não é igual"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["> mais"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≥ mais ou igual"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["< Menos"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≤ menos ou igual"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entendo"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soma"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escolha um ativo"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meu papel"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabricante"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tomador"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ativo interno"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todas as opções"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entendo"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eu dou"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID do criador"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número do anúncio"])}
}