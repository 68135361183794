export default {
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعطاء المبلغ"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المبلغ"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استلام المبلغ"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مرشحات"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحديث"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصفقات"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصفقة"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد عروض"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العروض"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معرف البائع"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كود العرض"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأصل المشفر"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوصف"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم التحقق من البائع"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["للمستخدمين الذين تم التحقق منهم فقط"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يمكن إجراء صفقة مع نفسك"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر الأصل"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات الإعلان"])}
}