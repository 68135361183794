export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список инвойсов"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Инвойсов не найдено"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комментарий"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Магазин"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер магазина"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменить инвойс"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код платежа магазина"])}
}