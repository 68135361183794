export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cédé"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reçu"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["actif"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je transmets"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["interne"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["je reçois"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actifs internes"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actifs non trouvés"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actifs externes"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["externe"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionné :"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internes"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Externes"])}
}