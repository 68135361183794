export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dado"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recibido"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["activo"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["doy"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["interno"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["recibo"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activos internos"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontraron activos"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activos externos"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["externo"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionado:"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internos"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Externos"])}
}