export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Документація"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Протокол мерчанта"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переадресація на мерчант"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повернення в магазин"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сповіщення магазину"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обчислення підпису"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вбудований віджет"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Встановлення"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виклик"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відповідь від магазину"])}
}