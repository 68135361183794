export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upravit externí aktivum"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušit"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložit"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skupina"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synonyma"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Země"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propojit s aktivem"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jednotky"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zkratka"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přesnost"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivní"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akceptováno pro obchodování"])}
}