export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Логотип"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Атауы"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ТИКЕР"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Санаты"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Миссия"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Әрекеттер"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Крипто-активтер тізімі"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Крипто-активтер жоқ"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Токен шығару"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қабылдау"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нарық"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дәлдік"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бастапқы"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қосымша"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Басқа активпен сәйкестендірілген"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Крипто-актив туралы толық ақпарат"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Крипто-актив деректерін өңдеу"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қосымша эмиссия"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Крипто-активті жою"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Крипто-активтер тізімі"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қабылдау"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Белсенділік"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Иә"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жоқ"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Токендерді банкоматта сатып алуға болады"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Токендерді банкоматта сатуға болады"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Санаты"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қосымша нарық токендері"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Токендерді көрсету"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Барлық қабылданғандар"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Барлық менікі"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пайдаланушы UID"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активті жою"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нақты пайдаланушы үшін"])}
}