export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pary walutowe"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz parę walutową"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edytuj parę walutową"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń parę walutową"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Działania"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wejście"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ aktualizacji"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie znaleziono par walutowych"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czy na pewno chcesz usunąć parę walutową"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["powiązany z inną walutą"])}
}