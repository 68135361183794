export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doładowywane konto"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sposób płatności"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa szablonu:"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz szablon"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontynuuj"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szablony"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doładowanie konta"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szczegóły przelewu"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprawdź przelew"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informacje o przelewach"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instrukcja"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doładowanie adresu"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doładowywany adres"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eksport"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do listy zadań"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres przelewu"])}
}