export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви можете зробити одночасно кілька платежів. \nДля цього Вам потрібно заздалегідь створити файл із описом платежів у форматі \"XLS\", \"XLSX\", \"CSV\"."])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аналіз файлу"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кореспондент"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коментар"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Параметр"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Інформація про файл"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всього платежів: "])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["на загальну суму: "])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Файл платежів"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дебет рахунку"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Здійснити платежі"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продовжити"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завантажити файл платежів"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перевірте вміст файлу"])},
  "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Масові платежі та перекази"])}
}