export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geldautomaten"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerät hinzufügen"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerätetyp"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfiguration"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kassette hinzufügen"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kassettentyp"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfiguration"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerät entfernen"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kassette entfernen"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerät löschen?"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kassette löschen?"])}
}