export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["生成支付代码/链接"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商店操作编号"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金额"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评论"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["附加参数"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成功URL"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["错误URL"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通知URL"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客户成功支付后返回的URL"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客户支付失败后返回的URL"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付结果通知的URL"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["固定金额"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["复制"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["检查"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付链接"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["表格"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文档"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付帐单的有效期（分钟），默认60分钟"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["链接有效期"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["链接"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["表格"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["小部件"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果您不固定金额，付款人将能够独立输入该金额（例如适用于慈善付款）"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["隐藏"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["付款表"])}
}