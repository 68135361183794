export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Služba hromadných plateb umožňuje rychle převádět prostředky jiným uživatelům a provádět platby poskytovatelům služeb."])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V sekci"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nahrát soubor plateb"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["můžete nahrát soubor ve formátu Excel (XLS, XLSX, CSV) nebo XML."])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seznam úkolů"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["umožňuje zobrazit stavy dříve vytvořených žádostí o hromadné platby."])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Můžete vytvořit soubor v tabulkovém editoru Excel."])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stáhnout příklad souboru"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Důležité poznámky:"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formát souboru - CSV, XLS nebo XLSX."])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soubor musí být bez záhlaví."])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aby se dlouhá čísla nepřeváděla na exponenciální formát (např. \"5,46546E+15\") a nezkracovaly se vedoucí nuly, můžete před číslem umístit znak jednoduché uvozovky (') nebo před zadáním dat nastavit buňce textový formát."])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formát sloupců pro platby poskytovatelům služeb"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seznam sloupců v souboru:"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kód operátora"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Uvádí se ze seznamu operátorů uvedených"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zde"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". (Pro všechny mobilní operátory můžete jako kód operátora uvést 7000 - v tomto případě bude operátor určen automaticky podle telefonního čísla.)"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Částka"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Částka je uvedena v aktivu účtu, ze kterého bude převod proveden. Pokud se aktivum účtu, na který jsou aktiva převáděna, liší od aktiva účtu, který je vybrán k provedení převodu, bude pro transakci automaticky použit kurz konverze systému."])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentář k platbě"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Vyplnění není povinné, může být prázdné; délka nesmí přesáhnout 255 znaků."])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametr platby 1"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". \nPředepsána je hodnota parametru požadovaného operátorem, například telefonní číslo nebo adresa krypto-akretuálního. \nMnoho operátorů je omezeno na jeden parametr plateb, ale pokud je počet parametrů více než jeden, musíte je všechny uvést v sousedních sloupcích."])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametr platby 2"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametr platby N"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametry platby můžete zobrazit přechodem k platbě jakéhokoli operátora:"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formát sloupců pro hromadné převody uživatelům"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kód operátora"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Uvádí se 0 nebo se vůbec neuvádí."])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Částka"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Částka je uvedena v aktivu účtu, ze kterého bude převod proveden. Pokud se aktivum účtu, na který jsou aktiva převáděna, liší od aktiva účtu, který je vybrán k provedení převodu, bude pro transakci automaticky použit kurz konverze systému."])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentář k platbě"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Vyplnění není povinné, může být prázdné; délka nesmí přesáhnout 255 znaků."])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Účet příjemce"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Zde se uvádí číslo účtu uživatele, kterému je třeba provést převod (20 číslic)."])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formát sloupců pro hromadné vydávání úvěrů úvěrovým agentem"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Tento řádek uvádí, že platby představují úvěry."])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Částka"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Částka se uvádí ve měně úvěrového produktu."])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentář k úvěru"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Vyplnění není povinné, může být prázdné; délka nesmí přesáhnout 255 znaků."])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID dlužníka"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Upozorňujeme, že dlužník musí mít digitální pas Mfo-BorrowPerson."])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kód úvěrového produktu"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Lze ho zobrazit v seznamu úvěrových produktů při ručním poskytování úvěru."])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Období"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Prosté číslo období (jaká období se používají je určeno v úvěrovém produktu, mohou to být např. dny, týdny, měsíce atd.)"])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kód operátora (číslo formuláře)"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Vyplnění tohoto a následujících polí není povinné, pokud je nevyplníte, může dlužník později samostatně provést výběr úvěru prostřednictvím svého osobního kabinetu."])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušit v případě chyby"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Zrušit (1) poskytnutý úvěr, pokud transakce výběru skončí chybou, nebo ponechat pro následný samostatný výběr (0)."])},
  "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametr platby 1"])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Uvádí se hodnota parametru požadovaného operátorem, např. telefonní číslo. Mnoho operátorů se omezuje na jeden parametr platby, ale pokud je počet parametrů více než jeden, je třeba uvést všechny ve vedlejších sloupcích."])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametr platby 2"])},
  "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametr platby N"])},
  "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soubor musí označit účty nebo adresy krypto-aktiv příjemců v jedné měně, protože odpis finančních prostředků se provádí z jednoho účtu, nikoli z několika."])}
}