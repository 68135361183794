export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informacje ogólne"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informacje o płatności"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podpis kontrolny"])},
  "List": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moje sklepy"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie znaleziono sklepów"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz kod widżetu"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz link do płatności"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edytuj"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń sklep"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czy na pewno chcesz usunąć sklep"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zarejestruj sklep"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kod"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa"])},
    "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tryb płatności"])},
    "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktywność"])},
    "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akceptacja"])},
    "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
    "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Działania"])}
  },
  "New": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zarejestruj sklep"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zarejestrowany w systemie sklep elektroniczny umożliwia przyjmowanie płatności od klientów przez merchant system."])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jako sklep rejestrują się sklepy elektroniczne, punkty wymiany i wszelkie inne zasoby internetowe chcące przyjmować płatności na swoje konto."])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa lub marka"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres URL sklepu"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krótki opis sklepu"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ serwisu"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sklep aktywny"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klucz tajny"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zezwól na automatyczne wypłaty"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wysyłaj wyniki na skrypt"])},
    "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres skryptu"])},
    "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powrót po udanej płatności"])},
    "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Po NIEudanej płatności"])},
    "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz"])},
    "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generuj"])},
    "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Co najmniej 30 znaków."])},
    "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Używany do sprawdzania integralności i wiarygodności przesyłanych danych przez system płatności (przy wysyłaniu użytkownika na merchant) i sklep (przy otrzymywaniu wyników płatności)"])},
    "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dalej"])},
    "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wstecz"])},
    "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do listy sklepów"])},
    "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niepoprawny adres URL"])}
  },
  "Invoices": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista faktur"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie znaleziono faktur"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kod"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentarz"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kwota"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sklep"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numer sklepu"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anuluj fakturę"])},
    "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przechowuj kod płatności"])}
  }
}