export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Реєстрація"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Персональний аккаунт"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бізнес аккаунт"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прізвище:"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ім'я:"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["По батькові: (якщо є)"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Допустимі латинські букви, а також дефіс та апостроф."])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bond"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Допустимі латинські букви, а також дефіс, пробіл та апостроф."])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["James"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["None"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Допустимі латинські букви, а також дефіс, лапки, пробіли, цифри та апостроф."])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"My Company\" Ltd"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Телефон"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Допустимі латинські букви (великими і малими літерами), цифри, символи _ - . ! $ % ^ * ="])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повторіть пароль"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Паролі не збігаються"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назад"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Реєстрація"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Далі"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дані користувача"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вкажіть коректну адресу електронної пошти або номер телефону. Номер телефону вказується в міжнародному форматі, наприклад: +499001234567."])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви успішно зареєстровані"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тепер ви можете"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["авторизуватися в особистому кабінеті"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш аккаунт буде перевірений нашим співробітником, про результати його активації ми повідомимо вам пізніше"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["відправлено листа з посиланням для підтвердження реєстрації"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На вашу адресу електронної пошти"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Після першої авторизації вам буде запропоновано підтвердити номер телефону"])}
}