export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select status"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The application for collection has been submitted"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collection application canceled"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collection approved for execution"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collection is carried out by the cashier (money is loaded into cassettes)"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cassettes handed over to collectors (money on the way)"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cash collection installed on the ATM"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As a result of a failure, overloaded with another collection"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The collection was replaced by another collection at an ATM (remains on the way)"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collection balances are posted (collection is sent to the archive)"])}
}