export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moje aktywa"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zewnętrzny aktyw"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupa"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa RU"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synonimy"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kraje"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeśli wypełnione, ogłoszenie będzie dostępne tylko dla użytkowników z wymienionych krajów"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powiąż go z aktywem"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jednostki miary"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skrócone"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precyzja"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opis"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szczegóły"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kierunek przelewu"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksimum"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limit"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czas oczekiwania (min)"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ogłoszenie jest aktywne"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostępne tylko przez link"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostępne tylko dla zweryfikowanych użytkowników"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do poprzedniego"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opublikuj"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dalej"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kierunek"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ograniczenia"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tworzenie ogłoszenia"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Własny zewnętrzny aktyw"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie wiązać"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tak"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmniejsz kurs"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przywróć"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak konta w takim aktywie"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz konto"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podaj kurs"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurs prawidłowy"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmniejsz kurs"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przywróć"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum większe niż maksimum"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gdzie i jak chcesz otrzymać przelew. Na przykład: Przelej w aplikacji bankowej na numer telefonu +499990001111 (odbiorca Aleksander N.)"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oddaję"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otrzymuję"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktywa"])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wymagane dane"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jakie dane musi przesłać taker, abyś mógł dokonać przelewu"])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz zasób"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz zasób"])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Co chcesz otrzymać: środki w aktywie wewnętrznym, na przykład BTC, czy w aktywie zewnętrznym, na przykład TOKEN GOLD?"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Napisz komentarz o szczegółach przeprowadzenia tej umowa, na przykład: \\\"Aktywa zewnętrzne są przekazywane tylko podczas osobistego spotkania\\\""])},
  "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rodzaj wartości, do których należy zasób zewnętrzny, na przykład „gotówka”"])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa aktywa do wyświetlenia na platformie"])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktywo może być również nazywane słowami z listy"])},
  "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porównanie z istniejącym aktywem"])},
  "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na przykład: litry, sztuki, godziny, pudełka"])},
  "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na przykład: l, szt, godz, pud"])},
  "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ile cyfr może być po przecinku w wartościach aktywa"])},
  "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybrałeś stworzenie jeszcze nieistniejącego aktywa, proszę wypełnij jego charakterystyki"])},
  "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czas dany stronom na wykonanie warunków"])},
  "62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktywem wewnętrznym jest aktywo istniejące w systemie \\\"New Reality\\\". Aktywem zewnętrznym jest aktywo nieistniejące w systemie \\\"New Reality\\\"."])},
  "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieprzypisany"])},
  "64": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wartość oczekiwana: od 1 do 2147483"])}
}