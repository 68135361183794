export default {
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oddaj sumę"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kwota"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otrzymaj sumę"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtry"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odśwież"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["transakcji"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transakcja"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak ofert"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oferty P2P"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID sprzedawcy"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kod oferty"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktywo"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opis"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprzedawca zweryfikowany"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tylko dla zweryfikowanych użytkowników"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie można dokonać transakcji z samym sobą"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybrać aktywo"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informacje o ogłoszeniu"])}
}