export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ATM collection"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add collection"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filters"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do not show archival collections"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do not show canceled collections"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Point number"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No collections"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collection application submitted"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit collection"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change collection status"])}
}