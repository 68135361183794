export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Биржа"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рынок. Сводка"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Похожие предложения"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Встречные предложения"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курс"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отдают"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получают"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мои заявки"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Последние сделки"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курс"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отдано"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата и время"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отдаю"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получаю"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курс"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операции"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["комиссия"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменить"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повторить"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет предложений"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет сделок"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет заявок"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Полностью выполнена"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обработка не начата"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обработка начата"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Частично выполнена"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Полностью отменена"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Частично выполнена и отменена"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получено"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все заявки"])}
}