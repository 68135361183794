export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Счет"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Остаток"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дополнительная информация"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Действия"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активен"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ввод"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вывод"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать новый адрес"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать новый счет"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить выбранные"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мои активы"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список счетов"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название счета"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы действительно хотите удалить счета?"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы действительно хотите удалить счет?"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активов не найдено"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Счетов не найдено"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переименование адреса"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы действительно хотите удалить адреса?"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы действительно хотите удалить адрес?"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нефиатные"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фиатные"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операции"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Действия"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пополнить"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вывести"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обменять"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скрыть"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить"])}
}