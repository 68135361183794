export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP地址访问设置将阻止您（或攻击者）从未列入允许列表的地址访问系统。"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["根据IP地址限制访问账户"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加地址"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地址： "])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地址块： "])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["至："])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的IP： "])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地址列表"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编号"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["从地址"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["至地址"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["访问"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有地址"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["禁止"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["允许"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入IP地址"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP地址无效"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未指定任何IP地址或地址块。"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有IP地址被允许授权。您将无法访问您的账户。"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["根据IP访问"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["从："])}
}