export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सामूहिक भुगतान सेवा अन्य उपयोगकर्ताओं को तेजी से धन हस्तांतरण करने और सेवा प्रदाताओं को भुगतान करने की अनुमति देती है।"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विभाग में"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भुगतान फ़ाइल अपलोड करें"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आप एक्सेल (XLS, XLSX, CSV) या XML फॉर्मेट में फ़ाइल अपलोड कर सकते हैं।"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कार्य सूची"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पहले से बने सामूहिक भुगतान अनुरोधों की स्थिति देखें।"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आप एक्सेल स्प्रेडशीट संपादक में फ़ाइल बना सकते हैं।"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फ़ाइल का उदाहरण डाउनलोड करें"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["महत्वपूर्ण नोट:"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फ़ाइल फॉर्मेट - CSV, XLS या XLSX।"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फ़ाइल बिना हेडर के होनी चाहिए।"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लंबी संख्याएँ एक्सपोनेंशियल फॉर्मेट में बदलने से बचाने के लिए, आप संख्याओं के आगे एकल उद्धरण (') जोड़ सकते हैं या डेटा दर्ज करने से पहले सेल को टेक्स्ट फॉर्मेट में सेट कर सकते हैं।"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सेवा प्रदाताओं को भुगतान के लिए कॉलम फॉर्मेट"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फ़ाइल में कॉलम की सूची:"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ऑपरेटर कोड"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". ऑपरेटर की सूची से निर्दिष्ट करें, जो यहां दी गई है"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यहाँ"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". (सभी मोबाइल ऑपरेटरों के लिए, आप ऑपरेटर कोड के रूप में 7000 निर्दिष्ट कर सकते हैं - इस मामले में, ऑपरेटर फोन नंबर द्वारा स्वचालित रूप से निर्धारित किया जाएगा।)"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["राशि"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". राशि उस संपत्ति के टिकर में निर्दिष्ट की जाती है जिससे हस्तांतरण किया जा रहा है। यदि ऐसा टिकर नहीं है, तो प्रणाली के रूपांतरण गुणांक को स्वचालित रूप से लागू किया जाएगा।"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भुगतान की टिप्पणी"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". वैकल्पिक भरें, इसे खाली छोड़ा जा सकता है; लंबाई 255 वर्णों से अधिक नहीं होनी चाहिए।"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भुगतान पैरामीटर 1"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["। \nऑपरेटर द्वारा आवश्यक पैरामीटर का मान, उदाहरण के लिए, फ़ोन नंबर या क्रिप्टो-एक्टुअल का पता, निर्धारित है। \nकई ऑपरेटर एक भुगतान पैरामीटर तक सीमित हैं, लेकिन यदि मापदंडों की संख्या एक से अधिक है, तो आपको उन सभी को पड़ोसी कॉलम में इंगित करने की आवश्यकता है।"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भुगतान पैरामीटर 2"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भुगतान पैरामीटर N"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आप किसी ऑपरेटर का भुगतान करते समय पैरामीटर देख सकते हैं:"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपयोगकर्ताओं के लिए सामूहिक हस्तांतरण के लिए कॉलम फॉर्मेट"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ऑपरेटर कोड"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". 0 निर्दिष्ट करें या इसे बिल्कुल भी निर्दिष्ट न करें।"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["राशि"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". राशि खाते के टिकर में प्रदर्शित की जाती है जिससे संपत्ति हस्तांतरित की जा रही है। यदि ऐसा टिकर नहीं है, तो प्रणाली के गुणांक को स्वचालित रूप से लागू किया जाएगा।"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भुगतान की टिप्पणी"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". वैकल्पिक भरें, इसे खाली छोड़ा जा सकता है; लंबाई 255 वर्णों से अधिक नहीं होनी चाहिए।"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्राप्तकर्ता खाता"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". यहां उपयोगकर्ता खाते की संख्या निर्दिष्ट करें जिसे भुगतान करना है (20 अंक)।"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्रेडिट एजेंट द्वारा ऋण जारी करने के लिए कॉलम फॉर्मेट"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". यह पंक्ति इंगित करती है कि भुगतान ऋण है।"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["राशि"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". राशि क्रेडिट उत्पाद की मुद्रा में निर्दिष्ट की जाती है।"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्रेडिट टिप्पणी"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". वैकल्पिक भरें, इसे खाली छोड़ा जा सकता है; लंबाई 255 वर्णों से अधिक नहीं होनी चाहिए।"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ऋणकर्ता आईडी"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". कृपया ध्यान दें कि ऋणकर्ता के पास Mfo-BorrowPerson डिजिटल पासपोर्ट होना चाहिए।"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्रेडिट उत्पाद कोड"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". इसे हाथ से ऋण जारी करते समय क्रेडिट उत्पाद सूची में देखा जा सकता है।"])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अवधि"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". केवल अवधि की संख्या (कौन सी अवधि उपयोग की जाती है क्रेडिट उत्पाद में निर्दिष्ट है, उदाहरण के लिए, यह दिन, सप्ताह, महीने आदि हो सकते हैं।)"])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ऑपरेटर कोड (फॉर्म नंबर)"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". यह और निम्नलिखित फ़ील्ड भरना आवश्यक नहीं है, यदि आप उन्हें नहीं भरते हैं, तो ऋणकर्ता बाद में अपने व्यक्तिगत कैबिनेट के माध्यम से स्वयं भुगतान निकाल सकता है।"])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["त्रुटि की स्थिति में रद्द करें"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". (1) त्रुटि के साथ समाप्त होने वाले लेन-देन के मामले में जारी किए गए ऋण को रद्द करें, या (0) इसे बाद में निकालने के लिए छोड़ दें।"])},
  "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भुगतान पैरामीटर 1"])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". उस पैरामीटर का मूल्य निर्दिष्ट करें जिसकी ऑपरेटर को आवश्यकता है, उदाहरण के लिए, फोन नंबर। कई ऑपरेटरों के लिए एक भुगतान पैरामीटर पर्याप्त होता है, लेकिन अगर पैरामीटर की संख्या अधिक है, तो आपको उन्हें पहले कॉलम के बगल में निर्दिष्ट करना होगा।"])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भुगतान पैरामीटर 2"])},
  "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भुगतान पैरामीटर №"])},
  "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फ़ाइल को एक मुद्रा में प्राप्तकर्ताओं के क्रिप्टो-एएसटीएस के खातों या पते को इंगित करना चाहिए, क्योंकि फंड का राइट-ऑफ एक खाते से किया जाता है, और कई से नहीं।"])}
}