export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuración de notificaciones"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activar"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notificar sobre operaciones"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorización (iniciar sesión en la oficina personal, aplicación móvil, etc.)"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notificar al realizar \"Operaciones financieras con tarjeta\""])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gastos (cualquier pago, transferencia, pago de servicios, etc.)"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recepción de fondos"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recepción de correo interno"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recepción de factura"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambio de configuración de seguridad"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar operaciones con un código del mensaje"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar autorización con un código del mensaje"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar al realizar \"Operaciones financieras con tarjeta\""])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar cambio de configuración de seguridad con un código del mensaje"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar gastos con un código del mensaje"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambios no guardados, ¿guardar?"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No guardar"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar"])}
}