export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obecné informace"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informace o platbě"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrolní podpis"])},
  "List": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moje obchody"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obchody nenalezeny"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořit widget kód"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořit platební odkaz"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upravit"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smazat obchod"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opravdu chcete smazat obchod?"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrovat obchod"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kód"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název"])},
    "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Režim plateb"])},
    "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivita"])},
    "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přijetí"])},
    "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
    "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akce"])}
  },
  "New": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrovat obchod"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-shop registrovaný v systému může přijímat platby od zákazníků prostřednictvím obchodníka našeho systému."])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elektronické obchody, směnárny a jakékoli jiné online zdroje, které jsou ochotny přijímat platby na svou adresu, jsou registrovány jako obchod."])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název nebo obchodní značka"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL adresa obchodu"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krátký popis obchodu"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ služby"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obchod je aktivní"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tajný klíč"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Povolit automatické výplaty"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posílat výsledky na skript"])},
    "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresa skriptu"])},
    "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Návrat po úspěšné platbě"])},
    "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Po NEúspěšné platbě"])},
    "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložit"])},
    "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vygenerovat"])},
    "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimálně 30 znaků."])},
    "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Používá se k ověření integrity a pravosti dat přenášených platebním systémem (při odesílání uživatele obchodem na obchodníka) a obchodem (při přijímání výsledků platby)"])},
    "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Další"])},
    "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zpět"])},
    "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do seznamu obchodů"])},
    "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nesprávná adresa URL"])}
  },
  "Invoices": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seznam faktur"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faktury nenalezeny"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kód"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stav"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentář"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Částka"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obchod"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postavení"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Číslo obchodu"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušit fakturu"])},
    "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skladujte platební kód"])}
  }
}