export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易请求"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["报价"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["卖方"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["超时"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["各方等待时间"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["汇率"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您给"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您收到"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请求交易"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建账户"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未选择账户"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金额"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金额不属于以下范围："])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分钟"])}
}