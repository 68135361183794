export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen Sie ein Währungspaar"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Währungspaar"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Update einrichten"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tor"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gegebene Währung"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erhaltene Währung"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gewinn zum Kurs hinzufügen, %"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Art der Kursaktualisierung"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kursdauer, Sekunden"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kursaktualisierungsintervall, Sekunden"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Also"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für welches Datum ist der Kurs gültig?"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alias ​​der angegebenen Währung"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alias ​​der empfangenen Währung"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tor"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es ist notwendig, eine Währung zu wählen, und zwar nur eine: gegeben oder empfangen"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sendebetrag * Kurs = Empfangsbetrag"])}
}