export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Документация"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Протокол мерчанта"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переадресация на мерчант"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Возврат в магазин"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уведомление магазина"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вычисление подписи"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Встраиваемый виджет"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Установка"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вызов"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ответ от магазина"])}
}