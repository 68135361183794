export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digitální pas"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Více informací"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uživatelské ID:"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum registrace:"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poslední autorizace:"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moje aktiva"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Účty"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobrazit vše"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doplnit"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vybrat"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádné účty"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrzovací heslo"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pro potvrzení operací musíte vytvořit potvrzovací heslo."])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Šablony"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vše"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Částka"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Populární platby"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Novinky"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skrýt"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dále..."])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádná aktiva"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořit"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořit"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Váhy"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odebrat"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doplňte"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výměna"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uživateli"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Celková bilance"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobrazit v"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non-fiat zůstatek"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rovnováha Fiat"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nové informace"])}
}