export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les clés à usage unique augmentent considérablement la sécurité du système."])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lors de la connexion au programme, on vous demandera non seulement de saisir l'identifiant et le mot de passe, mais aussi la clé à usage unique suivante. L'activation est effectuée successivement. La dernière clé est valide jusqu'à ce que vous créiez une nouvelle série de clés."])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utiliser des clés à usage unique"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des clés"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Générer"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporter"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clé"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clés à usage unique"])}
}