export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea una coppia di valute"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coppia di valute"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impostazione di un aggiornamento"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porta"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data valuta"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valuta ricevuta"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi profitto al corso,%"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo di aggiornamento del corso"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durata del corso, secondi"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intervallo di aggiornamento del percorso, secondi"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BENE"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per quale data è valido il corso?"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alias ​​della valuta indicata"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alias ​​della valuta ricevuta"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porta"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["È necessario scegliere una valuta e, inoltre, solo una: data o ricevuta"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo invio * Tariffa = Importo ricevuto"])}
}