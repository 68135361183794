export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Geschäfte"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Geschäfte gefunden"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Widget-Code erstellen"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungslink erstellen"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeiten"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschäft löschen"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie das Geschäft wirklich löschen"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschäft registrieren"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsmodus"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivität"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akzeptieren"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktionen"])}
}