export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل المتجر"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكن للمتجر الإلكتروني المسجل في النظام استقبال المدفوعات من العملاء عبر بوابة الدفع لدينا."])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكن تسجيل المتاجر الإلكترونية، مراكز الصرف وأي موارد إنترنت أخرى ترغب في استقبال المدفوعات."])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم أو العلامة التجارية"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان URL للمتجر"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وصف موجز للمتجر"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الخدمة"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المتجر نشط"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المفتاح السري."])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السماح بالدفع التلقائي."])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إرسال النتائج إلى البرنامج النصي."])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان البرنامج النصي"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العودة بعد نجاح الدفع"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بعد الدفع غير الناجح"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ما لا يقل عن 30 حرفا."])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تُستخدم للتحقق من سلامة وصحة البيانات المرسلة من قبل نظام الدفع (عندما يرسل المتجر المستخدم إلى التاجر) ومن قبل المتجر (عند استلام نتائج الدفع)."])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التالي‘"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رجوع‘"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلى قائمة المتاجر"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان URL غير صحيح"])}
}