export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Авторизація"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Логін:"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль:"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Забули пароль?"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вперше тут?"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створити обліковий запис"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відновлення паролю"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Телефон або e-mail:"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відновити"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відновлення паролю"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прізвище:"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ім'я:"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["По батькові:"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата народження:"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Увійти"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назад"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продовжити"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Знайти"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Користувача знайдено"])}
}