export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The mass payment service allows you to quickly transfer funds to other users and perform payment transactions to service providers."])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In chapter"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download payment file"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["you can upload an Excel (XLS, XLSX, CSV) or XML file."])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task list"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["allows you to view the statuses of previously created requests for mass payouts."])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can create a file in an Excel spreadsheet editor."])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download sample file"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Important notes:"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The file format is CSV, XLS or XLSX."])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The file must be without headers."])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In order to prevent long numbers from being converted to the exponent format (for example, \"5.46546E 15\") and leading zeros are not cut off, you can put a single quote character (') in front of the number, or set the cell to text format before entering data."])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format of columns for payments to service providers"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of columns in the file:"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operator code"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". \nSpecified from the list of operators given"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". \n(For all mobile operators, you can specify 7000 as the operator code - in this case, the operator will be determined automatically by phone number.)"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sum"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". \nThe amount is specified in the ticker of the asset from which the transfer is made.\nIf such a ticker is not available, the system's conversion rate is automatically applied to the transaction."])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment comment"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". \nFilling is not required, you can leave it blank; \nlength no more than 255 characters."])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment option 1"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". \nThe value of the parameter required by the operator, for example, the phone number or the address of the crypto-actual, is prescribed. \nMany operators are limited to one payment parameter, but if the number of parameters is more than one, then you need to indicate them all in the neighboring columns."])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment option 2"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment parameter N"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment parameters can be viewed by going to the payment of any operator:"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Column format for bulk transfers to users"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operator code"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". \nSet to 0 or not set at all."])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sum"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". \nThe amount is displayed in the ticker of the account from which the asset is transferred.\nIf such a ticker is not available, the system's conversion rate is automatically applied to the transaction."])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment comment"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". \nFilling is not required, you can leave it blank; \nlength no more than 255 characters."])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beneficiary's account"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". \nThis specifies the account number of the user who needs to make the transfer (20 digits)."])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format of columns for mass issuance of loans by a loan agent"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". \nThis line indicates that the payments are loans."])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sum"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". \nThe amount is indicated in the currency of the loan product."])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit comment"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". \nFilling is not required, you can leave it blank; \nlength no more than 255 characters."])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrower ID"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". \nPlease note that the borrower must have an Mfo-BorrowPerson digital passport."])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loan product code"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". \nYou can see it in the list of loan products on the manual provision of a loan."])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". \nJust the number of periods (exactly which periods are used is defined in the loan product, for example, it can be days, weeks, months, etc.)"])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operator code (form number)"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". \nFilling in this and the following fields is not necessary, if they are not filled out, then the borrower will be able to independently withdraw the loan later through his personal account."])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel in case of error"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". \nCancel (1) the issued credit if the withdrawal transaction fails, or leave it for later self-withdrawal (0)."])},
  "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment option 1"])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". \nThe value of the parameter required by the operator is written, for example, the phone number. \nMany operators are limited to one payment parameter, but if the number of parameters is more than one, then you need to specify them all in columns adjacent to the first one."])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment option 2"])},
  "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment parameter N"])},
  "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The file must indicate the accounts or addresses of the crypto-assets of recipients in one currency, since the write-off of funds is carried out from one account, and not from several."])}
}