export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["řádek až 255 znaků"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["celé číslo"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["20 číslic"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0 nebo 1"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zlomkové číslo, přesnost na dvě číslice, oddělovač teček"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["celé číslo"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["řetězec až 255 znaků, volitelný"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["celé číslo, volitelné"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["řetězec 32 znaků"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platební kód skladu; musí být jedinečný pro každou platbu při přenosu řetězce"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bude automaticky vygenerováno jedinečné číslo"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["číslo prodejny přidělené po registraci"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["číslo účtu obchodu; tento účet také určuje, v jaké měně musí být platba provedena (ve většině případů může uživatel provést platbu v jakékoli měně a prostředky budou automaticky převedeny na měnu příjemce)"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zakázat klientovi měnit zadanou částku (1 - zakázat, 0 - povolit)"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["částka příjemce v měně účtu příjemce"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["číslo formuláře, přes který je platba přijímána"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["komentář zobrazený plátci jako účel platby"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["další volitelný parametr, který bude předán do skriptu výsledku platby a také na návratové stránky"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL, kam se klient vrátí po úspěšné platbě; pokud není uvedeno, převezme se z nastavení obchodu"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL, kam se klient vrátí po chybě; pokud není uvedeno, převezme se z nastavení obchodu"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL, kam bude zasláno oznámení o výsledcích platby; pokud není uvedeno, převezme se z nastavení obchodu"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["životnost faktury (v minutách), výchozí 60"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["podepsání zprávy pomocí tajného klíče obchodu"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0 nebo 1"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 – v případě úspěchu, 0 – v opačném případě"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["celé číslo"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kód chyby (0, pokud není žádná chyba)"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["řetězec"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["text chyby (nebo vysvětlení stavu platby)"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0 nebo 1"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["znamení, že konečný stav platby ještě není znám"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["platební kód obchodu"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["číslo transakce"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protokol obchodníka"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přijímání plateb na webu je možné pouze pro online obchody registrované v systému. Pro registraci obchodu přejděte na stránku"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrace obchodu"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přesměrování uživatele na obchodníka pro platbu"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pro platbu je nutné přesměrovat uživatele na URL: https://merchant.[vaše doména] a odeslat následující parametry přes GET nebo POST:"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popis"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ dat"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podpis"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vypočítá se přidáním odeslaných parametrů a hashováním metodou hmac s tajným klíčem. Počet a pořadí přidaných parametrů závisí na parametrech"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["identified"])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pevná částka"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pořadí je následující:"])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ext_transact + num_shop + keyt_shop + identified + sum + payform + comment + free_param + url_success + url_decline + url_callback"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pro odkaz, kde klient"])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["platba, pořadí je následující (částka není zahrnuta v podpisu):"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ext_transact + num_shop + keyt_shop + identified + payform + comment + free_param + url_success + url_decline + url_callback"])},
  "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platební odkaz lze vygenerovat na stránce"])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'Můj obchod'"])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Příklad formuláře:"])},
  "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Návrat na web obchodu po dokončení operace"])},
  "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokud plátce odmítne platbu nebo operace skončí chybou, platební systém navrhne přejít na příslušnou stránku určenou v nastavení obchodu nebo odeslanou s formulářem"])},
  "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["url_decline"])},
  "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokud byla operace úspěšně dokončena, přejděte na stránku"])},
  "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["url_success"])},
  "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tyto skripty také obdrží výsledky platby (metodou určenou v nastavení obchodu. Výchozí - GET):"])},
  "62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název"])},
  "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popis"])},
  "64": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ dat"])},
  "65": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podpis"])},
  "66": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vypočítá se přidáním odeslaných parametrů a hashováním metodou hmac s tajným klíčem."])},
  "67": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pořadí přidaných parametrů:"])},
  "68": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ext_transact + amount + success + result + result_text + free_param"])},
  "69": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oznámení o stavu platby obchodu (callback)"])},
  "70": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Po provedení platby jsou výsledky platby odeslány do skriptu určeného během registrace obchodu"])},
  "71": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["result_url"])},
  "72": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tento skript přijímá data pro případy úspěšné platby i chyby."])},
  "73": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tyto skripty také obdrží výsledky platby (metodou určenou v nastavení obchodu. Výchozí - GET)."])},
  "74": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V některých případech informace s výsledky nedorazí (například pokud klient odešel zaplatit a zavřel stránku prohlížeče)."])},
  "75": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název"])},
  "76": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popis"])},
  "77": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ dat"])},
  "78": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podpis"])},
  "79": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vypočítá se přidáním odeslaných parametrů a hashováním metodou hmac s tajným klíčem."])},
  "80": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pořadí přidaných parametrů:"])},
  "81": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["transact + ext_transact + amount + success + result + result_text + free_param"])},
  "82": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výpočet podpisu"])},
  "83": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podpis je vytvořen přidáním požadovaných parametrů v určeném pořadí a hashováním metodou hmac."])},
  "84": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pořadí a počet přidaných parametrů je popsán výše."])},
  "85": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Následující seznamy ukazují příklady funkcí pro výpočet podpisu v různých jazycích, kde je řetězec přidaný pro podpis předán jako parametr"])},
  "86": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seznam 1. Výpočet podpisu v PHP."])},
  "87": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seznam 2. Výpočet podpisu v Pythonu."])},
  "88": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seznam 3. Výpočet podpisu v Perlu."])},
  "89": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pro platbu"])},
  "90": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Částka může být změněna"])},
  "91": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platba za produkt"])},
  "92": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaplatit"])},
  "93": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vygenerovat podpis s klíčem"])},
  "94": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["signed_str"])},
  "95": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a tajnou frází v parametru"])},
  "96": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["key"])}
}