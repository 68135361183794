export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ескі құпиясөз:"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жаңа құпиясөз:"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Құпиясөзді растау:"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жаңа құпиясөз:"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Құпиясөзді растау:"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сақтау"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сақтау"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"Жаңа құпиясөз\" және \"Құпиясөзді растау\" сәйкес емес."])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Құпиясөзде кириллица болмауы керек"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Екінші құпия сөзді жасаңыз"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Екінші құпия сөзді өзгертіңіз"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Басты бетке"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4-тен 65 символға дейінгі жол. Латын алфавитінің әріптері, цифрлар және таңбалар рұқсат етілген"])}
}