<template>
    <div class="light">
        <div v-if="this.ready">
            <div class="content p-4 chat-block">
                <div 
                    class="scroll-wrapper" 
                    ref="scrollWrapper" 
                    :style="{ height: this.filesBlock ? '40vh' : '55vh'}"
                >
                    <div 
                        class='scroll-menu container-fluid mt-1 p-0 g-3 d-flex flex-column' 
                        ref="scroll_menu" 
                        :style="`top: ${this.scrollPos}px;`"
                    >
                        <div 
                            v-for="(row, index) in this.msgs"
                            :key="index" 
                            :class="this.isThisMe(row.uid) ? 'align-self-end' : 'align-self-start'"
                            class="d-flex flex-column message-container"
                            :style="{ marginTop: calculateMargin(index) }"
                        >
                            <div class="message p-2 d-flex flex-column">
                                <span class="fs_08 wraptext">
                                    <span v-if="row.author" class="neue-bold color-menu" :class="row.admin == '1' ? 'color-warning' : ''">{{ row.author }}</span>
                                    <span v-else-if="row.admin == '0'" class="neue-bold">{{ row.uid }}</span>
                                </span>
                                <span class="mb-0 wraptext" v-html="row.text"></span>
                                <div v-for="(file, index) in row.files" :key="index" class="d-flex flex-row pt-1 pointer" v-on:click="this.DownloadFile(file)">
                                    <div v-if="this.isImage(file.mime)">
                                        <img
                                            class="message-img"
                                            :src="'data:' + file.mime + ';base64,' + file.body" 
                                            alt="file" 
                                        >
                                    </div>
                                    <div v-else class="d-flex flex-row pt-1 pointer">
                                        <i class='item-i contain c24 mr-1' :class='"file_download"'></i>
                                        {{ file.name }}
                                    </div>
                                </div>
                                <div class="d-flex justify-content-end">
                                    <span class="fs_07">{{ this.formatDate(row.dt) }}</span>
                                </div>
                            </div>
                        </div>
                        <div v-if="this.smallloader" class="loader-r-cc2">
                            <Loading :active="this.smallloader"
                                :is-full-page="false"
                                :height='20'
                                :width='50'
                                :loader='"dots"'
                                :color='"#008D3E"'
                                :opacity='0'
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div class='d-flex align-items-center container-fluid p-0 g-3 position-relative m-0 message-send-wrapper'>
                    <i 
                        class='item-i pointer contain c28 clip-files ml-1' 
                        v-on:click='this.filesBlock = !this.filesBlock'
                    ></i>
                    <textarea 
                        class="chat-textarea form-control m1-2" 
                        id="message" 
                        v-model="this.data.message" 
                        @updateParentState="this.updateState"
                        @keydown.enter="this.handleEnterKey"
                    ></textarea>
                    <i 
                        class='item-i contain c24 mr-1 message-send' 
                        :class="!this.data.message && !this.data.file.length > 0 ? 'btn-send-disabled' : 'pointer'" 
                        v-on:click="this.handleBtnSend"
                    ></i>
                </div>
                <div>
                    <FilePrime 
                        @updateParentState="this.updateState"
                        :filesBlock="this.filesBlock"
                        :dataValue='this.data.file'
                        :f_key="this.f_key"
                        :id='"file"'
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import Input from '@/components/service/Input';
import Button from '@/components/service/Button';
import File from '@/components/service/File';
import FilePrime from '@/components/service/FilePrime';

export default {
    components: {
        Loading,
        Input,
        Button,
        File,
        FilePrime
    },
    props: {
        topic: [Object]
    },
    data () {
        return {
            t: false,
            msgs: [],
            data: {
                message: '',
                file: [],
            },
            ready: false,
            smallloader: false,
            f_key: 0,
            scrollPos: 0,
            filesBlock: false,
        }
    },
    mounted() {
        this.getMessages();
    },
    beforeUnmount() {
        if (this.t) {
            window.clearTimeout(this.t);
        }
    },
    methods: {
        getMessages() {
            this.axios.post('GetlistSupportMessages', {data: {topic: this.topic.ticket}}).then(res => {
                this.ready = true;
                if (res.data.body.result === '0') {
                    this.msgs = res.data.body.messages;
                    console.log('msgs', this.msgs);
                    this.data.file = [];
                    this.msgs.length > 0 ? this.checkScrollMessageBlock() : '';
                    this.t = setTimeout(() => {this.updateMessages()}, 5000);
                    this.msgs.forEach((item, index) => {
                        if (typeof item.text === 'string') {
                            this.msgs[index].text = item.text.replace(/\n\r?/g, '<br />');
                        }
                    });
                } else {
                    this.close();
                }
            });
        },
        updateMessages() {
            if (this.t) {
                window.clearTimeout(this.t);
            }
            let params = {
                topic: this.topic.ticket
            };
            if (this.msgs.length > 0 ) {
                params['last_read_message'] = this.msgs[this.msgs.length - 1].inc;
            }
            this.axios.post('GetlistSupportMessages', {no_loader: true, data: params}).then(res => {
                this.t = setTimeout(() => {this.updateMessages()}, 5000);
                if (res.data.body.messages.length > 0) {
                    res.data.body.messages.forEach(e => {
                        this.msgs.push(e);
                    });
                    this.checkScrollMessageBlock();
                    this.$emit('updateSwapStatus');
                }
            })
        },
        updateState(state) {
            this.data[state.id] = state.value;
        },
        calculateMargin(index) {
            if (index == 0) {
                return '0';
            }

            if (this.msgs[index].uid == this.msgs[index - 1].uid) {
                return '2px'
            }

            return '1.5rem'
        },
        handleEnterKey(event) {
            if (event.key === 'Enter') {
                if (this.data.message || this.data.file.length > 0) {
                    this.AddSupportMessage();
                }
            }
        },
        handleBtnSend() {
            if (this.data.message || this.data.file.length > 0) {
                    this.AddSupportMessage();
                }
        },
        scrollToEndMessageBlock(container, content) {
            const scrollPosition = container.scrollTop;
            const heightContent = content.offsetHeight;
            const difference = heightContent - scrollPosition;

            // Прокрутка с анимацией
            const duration = 500; // Продолжительность анимации в миллисекундах
            const startTime = performance.now();
            
            const animateScroll = (timestamp) => {
                const elapsed = timestamp - startTime;
                const progress = Math.min(elapsed / duration, 1); // Ограничиваем прогресс до 1
                container.scrollTop = scrollPosition + difference * progress;
                
                if (progress < 1) {
                    requestAnimationFrame(animateScroll);
                }
            };
            
            requestAnimationFrame(animateScroll);
        },
        checkScrollMessageBlock() {
            setTimeout(() => {
                const container = this.$refs.scrollWrapper;
                const content = this.$refs.scroll_menu;

                if (container && content) {
                    this.scrollToEndMessageBlock(container, content);
                }
            }, 500);
        },
        AddSupportMessage() {
            this.axios.post('AddSupportMessage', this.getData(),
            {headers: {
                    'Content-Type': 'multipart/form-data',
            }}).then(res => {
                if (res.data.body.result == '0') {
                    this.checkScrollMessageBlock();
                    this.data.message = '';
                    this.data.file = [];
                    this.msgs = res.data.body.messages;
                    this.f_key =  this.f_key + 1
                    this.filesBlock = false;
                    if (this.t) {
                        window.clearTimeout(this.t);
                    }

                }
            });
        },
        isImage(mimeType) {
            return mimeType && mimeType.startsWith('image');
        },
        DownloadFile(file) {
            if (this.isImage(file.mine)) {
                return;
            }
            else {
                let data = 'data:' + file.mime + ';base64,' + file.body;
                fetch(data).then(res => {
                    res.blob().then( blob => {
                        let url = URL.createObjectURL(blob);
                        this.Down(url, file.name);
                        URL.revokeObjectURL(url); 
                    });
                })
            }
        },
        Down(path, filename) {
            const anchor = document.createElement('a');
            anchor.href = path;
            anchor.download = filename;

            document.body.appendChild(anchor);


            anchor.click();

            document.body.removeChild(anchor);
        },
        getData() {
            let param = new FormData();
            param.append('topic', this.topic.ticket);
            param.append('message', this.data.message);
            
            console.log('file in form', this.data.file);
            if(this.data.file.length > 0) {
                this.data.file.forEach((e, i) => {
                    param.append('file[]', e.fileObject[0]);
                })
            }
            return param;
        },
        isThisMe(uid) {
            return uid == this.$store.state.userData.id;
        },
        formatDate(dateString) {
            const date = new Date(dateString);
            const today = new Date();
            const yesterday = new Date(today);
            yesterday.setDate(yesterday.getDate() - 1);

            if (date.toDateString() === today.toDateString()) {
                return date.toLocaleTimeString('en-US', { hour12: false });
            } else if (date.toDateString() === yesterday.toDateString()) {
                return this.$t('views.P2P.P2PChat.1') + ' ' + date.toLocaleTimeString('en-US', { hour12: false });
            } else if (date.getFullYear() === today.getFullYear()) {
                return date.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit' }) + ' ' + date.toLocaleTimeString('en-US', { hour12: false });
            } else {
                return date.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }) + ' ' + date.toLocaleTimeString('en-US', { hour12: false });
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.loader-r-cc {
    min-height: 100px;
    position: relative;
}
.loader-r-cc2 {
    min-height: 15px;
    position: relative;
}
.wraptext {
    overflow-wrap: anywhere;
}

.fixed-wrapper {
    position: sticky;
}
.scroll-menu {
    position: absolute;
    left: 0;
    right: 0;
    width: calc(100% - 2px);
    max-height: fit-content;
}
.scroll-wrapper {
    overflow-y: auto;
    -ms-overflow-style: none;
    position: relative;
    z-index: 10;
}

.scroll-wrapper,
.chat-textarea {
    &::-webkit-scrollbar {
        width: 6px;
        border-radius: 20px;               /* width of the entire scrollbar */
    }

    &::-webkit-scrollbar-track {
        background: white;
        border-radius: 20px;        /* color of the tracking area */
    }

    &::-webkit-scrollbar-thumb {
        background-color: $color-green;    /* color of the scroll thumb */
        border-radius: 20px;       /* roundness of the scroll thumb */
        border: 1px solid white;  /* creates padding around scroll thumb */
    }
}

.chat-block {
    background: url($bg-chat);
    background-size: contain;
    margin-bottom: 0;
    position: relative;
    border-radius: 12px 12px 0 0;

    @media(max-width: 768px) {
        border-radius: 12px;
    }
}

.chat-block:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: block;
    background: $color-menu;
    background-blend-mode: darken;
    opacity: 0.08;
    border-radius: 12px 12px 0 0;

    @media(max-width: 768px) {
        border-radius: 12px;
    }
}

.message-send-wrapper,
.message {
    background: white;
}

.message-send-wrapper {
    border-radius: 0 0 12px 12px;
}

.message {
    border-radius: 0.5rem;
}

.message-img {
    max-width: 200px;
    max-height: 200px;
}

.chat-textarea {
    border: none;
    border-radius: 0;
    resize: none;
    min-height: auto;
}

.chat-textarea:focus,
.chat-textarea:focus-visible{
    outline: none;
    border: none;
    box-shadow: none;
}

.btn-send-disabled {
    filter: saturate(10%);
}
</style>