export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्थिति"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विषय"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अंतिम संदेश"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["श्रेणी"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्रियाएँ"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई अनुरोध नहीं"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["श्रेणी"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्राथमिकता"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विषय"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संदेश का पाठ"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फाइलें"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आप एक या अधिक फाइलें अपलोड कर सकते हैं। फाइल का अधिकतम आकार - 10MB."])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भेजें"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["निम्न"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सामान्य"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उच्च"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["तकनीकी सहायता"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सक्रिय अनुरोध"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संग्रह"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नया अनुरोध"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ऑपरेटर द्वारा प्रोसेस हो रहा है"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अनुरोध बनाया गया"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["काम चल रहा है, ऑपरेटर के उत्तर की प्रतीक्षा करें"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अनुरोध बंद"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अनुरोध बंद"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई नए संदेश नहीं"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ऑपरेटर से नया संदेश"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई नए संदेश नहीं"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संग्रह में भेजें"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी अनुरोध"])}
}