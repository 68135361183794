export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створення електронного чека"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рахунок списання"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номінал кожного чека"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коментар"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кількість чеків"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назад"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створити"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Серія:"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер:"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код активації:"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створені чеки"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завантажити файл з чеками"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Збережіть інформацію - після закриття цієї сторінки коди активації будуть приховані"])}
}