export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पता"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खाता"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शेष"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्थिति"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अतिरिक्त जानकारी"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कार्य"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सक्रिय"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जमा"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["निकालना"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नया पता बनाएं"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नया खाता बनाएं"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["चयनित हटाएं"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मेरे संसाधन"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खाता सूची"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खाता नाम"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्या आप वाकई खाते हटाना चाहते हैं?"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्या आप वाकई खाता हटाना चाहते हैं?"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संसाधन नहीं मिले"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खाते नहीं मिले"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पते का नाम बदलना"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्या आप वाकई पते हटाना चाहते हैं?"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्या आप वाकई पता हटाना चाहते हैं?"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["गैर-फिएट"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["व्यवस्थापत्र"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संचालन"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कार्रवाई"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लबालब भरना"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["निकालना"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अदला-बदली"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["छिपाना"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["परिवर्तन"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मिटाना"])}
}