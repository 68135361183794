export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всі заявки"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скинути"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фільтри"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оновити"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дано"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отримано"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курс"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операції"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["курс"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скасувати"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повторити"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заявок немає"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мінімальна сума дано"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Максимальна сума дано"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мінімальна сума отримано"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Максимальна сума отримано"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кількість спроб транзакції"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активність"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дано актив"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отримано актив"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дебет рахунку"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кредит рахунку"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список субдистриб'юторів"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рахунок не обрано"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Період з"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Період по"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сьогодні"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вчора"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тиждень"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Місяць"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рік"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип дати не обрано"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створення"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завершення"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кінець"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Початок"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Яка частина є фіксованою, а яка змінною"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума дано фіксована"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума до отримання фіксована"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип заявки"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Користувач бажає обміняти швидко за кращими зустрічними пропозиціями"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обмін за строго фіксованим курсом"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обробка не розпочата"])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обробка розпочата"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Частково виконано"])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повністю виконано"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повністю скасовано"])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Частково виконано та скасовано"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дано актив"])},
  "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отримано актив"])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип дати"])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мінімальна сума більша за максимальну суму"])}
}