export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر العنوان"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المستوطنة"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العنوان"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلغاء"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البلد"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المنطقة"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المقاطعة"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحي"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المدينة"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المنطقة الداخلية للمدينة"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المستوطنة"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشارع"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العنصر الإضافي للعنوان"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التوضيح"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المنزل"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرمز البريدي"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العنوان"])}
}