export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Widget Integrado"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instalación"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conecte el script a la página en la sección head a través de CDN o descargándolo como archivo"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Llamada"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Llame a la función"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pasando un objeto JS como argumento"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descripción"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de datos"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ejemplo"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["id para iframe"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["name para iframe"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["objeto con parámetros de pago"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["función manejadora de operación exitosa"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["función manejadora de operación fallida"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección del servicio de pago"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abajo"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Más abajo"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retorno al sitio de la tienda después de completar la operación"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Después de completar la operación o rechazarla, el widget se cerrará. El objeto con los parámetros a continuación se pasará al callback onFail u onSuccess:"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagar"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acciones en caso de éxito"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acciones en caso de fallo"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección del servicio de pago"])}
}