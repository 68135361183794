export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repetir operación"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historial de operaciones"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de cuentas"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Éxito"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error desconocido"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plantillas"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mis activos"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información sobre el nuevo activo"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emisión adicional"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de activos virtuales"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enlace a cadena de bloques"])}
}