export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер кассеты"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID кассеты"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер инкассации кассеты"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номинал кассеты"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загружено"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сервер"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Банкомат"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фактически"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комментарий к кассете"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет данных для отображения"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Инкассация устройства "])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["действует "])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["c "])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["по "])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["остатки разнесены "])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сервер"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество купюр"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Банкомат"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество купюр"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комментарий"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Диспенсер"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reject/Retract"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Купюроприёмник"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reject карты"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карты"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["текущие"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["выгруженные"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Инкассационные остатки по банкомату "])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Инкассация устройства "])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["действует "])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["кассета установлена в банкомат "])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["изъята "])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["остатки разнесены "])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["остатки разнесены "])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" кассета пустая: "])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["слипания: "])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дополнительная информация: "])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" по настоящее время "])}
}