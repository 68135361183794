export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["строка до 255 символов"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["целое число"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["20 цифр"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0 или 1"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["дробное число, точность две цифры, разделитель точка"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["целое число"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["строка до 255 символов, необязательно"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["целое число, необязательно"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["строка 32 символа"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["код оплаты магазина; должен быть уникальным для каждой оплаты; при передаче строки"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["будет автоматически сгенерирован уникальный номер"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["номер магазина, присваиваемый после регистрации"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["номер счета магазина; этот счет также определяет, в какой валюте должна быть произведена оплата (в большинстве случаев пользователь может произвести оплату в любой валюте, а средства будут автоматически конвертированы в валюту получателя)"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["запретить клиенту изменять указанную сумму (1 - запретить, 0 - разрешить)"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["сумма получателя в валюте счета получателя"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["номер формы, через которую принимается оплата"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["комментарий, отображаемый плательщику в качестве назначения платежа"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["дополнительный необязательный параметр, который будет передан в скрипт результата оплаты, а также на страницы возврата"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL, на который вернется клиент после успешной оплаты; если не указан, берется из настроек магазина"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL, на который вернется клиент после ошибки; если не указан, берется из настроек магазина"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL, на который будет отправлено уведомление о результатах оплаты; если не указан, берется из настроек магазина"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["срок действия счета на оплату (в минутах), по умолчанию 60"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["подпись сообщения с использованием секретного ключа магазина"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0 или 1"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 - в случае успеха, 0 - в противном случае"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["целое число"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["код ошибки (0 если нет ошибки)"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["строка"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["текст ошибки (или пояснение к статусу оплаты)"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0 или 1"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["признак того, что окончательный статус оплаты еще не известен"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["код оплаты магазина"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["номер транзакции"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Протокол мерчанта"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прием платежей на сайте возможен только для зарегистрированных в системе интернет-магазинов. Для регистрации магазина перейдите на страницу"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регистрация магазина"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перенаправление пользователя на мерчант для оплаты"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для проведения оплаты необходимо перенаправить пользователя на URL: https://merchant.[ваш домен] и передать следующие параметры методом GET или POST:"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Описание"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип данных"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подпись"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["вычисляется путем конкатенации передаваемых параметров и хеширования методом hmac с секретным ключом. Количество и порядок конкатенируемых параметров зависят от параметра"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["identified"])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["фиксированная сумма"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["порядок следующий:"])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ext_transact + num_shop + keyt_shop + identified + sum + payform + comment + free_param + url_success + url_decline + url_callback"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для ссылки, где клиент"])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["оплата, порядок следующий (сумма не включается в подпись):"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ext_transact + num_shop + keyt_shop + identified + payform + comment + free_param + url_success + url_decline + url_callback"])},
  "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Платежную ссылку можно сгенерировать на странице"])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'Мои магазины'"])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пример формы:"])},
  "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Возврат на сайт магазина после завершения операции"])},
  "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если плательщик отказывается от оплаты или операция завершается с ошибкой, платежная система предлагает перейти на соответствующую страницу, указанную в настройках магазина или переданную с формой"])},
  "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["url_decline"])},
  "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если операция завершается успешно, переход на страницу"])},
  "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["url_success"])},
  "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эти скрипты также получат результаты оплаты (методом, указанным в настройках магазина. По умолчанию - GET):"])},
  "62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название"])},
  "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Описание"])},
  "64": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип данных"])},
  "65": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подпись"])},
  "66": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["вычисляется путем конкатенации передаваемых параметров и хеширования методом hmac с секретным ключом."])},
  "67": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Порядок конкатенируемых параметров:"])},
  "68": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ext_transact + amount + success + result + result_text + free_param"])},
  "69": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уведомление магазина о статусе оплаты (callback)"])},
  "70": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["После проведения оплаты результаты оплаты отправляются на скрипт, указанный при регистрации магазина"])},
  "71": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["result_url"])},
  "72": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Этот скрипт получает данные как об успешных оплатах, так и в случае ошибки."])},
  "73": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эти скрипты также получат результаты оплаты (методом, указанным в настройках магазина. По умолчанию - GET)."])},
  "74": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В некоторых случаях уведомление с результатами не приходит (например, если клиент пошел оплачивать и закрыл страницу браузера)."])},
  "75": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название"])},
  "76": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Описание"])},
  "77": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип данных"])},
  "78": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подпись"])},
  "79": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["вычисляется путем конкатенации передаваемых параметров и хеширования методом hmac с секретным ключом."])},
  "80": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Порядок конкатенируемых параметров:"])},
  "81": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["transact + ext_transact + amount + success + result + result_text + free_param"])},
  "82": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вычисление подписи"])},
  "83": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подпись формируется путем конкатенации необходимых параметров в указанном порядке и хеширования методом hmac."])},
  "84": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Порядок и количество конкатенируемых параметров описаны выше."])},
  "85": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В следующих листингах показаны примеры функций, вычисляющих подпись на разных языках, где конкатенированная подписываемая строка передается параметром"])},
  "86": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Листинг 1. Вычисление подписи на PHP."])},
  "87": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Листинг 2. Вычисление подписи на Python."])},
  "88": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Листинг 3. Вычисление подписи на Perl."])},
  "89": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для оплаты с"])},
  "90": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["может изменить сумму"])},
  "91": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["оплата товара"])},
  "92": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оплатить"])},
  "93": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сгенерировать подпись с ключом"])},
  "94": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["signed_str"])},
  "95": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["и секретной фразой в параметре"])},
  "96": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["key"])}
}