export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accounts"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remainder"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional Information"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conclusion"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a new address"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a new account"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Selected"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My assets"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of accounts"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account name"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete accounts?"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete the account?"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No assets found"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No accounts found"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renaming an address"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete addresses?"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non-fiat"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiat"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operations"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top up"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Withdraw"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exchange"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])}
}