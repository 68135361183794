export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a currency pair"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency pair"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setting up an update"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gateway"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Given currency"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receive currency"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add profit to the course, %"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course update type"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course lifetime, seconds"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course update interval, seconds"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Well"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What date is the course valid for?"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alias ​​of the given currency"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alias ​​of the received currency"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gateway"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is necessary to choose a currency and, moreover, only one: given or received"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sending amount * Rate = Receiving amount"])}
}