export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documentação"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protocolo do Comerciante"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redirecionamento para o comerciante"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retorno à loja"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notificação da loja"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cálculo da assinatura"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Widget incorporado"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instalação"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chamada"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resposta da loja"])}
}