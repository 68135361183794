import { createI18n } from 'vue-i18n'

/**
 * Load locale messages
 *
 * The loaded `JSON` locale messages is pre-compiled by `@intlify/vue-i18n-loader`, which is integrated into `vue-cli-plugin-i18n`.
 * See: https://github.com/intlify/vue-i18n-loader#rocket-i18n-resource-pre-compilation
 */
function loadLocaleMessages() {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    // console.log('KEY', key)
    let keysPath = key.split('/');
    keysPath.pop();
    // console.log('END_KEY', endKey)
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    // console.log('MATCHED', matched)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = messages[locale] !== undefined ? messages[locale] : {};
      recursivePath(messages[locale], keysPath, locales, key);
      let ppath = key.split('/');
      ppath.pop();
      ppath.shift();
      ppath = ppath.join('.');
      nestedVal.set(ppath, messages[locale], locales(key).default);

      // messages[locale] = locales(key).default
      // console.log('TST',tst) 
    }
  })
  // console.log('TST',tst) 
  return messages 
}

function recursivePath(obj, arr, locales, key) {
  arr.shift();
  if (arr.length > 0) {
    obj[arr[0]] = obj[arr[0]] !== undefined ? obj[arr[0]] : {};
    recursivePath(obj[arr[0]], arr, locales, key);
  } else {
    return;
  }
}

const nestedVal = {
  get(path, root) {
    return path.split('.').reduce((p, c) => p[c], root);
  },
  set(path, root, val) {
    path = path.split('.');
    const key = path.pop();
    nestedVal.get(path.join('.'), root)[key] = val;
  }
};

// function detectLanguage() {
//   const lng = window.navigator.userLanguage || window.navigator.language;
//   const locales = require.context(
//     "./locales",
//     true,
//     /[A-Za-z0-9-_,\s]+\.json$/i
//   );
//   const lang = locales
//     .keys()
//     .find((key) => lng.includes(key.replace("./", "").replace(".json", "")));
//   return lang ? lang.replace("./", "").replace(".json", "") : null;
// }

export default createI18n({
  legacy: false,
  locale: localStorage.getItem("lang") ||
    process.env.VUE_APP_I18N_LOCALE,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'ru',
  messages: loadLocaleMessages()
})
