export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informações gerais"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informações de pagamento"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assinatura de controle"])},
  "List": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meus lojas"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lojas não encontradas"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar código de widget"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar link de pagamento"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excluir loja"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você realmente deseja excluir a loja"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrar loja"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
    "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modo de pagamento"])},
    "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atividade"])},
    "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceitação"])},
    "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
    "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ações"])}
  },
  "New": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrar loja"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A loja eletrônica registrada no sistema pode aceitar pagamentos de clientes através do merchant do nosso sistema."])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podem ser registradas como loja lojas eletrônicas, pontos de troca e quaisquer outros recursos da internet que desejem aceitar pagamentos."])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome ou marca comercial"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL da loja"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrição curta da loja"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de serviço"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loja ativa"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chave secreta"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permitir pagamentos automáticos"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar resultados para o script"])},
    "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereço do script"])},
    "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retornar após pagamento bem-sucedido"])},
    "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Após pagamento não bem-sucedido"])},
    "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvar"])},
    "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerar"])},
    "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pelo menos 30 caracteres."])},
    "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usado para verificar a integridade e autenticidade dos dados transmitidos pelo sistema de pagamento (ao enviar o usuário do merchant) e pela loja (ao receber os resultados do pagamento)"])},
    "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avançar"])},
    "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voltar"])},
    "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para a lista de lojas"])},
    "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL incorreto"])}
  },
  "Invoices": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de faturas"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faturas não encontradas"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentário"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantia"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loja"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número da loja"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancele a fatura"])},
    "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de pagamento da loja"])}
  }
}