export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वन-टाइम कुंजी सिस्टम की सुरक्षा को काफी बढ़ाती है।"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रवेश के समय, प्रोग्राम आपसे न केवल लॉगिन और पासवर्ड, बल्कि अगली वन-टाइम कुंजी भी पूछेगा। सक्रियण क्रमशः होता है। अंतिम कुंजी तब तक प्रभावी रहती है जब तक कि आप नई कुंजी श्रृंखला नहीं बनाते।"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वन-टाइम कुंजी का उपयोग करें"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कुंजी सूची"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उत्पन्न करें"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["निर्यात"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्रमांक"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कुंजी"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वन-टाइम कुंजी"])}
}