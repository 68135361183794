export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emissão adicional"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criptoativo"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conta - fonte de cobertura"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conta de débito"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emissão adicional"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentário"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emissão atual"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TOKEN"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cálculo de cobertura"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comissão"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comissão"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cálculo de cobertura"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vinculação"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dados básicos"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porcentagem de cobertura"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cobertura futura"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baixa de cobertura"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor que estará em reserva após a emissão adicional."])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A deduzir"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cobertura atual"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor que será debitado da sua conta após a emissão adicional."])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor de cobertura futuro"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E — Valor de emissão atual"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I — Emissão adicional"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["K — Taxa de vinculação"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["P — Porcentagem de cobertura"])}
}