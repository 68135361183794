export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запит угоди"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пропозиція"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продавець"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Таймаут"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Час очікування дій сторін"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курс"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Віддаєте"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отримуєте"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запросити угоду"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створити рахунок"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рахунок не обрано"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума не потрапляє в діапазон: "])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["хв"])}
}