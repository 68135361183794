export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset interno"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conto"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset esterno"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppo"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome EN"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sinonimi"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paesi"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se compilato, l'annuncio sarà disponibile solo per gli utenti dei paesi indicati"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collega all'asset"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unità di misura"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbreviazione"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precisione"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrizione"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettagli"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direzione del trasferimento"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimo"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Massimo"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limite"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tempo di attesa (min)"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'annuncio è attivo"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponibile solo tramite link"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponibile solo per utenti verificati"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precedente"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pubblica"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successivo"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direzione"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restrizioni"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creazione annuncio"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il tuo asset esterno"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non collegare"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sì"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riduci tasso"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ritorna indietro"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun conto in tale asset"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea conto"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specifica tasso"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasso corretto"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riduci tasso"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ritorna indietro"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimo superiore al massimo"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dove e come vuoi ricevere il trasferimento. Ad esempio: Trasferisci tramite l'app bancaria al numero di telefono +399990001111 (destinatario Alessandro N.)"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["do"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ricevo"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset"])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettagli richiesti"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quali dettagli deve inviare il ricevente per poter effettuare il trasferimento"])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selezionare un attivo"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selezionare un attivo"])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cosa desiderate ricevere: fondi in un attivo interno, ad esempio BTC, o in un attivo esterno, ad esempio TOKEN GOLD?"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentate le caratteristiche di questa transazione, ad esempio: \\\"L'attivo esterno viene trasferito solo in un incontro personale\\\""])},
  "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo di valori a cui appartiene un'attività esterna, ad esempio \"contanti\""])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome dell'attivo da visualizzare sulla piattaforma"])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'attivo può anche essere chiamato con parole della lista"])},
  "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corrispondenza con un attivo esistente"])},
  "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad esempio: litri, pezzi, ore, scatole"])},
  "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad esempio: L, pz, ore, scat"])},
  "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quante cifre possono esserci dopo la virgola nei valori dell'attivo"])},
  "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avete scelto di creare un attivo non ancora esistente, si prega di compilare le sue caratteristiche"])},
  "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tempo concesso alle parti per adempiere le condizioni"])},
  "62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un attivo interno è un attivo che esiste nel sistema \\\"New Reality\\\". Un attivo esterno è un attivo che non esiste nel sistema \\\"New Reality\\\"."])},
  "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non confrontato"])},
  "64": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valore previsto: da 1 a 2147483"])}
}