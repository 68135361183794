export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["魔法词是额外的安全设置。"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果启用了魔法词，每次授权时，系统将要求您输入魔法词中的一些字符（按其序号）。"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["魔法词："])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["要求魔法词："])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录账户时："])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通过商家支付时："])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用卡时："])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["魔法词"])}
}