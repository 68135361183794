export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة المجموعة"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المعلومات الأساسية"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأساسيات"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["موزع"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم النقطة"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد البطاقات لكل طلب"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متقبل الفاتورة"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بطاقات"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جامعي النقد"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملاحظة لهواة الجمع"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعليق على الجمع"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التحميل خارج الترتيب"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استبدل كاسيت متقبل الفاتورة"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التعليق على الكاسيت"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التعليق على البطاقات"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قم بإزالة جميع البطاقات من علبة موزع البطاقات المرفوضة"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قم بإزالة جميع البطاقات من الأنبوب"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم النقطة هو حقل مطلوب يجب ملؤه"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معرف الكاسيت 1"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معرف الكاسيت 2"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معرف الكاسيت 3"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معرف الكاسيت 4"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تصنيف الكاسيت 1"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الفواتير في الكاسيت 1"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المبلغ في الكاسيت 1"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تصنيف الكاسيت 2"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الفواتير في الكاسيت 2"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التعليق على الشريط 1"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معرف الكاسيت"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تصنيف الكاسيت"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الفواتير"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مجموع"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التعليق على الكاسيت"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يحفظ"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحرير المجموعة"])}
}