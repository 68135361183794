export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar activo externo"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupo"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sinónimos"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Países"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vincular al activo"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unidades de medida"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abreviado"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precisión"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activo"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceptado para operar"])}
}