export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сжигание актива"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма сжигания"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Счёт для возврата"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сжечь"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма сжигания"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма сжигания не может быть больше чем сумма эмиссии"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Возврат покрытия"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["К возврату"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cумма, которая останется в резерве после сжигания актива"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cумма, которая вернется на ваш счет после сжигания актива"])}
}