export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documentation"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merchant Protocol"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redirect to merchant"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return to store"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Store notification"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signature calculation"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Embedded widget"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installation"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Call"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Response from store"])}
}