export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Түскен күні"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Өңдеу күні"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Төлемдер саны"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["соның ішінде сәтті"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["соның ішінде қате"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жаппай төлемдер"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакция"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нәтиже"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қызмет көрсетуші"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сома"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комиссия"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Түсініктеме"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1-параметр"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Төлемдер табылмады"])}
}