export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magiczne słowo to dodatkowe ustawienie bezpieczeństwa."])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeśli magiczne słowo jest włączone, przy każdej autoryzacji system poprosi o wprowadzenie pewnych znaków (według ich numeru) z magicznego słowa."])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magic Word:"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapytaj magiczne słowo:"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przy wejściu do konta:"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przy płatnościach przez merchanta:"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przy użyciu karty:"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magic Word"])}
}