export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez l'adresse"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Localité"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Région"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["District"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arrondissement"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Territoire urbain"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Localité"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rue"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Élément d'adresse supplémentaire"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Précision"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maison"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code postal"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])}
}