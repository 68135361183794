export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تتيح خدمة المدفوعات الجماعية تحويل الأموال بسرعة إلى مستخدمين آخرين وإجراء عمليات الدفع لمزودي الخدمة."])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["في القسم"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحميل ملف المدفوعات"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكنك تحميل ملف بتنسيق Excel (XLS، XLSX، CSV) أو XML."])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قائمة المهام"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تتيح لك عرض حالات الطلبات التي تم إنشاؤها سابقًا للمدفوعات الجماعية."])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكنك إنشاء ملف في محرر الجداول Excel."])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحميل نموذج الملف"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملاحظات هامة:"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تنسيق الملف - CSV، XLS أو XLSX."])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب أن يكون الملف بدون عناوين."])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لتجنب تحويل الأرقام الطويلة إلى تنسيق الأسي (مثل \"5,46546E+15\") وعدم قطع الأصفار الرائدة، يمكن إضافة رمز الاقتباس الفردي (')، أو تعيين التنسيق النصي للخلية قبل إدخال البيانات."])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تنسيق الأعمدة للمدفوعات إلى مزودي الخدمة"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قائمة الأعمدة في الملف:"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كود المشغل"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". يحدد من قائمة المشغلين المتوفرة"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هنا"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". (يمكن تحديد كود المشغل 7000 لجميع مشغلي شبكات المحمول - في هذه الحالة سيتم تحديد المشغل تلقائيًا حسب رقم الهاتف.)"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المبلغ"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". يحدد المبلغ بعملة الأصل الذي يتم التحويل منه. إذا لم يكن هناك هذا الأصل، فسيتم تطبيق معامل التحويل النظامي تلقائيًا على العملية."])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التعليق على الدفع"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". ليس من الضروري ملؤها، يمكن تركها فارغة؛ لا يتجاوز طولها 255 حرفًا."])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المعلمة الأولى للدفع"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". \nيتم تحديد قيمة المعلمة المطلوبة من قبل المشغل ، على سبيل المثال ، رقم الهاتف أو عنوان التشفير. \nيقتصر العديد من المشغلين على معلمة دفع واحدة ، ولكن إذا كان عدد المعلمات أكثر من واحد ، فأنت بحاجة إلى الإشارة إليها جميعًا في الأعمدة المجاورة."])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المعلمة الثانية للدفع"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المعلمة الثالثة للدفع"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكنك الاطلاع على معلمات الدفع عند الانتقال إلى دفع أي مشغل:"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تنسيق الأعمدة للتحويلات الجماعية إلى المستخدمين"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كود المشغل"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". يحدد 0 أو لا يحدد."])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المبلغ"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". يحدد المبلغ بعملة الحساب الذي يتم التحويل منه. إذا لم يكن هناك هذا الأصل، فسيتم تطبيق معامل التحويل النظامي تلقائيًا على العملية."])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التعليق على الدفع"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". ليس من الضروري ملؤها، يمكن تركها فارغة؛ لا يتجاوز طولها 255 حرفًا."])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حساب المستلم"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". هنا يحدد رقم حساب المستخدم الذي يجب إجراء التحويل إليه (20 رقمًا)."])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تنسيق الأعمدة لإصدار القروض الجماعية من قبل وكيل الائتمان"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". يشير هذا السطر إلى أن المدفوعات تمثل قروضًا."])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المبلغ"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". يحدد المبلغ بعملة المنتج الائتماني."])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التعليق على القرض"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". ليس من الضروري ملؤها، يمكن تركها فارغة؛ لا يتجاوز طولها 255 حرفًا."])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معرف المقترض"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". لاحظ أنه يجب أن يكون لدى المقترض جواز سفر رقمي Mfo-BorrowPerson."])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كود المنتج الائتماني"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". يمكنك الاطلاع عليه في قائمة المنتجات الائتمانية في إصدار القرض اليدوي."])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفترة"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". فقط عدد الفترات (تحدد المنتجات الائتمانية الفترات المستخدمة، على سبيل المثال، يمكن أن تكون أيام، أسابيع، شهور، إلخ.)"])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كود المشغل (رقم النموذج)"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". ليس من الضروري ملء هذا والحقول التالية، إذا لم تملأها، فسيتمكن المقترض لاحقًا من سحب القرض بنفسه عبر حسابه الشخصي."])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإلغاء في حالة الخطأ"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". إلغاء القرض الممنوح (1) إذا انتهت معاملة السحب بخطأ، أو تركه للسحب الذاتي لاحقًا (0)."])},
  "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المعلمة الأولى للدفع"])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". يحدد قيمة المعلمة المطلوبة من قبل المشغل، مثل رقم الهاتف. يقتصر العديد من المشغلين على معلمة دفع واحدة، ولكن إذا كان عدد المعلمات أكثر من واحد، فيجب عليك تحديدها جميعًا في الأعمدة المجاورة للعمود الأول."])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المعلمة الثانية للدفع"])},
  "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المعلمة الثالثة للدفع"])},
  "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب أن يشير الملف إلى حسابات أو عناوين أجرام المشفرات للمستلمين في عملة واحدة ، حيث يتم شطب الأموال من حساب واحد ، وليس من عدة."])}
}