export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las claves de un solo uso mejoran significativamente la seguridad del sistema."])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En el momento de iniciar sesión, el programa te pedirá no solo que ingreses tu usuario y contraseña, sino también la siguiente clave de un solo uso. La activación se realiza secuencialmente. La última clave es válida hasta que crees una nueva serie de claves."])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usar claves de un solo uso"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de claves"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generar"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportar"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nº"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clave"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Claves de un solo uso"])}
}