export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mot magique est un paramètre de sécurité supplémentaire."])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si le mot magique est activé, le système vous demandera, à chaque connexion, d'entrer certains caractères (selon leur ordre) du mot magique."])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot magique :"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demander le mot magique :"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lors de l'accès au compte :"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lors du paiement par le marchand :"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lors de l'utilisation de la carte :"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot magique"])}
}