export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar ativo externo"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvar"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupo"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sinônimos"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Países"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vincular ao ativo"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unidades de medida"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abreviado"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precisão"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ativo"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceito para negociação"])}
}