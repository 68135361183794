export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Інкасація банкоматів"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати інкасацію"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дії"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фільтри"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скинути"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оновити"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не показувати архівні інкасації"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не показувати скасовані інкасації"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер точки"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Немає інкасацій"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заявку на інкасацію подано"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редагувати інкасацію"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Змінити статус інкасації"])}
}