export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona indirizzo"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Località"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paese"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regione"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provincia"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distretto"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Città"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Territorio urbano"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Località"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Via"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elemento aggiuntivo dell'indirizzo"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specificazione"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero civico"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice postale"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo"])}
}