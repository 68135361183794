export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security"])},
  "Antiphishing": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The anti-phishing setting is an additional protection mechanism when working with the web banking."])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The welcome phrase you set will be displayed at the top of any page of your account."])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The correct phrase will show you that you are on the right site, the wrong phrase will be a signal: without performing operations, you must urgently contact the security service of our system."])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable anti-phishing:"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anti-phishing string:"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anti-phishing"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The changes will take effect after the next login."])}
  },
  "IP": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuring access by IP address will not allow you (or an intruder) to work with the system from an address that is not registered in the list of allowed."])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restriction of access to the cabinet by IP addresses"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add addresses"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address: "])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address block: "])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to:"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Own IP:"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address list"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From address"])},
    "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To address"])},
    "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access"])},
    "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
    "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No addresses"])},
    "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forbid"])},
    "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allow"])},
    "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter IP address"])},
    "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incorrect IP address entered"])},
    "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No IP address or block of addresses specified."])},
    "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No IP address is allowed for authorization. You will not be able to access your account."])},
    "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP Access"])},
    "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["from:"])}
  },
  "Magic": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The magic word is an additional security setting."])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If the magic word is enabled, then each time you log in, the system will ask you to enter some characters (by their serial number) from the magic word."])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magic word:"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ask for the magic word:"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When entering the office:"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When paying through a merchant:"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When using the card:"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magic word"])}
  },
  "Onekey": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One-time keys significantly increase system security."])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When you enter the program, you will be asked not only to enter your login and password, but also another one-time key. Activation is performed one by one. The last key is valid until you create a new series of keys."])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use one-time keys"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of keys"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Key"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One-time keys"])}
  },
  "Password": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Old password:"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password:"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Password:"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"New Password\" and \"Confirm Password\" do not match."])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change password"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["String from 4 to 63 characters. \nLatin characters, numbers and symbols are allowed"])}
  },
  "PayPassword": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Old password:"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password:"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Password:"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password:"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Password:"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"New Password\" and \"Confirm Password\" do not match."])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password must not contain Cyrillic"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create confirmation password"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change confirmation password"])},
    "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To the main"])},
    "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["String from 4 to 65 characters. \nLatin characters, numbers and symbols are allowed"])}
  },
  "SecretQuestion": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In case you've forgotten your password, you can recover it by answering your secret question."])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In this section you can change your security question/answer."])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secret question"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your question:"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Answer:"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secret question"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your question"])}
  }
}