export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء شيك إلكتروني"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حساب الخصم"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القيمة الاسمية لكل شيك"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعليق"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الشيكات"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رجوع"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السلسلة:"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرقم:"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رمز التفعيل:"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم إنشاء الشيكات"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحميل ملف الشيكات"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["احفظ هذه المعلومات - سيتم إخفاء رموز التفعيل بعد إغلاق هذه الصفحة"])}
}