export default {
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dar quantia"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantia"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receber quantia"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtros"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atualizar"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["negociações"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negociação"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma oferta"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ofertas"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID do vendedor"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código da oferta"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cripto-ativo"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrição"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendedor verificado"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apenas para usuários verificados"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não é possível negociar consigo mesmo"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecionar ativo"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informações do anúncio"])}
}