export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar colección"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información básica"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lo esencial"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dispensador"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de punto"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de tarjetas por aplicación"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aceptador de billetes"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarjetas"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coleccionistas de efectivo"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nota para coleccionistas"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentario sobre la colección."])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargando fuera de orden"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reemplace el casete del aceptador de billetes"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentario sobre el casete."])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentario sobre las cartas."])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retire todas las tarjetas del casete del dispensador de tarjetas de rechazo."])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retire todas las tarjetas del tubo."])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El número de punto es un campo obligatorio que debe completarse"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID de casete 1"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID de casete 2"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID de casete 3"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID de casete 4"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clasificación del casete 1"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de billetes en el casete 1"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad en casete 1"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clasificación del casete 2"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de billetes en el casete 2"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentario sobre la cinta 1"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID del casete"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clasificación del casete"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de billetes"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suma"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentario sobre el casete."])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ahorrar"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edición de colección"])}
}