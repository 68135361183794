export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Віддаю"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["залишок "])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отримую"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Торгова пара"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рахунки"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип обміну"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переказ за ринком - буде використано обмін за найвигіднішим курсом на момент виконання заявки. Увага, сума до отримання може змінитися і відрізнятися від розрахованої залежно від заявок на момент обміну."])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переказ за фіксованою ціною відбувається відповідно до вказаної вами суми та курсу. Буде виставлена заявка. Якщо вказаний курс сильно відрізняється від ринкового, то закриття заявки може зайняти багато часу."])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курс (прогноз):"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курс"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курс не визначено,"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["рекомендується задати курс самостійно"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Використати все"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комісія"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комісія"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Підсумок:"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["За ринком"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(швидкий обмін за найкращою зустрічною пропозицією)"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фіксований курс"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(виставити заявку із заданим курсом)"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Немає відповідних рахунків"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курс"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зміна за 24 години"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коливання за 24 години"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обсяг угод за 24 години"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума не може дорівнювати нулю!"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обміняти"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Щоб побачити дані зведення, виберіть обмінну пару"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не можна обмінювати однакові активи"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додатково"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заявка активна з"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["до"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обмеження"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для полів \"Курс\" потрібно вказати значення, відмінне від 0"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Недостатньо коштів на рахунку"])}
}