export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period from"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period to"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filters"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sum"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction number"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameter"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operation"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correspondent"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameters/Comment"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No operations found"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount of transactions"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receipts:"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["receipts in"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expenses:"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operation types"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Withdrawal, payments for services"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internal transfers"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice payment"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfers to own accounts"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creating crypto vouchers"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activation of crypto vouchers"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incoming/outgoing"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coming"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consumption"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["= equal"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≠ not equal"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["> more"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≥ greater or equal"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["< less"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≤ less or equal"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed successfully"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In progress"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Today"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yesterday"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Week"])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Month"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year"])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["History"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account top-up"])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account not selected"])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exchange on the stock exchange"])},
  "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crypto money transfer"])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfers within your wallet"])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correspondent"])},
  "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operation"])}
}