export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inscription"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte personnel"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte professionnel"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de famille :"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom :"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patronyme :"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les lettres de l'alphabet latin sont acceptées, ainsi que le trait d'union et l'apostrophe."])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bond"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les lettres de l'alphabet latin sont acceptables, ainsi que le trait d'union, l'espace et l'apostrophe."])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["James"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["None"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les lettres de l'alphabet latin sont acceptées, ainsi que le trait d'union, les guillemets, les espaces, les chiffres et l'apostrophe."])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"Mon entreprise\" SARL"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphone"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lettres latines (majuscules et minuscules), chiffres, symboles _ - . ! $ % ^ * ="])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Répétez le mot de passe"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les mots de passe ne correspondent pas"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inscription"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivant"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations utilisateur"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indiquez une adresse e-mail ou un numéro de téléphone valide. Le numéro de téléphone doit être au format international, par exemple : +499001234567."])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous êtes enregistré avec succès"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez maintenant"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vous connecter à votre compte personnel"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre compte sera vérifié par notre employé et nous vous informerons des résultats de son activation plus tard"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un e-mail avec un lien de confirmation d'inscription a été envoyé à votre adresse e-mail"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À votre adresse e-mail"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Après la première connexion, il vous sera demandé de confirmer votre numéro de téléphone"])}
}