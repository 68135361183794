export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жинақ қосу"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Негізгі ақпарат"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Негіздер"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Диспенсер"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нүкте нөмірі"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бір өтінімдегі карталар саны"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шотты қабылдаушы"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карталар"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Инкассаторлар"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коллекционерлерге арналған ескерту"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жинаққа түсініктеме"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жүктеу ретсіз"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вексель қабылдаушы кассетаны ауыстырыңыз"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кассета туралы түсініктеме"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карточкаларға түсініктеме беру"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Барлық карталарды қабылдамау картасы үлестіргіш кассетасынан алып тастаңыз"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Түтіктен барлық карталарды алып тастаңыз"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нүкте нөмірі - толтырылуы тиіс міндетті өріс"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кассета идентификаторы 1"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кассета идентификаторы 2"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кассета идентификаторы 3"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кассета идентификаторы 4"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кассета рейтингі 1"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кассетадағы шоттардың саны 1"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кассетадағы сома 1"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кассета рейтингі 2"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кассетадағы шоттардың саны 2"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1-таспаға түсініктеме"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кассета идентификаторы"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кассета рейтингі"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шоттардың саны"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["сомасы"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кассета туралы түсініктеме"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сақтау"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жинақты өңдеу"])}
}