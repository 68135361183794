export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Логотип"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ТІКЕР"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категорія"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Місія"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дії"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список криптоактивів"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Немає криптоактивів"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Випуск токена"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Акцепт"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ринок"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Точність"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Первинний"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вторинний"])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зіставлений з іншим активом"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Детальна інформація про криптоактив"])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редагувати дані криптоактиву"])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додаткова емісія"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити криптоактив"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список криптоактивів"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Акцепт"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активність"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Так"])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ні"])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Токени можна купити в криптоматі"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Токени можна продати в криптоматі"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категорія"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Токени вторинного ринку"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відображення токенів"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всі акцептовані"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всі мої"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UID користувача"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Спалювання активу"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для конкретного користувача"])}
}