export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überweisungskonto:"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betrag:"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar:"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaktionsschutz"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code:"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generieren"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schutzdauer:"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tage"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorlagenname:"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als Vorlage speichern"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überweisen"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorlagen"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überweisung an einen anderen Benutzer"])},
  "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Schutzcode ist der Code, den der Empfänger eingeben muss, um die Transaktion abzuschließen. Der Empfänger sieht die geschützte Überweisung sofort im Abschnitt \"Operationsgeschichte\", kann das Geld jedoch erst verwenden, nachdem er den Schutzcode in der Transaktionskarte eingegeben hat. Wenn die Zahlungsfrist abgelaufen ist, werden die Mittel an den Absender zurückgegeben."])},
  "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überweisungsadresse"])}
}